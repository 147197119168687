import { GET_RETURNABLE_PLAN, SET_RETURNABLE_PLAN } from "./ReturnablePlanTypes";

const getReturnablePlan = () => {
    return {
        type: GET_RETURNABLE_PLAN,
    };
};

const setReturnablePlan = (data) => {
    return {
        type: SET_RETURNABLE_PLAN,
        payload: data,
    };
};
const ReturnablePlanAction = { getReturnablePlan, setReturnablePlan };

export default ReturnablePlanAction;
