import { Menu } from "@mui/material";
import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import FetchServerData from "../../provider/FetchServerData";
import store from "../../redux/store";
import MenuIcon from "@mui/icons-material/Menu";
import AdminHeaderAction from "../../redux/adminPanel/header/AdminHeaderAction";
import AdminAppUserDataAction from "../../redux/adminPanel/appUserData/AdminAppUserDataAction";

class AdminNavbar extends Component {
	header = store.getState().adminHeader.adminHeader;
	userName = store.getState().adminUserDetails.adminUserDetails;
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			anchorEl: null,
		};
	}

	toggleSidebar = () => {
		let overlay = document.getElementById("overlay");
		let loaderClasses = document.getElementById("pageLoader").classList;
		let loaderWithTextClasses =
			document.getElementById("loader-with-text").classList;
		if (
			loaderClasses.contains("show-loader") ||
			loaderWithTextClasses.contains("text-loader-show")
		) {
			return;
		}

		overlay.addEventListener("click", this.toggleSidebar);

		let appClasses = document.getElementById("app").classList;
		let overlayClasses = overlay.classList;

		if (appClasses.contains("sidebar-menu-extended")) {
			appClasses.remove("sidebar-menu-extended");
			overlayClasses.remove("overlay-active");
		} else {
			appClasses.add("sidebar-menu-extended");
			overlayClasses.add("overlay-active");
		}
	};

	handleAccountDropdownOpen = (e) => {
		this.setState({ open: true, anchorEl: e.currentTarget });
	};

	handleAccountDropdownClose = () => {
		this.setState({ open: false, anchorEl: null });
	};
	profile = () => {
		this.props.navigate("/ApProfile");
	};
	logOut = () => {
		let postObject = {
			header: this.header,
			appuser: {
				email: this.header.email,
			},
		};
		FetchServerData.callPostService("/adminpanelAppuserms/logout", postObject).then(
			(output) => {
				if (output.status === "SUCCESS") {
					// store.dispatch(StaticDataAction.setStaticData({}));
					store.dispatch(AdminHeaderAction.setAdminHeader({}));
					store.dispatch(AdminAppUserDataAction.setAdminUser({}));
					this.props.navigate("/APSignin");
				} else {
				}
			}
		);
	};

	render() {
		return (
			<section id="navbar">
				<nav
					className="layout-navbar navbar navbar-expand-lg fixed-top navbar-detached align-items-center bg-navbar-theme"
					style={{ width: "100%", margin: "0px" }}
					id="layout-navbar"
				>
					<div className="container">
						<div className="col text-start">
							<h5><b>Admin Panel</b></h5>
						</div>
						<div className="col text-end">
							<span style={{ fontSize: "16px", color: "black" }}>
								{this.userName.firstName} {this.userName.lastName}
							</span>{" "}
							&nbsp;
							<MenuIcon
								style={{
									fontSize: "28px",
									color: "black",
									cursor: "pointer",
								}}
								aria-controls={this.state.open ? "dropdown-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={this.state.open ? "true" : undefined}
								onClick={this.handleAccountDropdownOpen}
							/>
						</div>
					</div>
					<ul className="navbar-nav flex-row align-items-center ms-auto">
						<Menu
							anchorEl={this.state.anchorEl}
							id="dropdown-menu"
							open={this.state.open}
							onClose={this.handleAccountDropdownClose}
							onClick={this.handleAccountDropdownClose}
							PaperProps={{
								elevation: 0,
								sx: {
									overflow: "visible",
									filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
									mt: 1.5,
									"& .MuiAvatar-root": {
										width: 32,
										height: 32,
										ml: -0.5,
										mr: 1,
									},
									"&:before": {
										content: '""',
										display: "block",
										position: "absolute",
										top: 0,
										right: 20,
										width: 10,
										height: 10,
										bgcolor: "background.paper",
										transform: "translateY(-50%) rotate(45deg)",
										zIndex: 0,
									},
								},
							}}
							transformOrigin={{ horizontal: "right", vertical: "top" }}
							anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
						>
							<li>
								<div
									className="dropdown-item"
									onClick={this.profile}
									style={{ cursor: "pointer" }}
								>
									<i className="bx bx-user-circle me-2"></i>
									<span className="align-middle">Profile</span>
								</div>
							</li>

							<li>
								<div className="dropdown-divider"></div>
							</li>
							<li>
								<div
									className="dropdown-item"
									onClick={this.logOut}
									style={{ cursor: "pointer" }}
								>
									<i className="bx bx-power-off me-2"></i>
									<span className="align-middle">Log Out</span>
								</div>
							</li>
						</Menu>
					</ul>
				</nav>
			</section>
		);
	}
}

export default withRouter(AdminNavbar);
