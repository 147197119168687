import React, { Component } from 'react';
import MaterialTable from 'material-table';
import UiUtils from '../../provider/UiUtils';
import PtsAlert from '../../component/ptsAlert/PtsAlert';
import { withRouter } from "../../withRouter";
import store from '../../redux/store';
import { TextField } from '@material-ui/core';
import AdminFetchServerData from '../../provider/AdminFetchServerData';
// import "./RoleManagementList.css";
import Utils from '../../provider/Utils';
import ApplicationStateAction from '../../redux/applicationState/ApplicationStateAction';
import PtsButton from '../../component/buttons/PtsButton';
import EditIconButton from '../../component/buttons/EditIconButton';
import DeleteIconButton from '../../component/buttons/DeleteIconButton';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
class ProfileManagementList extends Component {
	header = store.getState().adminHeader.adminHeader;
	profileState = store.getState().applicationState.profileState;
	profileStateDetails = {};
	title = 'Profile Management List';
	breadCrumb = [{ pageid: '', uiname: 'Profile Management List' }];
	constructor() {
		super();
		if (this.profileState) {
			this.profileStateDetails = Object.getOwnPropertyNames(this.profileState);
		};
		if (this.profileStateDetails.length > 0) {
			this.state = {
				initialPage: this.profileState.initialPage,
				pageSize: this.profileState.pageSize,
				profileId: this.profileState.profileId,
				profileList: [],
				menus: [],
				render: false,
				getCreatePermission: false,
				getUpdatePermission: false,
				getDeletePermission: false,
			};
		} else {
			this.state = {
				initialPage: 0,
				pageSize: 10,
				profileList: [],
				menus: [],
				profileId: 0,
				render: false,
				getCreatePermission: false,
				getUpdatePermission: false,
				getDeletePermission: false,
			};
		}
		this.getprofileManagementList();
	};
	getprofileManagementList() {
		const postObject = {
			header: this.header,
		};
		AdminFetchServerData.callPostService('/apProfilems/getList', postObject).then((output) => {
			if (output.status === 'SUCCESS') {
				let profileList = output.data.profileList;
				let getPermission = Utils.getRolePermission(output.data.privilegeList);
				let getCreatePermission = getPermission[1];
				let getUpdatePermission = getPermission[3];
				let getDeletePermission = getPermission[4];
				let isPaging = false;
				if (profileList.length > this.state.pageSize) {
					isPaging = true;
				}
				this.setState({
					render: true,
					profileList: profileList,
					isPaging: isPaging,
					initialPage: this.state.initialPage,
					getCreatePermission: getCreatePermission,
					getUpdatePermission: getUpdatePermission,
					getDeletePermission: getDeletePermission,
				});
			} else {
			}
		});
	};

	deleteProfileToBackend = (e, data) => {
		const postObject = {
			header: this.header,
			profile: {
				id: data.id,
			},
		};
		AdminFetchServerData.callPostService('/apProfilems/delete', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					PtsAlert.success(output.message);
					var newList = this.state.profileList.filter((d) => d.id !== data.id)
					let isPaging = false;
					if (newList.length > this.state.pageSize) {
						isPaging = true;
					}
					this.setState({
						profileList: newList,
						isPaging: isPaging,
					});
				} else {
				};
			});
	};

	editProfile(e, data) {
		let profileState = {
			profileId: data.id,
		};
		store.dispatch(ApplicationStateAction.setApplicationState({ profileState }));
		this.props.navigate("/apProfileMgmnt", {
			state: {
				action: "edit",
				profileId: data.id,
			},
		});
	};

	addProfile() {
		this.props.navigate("/apProfileMgmnt", {
			state: {
				action: "create"
			}
		});
	}

	toProfileDetails(e, rowData) {
		let profileState = {
			initialPage: this.state.initialPage,
			pageSize: this.state.pageSize,
			profileId: rowData.id,
			selectedTab: this.state.selectedTab,
		};
		store.dispatch(ApplicationStateAction.setApplicationState({ profileState }));
		this.props.navigate("/apProfileMgmnt", {
			state: {
				action: "view",
				profileId: rowData.id,
				name: rowData.name,
				selectedTab: this.state.selectedTab,
			},
		});
	}
	handleMaterialTablePageChange(page, pageSize) {
		this.setState({ initialPage: page, pageSize: pageSize });
	};
	render() {
		if (this.state.render === false) {
			return <div />;
		};
		return (
			<>
				<AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} navigate={this.props.navigate} name={this.title} />
				<div style={{ maxWidth: '100%' }}>
					<MaterialTable
						title=""
						icons={UiUtils.getMaterialTableIcons("Add Role")}
						onRowClick={(event, rowData) => {
							this.toProfileDetails(event, rowData);
							event.stopPropagation();
						}}
						columns={[
							{
								title: '#',
								render: (rowData) => rowData.tableData.id + 1,
								width: '5%',
								align: 'left',
								cellStyle: {
									width: '6%',
								},
							},
							{
								title: 'Profile',
								field: 'name',
								cellStyle: {
									width: '75%',
								},
							},
						]}
						data={this.state.profileList}
						options={{
							sorting: true,
							actionsColumnIndex: -1,
							addRowPosition: "first",
							initialPage: this.state.initialPage,
							pageSize: this.state.pageSize,
							paging: this.state.isPaging,
							pageSizeOptions: Utils.materialTablePageSize(),
							headerStyle: {
								backgroundColor: '#0B6F9D',
								fontWeight: 'bold',
								color: "#ffff",
								align: 'left',
							},
							rowStyle: (rowData) => ({
								backgroundColor: rowData.id === this.state.profileId ? '#FEF5E7' : '',
								height: '3em',
								textTransform: 'capitalize',
							}),
						}}
						onChangePage={(page, pageSize) => {
							this.handleMaterialTablePageChange(page, pageSize);
						}}
						actions={[
							{
								icon: () => (
									<PtsButton btnName="Add Profile" type="submit" />
								),
								tooltip: 'Add Role',
								isFreeAction: true,
								onClick: (event) => this.addProfile(),
							},
						]}
					/>
				</div>
			</>
		)
	};

};
export default withRouter(ProfileManagementList);