import React, { Component } from 'react';
// import MaterialTable from '@material-table/core';
import MaterialTable from 'material-table'
import UiUtils from '../../provider/UiUtils';
import { withRouter } from "../../withRouter";
import AdminFetchServerData from '../../provider/AdminFetchServerData';
import store from '../../redux/store';
// import PtsButton from '../../component/buttons/PtsButton';
import Utils from "../../provider/Utils";
import ApplicationStateAction from '../../redux/applicationState/ApplicationStateAction';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import PtsButton from '../../component/buttons/PtsButton';
import AppUserRoleAction from '../../redux/adminPanel/appUserRole/AppUserRoleAction';

class AppUserList extends Component {
	header = store.getState().adminHeader.adminHeader;
	appUserState = store.getState().applicationState.appUserState;
	appUserStateDetails = {};
	title = 'Admin User List';
	breadCrumb = [{ pageid: '', uiname: 'Admin User List' }];
	constructor() {
		super();
		if (this.appUserState) {
			this.appUserStateDetails = Object.getOwnPropertyNames(this.appUserState);
		}
		if (this.appUserStateDetails.length > 0) {
			this.state = {
				initialPage: this.appUserState.initialPage,
				pageSize: this.appUserState.pageSize,
				appUserList: [],
				isPaging: false,
				render: false,
				getCreatePermission: false,
				appUserId: this.appUserState.appUserId
			};
		} else {
			this.state = {
				initialPage: 0,
				pageSize: 10,
				appUserList: [],
				isPaging: false,
				render: false,
				getCreatePermission: false,
				appUserId: "",
			};
		}
		this.getAppUserList();
		this.getUserRoleList();
	}

	async getUserRoleList() {
		const postObject = {
			header: this.header,
		};
		await AdminFetchServerData.callPostService('/apProfilems/getList', postObject).then((output) => {
			if (output.status === 'SUCCESS') {
				let profileList = output.data.profileList;
				store.dispatch(AppUserRoleAction.setAppUserRole(profileList));
				this.setState({
					// render: true,
					profileList: profileList,
				});
			} else {
			}
		});
	}

	getAppUserList() {
		const postObject = {
			header: this.header,
		};

		AdminFetchServerData.callPostService('/adminpanelAppuserms/getList', postObject).then((output) => {
			if (output.status === 'SUCCESS') {
				let appUserList = output.data.appusers;
				let getPermission = Utils.getRolePermission(output.data.privilegeList);
				let getCreatePermission = getPermission[1];
				let isPaging = false;
				if (appUserList.length > this.state.pageSize) {
					isPaging = true;
				}
				this.setState({
					render: true,
					isPaging: isPaging,
					appUserList: appUserList,
					initialPage: this.state.initialPage,
					getCreatePermission: getCreatePermission,
				});
			} else {
			}
		});
	}

	async addUser() {
		this.props.navigate("/appUser", {
			state: {
				action: "create",
				profileList: this.state.profileList,
			},
		});
	};

	toUserDetails(e, data) {
		let appUserState = {
			appUserId: data.id,
			initialPage: this.state.initialPage,
			pageSize: this.state.pageSize,
		};
		store.dispatch(ApplicationStateAction.setApplicationState({ appUserState }));
		this.props.navigate("/appUser", {
			state: {
				action: "view",
				appUserId: data.id,
			},
		});
	}
	handleMaterialTablePageChange(page, pageSize) {
		this.setState({ initialPage: page, pageSize: pageSize });
	}
	render() {
		if (this.state.render === false) {
			return <div />;
		}
		return (
			<>
				<AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} navigate={this.props.navigate} name={this.title} />
				<div style={{ maxWidth: '100%' }}>
					<MaterialTable
						title=""
						onRowClick={(event, rowData) => {
							this.toUserDetails(event, rowData);
							event.stopPropagation();
						}}
						icons={UiUtils.getMaterialTableIcons()}
						localization={{
							body: {
								emptyDataSourceMessage: "Please add user",
							},
						}}
						columns={[
							{
								title: '#',
								render: (rowData) => rowData.tableData.id + 1,
								width: '5%',
								align: 'left',
								cellStyle: {
									width: '6%',
								},
							},
							{
								title: 'Name',
								field: 'name',
								cellStyle: {
									width: '20%',
								},
							},
							{
								title: 'Email',
								field: 'email',
								cellStyle: {
									width: '24%',
								},
							},
							{
								title: 'Phone',
								field: 'phone',
								cellStyle: {
									width: '20%',
								},
							},
							{
								title: 'Profile',
								field: 'profile',
								cellStyle: {
									width: '20%',
								},
							},
							{
								title: 'Status',
								field: 'status',
								filtering: false,

							},
						]}
						data={this.state.appUserList}
						options={{
							sorting: true,
							actionsColumnIndex: -1,
							initialPage: this.state.initialPage,
							pageSize: this.state.pageSize,
							pageSizeOptions: Utils.materialTablePageSize(),
							paging: this.state.isPaging,
							headerStyle: {
								backgroundColor: '#0B6F9D',
								fontWeight: 'bold',
								color: "#ffff",
								align: 'left',
							},
							rowStyle: (rowData) => ({
								backgroundColor: rowData.id === this.state.appUserId ? '#FEF5E7' : '',
								height: '3em',
								textTransform: 'capitalize',
							}),
						}}
						onChangePage={(page, pageSize) => {
							this.handleMaterialTablePageChange(page, pageSize);
						}}
						actions={[
							{
								icon: () => (
									this.state.getCreatePermission &&
									<span className="addUser">
										{' '}
										<PtsButton btnName="Add User" type="submit" />
									</span>
								),
								tooltip: 'Add User',
								isFreeAction: true,
								onClick: (event) => this.addUser(),
							},
						]}
					/>
				</div>
			</>
		)
	}
}
export default withRouter(AppUserList);