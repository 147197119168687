import TextField from "@material-ui/core/TextField";
import { Autocomplete } from '@mui/material';
import React, { Component } from 'react';
import { Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../provider/FetchServerData';
import Utils from '../../provider/Utils';
import store from '../../redux/store';
import PtsAlert from '../ptsAlert/PtsAlert';
class AddressWithTimezone extends Component {
  header = store.getState().header.header;
  countryListArray = store.getState().staticData.countryList;
  stateListArray = []
  constructor(props) {
    super(props);
    let address = props.data;
    let action = props.action;
    let pageId = "";
    let defaultCountry = this.countryListArray[this.countryListArray.findIndex((i) => i.code.toLowerCase() === "ind".toLowerCase())]
    if (props.state) {
      action = props.state.action;
      pageId = props.state.pageId;
    }
    if (action && Utils.equalsIgnoreCase(action, "create")) {
      this.state = {
        id: "",
        line: "",
        country: defaultCountry,
        state: null,
        city: "",
        zipCode: "",
        latitude: "",
        longitude: "",
        pageId: pageId,
        action: this.props.action,
        onChange: this.props.onChange,
        render: true,
      };
      this.handleCountryChange(defaultCountry)
    } else {
      if (address) {
        this.state = {
          id: address.id ? address.id : "",
          line: address.line ? address.line : "",
          state: address.state ? address.state : "",
          selectedState: address.state ? address.state : "",
          country: address.state ? address.state.country : "",
          city: address.city ? address.city : "",
          zipCode: address.zipcode ? address.zipcode : "",
          latitude: address.latitude ? address.latitude : "",
          longitude: address.longitude ? address.longitude : "",
          stateClear: false,
          action: this.props.action,
          onChange: this.props.onChange,
          render: true,
        };
        this.handleCountryChange(address.state.country)
      } else {
        this.state = {
          id: "",
          line: "",
          country: defaultCountry,
          state: null,
          city: "",
          zipCode: "",
          latitude: "",
          longitude: "",
          pageId: pageId,
          action: this.props.action,
          onChange: this.props.onChange,
          render: true,
        };
        this.handleCountryChange(defaultCountry)
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props?.data?.state !== this.state.state) {
      this.setState({
        render: true,
        address: props?.data,
        state: props?.data?.state,
        line: props?.data?.line1 ? props?.data?.line1 : props?.data?.line,
        city: props?.data?.city,
        zipCode: props?.data?.pin ? props?.data?.pin : props?.data?.zipcode,
        latitude: props?.data?.latitude,
        longitude: props?.data?.longitude,
      });
    }
  }

  setStatesForCountry(selectedCountry) {
    this.setState({
      country: selectedCountry,
      selectedCountry: selectedCountry,
    });
  }

  handleStateChange(selectedState) {
    this.state.state = selectedState;
    this.setState({ state: selectedState });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
  }

  handleLine1Change(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ line: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLine2Change(e) {
    this.setState({ line2: e.target.value });
  }

  handleCityChange(e) {
    let val = Utils.toCamelCase(e.target.value);
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ city: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handlePinCodeChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 16);
    if (res.length < 16) {
      this.setState({ zipCode: res });
    } else {
      Utils.maxFieldLength(16);
    }
  }
  handleLatitudeChange(e) {
    this.setState({ latitude: e.target.value });
  }
  handleLongitudeChange(e) {
    this.setState({ longitude: e.target.value });
  }

  handleCountryChange(selectedCountry) {
    if (null === selectedCountry || selectedCountry === "") {
      this.stateListArray = [];
      this.setState({
        stateClear: !this.state.stateClear,
      });
      return;
    }
    this.state.country = selectedCountry;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }

    this.setState({
      country: selectedCountry,
      state: null,
    });

    const postObject = {
      header: {},
      country: { id: selectedCountry?.id },
    };
    FetchServerData.callPostService("/countryms/get", postObject).then((output) => {
      if (output.status === "SUCCESS") {
        let stateTimezoneList = output.data;
        this.stateListArray = stateTimezoneList.states;
        this.setState({
          render: true,
        });
      } else {
      }
    });
  }
  validateAddress() {
    if (Utils.isNullOrEmpty(this.state.country)) {
      document.getElementById("country").focus();
      PtsAlert.error("Please Select Country");

      return false;
    }
    if (Utils.isNullOrEmpty(this.state.state)) {
      document.getElementById("state").focus();
      PtsAlert.error("Please Select State");
      return false;
    }
    if (Utils.isNullOrEmpty(this.state.timeZone)) {
      document.getElementById("timeZone").focus();
      PtsAlert.error("Please Select TimeZone");
      return false;
    }

    return true;
  }

  render() {
    // if (this.state.render === false) {
    //   return <div />;
    // } else {
    this.countryRef = React.createRef();
    this.stateRef = React.createRef();
    if (this.props.action === "create") {
      return this.createAddress();
    } else if (this.props.action === "edit") {
      return this.editAddress();
    } else {
      return this.viewAddress();
    }
    // }
  }
  editAddress() {
    return (
      <>
        {undefined !== this.props.title && this.props.title.length > 0 && (
          <Row>
            <Col>
              <Label
                style={{
                  fontWeight: "600",
                  color: "#1b7189",
                  marginLeft: "0.5em",
                }}
              >
                {this.props.title}
              </Label>
            </Col>
          </Row>
        )}
        <div className="row mb-4">
          <div className="col-md-4" id="assetFormLabel">
            <label className="form-label" htmlFor="collapsible-companyName">
              Line
            </label>
            <TextField
              type="text"
              className="textField mt-1"
              fullWidth
              id="basic-default-line"
              variant="outlined"
              placeholder="Line"
              value={this.state.line}
              onChange={this.handleLine1Change.bind(this)}
            />
          </div>
          <div className="col-md-4" id="assetFormLabel">
            <label className="form-label" htmlFor="collapsible-fullname">
              Country
              <span className="asterisk"> *</span>
            </label>
            <Autocomplete
              size="small"
              id="country"
              options={this.countryListArray}
              value={this.state.country}
              defaultValue={this.state.select}
              getOptionLabel={(option) => option.namecode}
              onChange={(event, value) => {
                this.handleCountryChange(value);
              }}
              inputprops={{ style: { textTransform: "capitalize" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Country *"
                  variant="outlined"
                  required
                />
              )}
            />
          </div>
          <div className="col-md-4" id="assetFormLabel">
            <label className="form-label" htmlFor="collapsible-fullname">
              State
              <span className="asterisk"> *</span>
            </label>
            <Autocomplete
              id="country"
              options={this.stateListArray}
              value={this.state.state}
              getOptionLabel={(option) =>
                option.namecode ? option.namecode : option.name
              }
              defaultValue={this.state.state}
              onChange={(event, value) => {
                this.handleStateChange(value);
              }}
              inputprops={{ style: { textTransform: "capitalize" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="State *"
                  variant="outlined"
                  size="small"
                  className="textField"
                  required
                />
              )}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-4" id="assetFormLabel">
            <label className="form-label" htmlFor="collapsible-fullname">
              City
            </label>
            <TextField
              type="text"
              className="textField mt-1"
              fullWidth
              id="basic-default-city"
              variant="outlined"
              placeholder="City"
              value={this.state.city}
              onChange={this.handleCityChange.bind(this)}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="city"
              inputprops={{
                maxLength: 128,
                style: { textTransform: "capitalize" },
              }}
            />
          </div>
          <div className="col-md-4" id="assetFormLabel">
            <label className="form-label" htmlFor="collapsible-fullname">
              Postal Code
            </label>
            <TextField
              type="text"
              className="textField mt-1"
              fullWidth
              id="basic-default-postalcode"
              variant="outlined"
              placeholder="Postal Code"
              value={this.state.zipCode}
              onChange={this.handlePinCodeChange.bind(this)}
              inputprops={{ maxLength: 16 }}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="zipCode"
            />
          </div>
        </div>
        {this.props.pageId === "Geolocation" ?
          <>
            <hr></hr>
            <div
              className="card-header d-flex align-items-center justify-content-between"
              id="assetCardHeader"
              style={{ marginLeft: "-22px" }}
            >
              <h5 className="mb-0" id="headerLabel">
                Geolocation
              </h5>
            </div>
            <div className="row mb-4">
              <div className="col-md-4" id="assetFormLabel">
                <label className="form-label" htmlFor="collapsible-fullname">
                  Latitude
                </label>
                <TextField
                  type="number"
                  className="textField mt-1"
                  fullWidth
                  id="basic-default-postalcode"
                  variant="outlined"
                  placeholder="latitude"
                  value={this.state.latitude}
                  onChange={this.handleLatitudeChange.bind(this)}
                  inputprops={{ maxLength: 16 }}
                  onBlur={() => {
                    this.props.onChange(this.state);
                  }}
                  name="latitude"
                />
              </div>
              <div className="col-md-4" id="assetFormLabel">
                <label className="form-label" htmlFor="collapsible-fullname">
                  Longitude
                </label>
                <TextField
                  type="number"
                  className="textField mt-1"
                  fullWidth
                  id="basic-default-postalcode"
                  variant="outlined"
                  placeholder="longitude"
                  value={this.state.longitude}
                  onChange={this.handleLongitudeChange.bind(this)}
                  inputprops={{ maxLength: 16 }}
                  onBlur={() => {
                    this.props.onChange(this.state);
                  }}
                  name="longitude"
                />
              </div>
            </div>
          </> : ""
        }
      </>
    );
  }

  viewAddress() {
    let stateName =
      null !== this.state.state && undefined !== this.state.state
        ? this.state.state.name
        : "";
    let countryName =
      null !== this.state.country && undefined !== this.state.country
        ? this.state.country.name
        : "";
    let statecountryName = stateName + "," + countryName;
    return (
      <>
        <Row md={12} className="addressRow">
          <Col>
            <Label style={{ color: "#000" }}>
              {undefined !== this.state.line ? this.state.line : ""}
            </Label>
          </Col>
        </Row>
        <Row className="addressRow">
          <Col>
            <Label style={{ color: "#000" }}>
              {undefined !== this.state.city
                ? Utils.toCamelCase(this.state.city)
                : ""}
            </Label>
          </Col>
        </Row>
        <Row className="addressRow">
          <Col>
            <Label style={{ color: "#000" }}>{statecountryName} </Label>
          </Col>
        </Row>
        <Row className="addressRow">
          <Col>
            <Label style={{ color: "#000" }}>{this.state.zipCode}</Label>
          </Col>
        </Row>
      </>
    );
  }

  createAddress() {
    return (
      <>
        {undefined !== this.props.title && this.props.title.length > 0 && (
          <Row>
            <Col>
              <Label
                style={{
                  fontWeight: "600",
                  color: "#1b7189",
                  marginLeft: "0.5em",
                }}
              >
                {this.props.title}
              </Label>
            </Col>
          </Row>
        )}
        <div className="row mb-4">
          <div className="col-md-4" id="assetFormLabel">
            <label className="form-label" htmlFor="collapsible-companyName">
              Line
            </label>
            <TextField
              type="text"
              className="textField mt-1"
              fullWidth
              id="basic-default-line"
              variant="outlined"
              placeholder="Line"
              value={this.state.line}
              onChange={this.handleLine1Change.bind(this)}
            />
          </div>
          <div className="col-md-4" id="assetFormLabel">
            <label className="form-label" htmlFor="collapsible-fullname">
              Country
              <span className="asterisk"> *</span>
            </label>
            <Autocomplete
              size="small"
              id="country"
              options={this.countryListArray}
              value={this.state.country}
              defaultValue={this.state.select}
              getOptionLabel={(option) => option.namecode}
              onChange={(event, value) => {
                this.handleCountryChange(value);
              }}
              inputprops={{ style: { textTransform: "capitalize" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Country *"
                  variant="outlined"
                  required
                />
              )}
            />
          </div>
          <div className="col-md-4" id="assetFormLabel">
            <label className="form-label" htmlFor="collapsible-fullname">
              State
              <span className="asterisk"> *</span>
            </label>
            <Autocomplete
              id="country"
              options={this.stateListArray}
              value={this.state.state}
              getOptionLabel={(option) =>
                option.namecode ? option.namecode : option.name
              }
              defaultValue={this.state.state}
              onChange={(event, value) => {
                this.handleStateChange(value);
              }}
              inputprops={{ style: { textTransform: "capitalize" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="State *"
                  variant="outlined"
                  size="small"
                  className="textField"
                  required
                />
              )}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-4" id="assetFormLabel">
            <label className="form-label" htmlFor="collapsible-fullname">
              City
            </label>
            <TextField
              type="text"
              className="textField mt-1"
              fullWidth
              id="basic-default-city"
              variant="outlined"
              placeholder="City"
              value={this.state.city}
              onChange={this.handleCityChange.bind(this)}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="city"
              inputprops={{
                maxLength: 128,
                style: { textTransform: "capitalize" },
              }}
            />
          </div>
          <div className="col-md-4" id="assetFormLabel">
            <label className="form-label" htmlFor="collapsible-fullname">
              Postal Code
            </label>
            <TextField
              type="text"
              className="textField mt-1"
              fullWidth
              id="basic-default-postalcode"
              variant="outlined"
              placeholder="Postal Code"
              value={this.state.zipCode}
              onChange={this.handlePinCodeChange.bind(this)}
              inputprops={{ maxLength: 16 }}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="zipCode"
            />
          </div>
        </div>
        {this.props.pageId === "Geolocation" ?
          <>
            <hr></hr>
            <div
              className="card-header d-flex align-items-center justify-content-between"
              id="assetCardHeader"
              style={{ marginLeft: "-22px" }}
            >
              <h5 className="mb-0" id="headerLabel">
                Geolocation
              </h5>
            </div>
            <div className="row mb-4">
              <div className="col-md-4" id="assetFormLabel">
                <label className="form-label" htmlFor="collapsible-fullname">
                  Latitude
                </label>
                <TextField
                  type="number"
                  className="textField mt-1"
                  fullWidth
                  id="basic-default-postalcode"
                  variant="outlined"
                  placeholder="latitude"
                  value={this.state.latitude}
                  onChange={this.handleLatitudeChange.bind(this)}
                  inputprops={{ maxLength: 16 }}
                  onBlur={() => {
                    this.props.onChange(this.state);
                  }}
                  name="latitude"
                />
              </div>
              <div className="col-md-4" id="assetFormLabel">
                <label className="form-label" htmlFor="collapsible-fullname">
                  Longitude
                </label>
                <TextField
                  type="number"
                  className="textField mt-1"
                  fullWidth
                  id="basic-default-postalcode"
                  variant="outlined"
                  placeholder="longitude"
                  value={this.state.longitude}
                  onChange={this.handleLongitudeChange.bind(this)}
                  inputprops={{ maxLength: 16 }}
                  onBlur={() => {
                    this.props.onChange(this.state);
                  }}
                  name="longitude"
                />
              </div>
            </div>
          </> : ""
        }
      </>
    );
  }
}

export default AddressWithTimezone;
