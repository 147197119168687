import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Col, Row } from "reactstrap";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import Organization from "./organization/Organization";
import UnitOfMeasurementList from "./unitofmeasurement/UnitOfMeasurementList";
import AssetCodeMasterList from "./assetCodeMaster/AssetCodeMasterList";
import store from "../../redux/store";
import "./Settings.css";
import ApplicationStateAction from "../../redux/applicationState/ApplicationStateAction";
import ProfileMgmntList from "./profileManagement/ProfileMgmntList";
class Settings extends Component {
  menuList = store.getState().menus.menuList;
  profileState = store.getState().applicationState.profileState;
  breadCrumb = [{ pageid: "", uiname: "Settings" }];
  profileStateDetails = {};
  constructor(props) {
    super(props);
    if (this.profileState) {
      this.profileStateDetails = Object.getOwnPropertyNames(this.profileState);
    }
    store.dispatch(ApplicationStateAction.setApplicationState({}));
    if (this.profileStateDetails.length > 0) {
      this.state = {
        selectedTab: this.profileState.selectedTab,
        childMenus: this.menuList.filter((menu) => menu.menuid === "Settings")[0].childMenus.sort((a, b) => a.displaysequence - b.displaysequence),
      };
    } else {
      this.state = {
        selectedTab: 0,
        childMenus: this.menuList.filter((menu) => menu.menuid === "Settings")[0].childMenus.sort((a, b) => a.displaysequence - b.displaysequence),
      }
    }
  }
  setKey = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  render() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject={this.state.childMenus[this.state.selectedTab].uiname}
          name={this.state.childMenus[this.state.selectedTab].uiname}
        />
        <Row>
          <Col
            md={12}
            className="settingTabRow"
          >
            <Tabs
              defaultIndex={this.state.selectedTab}
              onSelect={(k) => this.setKey(k)}
              className="settingTab"
            >
              <TabList style={{ listStyle: "none" }} className="text-start d-flex justify-content-start settingTabList ps-0">
                {this.state.childMenus.map((d, index) =>
                  d.isactive === true ?
                    <Tab className="tabSetting hoverTab" key={index}>{d.uiname}</Tab>
                    : <div></div>
                )}
              </TabList>
              <TabPanel><Organization action="view" /></TabPanel>
              <TabPanel><UnitOfMeasurementList /></TabPanel>
              <TabPanel><AssetCodeMasterList /></TabPanel>
              <TabPanel><ProfileMgmntList selectedTab={this.state.selectedTab} profileState={this.profileState} /></TabPanel>
            </Tabs>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(Settings);
