import React, { Component } from "react";
// import logo from "../../assets/images/Asset tracking logo.svg";
import "./assets/css/fontawesome.css";
import "./assets/css/templatemo-space-dynamic.css";
import "./assets/css/animated.css";
import HomePage from "./assets/images/HomePage.jpg";
// import portfolioImage from "./assets/images/portfolio-image.png";
import automatedCheckIn from "./assets/images/AutomatedCheckIn.jpg";
import lifecycle from "./assets/images/Lifecycle.jpg";
import OnTheGoConvinience from "./assets/images/OnTheGoConvinience.jpg";
import multipleLocations from "./assets/images/multipleLocations1.jpg";
import aboutUs from "./assets/images/aboutUs.jpg";
// import serviceIcon from "./assets/images/service-icon-01.png";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Container } from "react-floating-action-button";
import Fab from "@material-ui/core/Fab";
import logo from "../../assets/images/AssetTrackifierLogo.png";
import GooglePlayStore from "../../assets/images/googlePlayStore.png";
import "./assets/css/owl.css";
import "./Home.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { withRouter } from "../../withRouter";
import FetchServerData from "../../provider/FetchServerData";
import PtsAlert from "../ptsAlert/PtsAlert";
import { Col, Modal, ModalBody, Row } from "reactstrap";
// import CloseIcon from '@material-ui/icons/Close';
// import zIndex from "@material-ui/core/styles/zIndex";
// import "./vendor/bootstrap/css/bootstrap.min.css";
class Home extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      email: "",
      name: "",
      phone: "",
      yourCompany: "",
      message: "",
      subject: "",
      activeElement: "",
      visible: false,
      menuList: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.toggleVisible);
    console.log(this.props);
    let scrollTo =
      this.props?.state !== null
        ? this.props?.state?.scrollTo
        : this.props.scrollTo;
    console.log(scrollTo);
    if (typeof scrollTo !== "undefined" || scrollTo !== null) {
      this.scrollToSection(scrollTo);
    }
  }
  navigateToLink(linkToNavigate) {
    this.props.navigate(linkToNavigate);
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  }

  handleCompanyChange(e) {
    this.setState({ yourCompany: e.target.value });
  }

  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  handleSubjectChange(e) {
    this.setState({ subject: e.target.value });
  }
  addContactToBackend(e) {
    const postObject = {
      name: this.state.name,
      email: this.state.email,
      companyName: this.state.yourCompany,
      message: this.state.message,
      phone: this.state.phone,
      subject: this.state.subject,
    };
    FetchServerData.callPostService("/enquiryms/sendEnquiry", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);

          this.setState({
            email: "",
            name: "",
            phone: "",
            yourCompany: "",
            message: "",
            subject: "",
          });
        } else {
        }
      }
    );
  }

  scrollToSection(elementID) {
    this.setState({
      activeElement: elementID,
    });
    if (document.getElementById(elementID)) {
      document.getElementById(elementID).scrollIntoView();
    }
  }

  toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      this.setState({
        visible: true,
      });
    } else if (scrolled <= 300) {
      this.setState({
        visible: false,
      });
    }
  };

  openMenu() {
    this.setState({
      menuList: true,
    });
  }
  menuListToggle = () => {
    this.setState({
      menuList: !this.state.menuList,
    });
  };
  render() {
    let url = window.location.href;
    let urlArr = url.split("/");
    let activeUrl = urlArr[3];
    return (
      <>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        {/* <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        /> */}
        <title>Space Dynamic - SEO HTML5 Template</title>
        {/* Bootstrap core CSS */}
        {/* <link href="vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" /> */}
        {/* Additional CSS Files */}
        {/* <link rel="stylesheet" href="assets/css/fontawesome.css" />
        <link rel="stylesheet" href="assets/css/templatemo-space-dynamic.css" />
        <link rel="stylesheet" href="assets/css/animated.css" />
        <link rel="stylesheet" href="assets/css/owl.css" /> */}
        {/*

TemplateMo 562 Space Dynamic

https://templatemo.com/tm-562-space-dynamic

*/}
        {/* ***** Preloader Start ***** */}
        {/* <div id="js-preloader" className="js-preloader">
          <div className="preloader-inner">
            <span className="dot" />
            <div className="dots">
              <span />
              <span />
              <span />
            </div>
          </div>
        </div> */}
        {/* ***** Preloader End ***** */}
        {/* ***** Header Area Start ***** */}
        <header
          className="header-area header-sticky wow slideInDown"
          data-wow-duration="0.75s"
          data-wow-delay="0s"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="main-nav">
                  {/* ***** Logo Start ***** */}
                  <a href="" className="logo">
                    <div className="row d-flex">
                      <div className="col-3 p-0 text-end">
                        <span
                          style={{
                            borderRadius: "70%",
                            backgroundColor: "#fff",
                            // height: "70px",
                            // width: "70px",
                          }}
                        >
                          <img
                            src={logo}
                            className="img-fluid"
                            alt=""
                            style={{
                              marginTop: "-5.5px",
                              width: "25px",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                            data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                            data-app-light-img="illustrations/girl-with-laptop-light.png"
                          />
                        </span>
                      </div>
                      <div className="col-9">
                        <h4>AssetTrackifier</h4>
                      </div>
                    </div>
                  </a>
                  {/* ***** Logo End ***** */}
                  {/* ***** Menu Start ***** */}
                  <ul className="nav" id="myDIV">
                    <li
                      className={
                        this.state.activeElement === "/" ||
                        this.state.activeElement === "hero" ||
                        this.state.activeElement === ""
                          ? this.state.homeActiveClass
                          : ""
                      }
                    >
                      <a
                        href="/#/"
                        style={{ color: "#fff", cursor: "pointer" }}
                        onClick={() => this.scrollToSection("hero")}
                      >
                        Home
                      </a>
                    </li>
                    <li
                      className={
                        this.state.activeElement === "about"
                          ? this.state.homeActiveClass
                          : ""
                      }
                    >
                      <a
                        href="/#/"
                        style={{ color: "#fff", cursor: "pointer" }}
                        onClick={() => this.scrollToSection("about")}
                      >
                        About
                      </a>
                    </li>
                    <li className="scroll-to-section">
                      <a
                        href="/#/"
                        className={
                          this.state.activeElement === "ourFeatures"
                            ? this.state.homeActiveClass
                            : ""
                        }
                        style={{ color: "#fff", cursor: "pointer" }}
                        onClick={() => this.scrollToSection("ourFeatures")}
                      >
                        Features
                      </a>
                    </li>
                    <li className="scroll-to-section">
                      <a
                        href="/#/"
                        className={
                          this.state.activeElement === "services"
                            ? this.state.homeActiveClass
                            : ""
                        }
                        style={{ color: "#fff", cursor: "pointer" }}
                        onClick={() => this.scrollToSection("services")}
                      >
                        Services
                      </a>
                    </li>
                    <li className="scroll-to-section">
                      <a
                        href="/#/"
                        className={
                          this.state.activeElement === "faq"
                            ? this.state.homeActiveClass
                            : ""
                        }
                        style={{ color: "#fff", cursor: "pointer" }}
                        onClick={() => this.scrollToSection("faq")}
                      >
                        FAQ
                      </a>
                    </li>
                    <li className="scroll-to-section">
                      <a
                        onClick={() => this.navigateToLink("/signinEmail")}
                        style={{ cursor: "pointer" }}
                      >
                        Sign In
                      </a>
                    </li>
                    <li className="scroll-to-section">
                      <a
                        onClick={() => this.navigateToLink("/signup")}
                        style={{ cursor: "pointer" }}
                      >
                        Sign Up
                      </a>
                    </li>
                    <li className="scroll-to-section">
                      <a
                        className={
                          this.state.activeElement === "contact"
                            ? this.state.homeActiveClass
                            : ""
                        }
                        style={{ color: "#fff", cursor: "pointer" }}
                        onClick={() => this.scrollToSection("contact")}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                  {/* <a className="menu-trigger"
                    onClick={(e) => this.openMenu()}
                  >
                    <span style={{ color: "#fff" }}>Menu</span>
                  </a> */}
                  <button
                    type="button"
                    className="mobile-nav-toggle d-lg-none text-end"
                    style={{ marginRight: "-5em", marginTop: "2em" }}
                    onClick={(e) => this.openMenu()}
                  >
                    <i className="fa fa-bars" style={{ fontSize: "1.5em" }}></i>
                  </button>
                  {/* ***** Menu End ***** */}
                </nav>
              </div>
            </div>
          </div>
        </header>
        {/* ***** Header Area End ***** */}
        <section id="hero">
          <div
            className="main-banner wow fadeIn"
            id="top"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6 align-self-center">
                      <div
                        className="left-content header-text wow fadeInLeft"
                        data-wow-duration="1s"
                        data-wow-delay="1s"
                      >
                        {/* <h6>Welcome to AssetTrackifier</h6> */}
                        <h4>
                          Manage your <em>Physical Properties </em> with
                          <span> AssetTrackifier</span> as per your
                          <em> Organisation Needs</em>
                        </h4>
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.pts.assettrak"
                          style={{ color: "#fff", cursor: "pointer" }}
                        >
                          <img
                            src={GooglePlayStore}
                            style={{ height: "50px", width: "150px" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="right-image wow fadeInRight"
                        data-wow-duration="1s"
                        data-wow-delay="0.5s"
                      >
                        <img
                          src={HomePage}
                          alt="team meeting"
                          style={{ width: "715px", height: "400px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="about" className="about-us section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4" style={{ margin: "auto" }}>
                <div
                  className="wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  {/* <img
                    src="assets/images/about-left-image.png"
                    alt="person graphic"
                  /> */}
                  <img
                    src={aboutUs}
                    alt="person graphic"
                    style={{ width: "490px", marginTop: "-35px" }}
                  />
                </div>
              </div>
              <div
                className="col-lg-8 align-self-center"
                style={{ paddingTop: "1em" }}
              >
                <div className="row">
                  <div className="col-lg-12">
                    <h3 className="aboutUsTitle">
                      <b>About Us</b>
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-12"
                    style={{ padding: "1em -0.5em 7em 7em" }}
                  >
                    <p
                      className="aboutUsp"
                      // style={{
                      //   fontSize: "18px",
                      //   lineHeight: "28px",
                      //   textAlign: "left",
                      // }}
                    >
                      We are a team of hard-working people who are expertise in
                      our fields. Our goal is to create a software that is easy
                      to use and accurately track assets in Real-Time, always.
                      The tracking and managing of assets are more than just the
                      provision of software and hardware. These assets can be
                      searched, planned, tracked, and traded as easily as any
                      online commodity. We provide the tools and all the support
                      to manage assets more efficiently. We help companies
                      receive, track, audit, and report on assets to make
                      informed decisions.{" "}
                    </p>
                  </div>
                  {/* <div className="col-lg-6">
                      <div
                        className="item wow fadeIn"
                        data-wow-duration="1s"
                        data-wow-delay="0.5s"
                      >
                        <div className="icon">

                          <img src={serviceIcon} alt="reporting" />
                        </div>
                        <div className="right-text">
                          <h4>Data Analysis</h4>
                          <p>
                            Lorem ipsum dolor sit amet, ctetur aoi adipiscing
                            eliter
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item wow fadeIn"
                        data-wow-duration="1s"
                        data-wow-delay="0.7s"
                      >
                        <div className="icon">
                          <img src={serviceIcon} alt="reporting" />
                        </div>
                        <div className="right-text">
                          <h4>Data Reporting</h4>
                          <p>
                            Lorem ipsum dolor sit amet, ctetur aoi adipiscing
                            eliter
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item wow fadeIn"
                        data-wow-duration="1s"
                        data-wow-delay="0.9s"
                      >
                        <div className="icon">
                          <img src={serviceIcon} alt="reporting" />
                        </div>
                        <div className="right-text">
                          <h4>Web Analytics</h4>
                          <p>
                            Lorem ipsum dolor sit amet, ctetur aoi adipiscing
                            eliter
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="item wow fadeIn"
                        data-wow-duration="1s"
                        data-wow-delay="1.1s"
                      >
                        <div className="icon">
                          <img src={serviceIcon} alt="reporting" />
                        </div>
                        <div className="right-text">
                          <h4>SEO Suggestions</h4>
                          <p>
                            Lorem ipsum dolor sit amet, ctetur aoi adipiscing
                            eliter
                          </p>
                        </div>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="ourFeatures" className="our-portfolio section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="ourFeaturesTitle">
                  <b>Features</b>
                </h3>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-3 mt-4">
                {/* <a href="#"> */}
                <div
                  className="showed-content"
                  style={{ background: "#58D68D" }}
                >
                  <h4>RFID/QR Scanning </h4>
                  <p>
                    Instantly update an asset in the field by scanning it's RFID
                    tag or QR code and entering the necessary information.
                  </p>
                </div>
                {/* </div> */}
                {/* </a> */}
              </div>
              <div className="col-sm-3 mt-4">
                {/* <a href="#"> */}
                <div
                // className="item wow bounceInUp"
                // data-wow-duration="1s"
                // data-wow-delay="0.4s"
                >
                  {/* <div className="hidden-content" id="oddBoxStyle">
                      <p>Real time Asset identification & information</p>
                    </div> */}
                  <div
                    className="showed-content"
                    style={{ background: "#BB8FCE" }}
                  >
                    {/* <img src={portfolioImage} alt="" /> */}
                    <h4>Real Time Data Reporting </h4>
                    <p>Real time Asset identification & information</p>
                  </div>
                </div>
                {/* </a> */}
              </div>
              <div className="col-sm-3 mt-4">
                {/* <a href="#"> */}
                <div
                // className="item wow bounceInUp"
                // data-wow-duration="1s"
                // data-wow-delay="0.5s"
                >
                  {/* <div className="hidden-content" id="evenBoxStyle">
                      <p>
                        Manage millions of assets with smartphones, tablets and
                        rugged handheld computers.
                      </p>
                    </div> */}
                  <div
                    className="showed-content"
                    style={{ background: "#EC7063" }}
                  >
                    {/* <img src={portfolioImage} alt="" /> */}
                    <h4>Best In class Services </h4>
                    <p>
                      Manage millions of assets with smartphones, tablets and
                      rugged handheld computers.
                    </p>
                  </div>
                </div>
                {/* </a> */}
              </div>
              <div className="col-sm-3 mt-4">
                {/* <a href="#"> */}
                <div
                // className="item wow bounceInUp"
                // data-wow-duration="1s"
                // data-wow-delay="0.6s"
                >
                  {/* <div className="hidden-content" id="oddBoxStyle">
                      <p>
                        Asset Tracking helps in maintenance and streamlining
                        your entire asset life cycle.
                      </p>
                    </div> */}
                  <div
                    className="showed-content"
                    style={{ background: "#F7DC6F" }}
                  >
                    {/* <img src={portfolioImage} alt="" /> */}
                    <h4>Full time Support </h4>
                    <p>
                    Customer wise Stock helps in maintenance and streamlining your
                      entire asset life cycle.
                    </p>
                  </div>
                </div>
                {/* </a> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 mt-4">
                {/* <a href="#"> */}
                <div
                  className="showed-content"
                  style={{ background: "#85C1E9" }}
                >
                  <h4>Customisable </h4>
                  <p>Easily customisable as per your Organisation needs.</p>
                </div>
                {/* </div> */}
                {/* </a> */}
              </div>
              <div className="col-lg-3 mt-4">
                {/* <a href="#"> */}
                <div
                  className="showed-content"
                  style={{ background: "#EB984E" }}
                >
                  {/* <img src={portfolioImage} alt="" /> */}
                  <h4>Safe and Secure </h4>
                  <p>
                    Protect and secure files with advanced privacy settings.
                  </p>
                </div>
                {/* </div> */}
                {/* </a> */}
              </div>
              <div className="col-lg-3 mt-4">
                {/* <a href="#"> */}
                <div
                  className="showed-content"
                  style={{ background: "#ABB2B9" }}
                >
                  {/* <img src={portfolioImage} alt="" /> */}
                  <h4>Complete Visibility </h4>
                  <p>
                    Obtain detailed history of asset location, status and
                    condition.
                  </p>
                </div>
                {/* </div> */}
                {/* </a> */}
              </div>
              <div className="col-lg-3 mt-4">
                {/* <a href="#"> */}
                <div
                  className="showed-content"
                  style={{ background: "#6dcece" }}
                >
                  <h4>Theft Protection </h4>
                  <p>
                    Know entire asset life cycle to avoid theft or missing of
                    assets.
                  </p>
                </div>
                {/* </div> */}
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>
        <div id="services" className="our-services section">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 align-self-center wow fadeInLeft"
                data-wow-duration="0.5s"
                data-wow-delay="0.25s"
              >
                <h3>Automate Check-In/Check-Out and Expedite Audits</h3>
                <br />
                <p>
                  Easily track all assets in your facilities with
                  AssetTrackifier. Track where your assets are, who has your
                  assets, and when your assets are due back. Checking out assets
                  allows you to assign responsibility to an employee, a
                  customer, or even a vendor. Checking in assets releases end
                  user responsibility and allows the assets to be reallocated
                  throughout your organization.
                </p>
              </div>{" "}
              <div
                className="col-lg-6 wow fadeInRight"
                data-wow-duration="0.5s"
                data-wow-delay="0.25s"
              >
                <img
                  src={automatedCheckIn}
                  alt=""
                  style={{ width: "440px", height: "410px" }}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-6 align-self-center wow fadeInLeft"
                data-wow-duration="0.5s"
                data-wow-delay="0.25s"
              >
                <img
                  src={lifecycle}
                  alt=""
                  style={{ width: "440px", height: "410px" }}
                />
              </div>{" "}
              <div
                className="col-lg-6 wow fadeInRight"
                data-wow-duration="0.5s"
                data-wow-delay="0.25s"
                style={{ margin: "auto" }}
              >
                <h3> End-to-End Asset Lifecycle Management</h3>
                <br />
                <p>
                  Get the most out of your equipment with complete lifecycle
                  management. Manage all aspects of an asset’s life cycle -
                  Track condition and location of your assets. Link assets to
                  borrower and track movement. Manage asset maintenance records.
                  View activity logs and reports.
                </p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-6 align-self-center wow fadeInLeft"
                data-wow-duration="0.5s"
                data-wow-delay="0.25s"
              >
                <h3>On-The-Go Convenience With Mobile App</h3>
                <br />
                <p>
                  Use the mobile app to easily check-in, check-out, and service
                  items across multiple locations. Real-time sync with the cloud
                  ensures that you are always accessing up-to-date information.
                  Scanning makes these actions even easier, as the app is
                  integrated with QR code and RFID systems.
                </p>
              </div>{" "}
              <div
                className="col-lg-6 wow fadeInRight"
                data-wow-duration="0.5s"
                data-wow-delay="0.25s"
                style={{ margin: "auto" }}
              >
                <img
                  src={OnTheGoConvinience}
                  alt=""
                  style={{ width: "460px", height: "415px" }}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-6 align-self-center wow fadeInLeft"
                data-wow-duration="0.5s"
                data-wow-delay="0.25s"
              >
                <img
                  src={multipleLocations}
                  alt=""
                  style={{ width: "470px", height: " 430px" }}
                />
              </div>{" "}
              <div
                className="col-lg-6 wow fadeInRight"
                data-wow-duration="0.5s"
                data-wow-delay="0.25s"
                style={{ margin: "auto" }}
              >
                <h3>Manage Assets Across Multiple Locations</h3>
                <br />
                <p>
                  Managing assets across multiple job sites, warehouses, and
                  office locations made easy with AssetTrackifier. View a map of
                  all your locations that have assets. Unlimited
                  location-nesting enables efficient tracking of assets down to
                  the last cabinet or shelf.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="faq" className="our-services section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="faqTitle">
                  <b>FREQUENTLY ASKED QUESTIONS</b>
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Accordion allowZeroExpanded className="fccFaqHeading">
                  <AccordionItem>
                    <AccordionItemHeading className="faqHeading">
                      <AccordionItemButton>
                        Why should I track my assets?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="faqPanel">
                      Asset tracking helps you get rid of the need for employees
                      to track down assets manually, so with appropriate asset
                      management, the administrative costs of your business will
                      be lowered.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading className="faqHeading">
                      <AccordionItemButton>
                        What is the need of Mobile App?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="faqPanel">
                      Mobile app helps scan RFID/QR codes to review and create
                      asset history, check in and out, or update statuses
                      including location and perform maintenance.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading className="faqHeading">
                      <AccordionItemButton>
                        Are multiple Users allowed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="faqPanel">
                      Yes. Admin User can assign roles to Users and depending on
                      the roll, User can access information.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading className="faqHeading">
                      <AccordionItemButton>
                        Can I use this system offline?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="faqPanel">
                      This system depends on an internet connection. But, system
                      can still store a list of your assets and gets uploaded
                      when you're connected to the internet.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading className="faqHeading">
                      <AccordionItemButton>
                        Is this system Secure?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="faqPanel">
                      The personal information that you provide to us is stored
                      on servers with restricted access and protected by
                      protocols and procedures designed to ensure the security
                      of such information.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading className="faqHeading">
                      <AccordionItemButton>
                        If my asset is lost, Can I find it?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="faqPanel">
                      You will have the complete life cycle of asset. The last
                      entered location and status of asset will let you know the
                      asset whereabouts.
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div id="contact" className="contact-us section">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 align-self-center wow fadeInLeft"
                data-wow-duration="0.5s"
                data-wow-delay="0.25s"
              >
                <div className="section-heading">
                  <h2>Feel Free To Send Us a Message</h2>

                  {/* <div className="phone-info">
                    <h4>
                      For any enquiry, Call Us:{" "}
                      <span>
                        <i className="fa fa-phone" />{" "}
                        <a href="#">010-020-0340</a>
                      </span>
                    </h4>
                  </div> */}
                </div>
              </div>
              <div
                className="col-lg-6 wow fadeInRight"
                data-wow-duration="0.5s"
                data-wow-delay="0.25s"
                id="contactForm"
              >
                <ValidatorForm
                  ref="form"
                  onSubmit={this.addContactToBackend.bind(this)}
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <TextField
                        margin="normal"
                        variant="outlined"
                        type="text"
                        fullWidth
                        required
                        id="name"
                        onChange={this.handleNameChange.bind(this)}
                        placeholder="Your Name*"
                        name="name"
                        autoComplete="off"
                        value={this.state.name}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <TextValidator
                        margin="normal"
                        variant="outlined"
                        type="email"
                        fullWidth
                        required
                        id="email"
                        onChange={this.handleEmailChange.bind(this)}
                        placeholder="Email*"
                        name="email"
                        autoComplete="off"
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "this field is required",
                          "email is not valid",
                        ]}
                        value={this.state.email}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <TextValidator
                        margin="normal"
                        variant="outlined"
                        type="text"
                        required
                        fullWidth
                        id="phone"
                        onChange={this.handlePhoneChange.bind(this)}
                        // label="Phone
                        placeholder="Phone*"
                        name="phone"
                        validators={["required", "isNumber"]}
                        errorMessages={[
                          "this field is required",
                          "phone is not valid",
                        ]}
                        autoComplete="off"
                        value={this.state.phone}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <TextField
                        margin="normal"
                        variant="outlined"
                        type="text"
                        fullWidth
                        autoComplete="off"
                        id="yourCompany"
                        onChange={this.handleCompanyChange.bind(this)}
                        placeholder="Your Company"
                        name="yourCompany"
                        value={this.state.yourCompany}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <TextField
                        margin="normal"
                        variant="outlined"
                        type="text"
                        fullWidth
                        id="subject"
                        autoComplete="off"
                        required
                        onChange={this.handleSubjectChange.bind(this)}
                        placeholder="Subject*"
                        name="subject"
                        value={this.state.subject}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      margin="normal"
                      variant="outlined"
                      style={{
                        width: "100%",
                        padding: "1em",
                        border: "1px solid lightgray",
                        borderRadius: " 5px",
                      }}
                      value={this.state.message}
                      name="message"
                      autoComplete="off"
                      fullWidth
                      onChange={this.handleMessageChange.bind(this)}
                      placeholder="Message"
                    />
                  </div>

                  <div className="text-center" style={{ marginTop: "1em" }}>
                    <Button
                      type="submit"
                      style={{
                        background: "#0b6f9d",
                        borderRadius: "5px",
                        border: "none",
                        color: "#fff",
                        textTransform: "capitalize",
                      }}
                    >
                      Send Message
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
        <Container id="scroll">
          <a
            href="/#/"
            onClick={() => this.scrollToSection("hero")}
            style={{
              display: this.state.visible ? "inline" : "none",
              textDecoration: "none",
            }}
          >
            <Fab
              aria-label="add"
              className="foatingActionBtn"
              style={{
                float: "right",
                fontSize: "small",
                height: "42px",
                width: "42px",
                background: "#0B6F9D",
                cursor: "pointer",
                color: "white",
                backgroundImage:
                  "-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);",
                border: "2px solid #edf0f1",
                margin: "0em 3em",
                position: "absolute",
              }}
            >
              <ArrowUpwardIcon />
            </Fab>{" "}
          </a>
        </Container>
        <Modal
          isOpen={this.state.menuList}
          toggle={this.menuListToggle}
          size="sm"
          style={{
            alignContent: "center",
            width: "100%",
            marginLeft: "0px",
            marginTop: "80px",
          }}
        >
          {/* <Row>
            <Col md={12}>
              <CloseIcon
                onClick={(e) => this.menuListToggle()}
                style={{ float: 'right', margin: '0.5em 1em 0em 0em' }}
              />
            </Col>
          </Row> */}
          <ModalBody style={{ backgroundColor: "#0B6F9D", color: "#fff" }}>
            <nav className="mobileViewNav">
              <ul>
                <li
                  className={
                    this.state.activeElement === "/" ||
                    this.state.activeElement === "hero" ||
                    this.state.activeElement === ""
                      ? this.state.homeActiveClass
                      : ""
                  }
                >
                  <a
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={() => this.scrollToSection("hero")}
                  >
                    Home
                  </a>
                </li>
                <li className="scroll-to-section pb-2">
                  <a
                    className={
                      this.state.activeElement === "about"
                        ? this.state.homeActiveClass
                        : ""
                    }
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={() => this.scrollToSection("about")}
                  >
                    About Us
                  </a>
                </li>
                <li className="scroll-to-section pb-2">
                  <a
                    className={
                      this.state.activeElement === "ourFeatures"
                        ? this.state.homeActiveClass
                        : ""
                    }
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={() => this.scrollToSection("ourFeatures")}
                  >
                    Features
                  </a>
                </li>
                <li className="scroll-to-section pb-2">
                  <a
                    className={
                      this.state.activeElement === "services"
                        ? this.state.homeActiveClass
                        : ""
                    }
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={() => this.scrollToSection("services")}
                  >
                    Services
                  </a>
                </li>
                <li className="scroll-to-section pb-2">
                  <a
                    className={
                      this.state.activeElement === "faq"
                        ? this.state.homeActiveClass
                        : ""
                    }
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={() => this.scrollToSection("faq")}
                  >
                    FAQ
                  </a>
                </li>
                <li className="scroll-to-section pb-2">
                  <a
                    onClick={() => this.navigateToLink("/signinEmail")}
                    style={{ cursor: "pointer" }}
                  >
                    Sign In
                  </a>
                </li>
                <li className="scroll-to-section pb-2">
                  <a
                    onClick={() => this.navigateToLink("/signup")}
                    style={{ cursor: "pointer" }}
                  >
                    Sign Up
                  </a>
                </li>
                <li className="scroll-to-section">
                  <a
                    className={
                      this.state.activeElement === "contact"
                        ? this.state.homeActiveClass
                        : ""
                    }
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={() => this.scrollToSection("contact")}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </nav>
          </ModalBody>
        </Modal>
        <footer style={{ background: "#000", padding: "30px 0px" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4" style={{ margin: "auto" }}>
                <div
                  className="copyright"
                  style={{ fontSize: "12px", color: "#fff" }}
                >
                  <span
                    style={{ fontSize: "14px", color: "rgb(11, 111, 157)" }}
                  >
                    <b>Product of Proto Technology Solutions</b>
                  </span>
                  <br></br>
                  <b>
                    <a
                      href="http://www.prototsolutions.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.prototsolutions.com
                    </a>
                  </b>
                </div>
              </div>
              <div className="col-md-4" style={{ margin: "auto" }}>
                <div
                  className="copyright mt-3"
                  style={{ fontSize: "12px", color: "#fff" }}
                >
                  <span>Copyright © 2023. All rights reserved.</span> &nbsp;
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="copyright"
                  style={{ fontSize: "14px", color: "#fff" }}
                >
                  <a
                    onClick={() => this.navigateToLink("/termsConditions")}
                    style={{ color: "#fff", cursor: "pointer" }}
                  >
                    {" "}
                    Terms &amp; Conditions
                  </a>{" "}
                  &nbsp;
                  <a
                    onClick={() => this.navigateToLink("/privacyPolicy")}
                    style={{ color: "#fff", cursor: "pointer" }}
                  >
                    {" "}
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* Scripts */}
      </>
    );
  }
}

export default withRouter(Home);
