import React, { Component } from 'react'
import { withRouter } from "../../../withRouter";
import { MenuWithBreadScrum } from "../../menu/MenuWithBreadScrum";
import { NavLink } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import ReactApexCharts from 'react-apexcharts';
import store from '../../../redux/store';

class SupplierReportAnalysis extends Component {
	header = store.getState().header.header;
	breadCrumb = [
		{ pageid: "/reports", uiname: "MIS Reports" },
		{ pageid: "", uiname: "Supplier Analytics" }
	];

	constructor() {
		super();
		this.state = {
			render: false,
			activeSupplier: 0,
			inActiveSupplier: 0,
			series: null,
			options: null,
			supplierOptions: null,
			supplierSeries: null,
			registeredSupplierSeries: null,
			registeredSupplierOptions: null,

		};
		this.getSupplierAnalysisReport();
	}

	getSupplierAnalysisReport() {
		const postObject = {
			header: this.header,
		};

		FetchServerData.callPostService('/supplierAnalyticms/get', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					let supplierAnalysisDetails = output.data;
					let activeSupplier = supplierAnalysisDetails.activeSuppliers;
					let inActiveSupplier = supplierAnalysisDetails.inActiveSuppliers;
					let supplierChartData = supplierAnalysisDetails.supplierAssetList;
					let registeredSuppliers = supplierAnalysisDetails.registeredSuppliers;
					let unRegisteredSuppliers = supplierAnalysisDetails.unregisteredSuppliers;

					let assetLabels = [];
					let assetValues = [];

					for (let i = 0; i < supplierChartData.length; i++) {
						let date = supplierChartData[i].supplierName;
						assetLabels.push(date);
						assetValues.push(supplierChartData[i].count);
					}

					this.setState({
						render: true,
						supplierChartData: supplierChartData,
						series: [activeSupplier, inActiveSupplier],
						options: {
							colors: ['rgb(0, 227, 150)', '#F44336',],
							labels: ["Active Suppliers", "InActive Suppliers"],
							plotOptions: {
								pie: {
									donut: {
										labels: {
											show: true,
											total: {
												show: true,
												showAlways: true,
												fontSize: "18px",
												color: "black"
											}
										},
									}
								}
							},
							chart: {
								type: 'donut',
							},

							responsive: [{
								breakpoint: 480,
								options: {
									chart: {
										width: 200
									},
									legend: {
										position: 'bottom'
									}
								}
							}],
						},
						registeredSupplierSeries: [registeredSuppliers, unRegisteredSuppliers],
						registeredSupplierOptions: {
							colors: ['rgb(0, 227, 150)', '#FF9800',],
							labels: ["Registered Supplier", "UnRegistered Supplier"],
							chart: {
								type: 'pie',
							},

							responsive: [{
								breakpoint: 480,
								options: {
									chart: {
										width: 200
									},
									legend: {
										position: 'bottom'
									}
								}
							}],
						},
						supplierSeries: [
							{
								name: 'Assets',
								type: 'bar',
								data: assetValues
							},
						],
						supplierOptions: {
							chart: {
								type: 'bar',
								height: 430,
								toolbar: {
									show: true,
									tools: {
										download: false
									}
								}
							},
							colors: ['#2E93fA', '#0091B1'],
							plotOptions: {
								bar: {
									horizontal: false,
									columnWidth: '25%',
									dataLabels: {
										position: 'top',
									},
								}
							},
							grid: {
								show: true,
								xaxis: {
									lines: {
										show: false
									}
								},
								yaxis: {
									lines: {
										show: false
									}
								},
							},
							dataLabels: {
								enabled: true,
								// offsetX: -6,
								style: {
									fontSize: '12px',
									colors: ['#fff'],
									align: 'center',
								}
							},
							stroke: {
								show: true,
								width: 1,
								colors: ['#fff']
							},
							tooltip: {
								shared: true,
								intersect: false
							},
							xaxis: {
								categories: assetLabels,
								labels: {
									style: {
										colors: '#247BA0',
									},
								},
								title: {
									text: 'Suppliers',
									style: {
										color: '#247BA0',
										fontSize: "14px"
									},
								},
								axisTicks: {
									show: true,
								},
								axisBorder: {
									show: true,
									color: '#247BA0',
								},
							},
							yaxis: [
								{
									axisTicks: {
										show: true,
									},
									axisBorder: {
										show: true,
										color: '#247BA0',
									},
									labels: {
										formatter: function (val) {
											return val.toFixed(0);
										},
										style: {
											colors: '#247BA0',
										},
									},
									title: {
										text: 'Assets',
										style: {
											color: '#247BA0',
											fontSize: "15px"
										},
									},
								},
							],
						},
					});
				} else {
				};
			});
	};
	render() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<>
					<MenuWithBreadScrum
						breadCrumb={this.breadCrumb}
						navigate={this.props.navigate}
						domainObject="Supplier Analytics"
						name="Supplier Analytics"
					/>
					{this.state.supplierChartData.length > 0 ? (
						<>
							<div className="row">
								<div className="col-md-6">
									<div className="card">
										<div className="card-body">
											<div className="row mb-12 row d-flex">
												<h5 className='text-start pb-4'>Supplier Status</h5>
												<div className="text-start" id="chart" style={{ padding: "1em" }}>
													{this.state.options !== null && this.state.series !== null ?
														<ReactApexCharts options={this.state.options} series={this.state.series} type="donut" height={240} />
														: ""
													}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card">
										<div className="card-body">
											<div className="row mb-12 row d-flex">
												<h5 className='text-start pb-4'>Supplier Type</h5>
												<div className="text-start" id="chart" style={{ padding: "1em" }}>
													{this.state.registeredSupplierOptions !== null && this.state.registeredSupplierSeries !== null ?
														<ReactApexCharts options={this.state.registeredSupplierOptions} series={this.state.registeredSupplierSeries} type="pie" height={240} />
														: ""
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-md-12">
									<div className="card">
										<div className="card-body">
											<div className="row mb-12 row justify-content-end">
												<div className="col-sm-3 m-2 text-end">
													<NavLink className="navLink text-decoration-underline" to="/supplierAssets">
														Supplier Asset
													</NavLink>
													&nbsp; &nbsp;&nbsp;
													<NavLink className="navLink text-decoration-underline" to="/supplierReportList">
														Supplier List
													</NavLink>
												</div>
											</div >
											<div className="row mb-12 row d-flex">
												{/* <h5 className='text-start pb-4'>Supplier Data</h5> */}
												<h5 className='text-start pb-1'>Suppliers vs. Assets</h5>
												<div className="text-start" id="chart" style={{ padding: "1em", overflowX: 'auto' }}>
													{this.state.supplierOptions !== null && this.state.supplierSeries !== null ?
														< ReactApexCharts
															options={this.state.supplierOptions}
															series={this.state.supplierSeries}
															type="bar"
															height={450}
															width={150 * Math.max(this.state.supplierChartData.length)}
														/>
														: ""
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<div className="row" style={{ marginTop: "1em" }}>
							<div className="col-md-12">
								<Card>
									<CardBody>
										<div className="row">
											<div className="col-md-12" style={{ padding: "2em" }}>
												<h5>
													<b>Supplier analytics data not found</b>
												</h5>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					)}
				</>
			)
		}
	}
}
export default withRouter(SupplierReportAnalysis);