import React, { Component } from 'react'
import { withRouter } from "../../../withRouter";
import { Row, Col } from "reactstrap";
class AccountDelete extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		window.scrollTo(0, 0);
	}

	navigateToLink(linkToNavigate) {
		this.props.navigate(linkToNavigate);
	}

	render() {
		return (
			<>
				<header
					className="header-area header-sticky wow slideInDown"
					data-wow-duration="0.75s"
					data-wow-delay="0s"
				>
					<div className="container">
						<div className="row">
							<div className="col-12">
								<nav className="main-nav">
									{/* ***** Logo Start ***** */}
									<a href="/" className="logo">
										<h4>AssetTrackifier</h4>
									</a>
									{/* ***** Logo End ***** */}
									{/* ***** Menu Start ***** */}
									<ul className="nav">
										<li className="scroll-to-section">
											<a href="/">
												Home
											</a>
										</li>
										{/* <li className="scroll-to-section">
                      <a href="#about">About Us</a>
                    </li>
                    <li className="scroll-to-section">
                      <a href="#ourFeatures">Features</a>
                    </li>
                    <li className="scroll-to-section">
                      <a href="#services">Services</a>
                    </li>
                    <li className="scroll-to-section">
                      <a href="#faq">FAQ</a>
                    </li> */}
										<li className="scroll-to-section">
											<a
												onClick={() => this.navigateToLink("/signinEmail")}
												style={{ cursor: "pointer" }}
											>
												Sign In
											</a>
										</li>
										<li className="scroll-to-section">
											<a
												onClick={() => this.navigateToLink("/signup")}
												style={{ cursor: "pointer" }}
											>
												Sign Up
											</a>
										</li>
										{/* <li className="scroll-to-section">
                      <a href="#contact">Contact Us</a>
                    </li> */}
									</ul>
									<a className="menu-trigger">
										<span>Menu</span>
									</a>
									{/* ***** Menu End ***** */}
								</nav>
							</div>
						</div>
					</div>
				</header>
				<div
					className="termsBanner"
					id="top"
					data-wow-duration="1s"
					data-wow-delay="0.5s"
				>
					<div
						className="container"
						style={{ border: "1px solid lightgray", padding: "2em" }}
					>
						<Row>
							<Col md={12}>
								<h3 style={{ color: "#000" }}>
									<b>
										<u>Account Deletion Instructions</u>
									</b>
								</h3>
							</Col>
						</Row>
						<Row style={{ marginTop: "1em", fontWeight: "400" }}>
							<Col md={12} style={{ textAlign: "left" }}>
								<ul className="termsList" style={{}}>
									<li>
										<p>
											Login on&nbsp;
											<b>
												<a href="http://www.assettrackifier.com/" target="_blank" rel="noreferrer">
													http://www.assettrackifier.com
												</a>
											</b>
											&nbsp;with your username and credential.
										</p>
									</li>
									<li>
										<p>Go to Profile Page. </p>
									</li>
									<li>
										<p>
											Click Delete Button/Icon.
										</p>
									</li>
								</ul>
							</Col>
						</Row>
					</div>
				</div>
			</>
		)
	}
}
export default withRouter(AccountDelete);