import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CloseIcon from "@mui/icons-material/Close";
import React, { Component } from "react";
import store from "../../redux/store";
class AssetProperty extends Component {
  propertyDataTypeList = store.getState().staticData.propertyDataTypeList;
  constructor(props) {
    super(props);
    this.state = {
      id: props.data.id,
      index: props.index,
      key: props.key,
      action: props.action,
      name: props.data.name,
      type: props.data.type,
      value: props.data.value,
      onRowDelete: props.onRowDelete,
      onChange: props.onChange,
      callBack: props.callBack,
    };
  }

  handlePropertyNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }

  handlePropertyTypeChange(e) {
    this.setState({ type: e.target.value });
  }

  handlePropertyValueChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  handleRemoveRow(e) {
    if (
      undefined !== this.state.onRowDelete &&
      null !== this.state.onRowDelete
    ) {
      this.state.onRowDelete(this.state);
    }
  }

  render() {
    if (this.state.action === "create") {
      return this.createPropertyDetails();
    } else if (this.state.action === "edit") {
      return this.editPropertyDetails();
    } else {
      return this.viewPropertyDetails();
    }
  }

  createPropertyDetails() {
    return (
      <>
        <tr>
          <td>{this.props.index + 1}</td>
          <td>
            <TextField
              fullWidth
              variant="outlined"
              id={"name" + this.state.index}
              value={this.state.name}
              placeholder="Name"
              onChange={this.handlePropertyNameChange.bind(this)}
              name="name"
              type="text"
              className="textField"
            />{" "}
          </td>
          <td>
            <FormControl variant="outlined" fullWidth size="small">
              <Select
                // labelId="occurrence-label"
                id={"type" + this.state.index}
                style={{ background: "#fff" }}
                value={this.state.type}
                placeholder="Type *"
                required
                onChange={(e) => {
                  this.handlePropertyTypeChange(e);
                }}
              >
                {this.propertyDataTypeList.map((event, keyIndex) => {
                  return (
                    <MenuItem key={keyIndex} value={event.type}>
                      {event.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </td>
          <td>
            <TextField
              fullWidth
              variant="outlined"
              id={"value" + this.state.index}
              placeholder="Title"
              value={this.state.value}
              onChange={this.handlePropertyValueChange.bind(this)}
              name="name"
              type="text"
              className="textField"
            />
          </td>
          <td>
            <CloseIcon
              onClick={this.handleRemoveRow.bind(this)}
              style={{ cursor: "pointer" }}
            />
          </td>
        </tr>
      </>
    );
  }
  viewPropertyDetails() {
    return (
      <>
        <div className="row">
          {" "}
          <label
            className="col-sm-3 col-form-label fw-bold"
            htmlFor="basic-default-name"
            id="assetFormLabel"
          >
            Name
          </label>
          <div className="col-sm-9" id="AssetCol">
            <span>{this.props.data.name}</span>
          </div>
        </div>
        <div className="row">
          {" "}
          <label
            className="col-sm-3 col-form-label fw-bold"
            htmlFor="basic-default-name"
            id="assetFormLabel"
          >
            Value
          </label>
          <div className="col-sm-9" id="AssetCol">
            <span>{this.props.data.value}</span>
          </div>
        </div>
      </>
    );
  }

  editPropertyDetails() {
    return (
      <>
        <tr>
          <td>{this.props.index + 1}</td>
          <td>
            <TextField
              type="text"
              className="textField"
              fullWidth
              variant="outlined"
              id={"name" + this.state.index}
              value={this.state.name}
              placeholder="Name"
              onChange={this.handlePropertyNameChange.bind(this)}
              name="name"
            />{" "}
          </td>
          <td>
            <FormControl variant="outlined" fullWidth size="small">
              <Select
                // labelId="occurrence-label"
                id={"type" + this.state.index}
                style={{ background: "#fff" }}
                value={this.state.type}
                placeholder="Type *"
                required
                onChange={(e) => {
                  this.handlePropertyTypeChange(e);
                }}
              >
                {this.propertyDataTypeList.map((event, keyIndex) => {
                  return (
                    <MenuItem key={keyIndex} value={event.type}>
                      {event.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </td>
          <td>
            <TextField
              fullWidth
              variant="outlined"
              id={"value" + this.state.index}
              placeholder="Title"
              value={this.state.value}
              onChange={this.handlePropertyValueChange.bind(this)}
              name="name"
              type="text"
              className="textField"
            />
          </td>
          <td>
            <CloseIcon
              onClick={this.handleRemoveRow.bind(this)}
              style={{ cursor: "pointer" }}
            />
          </td>
        </tr>
      </>
    );
  }
}

export default AssetProperty;
