import { GET_STATIC_DATA, SET_STATIC_DATA } from "./StaticDataTypes";

const getStaticData = () => {
  return {
    type: GET_STATIC_DATA,
  };
};
const setStaticData = (data) => {
  return {
    type: SET_STATIC_DATA,
    payload: data,
  };
};
const StaticDataAction = { getStaticData, setStaticData };
export default StaticDataAction;
