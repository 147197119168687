import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import { withRouter } from "../withRouter";
class LoginLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Outlet props={this.props} />
      </div>
    );
  }
}
export default withRouter(LoginLayout);
