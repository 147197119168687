import { TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Autocomplete from "@mui/material/Autocomplete";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import store from "../../redux/store";
import Modal from "../../ui/modal/Modal";
import { withRouter } from "../../withRouter";
import BackButton from "../buttons/BackButton";
import CreateSimilarIconButton from "../buttons/CreateSimilarIconButton";
import DeleteIconButton from "../buttons/DeleteIconButton";
import EditIconButton from "../buttons/EditIconButton";
import MarkDamagedButton from "../buttons/MarkDamagedButton";
import MarkDiscardButton from "../buttons/MarkDiscardButton";
import MarkInMaintenanceButton from "../buttons/MarkInMaintenanceButton";
import MarkInMaintenanceToActiveButton from "../buttons/MarkInMaintenanceToActiveButton";
import MarkActiveButton from "../buttons/MarkActiveButton";
import MarkInActiveButton from "../buttons/MarkInActiveButton";
import MenuWithBreadScrum from "../menu/MenuWithBreadScrum";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./Asset.css";
import AssetProperty from "./AssetProperty";
import AuditLog from "./AuditLog";
import TrackingHistory from "./TrackingHistory";
import Tooltip from "../../ui/tooltip/Tooltip";
import MaterialTable from "material-table";
import UiUtils from "../../provider/UiUtils";
import CatalogueBreadScrum from "../catalogueManagement/catalogueBreadScrum/CatalogueBreadScrum";
import CancelIconButton from "../buttons/CancelIconButton";
import SaveButton from "../buttons/SaveButton";
import CreateButton from "../buttons/CreateButton";
import PrintIconButton from "../buttons/PrintIconButton";
import infoIcon from "../../assets/images/infoIcon.png";

import Axios from "axios";

class Asset extends Component {
  uomList = store.getState().staticData.uomList;
  currencyList = store.getState().staticData.currencyList;
  assetTypeList = store.getState().staticData.assetTypeList;
  taxRateList = store.getState().staticData.taxRateList;
  header = store.getState().header.header;
  catalogueDetails = store.getState().Catalogue.Catalogue;
  assetPropertyRefList = [];
  assetUomList = store.getState().codeUomList.uomList;
  assetCodeList = store.getState().codeUomList.assetCodeList;
  category = store.getState().category.category;
  breadCrumb = [];
  constructor(props) {
    super(props);
    let assetId = "";
    let action = "";
    let categoryId = "";
    let pageId = "";
    let returnablePlanId = "";
    let assetCycleId = "";
    let pageNo = 0;
    let showPerPage = 10;
    let selectedAsset =
      this.assetTypeList[
        this.assetTypeList.findIndex(
          (i) => i.type.toLowerCase() === "returnable".toLowerCase()
        )
      ];
    let assetBreadCrumb = [
      { pageid: "/catalogue", uiname: "Catalogue" },
      { pageid: "/category", uiname: "Category" },
      { pageid: "", uiname: "Asset View" },
    ];
    let trackHistoryBreadCrumb = [
      { pageid: "/assetTrackingList", uiname: "Asset Tracking History List" },
      { pageid: "", uiname: "Asset View" },
    ];
    let returnablePlanBreadCrumb = [
      { pageid: "/returnablePlanList", uiname: "Returnable Plan List" },
      { pageid: "/returnablePlan", uiname: "Returnable Plan Details" },
      { pageid: "", uiname: "Asset View" },
    ];
    let assetCycleBreadCrumb = [
      { pageid: "/assetCycleList", uiname: "Asset Cycle List" },
      { pageid: "/assetCycle", uiname: "Asset Cycle Details" },
      { pageid: "", uiname: "Asset View" },
    ];
    // let assetCodeList = props.state.assetCodeList;
    // let assetUomList = props.state.assetUomList;
    if (!assetId && props.state) {
      action = props.state.action;
      categoryId = props.state.categoryId;
      assetId = props.state.assetId;
      pageId = props.state.pageId;
      returnablePlanId = props.state.returnablePlanId;
      assetCycleId = props.state.assetCycleId;
      pageNo = props.state.pageNo;
      showPerPage = props.state.showPerPage;
      assetBreadCrumb = props.state.assetBreadCrumb;
    }
    if (pageId === "assetTrackHistoryList") {
      this.breadCrumb = trackHistoryBreadCrumb;
    } else if (pageId === "returnablePlanDetails") {
      this.breadCrumb = returnablePlanBreadCrumb;
    } else if (pageId === "assetCycleDetails") {
      this.breadCrumb = assetCycleBreadCrumb;
    } else {
      this.breadCrumb = assetBreadCrumb;
    }
    if (null !== assetId && assetId > 0) {
      this.getAssetDetails(assetId);
    }
    this.state = {
      render: false,
      action: action,
      pageId: pageId,
      breadCrumb: this.breadCrumb,
      id: assetId,
      returnablePlanId: returnablePlanId,
      assetCycleId: assetCycleId,
      catalogueId: this.catalogueDetails?.id,
      assetCodeList: this.assetCodeList,
      assetUomList: this.assetUomList,
      assetType: selectedAsset,
      assetTypeList: this.assetTypeList,
      assetName: "",
      assetCode: null,
      assetKeyword: "",
      assetDescription: "",
      assetUom: null,
      dimensionHeight: "",
      heightUom: null,
      dimensionWeight: "",
      weightUom: null,
      dimensionWidth: "",
      widthUom: null,
      dimensionBreadth: "",
      breadthUom: null,
      unitPrice: "",
      currency: null,
      categoryId: categoryId,
      newProperties: [],
      qrcodeimg: "",
      assetRfId: "",
      assetDeviceId: "",
      uId: "",
      taxRate: null,
      createSimilarModal: false,
      numberOfAsset: "",
      hsnCode: "",
      taxRateId: "",
      heightUomId: "",
      weightUomId: "",
      widthUomId: "",
      breadthUomId: "",
      currencyId: "",
      assetTypeId: selectedAsset.id,
      assetUomId: "",
      assetCodeId: "",
      supplierList: [],
      supplierType: null,
      selectedTab: 0,
      assetStatus: null,
      getAllPermission: false,
      getCreatePermission: false,
      getReadPermission: false,
      getUpdatePermission: false,
      getDeletePermission: false,
      canMarkDamaged: false,
      canMarkActive: false,
      canMarkInActive: false,
      canMarkInMaintenance: false,
      canMarkDiscard: false,
      canMarkInMaintenanceToActive: false,
      isPrinter: false,
      quntity: 1,
      selectedPrinter: null,
      selectedPrinterList: [],
      assetCycle: {},
      pageNo: pageNo,
      showPerPage: showPerPage,
    };
    this.getSupplierTypeList();
  }

  getAssetDetails(assetId) {
    const postObject = {
      header: this.header,
      asset: {
        id: assetId,
      },
    };
    FetchServerData.callPostService("/assetms/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let assetDetails = output.data.asset;
          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getAllPermission = getPermission[0];
          let getCreatePermission = getPermission[1];
          let getReadPermission = getPermission[2];
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          for (let i = 0; i < assetDetails.properties?.length; i++) {
            let ref = React.createRef();
            this.assetPropertyRefList.push(ref);
            /*Set Default Operation as Update */
            assetDetails.properties[i].action = "edit";
          }
          assetDetails.categoryPathInfo = [
            ...assetDetails.categoryPathInfo,
            { name: "Asset Details" },
          ];

          this.setState({
            render: true,
            // action: "view",
            id: assetDetails.id,
            categoryId: assetDetails.category.id,
            assetName: assetDetails.name,
            assetCode: assetDetails.assetcode,
            assetKeyword: assetDetails.keywords,
            assetDescription: assetDetails.description,
            assetUom: assetDetails.uom,
            // viewAssetUom: assetDetails.uom.name,
            dimensionHeight: assetDetails.dimension.height,
            heightUom: assetDetails.dimension.heightuom,
            heightUomId:
              assetDetails.dimension?.heightuom !== null
                ? assetDetails.dimension?.heightuom.id
                : "",
            dimensionWeight: assetDetails.dimension.weight,
            weightUom: assetDetails.dimension.weightuom,
            weightUomId:
              assetDetails.dimension?.weightuom !== null
                ? assetDetails.dimension?.weightuom.id
                : "",
            dimensionWidth: assetDetails.dimension.width,
            widthUom: assetDetails.dimension.widthuom,
            widthUomId:
              assetDetails.dimension?.widthuom !== null
                ? assetDetails.dimension?.widthuom.id
                : "",
            dimensionBreadth: assetDetails.dimension.breadth,
            breadthUom: assetDetails.dimension.breadthuom,
            breadthUomId:
              assetDetails.dimension?.breadthuom !== null
                ? assetDetails.dimension?.breadthuom.id
                : "",
            unitPrice:
              assetDetails.priceinfo !== null
                ? assetDetails.priceinfo.unitprice
                : "",
            currency:
              assetDetails.priceinfo !== null
                ? assetDetails.priceinfo.currency
                : null,
            currencyId:
              assetDetails.priceinfo?.currency !== null
                ? assetDetails.priceinfo?.currency.id
                : "",
            newProperties: assetDetails.properties,
            qrcodeimg: assetDetails.qrcodeimg,
            assetRfId: assetDetails.rfid,
            assetDeviceId: assetDetails.ptsdeviceid,
            uId: assetDetails.uid,
            taxRate: assetDetails.taxRate,
            hsnCode: assetDetails.hsncode,
            assetType: assetDetails.assetType,
            assetTypeId: assetDetails.assetType.id,
            supplierType: assetDetails.supplier,
            assetStatus: assetDetails.status,
            getAllPermission: getAllPermission,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
            getReadPermission: getReadPermission,
            getCreatePermission: getCreatePermission,
            canMarkActive: assetDetails.canMarkActive,
            canMarkInActive: assetDetails.canMarkInActive,
            canMarkDamaged: assetDetails.canMarkDamaged,
            canMarkInMaintenance: assetDetails.canMarkInMaintenance,
            canMarkDiscard: assetDetails.canMarkDiscard,
            canMarkInMaintenanceToActive:
              assetDetails.canMarkInMaintenanceToActive,
            assetPropertyList: assetDetails.properties,
            createdByName: assetDetails.createdByName,
            createdOn: assetDetails.createdOn,
            breadCrumb: assetDetails.categoryPathInfo,
            trackingHistoryStatus: assetDetails.trackingHistoryStatus,
            assetCycle: assetDetails.assetCycle,
          });
        } else {
        }
      }
    );
  }

  getSupplierTypeList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService(
      "/supplierms/getActiveList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let supplierList = output.data.supplierList;
        this.setState({
          supplierList: supplierList,
        });
      } else {
      }
    });
  }
  handleAssetNameChange(e) {
    this.setState({ assetName: e.target.value });
  }
  handleAssetCodeChange(selectedAssetCode) {
    this.state.assetCode = selectedAssetCode;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.setState({
      assetCode: selectedAssetCode,
      assetCodeId: selectedAssetCode.id,
      assetName:
        selectedAssetCode.description !== null
          ? selectedAssetCode.description
          : "",
    });
  }

  handleSupplierTypeChange(selectedAssetCode) {
    this.state.supplierType = selectedAssetCode;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.setState({
      supplierType: selectedAssetCode,
    });
  }
  handleAssetKeywordChange(e) {
    this.setState({ assetKeyword: e.target.value });
  }
  handleAssetDescriptionChange(e) {
    this.setState({ assetDescription: e.target.value });
  }
  handleAssetRfIdChange(e) {
    this.setState({ assetRfId: e.target.value });
  }
  handleAssetDeviceIdChange(e) {
    this.setState({ assetDeviceId: e.target.value });
  }
  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }
  handleAssetUmoChange(selectedUom) {
    this.state.assetUom = selectedUom;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.setState({
      assetUom: selectedUom,
      assetUomId: selectedUom.id,
    });
  }

  handleAssetTypeChange(selectedType) {
    // this.state.assetType = selectedType;
    // if (undefined !== this.onChange && null !== this.onChange) {
    //   this.onChange(this.state);
    // }
    this.setState({
      assetType: selectedType,
      assetTypeId: selectedType.id,
    });
  }
  handleTaxRateChange(selectedRate) {
    this.state.taxRate = selectedRate;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.setState({
      taxRate: selectedRate,
      taxRateId: selectedRate.id,
    });
  }

  handleDimensionHeightChange(e) {
    this.setState({ dimensionHeight: e.target.value });
  }

  handleHeightUmoChange(selectedType) {
    this.state.heightUom = selectedType;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }

    this.setState({
      heightUom: selectedType,
      heightUomId: selectedType.id,
    });
  }

  handleDimensionWeightChange(e) {
    this.setState({ dimensionWeight: e.target.value });
  }

  handleWeightUmoChange(selectedType) {
    this.state.weightUom = selectedType;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }

    this.setState({
      weightUom: selectedType,
      weightUomId: selectedType.id,
    });
  }

  handleDimensionWidthChange(e) {
    this.setState({ dimensionWidth: e.target.value });
  }
  handleWidthUmoChange(selectedType) {
    this.state.widthUom = selectedType;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }

    this.setState({
      widthUom: selectedType,
      widthUomId: selectedType.id,
    });
  }

  handleDimensionBreadthChange(e) {
    this.setState({ dimensionBreadth: e.target.value });
  }
  handleBreadthUmoChange(selectedType) {
    this.state.breadthUom = selectedType;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }

    this.setState({
      breadthUom: selectedType,
      breadthUomId: selectedType.id,
    });
  }

  handleUnitPriceChange(e) {
    this.setState({ unitPrice: e.target.value });
  }

  handleHsnCodeChange(e) {
    this.setState({ hsnCode: e.target.value });
  }

  handleCurrencyChange(selectedType) {
    this.state.currency = selectedType;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }

    this.setState({
      currency: selectedType,
      currencyId: selectedType.id,
    });
  }
  handleNumberOfAssetChange(e) {
    this.setState({ numberOfAsset: e.target.value });
  }
  handlePropertyChange = (index) => (e) => {
    const { name, value } = e.target;
    const newProperties = [...this.state.newProperties];
    newProperties[index] = {
      [name]: value,
    };
    this.setState({
      newProperties,
    });
  };

  createAssetInBackend() {
    let propertiesList = [];
    for (let i = 0; i < this.assetPropertyRefList.length; i++) {
      if (this.assetPropertyRefList[i].current) {
        let properties = {};
        properties.id = null;
        properties.action = this.assetPropertyRefList[i].current.state.action;
        properties.name = this.assetPropertyRefList[i].current.state.name;
        properties.type = this.assetPropertyRefList[i].current.state.type;
        properties.value = this.assetPropertyRefList[i].current.state.value;
        propertiesList[i] = properties;
      }
    }

    let supplierId = "";
    let taxRateId = "";
    if (this.state.supplierType !== null) {
      supplierId = this.state.supplierType.id;
    } else if (this.state.taxRate !== null) {
      taxRateId = this.state.taxRate.id;
    }
    const postObject = {
      header: this.header,
      asset: {
        name: this.state.assetName,
        assetcode: {
          name: this.state.assetCode.code,
          id: this.state.assetCodeId,
        },
        keywords: this.state.assetKeyword,
        description: this.state.assetDescription,
        hsncode: this.state.hsnCode,
        rfid: this.state.assetRfId,
        ptsdeviceid: this.state.assetDeviceId,
        taxRate: this.state.taxRate,
        assetType: {
          id: this.state.assetTypeId,
        },
        uom: {
          id: this.state.assetUomId,
        },
        supplier: {
          id: supplierId,
        },
        dimension: {
          height: this.state.dimensionHeight,
          heightuom: {
            id: this.state.heightUomId,
          },
          weight: this.state.dimensionWeight,
          weightuom: {
            id: this.state.weightUomId,
          },
          width: this.state.dimensionWidth,
          widthuom: {
            id: this.state.widthUomId,
          },
          breadth: this.state.dimensionBreadth,
          breadthuom: {
            id: this.state.breadthUomId,
          },
        },
        priceinfo: {
          unitprice: this.state.unitPrice,
          currency: {
            id: this.state.currencyId,
          },
        },
        category: {
          id: this.state.categoryId,
        },
        properties: propertiesList,
      },
    };
    FetchServerData.callPostService("/assetms/create", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          let assetDetails = output.data.asset;
          this.getAssetDetails(assetDetails.id);
          this.setState({
            action: "view",
          });
        } else {
        }
      })
      .catch((error) => {});
    // }
  }
  updateAssetInBackend() {
    let propertiesList = [];
    for (let i = 0; i < this.state.newProperties.length; i++) {
      if (this.state.newProperties[i].action === "delete") {
        let properties = {};
        properties.id = this.state.newProperties[i].id;
        properties.action = this.state.newProperties[i].action;
        properties.name = this.state.newProperties[i].name;
        properties.type = this.state.newProperties[i].type;
        properties.value = this.state.newProperties[i].value;
        propertiesList[i] = properties;
      } else {
        for (let i = 0; i < this.assetPropertyRefList.length; i++) {
          if (this.assetPropertyRefList[i].current) {
            let properties = {};
            properties.id = this.assetPropertyRefList[i].current.state.id;
            properties.action =
              this.assetPropertyRefList[i].current.state.action;
            properties.name = this.assetPropertyRefList[i].current.state.name;
            properties.type = this.assetPropertyRefList[i].current.state.type;
            properties.value = this.assetPropertyRefList[i].current.state.value;
            propertiesList[i] = properties;
          }
        }
      }
    }
    let supplierId = "";
    let taxRateId = "";
    if (this.state.supplierType !== null) {
      supplierId = this.state.supplierType.id;
    } else if (this.state.taxRate !== null) {
      taxRateId = this.state.taxRate.id;
    }

    const postObject = {
      header: this.header,
      asset: {
        id: this.state.id,
        name: this.state.assetName,
        assetcode: {
          name: this.state.assetCode.code,
          id: this.state.assetCodeId,
        },
        keywords: this.state.assetKeyword,
        description: this.state.assetDescription,
        hsncode: this.state.hsnCode,
        rfid: this.state.assetRfId,
        ptsdeviceid: this.state.assetDeviceId,
        taxRate: this.state.taxRate,
        assetType: {
          id: this.state.assetTypeId,
        },
        uom: {
          id: this.state.assetUomId,
        },
        supplier: {
          id: supplierId,
        },
        dimension: {
          height: this.state.dimensionHeight,
          heightuom: this.state.heightUom,
          weight: this.state.dimensionWeight,
          weightuom: {
            id: this.state.weightUomId,
          },
          width: this.state.dimensionWidth,
          widthuom: {
            id: this.state.widthUomId,
          },
          breadth: this.state.dimensionBreadth,
          breadthuom: {
            id: this.state.breadthUomId,
          },
        },
        priceinfo: {
          unitprice: this.state.unitPrice,
          currency: {
            id: this.state.currencyId,
          },
        },
        category: {
          id: this.state.categoryId,
        },
        properties: propertiesList,
      },
    };
    FetchServerData.callPostService("/assetms/update", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          window.scrollTo(0, 0);
          let assetDetails = output.data.asset;
          this.getAssetDetails(assetDetails.id);
          this.setState({
            action: "view",
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  deleteAssetData() {
    const postObject = {
      header: this.header,
      asset: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService("/assetms/delete", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.props.navigate("/category", {
            state: {
              view: "categoryView",
              categoryId: this.state.categoryId,
              catalogueId: this.catalogueDetails.id,
            },
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  onCancel = () => {
    this.props.navigate("/category", {
      state: {
        view: "categoryView",
        categoryId: this.state.categoryId,
        catalogueId: this.catalogueDetails.id,
      },
    });
  };
  editAssetData = () => {
    let id = this.state.id;
    this.setState({
      action: "edit",
      assetId: id,
      categoryId: this.state.categoryId,
      assetCodeList: this.assetCodeList,
      assetUomList: this.assetUomList,
      breadCrumb: this.state.breadCrumb,
    });
  };

  handleAddRow = () => {
    let items = this.state.newProperties;
    let item = {
      id: "",
      action: "create",
      name: "",
      type: "",
      value: "",
    };
    items.push(item);
    this.setState({ newProperties: items });
  };

  handleRemoveRow = () => {
    this.setState({
      newProperties: this.state.newProperties.slice(0, -1),
    });
  };

  handleDeleteRow(item) {
    let items = [];

    if (item.action && "edit" === item.action) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.newProperties.length; i++) {
        let item = this.state.newProperties[i];

        if (this.state.newProperties[i].action === "edit" && index === i) {
          item.action = "delete";
        }

        items.push(item);
      }
      this.setState({ newProperties: items });
    }
    if (item.action && "create" === item.action) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.newProperties.length; i++) {
        if (this.state.newProperties[i].action === "create" && index === i) {
          this.assetPropertyRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.newProperties[i]);
        }
      }
      this.setState({ newProperties: items });
    }
  }

  onEditCancel() {
    this.props.navigate("/asset", {
      state: {
        action: "view",
        assetId: this.state.id,
        categoryId: this.state.categoryId,
      },
    });
    this.props.navigate(0);
  }
  viewCancel() {
    if (this.state.pageId === "filterData") {
      this.props.navigate("/catalogue", {
        state: {
          categoryId: this.category.id,
          catalogueId: this.catalogueDetails.id,
          view: "filterView",
        },
      });
    } else if (this.state.pageId === "CategoryFilterData") {
      this.props.navigate("/category", {
        state: {
          categoryId: this.category.id,
          catalogueId: this.catalogueDetails.id,
          view: "filterView",
        },
      });
    } else if (this.state.pageId === "assetTrackHistoryList") {
      this.props.navigate("/assetTrackingList", {
        state: {
          assetId: this.state.id,
        },
      });
    } else if (this.state.pageId === "returnablePlanDetails") {
      this.props.navigate("/returnablePlan", {
        state: {
          action: "view",
          assetId: this.state.id,
          returnableCyclePlanId: this.state.returnablePlanId,
        },
      });
    } else if (this.state.pageId === "assetCycleDetails") {
      this.props.navigate("/assetCycle", {
        state: {
          action: "view",
          assetCycleId: this.state.assetCycleId,
        },
      });
    } else {
      this.props.navigate("/category", {
        state: {
          view: "categoryView",
          pageNo: this.state.pageNo,
          showPerPage: this.state.showPerPage,
          categoryId: this.state.categoryId,
          catalogueId: this.catalogueDetails.id,
        },
      });
    }
  }
  editCancel() {
    this.getAssetDetails(this.state.id);
    this.setState({
      categoryId: this.state.categoryId,
      action: "view",
      assetId: this.state.id,
      replace: true,
    });
  }

  createSimilarAsset() {
    this.setState({
      createSimilarModal: true,
    });
  }

  onCreateSimilarModalClose() {
    this.setState({
      createSimilarModal: !this.state.createSimilarModal,
    });
  }

  createNumberOfAssetInBackend() {
    const postObject = {
      header: this.header,
      asset: {
        id: this.state.id,
      },
      numberOfAssets: this.state.numberOfAsset,
    };
    FetchServerData.callPostService("/assetms/createSimilar", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.setState({ createSimilarModal: false });
          this.props.navigate("/category", {
            state: {
              view: "categoryView",
              categoryId: this.state.categoryId,
              catalogueId: this.catalogueDetails.id,
            },
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  setKey(tab) {
    this.setState({
      selectedTab: tab,
    });
  }

  markActiveAsset() {
    const postObject = {
      header: this.header,
      asset: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService("/assetms/markActive", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.getAssetDetails(output.data.asset.id);
          this.setState({
            action: "view",
          });
        } else {
        }
      }
    );
  }
  markInActiveAsset() {
    const postObject = {
      header: this.header,
      asset: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService("/assetms/markInActive", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.getAssetDetails(output.data.asset.id);
          this.setState({
            action: "view",
          });
        } else {
        }
      }
    );
  }

  markDamagedAsset() {
    const postObject = {
      header: this.header,
      asset: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService("/assetms/markDamaged", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.getAssetDetails(output.data.asset.id);
          this.setState({
            action: "view",
          });
          // this.props.navigate("/asset", {
          //   state: {
          //     action: "view",
          //     assetId: this.state.id,
          //   },
          // });
        } else {
        }
      }
    );
  }
  markInMaintenanceAsset() {
    const postObject = {
      header: this.header,
      asset: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService(
      "/assetms/markInMaintenance",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        this.getAssetDetails(output.data.asset.id);
        this.setState({
          action: "view",
        });
      } else {
      }
    });
  }
  markDiscardAsset() {
    const postObject = {
      header: this.header,
      asset: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService("/assetms/markDiscard", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.getAssetDetails(output.data.asset.id);
          this.setState({
            action: "view",
          });
        } else {
        }
      }
    );
  }
  markInMaintenanceToActiveAsset() {
    const postObject = {
      header: this.header,
      asset: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService(
      "/assetms/markInMaintenanceToActive",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        this.getAssetDetails(output.data.asset.id);
        this.setState({
          action: "view",
        });
      } else {
      }
    });
  }

  toPlanDetails() {
    this.props.navigate("/returnablePlan", {
      state: {
        action: "view",
        returnableCyclePlanId: this.state.assetCycle?.cycleplanid,
        assetId: this.state.id,
        pageId: "assetPlanDetails",
      },
    });
  }
  toAssetCycleDetails() {
    this.props.navigate("/assetCycle", {
      state: {
        action: "view",
        assetCycleId: this.state.assetCycle?.id,
        assetId: this.state.id,
        pageId: "assetCycleDetails",
      },
    });
  }

  handleSelectPrinterChange(selectedPrinter) {
    console.log(selectedPrinter);
    this.setState({
      selectedPrinter: selectedPrinter,
    });
  }
  handleQuantityChange(e) {
    this.setState({
      quntity: e.target.value,
    });
  }

  getPrinterList(e) {
    FetchServerData.callGetService(
      "/assetTrackServices/staticms/getPrinterList"
    )
      .then((output) => {
        if (output.status === "SUCCESS") {
          let printerList = output.data.printerList;
          this.setState({
            selectedPrinterList: printerList,
            selectedPrinter: printerList[0],
            isPrinter: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  printInBackend() {
    const postObject = {
      header: {},
      printer: this.state.selectedPrinter?.name,
      quantity: this.state.quntity,
      asset: {
        id: this.state.id,
        name: this.state.assetName,
        uid: this.state.uId,
      },
      commands: this.state.selectedPrinter?.commands,
    };
    Axios.post("http://localhost:9090/printData", postObject).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.setState({
          isPrinter: false,
        });
      } else {
      }
    });
  }

  // printInBackend() {

  //   this.setState({
  //     isPrinter: true,
  //   })
  // window.location.replace("./AssetPrint.html");

  // this.props.navigate("/assetPrint", {
  //   state: {
  //     action: "view",
  //     assetCycleId: this.state.assetCycle?.id,
  //     assetId: this.state.id,
  //     uid: this.state.uId,
  //     pageId: "assetCycleDetails",
  //   },
  // });

  // const postObject = {
  //   uid: this.state.uId,
  // };
  // Axios.post("http://localhost:9090/printData", postObject)
  //   .then((output) => {
  //     if (output.status === "SUCCESS") {
  //       PtsAlert.success(output.message);
  //     } else {
  //     }
  //   });
  // }

  render() {
    this.assetPropertyRefList = [];
    for (
      let i = 0;
      this.state.newProperties && i < this.state.newProperties.length;
      i++
    ) {
      this.assetPropertyRefList[i] = React.createRef();
    }
    if (this.state.action === "create") {
      return this.createAssetDetails();
    } else if (this.state.action === "edit") {
      return this.editAssetDetails();
    } else {
      return this.viewAssetDetails();
    }
  }
  createAssetDetails() {
    let assetPropertyList = [];
    this.state.newProperties.forEach((item, index) => {
      assetPropertyList.push(
        <AssetProperty
          ref={this.assetPropertyRefList[index]}
          key={index}
          data={item}
          index={index}
          action={item.action}
          onRowDelete={this.handleRemoveRow.bind(this)}
        />
      );
    });

    return (
      <>
        {typeof this.state.pageId !== "undefined" ? (
          <>
            <MenuWithBreadScrum
              breadCrumb={this.state.breadCrumb}
              navigate={this.props.navigate}
              domainObject="Create Asset"
              name="Create Asset"
              pageId="AssetView"
              categoryId={this.state.categoryId}
              assetCycleId={this.state.assetCycleId}
              returnablePlanId={this.state.returnablePlanId}
            />{" "}
          </>
        ) : (
          <CatalogueBreadScrum
            breadCrumb={[...this.state.breadCrumb, { name: "Create Asset" }]}
            catalogueId={this.state.catalogueId}
            categoryId={this.state.categoryId}
            onSuccess={() => {}}
            navigate={this.props.navigate}
            domainObject="Create Asset"
            name="Create Asset"
            pageId="AssetView"
          />
        )}{" "}
        <ValidatorForm
          style={{ width: "100%" }}
          ref="form"
          onSubmit={this.createAssetInBackend.bind(this)}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="card " id="assetFormCard">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="mb-0" id="headerLabel">
                    Asset Info
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Name<span className="asterisk"> *</span>
                      </label>

                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        variant="outlined"
                        placeholder="Name*"
                        value={this.state.assetName}
                        onChange={this.handleAssetNameChange.bind(this)}
                        id="basic-default-name"
                        required
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Asset Code<span className="asterisk"> *</span>
                        <Tooltip
                          placement="top"
                          title="Asset Codes are created by Admin Users in Settings"
                          id="password-requirements-tooltip"
                        >
                          <img src={infoIcon} alt="" width="20" height="20" />
                        </Tooltip>
                      </label>
                      <Autocomplete
                        required
                        // onFocus={this.toggleFocusNewAssetCode}
                        // onBlur={this.toggleFocusNewAssetCode}
                        options={this.state.assetCodeList}
                        value={this.state.assetCode}
                        defaultValue={this.state.assetCode}
                        getOptionLabel={(option) => option.code}
                        onChange={(event, value) => {
                          this.handleAssetCodeChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Asset Code*"
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Keywords
                      </label>
                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Keywords"
                        value={this.state.assetKeyword}
                        onChange={this.handleAssetKeywordChange.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Asset Type<span className="asterisk"> *</span>
                      </label>

                      <Autocomplete
                        id="multicol-country"
                        required
                        options={this.state.assetTypeList}
                        value={this.state.assetType}
                        defaultValue={this.state.assetType}
                        getOptionLabel={(option) => option.type}
                        onChange={(event, value) => {
                          this.handleAssetTypeChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Asset Type*"
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        UOM<span className="asterisk"> *</span>
                        <Tooltip
                          placement="top"
                          title="UOM are created by Admin Users in Settings"
                          id="password-requirements-tooltip"
                        >
                          <img src={infoIcon} alt="" width="20" height="20" />
                        </Tooltip>
                      </label>

                      <Autocomplete
                        id="multicol-country"
                        required
                        options={this.state.assetUomList}
                        value={this.state.assetUom}
                        defaultValue={this.state.assetUom}
                        getOptionLabel={(option) => option.namecode}
                        onChange={(event, value) => {
                          this.handleAssetUmoChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Unit Of Measurement"
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        RFID
                      </label>
                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="RFID"
                        value={this.state.assetRfId}
                        onChange={this.handleAssetRfIdChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        PTS DEVICE ID
                      </label>
                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="PTS Device Id"
                        value={this.state.assetDeviceId}
                        onChange={this.handleAssetDeviceIdChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Supplier
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.state.supplierList}
                        value={this.state.supplierType}
                        defaultValue={this.state.supplierType}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.handleSupplierTypeChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Supplier"
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Description
                      </label>
                      <textarea
                        name="collapsible-address"
                        className="form-control"
                        style={{
                          color: "black",
                          font: "inherit",
                          fontSize: "16px ",
                        }}
                        id="collapsible-address"
                        rows="4"
                        placeholder="Description"
                        value={this.state.assetDescription}
                        onChange={this.handleAssetDescriptionChange.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "1em" }}>
            <div className="col-md-12">
              <div className="card" id="assetFormCard">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="mb-0" id="headerLabel">
                    Dimensions
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-4 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        height
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="height"
                        value={this.state.dimensionHeight}
                        onChange={this.handleDimensionHeightChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-4 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        UOM
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.uomList}
                        value={this.state.heightUom}
                        defaultValue={this.state.heightUom}
                        getOptionLabel={(option) => option.namecode}
                        onChange={(event, value) => {
                          this.handleHeightUmoChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Unit Of Measurement"
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-4 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        Width
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Width"
                        value={this.state.dimensionWidth}
                        onChange={this.handleDimensionWidthChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        UOM
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.uomList}
                        value={this.state.widthUom}
                        defaultValue={this.state.widthUom}
                        getOptionLabel={(option) => option.namecode}
                        onChange={(event, value) => {
                          this.handleWidthUmoChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Unit Of Measurement"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3" id="assetFormLabel">
                      {" "}
                      <label
                        className="col-sm-4 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        Length
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Length"
                        value={this.state.dimensionBreadth}
                        onChange={this.handleDimensionBreadthChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        UOM
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.uomList}
                        value={this.state.breadthUom}
                        defaultValue={this.state.breadthUom}
                        getOptionLabel={(option) => option.namecode}
                        onChange={(event, value) => {
                          this.handleBreadthUmoChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Unit Of Measurement"
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-4 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        Weight
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Weight"
                        value={this.state.dimensionWeight}
                        onChange={this.handleDimensionWeightChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        UOM
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.uomList}
                        value={this.state.weightUom}
                        defaultValue={this.state.weightUom}
                        getOptionLabel={(option) => option.namecode}
                        onChange={(event, value) => {
                          this.handleWeightUmoChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Unit Of Measurement"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "1em" }}>
            <div className="col-md-12">
              <div className="card" id="assetFormCard">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="mb-0" id="headerLabel">
                    Price Information
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        Unit Price
                      </label>

                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Unit Price"
                        value={this.state.unitPrice}
                        onChange={this.handleUnitPriceChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        Currency
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.currencyList}
                        value={this.state.currency}
                        defaultValue={this.state.currency}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.handleCurrencyChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Currency"
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        tax Rate
                      </label>

                      <Autocomplete
                        id="multicol-country"
                        options={this.taxRateList}
                        value={this.state.taxRate}
                        defaultValue={this.state.taxRate}
                        getOptionLabel={(option) => option.description}
                        onChange={(event, value) => {
                          this.handleTaxRateChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Tax Rate"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        HSN Code
                      </label>

                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="HSN Code"
                        value={this.state.hsnCode}
                        onChange={this.handleHsnCodeChange.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "1em" }}>
            <div className="col-md-12">
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="mb-0" id="headerLabel">
                    Add Custom Fields
                  </h5>
                </div>
                <div className="card-body">
                  <div className="table text-nowrap">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Value</th>

                          <th>
                            <AddIcon
                              onClick={this.handleAddRow}
                              className="newPropertiesBtn"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ borderWidth: "1px" }}>
                        {assetPropertyList}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "1.2rem 1.5rem 1.5rem" }}>
            <CreateButton btnName="Create" type="submit" />
            &nbsp; &nbsp;
            <CancelIconButton
              btnName="Cancel"
              onClick={this.onCancel.bind(this)}
            />
            {/* <button
              type="button"
              className="btn btn-label-secondary"
              data-bs-dismiss="modal"
              onClick={this.onCancel.bind(this)}
            >
              Close
            </button> */}
          </div>
        </ValidatorForm>
      </>
    );
  }

  viewAssetDetails() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let assetPropertyList = [];
      this.state.newProperties?.forEach((item, index) => {
        assetPropertyList.push(
          <AssetProperty
            ref={this.assetPropertyRefList[index]}
            key={index}
            data={item}
            index={index}
            action="view"
          />
        );
      });

      return (
        <>
          {typeof this.state.pageId !== "undefined" ? (
            <>
              <MenuWithBreadScrum
                // editCallback={this.editAssetData.bind(this)}
                // deleteCallback={this.deleteAssetData.bind(this)}
                // createSimilarCallback={this.createSimilarAsset.bind(this)}
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                breadCrumb={this.state.breadCrumb}
                navigate={this.props.navigate}
                domainObject="View Asset"
                name="View Asset"
                pageId="AssetView"
                categoryId={this.state.categoryId}
                assetCycleId={this.state.assetCycleId}
                returnablePlanId={this.state.returnablePlanId}
              />{" "}
            </>
          ) : (
            <CatalogueBreadScrum
              getDeletePermission={this.state.getDeletePermission}
              getUpdatePermission={this.state.getUpdatePermission}
              breadCrumb={this.state.breadCrumb}
              catalogueId={this.state.catalogueId}
              categoryId={this.state.categoryId}
              onSuccess={() => {}}
              navigate={this.props.navigate}
              domainObject="Asset"
              name="View Asset"
              pageId="AssetView"
            />
          )}
          <Row>
            <Col md={12} className="assetTabRow">
              <Tabs
                defaultIndex={this.state.selectedTab}
                onSelect={(k) => this.setKey(k)}
                className="assetTab"
              >
                <TabList style={{ paddingLeft: "0.5em", borderBottom: "none" }}>
                  <Tab>
                    {" "}
                    <span className="tabUser">Asset Details</span>
                  </Tab>
                  <Tab>
                    <span className="tabBilling">Tracking History</span>
                  </Tab>
                  <Tab>
                    <span className="tabSponsorship">Audit Log</span>
                  </Tab>
                </TabList>{" "}
                <TabPanel>
                  <div className="row" style={{ margin: "0em" }}>
                    <div className="col-md-12" style={{ float: "right" }}>
                      {this.state.getDeletePermission && (
                        <DeleteIconButton
                          domainObject={this.state.assetName}
                          onClick={this.deleteAssetData.bind(this)}
                          pageId={this.props.pageId}
                          title="Asset"
                        />
                      )}
                      &nbsp;
                      {this.state.getUpdatePermission && (
                        <EditIconButton
                          domainObject={this.state.domainObject}
                          onClick={this.editAssetData.bind(this)}
                        />
                      )}
                      &nbsp;
                      {this.state.getCreatePermission && (
                        <CreateSimilarIconButton
                          domainObject={this.state.domainObject}
                          onClick={this.createSimilarAsset.bind(this)}
                          name="Create Similar"
                        />
                      )}
                      &nbsp;
                      {this.state.canMarkActive && (
                        <MarkActiveButton
                          domainObject={this.state.domainObject}
                          onClick={this.markActiveAsset.bind(this)}
                          name="Mark Active"
                        />
                      )}
                      {this.state.canMarkInActive && (
                        <MarkInActiveButton
                          domainObject={this.state.domainObject}
                          onClick={this.markInActiveAsset.bind(this)}
                          name="Mark InActive"
                        />
                      )}
                      &nbsp;
                      {this.state.canMarkDamaged && (
                        <MarkDamagedButton
                          domainObject={this.state.domainObject}
                          onClick={this.markDamagedAsset.bind(this)}
                          name="Mark Damaged"
                        />
                      )}
                      {this.state.canMarkInMaintenance && (
                        <MarkInMaintenanceButton
                          domainObject={this.state.domainObject}
                          onClick={this.markInMaintenanceAsset.bind(this)}
                          name="Mark In Maintenance"
                        />
                      )}
                      {this.state.canMarkDiscard && (
                        <MarkDiscardButton
                          domainObject={this.state.domainObject}
                          onClick={this.markDiscardAsset.bind(this)}
                          name="Mark Discard"
                        />
                      )}
                      {this.state.canMarkInMaintenanceToActive && (
                        <MarkInMaintenanceToActiveButton
                          domainObject={this.state.domainObject}
                          onClick={this.markInMaintenanceToActiveAsset.bind(
                            this
                          )}
                          name="Mark In Maintenance To Active"
                        />
                      )}
                    </div>
                  </div>
                  <div className="row" style={{ margin: "0.5em" }}>
                    <div className="card" id="assetFormCard">
                      <div className="row">
                        {/* <div
                          className="card-header d-flex align-items-center justify-content-between"
                          id="assetCardHeader"
                        >
                          <h5 className="mb-0" id="headerLabel">
                            Asset Info
                          </h5>
                        </div> */}
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row">
                                    <label
                                      className="col-sm-4 col-form-label fw-bold"
                                      htmlFor="basic-default-name"
                                      id="assetFormLabel"
                                    >
                                      Name
                                    </label>
                                    <div className="col-sm-8" id="AssetCol">
                                      <span>{this.state.assetName}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label
                                      className="col-sm-4 col-form-label fw-bold"
                                      htmlFor="basic-default-name"
                                      id="assetFormLabel"
                                    >
                                      Asset Code
                                    </label>
                                    <div className="col-sm-8" id="AssetCol">
                                      <span>{this.state.assetCode.code}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label
                                      className="col-sm-4 col-form-label fw-bold"
                                      htmlFor="basic-default-name"
                                      id="assetFormLabel"
                                    >
                                      Keywords
                                    </label>
                                    <div className="col-sm-8" id="AssetCol">
                                      <span>{this.state.assetKeyword}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label
                                      className="col-sm-4 col-form-label fw-bold"
                                      htmlFor="basic-default-name"
                                      id="assetFormLabel"
                                    >
                                      Asset Type
                                    </label>
                                    <div className="col-sm-8" id="AssetCol">
                                      <span>{this.state.assetType.type}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label
                                      className="col-sm-4 col-form-label fw-bold"
                                      htmlFor="basic-default-name"
                                      id="assetFormLabel"
                                    >
                                      Supplier
                                    </label>
                                    <div className="col-sm-8" id="AssetCol">
                                      <span>
                                        {this.state.supplierType
                                          ? this.state.supplierType.name
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  {this.state.assetCycle && (
                                    <div className="row">
                                      <label
                                        className="col-sm-4 col-form-label fw-bold"
                                        htmlFor="basic-default-name"
                                        id="assetFormLabel"
                                      >
                                        Plan Name
                                      </label>
                                      <div className="col-sm-8" id="AssetCol">
                                        {/* <span>
                                          {this.state.assetCycle !== null
                                            ? this.state.assetCycle.cycleplanname
                                            : ""}
                                        </span> */}
                                        <span>
                                          <a
                                            // href="#"
                                            onClick={this.toPlanDetails.bind(
                                              this
                                            )}
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {this.state.assetCycle !== null
                                              ? this.state.assetCycle
                                                  .cycleplanname
                                              : ""}
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-6">
                                  {" "}
                                  <div className="row">
                                    <label
                                      className="col-sm-4 col-form-label fw-bold"
                                      htmlFor="basic-default-name"
                                      id="assetFormLabel"
                                    >
                                      UOM
                                    </label>
                                    <div className="col-sm-8" id="AssetCol">
                                      <span>
                                        {this.state.assetUom
                                          ? this.state.assetUom.name
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label
                                      className="col-sm-4 col-form-label fw-bold"
                                      htmlFor="basic-default-name"
                                      id="assetFormLabel"
                                    >
                                      RFID
                                    </label>
                                    <div className="col-sm-8" id="AssetCol">
                                      <span>{this.state.assetRfId}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label
                                      className="col-sm-4 col-form-label fw-bold"
                                      htmlFor="basic-default-name"
                                      id="assetFormLabel"
                                    >
                                      PTS DEVICE ID
                                    </label>
                                    <div className="col-sm-8" id="AssetCol">
                                      <span>{this.state.assetDeviceId}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label
                                      className="col-sm-4 col-form-label fw-bold"
                                      htmlFor="basic-default-name"
                                      id="assetFormLabel"
                                    >
                                      Status
                                    </label>
                                    <div className="col-sm-8" id="AssetCol">
                                      <span>
                                        {this.state.assetStatus
                                          ? this.state.assetStatus?.status
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  {this.state.trackingHistoryStatus !== null ? (
                                    <div className="row">
                                      <label
                                        className="col-sm-4 col-form-label fw-bold"
                                        htmlFor="basic-default-name"
                                        id="assetFormLabel"
                                      >
                                        Tracking Status
                                      </label>
                                      {this.state.trackingHistoryStatus
                                        ?.status === "Active" && (
                                        <div className="col-sm-8" id="AssetCol">
                                          <span>
                                            {
                                              this.state.trackingHistoryStatus
                                                ?.status
                                            }
                                          </span>
                                        </div>
                                      )}
                                      {this.state.trackingHistoryStatus
                                        ?.status === "Tracked" && (
                                        <div className="col-sm-8" id="AssetCol">
                                          <span className="greendot"></span>
                                          <span className="ps-2">
                                            {
                                              this.state.trackingHistoryStatus
                                                ?.status
                                            }
                                          </span>
                                        </div>
                                      )}
                                      {this.state.trackingHistoryStatus
                                        ?.status === "MissingAsset" && (
                                        <div className="col-sm-8" id="AssetCol">
                                          <span className="reddot blink_me"></span>
                                          <span className="ps-2">
                                            {
                                              this.state.trackingHistoryStatus
                                                ?.status
                                            }
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.assetCycle && (
                                    <div className="row">
                                      <label
                                        className="col-sm-4 col-form-label fw-bold"
                                        htmlFor="basic-default-name"
                                        id="assetFormLabel"
                                      >
                                        Asset Cycle Name
                                      </label>
                                      <div className="col-sm-8" id="AssetCol">
                                        <span>
                                          <a
                                            onClick={this.toAssetCycleDetails.bind(
                                              this
                                            )}
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {this.state.assetCycle !== null
                                              ? this.state.assetCycle?.name
                                              : ""}
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="row">
                                  <label
                                    className="col-sm-12 col-form-label fw-bold"
                                    htmlFor="basic-default-name"
                                    id="assetFormLabel"
                                  >
                                    Description
                                  </label>
                                  <br></br>
                                  <div className="col-sm-12" id="AssetCol">
                                    <span>{this.state.assetDescription}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row">
                                <div className="col-sm-12">
                                  <img
                                    src={`data:image/png;base64,${this.state.qrcodeimg}`}
                                    alt=""
                                    style={{
                                      maxHeight: "200px",
                                      width: "200px",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                {" "}
                                <div className="col-sm-12">
                                  <span>{this.state.uId}</span>
                                  &nbsp;&nbsp;&nbsp;
                                  <span>
                                    <PrintIconButton
                                      onClick={this.getPrinterList.bind(this)}
                                    />
                                  </span>
                                </div>
                                {this.state.isPrinter && (
                                  <>
                                    <div className="col-sm-12 mt-2">
                                      <div className="row">
                                        <div
                                          className="col-md-4"
                                          id="assetFormLabel"
                                        >
                                          <label
                                            className="form-label "
                                            htmlFor="collapsible-fullname"
                                          >
                                            QTY
                                            <span className="asterisk"> *</span>
                                          </label>
                                          <TextField
                                            type="number"
                                            className="textField"
                                            fullWidth
                                            variant="outlined"
                                            placeholder="QTY*"
                                            value={this.state.quntity}
                                            onChange={this.handleQuantityChange.bind(
                                              this
                                            )}
                                            id="basic-default-name"
                                            required
                                          />
                                        </div>
                                        <div
                                          className="col-md-8"
                                          id="assetFormLabel"
                                        >
                                          <label
                                            className="form-label "
                                            htmlFor="collapsible-fullname"
                                          >
                                            Printer
                                            <span className="asterisk"> *</span>
                                          </label>
                                          <Autocomplete
                                            id="multicol-country"
                                            required
                                            options={
                                              this.state.selectedPrinterList
                                            }
                                            value={this.state.selectedPrinter}
                                            defaultValue={
                                              this.state.selectedPrinter
                                            }
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            onChange={(event, value) => {
                                              this.handleSelectPrinterChange(
                                                value
                                              );
                                            }}
                                            inputprops={{
                                              style: {
                                                textTransform: "capitalize",
                                              },
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                className="form-control"
                                                variant="outlined"
                                                placeholder="Printer"
                                                required
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-12 mt-2">
                                      <Button
                                        type="submit"
                                        // onclick="print();"
                                        style={{
                                          background: "#0B6F9D",
                                          color: "#fff",
                                          marginRight: "1rem",
                                        }}
                                        onClick={this.printInBackend.bind(this)}
                                      >
                                        Print Now
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-start ms-2">
                        <span style={{ fontSize: "14px" }}>
                          Created by {this.state.createdByName + " "} on{" "}
                          {" " + Utils.getUtcToLocal(this.state.createdOn)}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="row">
                        <div
                          className="card-header d-flex align-items-center justify-content-between"
                          id="assetCardHeader"
                        >
                          <h5 className="mb-0" id="headerLabel">
                            Dimensions
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="row">
                                <label
                                  className="col-sm-3 col-form-label fw-bold"
                                  htmlFor="basic-default-name"
                                  id="assetFormLabel"
                                >
                                  height
                                </label>
                                <div className="col-sm-9" id="AssetCol">
                                  <span>
                                    {this.state.dimensionHeight
                                      ? this.state.dimensionHeight
                                      : " "}{" "}
                                    &nbsp;
                                    {this.state.heightUom !== null &&
                                    this.state.heightUom !== undefined
                                      ? this.state.heightUom.name
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row">
                                <label
                                  className="col-sm-3 col-form-label fw-bold"
                                  htmlFor="basic-default-name"
                                  id="assetFormLabel"
                                >
                                  Width
                                </label>
                                <div className="col-sm-9" id="AssetCol">
                                  <span>
                                    {this.state.dimensionWidth
                                      ? this.state.dimensionWidth
                                      : ""}{" "}
                                    &nbsp;
                                    {this.state.widthUom !== null &&
                                    this.state.widthUom !== undefined
                                      ? this.state.widthUom.name
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              {" "}
                              <div className="row">
                                <label
                                  className="col-sm-3 col-form-label fw-bold"
                                  htmlFor="basic-default-name"
                                  id="assetFormLabel"
                                >
                                  Length
                                </label>
                                <div className="col-sm-9" id="AssetCol">
                                  <span>
                                    {this.state.dimensionBreadth
                                      ? this.state.dimensionBreadth
                                      : ""}{" "}
                                    &nbsp;
                                    {this.state.breadthUom !== null &&
                                    this.state.breadthUom !== undefined
                                      ? this.state.breadthUom.name
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              {" "}
                              <div className="row">
                                <label
                                  className="col-sm-3 col-form-label fw-bold"
                                  htmlFor="basic-default-name"
                                  id="assetFormLabel"
                                >
                                  Weight
                                </label>
                                <div className="col-sm-9" id="AssetCol">
                                  <span>
                                    {this.state.dimensionWeight
                                      ? this.state.dimensionWeight
                                      : ""}{" "}
                                    &nbsp;
                                    {this.state.weightUom !== null &&
                                    this.state.weightUom !== undefined
                                      ? this.state.weightUom.name
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="row">
                        <div
                          className="card-header d-flex align-items-center justify-content-between"
                          id="assetCardHeader"
                        >
                          <h5 className="mb-0" id="headerLabel">
                            Price Information
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row text-start">
                            <div className="col-md-4">
                              <div className="row">
                                <label
                                  className="col-sm-3 col-form-label fw-bold"
                                  htmlFor="basic-default-name"
                                  id="assetFormLabel"
                                >
                                  Unit Price
                                </label>
                                <div className="col-sm-9" id="AssetCol">
                                  <span>{this.state.unitPrice}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row">
                                <label
                                  className="col-sm-3 col-form-label fw-bold"
                                  htmlFor="basic-default-name"
                                  id="assetFormLabel"
                                >
                                  Currency
                                </label>
                                <div className="col-sm-9" id="AssetCol">
                                  <span>
                                    {this.state.currency
                                      ? this.state.currency.name
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row">
                                <label
                                  className="col-sm-3 col-form-label fw-bold"
                                  htmlFor="basic-default-name"
                                  id="assetFormLabel"
                                >
                                  Tax Rate
                                </label>
                                <div className="col-sm-9" id="AssetCol">
                                  <span>
                                    {this.state.taxRate
                                      ? this.state.taxRate.description
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="row">
                              <label
                                className="col-sm-3 col-form-label fw-bold"
                                htmlFor="basic-default-name"
                                id="assetFormLabel"
                              >
                                Hsn Code
                              </label>
                              <div className="col-sm-9" id="AssetCol">
                                <span>{this.state.hsnCode}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <hr></hr>
                      <div className="row">
                        <div
                          className="card-header d-flex align-items-center justify-content-between"
                          id="assetCardHeader"
                        >
                          <h5 className="mb-0" id="headerLabel">
                            Custom Field Name
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">{assetPropertyList}</div>

                          </div>
                        </div>
                      </div> */}
                    </div>
                    <MaterialTable
                      className="customTable"
                      title="Custom Fields"
                      icons={UiUtils.getMaterialTableIcons()}
                      columns={[
                        {
                          title: "#",
                          render: (rowData) => rowData.tableData.id + 1,
                          width: "5%",
                          align: "left",
                          cellStyle: {
                            width: "6%",
                          },
                        },
                        {
                          title: "Name",
                          field: "name",
                          cellStyle: {
                            width: "25%",
                          },
                        },
                        {
                          title: "Type",
                          field: "type",
                          cellStyle: {
                            width: "25%",
                          },
                        },
                        {
                          title: "Value",
                          field: "value",
                          cellStyle: {
                            width: "25%",
                          },
                        },
                      ]}
                      data={this.state.assetPropertyList}
                      options={{
                        actionsColumnIndex: -1,
                        selectedTab: 1,
                        initialPage: this.state.initialPage,
                        pageSize: this.state.pageSize,
                        pageSizeOptions: Utils.materialTablePageSize(),
                        paging: this.state.isAssetPropertyListPaging,
                        headerStyle: {
                          backgroundColor: "#0B6F9D",
                          fontWeight: "bold",
                          color: "#ffff",
                          align: "left",
                        },
                      }}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <Row style={{ margin: "0em" }}>
                    <Col md={12} style={{ padding: "0em 0.5em" }}>
                      <TrackingHistory assetId={this.state.id} />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <Row style={{ margin: "0em" }}>
                    <Col md={12} style={{ padding: "0em 0.5em" }}>
                      <AuditLog assetId={this.state.id} />
                    </Col>
                  </Row>
                </TabPanel>
              </Tabs>
            </Col>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "2em",
                padding: "0em 0em 1em",
              }}
            >
              <BackButton
                type="button"
                style={{ backgroundColor: "#162c50", color: "#fff" }}
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={(e) => this.viewCancel()}
              >
                Back
              </BackButton>{" "}
            </div>
          </Row>
          <Modal
            open={this.state.createSimilarModal}
            title="Create Similar Assets"
            onClose={this.onCreateSimilarModalClose.bind(this)}
          >
            <ValidatorForm
              style={{ width: "100%" }}
              ref="form"
              onSubmit={this.createNumberOfAssetInBackend.bind(this)}
            >
              <div className="row">
                <div className="col-md-10" id="modalTextFiled">
                  <div className="row mb-3">
                    <label
                      className="col-sm-4 col-form-label"
                      htmlFor="basic-default-name"
                      id="modalFormLabel"
                    >
                      Number Of Assets*
                    </label>
                    <TextField
                      type="text"
                      required
                      className="textField"
                      fullWidth
                      variant="outlined"
                      placeholder="Number Of Assets*"
                      value={this.state.numberOfAsset}
                      onChange={this.handleNumberOfAssetChange.bind(this)}
                    />
                  </div>
                </div>
              </div>
              <div
                className="modal-footer"
                style={{ padding: "1.2rem 1.5rem 1.5rem" }}
              >
                {/* <PtsButton btnName="Create" type="submit" /> */}
                <CreateButton btnName="Create" type="submit" />
                <CancelIconButton
                  btnName="Cancel"
                  onClick={this.onCreateSimilarModalClose.bind(this)}
                />
              </div>
            </ValidatorForm>
          </Modal>
        </>
      );
    }
  }
  editAssetDetails() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let assetPropertyList = [];
      this.state.newProperties.forEach((item, index) => {
        if ("delete" !== item.action) {
          assetPropertyList.push(
            <AssetProperty
              ref={this.assetPropertyRefList[index]}
              key={index}
              data={item}
              index={index}
              action={item.action ? item.action : "view"}
              onRowDelete={this.handleDeleteRow.bind(this)}
            />
          );
        }
      });
      return (
        <>
          {typeof this.state.pageId !== "undefined" ? (
            <>
              <MenuWithBreadScrum
                // editCallback={this.editAssetData.bind(this)}
                // deleteCallback={this.deleteAssetData.bind(this)}
                // createSimilarCallback={this.createSimilarAsset.bind(this)}
                // getDeletePermission={this.state.getDeletePermission}
                // getUpdatePermission={this.state.getUpdatePermission}
                breadCrumb={this.state.breadCrumb}
                navigate={this.props.navigate}
                domainObject="Edit Asset"
                name="Edit Asset"
                pageId="AssetView"
                categoryId={this.state.categoryId}
                assetCycleId={this.state.assetCycleId}
                returnablePlanId={this.state.returnablePlanId}
              />{" "}
            </>
          ) : (
            <CatalogueBreadScrum
              // getDeletePermission={this.state.getDeletePermission}
              // getUpdatePermission={this.state.getUpdatePermission}
              breadCrumb={this.state.breadCrumb}
              catalogueId={this.state.catalogueId}
              categoryId={this.state.categoryId}
              onSuccess={() => {}}
              navigate={this.props.navigate}
              domainObject="Edit Asset"
              name="Edit Asset"
              pageId="AssetView"
            />
          )}
          <div className="row">
            <div className="col-md-12">
              <div className="card " id="assetFormCard">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="mb-0" id="headerLabel">
                    Asset Info
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Name<span className="asterisk"> *</span>
                      </label>

                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        variant="outlined"
                        placeholder="Name*"
                        value={this.state.assetName}
                        onChange={this.handleAssetNameChange.bind(this)}
                        id="basic-default-name"
                        required
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Asset Code<span className="asterisk"> *</span>
                        <Tooltip
                          placement="top"
                          title="Asset Codes are created by Admin Users in Settings"
                          id="password-requirements-tooltip"
                        >
                          <img src={infoIcon} alt="" width="20" height="20" />
                        </Tooltip>
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        required
                        options={this.state.assetCodeList}
                        value={this.state.assetCode}
                        defaultValue={this.state.assetCode}
                        getOptionLabel={(option) => option.code}
                        onChange={(event, value) => {
                          this.handleAssetCodeChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Asset Code*"
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Keywords
                      </label>
                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Keywords"
                        value={this.state.assetKeyword}
                        onChange={this.handleAssetKeywordChange.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Asset Type<span className="asterisk"> *</span>
                      </label>

                      <Autocomplete
                        id="multicol-country"
                        required
                        options={this.state.assetTypeList}
                        value={this.state.assetType}
                        defaultValue={this.state.assetType}
                        getOptionLabel={(option) => option.type}
                        onChange={(event, value) => {
                          this.handleAssetTypeChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Asset Type*"
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        UOM<span className="asterisk"> *</span>
                        <Tooltip
                          placement="top"
                          title="UOM are created by Admin Users in Settings"
                          id="password-requirements-tooltip"
                        >
                          <img src={infoIcon} alt="" width="20" height="20" />
                        </Tooltip>
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        required
                        options={this.state.assetUomList}
                        value={this.state.assetUom}
                        defaultValue={this.state.assetUom}
                        getOptionLabel={(option) => option.namecode}
                        onChange={(event, value) => {
                          this.handleAssetUmoChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Unit Of Measurement"
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        RFID
                      </label>
                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="RFID"
                        value={this.state.assetRfId}
                        onChange={this.handleAssetRfIdChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        PTS DEVICE ID
                      </label>
                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="PTS Device Id"
                        value={this.state.assetDeviceId}
                        onChange={this.handleAssetDeviceIdChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Supplier
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.state.supplierList}
                        value={this.state.supplierType}
                        defaultValue={this.state.supplierType}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.handleSupplierTypeChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Supplier"
                          />
                        )}
                      />
                      {/* <div className="row">
                        {this.state.supplierType &&
                          this.state.supplierType.name && (
                            <>
                              <label
                                className="col-sm-3 col-form-label fw-bold"
                                htmlFor="basic-default-name"
                                id="assetFormLabel"
                              >
                                Supplier
                              </label>
                              <div
                                className="col-sm-9"
                                id="AssetCol"
                                style={{ display: "inline-flex" }}
                              >
                                <span>
                                  {this.state.supplierType
                                    ? this.state.supplierType.name
                                    : ""}
                                </span>
                              </div>
                            </>
                          )}
                      </div> */}
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Description
                      </label>
                      <textarea
                        name="collapsible-address"
                        className="form-control"
                        style={{
                          color: "black",
                          font: "inherit",
                          fontSize: "16px ",
                        }}
                        id="collapsible-address"
                        rows="4"
                        placeholder="Description"
                        value={this.state.assetDescription}
                        onChange={this.handleAssetDescriptionChange.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "1em" }}>
            <div className="col-md-12">
              <div className="card" id="assetFormCard">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="mb-0" id="headerLabel">
                    Dimensions
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-4 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        height
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="height"
                        value={this.state.dimensionHeight}
                        onChange={this.handleDimensionHeightChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-4 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        UOM
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.uomList}
                        value={this.state.heightUom}
                        defaultValue={this.state.heightUom}
                        getOptionLabel={(option) =>
                          option.namecode !== null ? option.namecode : ""
                        }
                        onChange={(event, value) => {
                          this.handleHeightUmoChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Unit Of Measurement"
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-4 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        Width
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Width"
                        value={this.state.dimensionWidth}
                        onChange={this.handleDimensionWidthChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        UOM
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.uomList}
                        value={this.state.widthUom}
                        defaultValue={this.state.widthUom}
                        getOptionLabel={(option) =>
                          option.namecode !== null ? option.namecode : ""
                        }
                        onChange={(event, value) => {
                          this.handleWidthUmoChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Unit Of Measurement"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3" id="assetFormLabel">
                      {" "}
                      <label
                        className="col-sm-4 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        Length
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Length"
                        value={this.state.dimensionBreadth}
                        onChange={this.handleDimensionBreadthChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        UOM
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.uomList}
                        value={this.state.breadthUom}
                        defaultValue={this.state.breadthUom}
                        getOptionLabel={(option) =>
                          option.namecode !== null ? option.namecode : ""
                        }
                        onChange={(event, value) => {
                          this.handleBreadthUmoChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Unit Of Measurement"
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-4 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        Weight
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Weight"
                        value={this.state.dimensionWeight}
                        onChange={this.handleDimensionWeightChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-3" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        UOM
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.uomList}
                        value={this.state.weightUom}
                        defaultValue={this.state.weightUom}
                        getOptionLabel={(option) =>
                          option.namecode !== null ? option.namecode : ""
                        }
                        onChange={(event, value) => {
                          this.handleWeightUmoChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Unit Of Measurement"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "1em" }}>
            <div className="col-md-12">
              <div className="card" id="assetFormCard">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="mb-0" id="headerLabel">
                    Price Information
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        Unit Price
                      </label>

                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Unit Price"
                        value={this.state.unitPrice}
                        onChange={this.handleUnitPriceChange.bind(this)}
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        Currency
                      </label>
                      <Autocomplete
                        id="multicol-country"
                        options={this.currencyList}
                        value={this.state.currency}
                        defaultValue={this.state.currency}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.handleCurrencyChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Currency"
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        tax Rate
                      </label>

                      <Autocomplete
                        id="multicol-country"
                        options={this.taxRateList}
                        value={this.state.taxRate}
                        defaultValue={this.state.taxRate}
                        getOptionLabel={(option) => option.description}
                        onChange={(event, value) => {
                          this.handleTaxRateChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            variant="outlined"
                            placeholder="Tax Rate *"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-4" id="assetFormLabel">
                      <label
                        className="col-sm-5 col-form-label"
                        htmlFor="basic-default-name"
                      >
                        HSN Code
                      </label>

                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="HSN Code"
                        value={this.state.hsnCode}
                        onChange={this.handleHsnCodeChange.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "1em" }}>
            <div className="col-md-12">
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="mb-0" id="headerLabel">
                    Add Custom Fields
                  </h5>
                </div>
                <div className="card-body">
                  <div className="table text-nowrap">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Value</th>

                          <th>
                            <AddIcon
                              onClick={this.handleAddRow}
                              className="newPropertiesBtn"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ borderWidth: "1px" }}>
                        {assetPropertyList}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "1.2rem 1.5rem 1.5rem" }}>
            <SaveButton
              id="saveButton"
              btnName="Save"
              type="submit"
              onClick={(e) => this.updateAssetInBackend()}
            />
            &nbsp;
            {/* <button
              type="button"
              className="btn btn-label-secondary fw-bold"
              data-bs-dismiss="modal"
              style={{ background: "#8592a3", color: "#fff" }}
              onClick={this.editCancel.bind(this)}
            >
              Cancel
            </button> */}
            <CancelIconButton
              btnName="Cancel"
              onClick={this.editCancel.bind(this)}
            />
          </div>
        </>
      );
    }
  }
}

export default withRouter(Asset);
