import React, { Component } from 'react'
import TextField from "@material-ui/core/TextField";
import DeleteIconButton from '../../buttons/DeleteIconButton';
import EditIconButton from '../../buttons/EditIconButton';
import SaveButtons from '../../buttons/SaveButtons';
import CancelButtons from '../../buttons/CancelButtons';

class UnitOfMeasurement extends Component {
    uomRefList = [];
    constructor(props) {
        super(props);
        let action = props.action;
        let data = props.data;

        this.state = {
            action: action,
            id: data.id,
            abbrevition: data.abbrevition,
            measuretype: data.measuretype,
            name: data.name,
            operation: data.operation,
            srno: props.srno,
            iteamIndex: props.iteamIndex,
            onUomAdd: props.onAdd,
            onUomEdit: props.onEdit,
            onUomDelete: props.onUomDelete,
            onUomUpdateSave: props.onUomUpdateSave,
            onUomRemove: props.onUomRemove,
            onUomEditClick: props.onUomEditClick,
            key: props.key,
            render: false,
        };
    }

    componentDidMount() {
        this.setState({
            id: this.props.data.id,
        });
    }

    componentWillReceiveProps(props) {
        this.setState({
            action: props.action,
            id: props.data.id,
            abbrevition: props.data.abbrevition,
            measuretype: props.data.measuretype,
            name: props.data.name,
            operation: props.data.operation,
            srno: props.srno,
            iteamIndex: props.iteamIndex,
            onUomAdd: props.onAdd,
            onUomEdit: props.onEdit,
            onUomDelete: props.onUomDelete,
            onUomUpdateSave: props.onUomUpdateSave,
            onUomRemove: props.onUomRemove,
            onUomEditClick: props.onUomEditClick,
            key: props.key,
            render: true,
        });
    }

    onUomAdd() {
        if (this.state.onUomAdd) {
            this.state.onUomAdd(this.state);
        }
    }

    handleNameChange(e) {
        this.setState({ name: e.target.value });
    }

    handleAbbrevitionChange(e) {
        this.setState({
            abbrevition: e.target.value,
        })
    }
    handleMeasureTypeChange(e) {
        this.setState({
            measuretype: e.target.value,
        })
    }

    updateNotes(e) {
        if (this.state.onNoteUpdate) {
            this.setState({ action: 'view' });
            this.state.onNoteUpdate(this.state.key);
        }
    }

    deleteUom(e) {
        if (undefined !== this.state.onUomDelete && null !== this.state.onUomDelete) {
            this.props.onUomDelete(this.state);
        }
    }

    onUomEditClick(id) {
        this.setState({ action: 'edit' });
    }


    updateUom(e) {
        if (this.state.onUomUpdateSave) {
            this.setState({ action: 'view' });
            this.state.onUomUpdateSave(this.state);
        }
    }

    removeUom() {
        if (this.state.onUomRemove) {
            this.state.onUomRemove(this.state);
        }
    }

    editUomCancel() {
        this.setState({
            action: "view",
        })
    }

    render() {
        if (this.state.action === "create") {
            return this.addUom()
        } else if (this.state.action === "view") {
            return this.viewUom()
        } else if (this.state.action === "edit") {
            return this.editUom()
        }
    }

    addUom() {
        return (
            <>
                < tr >
                    <td style={{ width: "5%" }}></td>
                    <td>
                        <TextField
                            type="text"
                            fullWidth
                            className="textField"
                            // style={{ width: "310px" }}
                            variant="outlined"
                            placeholder="Name"
                            value={this.state.name}
                            onChange={this.handleNameChange.bind(this)}
                            required
                        />
                    </td>
                    <td>
                        <TextField
                            type="text"
                            fullWidth
                            className="textField"
                            // style={{ width: "310px" }}
                            variant="outlined"
                            placeholder="Abbrevition"
                            value={this.state.abbrevition}
                            onChange={this.handleAbbrevitionChange.bind(this)}
                            required
                        />
                    </td>
                    <td>
                        <TextField
                            type="text"
                            fullWidth
                            className="textField"
                            // style={{ width: "310px" }}
                            variant="outlined"
                            placeholder="Measure Type"
                            value={this.state.measuretype}
                            onChange={this.handleMeasureTypeChange.bind(this)}
                            required
                        />
                    </td>
                    <td style={{ width: "20%", alignItems: "center", padding: "10px" }}>
                        <CancelButtons
                            onClick={this.removeUom.bind(this)}
                        />
                        <SaveButtons onClick={this.onUomAdd.bind(this)} />
                    </td>
                </tr>
            </>
        )
    }

    viewUom() {
        return (
            <>
                <tr
                    className="hoverTable"
                    key={this.props.iteamIndex}
                >
                    <td style={{ width: "5%" }}>
                        {this.props.iteamIndex}
                    </td>
                    <td style={{ width: "20%" }}>{this.state.name}</td>
                    <td style={{ width: "20%" }}>{this.state.abbrevition}</td>
                    <td style={{ width: "20%" }}>{this.state.measuretype}</td>
                    <td style={{ width: "20%" }}>
                        <DeleteIconButton
                            onClick={(e) => this.deleteUom(e)}
                            title="UOM"
                            domainObject={this.state.name}
                        />
                        <EditIconButton
                            onClick={this.onUomEditClick.bind(this)}
                        />
                    </td>
                </tr>
            </>
        )
    }

    editUom() {
        return (
            <>
                < tr >
                    <td style={{ width: "5%" }}>
                        {this.props.iteamIndex}
                    </td>
                    <td>
                        <TextField
                            type="text"
                            fullWidth
                            className="textField"
                            // style={{ width: "310px" }}
                            variant="outlined"
                            placeholder="Name"
                            value={this.state.name}
                            onChange={this.handleNameChange.bind(this)}
                            required
                        />
                    </td>
                    <td>
                        <TextField
                            type="text"
                            fullWidth
                            className="textField"
                            // style={{ width: "310px" }}
                            variant="outlined"
                            placeholder="Abbrevition"
                            value={this.state.abbrevition}
                            onChange={this.handleAbbrevitionChange.bind(this)}
                            required
                        />
                    </td>
                    <td>
                        <TextField
                            type="text"
                            fullWidth
                            className="textField"
                            // style={{ width: "310px" }}
                            variant="outlined"
                            placeholder="Measure Type"
                            value={this.state.measuretype}
                            onChange={this.handleMeasureTypeChange.bind(this)}
                            required
                        />
                    </td>
                    <td style={{ width: "20%", alignItems: "center", padding: "10px" }}>
                        <CancelButtons
                            onClick={this.editUomCancel.bind(this)}
                        />
                        <SaveButtons onClick={this.updateUom.bind(this)} />
                    </td>
                </tr>
            </>
        )
    }
}
export default UnitOfMeasurement;