import { GET_CUSTOMER, SET_CUSTOMER } from "./CustomerTypes";

const initialState = {
    customerList: {},
};

const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER:
      return {
        ...state,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customerList: action.payload,
      };

    default:
      return state;
  }
};

export default CustomerReducer;
