import { GET_TRACKINGHISTORY, SET_TRACKINGHISTORY } from "./TrackingHistoryTypes";

const initialState = {
  history: {},
};

const TrackingHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRACKINGHISTORY:
      return {
        ...state,
      };
    case SET_TRACKINGHISTORY:
      return {
        ...state,
        history: action.payload,
      };
    default:
      return state;
  }
};

export default TrackingHistoryReducer;
