import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { NavLink } from "react-router-dom";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import HeaderAction from "../../redux/header/HeaderAction";
import StaticDataAction from "../../redux/staticData/StaticDataAction";
import OrganizationAction from "../../redux/organization/OrganizationAction";
import store from "../../redux/store";
import { withRouter } from "../../withRouter";
import { Autocomplete } from "@mui/material";
import logo from "../../assets/images/AssetTrackifierLogo.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./Signin.css";
import AppUserDataAction from "../../redux/appUserData/AppUserDataAction";
import MenuAction from "../../redux/menu/MenuAction";
export class Signin extends Component {
  constructor(props) {
    super(props);
    let email = "";
    let tenantList = [];
    let render = false;
    if (props.state) {
      email = props.state.email;
      tenantList = props.state.tenantList;
      render = props.state.render;
    }
    this.state = {
      email: email,
      tenantList: tenantList,
      showPassword: false,
      showConfirmPassword: false,
      ischeckbox: false,
      isTenantCheckbox: false,
      tenantId: tenantList[0],
      companyList: [],
      render: render,
    };
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleClickCheckBox = (e) => {
    this.setState({
      ischeckbox: e.target.checked,
    });
  };

  handleCompanyChange = (selectedValue) => {
    this.setState({ tenantId: selectedValue });
  };
  processLoginSuccess(output_data) {
    let headerObj = output_data.header;
    store.dispatch(HeaderAction.setHeader(headerObj));

    let organizationObj = {
      organizationName: output_data.organization.name,
      organizationLogo: output_data.organization.logo,
      organizationAddress: output_data.organization.address,
    };
    store.dispatch(OrganizationAction.setOrganization(organizationObj));
    store.dispatch(MenuAction.setMenu(output_data.menuList));

    for (let i = 0; i < output_data.menuList.length; i++) {
      const menu = output_data.menuList[i];
      if (menu.isactive) {
        let activeMenu = menu.id;
        store.dispatch(MenuAction.setActiveMenu(activeMenu));
        break;
      }
    }

    let appuser = {
      email: output_data.appuser.email,
      id: output_data.appuser.id,
      firstName: output_data.appuser.firstName,
      lastName: output_data.appuser.lastName,
    };
    store.dispatch(AppUserDataAction.setUserData(appuser));

    let header = headerObj;
    this.loadDashboardPage(header, output_data);
  }
  async loadDashboardPage(header, output) {
    let menus = output.menuList;

    Utils.setFCMTokenForUser();
    await this.setStaticData(header);

    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i];
      if (menu.isactive) {
        this.props.navigate(menu.pageid);
        break;
      }
    }
  }
  async setStaticData(header) {
    const postObject = {
      header: header,
    };

    FetchServerData.callPostService("/staticms/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          store.dispatch(StaticDataAction.setStaticData(output.data));
        } else {
        }
      }
    );
  }

  loginInBackend = (e) => {
    let header = {
      email: this.state.email,
      tid: this.state.tenantId.id,
      appclient: "W",
    };
    const postObject = {
      header: header,
      appuser: {
        email: this.state.email,
        credential: this.state.password,
      },
    };

    FetchServerData.callPostService("/appuserms/login", postObject)
      .then((response) => {
        if (Utils.equalsIgnoreCase(response.status, "SUCCESS")) {
          let output = response.data;
          this.processLoginSuccess(output);
        } else if (Utils.equalsIgnoreCase(response.status, "FAIL")) {
          if (
            response.errorCode &&
            Utils.equalsIgnoreCase(response.errorCode, "RVF_101")
          ) {
            // /* Verification is Not Yet done, Go To Verification Page */
            // this.props.history.push({
            //   pathname: "/verification",
            //   state: { email: this.state.email, url: "/login" },
            // });
          }
        }
      })
      .catch((error) => {});
  };
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <div className="authentication-wrapper authentication-cover">
            <div className="authentication-inner row">
              {/* /Left Text */}
              <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center leftBackground ">
                <div className="mx-auto ">
                  <div className="logoBackground ">
                    <img
                      src={logo}
                      className="img-fluid logoImage"
                      alt=""
                      data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                      data-app-light-img="illustrations/girl-with-laptop-light.png"
                    />
                  </div>
                  <div className="align-items-center">
                    <h2 className="logoHeding">AssetTrackifier</h2>
                  </div>
                </div>
              </div>
              {/* /Left Text */}
              {/* Register */}
              <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center authentication-bg p-sm-5 p-4">
                <div className="w-px-400 mx-auto">
                  <div className="signinHeding">Sign In</div>
                  <ValidatorForm
                    ref="form"
                    id="formAuthentication"
                    className="mb-3"
                    onSubmit={this.loginInBackend.bind(this)}
                  >
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label"
                        id="signupFormLabel"
                      >
                        EMAIL ID <span className="asterisk">*</span>
                      </label>
                      <TextValidator
                        id="email"
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        placeholder="Enter Email *"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleEmailChange.bind(this)}
                        autoComplete="off"
                        validators={["required", "isEmail"]}
                        errormessages={[
                          "This field is required",
                          "Email id is not valid",
                        ]}
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label"
                        id="signupFormLabel"
                      >
                        Company name
                        <span className="asterisk">*</span>
                      </label>
                      <Autocomplete
                        id="tenantList"
                        options={this.state.tenantList}
                        value={this.state.tenantId}
                        defaultValue={this.state.tenantId}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.handleCompanyChange(value);
                        }}
                        inputprops={{ style: { textTransform: "capitalize" } }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Company"
                            variant="outlined"
                            required
                            margin="normal"
                          />
                        )}
                      />
                    </div>
                    <div className="mb-3 form-password-toggle">
                      <label
                        htmlFor="password"
                        className="form-label"
                        id="signupFormLabel"
                      >
                        PASSWORD <span className="asterisk">*</span>
                      </label>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        value={this.state.password}
                        name="password"
                        placeholder="Enter Password *"
                        type={this.state.showPassword ? "text" : "password"}
                        id="password"
                        autoComplete="current-password"
                        onChange={this.handlePasswordChange.bind(this)}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword.bind(
                                  this
                                )}
                              >
                                {this.state.showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <NavLink
                      to="/forgotPasswordEmail"
                      style={{ float: "right", marginBottom: "10px" }}
                    >
                      <span>Forgot Password?</span>
                    </NavLink>
                    <button className="siginButton d-grid w-100">
                      Sign in
                    </button>
                  </ValidatorForm>
                  <div className="d-flex flex-row">
                    <NavLink className="pt-1" to="/signinEmail">
                      <ArrowBackIosNewIcon />
                      Back Sign In
                    </NavLink>
                    <p className="ms-5 ps-4 text-end">
                      <span>Do not have an account?</span>&nbsp;
                      <NavLink to="/signup">
                        <span>Sign Up</span>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default withRouter(Signin);
