import React, { Component } from 'react'
import { Row, Col } from "reactstrap";
import PrototTSolutions from "../../assets/images/PrototTSolutions.png"
export default class AboutUsContent extends Component {
    render() {
        return (
            <div
                id="top"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
                <div
                    className="container"
                    style={{ border: "1px solid lightgray", padding: "2em" }}
                >
                    <Row>
                        <Col md={12}>
                            <h3 style={{ color: "#000" }}>
                                <b>
                                    <u>About Us</u>
                                </b>
                            </h3>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "1em",
                            fontWeight: "400",
                            lineHeight: "28px",
                        }}
                    >
                        <Col md={12} style={{ textAlign: "center" }}>
                            <p>
                                The tracking and managing of assets are more than just the provision of software and hardware.
                                These assets can be searched, planned, tracked, and traded easily. We provide the tools and all
                                the support to manage assets more efficiently.
                            </p>
                            <br></br>
                            <p style={{ fontSize: "16px", color: "rgb(11, 111, 157)" }}><b>Product of Proto technology Solutions</b></p>
                            <div className='row' >
                                <div className='col-12' style={{ textAlign: "center" }}>
                                    <img alt="" src={PrototTSolutions} style={{ width: "380px", height: "280px", fontSize: "24px", padding: "0.5em" }} />
                                </div>
                            </div>
                            {/* <p style={{ fontSize: "16px", color: "rgb(11, 111, 157)" }}><b>Proto technology Solutions</b></p> */}
                            <b>
                                <a href="http://www.prototsolutions.com/" target="_blank" rel="noreferrer">
                                    http://www.prototsolutions.com
                                </a>
                            </b>
                        </Col>
                    </Row>
                </div>
            </div >
        )
    }
}
