import React, { Component } from "react";
import { Col } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import PtsAlert from "../../ptsAlert/PtsAlert";
import { withRouter } from "../../../withRouter";
import store from "../../../redux/store";
import Utils from "../../../provider/Utils";
import PtsButton from "../../buttons/PtsButton";
import UnitOfMeasurement from "./UnitOfMeasurement";

class UnitOfMeasurementList extends Component {
  header = store.getState().header.header;
  constructor() {
    super();
    this.state = {
      initialPage: 0,
      pageSize: 10,
      uomList: [],
      render: false,
      getCreatePermission: false,
      getUpdatePermission: false,
      getDeletePermission: false,
    };
    this.getUomList();
  }
  getUomList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/uomms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let uomList = output.data.uomList;
          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getCreatePermission = getPermission[1];
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let isPaging = false;
          if (uomList.length > this.state.pageSize) {
            isPaging = true;
          }
          this.setState({
            render: true,
            uomList: uomList,
            isPaging: isPaging,
            initialPage: this.state.initialPage,
            getCreatePermission: getCreatePermission,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        } else {
        }
      }
    );
  }

  createUomToBackend(newData) {
    for (let i = 0; i < this.state.uomList.length; i++) {
      if (
        this.state.uomList[i] &&
        newData.srno === i &&
        this.uomRefList[i] &&
        this.uomRefList[i].current
      ) {
        const postObject = {
          header: this.header,
          uom: {
            name: this.uomRefList[i].current.state.name,
            abbrevition: this.uomRefList[i].current.state.abbrevition,
            measuretype: this.uomRefList[i].current.state.measuretype,
          },
        };
        FetchServerData.callPostService("/uomms/create", postObject).then(
          (output) => {
            if (output.status === "SUCCESS") {
              PtsAlert.success(output.message);
              let uomInfo = output.data.uom;
              this.uomRefList[i].current.state.action = "view";
              this.setState({
                render: true,
                action: "view",
                uomList: [uomInfo, ...this.state.uomList],
              });
            } else {
            }
          }
        );
      }
    }
  }

  updateUomToBackend(newData) {
    const postObject = {
      header: this.header,
      uom: {
        name: newData.name,
        abbrevition: newData.abbrevition,
        measuretype: newData.measuretype,
        id: newData.id,
      },
    };
    FetchServerData.callPostService("/uomms/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let uomData = output.data.uom;
          var currentIndex = this.state.uomList.findIndex(
            (d) => d.id === uomData.id
          );
          let currentList = [...this.state.uomList];
          currentList.splice(currentIndex, 1, uomData);
          this.setState({
            render: true,
            uomList: currentList,
            isOpen: false,
          });
        } else {
        }
      }
    );
  }
  deleteUomToBackend = (data) => {
    const postObject = {
      header: this.header,
      uom: {
        id: data.id,
      },
    };
    FetchServerData.callPostService("/uomms/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // var newList = output.data.uom;
          for (let i = 0; i < this.state.uomList.length; i++) {
            if (this.state.uomList[i].id === data.id) {
              this.state.uomList.splice(i, 1);
            }
          }
          this.setState({ uomList: this.state.uomList, render: true });
          PtsAlert.success(output.message);
        } else {
        }
      }
    );
  };

  addUom() {
    let uomInfos = this.state.uomList;
    let uomInfo = {
      action: "create",
      operation: "add",
      name: "",
      abbrevition: "",
      materialtype: "",
    };
    uomInfos.push(uomInfo);
    this.setState({
      assetCodeList: uomInfos,
      addRow: true,
      action: "create",
    });
  }

  onUomRemove(item) {
    let items = [];
    if (item.operation && "add" === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.srno;
      for (let i = 0; i < this.state.uomList.length; i++) {
        if (this.state.uomList[i].operation === "add" && index === i) {
          this.uomRefList.splice(i, 1);
        } else {
          items.push(this.state.uomList[i]);
        }
      }
      this.setState({ uomList: items, addRow: false });
    }
  }
  onUomEditClick(e) {
    console.log(e);
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    this.uomRefList = [];
    for (let i = 0; this.state.uomList && i < this.state.uomList.length; i++) {
      this.uomRefList[i] = React.createRef();
    }

    let uomItemList = [];
    this.state.uomList &&
      this.state.uomList.forEach((element, index) => {
        uomItemList.unshift(
          <UnitOfMeasurement
            ref={this.uomRefList[index]}
            key={index}
            data={element}
            backgroundColor={
              index % 2 ? { background: "#EFF4F7" } : { background: "#F7F7F7" }
            }
            srno={index}
            action={element.action ? element.action : "view"}
            // ListRow={this.state.etinListRow}
            iteamIndex={this.state.uomList.length - index}
            onAdd={this.createUomToBackend.bind(this)}
            onUomEditClick={this.onUomEditClick.bind(this)}
            onUomDelete={this.deleteUomToBackend.bind(this)}
            onUomUpdateSave={this.updateUomToBackend.bind(this)}
            onUomRemove={this.onUomRemove.bind(this)}
          />
        );
      });

    return (
      <>
        <div style={{ maxWidth: "100%" }}>
          <div style={{ maxWidth: "100%" }}>
            <div className="row">
              <Col md={12} style={{ flexDirection: "column", display: "flex" }}>
                <div className="card">
                  <div className="row justify-content-between p-3">
                    <div className="d-flex col-12 col-lg-4 col-xl-4">
                      <div className="col-12 text-start totalResults">
                        {/* <span>Total Results:</span> */}
                      </div>
                    </div>
                    <div className="d-flex col-12 col-lg-8 col-xl-8">
                      <div className="col-12 text-end">
                        {/* <input
                        type="search"
                        id="gsearch"
                        placeholder="Search"
                        name="gsearch"
                        style={{ marginTop: "2px" }}
                      // value={this.state.searchText}
                      // onChange={this.onSearchChange.bind(this)}
                      />
                      <button
                        className="gsearchButton"
                      // onClick={this.onSearchClick.bind(this)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button> */}{" "}
                        &nbsp;&nbsp;
                        {/* {this.state.addRow ? */}
                        <PtsButton
                          btnName="Add New Measure"
                          type="submit"
                          onClick={this.addUom.bind(this)}
                        />
                        {/* :
                          <Button
                            className="add"
                            style={{
                              background: "rgb(27, 113, 137)",
                              color: "white",
                              fontSize: "x-larger",
                              textTransform: "capitalize",
                              cursor: "pointer",
                              float: "right",
                              margin: "0.2em 0.5em 0.3em 0em",
                            }}
                            startIcon={<AddIcon />}
                          >
                            Add Asset Code
                          </Button>
                        } */}
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table text-start table-hover">
                      <thead className="tableThead">
                        <tr>
                          <th style={{ width: "5%" }} scope="col fw-bold ">
                            #
                          </th>
                          <th style={{ width: "20%" }} scope="col fw-bold">
                            Name
                          </th>
                          <th style={{ width: "20%" }} scope="col fw-bold">
                            Abbreviation
                          </th>
                          <th style={{ width: "20%" }} scope="col fw-bold">
                            Material Type
                          </th>
                          <th style={{ width: "20%" }} scope="col fw-bold">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>{uomItemList}</tbody>
                    </table>
                  </div>
                  {this.state.totalRecords === 0 && (
                    <div className="row text-center p-2">
                      <h5>Data not Available</h5>
                    </div>
                  )}
                </div>
              </Col>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(UnitOfMeasurementList);
