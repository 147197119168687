import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import PtsButton from "../buttons/PtsButton";
class PtsConfirmDialogue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: props.title,
      text: props.text,
      confirmCallback: props.confirmCallback,
      notConfirmCallback: props.notConfirmCallback,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleConfirmYes = () => {
    if (this.state.confirmCallback) {
      this.state.confirmCallback();
    } else if (this.props.confirmCallback) {
      this.props.confirmCallback();
    }
    this.setState({ open: false });
  };
  handleConfirmNo = () => {
    this.handleClose();
  };
  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose.bind(this)}
        // onHide={this.close}
        style={{ padding: "1em" }}
      >
        <DialogContent>
          <Row style={{ marginRight: "auto", marginLeft: "auto" }}>
            <Col md={12} style={{ textAlign: "center" }}>
              <ErrorOutlineIcon
                style={{
                  border: "2px solid #fff",
                  borderRadius: "25%",
                  background: "#066f72 !important",
                  color: "red",
                  fontSize: "50px",
                  width: "300px"
                }}
              />
            </Col>
          </Row>
          <span style={{ textAlign: "center" }}>
            <p style={{ fontSize: "19px", color: "#000", fontWeight: "600" }}>
              Are you sure about deleting
            </p>
            <p style={{ fontSize: "19px", color: "#000", fontWeight: "600" }}>
              {/* It cannot be recovered once deleted? */}
              {this.state.title}
            </p>
            <h6
              style={{ color: "#0f666b", fontWeight: "bold", fontSize: "20px" }}
            >
              {" "}
              {this.state.text}
            </h6>{" "}
          </span>
        </DialogContent>
        <DialogActions
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <PtsButton
            btnName="Delete"
            onClick={this.handleConfirmYes.bind(this)}
          />

          <button
            type="button"
            className="btn btn-default "
            style={{
              background: "#717373",
              color: "#fff",
            }}
            onClick={this.handleConfirmNo.bind(this)}
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PtsConfirmDialogue;
