import GoogleMapReact from "google-map-react";
import React from "react";
import Geocode from "react-geocode";
// import '../../scss/styles.scss';
import Marker from "./Marker";
// implementation of this function is needed for codesandbox example to work
// you can remove it otherwise
const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    // return distance between the marker and mouse pointer
    return Math.sqrt(
      (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
    );
  }
};

Geocode.setApiKey("AIzaSyC2X652RPdeR7LYXtbK6X7Vemh_VEQdgMM");
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

export default function Map(props) {
  let Lat = 20.5937;
  let Long = 78.9629;
  // if (props.markers) {
  //   props.markers.map((marker) => {
  //     console.log(marker);
  //     //  return <Marker lat={marker.lati} lng={marker.longi} marker={marker} />;

  //   });
  // }
  if (props.markers !== null && props.markers) {
    for (let i = 0; i < props.markers.length; i++) {
      Lat = props.markers[i].lati;
      Long = props.markers[i].longi;
    }
  }
  return (
    <GoogleMapReact
      style={{ position: "absolute !important", height: "20em" }}
      yesIWantToUseGoogleMapApiInternals={true}
      bootstrapURLKeys={{
        key: "AIzaSyC2X652RPdeR7LYXtbK6X7Vemh_VEQdgMM",
        language: "en",
        region: "IN",
      }}
      // defaultCenter={{ lat: 20.5937, lng: 78.9629 }}
      defaultCenter={{
        lat: Lat,
        lng: Long,
      }}
      defaultZoom={5}
      distanceToMouse={distanceToMouse}
    >
      {props.markers &&
        props.markers.map((marker, index) => {
          return (
            <Marker key={index} lat={marker.lati} lng={marker.longi} marker={marker} />
          );
        })}
    </GoogleMapReact>
  );
}
