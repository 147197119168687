import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import "./Signup.css";
import { NavLink } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton, InputAdornment } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import Checkbox from "@material-ui/core/Checkbox";
import PtsAlert from "../ptsAlert/PtsAlert";
import { TextValidator } from "react-material-ui-form-validator";
import RefreshIcon from "@mui/icons-material/Refresh";
import Utils from "../../provider/Utils";
import debounce from "lodash.debounce";
import PasswordRequirementsTooltip from "../../ui/passwordRequirementsTooltip/passwordRequirementsTooltip";
import FetchServerData from "../../provider/FetchServerData";
import logo from '../../assets/images/AssetTrackifierLogo.png'
import { withRouter } from "../../withRouter";
import Box from '@mui/material/Box';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import TermsConditions from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy";

export class Signup extends Component {
  stateListArray = [];
  constructor(props) {
    super(props);
    let urlParam = window.location.href;
    urlParam = urlParam.split("?RC")[1];
    let refered_by = "";
    if (urlParam !== undefined) {
      refered_by = "RC" + urlParam;
    } else {
      refered_by = "";
    };

    this.state = {
      fname: "",
      lname: "",
      fnameHelperText: "",
      lnameHelperText: "",
      open: false,
      email: "",
      refered_by: refered_by,
      confirmPassword: "",
      confirmPasswordHelperText: "",
      password: "",
      passwordHelperText: "",
      organization: "",
      showPassword: false,
      showConfirmPassword: false,
      country: null,
      state: null,
      countryListArray: [],
      checkbox: false,
      error: {
        one: true,
        two: true,
        three: true,
        four: true,
        five: true,
      },
      passwordFocussed: false,
      companyName: "",
      phone: "",
      activeStep: true,
      activeStep1: false,
      isDisabled: true,
      completed: {},
      newCompleted: "",
      termsConditionModal: false,
      privacyPolicyModal: false,
    };

    this.getCountryList();
  };
  getCountryList() {
    const postObject = {
      header: {},
    };
    FetchServerData.callPostService('/countryms/list', postObject).then((output) => {
      if (output.status === "SUCCESS") {
        let countryList = output.data;
        let defaultCountry = countryList[countryList.findIndex((i) => i.code.toLowerCase() === "ind".toLowerCase())]
        this.setState({
          render: true,
          countryListArray: countryList,
          country: defaultCountry,
        });
        this.handleCountryChange(defaultCountry)
      } else {
      };
    });
  };
  handleOrgNameChange(e) {
    this.setState({ orgName: e.target.value });
  };
  handleOrgPhoneChange(e) {
    let res = e.target.value;
    if (res.length <= 10) {
      this.setState({ orgPhone: res })
    };
  };
  handleOrgGSTNChange(e) {
    this.setState({ orgGstn: e.target.value });
  };

  handleOrgGSTNOnBlur(e) {
    let orgGstn = this.state.orgGstn;
    let panNo = orgGstn?.substr(2, 10);
    this.setState({ orgPanNo: panNo });
  }

  handleOrgPANChange(e) {
    this.setState({ orgPanNo: e.target.value })
  };
  handleOrgWebsiteChange(e) {
    this.setState({ orgWebsite: e.target.value })
  };
  handleOrgFaxChange(e) {
    this.setState({ orgFax: e.target.value })
  };
  handleLineChange(e) {
    this.setState({ line: e.target.value })
  };
  handleFirstNameChange(e) {
    this.setState({ fname: e.target.value, fnameHelperText: "" });
    this.debouncedValidateName(e.target.value);
  };
  debouncedValidateName = debounce((fname) => this.validateName(fname), 1000);
  validateName = (fname) => {
    if (Utils.matchSpecialCharacters(fname)) {
      this.setState({ fnameHelperText: "No special characters are allowed" });
    } else if (fname.match(/[0-9]/)) {
      this.setState({ fnameHelperText: "No numbers are allowed" });
    }
  };
  handleLastNameChange(e) {
    this.setState({ lname: e.target.value, lnameHelperText: "" });
    this.debouncedValidateLastName(e.target.value);
  };
  debouncedValidateLastName = debounce((lname) => this.validateLastName(lname), 1000);
  validateLastName = (lname) => {
    if (Utils.matchSpecialCharacters(lname)) {
      this.setState({ lnameHelperText: "No special characters are allowed" });
    } else if (lname.match(/[0-9]/)) {
      this.setState({ lnameHelperText: "No numbers are allowed" });
    };
  };

  toggleFocusNewPassword = () => {
    this.setState((prevState) => {
      return { passwordFocussed: !prevState.passwordFocussed };
    });
  };

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  };
  handleReferralCodeChange(e) {
    this.setState({ refered_by: e.target.value });
  };
  handlePhoneChange(e) {
    let res = e.target.value;
    if (res.length <= 10) {
      this.setState({ phone: res });
    };
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === "password") {
      this.debouncedValidatePassword(value);
    } else if (name === "confirmPassword") {
      this.debouncedValidatePassword(this.state.password);
    };
  };

  debouncedValidatePassword = debounce(
    (password) => this.validatePassword(password),
    1000
  );
  validatePassword = (password) => {
    const { confirmPassword } = this.state;
    let one = false,
      two = false,
      three = false,
      four = false,
      five = false,
      confirmPasswordHelperText = "";

    if (password.length < 8 || password.length > 16) {
      one = true;
    };
    if (!password.match(/[A-Z]/)) {
      two = true;
    };
    if (!password.match(/[a-z]/)) {
      three = true;
    };
    if (!password.match(/[\d]/)) {
      four = true;
    };
    if (!password.match(/[\W]/)) {
      five = true;
    };

    if (password !== confirmPassword) {
      confirmPasswordHelperText = "Password & confirm password must match";
    };

    if (one || two || three || four || five) {
      this.setState({
        error: { one: one, two: two, three: three, four: four, five: five },
        confirmPasswordHelperText: confirmPasswordHelperText,
        passwordHelperText: "Password requirements not met",
      });
      return;
    };

    this.setState({
      disable: false || password !== confirmPassword,
      error: {},
      confirmPasswordHelperText: confirmPasswordHelperText,
      passwordHelperText: "",
    });
  };

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  };
  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleClickShowConfirmPassword() {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleClickCheckBox = (e) => {
    if (e.target.checked === true) {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: false,
      });
    } else {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: true,
      });
    }
  };
  handleCountryChange(selectedCountry) {
    if (null === selectedCountry || selectedCountry === "") {
      this.stateListArray = [];
      return;
    };
    this.state.country = selectedCountry;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    };

    this.setState({
      country: selectedCountry,
      state: null
    });

    const postObject = {
      header: {},
      country: { id: selectedCountry.id },
    };
    FetchServerData.callPostService("/countryms/get", postObject).then((output) => {
      if (output.status === "SUCCESS") {
        let stateTimezoneList = output.data;
        this.stateListArray = stateTimezoneList.states;
        this.setState({
          render: true,
        });
      } else {
      };
    });
  };
  handleStateChange(selectedState) {
    this.state.state = selectedState;
    this.setState({ state: selectedState });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    };
  };
  createAccountInBackend = (e) => {
    e.preventDefault();
    const { fname, lname, email, password, phone, orgName, orgPhone, orgFax, orgWebsite, orgPanNo, orgGstn } = this.state;
    const postObject = {
      header: {},
      registration: {
        firstName: fname,
        lastName: lname,
        phone: phone,
        email: email,
        credential: password,
        orgName: orgName,
        orgPhone: orgPhone,
        orgFaxnumber: orgFax,
        orgWebsite: orgWebsite,
        orgPanno: orgPanNo,
        orgGstn: orgGstn,
        state: {
          id: this.state.state.id
        }
      }
    };
    FetchServerData.callPostService('/regms/create', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          // this.props.navigate({
          //   pathname: '/verifyOtp',
          //   state: { email: this.state.email },
          // });
          this.props.navigate('/confirmationEmail', { state: { email: this.state.email } });
        } else {
        };
      })
      .catch((error) => { });
  };

  renderSignupButton = () => {
    let disable = true;
    const {
      fname,
      lname,
      fnameHelperText,
      lnameHelperText,
      email,
      confirmPassword,
      confirmPasswordHelperText,
      password,
      passwordHelperText,
      country,
      checkbox,
    } = this.state;

    if (
      fname !== "" &&
      lname !== "" &&
      fnameHelperText === "" &&
      lnameHelperText === "" &&
      !Utils.validateEmail(email) &&
      country !== null &&
      country !== "" &&
      password !== "" &&
      passwordHelperText === "" &&
      confirmPassword === password &&
      confirmPasswordHelperText === "" &&
      checkbox
    ) {
      disable = false;
    }

    return (
      <button
        className="signupButton d-grid w-50"
        type="submit"
        disabled={disable}
      >
        Sign up
      </button>
    );
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  termsConditionToggle = () => {
    this.setState({
      open: false,
      termsConditionModal: !this.state.termsConditionModal,
    });
  };

  openTermsCondition() {
    this.setState({
      termsConditionModal: true,
    });
  }

  privacyPolicyToggle = () => {
    this.setState({
      privacyPolicyModal: !this.state.privacyPolicyModal,
    });
  };

  openPrivacyPolicy() {
    this.setState({
      privacyPolicyModal: true,
    });
  }

  handleNext(e) {
    e.preventDefault()
    if (this.state.orgGstn) {
      if (!Utils.checkGstnLength(this.state.orgGstn)) {
        return;
      };
    };
    if (!Utils.checkPanLength(this.state.orgPanNo)) {
      return;
    };
    this.setState({ activeStep: false, activeStep1: true });
  };

  handleBack = () => {
    this.setState({ activeStep: true, activeStep1: false });
  };

  getGstn() {
    if (!this.state.orgGstn) {
      PtsAlert.error("Enter GSTN");
      return;
    }
    const postObject = {
      gstin: this.state.orgGstn,
      header: {
        sessionid: "a1234v500G909u2332",
        email: "taxonomyhub@prototsolutions.com",
        tid: 1,
      },
    };
    FetchServerData.callPostServiceWithoutHeader(
      "/staticms/publicFindByGstn",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let gstnDetails = output.data.taxpayer;
        PtsAlert.success(output.message);
        this.setState({
          orgName: gstnDetails.legalname,
          state: gstnDetails.address?.state,
          address: gstnDetails.address,
        });
      } else {
      }
    });
  }

  render() {
    const { error, passwordFocussed } = this.state;
    return (
      <>
        <div className="authentication-wrapper authentication-cover">
          <div className="authentication-inner row m-0">
            <div className="d-flex col-12 col-lg-5 col-xl-5 align-items-center leftBackground ">
              <div className="mx-auto">
                <div className="logoSignupBackground">
                  <img
                    src={logo}
                    className="img-fluid logoSignupImage"
                    alt=""
                    data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                    data-app-light-img="illustrations/girl-with-laptop-light.png"
                  />
                </div>
                <div className="align-items-center">
                  <h2 className="logoHeding">AssetTrackifier</h2>
                </div>
              </div>
            </div>
            <div className="d-flex col-12 col-lg-7 col-xl-7 align-items-center authentication-bg">
              <div className="w-px-800 mx-auto">
                {this.state.activeStep === true &&
                  <>
                    <ValidatorForm
                      ref="form"
                      onSubmit={this.handleNext.bind(this)}
                    >
                      <div className="mx-auto">
                        <div className="signupHeding">Organization Sign Up</div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              htmlFor="firstName"
                              className="form-label"
                              id="signupFormLabel"
                            >
                              Organization Name <span className="asterisk">*</span>
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              type="text"
                              className="form-control"
                              id="organizationName"
                              name="organizationName"
                              placeholder="Enter Organization Name "
                              margin="normal"
                              value={this.state.orgName}
                              onChange={this.handleOrgNameChange.bind(this)}
                              inputprops={{
                                style: { textTransform: "capitalize" },
                              }}
                              required
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="lastName"
                              className="form-label"
                              id="signupFormLabel"
                            >
                              Phone <span className="asterisk">*</span>
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              required
                              type="number"
                              className="form-control"
                              id="phone"
                              name="phone"
                              placeholder="Enter Phone Number"
                              margin="normal"
                              value={this.state.orgPhone}
                              onChange={this.handleOrgPhoneChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              htmlFor="email"
                              className="form-label pt-2"
                              id="signupFormLabel"
                            >
                              GSTN
                            </label>
                            <TextValidator
                              id="text"
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              placeholder="GSTN"
                              type="text"
                              value={this.state.orgGstn}
                              onChange={this.handleOrgGSTNChange.bind(this)}
                              onBlur={this.handleOrgGSTNOnBlur.bind(this)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="orggstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="lastName"
                              className="form-label"
                              id="signupFormLabel"
                            >
                              PAN NO<span className="asterisk">*</span>
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              required
                              type="text"
                              className="form-control"
                              id="panno"
                              name="panno"
                              placeholder="Enter PAN NO"
                              margin="normal"
                              value={this.state.orgPanNo}
                              onChange={this.handleOrgPANChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              htmlFor="lastName"
                              className="form-label"
                              id="signupFormLabel"
                            >
                              Website
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              type="text"
                              className="form-control"
                              id="website"
                              name="website"
                              placeholder="Enter Website"
                              margin="normal"
                              value={this.state.orgWebsite}
                              onChange={this.handleOrgWebsiteChange.bind(this)}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="lastName"
                              className="form-label"
                              id="signupFormLabel"
                            >
                              Fax
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              type="text"
                              className="form-control"
                              id="fax"
                              name="fax"
                              placeholder="Enter fax"
                              margin="normal"
                              value={this.state.orgFax}
                              onChange={this.handleOrgFaxChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              htmlFor="lastName"
                              className="form-label"
                              id="signupFormLabel"
                            >
                              Country <span className="asterisk"> *</span>
                            </label>
                            <Autocomplete
                              id="country"
                              required
                              openOnFocus
                              options={this.state.countryListArray}
                              value={this.state.country}
                              defaultValue={this.state.country}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                this.handleCountryChange(value);
                              }}
                              inputprops={{
                                style: { textTransform: "capitalize" },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="form-control"
                                  variant="outlined"
                                  margin="normal"
                                  placeholder="Please select your Country"
                                  required
                                />
                              )}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="lastName"
                              className="form-label"
                              id="signupFormLabel"
                            >
                              State <span className="asterisk"> *</span>
                            </label>
                            <Autocomplete
                              id="state"
                              options={this.stateListArray}
                              value={this.state.state}
                              getOptionLabel={(option) =>
                                option.name + " " + "(" + option.code + ")"
                              }
                              defaultValue={this.state.state}
                              onChange={(event, value) => {
                                this.handleStateChange(value);
                              }}
                              inputprops={{ style: { textTransform: "capitalize" } }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="form-control"
                                  variant="outlined"
                                  margin="normal"
                                  placeholder="Please select your State'"
                                  required
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex">
                        <div className="col-6 text-start pt-4">
                          <span>Already have an account?</span>&nbsp;
                          <NavLink to="/signinEmail">
                            <span>Sign In</span>
                          </NavLink>
                        </div>
                        <div className="col-6 pt-4 text-end">
                          <button className="nextSignUp" type="submit">
                            Next
                          </button>
                        </div>
                      </div>
                      <div className="text-start">
                        <NavLink to="/">
                          <ArrowBackIosNewIcon />
                          Back Home
                        </NavLink>
                      </div>
                    </ValidatorForm>
                  </>
                }
                {this.state.activeStep1 === true &&
                  <>
                    <ValidatorForm
                      ref="form"
                      id="formAuthentication"
                      className="mb-3"
                      autoComplete="none"
                      onSubmit={this.createAccountInBackend.bind(this)}
                    >
                      <div className=" mx-auto">
                        <div className="signupHeding-1">Admin User</div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              htmlFor="firstName"
                              className="form-label"
                              id="signupFormLabel"
                            >
                              First Name <span className="asterisk">*</span>
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              type="text"
                              className="form-control"
                              id="firstName"
                              name="firstName"
                              placeholder="Enter First Name "
                              autoComplete="new-password"
                              margin="normal"
                              helperText={this.state.fnameHelperText}
                              error={this.state.fnameHelperText !== ""}
                              value={this.state.fname}
                              onChange={this.handleFirstNameChange.bind(this)}
                              inputprops={{
                                style: { textTransform: "capitalize" },
                              }}
                              required
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="lastName"
                              className="form-label"
                              id="signupFormLabel"
                            >
                              Last Name <span className="asterisk">*</span>
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              type="text"
                              className="form-control"
                              id="lastName"
                              name="lastName"
                              placeholder="Enter Last Name"
                              autoComplete="new-password"
                              margin="normal"
                              helperText={this.state.lnameHelperText}
                              error={this.state.lnameHelperText !== ""}
                              value={this.state.lname}
                              onChange={this.handleLastNameChange.bind(this)}
                              inputprops={{
                                style: { textTransform: "capitalize" },
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6">
                            <label
                              htmlFor="email"
                              className="form-label"
                              id="signupFormLabel"
                            >
                              EMAIL ID <span className="asterisk">*</span>
                            </label>
                            <TextValidator
                              id="email"
                              fullWidth
                              required
                              margin="normal"
                              variant="outlined"
                              placeholder="Enter Email"
                              type="email"
                              value={this.state.email}
                              onChange={this.handleEmailChange.bind(this)}
                              // onBlur={this.props.onBlur}
                              autoComplete="new-password"
                              validators={["required", "isEmail"]}
                              errorMessages={[
                                "This field is required",
                                "Email id is not valid",
                              ]}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="lastName"
                              className="form-label"
                              id="signupFormLabel"
                            >
                              Phone <span className="asterisk"> *</span>
                            </label>
                            <TextField
                              variant="outlined"
                              fullWidth
                              type="number"
                              className="form-control"
                              id="phone"
                              name="phone"
                              placeholder="Enter phone number"
                              margin="normal"
                              value={this.state.phone}
                              onChange={this.handlePhoneChange.bind(this)}
                              inputprops={{
                                style: { textTransform: "capitalize" },
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="row g-3 mt-1">
                          <div className="col-sm-6 form-password-toggle">
                            <label
                              className="form-label d-flex justify-content-space-between align-center"
                              htmlFor="newPassword"
                              id="signupFormLabel"
                              style={{ width: "100%", marginTop: "-0.5em" }}
                            >
                              <div>
                                PASSWORD <span className="asterisk">*</span>
                                <PasswordRequirementsTooltip
                                  error={error}
                                  open={passwordFocussed}
                                />
                              </div>

                            </label>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              value={this.state.password}
                              name="password"
                              placeholder="Password "
                              type={this.state.showPassword ? "text" : "password"}
                              id="password"
                              autoComplete="new-password"
                              onFocus={this.toggleFocusNewPassword}
                              onBlur={this.toggleFocusNewPassword}
                              helperText={this.state.passwordHelperText}
                              error={this.state.passwordHelperText !== ""}
                              onChange={this.handleChange.bind(this)}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                  >
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword.bind(
                                        this
                                      )}
                                    >
                                      {this.state.showPassword ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="col-sm-6 form-password-toggle">
                            <label
                              className="form-label"
                              htmlFor="confirmPassword"
                              id="signupFormLabel"
                            >
                              CONFIRM PASSWORD <span className="asterisk">*</span>
                            </label>
                            <div className="input-group input-group-merge">
                              <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                value={this.state.confirmPassword}
                                name="confirmPassword"
                                placeholder="Confirm Password "
                                type={this.state.showConfirmPassword ? "text" : "password"}
                                id="confirmPassword"
                                autoComplete="new-password"
                                helperText={this.state.confirmPasswordHelperText}
                                error={this.state.confirmPasswordHelperText !== ""}
                                onChange={this.handleChange.bind(this)}
                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                    >
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowConfirmPassword.bind(
                                          this
                                        )}
                                      >
                                        {this.state.showConfirmPassword ? (
                                          <VisibilityIcon />
                                        ) : (
                                          <VisibilityOffIcon />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 mt-3 row d-flex ps-3">
                        <div className="form-check" id="checkBox">
                          {/* <input className="form-check-input" type="checkbox" id="terms-conditions" name="terms" /> */}
                          <Checkbox
                            checked={this.state.checkbox}
                            onChange={this.handleClickCheckBox}
                            className="form-check-input"
                            id="terms-conditions"
                            name="terms"
                            required
                          />
                          I agree to
                          <a onClick={(e) => this.openTermsCondition()} style={{ color: '#007bff', cursor: 'pointer' }}>
                            Terms & Conditions
                          </a>
                          and
                          <a onClick={(e) => this.openPrivacyPolicy()} style={{ color: '#007bff', cursor: 'pointer' }}>
                            Privacy Policy
                          </a>
                        </div>
                      </div>
                      <div className="row d-flex">
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          {this.state.activeStep1 === true ?
                            <div className="col-6 text-start">
                              <button
                                color="inherit"
                                className="backSignUp"
                                onClick={this.handleBack}
                                sx={{ mr: 1 }}
                              >
                                Back
                              </button>
                            </div>
                            : ""
                          }
                          <Box sx={{ flex: '1 1 auto' }} />
                          {this.state.activeStep1 === true ? (
                            <div className="col-6 text-end">
                              <button className="submitSignup" style={{ backgroundColor: this.state.isDisabled ? "lightslategray" : "#0B6F9D" }} type="submit">
                                Submit
                              </button>
                            </div>
                          ) : " "}
                        </Box>
                      </div>

                      <div className="text-start pt-4">
                        <span>Already have an account?</span>&nbsp;
                        <NavLink to="/signinEmail">
                          <span>Sign In</span>
                        </NavLink>
                      </div>

                    </ValidatorForm>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.termsConditionModal} toggle={this.termsConditionToggle} size="xl">
          <Row style={{ marginTop: '1em' }}>
            <Col md={11}></Col>
            <Col md={1}>
              <Fab
                aria-label="add"
                style={{
                  float: 'left',
                  fontSize: 'small',
                  height: '15px',
                  width: '35px',
                  background: '#76767d',
                  cursor: 'pointer',
                  color: 'white',
                  backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                  border: '2px solid #edf0f1',
                }}
              >
                <CloseIcon onClick={(e) => this.termsConditionToggle()} />
              </Fab>
            </Col>
          </Row>
          <ModalBody>
            <Row>
              <Col md={12}>
                <TermsConditions />
              </Col>
            </Row>
          </ModalBody>
        </Modal>{' '}
        <Modal isOpen={this.state.privacyPolicyModal} toggle={this.privacyPolicyToggle} size="xl">
          <Row style={{ marginTop: '1em' }}>
            <Col md={11}></Col>
            <Col md={1}>
              <Fab
                aria-label="add"
                style={{
                  float: 'left',
                  fontSize: 'small',
                  height: '15px',
                  width: '35px',
                  background: '#76767d',
                  cursor: 'pointer',
                  color: 'white',
                  backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                  border: '2px solid #edf0f1',
                }}
              >
                <CloseIcon onClick={(e) => this.privacyPolicyToggle()} />
              </Fab>
            </Col>
          </Row>
          <ModalBody>
            <Row>
              <Col md={12}>
                <PrivacyPolicy />
              </Col>
            </Row>
          </ModalBody>
        </Modal>{' '}
      </>
    );
  }
}

export default withRouter(Signup);
