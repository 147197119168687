import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Autocomplete } from "@mui/material";
import { Menu } from "@mui/material";
import PtsButton from "../buttons/PtsButton";
import DatePicker from "react-datepicker";
import TextField from "@material-ui/core/TextField";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FetchServerData from "../../provider/FetchServerData";
import store from "../../redux/store";
import Utils from "../../provider/Utils";
import CustomerAction from "../../redux/customer/CustomerAction";
import ApplicationStateAction from "../../redux/applicationState/ApplicationStateAction";
import Pagination from '../assetTracking/Pagination';
import "./ReturnablePlanList.css";
class ReturnablePlanList extends Component {
  header = store.getState().header.header;
  returnablePlanState = store.getState().applicationState.returnablePlanState;
  returnablePlanStateDetails = {};
  breadCrumb = [{ pageid: "", uiname: "Returnable Plan List" }];
  constructor() {
    super();
    if (this.returnablePlanState) {
      this.returnablePlanStateDetails = Object.getOwnPropertyNames(this.returnablePlanState);
    }
    if (this.returnablePlanStateDetails.length > 0) {
      this.state = {
        // initialPage: this.returnablePlanState.initialPage,
        pageSize: this.returnablePlanState.pageSize,
        selectedTab: this.returnablePlanState.selectedTab,
        searchText: "",
        pageNo: this.returnablePlanState.pageNo,
        showPerPage: 0,
        returnablePlanList: [],
        customerList: [],
        plannedReturnableListArr: [],
        startedReturnableListArr: [],
        completedReturnableListArr: [],
        allReturnableListArr: [],
        returnableCyclePlanId: "",
        customerId: "",
        customer: null,
        startDate: null,
        endDate: null,
        d_customer: null,
        d_startDate: null,
        d_endDate: null,
        render: false,
        getCreatePermission: false,
        openListModal: false,
        open: false,
        error: false,
        isFirstTimeLoading: true,
        anchorEl: null,
        errorMassage: false,
        returnablePlanId: this.returnablePlanState.returnablePlanId,
      };
    } else {
      this.state = {
        // initialPage: 0,
        pageSize: 10,
        selectedTab: 0,
        searchText: "",
        pageNo: 0,
        showPerPage: 0,
        returnablePlanList: [],
        customerList: [],
        plannedReturnableListArr: [],
        startedReturnableListArr: [],
        completedReturnableListArr: [],
        allReturnableListArr: [],
        returnableCyclePlanId: "",
        customerId: "",
        customer: null,
        startDate: null,
        endDate: null,
        d_customer: null,
        d_startDate: null,
        d_endDate: null,
        render: false,
        getCreatePermission: false,
        openListModal: false,
        open: false,
        error: false,
        isFirstTimeLoading: true,
        anchorEl: null,
      };
    }
    // this.filterDataInBackend(this.state.selectedTab);
  }

  componentDidMount() {
    this.onPaginationChange({ buttonIndex: this.state.pageNo, showPerPage: this.state.pageSize })
    this.getCustomerList();
  }

  getCustomerList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/costomerms/getActiveList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let customerListArray = output.data.customerList;
          let allCustomer = {
            name: "All"
          }
          const customerList = [allCustomer, ...customerListArray]
          store.dispatch(CustomerAction.setCustomer(customerList));
          this.setState({
            customerList: customerList,
          });
        } else {
        }
      }
    );
  }

  addReturnablePlan() {
    this.props.navigate("/returnablePlan", {
      state: {
        action: "create",
      },
    });
  }
  toReturnablePlanDetails(data) {
    let returnablePlanState = {
      returnablePlanId: data.id,
      pageNo: this.state.pageNo,
      pageSize: this.state.pageSize,
      selectedTab: this.state.selectedTab,
    };
    store.dispatch(ApplicationStateAction.setApplicationState({ returnablePlanState }));
    this.props.navigate("/returnablePlan", {
      state: {
        action: "view",
        returnableCyclePlanId: data.id,
      },
    });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ pageNo: page, pageSize: pageSize });
  }

  setKey(tab) {
    store.dispatch(ApplicationStateAction.setApplicationState({}));
    this.filterDataInBackend(tab);
    this.setState({
      selectedTab: tab,
      pageNo: 0,
      pageSize: 10
    });
  }

  handleCustomerTypeChange(selectedCustomer) {
    this.setState({
      customer: selectedCustomer,
      customerId: selectedCustomer.id,
    });
  }

  handleStartDateChange(date) {
    if (this.state.endDate === null && date === null) {
      this.setState({ startDate: date, errorMassage: false });
    } else if (date === null) {
      this.setState({ startDate: date, errorMassage: true });
    } else {
      this.setState({ startDate: date, errorMassage: false });
    }
  }

  handleEndDateChange(date) {
    if (this.state.startDate === null && date === null) {
      this.setState({ endDate: date, errorMassage: false });
    } else if (this.state.startDate === null) {
      this.setState({ endDate: date, errorMassage: true });
    } else {
      this.setState({ endDate: date, errorMassage: false });
    }
    // this.setState({ endDate: date });
  }

  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  filterDataInBackend(tab, pageNo, pageSize) {
    if (this.state.errorMassage) {
      return false;
    }

    this.setState({
      d_customer: this.state.customer,
      d_startDate: this.state.startDate,
      d_endDate: this.state.endDate,
    })
    let fromDate = Date.parse(this.state.startDate);
    let toDate = Date.parse(this.state.endDate);

    if ((fromDate !== null || toDate !== null)) {
      if (fromDate !== null && toDate !== null && fromDate > toDate) {
        this.setState({ error: true });
      } else {

        let status = ["Planned", "Started", "Completed", ""];
        const postObject = {
          header: this.header,
          customer: {
            id: this.state.customerId
          },
          fromDate: this.state.startDate,
          toDate: this.state.endDate,
          status: { status: status[tab] },
          page: {
            searchText: this.state.search ? this.state.search : "",
            pageNo: pageNo !== null ? pageNo : this.state.pageNo,
            pageSize: pageSize !== null ? pageSize : this.state.pageSize,
          }
        }
        FetchServerData.callPostService('/returnablePlanms/getFilterList', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            let returnablePlanList = output.data.cyclePlanList;
            let page = output.data.page;
            let getPermission = Utils.getRolePermission(output.data.privilegeList);
            let getCreatePermission = getPermission[1];

            for (let i = 0; i < returnablePlanList.length; i++) {
              returnablePlanList[i].planDate = Utils.getUtcToLocal(returnablePlanList[i].planDate);
            }
            let plannedReturnableListArr = [];
            let startedReturnableListArr = [];
            let completedReturnableListArr = [];
            for (let i = 0; i < returnablePlanList.length; i++) {
              if (returnablePlanList[i].status === 'Planned') {
                plannedReturnableListArr.push(returnablePlanList[i]);
              } else if (returnablePlanList[i].status === 'Started') {
                startedReturnableListArr.push(returnablePlanList[i]);
              } else if (returnablePlanList[i].status === 'Completed') {
                completedReturnableListArr.push(returnablePlanList[i]);
              }
            }

            this.setState({
              render: true,
              open: false,
              // initialPage: this.state.initialPage,
              searchText: page.searchText,
              pageNo: page.pageNo,
              totalPages: page.totalPages,
              totalRecords: page.total,
              showPerPage: page.pageSize,
              returnablePlanList: returnablePlanList,
              getCreatePermission: getCreatePermission,
              plannedReturnableListArr: plannedReturnableListArr,
              startedReturnableListArr: startedReturnableListArr,
              completedReturnableListArr: completedReturnableListArr,
              allReturnableListArr: returnablePlanList,
            });
          } else {
          }
        });
      }
    }
  }

  resetFilter() {
    let status = ["Planned", "Started", "Completed", ""];
    const postObject = {
      header: this.header,
      customer: {
        id: ""
      },
      fromDate: "",
      toDate: "",
      status: { status: status[this.state.selectedTab] }
    }
    FetchServerData.callPostService('/returnablePlanms/getFilterList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let returnablePlanList = output.data.cyclePlanList;
        let page = output.data.page;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];

        for (let i = 0; i < returnablePlanList.length; i++) {
          returnablePlanList[i].planDate = Utils.getUtcToLocal(returnablePlanList[i].planDate);
        }
        let plannedReturnableListArr = [];
        let startedReturnableListArr = [];
        let completedReturnableListArr = [];

        for (let i = 0; i < returnablePlanList.length; i++) {
          if (returnablePlanList[i].status === 'Planned') {
            plannedReturnableListArr.push(returnablePlanList[i]);
          } else if (returnablePlanList[i].status === 'Started') {
            startedReturnableListArr.push(returnablePlanList[i]);
          } else if (returnablePlanList[i].status === 'Completed') {
            completedReturnableListArr.push(returnablePlanList[i]);
          }
        }

        this.setState({
          render: true,
          customerId: "",
          customer: null,
          startDate: null,
          endDate: null,
          d_customer: null,
          d_startDate: null,
          d_endDate: null,
          open: false,
          error: false,
          errorMassage: false,
          isFirstTimeLoading: true,
          totalRecords: page.total,
          returnablePlanList: returnablePlanList,
          getCreatePermission: getCreatePermission,
          plannedReturnableListArr: plannedReturnableListArr,
          startedReturnableListArr: startedReturnableListArr,
          completedReturnableListArr: completedReturnableListArr,
          allReturnableListArr: returnablePlanList,
        });
      } else {
      }
    });
  }

  onPaginationChange(returnProp) {
    let counter = returnProp.counter;
    let pageNo = this.state.pageNo + counter;
    let pageSize = returnProp.showPerPage;
    let buttonIndex = returnProp.buttonIndex;
    let currentPageIndex = typeof counter !== 'undefined' ? pageNo : buttonIndex;
    let resetPageNo = returnProp.resetPageNo;
    if (resetPageNo === true) {
      currentPageIndex = 0
    };
    this.setState({
      pageNo: currentPageIndex,
    });
    // console.log("showPerPage", returnProp.showPerPage, "pageNo", this.state.pageNo + counter)
    this.filterDataInBackend(this.state.selectedTab, currentPageIndex, pageSize);
    this.handleMaterialTablePageChange(currentPageIndex, pageSize);
  };

  onSearchChange(e) {
    this.setState({ search: e.target.value });
  };

  onFilterClick() {
    this.setState({
      isFirstTimeLoading: false,
      // open: false,
    });
    this.filterDataInBackend(this.state.selectedTab)
  }

  onSearchClick() {
    this.setState({
      isFirstTimeLoading: false
    });
    this.filterDataInBackend(this.state.selectedTab, this.state.pageNo, this.state.pageSize)
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject="Returnable plan"
            name="Returnable Plan List"
          />
          <Row>
            <Col md={12} >
              <div className="row align-items-end text-end">
                <div className="col">
                  <span>
                    {this.state.d_customer !== null ||
                      this.state.d_startDate !== null ||
                      this.state.d_endDate !== null ?
                      <span>
                        ( {" "}
                        {this.state.d_customer !== null && this.state.d_customer.name ?
                          <span>
                            <b>Customer : </b> {this.state.d_customer.name + ","}
                          </span> : ""
                        }
                        &nbsp;
                        {this.state.d_startDate && this.state.d_startDate !== null ?
                          <span>
                            <b>Form : </b> {Utils.getUtcToLocal(this.state.d_startDate) + ","}
                          </span> : ""
                        }
                        &nbsp;
                        {this.state.d_endDate && this.state.d_endDate !== "" ?
                          <span>
                            <b>To : </b> {Utils.getUtcToLocal(this.state.d_endDate)}
                          </span> : ""
                        } {" "}
                        )
                        &nbsp;&nbsp;
                      </span>
                      : ""
                    }
                  </span>
                  &nbsp;&nbsp;
                  <span style={{ fontSize: "17px", fontWeight: "500", color: "#566a7f", cursor: "pointer" }} onClick={this.handleAccountDropdownOpen}>
                    <FilterAltIcon
                      style={{ fontSize: "28px", color: "rgb(106, 123, 142)", }}
                      aria-controls={this.state.open ? "dropdown-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={this.state.open ? "true" : undefined}
                    />
                    Filter
                  </span>{"  "} &nbsp;&nbsp;
                  {this.state.isFirstTimeLoading ? "" :
                    < span style={{ fontSize: "17px", fontWeight: "500", color: "#566a7f", cursor: "pointer" }} onClick={this.resetFilter.bind(this)}>
                      <i class="fa fa-refresh" aria-hidden="true"></i>{" "}
                      Reset
                    </span>
                  }
                </div>
              </div>
              <Menu
                anchorEl={this.state.anchorEl}
                id="dropdown-menu"
                className="filterDropdownMenu"
                open={this.state.open}
                onClose={this.handleAccountDropdownClose}
                // onClick={this.handleAccountDropdownClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <div
                  className="dropdown-item"
                  style={{ cursor: "pointer", width: "400px", height: "100%" }}
                >
                  <h5>Filter</h5>
                  <div className="col-md-12 mt-1" id="assetFormLabel">
                    <label className="form-label " htmlFor="collapsible-supplierType">
                      Customer
                    </label>
                    <Autocomplete
                      fullWidth
                      id="customerTypeList"
                      className='customerDropdownIcon'
                      options={this.state.customerList}
                      value={this.state.customer}
                      defaultValue={this.state.customer}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleCustomerTypeChange(value);
                      }}
                      inputprops={{ style: { textTransform: "capitalize" } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Customer"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12 mt-1  d-flex justify-content-between returnableFromDate">
                    <div className="col-md-6 mt-1">
                      <label className="form-label " htmlFor="collapsible-supplierType">
                        From Date
                      </label>
                      <br></br>
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleStartDateChange.bind(this)}
                        dateFormat="d MMM, yyyy"
                        placeholderText="From Date"
                        required
                        className="startDateTimeInput"
                      />
                    </div>
                    &nbsp;
                    <div className="col-md-6 mt-1">
                      <label className="form-label " htmlFor="collapsible-supplierType">
                        To Date
                      </label>
                      <br></br>
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleEndDateChange.bind(this)}
                        dateFormat="d MMM, yyyy"
                        placeholderText="To Date"
                        required
                        className="endDateTimeInput"
                      />
                    </div>
                  </div>
                  {this.state.errorMassage ? <lable className="danger ms-1" style={{ color: "red" }}>From Date is Required</lable> : ""}
                  {this.state.error ? <lable className="danger" style={{ color: "red" }}>To Date should be after From Date</lable> : ""}
                  <div className="col-md-12 mt-4">
                    <button className="fileterButton" onClick={this.onFilterClick.bind(this)}>
                      <i className="fa-solid fa-filter"></i>
                      &nbsp;
                      Filter
                    </button>
                    &nbsp; &nbsp;
                    <button className="resetButton"
                      onClick={this.resetFilter.bind(this)}
                    >
                      <i className="fa fa-refresh"></i>
                      &nbsp;
                      Reset
                    </button>
                  </div>
                </div>
              </Menu>
              <Tabs defaultIndex={this.state.selectedTab}
                onSelect={(k) => this.setKey(k)}
                className="returnableTabs"
              >
                <TabList style={{ listStyle: "none" }} className="text-start d-flex justify-content-start ps-0">
                  <Tab className="returnableTab hoverTab" > Planned </Tab>
                  <Tab className="returnableTab hoverTab" > Started </Tab>
                  <Tab className="returnableTab hoverTab" > Completed </Tab>
                  <Tab className="returnableTab hoverTab" > All </Tab>
                </TabList>
                <TabPanel>
                  {(this.state.isFirstTimeLoading && this.state.returnablePlanList.length === 0) ? (
                    <div className="row">
                      <div className="col-md-12">
                        <Card>
                          <CardBody>
                            <div className="row">
                              <div className="col-md-12" style={{ padding: "2em" }}>
                                <h5>
                                  <b>Returnable Plan not created yet</b>
                                </h5>
                                <br></br>
                                <PtsButton
                                  btnName="Add New Returnable Plan"
                                  onClick={this.addReturnablePlan.bind(this)}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col md-12">
                        <div className="card">
                          <div className='row justify-content-between p-3'>
                            <div className="d-flex col-12 col-lg-4 col-xl-4">
                              <div className="col-4 text-start totalResults">
                                <span>Total Results: {this.state.totalRecords}</span>
                              </div>
                            </div>
                            <div className="d-flex col-12 col-lg-8 col-xl-8 justify-content-end">
                              <input
                                type="search"
                                id="gsearch"
                                placeholder="Search"
                                name="gsearch"
                                value={this.state.search}
                                onChange={this.onSearchChange.bind(this)}
                              />
                              <button
                                className='gsearchButton'
                                onClick={this.onSearchClick.bind(this)}                                >
                                <i className="fa fa-search" aria-hidden="true"></i>
                              </button>
                              &nbsp; &nbsp;
                              {this.state.getCreatePermission &&
                                <PtsButton
                                  btnName="Add Returnable Plan"
                                  onClick={this.addReturnablePlan.bind(this)}
                                  style={{ float: "right" }}
                                />
                              }
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table text-start table-hover">
                              <thead className="assetCycleThead">
                                <tr>
                                  <th scope="col fw-bold ">#</th>
                                  <th scope="col fw-bold">Plan Name</th>
                                  <th scope="col fw-bold">Plan Date</th>
                                  <th scope="col fw-bold">Customer</th>
                                  <th scope="col fw-bold">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.plannedReturnableListArr?.map((rowData, index) => (
                                  <tr
                                    key={index}
                                    onClick={this.toReturnablePlanDetails.bind(this, rowData)}
                                    style={{ backgroundColor: rowData.id === this.state.returnablePlanId ? '#FEF5E7' : '#fff', cursor: "pointer" }}
                                  >
                                    <td style={{ width: "6px" }}>{this.state.pageNo * this.state.showPerPage + index + 1}</td>
                                    <td>{rowData.name}</td>
                                    <td>{rowData.planDate}</td>
                                    <td>{rowData.customerName}</td>
                                    <td>{rowData.status}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {this.state.totalRecords === 0 &&
                            <div className='row text-center p-2'>
                              <h5>Data not Available</h5>
                            </div>
                          }
                          {this.state.totalRecords > 10 &&
                            <div className="row justify-content-between pagination p-2">
                              <div className="pageSize col-4 text-start">
                                Page {this.state.pageNo + 1} of {this.state.totalPages}
                              </div>
                              <div className='col-4 text-end'>
                                <Pagination
                                  showPerPage={this.state.showPerPage}
                                  onPaginationChange={this.onPaginationChange.bind(this)}
                                  total={this.state.totalRecords}
                                  pageNo={this.state.pageNo}
                                />
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )
                  }
                </TabPanel>
                <TabPanel>
                  {(this.state.isFirstTimeLoading && this.state.startedReturnableListArr.length === 0) ? (
                    <div className="row">
                      <div className="col-md-12">
                        <Card>
                          <CardBody>
                            <div className="row">
                              <div className="col-md-12" style={{ padding: "2em" }}>
                                <h5>
                                  <b> Returnable Plan not created yet</b>
                                </h5>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col md-12">
                        <div className="card">
                          <div className='row justify-content-between p-3'>
                            <div className="d-flex col-12 col-lg-4 col-xl-4">
                              <div className="col-12 text-start totalResults">
                                <span>Total Results: {this.state.totalRecords}</span>
                              </div>
                            </div>
                            <div className="d-flex col-12 col-lg-8 col-xl-8">
                              <div className='col-12 text-end'>
                                <input
                                  type="search"
                                  id="gsearch"
                                  // style={{ width: "240px", height: "33px", outline: "none" }}
                                  placeholder="Search"
                                  name="gsearch"
                                  value={this.state.search}
                                  onChange={this.onSearchChange.bind(this)}
                                />
                                <button
                                  className='gsearchButton'
                                  onClick={this.onSearchClick.bind(this)}
                                >
                                  <i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table text-start table-hover">
                              <thead className="assetCycleThead">
                                <tr>
                                  <th scope="col fw-bold ">#</th>
                                  <th scope="col fw-bold">Plan Name</th>
                                  <th scope="col fw-bold">Plan Date</th>
                                  <th scope="col fw-bold">Customer</th>
                                  <th scope="col fw-bold">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.startedReturnableListArr?.map((rowData, index) => (
                                  <tr
                                    key={index}
                                    onClick={this.toReturnablePlanDetails.bind(this, rowData)}
                                    style={{ backgroundColor: rowData.id === this.state.returnablePlanId ? '#FEF5E7' : '#fff', cursor: "pointer" }}
                                  >
                                    <td style={{ width: "6px" }}>{this.state.pageNo * this.state.showPerPage + index + 1}</td>
                                    <td>{rowData.name}</td>
                                    <td>{rowData.planDate}</td>
                                    <td>{rowData.customerName}</td>
                                    <td>{rowData.status}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {this.state.totalRecords === 0 &&
                            <div className='row text-center p-2'>
                              <h5>Data not Available</h5>
                            </div>
                          }
                          {this.state.totalRecords > 10 &&
                            <div className="row justify-content-between pagination p-2">
                              <div className="pageSize col-4 text-start">
                                Page {this.state.pageNo + 1} of {this.state.totalPages}
                              </div>
                              <div className='col-4 text-end'>
                                <Pagination
                                  showPerPage={this.state.showPerPage}
                                  onPaginationChange={this.onPaginationChange.bind(this)}
                                  total={this.state.totalRecords}
                                  pageNo={this.state.pageNo}
                                />
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )
                  }
                </TabPanel>
                <TabPanel>
                  {(this.state.isFirstTimeLoading && this.state.completedReturnableListArr.length === 0) ? (
                    <div className="row">
                      <div className="col-md-12">
                        <Card>
                          <CardBody>
                            <div className="row">
                              <div className="col-md-12" style={{ padding: "2em" }}>
                                <h5>
                                  <b>Returnable Plan not created yet</b>
                                </h5>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col md-12">
                        <div className="card">
                          <div className='row justify-content-between p-3'>
                            <div className="d-flex col-12 col-lg-4 col-xl-4">
                              <div className="col-12 text-start totalResults">
                                <span>Total Results: {this.state.totalRecords}</span>
                              </div>
                            </div>
                            <div className="d-flex col-12 col-lg-8 col-xl-8">
                              <div className='col-12 text-end'>
                                <input
                                  type="search"
                                  id="gsearch"
                                  // style={{ width: "240px", height: "33px", outline: "none" }}
                                  placeholder="Search"
                                  name="gsearch"
                                  value={this.state.search}
                                  onChange={this.onSearchChange.bind(this)}
                                />
                                <button
                                  className='gsearchButton'
                                  onClick={this.onSearchClick.bind(this)}
                                >
                                  <i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table text-start table-hover">
                              <thead className="assetCycleThead">
                                <tr>
                                  <th scope="col fw-bold ">#</th>
                                  <th scope="col fw-bold">Plan Name</th>
                                  <th scope="col fw-bold">Plan Date</th>
                                  <th scope="col fw-bold">Customer </th>
                                  <th scope="col fw-bold">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.completedReturnableListArr?.map((rowData, index) => (
                                  <tr
                                    key={index}
                                    onClick={this.toReturnablePlanDetails.bind(this, rowData)}
                                    style={{ backgroundColor: rowData.id === this.state.returnablePlanId ? '#FEF5E7' : '#fff', cursor: "pointer" }}
                                  >
                                    <td style={{ width: "6px" }}>{this.state.pageNo * this.state.showPerPage + index + 1}</td>
                                    <td>{rowData.name}</td>
                                    <td>{rowData.planDate}</td>
                                    <td>{rowData.customerName}</td>
                                    <td>{rowData.status}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {this.state.totalRecords === 0 &&
                            <div className='row text-center p-2'>
                              <h5>Data not Available</h5>
                            </div>
                          }
                          {this.state.totalRecords > 10 &&
                            <div className="row justify-content-between pagination p-2">
                              <div className="pageSize col-4 text-start">
                                Page {this.state.pageNo + 1} of {this.state.totalPages}
                              </div>
                              <div className='col-4 text-end'>
                                <Pagination
                                  showPerPage={this.state.showPerPage}
                                  onPaginationChange={this.onPaginationChange.bind(this)}
                                  total={this.state.totalRecords}
                                  pageNo={this.state.pageNo}
                                />
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )
                  }
                </TabPanel>
                <TabPanel>
                  {(this.state.isFirstTimeLoading && this.state.returnablePlanList.length === 0) ? (
                    <div className="row" >
                      <div className="col-md-12">
                        <Card>
                          <CardBody>
                            <div className="row">
                              <div className="col-md-12" style={{ padding: "2em" }}>
                                <h5>
                                  <b>Returnable Plan not created yet</b>
                                </h5>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col md-12">
                        <div className="card">
                          <div className='row justify-content-between p-3'>
                            <div className="d-flex col-12 col-lg-4 col-xl-4">
                              <div className="col-12 text-start totalResults">
                                <span>Total Results: {this.state.totalRecords}</span>
                              </div>
                            </div>
                            <div className="d-flex col-12 col-lg-8 col-xl-8">
                              <div className='col-12 text-end'>
                                <input
                                  type="search"
                                  id="gsearch"
                                  // style={{ width: "240px", height: "33px", outline: "none" }}
                                  placeholder="Search"
                                  name="gsearch"
                                  value={this.state.search}
                                  onChange={this.onSearchChange.bind(this)}
                                />
                                <button
                                  className='gsearchButton'
                                  onClick={this.onSearchClick.bind(this)}
                                >
                                  <i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table text-start table-hover">
                              <thead className="assetCycleThead">
                                <tr>
                                  <th scope="col fw-bold ">#</th>
                                  <th scope="col fw-bold">Plan Name</th>
                                  <th scope="col fw-bold">Plan Date</th>
                                  <th scope="col fw-bold">Customer</th>
                                  <th scope="col fw-bold">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.allReturnableListArr?.map((rowData, index) => (
                                  <tr
                                    key={index}
                                    onClick={this.toReturnablePlanDetails.bind(this, rowData)}
                                    style={{ backgroundColor: rowData.id === this.state.returnablePlanId ? '#FEF5E7' : '#fff', cursor: "pointer" }}
                                  >
                                    <td style={{ width: "6px" }}>{this.state.pageNo * this.state.showPerPage + index + 1}</td>
                                    <td>{rowData.name}</td>
                                    <td>{rowData.planDate}</td>
                                    <td>{rowData.customerName}</td>
                                    <td>{rowData.status}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {this.state.totalRecords === 0 &&
                            <div className='row text-center p-2'>
                              <h5>Data not Available</h5>
                            </div>
                          }
                          {this.state.totalRecords > 10 &&
                            <div className="row justify-content-between pagination p-2">
                              <div className="d-flex col-12 col-lg-4 col-xl-4">
                                <div className="pageSize col-12 text-start">
                                  Page {this.state.pageNo + 1} of {this.state.totalPages}
                                </div>
                              </div>
                              <div className="d-flex col-12 col-lg-8 col-xl-8">
                                <div className='col-12 text-end'>
                                  <Pagination
                                    showPerPage={this.state.showPerPage}
                                    onPaginationChange={this.onPaginationChange.bind(this)}
                                    total={this.state.totalRecords}
                                    pageNo={this.state.pageNo}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )
                  }
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
        </>
      );
    }
  }
}
export default withRouter(ReturnablePlanList);
