import React, { Component } from 'react'
import { withRouter } from "../../../withRouter";
import { MenuWithBreadScrum } from "../../menu/MenuWithBreadScrum";
import { NavLink } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import ReactApexCharts from 'react-apexcharts';
import store from '../../../redux/store';

class AssetCycleReportAnalysis extends Component {
  header = store.getState().header.header;
  breadCrumb = [
    { pageid: "/reports", uiname: "MIS Reports" },
    { pageid: "", uiname: "Asset Cycle Analytics" }
  ];

  constructor() {
    super();
    this.state = {
      render: false,
      activeCustomers: 0,
      inActiveCustomers: 0,
      series: null,
      options: null,
      trackingStatusSeries: null,
      trackingStatusOptions: null,
      customerCycleOptions: null,
      customerCycleSeries: null,
      assetCycleOptions: null,
      assetCycleSeries: null,
      customerCycleChartData: [],
      assetCycleChartData: [],
    };
    this.getAssetCycleAnalysisReport();
  }

  getAssetCycleAnalysisReport() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/assetCycleAnalyticms/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let assetAnalysisDetails = output.data;
          let completedCount = assetAnalysisDetails.completedCount;
          let incycleCount = assetAnalysisDetails.incycleCount;
          let overdueCount = assetAnalysisDetails.overdueCount;
          let activeCycles = assetAnalysisDetails.activeCycles;
          let missingCycles = assetAnalysisDetails.missingCycles;
          let cycletimeOverCycles = assetAnalysisDetails.cycletimeOverCycles;
          let holdingPeriodOverCycles = assetAnalysisDetails.holdingPeriodOverCycles;
          let trackedCycles = assetAnalysisDetails.trackedCycles;
          let customerCycleChartData = assetAnalysisDetails.customerCycleList;
          let assetCycleChartData = assetAnalysisDetails.assetCycleList;
          let customeCycleLabels = [];
          let customeCycleValue = [];
          let assetCycleLabels = [];
          let assetCycleValue = [];
          customerCycleChartData.sort((a, b) => b.count - a.count)
          for (let i = 0; i < customerCycleChartData.length; i++) {
            let date = customerCycleChartData[i].customerName;
            customeCycleLabels.push(date);
            customeCycleValue.push(customerCycleChartData[i].count);
          }
          for (let i = 0; i < assetCycleChartData.length; i++) {
            let date = assetCycleChartData[i].assetName;
            assetCycleLabels.push(date);
            assetCycleValue.push(assetCycleChartData[i].count);
          }
          this.setState({
            render: true,
            customerCycleChartData: customerCycleChartData,
            assetCycleChartData: assetCycleChartData,
            series: [completedCount, incycleCount, overdueCount],
            options: {
              colors: ['rgb(0, 227, 150)', "#288C28", '#fd7e14', "#ffc107", "#696cff", "#ff3e1d"],
              labels: ["Completed", "InCycle", "Overdue"],
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        showAlways: true,
                        fontSize: "18px",
                        color: "black"
                      }
                    },
                  }
                }
              },
              chart: {
                type: 'donut',
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }],
            },
            trackingStatusSeries: [activeCycles, missingCycles, cycletimeOverCycles, holdingPeriodOverCycles, trackedCycles],
            trackingStatusOptions: {
              colors: ['rgb(0, 227, 150)', '#F44336', "#fd7e14", "#FFFF00", "#288C28"],
              labels: ["Active", "Missing", "CycletimeOver", "HoldingPeriodOver", "Tracked Assets"],
              chart: {
                type: 'pie',
              },

              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }],
            },
            customerCycleSeries: [
              {
                name: 'Asset Cycles',
                type: 'bar',
                data: customeCycleValue
              },
            ],
            customerCycleOptions: {
              chart: {
                type: 'bar',
                height: 430,
                toolbar: {
                  show: true,
                  tools: {
                    download: false
                  }
                }
              },
              // title: {
              //   text: 'Asset Cycles vs. Customers',
              //   align: 'left',
              //   fontSize: "18px"
              // },
              // colors: ['#f48024', '#69d2e7'],
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '25%',
                  dataLabels: {
                    position: 'top',
                  },
                }
              },
              grid: {
                show: true,
                xaxis: {
                  lines: {
                    show: false
                  }
                },
                yaxis: {
                  lines: {
                    show: false
                  }
                },
              },
              dataLabels: {
                enabled: true,
                // offsetX: -6,
                style: {
                  fontSize: '12px',
                  colors: ['#fff']
                }
              },
              stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
              },
              tooltip: {
                shared: true,
                intersect: false
              },
              xaxis: {
                categories: customeCycleLabels,
                labels: {
                  style: {
                    colors: '#247BA0',
                  },
                },
                title: {
                  text: 'Customers',
                  style: {
                    color: '#247BA0',
                    fontSize: "12px"
                  },
                },
                axisTicks: {
                  show: true,
                },
                axisBorder: {
                  show: true,
                  color: '#247BA0',
                },
              },
              yaxis: [
                {
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                    color: '#247BA0',
                  },

                  labels: {
                    formatter: function (val) {
                      return val.toFixed(0);
                    },
                    style: {
                      colors: '#247BA0',
                    },
                  },
                  title: {
                    text: 'Asset Cycles',
                    style: {
                      color: '#247BA0',
                      fontSize: "12px"
                    },
                  },
                },
              ],
            },
            assetCycleSeries: [
              {
                name: 'Asset Cycles',
                type: 'bar',
                data: assetCycleValue
              },
            ],
            assetCycleOptions: {
              chart: {
                type: 'bar',
                height: 430,
                toolbar: {
                  show: true,
                  tools: {
                    download: false
                  }
                }
              },
              // title: {
              //   text: 'Asset Cycles vs. Assets',
              //   align: 'left',
              //   fontSize: "18px"
              // },
              colors: ['#0091B1', '#69d2e7'],
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '25%',
                  width: "20px",
                  dataLabels: {
                    position: 'top',
                  },
                }
              },
              grid: {
                show: true,
                xaxis: {
                  lines: {
                    show: false
                  }
                },
                yaxis: {
                  lines: {
                    show: false
                  }
                },
              },
              dataLabels: {
                enabled: true,
                // offsetX: -6,
                style: {
                  fontSize: '12px',
                  colors: ['#fff']
                }
              },
              stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
              },
              tooltip: {
                shared: true,
                intersect: false
              },
              xaxis: {
                categories: assetCycleLabels,
                labels: {
                  style: {
                    colors: '#247BA0',
                  },
                },
                title: {
                  text: 'Assets',
                  style: {
                    color: '#247BA0',
                    fontSize: "12px"
                  },
                },
                axisTicks: {
                  show: true,
                },
                axisBorder: {
                  show: true,
                  color: '#247BA0',
                },
              },
              yaxis: [
                {
                  labels: {
                    formatter: function (val) {
                      return val.toFixed(0);
                    },
                    style: {
                      colors: '#247BA0',
                    },
                  },
                  title: {
                    text: 'Asset Cycles',
                    style: {
                      color: '#247BA0',
                      fontSize: "12px"
                    },
                  },
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                    color: '#247BA0',
                  },
                },
              ],
            },
          });
        } else {
        };
      });
  };
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject="Asset Cycle Analytics"
            name="Asset Cycle Analytics"
          />
          {this.state.assetCycleChartData.length > 0 ? (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-12 row d-flex justify-content-around">
                        <h5 className='text-start pb-4'>Asset Cycle Status</h5>
                        <div className="text-start" id="chart" style={{ padding: "1em" }}>
                          {this.state.options !== null && this.state.series !== null ?
                            <ReactApexCharts options={this.state.options} series={this.state.series} type="donut" height={250} />
                            : ""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-12 row d-flex">
                        <h5 className='text-start pb-4'>Tracking Status</h5>
                        <div className="text-start" id="chart" style={{ padding: "1em" }}>
                          {this.state.trackingStatusOptions !== null && this.state.trackingStatusSeries !== null ?
                            <ReactApexCharts options={this.state.trackingStatusOptions} series={this.state.trackingStatusSeries} type="pie" height={250} />
                            : ""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-12 row justify-content-end">
                        <div className="col-sm-3 m-2 text-end">
                          <NavLink className="navLink text-decoration-underline" to="/holdingPeriodOver">
                            Holding Period Over
                          </NavLink>
                          &nbsp; &nbsp;&nbsp;
                          <NavLink className="navLink text-decoration-underline" to="/cycleTimeOver">
                            Cycle Time Over
                          </NavLink>
                        </div>
                      </div >
                      <div className="row mb-12 row d-flex justify-content-around">
                        <h5 className='text-start pb-1'>Asset Cycles vs. Customers</h5>
                        <div className="text-start" id="chart" style={{ padding: "1em", overflowX: 'auto' }}>
                          {this.state.customerCycleOptions !== null && this.state.customerCycleSeries !== null ?
                            < ReactApexCharts
                              options={this.state.customerCycleOptions}
                              series={this.state.customerCycleSeries}
                              type="bar"
                              height={450}
                              width={150 * Math.max(this.state.customerCycleChartData.length)}
                            />
                            : ""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-12 row d-flex m-1">
                        <h5 className='text-start pb-1'>Asset Cycles vs. Assets</h5>
                        <div className="text-start" id="chart" style={{ padding: "1em", overflowX: 'auto' }}>
                          {this.state.assetCycleOptions !== null && this.state.assetCycleSeries !== null ?
                            < ReactApexCharts
                              options={this.state.assetCycleOptions}
                              series={this.state.assetCycleSeries}
                              type="bar"
                              height={450}
                              width={150 * Math.max(this.state.assetCycleChartData.length)}
                            />
                            : ""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-12" style={{ padding: "2em" }}>
                        <h5>
                          <b>AssetCycle analytics data not found</b>
                        </h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          )}
        </>
      )
    }
  }
}
export default withRouter(AssetCycleReportAnalysis);