import React, { Component } from 'react'
import { withRouter } from "../../withRouter";
import { Card, CardBody } from "reactstrap";
import FetchServerData from '../../provider/FetchServerData';
import store from '../../redux/store';
import Pagination from '../assetTracking/Pagination';
import Utils from '../../provider/Utils';
import Map from '../map/Map';

class AssetCycleTrakingHistoryList extends Component {
	header = store.getState().header.header;
	constructor(props) {
		super(props);
		let assetCycleId = "";
		if (props.state) {
			assetCycleId = props.state.assetCycleId;
		};
		this.state = {
			render: false,
			searchText: "",
			pageNo: 0,
			showPerPage: 0,
			pageSize: 10,
			trackingHistoryList: [],
			trackingMapList: [],
			assetCycleId: assetCycleId,
		};
		this.getAssetCycleTrakingHistoryList();
	};
	getAssetCycleTrakingHistoryList(pageNo, pageSize) {
		const postObject = {
			header: this.header,
			cycleId: this.state.assetCycleId,
			page: {
				searchText: this.state.search,
				pageNo: pageNo,
				pageSize: pageSize,
			}
		};
		FetchServerData.callPostService('/trackingHistory/getHistoryFoCycle', postObject).then((output) => {
			if (output.status === 'SUCCESS') {
				let trackingHistoryData = output.data;
				let assetHistoryList = output.data.history;
				for (let i = 0; i < assetHistoryList.length; i++) {
					assetHistoryList[i].sortDate = assetHistoryList[i].tdate;
					assetHistoryList[i].tdate = Utils.getUtcToLocalDateTime(assetHistoryList[i].tdate);
					assetHistoryList[i].tenddate = Utils.getUtcToLocalDateTime(assetHistoryList[i].tenddate);
				}
				// let trackingMapList = [];
				// trackingMapList = assetHistoryList.slice(0, 5);

				this.setState({
					render: true,
					trackingHistoryList: trackingHistoryData.history,
					pageNo: trackingHistoryData.page.pageNo,
					showPerPage: trackingHistoryData.page.pageSize,
					totalPages: trackingHistoryData.page.totalPages,
					totalRecords: trackingHistoryData.page.total,
					startDate: trackingHistoryData.assetCycle.startDate,
					endDate: trackingHistoryData.assetCycle.endDate,
					assetName: trackingHistoryData.assetCycle.assetname,
					assetuid: trackingHistoryData.assetCycle.assetuid,
					assetCode: trackingHistoryData.assetCycle.assetcode,
					status: trackingHistoryData.assetCycle.status,
					trackingMapList: assetHistoryList,
				});
			} else {
			};
		});
	};
	onPaginationChange(returnProp) {
		let counter = returnProp.counter;
		let pageNo = this.state.pageNo + counter;
		let pageSize = returnProp.showPerPage;
		let buttonIndex = returnProp.buttonIndex;
		let currentPageIndex = typeof counter !== 'undefined' ? pageNo : buttonIndex;
		let resetPageNo = returnProp.resetPageNo;
		if (resetPageNo === true) {
			currentPageIndex = 0
		};
		this.setState({
			pageNo: currentPageIndex,
		});
		// console.log("showPerPage", returnProp.showPerPage, "pageNo", this.state.pageNo + counter)
		this.getAssetCycleTrakingHistoryList(currentPageIndex, pageSize);
	};
	onSearchChange(e) {
		this.setState({ search: e.target.value });
	};
	toAssetTrakingDetails(assetId) {
		this.props.navigate("/viewAssetTracking", {
			state: {
				action: "view",
				assetId: assetId,
				pageNo: this.state.pageNo,
				pageSize: this.state.showPerPage,
				searchText: this.state.searchText,
			}
		});
	};
	render() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<>
					<div className="row">
						<div className="col md-12">
							<Card style={{ height: "100%", borderRadius: "20px" }}>
								<CardBody>
									{" "}
									<Map markers={this.state.trackingMapList} />
								</CardBody>
							</Card>
						</div>
					</div>
					<div className="row">
						<div className="col md-12">
							<div className="card">
								<div className='row justify-content-between p-3 pb-1'>
									<div className="row col-md-12 text-start">
										<div className="col-sm-4">
											<div className="row pt-2">
												<label
													className="col-sm-5 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Asset Name
												</label>
												<div className="col-sm-7" id="OrganizationCol">
													<span>{this.state.assetName}</span>
												</div>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="row pt-2">
												<label
													className="col-sm-5 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Asset UID
												</label>
												<div className="col-sm-7" id="OrganizationCol">
													<span>{this.state.assetuid}</span>
												</div>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="row pt-2">
												<label
													className="col-sm-5 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Asset Code
												</label>
												<div className="col-sm-7" id="OrganizationCol">
													<span>{this.state.assetCode}</span>
												</div>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="row pt-2">
												<label
													className="col-sm-5 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Start Date
												</label>
												<div className="col-sm-7" id="OrganizationCol">
													<span>{Utils.getUtcToLocalDateTime(this.state.startDate)}</span>
												</div>
											</div>
										</div>
										<div className="col-sm-4">
											<div className="row pt-2">
												<label
													className="col-sm-5 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													End Date
												</label>
												<div className="col-sm-7" id="OrganizationCol">
													<span>{Utils.getUtcToLocalDateTime(this.state.endDate)}</span>
												</div>
											</div>
										</div>
									</div>
									<div className="d-flex col-12 col-lg-4 col-xl-4">
										<div className="col-md-12 text-start">
											<div className="row">
												<span className="col-form-label fw-bold" id="assetFormLabel" style={{ fontSize: "13px" }}>
													Total Results: <span className="ps-1">{this.state.totalRecords}</span>
												</span>
											</div>
										</div>
									</div>
									<div className="d-flex col-12 col-lg-8 col-xl-8">
										<div className='col-12 text-end'>
											<input
												type="search"
												id="gsearch"
												// style={{ width: "240px", height: "33px", outline: "none" }}
												placeholder="Search"
												name="gsearch"
												value={this.state.search}
												onChange={this.onSearchChange.bind(this)}
											/>
											<button
												className='gsearchButton'
												onClick={this.getAssetCycleTrakingHistoryList.bind(this, this.state.pageNo, this.state.pageSize)}
											>
												<i className="fa fa-search" aria-hidden="true"></i>
											</button>
										</div>
									</div>
								</div>
								<div className="table-responsive">
									<table className="table text-start table-hover">
										<thead
											className="assetCycleThead"
										>
											<tr>
												<th scope="col fw-bold ">#</th>
												<th scope="col fw-bold">Plan Name</th>
												<th scope="col fw-bold">First Track Date </th>
												<th scope="col fw-bold">Latest Track Date</th>
												<th scope="col fw-bold">Action</th>
												<th scope="col fw-bold">Location </th>
												<th scope="col fw-bold">Count</th>
											</tr>
										</thead>
										<tbody>
											{this.state.trackingHistoryList?.map((rowData, index) => (
												<tr key={index}>
													<td style={{ width: "6px" }}>{this.state.pageNo * this.state.showPerPage + index + 1}</td>
													<td style={{ width: "280px" }}>{rowData.planName}</td>
													<td style={{ width: "280px" }}>{rowData.tdate}</td>
													<td style={{ width: "280px" }}>{rowData.tenddate}</td>
													<td style={{ width: "150px" }}>{rowData.action}</td>
													<td style={{ width: "600px" }}>{rowData.location ? rowData.location : ""}</td>
													<td style={{ width: "120px" }}>{rowData.count}</td>
												</tr>
											)
											)}
										</tbody>
									</table>
								</div>
								{this.state.totalRecords === 0 &&
									<div className='row text-center p-2'>
										<h5>Data not Available</h5>
									</div>
								}
								{this.state.totalRecords > this.state.pageSize &&
									<div className="row justify-content-between pagination p-1">
										<div className="pageSize col-4 text-start">
											Page {this.state.pageNo + 1} of {this.state.totalPages}
										</div>
										<div className='col-4 text-end'>
											<Pagination
												showPerPage={this.state.showPerPage}
												onPaginationChange={this.onPaginationChange.bind(this)}
												total={this.state.totalRecords}
											/>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				</>
			);
		};
	};
};
export default withRouter(AssetCycleTrakingHistoryList);