import React, { Component } from 'react';
import MaterialTable from 'material-table';
import UiUtils from '../../../provider/UiUtils';
import FetchServerData from '../../../provider/FetchServerData';
import PtsAlert from '../../ptsAlert/PtsAlert';
import { withRouter } from "../../../withRouter";
import store from '../../../redux/store';
import "./ProfileMgmntList.css";
import Utils from '../../../provider/Utils';
import ApplicationStateAction from '../../../redux/applicationState/ApplicationStateAction';
import PtsButton from '../../buttons/PtsButton';
class ProfileMgmntList extends Component {
  header = store.getState().header.header;
  profileState = {};
  constructor(props) {
    super(props);
    let action = props.action;
    if (!action && props.state) {
      action = props.state.action;
    };
    if (props.profileState) {
      this.profileStateDetails = Object.getOwnPropertyNames(props.profileState);
    };
    if (this.profileStateDetails?.length > 0) {
      this.state = {
        action: action,
        selectedTab: props.profileState.selectedTab,
        initialPage: props.profileState.initialPage,
        pageSize: props.profileState.pageSize,
        profileId: props.profileState.profileId,
        profileList: [],
        menus: [],
        activeMenus: new Map(),
        render: false,
        getCreatePermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
      };
    } else {
      this.state = {
        action: action,
        selectedTab: props.selectedTab,
        initialPage: 0,
        pageSize: 10,
        profileList: [],
        menus: [],
        profileId: 0,
        activeMenus: new Map(),
        render: false,
        getCreatePermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
      };
    }
    this.getProfileList();
  };
  getProfileList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService('/profilems/getList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let profileList = output.data.profileList;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let getUpdatePermission = getPermission[3];
        let getDeletePermission = getPermission[4];
        let isPaging = false;
        if (profileList.length > this.state.pageSize) {
          isPaging = true;
        }
        this.setState({
          render: true,
          profileList: profileList,
          isPaging: isPaging,
          initialPage: this.state.initialPage,
          getCreatePermission: getCreatePermission,
          getUpdatePermission: getUpdatePermission,
          getDeletePermission: getDeletePermission,
        });
      } else {
      }
    });
  };

  deleteProfileToBackend = (e, data) => {
    const postObject = {
      header: this.header,
      role: {
        id: data.id,
      },
    };
    FetchServerData.callPostService('/profilems/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          var newList = this.state.profileList.filter((d) => d.id !== data.id)
          this.setState({ profileList: newList });
          PtsAlert.success(output.message);
        } else {
        };
      });
  };

  editProfile(e, data) {
    let profileState = {
      profileId: data.id,
      selectedTab: this.state.selectedTab,
    };
    store.dispatch(ApplicationStateAction.setApplicationState({ profileState }));
    this.props.navigate("/profileMgmnt", {
      state: {
        action: "edit",
        profileId: data.id,
        selectedTab: this.state.selectedTab,
      },
    });
  };

  toProfileDetails(e, rowData) {
    let profileState = {
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
      profileId: rowData.id,
      selectedTab: this.state.selectedTab,
    };
    store.dispatch(ApplicationStateAction.setApplicationState({ profileState }));
    this.props.navigate("/profileMgmnt", {
      state: {
        action: "view",
        profileId: rowData.id,
        name: rowData.name,
        selectedTab: this.state.selectedTab,
      },
    });
  }

  addProfile() {
    let profileState = {
      profileId: "",
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
      selectedTab: this.state.selectedTab,
    };
    store.dispatch(ApplicationStateAction.setApplicationState({ profileState }));
    this.props.navigate("/profileMgmnt", {
      state: {
        action: "create"
      }
    });
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  };

  render() {
    if (this.state.render === false) {
      return <div />;
    };
    return (
      <>
        <div style={{ maxWidth: '100%' }}>
          <MaterialTable
            title=""
            onRowClick={(event, rowData) => {
              this.toProfileDetails(event, rowData);
              event.stopPropagation();
            }}
            icons={UiUtils.getMaterialTableIcons("Add Profile")}
            columns={[
              {
                title: '#',
                render: (rowData) => rowData.tableData.id + 1,
                width: '5%',
                align: 'left',
                cellStyle: {
                  width: '6%',
                },
              },
              {
                title: 'Profile',
                field: 'name',
                cellStyle: {
                  width: '75%',
                },
              },
            ]}
            data={this.state.profileList}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              addRowPosition: "first",
              initialPage: this.state.initialPage,
              pageSize: this.state.pageSize,
              paging: this.state.isPaging,
              pageSizeOptions: Utils.materialTablePageSize(),
              headerStyle: {
                backgroundColor: '#0B6F9D',
                fontWeight: 'bold',
                color: "#ffff",
                align: 'left',
              },
              rowStyle: (rowData) => ({
                backgroundColor: rowData.id === this.state.profileId ? '#FEF5E7' : '',
                height: '3em',
                textTransform: 'capitalize',
              }),
            }}
            onChangePage={(page, pageSize) => {
              this.handleMaterialTablePageChange(page, pageSize);
            }}
            actions={[
              {
                icon: () => (
                  <PtsButton btnName="Add Profile" type="submit" />
                ),
                tooltip: 'Add Profile',
                isFreeAction: true,
                onClick: (event) => this.addProfile(),
              },
            ]}
          />
        </div>
      </>
    )
  };

};
export default withRouter(ProfileMgmntList);