import React, { Component } from 'react'
import { withRouter } from "../../../withRouter";
import FetchServerData from "../../../provider/FetchServerData";
import PtsAlert from "../../ptsAlert/PtsAlert";

import "./ConfirmationEmail.css";
class ConfirmationEmail extends Component {
  constructor(props) {
    super(props);
    let email = props.email
    if (props.state) {
      email = props.state.email;
    }
    this.state = {
      email: email,
    }
  }
  ResendConfirmationEmail = (e) => {
    e.preventDefault();
    const postObject = {
      header: {},
      registration: {
        email: this.state.email,
      }
    };
    FetchServerData.callPostService('/regms/resendVerificationCode', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
        } else {
        }
      })
      .catch((error) => { });
  };
  ConfirmationEmail() {
    this.props.navigate("/verifyOtp", {
      state: {
        email: this.state.email,
      },
    });
  }
  render() {
    return (
      <div className='container cardBox'>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="card text-center">
            <div className="card-body">
              <div className="card-title">
                <div className='confirmationCardHeading'>
                  Thank you for signing up.
                  <br></br>
                  You will receive an email shortly.
                </div>
              </div>
              <p className="card-text"> If you do not receive your confirmation email. Please check your spam or junk mail box of your email client.</p>
              <button className="button" onClick={this.ConfirmationEmail.bind(this)}>Verification Code</button>
              <br></br>
              <div className='resendEmail'>
                <a href="#" onClick={this.ResendConfirmationEmail.bind(this)}>Resend Verification Email</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(ConfirmationEmail)