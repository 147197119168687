import { GET_STATIC_DATA, SET_STATIC_DATA } from "./StaticDataTypes";

const initialState = {
  countryList: [],
  uomList: [],
  userRoleList: [],
  userStatusList: [],
  assetStatusList: [],
  assetTypeList: [],
  propertyDataTypeList: [],
  userTypeList: [],
  currencyList: [],
  cycleFrequencyList: [],
  customerTypeList: [],
  supplierTypeList: [],
  customerStatusList: [],
  supplierStatusList: [],
  assetType: [],
  taxRateList: [],
  materialTypeList: [],
  customerList: [],
};

const StaticDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATIC_DATA:
      return {
        ...state,
      };
    case SET_STATIC_DATA:
      return {
        ...state,
        countryList: action.payload.countryList,
        uomList: action.payload.uomList,
        userRoleList: action.payload.userRoleList,
        userStatusList: action.payload.userStatusList,
        assetStatusList: action.payload.assetStatusList,
        assetTypeList: action.payload.assetTypeList,
        propertyDataTypeList: action.payload.propertyDataTypeList,
        userTypeList: action.payload.userTypeList,
        currencyList: action.payload.currencyList,

        customerTypeList: action.payload.customerTypeList,
        supplierTypeList: action.payload.supplierTypeList,
        customerStatusList: action.payload.customerStatusList,
        supplierStatusList: action.payload.supplierStatusList,
        assetType: action.payload.assetType,
        taxRateList: action.payload.taxRateList,
        cycleFrequencyList: action.payload.cycleFrequencyList,
        materialTypeList: action.payload.materialTypeList,
        customerList: action.payload.customerList,
      };

    default:
      return state;
  }
};

export default StaticDataReducer;
