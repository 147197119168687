import { GET_MENU, SET_MENU, SET_ACTIVE_MENU, } from "./MenuTypes";

const getMenu = () => {
  return {
    type: GET_MENU,
  };
};

const setMenu = (data) => {
  return {
    type: SET_MENU,
    payload: data,
  };
};

export const setActiveMenu = (menuid) => {
  return {
    type: SET_ACTIVE_MENU,
    payload: menuid,
  };
};
const MenuAction = { getMenu, setMenu, setActiveMenu, };

export default MenuAction;
