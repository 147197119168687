import React from "react";
import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Asset from "./component/assetManagement/Asset";
import Category from "./component/catalogueManagement/category/Category";
import TopCategory from "./component/catalogueManagement/TopCategory";
import CustomerList from "./component/customer/CustomerList";
import Dashboard from "./component/dashboard/Dashboard";
import ForgotPassword from "./component/forgotPassword/ForgotPassword";
import ForgotPasswordEmail from "./component/forgotPassword/ForgotPasswordEmail";
import ResetPassword from "./component/forgotPassword/resetPassword/ResetPassword";
import ResetPasswordViaEmail from "./component/forgotPassword/resetPassword/ResetPasswordViaEmail";
import Home from "./component/home/Home";
import Customer from "./component/customer/Customer";
import Settings from "./component/settings/Settings";
import Signin from "./component/signin/Signin";
import SignInEmail from "./component/signin/SignInEmail";
import ConfirmationEmail from "./component/signup/confirmationEmail/ConfirmationEmail";
import Signup from "./component/signup/Signup";
import VerifyOtp from "./component/signup/verifyOtp/VerifyOtp";
import UserManagement from "./component/userManagement/UserManagement";
import UserManagementList from "./component/userManagement/UserManagementList";
import ApplicationLayout from "./layouts/ApplicationLayout";
import HomeLayout from "./layouts/HomeLayout";
import LoginLayout from "./layouts/LoginLayout";
import store from "./redux/store";
import SupplierList from "./component/supplier/SupplierList";
import Supplier from "./component/supplier/Supplier";
import MoveAsset from "./component/moveAsset/MoveAsset";
import MoveCategory from "./component/moveCategory/MoveCategory";
import FirebaseInit from "./provider/FirebaseInit";
import Utils from "./provider/Utils";
import AssetTrackingHistoryList from "./component/assetTracking/AssetTrackingHistoryList";
import Profile from "./component/profile/Profile";
import CustomerLocation from "./component/customer/CustomerLocation";
import TermsConditions from "./component/home/termsConditions/TermsConditions";
import ReturnablePlanList from "./component/returnablePlan/ReturnablePlanList";
import ReturnablePlan from "./component/returnablePlan/ReturnablePlan";
import AssetCycleList from "./component/assetCycle/AssetCycleList";
import AssetCycle from "./component/assetCycle/AssetCycle";
import PrivacyPolicy from "./component/home/privacyPolicy/PrivacyPolicy";
import MISReports from "./component/misRports/MISReports";
import CustomerReportList from "./component/misRports/customerReports/CustomerReportList";
import SupplierReportList from "./component/misRports/supplierReports/SupplierReportList";
import CustomerReportAsset from "./component/misRports/customerReports/CustomerReportAsset";
import SupplierReportAsset from "./component/misRports/supplierReports/SupplierReportAsset";
import ActiveAssetList from "./component/misRports/assetReports/ActiveAssetList";
import InMaintenanceAssetList from "./component/misRports/assetReports/InMaintenanceAssetList";
import MissingAssetList from "./component/misRports/assetReports/MissingAssetList";
import DamagedAssetList from "./component/misRports/assetReports/DamagedAssetList";
import DiscardedAssetList from "./component/misRports/assetReports/DiscardedAssetList";
import CycleTimeOverCycleList from "./component/misRports/assetCycleReports/CycleTimeOverCycleList";
import HoldingPeriodOverCycleList from "./component/misRports/assetCycleReports/HoldingPeriodOverCycleList";
import AdminLayout from "./layouts/AdminLayout";
import AdminLogin from "./adminPanel/adminLogin/AdminLogin";
import AdminForgetPassword from "./adminPanel/adminForgetPassword/AdminForgetPassword";
import AdminResetPassword from "./adminPanel/adminForgetPassword/AdminResetPassword";
import AdminDashboard from "./adminPanel/adminDashboard/AdminDashboard";
import EmailTemplateList from "./adminPanel/emailTemplate/EmailTemplateList";
import AppUserList from "./adminPanel/appUser/AppUserList";
import AppUser from "./adminPanel/appUser/AppUser";
import RegistrationList from "./adminPanel/registration/RegistrationList";
import EmailTemplate from "./adminPanel/emailTemplate/EmailTemplate";
import AdminProfile from "./adminPanel/adminProfile/AdminProfile";
import CustomerReportAnalysis from "./component/misRports/customerReports/CustomerReportAnalysis";
import SupplierReportAnalysis from "./component/misRports/supplierReports/SupplierReportAnalysis";
import AssetAnalysis from "./component/misRports/assetReports/AssetAnalysis";
import AssetCycleReportAnalysis from "./component/misRports/assetCycleReports/AssetCycleReportAnalysis";
import ProfileManagementList from "./adminPanel/profileManagement/ProfileManagementList";
import ProfileManagement from "./adminPanel/profileManagement/ProfileManagement";
import OrganizationAssetList from "./component/misRports/organizationAssets/OrganizationAssetList";
import CustomerWiseAssetList from "./component/misRports/customerWiseAssets/CustomerWiseAssetList";
import ProfileMgmnt from "./component/settings/profileManagement/ProfileMgmnt";
import TermsConditionContent from "./component/termsConditionContent/TermsConditionContent";
import PrivacyPolicyContent from "./component/privacyPolicyContent/PrivacyPolicyContent";
import AboutUsContent from "./component/aboutUsContent/AboutUsContent";
import AdminResetPasswordViaEmail from "./adminPanel/adminForgetPassword/AdminResetPasswordViaEmail";
import AccountDelete from "./component/home/accountDelete/AccountDelete";
import VerifyOtpViaEmail from "./component/signup/verifyOtp/VerifyOtpViaEmail.js";
import TermsConditionTemplate from "./adminPanel/terms&ConditionTemplate/TermsConditionTemplate.js";
import TermsConditionTemplateList from "./adminPanel/terms&ConditionTemplate/TermsConditionTemplateList.js";
import PrivacyPolicyTemplateList from "./adminPanel/privacyPolicyTemplate/PrivacyPolicyTemplateList.js";
import PrivacyPolicyTemplate from "./adminPanel/privacyPolicyTemplate/PrivacyPolicyTemplate.js";
import AssetPrint from "./component/assetManagement/AssetPrint.js";
import Registration from "./adminPanel/registration/Registration.js";

class App extends React.Component {
  constructor(props) {
    super(props);
    FirebaseInit.requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        FirebaseInit.onMessageListener();
        FirebaseInit.registerServiceWorker();
        /* Update Token whenever token changes, Token will be uploaded on server only if user Login successfully */
        Utils.setFCMTokenForUser();
      })
      .catch((err) => {});
  }
  render() {
    return (
      <div className="App">
        <HashRouter>
          <ToastContainer
            style={{
              alignItems: "center",
              fontSize: "small",
              width: "auto",
              maxWidth: "20rem",
              maxHeight: "1em",
              wordBreak: "break-word",
            }}
            position="top-right"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Provider store={store}>
            <Routes>
              <Route path="/" element={<HomeLayout />}>
                <Route path="" element={<Home />} />
                <Route path="contact" element={<Home scrollTo={"contact"} />} />
                <Route path="termsConditions" element={<TermsConditions />} />
                <Route path="privacyPolicy" element={<PrivacyPolicy />} />
                <Route path="accountdelete" element={<AccountDelete />} />
              </Route>
              <Route path="/" element={<LoginLayout />}>
                <Route path="signup" element={<Signup />} />
                <Route
                  path="confirmationEmail"
                  element={<ConfirmationEmail />}
                />
                <Route path="signinEmail" element={<SignInEmail />} />
                <Route path="signin" element={<Signin />} />
                <Route path="verifyOtp" element={<VerifyOtp />} />
                <Route
                  path="verifyOtpViaEmail"
                  element={<VerifyOtpViaEmail />}
                />
                <Route
                  path="forgotPasswordEmail"
                  element={<ForgotPasswordEmail />}
                />
                <Route path="forgotPassword" element={<ForgotPassword />} />
                <Route path="resetPassword" element={<ResetPassword />} />
                <Route
                  path="resetPasswordViaEmail"
                  element={<ResetPasswordViaEmail />}
                />
                <Route path="APSignin" element={<AdminLogin />} />
                <Route
                  path="APForgetPassword"
                  element={<AdminForgetPassword />}
                />
                <Route
                  path="APResetPassword"
                  element={<AdminResetPassword />}
                />
                <Route
                  path="APResetPasswordViaEmail"
                  element={<AdminResetPasswordViaEmail />}
                />
              </Route>
              <Route>
                <Route path="/" element={<ApplicationLayout />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/catalogue" element={<TopCategory />} />
                  <Route path="/category" element={<Category />} />
                  <Route path="/moveCategory" element={<MoveCategory />} />
                  <Route path="/userList" element={<UserManagementList />} />
                  <Route path="/user" element={<UserManagement />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/asset" element={<Asset />} />
                  <Route path="/moveAsset" element={<MoveAsset />} />
                  <Route path="/customerList" element={<CustomerList />} />
                  <Route path="/customer" element={<Customer />} />
                  <Route
                    path="/customerLocation"
                    element={<CustomerLocation />}
                  />
                  <Route path="/supplierList" element={<SupplierList />} />
                  <Route path="/supplier" element={<Supplier />} />
                  <Route
                    path="/returnablePlanList"
                    element={<ReturnablePlanList />}
                  />
                  <Route path="/returnablePlan" element={<ReturnablePlan />} />
                  <Route path="/assetCycleList" element={<AssetCycleList />} />
                  <Route path="/assetCycle" element={<AssetCycle />} />
                  <Route path="/assetPrint" element={<AssetPrint />} />
                  <Route
                    path="/assetTrackingList"
                    element={<AssetTrackingHistoryList />}
                  />
                  <Route path="/profileMgmnt" element={<ProfileMgmnt />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/reports" element={<MISReports />} />
                  <Route
                    path="/customerReportList"
                    element={<CustomerReportList />}
                  />
                  <Route
                    path="/customerAssets"
                    element={<CustomerReportAsset />}
                  />
                  <Route
                    path="/customerAnalytics"
                    element={<CustomerReportAnalysis />}
                  />
                  <Route
                    path="/supplierReportList"
                    element={<SupplierReportList />}
                  />
                  <Route
                    path="/supplierAssets"
                    element={<SupplierReportAsset />}
                  />
                  <Route
                    path="/supplierAnalytics"
                    element={<SupplierReportAnalysis />}
                  />
                  <Route path="/activeAssets" element={<ActiveAssetList />} />
                  <Route
                    path="/inMaintenanceAssets"
                    element={<InMaintenanceAssetList />}
                  />
                  <Route path="/missingAssets" element={<MissingAssetList />} />
                  <Route path="/damagedAssets" element={<DamagedAssetList />} />
                  <Route
                    path="/discardedAssets"
                    element={<DiscardedAssetList />}
                  />
                  <Route path="/assetAnalytics" element={<AssetAnalysis />} />
                  <Route
                    path="/assetCycleAnalytics"
                    element={<AssetCycleReportAnalysis />}
                  />
                  <Route
                    path="/cycleTimeOver"
                    element={<CycleTimeOverCycleList />}
                  />
                  <Route
                    path="/holdingPeriodOver"
                    element={<HoldingPeriodOverCycleList />}
                  />
                  <Route
                    path="/organizationAssets"
                    element={<OrganizationAssetList />}
                  />
                  <Route
                    path="/customerWiseAssetList"
                    element={<CustomerWiseAssetList />}
                  />
                  <Route
                    path="/termsConditionContent"
                    element={<TermsConditionContent />}
                  />
                  <Route
                    path="/privacyPolicyContent"
                    element={<PrivacyPolicyContent />}
                  />
                  <Route path="/aboutUsContent" element={<AboutUsContent />} />
                </Route>
              </Route>
              <Route path="/" element={<AdminLayout />}>
                <Route path="/APDashboard" element={<AdminDashboard />} />
                <Route
                  path="/registrationList"
                  element={<RegistrationList />}
                />
                <Route path="/viewRegistration" element={<Registration />} />
                <Route path="/appUserList" element={<AppUserList />} />
                <Route path="/appUser" element={<AppUser />} />
                <Route
                  path="/emailTemplateList"
                  element={<EmailTemplateList />}
                />
                <Route path="/emailTemplate" element={<EmailTemplate />} />
                <Route path="/ApProfile" element={<AdminProfile />} />
                <Route path="/apSettings" element={<ProfileManagementList />} />
                <Route path="/apProfileMgmnt" element={<ProfileManagement />} />
                {/* <Route path="/apTermsCondition" element={<TermsConditionTemplate />} /> */}
                <Route
                  path="/aptermsConditionsList"
                  element={<TermsConditionTemplateList />}
                />
                <Route
                  path="/addTermsCondition"
                  element={<TermsConditionTemplate />}
                />
                <Route
                  path="/viewTermsCondition"
                  element={<TermsConditionTemplate />}
                />
                <Route
                  path="/editTermsCondition"
                  element={<TermsConditionTemplate />}
                />
                <Route
                  path="/apTermsCondition"
                  element={<TermsConditionContent />}
                />
                <Route
                  path="/privacyPolicyList"
                  element={<PrivacyPolicyTemplateList />}
                />
                <Route
                  path="/addPrivacyPolicy"
                  element={<PrivacyPolicyTemplate />}
                />
                <Route
                  path="/viewPrivacyPolicy"
                  element={<PrivacyPolicyTemplate />}
                />
                <Route
                  path="/editPrivacyPolicy"
                  element={<PrivacyPolicyTemplate />}
                />
                <Route
                  path="/apPrivacyPolicy"
                  element={<PrivacyPolicyContent />}
                />

                <Route path="/apAboutUs" element={<AboutUsContent />} />
              </Route>
              <Route path="/home" />
            </Routes>
          </Provider>
        </HashRouter>
      </div>
    );
  }
}
export default App;
