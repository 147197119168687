import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { NavLink } from 'react-router-dom';
import { IconButton, InputAdornment } from '@mui/material';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Col } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import PtsAlert from '../../ptsAlert/PtsAlert';
import './ResetPassword.css';
import { TextValidator } from 'react-material-ui-form-validator';
import logo from '../../../assets/images/AssetTrackifierLogo.png'
import { Autocomplete } from '@mui/material';
import { withRouter } from "../../../withRouter";

export class ResetPassword extends Component {
  tenantList = []
  constructor(props) {
    super(props);
    let email = props.email;
    let tenantList = props.tenantList;
    let tenantId = props.tenantId;
    if (props.state) {
      email = props.state.email;
      tenantList = props.state.tenantList;
      tenantId = props.state.tenantId;
    }
    this.state = {
      code: '',
      password: '',
      confirmPassword: '',
      passwordHelperText: '',
      email: email,
      tenantList: tenantList,
      tenantId: tenantId,
      showPassword: false,
      showConfirmPassword: false,
      render: false,
      name: "",
      value1: '',
      value1Ref: React.createRef(),
      value2: '',
      value2Ref: React.createRef(),
      value3: '',
      value3Ref: React.createRef(),
      value4: '',
      value4Ref: React.createRef(),
      value5: '',
      value5Ref: React.createRef(),
      value6: '',
      value6Ref: React.createRef(),
    };
    // this.getTenantList(email);
  }

  handleCompanyChange = (selectedValue) => {
    this.setState({ tenantId: selectedValue })
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value, passwordHelperText: '' });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleClickShowConfirmPassword() {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }
  handlePasswordConfirmChange(e) {
    this.setState({ confirmPassword: e.target.value });
  }
  handleVerificationCodeChange(e) {
    this.setState({ code: e.target.value });
  }

  // getTenantList(email) {
  //   const postObject = {
  //     header: {
  //       email: email,
  //     },
  //   };
  //   FetchServerData.callPostService("/tntms/getList", postObject).then(
  //     (output) => {
  //       if (output.status === "SUCCESS") {
  //         let tenantList = output.data;
  //         this.setState({
  //           render: true,
  //           tenantList: tenantList,
  //           tenantId: tenantList[0],
  //         });
  //       } else {
  //       }
  //     }
  //   );
  // }

  resetPasswordLinkBackend() {
    const { value1, value2, value3, value4, value5, value6 } = this.state;
    const otpValue = `${value1}${value2}${value3}${value4}${value5}${value6}`;
    if (otpValue.length !== 6) {
      return;
    }
    if (this.state.password !== this.state.confirmPassword) {
      return
    }
    const postObject = {
      header: { tid: this.state.tenantId.id },
      appuser: {
        email: this.state.email,
        credential: this.state.password,
        temppassword: otpValue,
      }
    };

    FetchServerData.callPostService('/appuserms/resetPassword', postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.props.navigate({
            pathname: '/signinEmail',
          });
        } else {
        }
      })
      .catch((error) => { });
  }

  componentDidMount() {
    this.state.value1Ref.current.focus();
  }

  handleValueChange = async (e) => {
    const { name, value } = e.target;
    if (e.key === 'Backspace') {
      return;
    }
    if (value.length > 1) {
      PtsAlert.error('Please enter a single digit');
      return;
    }

    await this.setState({ [name]: value });

    switch (name) {
      case 'value1':
        this.value2Focus();
        this.value2Select();
        break;
      case 'value2':
        this.value3Focus();
        this.value3Select();
        break;
      case 'value3':
        this.value4Focus();
        this.value4Select();
        break;
      case 'value4':
        this.value5Focus();
        this.value5Select();
        break;
      case 'value5':
        this.value6Focus();
        this.value6Select();
        break;
      case 'value6':
        // this.resetPasswordLinkBackend();
        break;
      default: break;
    }
  };

  handleKeyUp = (e) => {
    const { name } = e.target;
    if (e.key === 'Enter') {
      this.resetPasswordLinkBackend();
    } else if (e.key === 'Backspace') {
      this.setState({ [name]: '' });

      switch (name) {
        case 'value1':
          break;
        case 'value2':
          this.setState({ value1: '' });
          this.value1Focus();
          break;
        case 'value3':
          this.setState({ value2: '' });
          this.value2Focus();
          break;
        case 'value4':
          this.setState({ value3: '' });
          this.value3Focus();
          break;
        case 'value5':
          this.setState({ value4: '' });
          this.value4Focus();
          break;
        case 'value6':
          this.setState({ value5: '' });
          this.value5Focus();
          break;
        default: break;
      }
    } else if (e.key === 'ArrowLeft') {
      switch (name) {
        case 'value1':
          break;
        case 'value2':
          this.value1Focus();
          this.value1Select();
          break;
        case 'value3':
          this.value2Focus();
          this.value2Select();
          break;
        case 'value4':
          this.value3Focus();
          this.value3Select();
          break;
        case 'value5':
          this.value4Focus();
          this.value4Select();
          break;
        case 'value6':
          this.value5Focus();
          this.value5Select();
          break;
        default: break;
      }
    } else if (e.key === 'ArrowRight') {
      switch (name) {
        case 'value1':
          this.value2Focus();
          this.value2Select();
          break;
        case 'value2':
          this.value3Focus();
          this.value3Select();
          break;
        case 'value3':
          this.value4Focus();
          this.value4Select();
          break;
        case 'value4':
          this.value5Focus();
          this.value5Select();
          break;
        case 'value5':
          this.value6Focus();
          this.value6Select();
          break;
        case 'value6':
          break;
        default: break;
      }
    }
  };
  value1Focus = () => {
    this.state.value1Ref.current.focus();
  };
  value2Focus = () => {
    this.state.value2Ref.current.focus();
  };
  value3Focus = () => {
    this.state.value3Ref.current.focus();
  };
  value4Focus = () => {
    this.state.value4Ref.current.focus();
  };
  value5Focus = () => {
    this.state.value5Ref.current.focus();
  };
  value6Focus = () => {
    this.state.value6Ref.current.focus();
  };

  value1Select = () => {
    this.state.value1Ref.current.select();
  };
  value2Select = () => {
    this.state.value2Ref.current.select();
  };
  value3Select = () => {
    this.state.value3Ref.current.select();
  };
  value4Select = () => {
    this.state.value4Ref.current.select();
  };
  value5Select = () => {
    this.state.value5Ref.current.select();
  };
  value6Select = () => {
    this.state.value6Ref.current.select();
  };

  renderInputBox = (name, ref) => {
    return (
      <Col md={2} style={{ padding: '0rem 0rem 0rem 1rem' }}>
        <input
          type="number"
          ref={this.state[ref]}
          className="form-control otpFormControlInput"
          id={'otpFormControlInput-' + name}
          maxLength="1"
          name={name}
          value={this.state[name]}
          onKeyUp={this.handleKeyUp}
          onChange={this.handleValueChange}
          aria-describedby="defaultFormControlHelp"
        />
      </Col>
    );
  };

  render() {
    const error = this.state.password !== this.state.confirmPassword;
    return (
      <>
        <div className="authentication-wrapper authentication-cover">
          <div className="authentication-inner row m-0">
            <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center leftBackground ">
              <div className="mx-auto">
                <div className="logoBackground">
                  <img
                    src={logo}
                    className="img-fluid logoImage"
                    alt=""
                    data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                    data-app-light-img="illustrations/girl-with-laptop-light.png"
                  />
                </div>
                <div className="align-items-center">
                  <h2 className="logoHeding">AssetTrackifier</h2>
                </div>
              </div>
            </div>
            <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center authentication-bg p-sm-5 p-4">
              <div className="w-px-400 mx-auto">
                <div className="row text-start">
                  <h4>Reset Password?</h4>
                </div>
                <ValidatorForm
                  ref="form"
                  id="formAuthentication"
                  className="mb-3"
                  onSubmit={this.resetPasswordLinkBackend.bind(this)}
                >
                  <div className="mb-2">
                    <label htmlFor="email" className="form-label" id="signupFormLabel">
                      EMAIL ID <span className="asterisk">*</span>
                    </label>
                    <TextValidator
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      placeholder="Enter Email"
                      type="email"
                      id="email"
                      value={this.state.email}
                      onChange={this.handleEmailChange.bind(this)}
                      autoComplete="off"
                      validators={['required', 'isEmail']}
                      errormessages={['This field is required', 'Email id is not valid']}
                      disabled
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="email"
                      className="form-label"
                      id="signupFormLabel"
                    >
                      Company name
                      <span className="asterisk">*</span>
                    </label>
                    <Autocomplete
                      id="tenantList"
                      options={this.state.tenantList}
                      value={this.state.tenantId}
                      defaultValue={this.state.tenantId}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleCompanyChange(value);
                      }}
                      disabled
                      inputprops={{ style: { textTransform: "capitalize" } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Company"
                          variant="outlined"
                          required
                          margin="normal"
                        />
                      )}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="email" className="form-label" id="signupFormLabel">
                      Verification Code <span className="asterisk">*</span>
                    </label>
                    <div className="d-flex col-12 col-lg-12 col-xl-12 pt-3">
                      <div className='row'>
                        {this.renderInputBox('value1', 'value1Ref')}
                        {this.renderInputBox('value2', 'value2Ref')}
                        {this.renderInputBox('value3', 'value3Ref')}
                        {this.renderInputBox('value4', 'value4Ref')}
                        {this.renderInputBox('value5', 'value5Ref')}
                        {this.renderInputBox('value6', 'value6Ref')}
                      </div>
                    </div>
                  </div>
                  <div className="mb-2 form-password-toggle">
                    <label htmlFor="new-password" className="form-label" id="signupFormLabel">
                      NEW PASSWORD <span className="asterisk">*</span>
                    </label>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.password}
                      name="password"
                      placeholder="Enter Password *"
                      type={this.state.showPassword ? 'text' : 'password'}
                      id="new-password"
                      autoComplete="current-password"
                      onChange={this.handlePasswordChange.bind(this)}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment
                            position="end"
                          // onFocus={this.gotToButton.bind(this)}
                          >
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword.bind(this)}
                            >
                              {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="mb-2 form-password-toggle">
                    <label htmlFor="new-password" className="form-label" id="signupFormLabel">
                      Confirm Password <span className="asterisk">*</span>
                    </label>
                    <TextField
                      id="confirmPassword"
                      className="form-control"
                      margin="normal"
                      variant="outlined"
                      placeholder="Confirm Password;"
                      name="confirmPassword"
                      aria-describedby="alignment-confirm password2"
                      type={this.state.showConfirmPassword ? "text" : "password"}
                      value={this.state.confirmPassword}
                      onChange={this.handlePasswordConfirmChange.bind(this)}
                      helperText={error ? "Password & confirm password must match" : ""}
                      error={error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowConfirmPassword.bind(this)}
                            >
                              {this.state.showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <button className="goButton d-grid w-100 mt-4">Reset Password</button>
                </ValidatorForm>
                <p className="signupLink">
                  <span>Already have an account?</span>&nbsp;
                  <NavLink to="/signinEmail">
                    <span>Sign In</span>
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ResetPassword);
