import React, { Component } from "react";
import { Col } from "reactstrap";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FetchServerData from "../../../provider/FetchServerData";
import PtsAlert from "../../ptsAlert/PtsAlert";
import { withRouter } from "../../../withRouter";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import store from "../../../redux/store";
import Utils from "../../../provider/Utils";
import PtsButton from "../../buttons/PtsButton";
import AssetCodeMaster from "./AssetCodeMaster";
import AssetStaticDataAction from "../../../redux/assetStaticData/AssetStaticDataAction";
class AssetCodeMasterList extends Component {
  header = store.getState().header.header;
  assetCodeRefList = [];
  constructor() {
    super();
    this.state = {
      assetCodeId: "",
      custabbreviation: null,
      initialPage: 0,
      pageSize: 10,
      assetCodeList: [],
      customerList: [],
      materialTypeList: [],
      // column: "asc",
      // direction: "desc",
      column: null,
      direction: "desc",
      render: false,
      addRow: false,
      getCreatePermission: false,
      getUpdatePermission: false,
      getDeletePermission: false,
      isAscending: false,
    };
    this.getAssetCodeList();
  }

  componentDidMount() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService(
      "/accountCodeStaticms/get",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let staticData = output.data;
        let assetStaticData = {
          materialTypeList: staticData.materialTypeList,
          customerList: staticData.customerList,
        };
        store.dispatch(
          AssetStaticDataAction.setAssetStaticData(assetStaticData)
        );
      } else {
      }
    });
  }

  getAssetCodeList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/assetcodems/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getCreatePermission = getPermission[1];
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let assetCodeList = output.data.assetCodes;
          let isPaging = false;
          if (assetCodeList.length > this.state.pageSize) {
            isPaging = true;
          }
          for (let i = 0; i < assetCodeList.length; i++) {
            assetCodeList[i].custAbbreviation = {
              abbreviation: assetCodeList[i].custabbreviation,
            };
          }
          this.setState({
            render: true,
            addRow: true,
            assetCodeList: assetCodeList,
            isPaging: isPaging,
            initialPage: this.state.initialPage,
            getCreatePermission: getCreatePermission,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        } else {
        }
      }
    );
  }

  createAssetCodeToBackend(newData) {
    if (newData.pocketcode.length !== 4) {
      PtsAlert.error("Pocket code must be 4 Character log");
      return false;
    }

    for (let i = 0; i < this.state.assetCodeList.length; i++) {
      if (
        this.state.assetCodeList[i] &&
        newData.srno === i &&
        this.assetCodeRefList[i] &&
        this.assetCodeRefList[i].current
      ) {
        const postObject = {
          header: this.header,
          assetCode: {
            custabbreviation:
              this.assetCodeRefList[i].current.state.custAbbreviation
                ?.abbreviation,
            pocketcode: this.assetCodeRefList[i].current.state.pocketcode,
            description: this.assetCodeRefList[i].current.state.description,
            materialtype: {
              id: this.assetCodeRefList[i].current.state.materialtype.id,
            },
          },
        };
        FetchServerData.callPostService("/assetcodems/create", postObject).then(
          (output) => {
            if (output.status === "SUCCESS") {
              PtsAlert.success(output.message);
              let assetCodeInfo = output.data.assetCode;
              let custAbbreviation = {
                abbreviation: assetCodeInfo.custabbreviation,
              };
              assetCodeInfo = {
                ...assetCodeInfo,
                custAbbreviation: custAbbreviation,
              };
              this.assetCodeRefList[i].current.state.action = "view";
              this.setState({
                render: true,
                action: "view",
                assetCodeList: [assetCodeInfo, ...this.state.assetCodeList],
              });
            } else {
            }
          }
        );
      }
    }
  }
  updateAssetCodeToBackend(newData) {
    if (newData.pocketcode.length !== 4) {
      PtsAlert.error("Pocket code must be 4 Character log");
      return false;
    }

    const postObject = {
      header: this.header,
      assetCode: {
        custabbreviation: newData.custAbbreviation?.abbreviation,
        pocketcode: newData.pocketcode,
        description: newData.description,
        materialtype: { id: newData.materialtype?.id },
        id: newData.id,
      },
    };
    FetchServerData.callPostService("/assetcodems/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let assetCodeData = output.data.assetCode;
          let custAbbreviation = {
            abbreviation: assetCodeData.custabbreviation,
          };
          assetCodeData = {
            ...assetCodeData,
            custAbbreviation: custAbbreviation,
          };
          var currentIndex = this.state.assetCodeList.findIndex(
            (d) => d.id === assetCodeData.id
          );
          let currentList = [...this.state.assetCodeList];
          currentList.splice(currentIndex, 1, assetCodeData);
          this.setState({
            render: true,
            assetCodeList: currentList,
            isOpen: false,
          });
        } else {
        }
      }
    );
  }
  deleteAssetCodeToBackend = (data) => {
    const postObject = {
      header: this.header,
      assetCode: {
        id: data.id,
      },
    };
    FetchServerData.callPostService("/assetcodems/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // var newList = output.data.assetCode;
          for (let i = 0; i < this.state.assetCodeList.length; i++) {
            if (this.state.assetCodeList[i].id === data.id) {
              this.state.assetCodeList.splice(i, 1);
            }
          }
          this.setState({
            assetCodeList: this.state.assetCodeList,
            render: true,
          });
          PtsAlert.success(output.message);
        } else {
        }
      }
    );
  };

  handleCusAbbreviationChange(selectedValue) {
    this.setState({
      custabbreviation: selectedValue,
    });
  }

  addAssetCode() {
    let assetInfos = this.state.assetCodeList;
    let assetInfo = {
      action: "create",
      operation: "add",
      custabbreviation: null,
      materialtype: null,
      pocketcode: "",
      description: "",
    };
    assetInfos.push(assetInfo);
    this.setState({
      assetCodeList: assetInfos,
      addRow: true,
      action: "create",
    });
  }

  onAssetCodeRemove(item) {
    let items = [];
    if (item.operation && "add" === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.srno;
      for (let i = 0; i < this.state.assetCodeList.length; i++) {
        if (this.state.assetCodeList[i].operation === "add" && index === i) {
          this.assetCodeRefList.splice(i, 1);
        } else {
          items.push(this.state.assetCodeList[i]);
        }
      }
      this.setState({ assetCodeList: items, addRow: false });
    }
  }

  onAssetCodeEditClick(e) {
    console.log(e);
  }

  onSort(column) {
    const direction = this.state.column
      ? this.state.direction === "desc"
        ? "asc"
        : "desc"
      : "desc";
    const sortedData = this.state.assetCodeList.sort((a, b) => {
      if (column === "description") {
        if (a.description === null) {
          return 1;
        }
        if (b.description === null) {
          return -1;
        }
        const nameA = a.description?.toLowerCase(); // ignore upper and lowercase
        const nameB = b.description?.toLowerCase(); // ignore upper and lowercase

        if (nameA === nameB) {
          return 0;
        }
        if (direction === "asc") {
          return nameA < nameB ? -1 : 1;
        }
        return nameA > nameB ? 1 : -1;
      } else if (column === "custabbreviation") {
        if (a.code === null) {
          return 1;
        }
        if (b.code === null) {
          return -1;
        }
        const nameA = a.code?.toLowerCase(); // ignore upper and lowercase
        const nameB = b.code?.toLowerCase(); // ignore upper and lowercase

        if (nameA === nameB) {
          return 0;
        }
        if (direction === "asc") {
          return nameA < nameB ? -1 : 1;
        }
        return nameA > nameB ? 1 : -1;
      } else {
        if (a.custabbreviation === null) {
          return 1;
        }
        if (b.custabbreviation === null) {
          return -1;
        }
        const nameA = a.custabbreviation?.toLowerCase(); // ignore upper and lowercase
        const nameB = b.custabbreviation?.toLowerCase(); // ignore upper and lowercase

        if (nameA === nameB) {
          return 0;
        }
        if (direction === "asc") {
          return nameA < nameB ? -1 : 1;
        }
        return nameA > nameB ? 1 : -1;
      }
    });

    if (direction === "desc") {
      sortedData.reverse();
    }

    this.setState({
      assetCodeList: sortedData,
      isAscending: !this.state.isAscending,
      column: column,
      direction: direction,
    });
  }

  setArrow = (column) => {
    let className = "sort-direction";

    if (this.state.column === column) {
      className += this.state.direction === "asc" ? " asc" : " desc";
    }
    return className;
  };

  getHeader() {
    const { column, direction } = this.state;
    return (
      <thead className="tableThead">
        <tr>
          <th style={{ width: "5%" }} scope="col fw-bold ">
            #
          </th>
          <th
            style={{ width: "20%" }}
            scope="col fw-bold"
            sorted={column === "custabbreviation" ? direction : null}
            onClick={(e) => this.onSort("custabbreviation")}
          >
            Customer Abbreviation
            {direction === "asc" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon fs-5" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon fs-5" />
              </>
            )}
          </th>
          <th style={{ width: "20%" }} scope="col fw-bold">
            Material Type
          </th>
          <th
            style={{ width: "20%" }}
            scope="col fw-bold"
            sorted={column === "code" ? direction : null}
            onClick={(e) => this.onSort("code")}
          >
            Pocket Code
            {direction === "asc" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon  fs-5" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon fs-5" />
              </>
            )}
          </th>
          <th
            style={{ width: "20%" }}
            scope="col fw-bold"
            onClick={(e) => this.onSort("description")}
            sorted={column === "description" ? direction : null}
          >
            Description
            {direction === "asc" ? (
              <>
                <ArrowUpwardIcon className="bonus-icon  fs-5" />
              </>
            ) : (
              <>
                <ArrowDownwardIcon className="bonus-icon  fs-5" />
              </>
            )}
          </th>
          <th style={{ width: "20%" }} scope="col fw-bold">
            Action
          </th>
        </tr>
      </thead>
    );
  }

  render() {
    this.printComponentRef = React.createRef();
    if (this.state.render === false) {
      return <div />;
    }

    this.assetCodeRefList = [];
    for (
      let i = 0;
      this.state.assetCodeList && i < this.state.assetCodeList.length;
      i++
    ) {
      this.assetCodeRefList[i] = React.createRef();
    }

    let assetCodeItemList = [];
    this.state.assetCodeList &&
      this.state.assetCodeList.forEach((element, index) => {
        assetCodeItemList.unshift(
          <AssetCodeMaster
            ref={this.assetCodeRefList[index]}
            key={index}
            data={element}
            backgroundColor={
              index % 2 ? { background: "#EFF4F7" } : { background: "#F7F7F7" }
            }
            srno={index}
            action={element.action ? element.action : "view"}
            // ListRow={this.state.etinListRow}
            iteamIndex={this.state.assetCodeList.length - index}
            onAdd={this.createAssetCodeToBackend.bind(this)}
            onAssetCodeEditClick={this.onAssetCodeEditClick.bind(this)}
            onAssetCodeDelete={this.deleteAssetCodeToBackend.bind(this)}
            onAssetCodeUpdateSave={this.updateAssetCodeToBackend.bind(this)}
            onAssetCodeRemove={this.onAssetCodeRemove.bind(this)}
            // onSort={this.onSort.bind(this)}
          />
        );
      });

    return (
      <>
        <div style={{ maxWidth: "100%" }}>
          <div style={{ maxWidth: "100%" }}>
            <div className="row">
              <Col md={12} style={{ flexDirection: "column", display: "flex" }}>
                <div className="card">
                  <div className="row justify-content-between p-3">
                    <div className="d-flex col-12 col-lg-4 col-xl-4">
                      <div className="col-12 text-start totalResults">
                        {/* <span>Total Results:</span> */}
                      </div>
                    </div>
                    <div className="d-flex col-12 col-lg-8 col-xl-8">
                      <div className="col-12 text-end">
                        {/* <input
                        type="search"
                        id="gsearch"
                        placeholder="Search"
                        name="gsearch"
                        style={{ marginTop: "2px" }}
                      // value={this.state.searchText}
                      // onChange={this.onSearchChange.bind(this)}
                      />
                      <button
                        className="gsearchButton"
                      // onClick={this.onSearchClick.bind(this)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button> */}{" "}
                        &nbsp;&nbsp;
                        {/* {this.state.addRow ? */}
                        <ReactToPrint
                          trigger={() => (
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                background: "#0B6F9D",
                                color: "#fff",
                                marginRight: "1rem",
                                padding: "0.4375rem 1.25rem",
                              }}
                              startIcon={<PrintIcon />}
                              // onClick={(e) => this.printContent(e)}
                            >
                              Print
                            </Button>
                          )}
                          content={() => this.printComponentRef}
                        />
                        <PtsButton
                          btnName="Add Asset Code"
                          type="submit"
                          onClick={this.addAssetCode.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table text-start table-hover"
                      ref={(el) => (this.printComponentRef = el)}
                    >
                      {this.getHeader()}
                      <tbody>{assetCodeItemList}</tbody>
                    </table>
                  </div>
                  {this.state.totalRecords === 0 && (
                    <div className="row text-center p-2">
                      <h5>Data not Available</h5>
                    </div>
                  )}
                </div>
              </Col>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(AssetCodeMasterList);
