import { GET_App_USER_ROLE, SET_App_USER_ROLE, SET_ACTIVE_MENU } from "./AppUserRoleTypes";

const initialState = {
  appUserRoleList: {},
  adminActiveMenu: {},
};

const AppUserRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_App_USER_ROLE:
      return {
        ...state,
      };
    case SET_App_USER_ROLE:
      return {
        ...state,
        appUserRoleList: action.payload,
      };
    case SET_ACTIVE_MENU:
      return {
        ...state,
        adminActiveMenu: action.payload,
      };

    default:
      return state;
  }
};

export default AppUserRoleReducer;
