import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    return <Component navigate={navigate} {...props} state={state} />;
  };

  return Wrapper;
};
