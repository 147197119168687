import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import React, { Component } from "react";

export class BackButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
      type: props.type,
      name: props.name,
      page: props.page,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }

  render() {
    if (this.state.page === "appointment") {
      return (
        <Tooltip title="Back">
          <Button
            type={this.state.type ? this.state.type : ""}
            variant="contained"
            size="small"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
              marginBottom: "1em",
            }}
            onClick={this.onClick.bind(this)}
          >
            {this.state.name ? this.state.name : "Back"}
          </Button>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Back">
          <Button
            type={this.state.type ? this.state.type : ""}
            variant="contained"
            size="small"
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
            }}
            onClick={this.onClick.bind(this)}
          >
            {this.state.name ? this.state.name : "Back"}
          </Button>
        </Tooltip>
      );
    }
  }
}
export default BackButton;
