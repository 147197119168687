import {
  GET_CODE_UOM_List,
  SET_CODE_UOM_List,
  CLEAR_CODE_UOM_List,
} from "./CodeUomListType";

const initialState = {
  uomList: [],
  assetCodeList: [],
};
const CodeUomListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CODE_UOM_List:
      return {
        ...state,
      };
    case SET_CODE_UOM_List:
      return {
        ...state,
        uomList: action.payload.uomList,
        assetCodeList: action.payload.assetCodeList,
      };

    case CLEAR_CODE_UOM_List:
      return {
        ...state,
        uomList: null,
        assetCodeList: null,
      };
    default:
      return state;
  }
};

export default CodeUomListReducer;
