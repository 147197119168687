import PrintIcon from '@material-ui/icons/Print';
import React from 'react';
import { Col, Row } from 'reactstrap';
import Quill from "../quill/Quill";
import FetchServerData from '../../provider/FetchServerData';
import PrintIconButton from '../buttons/PrintIconButton';
export class TermsConditions extends React.Component {
	constructor() {
		super();
		this.state = { templateDetails: "", render: false };
		this.getTemplateDetails();
	}

	printContent() {
		let printContents = document.getElementById('printform').innerHTML;
		let originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();

		document.body.innerHTML = originalContents;
		this.setState({ render: true });
		document.location.reload(true);
	}

	getTemplateDetails() {
		let postObject = {};
		FetchServerData.callPostService("/apTermsConditions/geActive", postObject)
			.then((response) => {
				let output = response;
				if (output.status === "SUCCESS") {
					let templateDetails = output.data.termsConditions.data;
					this.setState({ templateDetails: templateDetails, render: true });
				}
			})
			.catch((error) => { });
	}

	render() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<div
					id="termsConditionSection"
					style={{ height: '90vh', overflowY: 'scroll', padding: '0px 50px 30px 50px', background: '#fff' }}
				>
					<div style={{ border: '2px solid lightgray', marginTop: ' 10px', padding: '1em' }}>
						<section id="printform" className="contact">
							<Row md={12} style={{ margin: '0.5em' }}>
								<Col style={{ textAlign: 'center' }}>
									<span
										style={{
											textAlign: 'center',
											color: '#000',
											fontSize: 'large',
											float: 'right',
										}}
									>
										<PrintIconButton
											variant="contained"
											size="small"
											style={{ float: 'right', background: '#1b7189', color: '#fff', marginRight: '1rem' }}
											startIcon={<PrintIcon />}
											onClick={(e) => this.printContent(e)}
										/>
									</span>
								</Col>
							</Row>
							<Row md={12} style={{ margin: '0.5em' }}>
								<Quill
									text={this.state.templateDetails}
									className="templateMessageBox"
									theme="bubble"
									readOnly={true}
								/>
							</Row>
						</section>
					</div>
				</div>
			);
		}
	}
}

export default TermsConditions;
