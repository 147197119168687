import React, { Component } from "react";
import MaterialTable from "material-table";
import UiUtils from "../../provider/UiUtils";
import PtsButton from "../buttons/PtsButton";
import { withRouter } from "../../withRouter";
import FetchServerData from "../../provider/FetchServerData";
import { Card, CardBody } from "reactstrap";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import store from "../../redux/store";
import ApplicationStateAction from "../../redux/applicationState/ApplicationStateAction";
import Utils from "../../provider/Utils";
import MenuAction from "../../redux/menu/MenuAction";

class CustomerList extends Component {
  header = store.getState().header.header;
  customerState = store.getState().applicationState.customerState;
  customerStateDetails = {};
  breadCrumb = [{ pageid: "", uiname: "Customer List" }];
  constructor(props) {
    super(props);
    if (this.customerState) {
      this.customerStateDetails = Object.getOwnPropertyNames(
        this.customerState
      );
    }
    let userMenuArray = Utils.getTopMenus();
    if (props.state) {
      for (let i = 0; i < userMenuArray.length; i++) {
        for (let j = 0; props.state.pageid && j < props.state.pageid?.length; j++) {
          if (userMenuArray[i].pageid === props.state.pageid) {
            store.dispatch(MenuAction.setActiveMenu(userMenuArray[i].id));
          }
        }
      }
    }

    if (this.customerStateDetails.length > 0) {
      this.state = {
        initialPage: this.customerState.initialPage,
        pageSize: this.customerState.pageSize,
        customerList: [],
        isPaging: false,
        render: false,
        getCreatePermission: false,
        customerId: this.customerState.customerId,
      };
    } else {
      this.state = {
        initialPage: 0,
        pageSize: 10,
        customerList: [],
        isPaging: false,
        render: false,
        getCreatePermission: false,
        customerId: "",
      };
    }
    this.getCustomerList();
  }
  getCustomerList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService("/costomerms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let customerList = output.data.customerList;
          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getCreatePermission = getPermission[1];
          let isPaging = false;
          if (customerList.length > this.state.pageSize) {
            isPaging = true;
          }
          this.setState({
            render: true,
            isPaging: isPaging,
            customerList: customerList,
            initialPage: this.state.initialPage,
            getCreatePermission: getCreatePermission,
          });
        } else {
        }
      }
    );
  }
  addCustomer() {
    this.props.navigate("/customer", {
      state: {
        action: "create",
      },
    });
  }

  toCustomerDetails(e, data) {
    let customerState = {
      customerId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ customerState })
    );
    this.props.navigate("/customer", {
      state: {
        action: "view",
        customerId: data.id,
      },
    });
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject="Customer"
            name="Customer List"
          />
          {this.state.customerList.length > 0 ? (
            <>
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  title=""
                  onRowClick={(event, rowData) => {
                    this.toCustomerDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  icons={UiUtils.getMaterialTableIcons()}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "Please add customer",
                    },
                  }}
                  columns={[
                    {
                      title: "#",
                      render: (rowData) => rowData.tableData.id + 1,
                      width: "5%",
                      align: "left",
                      cellStyle: {
                        width: "6%",
                      },
                    },
                    {
                      title: "Name",
                      field: "name",
                    },
                    {
                      title: "Type",
                      field: "type",
                    },
                    {
                      title: "GSTN",
                      field: "gstn",
                    },
                    {
                      title: "Status",
                      field: "status",
                    },
                  ]}
                  data={this.state.customerList}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    initialPage: this.state.initialPage,
                    pageSize: this.state.pageSize,
                    pageSizeOptions: Utils.materialTablePageSize(),
                    paging: this.state.isPaging,
                    headerStyle: {
                      backgroundColor: "#0B6F9D",
                      fontWeight: "bold",
                      color: "#ffff",
                      align: "left",
                    },
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.customerId ? '#FEF5E7' : '',
                      height: "3em",
                      textTransform: "capitalize",
                    }),
                  }}
                  onChangePage={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                  actions={[
                    {
                      icon: () =>
                        this.state.getCreatePermission && (
                          <>
                            <span className="newDoctor">
                              {" "}
                              <PtsButton btnName="Add Customer" type="submit" />
                            </span>
                          </>
                        ),
                      tooltip: "Add Customer",
                      isFreeAction: true,
                      onClick: (event) => this.addCustomer(),
                    },
                  ]}
                />
              </div>
            </>
          ) : (
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-12" style={{ padding: "2em" }}>
                        <h5>
                          <b>Customer not created yet</b>
                        </h5>
                        <br />
                        <PtsButton
                          btnName="Add New Customer"
                          onClick={this.addCustomer.bind(this)}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          )}
        </>
      );
    }
  }
}
export default withRouter(CustomerList);
