import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import React, { Component } from "react";
export class CheckIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        className="markComplete"
        onClick={this.onClick.bind(this)}
        style={{
          cursor: "pointer",
          fontSize: "x-larger",
          marginRight: "0.5em",
          float: "right",
        }}
      >
        <Tooltip title="Mark Complete">
          <CheckIcon
            style={{
              borderRadius: "25%",
              background: "#867e7e",
              color: "white",
              fontSize: "x-large",
              width: "30px",
              minHeight: "30px",
              padding: "2px",
            }}
          />
        </Tooltip>
      </span>
    );
  }
}
export default CheckIconButton;
