import { TextField } from "@material-ui/core";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "../../withRouter";
import { ValidatorForm } from "react-material-ui-form-validator";
import logo from "../../assets/images/AssetTrackifierLogo.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FetchServerData from "../../provider/FetchServerData";

export class ForgotPasswordEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }
  getTenantList() {
    const postObject = {
      header: {
        email: this.state.email,
      },
    };
    FetchServerData.callPostService("/tntms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let tenantList = output.data;
          this.props.navigate("/forgotPassword", {
            state: {
              render: true,
              tenantList: tenantList,
              email: this.state.email,
            },
          });
        } else {
        }
      }
    );
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  render() {
    return (
      <>
        <div className="authentication-wrapper authentication-cover">
          <div className="authentication-inner row m-0">
            <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center leftBackground ">
              <div className="mx-auto">
                <div className="logoBackground">
                  <img
                    src={logo}
                    className="img-fluid logoImage"
                    alt=""
                    data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                    data-app-light-img="illustrations/girl-with-laptop-light.png"
                  />
                </div>
                <div className="align-items-center">
                  <h2 className="logoHeding">AssetTrackifier</h2>
                </div>
              </div>
            </div>
            <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center authentication-bg p-sm-5 p-4">
              <div className="w-px-400 mx-auto">
                <ValidatorForm
                  className="text-start"
                  ref="form"
                  onSubmit={this.getTenantList.bind(this)}
                >
                  <div className="text-start">
                    <h4>Forgot Password?</h4>
                    <p>
                      We will send you an email that will allow you to reset
                      your password.
                    </p>
                  </div>
                  <div className="mb-3 mt-4">
                    <label
                      htmlFor="email"
                      className="form-label"
                      id="signupFormLabel"
                    >
                      EMAIL ID <span className="asterisk">*</span>
                    </label>
                    <TextField
                      id="email"
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      placeholder="Enter Email *"
                      type="email"
                      value={this.state.email}
                      onChange={this.handleEmailChange.bind(this)}
                      //   autoComplete="off"
                      validators={["required", "isEmail"]}
                      errormessages={[
                        "This field is required",
                        "Email id is not valid",
                      ]}
                    />
                  </div>
                  <button className="goButton"> Continue </button>
                  <div className="d-flex flex-row pt-3">
                    <NavLink className="pt-1" to="/signinEmail">
                      <ArrowBackIosNewIcon />
                      Back Sign In
                    </NavLink>
                    <p className="ms-5 ps-4 text-end">
                      <span>Do not have an account?</span>&nbsp;
                      <NavLink to="/signup">
                        <span>Sign Up</span>
                      </NavLink>
                    </p>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ForgotPasswordEmail);
