import { GET_USER_ROLE, SET_USER_ROLE } from "./UserRoleTypes";

const initialState = {
  userRoleList: {},
};

const UserRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_ROLE:
      return {
        ...state,
      };
    case SET_USER_ROLE:
      return {
        ...state,
        userRoleList: action.payload,
      };
    default:
      return state;
  }
};

export default UserRoleReducer;
