import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { withRouter } from "../../../withRouter";
import Quill from "../../quill/Quill";
import FetchServerData from '../../../provider/FetchServerData';
import "./TermsConditions.css";
class TermsConditions extends Component {
  constructor(props) {
    super(props);
    this.state = { templateDetails: "", render: false };
    this.getTemplateDetails();
    window.scrollTo(0, 0);
  }
  navigateToLink(linkToNavigate) {
    this.props.navigate(linkToNavigate);
  }

  getTemplateDetails() {
    let postObject = {};
    FetchServerData.callPostService("/apTermsConditions/geActive", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          let templateDetails = output.data.termsConditions.data;
          this.setState({ templateDetails: templateDetails, render: true });
        }
      })
      .catch((error) => { });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <header
            className="header-area header-sticky wow slideInDown"
            data-wow-duration="0.75s"
            data-wow-delay="0s"
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <nav className="main-nav">
                    {/* ***** Logo Start ***** */}
                    <a href="/" className="logo">
                      <h4>AssetTrackifier</h4>
                    </a>
                    {/* ***** Logo End ***** */}
                    {/* ***** Menu Start ***** */}
                    <ul className="nav">
                      <li className="scroll-to-section">
                        <a href="/">
                          Home
                        </a>
                      </li>
                      <li className="scroll-to-section">
                        <a
                          onClick={() => this.navigateToLink("/signinEmail")}
                          style={{ cursor: "pointer" }}
                        >
                          Sign In
                        </a>
                      </li>
                      <li className="scroll-to-section">
                        <a
                          onClick={() => this.navigateToLink("/signup")}
                          style={{ cursor: "pointer" }}
                        >
                          Sign Up
                        </a>
                      </li>
                      {/* <li className="scroll-to-section">
                      <a href="#contact">Contact Us</a>
                    </li> */}
                    </ul>
                    <a className="menu-trigger">
                      <span>Menu</span>
                    </a>
                    {/* ***** Menu End ***** */}
                  </nav>
                </div>
              </div>
            </div>
          </header>
          <div
            className="termsBanner"
            id="top"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <div
              className="container"
              style={{ border: "1px solid lightgray", padding: "2em" }}
            >
              <Row>
                <Col md={12}>
                  <Quill
                    text={this.state.templateDetails}
                    className="templateMessageBox"
                    theme="bubble"
                    readOnly={true}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <footer style={{ background: "#000" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-6" style={{ margin: "auto" }}>
                  <div
                    className="copyright"
                    style={{ fontSize: "12px", color: "#fff" }}
                  >
                    © Copyright
                    <strong>

                      <span> AssetTrackifier</span>
                    </strong>
                    . All Rights Reserved
                  </div>
                </div>
                <div className="col-lg-3"></div>
                <div className="col-lg-3">
                  <ul
                    style={{
                      float: "right",
                      listStyle: "none",
                      lineHeight: "3em",
                      margin: "0 100px 0px 0",
                    }}
                  >
                    <li style={{ textAlign: "left" }}>
                      <i
                        className="fa fa-angle-right"
                        style={{ color: "#fff" }}
                      />

                      <a
                        onClick={() => this.navigateToLink("/termsConditions")}
                        style={{ color: "#fff", cursor: "pointer" }}
                      >
                        {" "}
                        Terms &amp; Conditions
                      </a>
                    </li>
                    <li style={{ textAlign: "left" }}>
                      <i
                        className="fa fa-angle-right"
                        style={{ color: "#fff" }}
                      />

                      <a
                        onClick={() => this.navigateToLink("/privacyPolicy")}
                        style={{ color: "#fff", cursor: "pointer" }}
                      >
                        {" "}
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </>
      );
    }
  }
}

export default withRouter(TermsConditions);
