import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { Component } from "react";
import PtsConfirmDialogue from "../ptsAlert/PtsConfirmDialogue";

export class DeleteIconButton extends Component {
  confirmDialogueRef = React.createRef();
  constructor(props) {
    let domainObject = props.domainObject ? props.domainObject : "";
    super(props);
    this.state = {
      domainObject: domainObject,
      title: props.title ? props.title : "Delete Confirmation",
      text: props.text ? props.text : domainObject,
      onClick: props.onClick,
      caption: props.caption,
      pageId: this.props.pageId,
      hideToolTip: props.hideToolTip,
      btnName: props.btnName,
    };
  }

  deleteConfirmed() {
    if (this.confirmDialogueRef && this.confirmDialogueRef.current) {
      this.confirmDialogueRef.current.setState({ open: false });
    }
    if (this.state.onClick) {
      this.state.onClick();
    }
  }

  deleteNotConfirmed() { }
  onClick() {
    if (
      this.confirmDialogueRef &&
      this.confirmDialogueRef.current &&
      this.confirmDialogueRef.current.state.open === false
    ) {
      this.confirmDialogueRef.current.handleClickOpen();
    }
  }
  render() {
    this.confirmDialogueRef = React.createRef();
    return (
      <span
        onClick={this.onClick.bind(this)}
        style={{
          cursor: "pointer",
          fontSize: "x-larger",
          marginRight: "0.5em",
          paddingTop: "5px",
          float: "right",
          display: "flex",
        }}
      >
        <PtsConfirmDialogue
          ref={this.confirmDialogueRef}
          title={this.state.title}
          btnName={this.state.btnName}
          text={this.state.text}
          open={false}
          pageId={this.props.pageId}
          confirmCallback={this.deleteConfirmed.bind(this)}
          notConfirmCallback={this.deleteNotConfirmed.bind(this)}
        />
        {this.state.hideToolTip ?
          <DeleteIcon
            className="delete"
            style={{
              borderRadius: "25%",
              color: "#6A7B8E",
              fontSize: "x-large",
              width: "30px",
              minHeight: "30px",
              padding: "2px",
            }}
          />
          :
          <Tooltip
            title={
              this.state.pageId === "caregiver"
                ? "Delete Caregiver from Circle"
                : "Delete"
            }
          >
            <DeleteIcon
              className="delete"
              style={{
                borderRadius: "25%",
                color: "#6A7B8E",
                fontSize: "x-large",
                width: "30px",
                minHeight: "30px",
                padding: "2px",
              }}
            />
          </Tooltip>
        }
        <h6 style={{ margin: "auto 0px" }}> Delete</h6>
      </span>
    );
  }
}
export default DeleteIconButton;
