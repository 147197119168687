import React, { Component } from "react";
import MaterialTable from "material-table";
import UiUtils from "../../../provider/UiUtils";
import { withRouter } from "../../../withRouter";
import FetchServerData from "../../../provider/FetchServerData";
import { Card, CardBody } from "reactstrap";
import { MenuWithBreadScrum } from "../../menu/MenuWithBreadScrum";
import store from "../../../redux/store";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import Utils from "../../../provider/Utils";
import BackIconButton from "../../buttons/BackIconButton";

class ActiveAssetList extends Component {
  header = store.getState().header.header;
  constructor(props) {
    super(props);
    let pageId = "";
    let assetBreadCrumb = [
      { pageid: "/reports", uiname: "MIS Reports" },
      { pageid: "/assetAnalytics", uiname: "Asset Analytics" },
      { pageid: "", uiname: "Active Asset List" },
    ];
    let activeAssetBreadCrumb = [
      { pageid: "/reports", uiname: "MIS Reports" },
      { pageid: "/organizationAssets", uiname: "organization Asset Analytics" },
      { pageid: "", uiname: "Active Asset List" },
    ];
    if (props.state) {
      pageId = props.state.pageId;
    }
    if (pageId === "activeAsset") {
      this.breadCrumb = activeAssetBreadCrumb;
    } else {
      this.breadCrumb = assetBreadCrumb;
    }
    this.state = {
      pageId: pageId,
      initialPage: 0,
      pageSize: 10,
      activeAssetList: [],
      isPaging: false,
      render: false,
    };
    this.getActiveAssetList();
  }
  getActiveAssetList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService("/misms/activeAssetList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let activeAssetList = output.data.assetList;
          let isPaging = false;
          if (activeAssetList.length > this.state.pageSize) {
            isPaging = true;
          }
          this.setState({
            render: true,
            isPaging: isPaging,
            activeAssetList: activeAssetList,
            initialPage: this.state.initialPage,
          });
        } else {
        }
      }
    );
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  backButton() {
    if (this.state.pageId === "activeAsset") {
      this.props.navigate("/organizationAssets");
    } else {
      this.props.navigate("/assetAnalytics");
    }
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject="Active Asset"
            // name="Active Asset List"
          />
          {this.state.activeAssetList.length > 0 ? (
            <>
              <div className="row pe-2">
                <div className="col-md-6 text-start p-2">
                  <BackIconButton onClick={this.backButton.bind(this)} />
                </div>
                <div className="col-md-6 text-end p-2">
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        variant="contained"
                        size="small"
                        id="printBtn"
                        style={{
                          background: "#0577ab",
                          color: "#fff",
                          padding: "5px",
                          width: "100px",
                        }}
                        startIcon={<PrintRoundedIcon />}
                      >
                        print
                      </Button>
                    )}
                    content={() => this.componentRef}
                  />
                </div>
              </div>
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  title={`Active Assets - ${
                    this.state.activeAssetList !== null
                      ? this.state.activeAssetList.length
                      : ""
                  }`}
                  tableRef={(el) => (this.componentRef = el)}
                  icons={UiUtils.getMaterialTableIcons()}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "Please add customer",
                    },
                  }}
                  columns={[
                    {
                      title: "#",
                      render: (rowData) => rowData.tableData.id + 1,
                      width: "5%",
                      align: "left",
                      cellStyle: {
                        width: "6%",
                      },
                    },
                    {
                      title: "Asset Name",
                      field: "name",
                      cellStyle: {
                        width: "15%",
                      },
                    },
                    {
                      title: "Asset UID",
                      field: "uid",
                      cellStyle: {
                        width: "15%",
                      },
                    },
                    {
                      title: "Asset Code",
                      field: "assetcode",
                      cellStyle: {
                        width: "15%",
                      },
                    },
                    {
                      title: "Asset Type",
                      field: "assetType",
                      cellStyle: {
                        width: "15%",
                      },
                    },
                    {
                      title: "Description",
                      field: "description",
                      cellStyle: {
                        width: "30%",
                      },
                    },
                    // {
                    // 	title: "Status",
                    // 	field: "status",
                    // },
                  ]}
                  data={this.state.activeAssetList}
                  options={{
                    sorting: true,
                    search: false,
                    actionsColumnIndex: -1,
                    initialPage: this.state.initialPage,
                    pageSize: this.state.pageSize,
                    pageSizeOptions: Utils.materialTablePageSize(),
                    paging: this.state.isPaging,
                    headerStyle: {
                      backgroundColor: "#0B6F9D",
                      fontWeight: "bold",
                      color: "#ffff",
                      align: "left",
                    },
                  }}
                />
              </div>
            </>
          ) : (
            <div className="row">
              <div className="row pe-2">
                <div className="col-md-12 text-start p-2">
                  <BackIconButton onClick={this.backButton.bind(this)} />
                </div>
              </div>
              <div className="col-md-12">
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-12" style={{ padding: "2em" }}>
                        <h5>
                          <b>Active Assets Not Found</b>
                        </h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          )}
        </>
      );
    }
  }
}
export default withRouter(ActiveAssetList);
