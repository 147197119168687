import React, { Component, createRef } from "react";
import TextField from "@material-ui/core/TextField";
import FetchServerData from "../../../provider/FetchServerData";
import store from "../../../redux/store";
import PtsAlert from "../../ptsAlert/PtsAlert";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddressWithTimezone from "../../addressWithTimezone/AddressWithTimezone";
import { withRouter } from "../../../withRouter";
import { InputAdornment } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import "./Organization.css";
import Utils from "../../../provider/Utils";
import EditIconButton from "../../buttons/EditIconButton";
import SaveButton from "../../buttons/SaveButton";
import CancelIconButton from "../../buttons/CancelIconButton";
import logoUpload from "../../../assets/images/logoUpload.png"
import Dropzone from 'react-dropzone';
import OrganizationAction from "../../../redux/organization/OrganizationAction";
class Organization extends Component {
  addressRef = React.createRef();
  header = store.getState().header.header;
  dropzoneRef = createRef();
  constructor(props) {
    super(props);
    let action = props.action;
    this.state = {
      action: action,
      companyName: "",
      gstn: "",
      panNo: "",
      phone: "",
      fax: "",
      website: "",
      description: "",
      organizationId: "",
      tenantId: "",
      logo: "",
      address: {},
      selectedFile: "",
      render: false,
      getUpdatePermission: false,
    };
    this.getOrganizationDetails();
  }

  handleAddressChange(address) {
    this.setState({ address: address });
  }

  editOrganizationDetails(e) {
    this.setState({ action: "edit", title: "Organization Details" });
  }

  getOrganizationDetails() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/companyms/get", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let organizationData = output.data.company;
          let getPermission = Utils.getRolePermission(output.data.privilegeList);
          let getUpdatePermission = getPermission[3];
          this.setState({
            render: true,
            action: "view",
            companyName: organizationData.name,
            gstn: organizationData.gstn,
            panNo: organizationData.panno,
            phone: organizationData.phone,
            fax: organizationData.faxnumber,
            website: organizationData.website,
            description: organizationData.description,
            organizationId: organizationData.id,
            tenantId: organizationData.tenant,
            logo: organizationData.logo,
            address: organizationData.address,
            getUpdatePermission: getUpdatePermission,
          });
        } else {
        }
      })
      .catch((error) => { });
  }

  handleCompanyNameChange(e) {
    this.setState({ companyName: e.target.value });
  }
  handleGstnChange(e) {
    if (e.target.value.length === 15) {
      this.setState({ panNo: e.target.value?.toUpperCase().substring(2, 12) })
    }
    this.setState({ gstn: e.target.value?.toUpperCase() })
  }
  handlePANChange(e) {
    let res = e.target.value;
    if (res.length <= 10) {
      this.setState({ panNo: res });
    } else {
      Utils.maxFieldLength(10);
    }
  }
  handlePhoneChange(e) {
    let res = e.target.value;
    if (res.length <= 10) {
      this.setState({ phone: res });
    }
  }
  handleWebsiteChange(e) {
    this.setState({ website: e.target.value });
  }
  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }
  handleFaxChange(e) {
    this.setState({ fax: e.target.value });
  }
  onDrop(files) {
    if (files.length > 0) {
      this.setState({ selectedFile: files[0] });
      this.logoFileHandler(this.state.selectedFile);
    }
  }

  openDialog() {
    if (this.dropzoneRef.current) {
      this.dropzoneRef.current.open();
    }
  };

  updateOrganizationToBackend() {
    if (this.state.gstn?.length > 0) {
      if (!Utils.checkGstnLength(this.state.gstn)) {
        return
      }
    }

    const postObject = {
      header: this.header,
      company: {
        name: this.state.companyName,
        gstn: this.state.gstn,
        panno: this.state.panNo,
        phone: this.state.phone,
        website: this.state.website,
        description: this.state.description,
        faxnumber: this.state.fax,
        id: this.state.organizationId,
        tenant: this.state.tenantId,
        address: {
          id: this.addressRef.current.state.id,
          line: this.addressRef.current.state.line,
          state: {
            name: this.addressRef.current.state.state.name,
            code: this.addressRef.current.state.state.code,
            id: this.addressRef.current.state.state.id,
            country: this.addressRef.current.state.country,
          },
          city: this.addressRef.current.state.city,
          zipcode: this.addressRef.current.state.zipCode,
          latitude: this.addressRef.current.state.latitude,
          longitude: this.addressRef.current.state.longitude,
        },
      },
    };
    FetchServerData.callPostService("/companyms/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let organizationData = output.data.company;
          this.setState({
            render: true,
            action: "view",
            title: "Organization Details",
            organizationId: organizationData.id,
            companyName: organizationData.name,
            gstn: organizationData.gstn,
            panNo: organizationData.panno,
            phone: organizationData.phone,
            fax: organizationData.faxnumber,
            website: organizationData.website,
            description: organizationData.description,
            tenantId: organizationData.tenant,
            address: organizationData.address,
          });
        } else {
        }
      });
  }

  editCancel() {
    this.getOrganizationDetails()
    // this.setState({ action: "view", title: "Organization Details" });
  }

  logoFileHandler(file) {
    let reader = new FileReader();
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    formData.append("sessionid", this.header.sessionid);
    // formData.append('imageposition', position);
    formData.append("companyid", this.state.organizationId);
    formData.append("uid", this.header.appuserId);
    formData.append("tid", this.header.tid);
    formData.append("appclient", "W");
    // this.setState({logo:this.selectedFile});
    reader.readAsDataURL(file);
  }

  uploadLogoFile() {
    const formData = new FormData();
    // Update the formData object
    formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
    formData.append('sessionid', this.header.sessionid);
    formData.append('uid', this.header.appuserId);
    formData.append('email', this.header.email);
    formData.append('companyid', this.state.organizationId);
    formData.append('tid', this.header.tid);
    formData.append('appclient', 'W');
    FetchServerData.callPostService('/companyms/uploadLogo', formData).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let uploadLogoData = output.data;
        let organizationObj = {
          organizationName: uploadLogoData.name,
          organizationLogo: uploadLogoData.logo,
          organizationAddress: uploadLogoData.address,
        }
        store.dispatch(OrganizationAction.setOrganization(organizationObj));
        this.setState({
          selectedFile: "",
          logo: uploadLogoData.logo
        });
      } else {

      }
    }
    );
  }
  resetFile() {
    document.getElementsByName("file")[0].value = null;
  }

  getGstn() {
    if (!this.state.gstn) {
      PtsAlert.error("Enter GSTN");
      return;
    }
    const postObject = {
      gstin: this.state.gstn,
      header: this.header,
    };
    FetchServerData.callPostService(
      "/staticms/findByGstn",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let gstnDetails = output.data.taxpayer;
        PtsAlert.success(output.message);
        this.setState({
          companyName: gstnDetails.legalname,
          state: gstnDetails.address?.state,
          address: gstnDetails.address,
        });
      } else {
      }
    });
  }

  render() {
    if (this.state.action === "view") {
      return this.viewOrganization();
    } else if (this.state.action === "edit") {
      return this.editOrganization();
    }
  }
  viewOrganization() {
    if (this.state.render === false) {
      return <div />;
    }
    return (
      <>
        <div className="row" style={{ padding: "1em", marginTop: "-15px" }}>
          <div className="card" id="organizationFormCard">
            <div className="row">
              <div className="row" style={{ height: "48px", alignItems: "center" }}>
                <div className="col-auto me-auto"> <h5 className="ps-2 mb-0" id="headerLabel">{this.state.companyName}</h5></div>
                <div className="col-auto me-3">
                  {this.state.getUpdatePermission && <EditIconButton onClick={(e) => this.editOrganizationDetails(e)} />}
                </div>
              </div>
              <hr></hr>
              <div
                className="card-header d-flex align-items-center justify-content-between"
                id="assetCardHeader"
              >
                <h5 className="mb-0" id="headerLabel">
                  Company Details
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row pt-2">
                          <label
                            className="col-sm-4 col-form-label fw-bold"
                            htmlFor="basic-default-name"
                            id="assetFormLabel"
                          >
                            GsTN
                          </label>
                          <div className="col-sm-8" id="OrganizationCol">
                            <span>{this.state.gstn}</span>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <label
                            className="col-sm-4 col-form-label fw-bold"
                            htmlFor="basic-default-name"
                            id="assetFormLabel"
                          >
                            PAN NO
                          </label>
                          <div className="col-sm-8" id="OrganizationCol">
                            <span>{this.state.panNo}</span>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <label
                            className="col-sm-4 col-form-label fw-bold"
                            htmlFor="basic-default-name"
                            id="assetFormLabel"
                          >
                            FAX
                          </label>
                          <div className="col-sm-8" id="OrganizationCol">
                            <span>{this.state.fax}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="row pt-2">
                              <label
                                className="col-sm-4 col-form-label fw-bold"
                                htmlFor="basic-default-name"
                                id="assetFormLabel"
                              >
                                Website
                              </label>
                              {this.state.website ? (
                                <div className="col-sm-8" id="OrganizationCol">
                                  <a
                                    href={
                                      this.state.website.includes('https://') || this.state.website.includes('http://')
                                        ? this.state.website
                                        : 'http://' + this.state.website
                                    }
                                    target="blank"
                                  >
                                    {this.state.website}
                                  </a>
                                </div>
                              ) : (
                                <span style={{ display: 'none' }}></span>
                              )}
                            </div>
                            <div className="row pt-2">
                              <label
                                className="col-sm-4 col-form-label fw-bold"
                                htmlFor="basic-default-name"
                                id="assetFormLabel"
                              >
                                Phone
                              </label>
                              <div className="col-sm-8" id="OrganizationCol">
                                <span>{this.state.phone}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-2">
                      <label
                        className="col-sm-12 col-form-label fw-bold"
                        htmlFor="basic-default-name"
                        id="assetFormLabel"
                      >
                        Description
                      </label>
                      <br></br>
                      <div className="col-sm-12 mt-1 justify-content-start" id="OrganizationCol">
                        <span>{this.state.description}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-sm-12">
                        {this.state.logo ? (
                          <img
                            src={`data:image/png;base64,${this.state.logo}`}
                            alt=""
                            width="100%"
                            style={{
                              maxHeight: "200px",
                              width: "170px",
                            }}
                          />
                        ) : (
                          <span style={{ display: 'none' }}></span>
                        )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="row">
              <div
                className="card-header d-flex align-items-center justify-content-between"
                id="assetCardHeader"
              >
                <h5 className="mb-0" id="headerLabel">
                  Address
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-sm-12" id="OrganizationCol">
                        <span>{Utils.getAddressAsText(this.state.address)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                    style={{ marginLeft: "-12px" }}
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Geolocation
                    </h5>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="row">
                          <label
                            className="col-sm-4 col-form-label fw-bold"
                            htmlFor="basic-default-name"
                            id="assetFormLabel"
                          >
                            Latitude
                          </label>
                          <div className="col-sm-8" id="OrganizationCol">
                            <span>{this.state.address?.latitude}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row ">
                          <label
                            className="col-sm-4 col-form-label fw-bold"
                            htmlFor="basic-default-name"
                            id="assetFormLabel"
                          >
                            Longitude
                          </label>
                          <div className="col-sm-8" id="OrganizationCol">
                            <span>{this.state.address?.longitude}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="row">
              <div
                className="card-header d-flex align-items-center justify-content-between"
                id="assetCardHeader"
              >
                <h5 className="mb-0" id="headerLabel"> Logo</h5>
              </div>
              <div className="card-body ">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-center">
                      <div className="col-sm-3 text-center ">
                        <Dropzone ref={this.dropzoneRef} onDrop={this.onDrop.bind(this)} multiple={false} noClick noKeyboard>
                          {({ getRootProps, getInputProps }) => {
                            return (
                              <div className="container">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                  <input {...getInputProps()} />
                                  <img
                                    src={logoUpload}
                                    alt=""
                                    style={{ width: "60px", height: "60px", }}
                                  />
                                  <p>Drag and Drop  <br /> OR</p>
                                  <button
                                    type="button"
                                    className="selectFile"
                                    onClick={this.openDialog.bind(this)}
                                  >
                                    Select File
                                  </button>
                                </div>
                              </div>
                            );
                          }}
                        </Dropzone>
                        {this.state.selectedFile ?
                          <div className="row d-flex justify-content-center">
                            <button
                              type="button"
                              className="logoUploadeBtn"
                              onClick={this.uploadLogoFile.bind(this)}
                            >
                              Upload Logo
                            </button>
                          </div> : ""
                        }
                      </div>
                      <div className="col-sm-3 text-center">
                        {this.state.selectedFile ? (
                          <img
                            src={URL.createObjectURL(this.state.selectedFile)}
                            alt=""
                            width="100%"
                            style={{
                              maxHeight: "200px",
                              width: "170px",
                            }}
                          />
                        ) : (
                          <span style={{ display: 'none' }}></span>
                        )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  editOrganization() {
    return (
      <>
        <div className="row">
          <ValidatorForm
            className="text-start"
            ref="form"
            onSubmit={this.updateOrganizationToBackend.bind(this)}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="card " id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Company Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-companyName">
                          Company Name
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-companyName"
                          variant="outlined"
                          placeholder="Company Name"
                          value={this.state.companyName}
                          onChange={this.handleCompanyNameChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          GSTN
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-gstn"
                          variant="outlined"
                          placeholder="GSTN"
                          value={this.state.gstn}
                          onChange={this.handleGstnChange.bind(this)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <RefreshIcon
                                  className="verifyGstnBtn"
                                  onClick={(e) => this.getGstn()}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          PAN NO
                          <span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-panno"
                          variant="outlined"
                          placeholder="PAN NO"
                          value={this.state.panNo}
                          onChange={this.handlePANChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          Website
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Website"
                          value={this.state.website}
                          onChange={this.handleWebsiteChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          Phone
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Width"
                          value={this.state.phone}
                          onChange={this.handlePhoneChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          Fax
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-fax"
                          variant="outlined"
                          placeholder="FAX"
                          value={this.state.fax}
                          onChange={this.handleFaxChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-description">
                          Description
                        </label>
                        <textarea
                          name="collapsible-address"
                          className="form-control"
                          style={{ color: 'black', font: "inherit", fontSize: "16px " }}
                          id="collapsible-address"
                          rows="4"
                          placeholder="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Address
                    </h5>
                  </div>
                  <div className="card-body">
                    <AddressWithTimezone
                      ref={this.addressRef}
                      onChange={this.handleAddressChange.bind(this)}
                      data={this.state.address}
                      action="edit"
                      pageId="Geolocation"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-sm-12 mt-4 text-center">
                <SaveButton id="saveButton" type="submit">
                  {" "}
                  Save{" "}
                </SaveButton>
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.editCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </>
    );
  }
}

export default withRouter(Organization);
