import React, { Component } from "react";
import store from "../../redux/store";
import { Col, Modal, ModalFooter, Row } from "reactstrap";
import { withRouter } from "../../withRouter";
import { ValidatorForm } from "react-material-ui-form-validator";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import { Autocomplete } from "@mui/material";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import SaveButton from "../buttons/SaveButton";
import CancelIconButton from "../buttons/CancelIconButton";
import FetchServerData from "../../provider/FetchServerData";
import UiUtils from "../../provider/UiUtils";
import MaterialTable from "material-table";
import PtsButton from "../buttons/PtsButton";
import BackButton from "../buttons/BackButton";
import DeleteIconButton from "../buttons/DeleteIconButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import Utils from "../../provider/Utils";
import ApplicationStateAction from "../../redux/applicationState/ApplicationStateAction";
import "./ReturnablePlan.css";
import CreateButton from "../buttons/CreateButton";
class ReturnablePlan extends Component {
  header = store.getState().header.header;
  customerList = store.getState().customer.customerList;
  returnableAssetState = store.getState().applicationState.returnableAssetState;
  returnableAssetCycleState =
    store.getState().applicationState.returnableAssetCycleState;
  returnableAssetStateDetails = {};
  returnableAssetCycleStateDetails = {};

  constructor(props) {
    super(props);
    let breadCrumb = [
      { pageid: "/returnablePlanList", uiname: "Returnable Plan List" },
      { pageid: "", uiname: "Returnable Plan Details" },
    ];
    let assetCycleBreadCrumb = [
      { pageid: "/assetCycleList", uiname: "Asset Cycle List" },
      { pageid: "/assetCycle", uiname: "Asset Cycle Details" },
      { pageid: "", uiname: "Returnable Plan Details" },
    ];
    let action = "";
    let returnableCyclePlanId = "";
    let assetId = "";
    let assetCycleId = "";
    let pageId = "";
    if (!action && props.state) {
      action = props.state.action;
      returnableCyclePlanId = props.state.returnableCyclePlanId;
      assetId = props.state.assetId;
      assetCycleId = props.state.assetCycleId;
      pageId = props.state.pageId;
    }
    if (pageId === "assetCycleDetails") {
      this.breadCrumb = assetCycleBreadCrumb;
    } else {
      this.breadCrumb = breadCrumb;
    }
    if (
      action &&
      Utils.equalsIgnoreCase(
        action,
        "view" || Utils.equalsIgnoreCase(action, "update")
      )
    ) {
      this.getReturnablePlanDetails(returnableCyclePlanId);
    }

    if (this.returnableAssetState) {
      this.returnableAssetStateDetails = Object.getOwnPropertyNames(
        this.returnableAssetState
      );
    }
    if (this.returnableAssetCycleState) {
      this.returnableAssetCycleStateDetails = Object.getOwnPropertyNames(
        this.returnableAssetCycleState
      );
    }

    if (this.returnableAssetStateDetails.length > 0) {
      this.state = {
        action: action,
        returnableCyclePlanId: this.returnableAssetState.returnablePlanId,
        assetId: this.returnableAssetState.assetId,
        name: "",
        description: "",
        planDate: new Date(),
        customer: null,
        customerId: "",
        initialPage: this.returnableAssetState.initialPage,
        pageSize: this.returnableAssetState.pageSize,
        isAssetListPaging: false,
        isAssetCycleListPaging: false,
        render: false,
        openListModal: false,
        getUpdatePermission: false,
        getDeletePermission: false,
        assetIds: [],
        planAssets: [],
        assetCyclesList: [],
        AssetList: [],
      };
    } else if (this.returnableAssetCycleStateDetails.length > 0) {
      this.state = {
        action: action,
        pageId: pageId,
        returnableCyclePlanId: this.returnableAssetCycleState.returnablePlanId,
        assetCycleId: this.returnableAssetCycleState.assetCycleId,
        name: "",
        description: "",
        planDate: new Date(),
        customer: null,
        customerId: "",
        initialAssetCyclePage: this.returnableAssetCycleState.initialPage,
        pageSize: this.returnableAssetCycleState.pageSize,
        isAssetListPaging: false,
        isAssetCycleListPaging: false,
        render: false,
        openListModal: false,
        getUpdatePermission: false,
        getDeletePermission: false,
        assetIds: [],
        planAssets: [],
        assetCyclesList: [],
        AssetList: [],
      };
    } else {
      this.state = {
        action: action,
        pageId: pageId,
        returnableCyclePlanId: returnableCyclePlanId,
        assetId: assetId,
        assetCycleId: assetCycleId,
        name: "",
        description: "",
        planDate: new Date(),
        customer: null,
        customerId: "",
        initialPage: 0,
        pageSize: 10,
        isAssetListPaging: false,
        isAssetCycleListPaging: false,
        render: false,
        openListModal: false,
        getUpdatePermission: false,
        getDeletePermission: false,
        assetIds: [],
        planAssets: [],
        assetCyclesList: [],
      };
    }
  }
  getReturnablePlanDetails(returnableCyclePlanId) {
    const postObject = {
      header: this.header,
      returnableCyclePlan: {
        id: returnableCyclePlanId,
      },
    };

    FetchServerData.callPostService("/returnablePlanms/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let returnableCyclePlanData = output.data.returnableCyclePlan;
          let assetCycleList = returnableCyclePlanData.assetCycles;
          for (let i = 0; i < assetCycleList.length; i++) {
            assetCycleList[i].startDate = Utils.getUtcToLocal(
              assetCycleList[i].startDate
            );
            assetCycleList[i].endDate =
              assetCycleList[i].endDate !== null
                ? Utils.getUtcToLocal(assetCycleList[i].endDate)
                : "";
          }
          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];

          let isAssetCycleListPaging = false;
          if (assetCycleList.length > this.state.pageSize) {
            isAssetCycleListPaging = true;
          }

          let isAssetListPaging = false;
          if (returnableCyclePlanData.planAssets.length > this.state.pageSize) {
            isAssetListPaging = true;
          }

          this.setState({
            render: true,
            action: "view",
            isAssetCycleListPaging: isAssetCycleListPaging,
            isAssetListPaging: isAssetListPaging,
            id: returnableCyclePlanData.id,
            name: returnableCyclePlanData.name,
            planDate: new Date(returnableCyclePlanData.planDate),
            description: returnableCyclePlanData.description,
            customer: returnableCyclePlanData.customer,
            customerId: returnableCyclePlanData.customer.id,
            planStatus: returnableCyclePlanData.planStatus,
            planAssets: returnableCyclePlanData.planAssets,
            assetCyclesList: assetCycleList,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
            canMarkStarted: returnableCyclePlanData.canMarkStarted,
            canMarkCompleted: returnableCyclePlanData.canMarkCompleted,
          });
        } else {
        }
      }
    );
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handlePlanDateChange(data) {
    this.setState({ planDate: data });
  }

  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handleCustomerTypeChange(selectedCustomer) {
    this.setState({
      customer: selectedCustomer,
      customerId: selectedCustomer.id,
    });
  }

  createReturnablePlanToBackend() {
    const postObject = {
      header: this.header,
      returnableCyclePlan: {
        name: this.state.name,
        planDate: this.state.planDate,
        description: this.state.description,
        customer: {
          id: this.state.customerId,
        },
      },
    };
    FetchServerData.callPostService(
      "/returnablePlanms/create",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let returnablePlanData = output.data.returnableCyclePlan;
        this.getReturnablePlanDetails(returnablePlanData.id);
        this.setState({
          action: "view",
        });
      } else {
      }
    });
  }

  createCancel() {
    this.props.navigate("/returnablePlanList");
  }

  viewCancel() {
    if (this.state.pageId === "assetPlanDetails") {
      this.props.navigate("/asset", {
        state: {
          assetId: this.state.assetId,
          action: "view",
        },
      });
    } else if (this.state.pageId === "assetCycleDetails") {
      this.props.navigate("/assetCycle", {
        state: {
          assetCycleId: this.state.assetCycleId,
          action: "view",
        },
      });
    } else {
      this.props.navigate("/returnablePlanList");
    }
  }
  editCancel() {
    this.getReturnablePlanDetails(this.state.id);
    // this.setState({
    //   action: "view",
    // })
  }

  editReturnablePlanDetails(e) {
    this.setState({ action: "edit", returnableCyclePlanId: this.state.id });
  }

  updateReturnablePlanToBackend(e) {
    const postObject = {
      header: this.header,
      returnableCyclePlan: {
        id: this.state.id,
        name: this.state.name,
        planDate: this.state.planDate,
        description: this.state.description,
        customer: {
          id: this.state.customerId,
        },
      },
    };
    FetchServerData.callPostService(
      "/returnablePlanms/update",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let returnablePlanData = output.data.returnableCyclePlan;
        this.getReturnablePlanDetails(returnablePlanData.id);
        this.setState({
          action: "view",
        });
      } else {
      }
    });
  }

  deleteReturnablePlanToBackend() {
    const postObject = {
      header: this.header,
      returnableCyclePlan: {
        id: this.state.returnableCyclePlanId,
      },
    };
    FetchServerData.callPostService(
      "/returnablePlanms/delete",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.props.navigate("/returnablePlanList");
      } else {
      }
    });
  }

  getAssetList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService(
      "/assetms/getActiveAssets",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let assetList = output.data.assetList;
        this.setState({
          assetList: assetList,
        });
      } else {
      }
    });
  }

  onAssetCheckBoxSelect(data) {
    let assetIds = [];
    for (let i = 0; i < data.length; i++) {
      const assetId = data[i].id;
      assetIds = [...assetIds, assetId];
    }
    this.setState({
      assetIds: assetIds,
      selectedAssetList: data,
    });
  }

  async addAssets() {
    await this.getAssetList();
    this.setState({
      openListModal: true,
    });
  }

  addReturnableAssetToList() {
    const postObject = {
      header: this.header,
      returnableCyclePlan: {
        id: this.state.id,
      },
      assetIds: this.state.assetIds,
    };
    FetchServerData.callPostService(
      "/returnablePlanms/addAssets",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let returnableCycleAssetData = output.data.returnableCyclePlan;
        PtsAlert.success(output.message);
        let isAssetListPaging = false;
        if (returnableCycleAssetData.planAssets.length > this.state.pageSize) {
          isAssetListPaging = true;
        }
        this.setState({
          isAssetListPaging: isAssetListPaging,
          action: "view",
          planAssets: returnableCycleAssetData.planAssets,
          openListModal: !this.state.openListModal,
          returnableCyclePlanId: returnableCycleAssetData.id,
        });
      } else {
      }
    });
  }

  toggle = () => {
    this.setState({
      openListModal: !this.state.openListModal,
    });
  };

  deleteReturnablePlanAsset(e, data) {
    let assetId = data.id;
    const postObject = {
      header: this.header,
      returnableCyclePlan: {
        id: this.state.returnableCyclePlanId,
      },
      assetIds: [assetId],
    };
    FetchServerData.callPostService(
      "/returnablePlanms/deleteAssets",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let returnablePlanAssetData = output.data.returnableCyclePlan;
        this.setState({
          action: "view",
          planAssets: returnablePlanAssetData.planAssets,
        });
      } else {
      }
    });
  }

  getStartPlan() {
    const postObject = {
      header: this.header,
      returnableCyclePlan: {
        id: this.state.returnableCyclePlanId,
      },
    };
    FetchServerData.callPostService(
      "/returnablePlanms/startPlan",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        store.dispatch(ApplicationStateAction.setApplicationState({}));
        let planAssets = output.data.returnableCyclePlan.planAssets;
        let assetIds = [];
        for (let i = 0; i < planAssets.length; i++) {
          assetIds.push(planAssets[i].id);
        }
        this.props.navigate("/assetCycleList", {
          state: {
            pageid: "/assetCycleList",
            assetCycleIds: assetIds,
          },
        });
        this.setState({
          planAssets: planAssets,
        });
      } else {
      }
    });
  }

  getCompletedPlan() {
    const postObject = {
      header: this.header,
      returnableCyclePlan: {
        id: this.state.returnableCyclePlanId,
      },
    };
    FetchServerData.callPostService(
      "/returnablePlanms/markComplete",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let returnableCyclePlan = output.data.returnableCyclePlan;
        let returnablePlanState = {
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
          selectedTab: 2,
          returnablePlanId: returnableCyclePlan.id,
        };
        store.dispatch(
          ApplicationStateAction.setApplicationState({ returnablePlanState })
        );
        this.props.navigate("/returnablePlanList");
      } else {
      }
    });
  }

  toAssetCycleDetails(e, data) {
    let returnableAssetCycleState = {
      assetCycleId: data.id,
      returnablePlanId: this.state.id,
      initialPage: this.state.initialAssetCyclePage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ returnableAssetCycleState })
    );
    this.props.navigate("/assetCycle", {
      state: {
        action: "view",
        assetCycleId: data.id,
        returnablePlanId: this.state.id,
        pageId: "returnablePlanDetails",
      },
    });
  }
  toPlanAssetListDetails(e, data) {
    let returnableAssetState = {
      assetId: data.id,
      returnablePlanId: this.state.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ returnableAssetState })
    );
    this.props.navigate("/asset", {
      state: {
        action: "view",
        assetId: data.id,
        returnablePlanId: this.state.id,
        pageId: "returnablePlanDetails",
      },
    });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  handleAssetCycleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialAssetCyclePage: page, pageSize: pageSize });
  }

  render() {
    if (this.state.action === "create") {
      return this.createReturnablePlan();
    } else if (this.state.action === "view") {
      return this.viewReturnablePlan();
    } else if (this.state.action === "edit") {
      return this.editReturnablePlan();
    }
  }

  createReturnablePlan() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="Returnable Plan"
          name="Create Returnable Plan"
        />
        <div className="row">
          <ValidatorForm
            className="text-start"
            ref="form"
            onSubmit={this.createReturnablePlanToBackend.bind(this)}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="card " id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Returnable Plan Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Plan Date
                        </label>
                        <DatePicker
                          selected={this.state.planDate}
                          onChange={this.handlePlanDateChange.bind(this)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Start Date*"
                          required
                          className="planDateTimeInput"
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-supplierType"
                        >
                          Customer <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="customerTypeList"
                          options={this.customerList}
                          value={this.state.customer}
                          defaultValue={this.state.customer}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCustomerTypeChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Customer"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-description"
                        >
                          Description
                        </label>
                        <textarea
                          name="collapsible-address"
                          className="form-control"
                          style={{
                            color: "black",
                            font: "inherit",
                            fontSize: "16px ",
                          }}
                          id="collapsible-address"
                          rows="4"
                          placeholder="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between custBut">
              <div className="col-sm-12 mt-3 text-center">
                <CreateButton btnName="Create" type="submit" />
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.createCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </>
    );
  }

  viewReturnablePlan() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject={this.state.name}
            name="Returnable Plan Details"
            title="Returnable Plan"
            pageId="AssetView"
            editCallback={this.editReturnablePlanDetails.bind(this)}
            deleteCallback={this.deleteReturnablePlanToBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            assetCycleId={this.state.assetCycleId}
            returnableCyclePlanId={this.state.returnableCyclePlanId}
          />
          <div className="row" style={{ padding: "1em", marginTop: "-12px" }}>
            <div className="card" id="organizationFormCard">
              <div className="row">
                <div
                  className="card-header d-flex align-items-center justify-content-between"
                  id="assetCardHeader"
                >
                  <h5 className="mb-0" id="headerLabel">
                    {this.state.name}
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Name
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.name}</span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Status
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>
                            {this.state.planStatus != null
                              ? this.state.planStatus.status
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row pt-2">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Plan Date
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>
                                {Utils.getUtcToLocalDateTime(
                                  this.state.planDate
                                )}
                              </span>
                            </div>
                          </div>
                          {/* <div className="row pt-2">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Discription
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>{this.state.description}</span>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row pt-2">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Customer
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>
                                {this.state.customer != null
                                  ? this.state.customer.name
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row pt-2">
                        <label
                          className="col-sm-12 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Description
                        </label>
                        <br></br>
                        <div className="col-sm-12" id="OrganizationCol">
                          <span>{this.state.description}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mt-4">
                      <div className="row">
                        <div className="col-sm-12 text-start">
                          {this.state.canMarkStarted &&
                          this.state.planAssets.length > 0 ? (
                            <button
                              className="startPlanBtton"
                              type="submit"
                              onClick={(e) => this.getStartPlan()}
                            >
                              Start Plan
                            </button>
                          ) : (
                            ""
                          )}
                          {this.state.canMarkCompleted && (
                            <button
                              className="startPlanBtton"
                              type="submit"
                              onClick={(e) => this.getCompletedPlan()}
                            >
                              Mark Completed
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.planStatus.status === "Planned" ? (
            <div style={{ padding: "3px" }}>
              <MaterialTable
                title="Plan Asset List"
                icons={UiUtils.getMaterialTableIcons()}
                onRowClick={(event, rowData) => {
                  this.toPlanAssetListDetails(event, rowData);
                  event.stopPropagation();
                }}
                columns={[
                  {
                    title: "#",
                    render: (rowData) => rowData.tableData.id + 1,
                    width: "5%",
                    align: "left",
                    cellStyle: {
                      width: "6%",
                    },
                  },
                  {
                    title: "Name",
                    field: "name",
                    cellStyle: {
                      width: "20%",
                    },
                  },
                  {
                    title: "UID",
                    field: "uid",
                    cellStyle: {
                      width: "20%",
                    },
                  },
                  {
                    title: "AssetCode",
                    field: "assetcode",
                    cellStyle: {
                      width: "20%",
                    },
                  },
                  {
                    title: "Status",
                    field: "status",
                    cellStyle: {
                      width: "20%",
                    },
                  },
                  {
                    title: "Action",
                    disableClick: true,
                    sorting: false,
                    render: (rowData) => (
                      <>
                        <span style={{ display: "flex", marginLeft: "-7px" }}>
                          <DeleteIconButton
                            className="actionBtn"
                            domainObject="Returnable Asset"
                            onClick={(e) =>
                              this.deleteReturnablePlanAsset(e, rowData)
                            }
                          />
                        </span>
                      </>
                    ),
                  },
                ]}
                data={this.state.planAssets}
                options={{
                  actionsColumnIndex: -1,
                  selectedTab: 1,
                  initialPage: this.state.initialPage,
                  pageSize: this.state.pageSize,
                  pageSizeOptions: Utils.materialTablePageSize(),
                  paging: this.state.isAssetListPaging,
                  headerStyle: {
                    backgroundColor: "#0B6F9D",
                    fontWeight: "bold",
                    color: "#ffff",
                    align: "left",
                  },
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      rowData.id === this.state.assetId ? "#FEF5E7" : "#fff",
                    height: "3em",
                    textTransform: "capitalize",
                  }),
                }}
                onChangePage={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
                actions={[
                  {
                    icon: () => <PtsButton btnName="Add Asset" />,
                    tooltip: "Add Asset",
                    isFreeAction: true,
                    onClick: (event) => this.addAssets(),
                  },
                ]}
              />
            </div>
          ) : (
            <>
              {this.state.assetCyclesList.length > 0 && (
                <div style={{ padding: "3px" }}>
                  <MaterialTable
                    title="Asset Cycle List"
                    icons={UiUtils.getMaterialTableIcons()}
                    onRowClick={(event, rowData) => {
                      this.toAssetCycleDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    columns={[
                      {
                        title: "#",
                        render: (rowData) => rowData.tableData.id + 1,
                        width: "5%",
                        align: "left",
                        cellStyle: {
                          width: "6%",
                        },
                      },
                      {
                        title: "Customer",
                        field: "customer",
                        cellStyle: {
                          width: "12%",
                        },
                      },
                      {
                        title: "Plan Name",
                        field: "cycleplanname",
                        cellStyle: {
                          width: "15%",
                        },
                      },
                      {
                        title: "Asset Name",
                        field: "assetname",
                        cellStyle: {
                          width: "15%",
                        },
                      },
                      {
                        title: "Asset UID",
                        field: "assetuid",
                        cellStyle: {
                          width: "12%",
                        },
                      },
                      {
                        title: "Asset Code",
                        field: "assetcode",
                        cellStyle: {
                          width: "12%",
                        },
                      },
                      {
                        title: "Start Date",
                        field: "startDate",
                        cellStyle: {
                          width: "12%",
                        },
                      },
                      {
                        title: "End Date",
                        field: "endDate",
                        cellStyle: {
                          width: "12%",
                        },
                      },
                      {
                        title: "Status",
                        field: "status",
                      },
                    ]}
                    data={this.state.assetCyclesList}
                    options={{
                      actionsColumnIndex: -1,
                      selectedTab: 1,
                      initialPage: this.state.initialAssetCyclePage,
                      pageSize: this.state.pageSize,
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      paging: this.state.isAssetCycleListPaging,
                      headerStyle: {
                        backgroundColor: "#0B6F9D",
                        fontWeight: "bold",
                        color: "#ffff",
                        align: "left",
                      },
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          rowData.id === this.state.assetCycleId
                            ? "#FEF5E7"
                            : "#fff",
                        height: "3em",
                        textTransform: "capitalize",
                      }),
                    }}
                    onChangePage={(page, pageSize) => {
                      this.handleAssetCycleMaterialTablePageChange(
                        page,
                        pageSize
                      );
                    }}
                  />
                </div>
              )}
              {/* {this.state.planAssets.length > 0 ?
                <div style={{ padding: "3px", marginTop: "15px" }}>
                  <MaterialTable
                    title="Plan Asset List"
                    icons={UiUtils.getMaterialTableIcons()}
                    onRowClick={(event, rowData) => {
                      this.toPlanAssetListDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    columns={[
                      {
                        title: "#",
                        render: (rowData) => rowData.tableData.id + 1,
                        width: "5%",
                        align: "left",
                        cellStyle: {
                          width: "6%",
                        },
                      },
                      {
                        title: "Name",
                        field: "name",
                        cellStyle: {
                          width: "20%",
                        },
                      },
                      {
                        title: "UID",
                        field: "uid",
                        cellStyle: {
                          width: "20%",
                        },
                      },
                      {
                        title: "AssetCode",
                        field: "assetcode",
                        cellStyle: {
                          width: "20%",
                        },
                      },
                      {
                        title: "Status",
                        field: "status",
                        cellStyle: {
                          width: "20%",
                        },
                      },
                    ]}
                    data={this.state.planAssets}
                    options={{
                      actionsColumnIndex: -1,
                      selectedTab: 1,
                      initialPage: this.state.initialPage,
                      pageSize: this.state.pageSize,
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      paging: this.state.isAssetListPaging,
                      headerStyle: {
                        backgroundColor: '#0B6F9D',
                        fontWeight: 'bold',
                        color: "#ffff",
                        align: 'left',
                      },
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          rowData.id === this.state.assetId
                            ? "#FEF5E7"
                            : "#fff",
                        height: "3em",
                        textTransform: "capitalize",
                      }),
                    }}

                    onChangePage={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                  />
                </div> : ""
              } */}
            </>
          )}

          <div className="mt-4">
            <BackButton
              type="button"
              style={{ backgroundColor: "#162c50", color: "#fff" }}
              variant="contained"
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
          <Modal
            isOpen={this.state.openListModal}
            toggle={this.toggle}
            size="lg"
          >
            <Row>
              <Col md={12}>
                <MaterialTable
                  title="Asset List"
                  icons={UiUtils.getMaterialTableIcons()}
                  columns={[
                    {
                      title: "Name",
                      field: "name",
                      cellStyle: {
                        width: "25%",
                      },
                    },
                    {
                      title: "UID",
                      field: "uid",
                      cellStyle: {
                        width: "20%",
                      },
                    },
                    {
                      title: "Asset code",
                      field: "assetcode",
                      cellStyle: {
                        width: "20%",
                      },
                    },
                    {
                      title: "Asset Type",
                      field: "assetType",
                      cellStyle: {
                        width: "20%",
                      },
                    },
                    {
                      title: "Status",
                      field: "status",
                    },
                  ]}
                  data={this.state.assetList}
                  options={{
                    actionsColumnIndex: -1,
                    selection: true,
                    paging: false,
                    showTextRowsSelected: false,
                    maxBodyHeight: 450,
                    headerStyle: {
                      fontWeight: "bold",
                      position: "sticky",
                      top: 0,
                      align: "left",
                    },
                  }}
                  onSelectionChange={(rows) => this.onAssetCheckBoxSelect(rows)}
                />
              </Col>
            </Row>
            <ModalFooter>
              <Col style={{ textAlign: "center", paddingTop: "10px" }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{ background: "#ef6603", color: "#fff" }}
                  onClick={(e) => this.addReturnableAssetToList()}
                >
                  Add Asset
                </Button>
                &nbsp; &nbsp;
                <CancelIconButton onClick={(e) => this.toggle()} />
              </Col>
            </ModalFooter>
          </Modal>
        </>
      );
    }
  }

  editReturnablePlan() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="Returnable Plan"
          name="Edit Returnable Plan"
        />
        <div className="row">
          <ValidatorForm
            className="text-start"
            ref="form"
            onSubmit={this.updateReturnablePlanToBackend.bind(this)}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="card " id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Returnable Plan Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Plan Date
                        </label>
                        <DatePicker
                          selected={this.state.planDate}
                          onChange={this.handlePlanDateChange.bind(this)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Start Date*"
                          required
                          className="planDateTimeInput"
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-supplierType"
                        >
                          Customer <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="customerTypeList"
                          options={this.customerList}
                          value={this.state.customer}
                          defaultValue={this.state.customer}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCustomerTypeChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Customer"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-description"
                        >
                          Description
                        </label>
                        <textarea
                          name="collapsible-address"
                          className="form-control"
                          style={{
                            color: "black",
                            font: "inherit",
                            fontSize: "16px ",
                          }}
                          id="collapsible-address"
                          rows="4"
                          placeholder="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between custBut">
              <div className="col-sm-12 mt-3 text-center">
                <SaveButton id="saveButton" type="submit">
                  {" "}
                  Save{" "}
                </SaveButton>
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.editCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </>
    );
  }
}
export default withRouter(ReturnablePlan);
