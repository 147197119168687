import moment from "moment";
import FetchServerData from "./FetchServerData";
import PtsAlert from "../component/ptsAlert/PtsAlert";
import store from "../redux/store";

const equalsIgnoreCase = (string1, string2) => {
  if (
    undefined === string1 ||
    null === string1 ||
    undefined === string2 ||
    null === string2
  ) {
    return false;
  }
  if (
    string1.toString().trim().toUpperCase() ===
    string2.toString().trim().toUpperCase()
  ) {
    return true;
  }

  return false;
};

const getUtcToLocalDateFromUnix = (unix) => {
  let localDateTimeFormate = moment.unix(unix).format("LLL");

  return localDateTimeFormate;
};

const getUtcToLocalDate = (dateTime) => {
  let date = new Date(dateTime);

  let localDateTimeFormate = moment(date).format("LLL");

  return localDateTimeFormate;
};

const getUtcToLocal = (dateTime) => {
  if (dateTime === null) {
    return "";
  }
  let date = new Date(dateTime);

  let localDateTimeFormate = moment(date).format("MMM DD, YYYY");

  return localDateTimeFormate;
};
const getUtcToLocalDateTime = (dateTime) => {
  if (dateTime === null) {
    return "";
  }
  let date = new Date(dateTime);

  let localDateTimeFormate = moment(date).format("MMM DD, YYYY  - hh:mm a");

  return localDateTimeFormate;
};

const compareDateTimeWithCurrent = (date) => {
  const diff = moment().diff(date, "minutes");
  return diff;
};

const greaterDateTimeWithCurrent = (date) => {
  const diff = moment().isAfter(date);
  return diff;
};

const matchSpecialCharacters = (string) => {
  let pattern = /^[\w\s&.\-]*$/;

  if (string.match(pattern)) {
    return false;
  }

  return true;
};

const convertLocalDateToUTCDate = (date) => {
  let utcDateTime = "";

  utcDateTime = new Date(date).toISOString();

  return utcDateTime;
};

const validateEmail = (email) => {
  return !email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const isNull = (input) => {
  if (undefined === input || null === input) {
    return true;
  }
  return false;
};

export const isNullOrEmpty = (input) => {
  if (undefined === input || null === input || input === "") {
    return true;
  }
  if (input.toString().trim() === "") {
    return true;
  }
  return false;
};

export const getAddressAsText = (address) => {
  if (isNull(address)) {
    return "";
  }
  let finalAddress = "";
  let addressLine1 = address.line1;
  if (addressLine1 && addressLine1.length > 0) {
    finalAddress = addressLine1.toString().trim() + ", ";
  }
  let addressLine2 = address.line2;
  if (addressLine2 && addressLine2.length > 0) {
    finalAddress = finalAddress + addressLine2.toString().trim() + ", ";
  }

  let addressLine = address.line;
  if (addressLine && addressLine.length > 0) {
    finalAddress = finalAddress + addressLine.toString().trim() + ", ";
  }

  let city = address.city;
  if (city && city.length > 0) {
    finalAddress = finalAddress + city.toString().trim() + ", ";
  }

  let state = address.statevo;
  if (state && state.name && state.name.toString().length > 0) {
    finalAddress = finalAddress + state.name.toString().trim() + ", ";
    if (
      state.countryvo &&
      state.countryvo.name &&
      state.countryvo.name.toString().length > 0
    ) {
      finalAddress =
        finalAddress + state.countryvo.name.toString().trim() + ", ";
    }
  } else {
    state = address.state;
    if (state && state.name && state.name.toString().length > 0) {
      finalAddress = finalAddress + state.name.toString().trim() + ", ";
      if (
        state.countryvo &&
        state.countryvo.name &&
        state.countryvo.name.toString().length > 0
      ) {
        finalAddress =
          finalAddress + state.countryvo.name.toString().trim() + ", ";
      }
      if (
        state.country &&
        state.country.name &&
        state.country.name.toString().length > 0
      ) {
        finalAddress =
          finalAddress + state.country.name.toString().trim() + ", ";
      }
    }
  }

  let pin = address.zipcode;
  if (pin && pin.length > 0) {
    finalAddress = finalAddress + pin.toString().trim();
  } else if (address.zipcode && address.zipcode > 0) {
    finalAddress = finalAddress + address.zipcode.toString().trim();
  }

  let zip = address.zipCode;
  if (zip && zip.length > 0) {
    finalAddress = finalAddress + zip.toString().trim();
  }

  return finalAddress;
};

export const toCamelCase = (string) => {
  String.prototype.toCamelCase = function () {
    return this.substring(0, 1).toUpperCase() + this.substring(1);
  };
  return string.toCamelCase();
};
export const displayUsaPhoneNumberFormate = (phoneNumber) => {
  if (phoneNumber === null) {
    return "";
  }

  let output = "";
  if (phoneNumber) {
    output = "(";
  } else {
    output = "";
  }
  phoneNumber.replace(
    /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
    function (match, g1, g2, g3) {
      if (g1.length) {
        output += g1;
        if (g1.length === 3) {
          output += ")";
          if (g2.length) {
            output += " " + g2;
            if (g2.length === 3) {
              output += " - ";
              if (g3.length) {
                output += g3;
              }
            }
          }
        }
      }
    }
  );
  return output;
};

export const maxFieldLength = (characters) => {
  let maxLength = PtsAlert.error(
    "The size of this field is limited to " + characters + " characters only"
  );

  return maxLength;
};

function checkPanLength(input) {
  var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

  if (input.length < 10) {
    PtsAlert.error("Enter 10 Digit PAN No");
    return false;
  }

  if (regpan.test(input) === false) {
    PtsAlert.error(
      "Enter Valid PAN No Format :: AAAAA1111A || 1-Number A-Character"
    );
    return false;
  } else {
    return true;
  }
}

function checkGstnLength(input) {
  if (input.length < 15) {
    PtsAlert.error("Enter 15 Digit GSTN No");
    return false;
  }

  var gstnPattern =
    "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}";
  var patternArray = input.match(gstnPattern);

  if (patternArray == null) {
    PtsAlert.error(
      "Enter Valid GSTN No  Format :: 11-AAAAA1111A-1-A-1/A || 1-Number A-Character"
    );
    return false;
  } else {
    return true;
  }
}

export const toCamelCaseUL = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

};
// export const getSidebarMenuRolePermission = () => {
//   let userType = store.getState().userDetailsWithToken.userDetailsWithToken
//     ? store.getState().userDetailsWithToken.userDetailsWithToken.user_type
//     : '';
//   let accessType = store.getState().userDetailsWithToken.userDetailsWithToken
//     ? store.getState().userDetailsWithToken.userDetailsWithToken.access_details
//     : '';
//   console.log('1');

//   let showMenuList = [];

//   let allMenuList = [
//     {
//       title: 'Home',
//       icon: 'fa fa-home',
//       to: '/dashboard',
//     },
//     {
//       title: 'Governance',
//       icon: 'fa fa-landmark',
//       to: '/governance',
//     },
//     {
//       title: 'Transaction History',
//       icon: 'fa fa-clock-rotate-left',
//       to: '/transaction-history',
//     },
//     {
//       title: 'Refer a friend',
//       icon: 'fa-solid fa-user-group',
//       to: '/referral-bonus',
//     },
//     {
//       title: 'FAQ',
//       icon: 'fa fa-question',
//       to: '/faq',
//     },
//   ];
//   let retailMenuItems = [
//     {
//       title: 'Home',
//       icon: 'fa fa-home',
//       to: '/dashboard',
//     },

//     {
//       title: 'Transaction History',
//       icon: 'fa fa-clock-rotate-left',
//       to: '/transaction-history',
//     },
//     {
//       title: 'Refer a friend',
//       icon: 'fa-solid fa-user-group',
//       to: '/referral-bonus',
//     },
//     {
//       title: 'FAQ',
//       icon: 'fa fa-question',
//       to: '/faq',
//     },
//   ];

//   if (userType !== null && userType !== undefined) {

//     switch (userType) {
//       case 'Promoter':
//         for (let i = 0; i < allMenuList.length; i++) {
//           if (accessType !== null && accessType.governance_proposal !== true) {
//             if (allMenuList[i].title === 'Governance') {
//               allMenuList.splice(i, 1);
//             }
//           }
//           if (accessType !== null && accessType.transaction_ledger !== true) {
//             if (allMenuList[i].title === 'Transaction History') {
//               allMenuList.splice(i, 1);
//             }
//           }
//         }
//         showMenuList = allMenuList;
//         break;
//       case 'Employee':
//         for (let i = 0; i < allMenuList.length; i++) {
//           if (accessType !== null && accessType.governance_proposal !== true) {
//             if (allMenuList[i].title === 'Governance') {
//               allMenuList.splice(i, 1);
//             }
//           }
//           if (accessType !== null && accessType.transaction_ledger !== true) {
//             if (allMenuList[i].title === 'Transaction History') {
//               allMenuList.splice(i, 1);
//             }
//           }
//         }
//         showMenuList = allMenuList;
//         break;
//       case 'Institutional':
//         for (let i = 0; i < allMenuList.length; i++) {
//           if (accessType !== null && accessType.governance_proposal !== true) {
//             if (allMenuList[i].title === 'Governance') {
//               allMenuList.splice(i, 1);
//             }
//           }
//           if (accessType !== null && accessType.transaction_ledger !== true) {
//             if (allMenuList[i].title === 'Transaction History') {
//               allMenuList.splice(i, 1);
//             }
//           }
//         }
//         showMenuList = allMenuList;
//         break;
//       case 'Retail':
//         for (let i = 0; i < retailMenuItems.length; i++) {
//           if (accessType !== null && accessType.governance_proposal !== true)
//             if (retailMenuItems[i].title === 'Governance') {
//               retailMenuItems.splice(i, 1);
//             } else if (accessType !== null && accessType.transaction_ledger !== true) {
//               if (retailMenuItems[i].title === 'Transaction History') {
//                 retailMenuItems.splice(i, 1);
//               }
//             }
//         }
//         showMenuList = retailMenuItems;
//         break;
//     }
//   }

//   return showMenuList;
// };

// export const checkUserLogin = () => {
//   let isAdminUserLogin = store.getState().adminMemberDetails.adminDetails
//     ? store.getState().adminMemberDetails.adminDetails.isUserSignin
//     : '';
//   let isUserLogin = store.getState().userDetailsWithToken.userDetailsWithToken
//     ? store.getState().userDetailsWithToken.userDetailsWithToken.isUserSignin
//     : '';
//   console.log(isAdminUserLogin);
//   if (isAdminUserLogin === true) {
//     return true;
//   }
//   if (isUserLogin === true) {
//     return true;
//   }
//   return false;
// };

export const getRolePermission = (privilegeList) => {
  let allPermission = false;
  let createPermission = false;
  let readPermission = false;
  let updatePermission = false;
  let deletePermission = false;
  if (privilegeList.length > 0) {
    for (let i = 0; i < privilegeList.length; i++) {
      switch (privilegeList[i].toLowerCase()) {

        case "*":
          allPermission = true;
          createPermission = true;
          readPermission = true;
          updatePermission = true;
          deletePermission = true;
          break;
        case "create":
          createPermission = true;
          readPermission = true;
          break;
        case "update":
          updatePermission = true;
          readPermission = true;
          break;
        case "delete":
          deletePermission = true;
          readPermission = true;
          break;
        default: break;
      }
    }
  }

  return [
    allPermission,
    createPermission,
    readPermission,
    updatePermission,
    deletePermission,
  ];
};
export const setFCMTokenForUser = () => {
  let header = store.getState().header.header;
  const fcmToken = store.getState().appUser.fcmToken;
  const userData = store.getState().appUserData.appUserData;
  if (!header || !header.appuserId) {
    return;
  }
  if (!fcmToken || fcmToken.length <= 0) {
    /*Fcm Token Not Exists, Create Fcm Token*/
    return;
  }
  /* Call Service to Set Token */
  header.appclient = "w";
  let requestObject = {
    header: header,
    appuser: {
      fcmtoken: fcmToken,
      email: userData.email,
      id: userData.id,
    },
  };
  FetchServerData.callPostService("/appuserms/updateFcmToken", requestObject)
    .then((output) => {
      if (output.status === "SUCCESS") {
        // console.log("FCM Token updated successfully");
      }
    })
    .catch((error) => {
      // console.log("Error while updating FCM Token: " + error);
    });
};

export const base64ToImage = (base64Data) => {
  let logoData = store.getState().organization.organization.organizationLogo;
  if (logoData) {
    return (
      <img
        src={"data:image/png;base64," + logoData}
        style={{ maxHeight: "2em" }}
        alt="logo"
      />
    );
  }
};

export const getTopMenus = () => {
  let topMenuArray = store.getState().menus.menuList;
  for (let i = 0; i < topMenuArray.length; i++) {
    if (topMenuArray[i].pageid) {
      topMenuArray[i].pageid = topMenuArray[i].pageid.split("#/").join("/");
    }
  }
  return topMenuArray;
};

export const materialTablePageSize = () => {
  let pageSize = [5, 10, 20, 50, 100];

  return pageSize;
};

const utilsObject = {
  equalsIgnoreCase,
  getUtcToLocalDate,
  getUtcToLocalDateTime,
  getUtcToLocal,
  matchSpecialCharacters,
  convertLocalDateToUTCDate,
  validateEmail,
  compareDateTimeWithCurrent,
  getUtcToLocalDateFromUnix,
  getRolePermission,
  // getSidebarMenuRolePermission,
  // checkUserLogin,
  greaterDateTimeWithCurrent,
  getAddressAsText,
  isNull,
  isNullOrEmpty,
  toCamelCase,
  toCamelCaseUL,
  displayUsaPhoneNumberFormate,
  maxFieldLength,
  checkPanLength,
  checkGstnLength,
  setFCMTokenForUser,
  base64ToImage,
  getTopMenus,
  materialTablePageSize,
};
export default utilsObject;

