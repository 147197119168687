import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { Component } from "react";
export class BackIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        onClick={this.onClick.bind(this)}
        style={{ cursor: "pointer", fontSize: "larger", marginLeft: "0.5em" }}
      >
        <Tooltip title="Back">
          <Button
            type={this.state.type ? this.state.type : ""}
            variant="contained"
            size="small"
            startIcon={<ArrowBackIcon />}
            style={{
              background: "#717373",
              color: "#fff",
              marginRight: "1rem",
              marginBottom: "1em",
              textTransform: "capitalize",
            }}
            onClick={this.onClick.bind(this)}
          >
            Back
          </Button>
        </Tooltip>
      </span>
    );
  }
}
export default BackIconButton;
