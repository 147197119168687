import { withRouter } from "../../withRouter";
import React, { Component } from "react";
import { CardHeader, Col, Row } from "reactstrap";
import { Buffer } from "buffer";
import Button from "@material-ui/core/Button";
import FetchServerData from "../../provider/FetchServerData";
import store from "../../redux/store";
import DeleteIconButton from "../buttons/DeleteIconButton";
import PtsButton from "../buttons/PtsButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./AssetList.css";
import ApplicationStateAction from "../../redux/applicationState/ApplicationStateAction";
import EditIconButton from "../buttons/EditIconButton";
import Pagination from "../assetTracking/Pagination";
import Utils from "../../provider/Utils";
import FilterDetailsAction from "../../redux/filterDetails/FilterDetailsAction";

class AssetList extends Component {
  header = store.getState().header.header;
  assetState = store.getState().applicationState.assetState;
  assetStateDetails = {};
  constructor(props) {
    super(props);
    let categoryId = "";
    let catalogueId = "";
    categoryId = props.categoryId;
    catalogueId = props.catalogueId;
    if (!categoryId && props.state) {
      categoryId = props.state.categoryId;
      catalogueId = props.state.catalogueId;
    }
    if (this.assetState) {
      this.assetStateDetails = Object.getOwnPropertyNames(this.assetState);
    }
    if (this.assetStateDetails.length > 0) {
      this.state = {
        search: "",
        categoryId: categoryId,
        catalogueId: catalogueId,
        initialPage: this.assetState.initialPage,
        pageSize: this.assetState.pageSize,
        assetId: this.assetState.assetId,
        showToolbarBtn: false,
        selectedAssetList: new Set(),
        assetCodeList: [],
        assetUomList: [],
        assetIdList: [],
        assetList: props.assetList,
        pageNo: props.assetSearchPage.pageNo,
        showPerPage: props.assetSearchPage.pageSize,
        totalPages: props.assetSearchPage.totalPages,
        totalRecords: props.assetSearchPage.total,
        openMoveAssetModal: false,
        isFirstTimeLoading: true,
        allCheckBoxSelected: false,
        assetBreadCrumb: props.assetBreadCrumb,
      };
    } else {
      this.state = {
        search: "",
        categoryId: categoryId,
        catalogueId: catalogueId,
        initialPage: 0,
        pageSize: 10,
        showToolbarBtn: false,
        selectedAssetList: new Set(),
        assetCodeList: [],
        assetUomList: [],
        assetIdList: [],
        assetList: props.assetList,
        pageNo: props.assetSearchPage.pageNo,
        showPerPage: props.assetSearchPage.pageSize,
        totalPages: props.assetSearchPage.totalPages,
        totalRecords: props.assetSearchPage.total,
        openMoveAssetModal: false,
        isFirstTimeLoading: true,
        allCheckBoxSelected: false,
        assetBreadCrumb: props.assetBreadCrumb,
      };
    }
  }

  async getCategoryDetails(
    categoryId,
    catalogueId,
    header,
    pageNo,
    pageSize,
    search,
    showAlert
  ) {
    const postObject = {
      header: typeof header === "undefined" ? this.header : header,
      category: {
        id: categoryId,
        catalogue: {
          id:
            typeof catalogueId === "undefined"
              ? this.catalogue.id
              : catalogueId,
        },
        assetSearchPage: {
          searchText: search,
          pageNo: pageNo,
          pageSize: pageSize,
        },
      },
    };
    await FetchServerData.callPostService("/categoryms/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let category = output.data.category;
          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );

          let getAllPermission = getPermission[0];
          let getCreatePermission = getPermission[1];
          let getReadPermission = getPermission[2];
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          store.dispatch(FilterDetailsAction.setFilterDetails({}));
          if (category.assetList.length === 0 && showAlert) {
            PtsAlert.error("Data Not Available");
            return;
          }

          this.setState({
            render: true,
            // id: category.id,
            assetList: category.assetList,
            getAllPermission: getAllPermission,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
            getReadPermission: getReadPermission,
            getCreatePermission: getCreatePermission,
            pageNo: category.assetSearchPage.pageNo,
            showPerPage: category.assetSearchPage.pageSize,
            totalPages: category.assetSearchPage.totalPages,
            totalRecords: category.assetSearchPage.total,
            assetBreadCrumb: category.categoryPath,
            allCheckBoxSelected: false,
          });
        } else {
        }
      }
    );
  }

  addAsset = async (e) => {
    // await this.getCodeUomList();
    this.props.navigate("/asset", {
      state: {
        action: "create",
        categoryId: this.state.categoryId,
        assetCodeList: this.state.assetCodeList,
        assetUomList: this.state.assetUomList,
        assetBreadCrumb: this.state.assetBreadCrumb,
      },
    });
  };
  viewAsset = (e, data) => {
    let assetState = {
      assetId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(ApplicationStateAction.setApplicationState({ assetState }));
    this.props.navigate("/asset", {
      state: {
        action: "view",
        categoryId: this.state.categoryId,
        assetId: data.id,
        replace: true,
        pageNo: this.state.pageNo,
        showPerPage: this.state.showPerPage,
        assetBreadCrumb: this.state.assetBreadCrumb,
      },
    });
  };
  editAsset = async (e, data) => {
    // await this.getCodeUomList();
    this.props.navigate("/asset", {
      state: {
        action: "edit",
        assetId: data.id,
        categoryId: this.state.categoryId,
        assetCodeList: this.state.assetCodeList,
        assetUomList: this.state.assetUomList,
      },
    });
  };

  deleteMultipleAsset(e) {
    let assetIdList = [];
    // for (let i = 0; i < this.state.selectedAssetList.size; i++) {
    //   console.log(this.state.selectedAssetList[i])
    //   assetIdList.push(this.state.selectedAssetList[i].id)
    // }
    this.state.selectedAssetList.forEach((element) => {
      assetIdList.push(element.id);
    });
    const postObject = {
      header: this.header,
      category: {
        id: this.state.categoryId,
        catalogue: {
          id: this.state.catalogueId,
        },
        assetSearchPage: {
          pageNo: this.state.pageNo,
          pageSize: this.state.pageSize,
        },
      },
      assetList: assetIdList,
    };

    FetchServerData.callPostService("/categoryms/deleteAssets", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let category = output.data.category;
          PtsAlert.success(output.message);
          this.setState({
            assetList: category.assetList,
            // selectedAssetList: [],
            pageNo: category.assetSearchPage.pageNo,
            showPerPage: category.assetSearchPage.pageSize,
            totalPages: category.assetSearchPage.totalPages,
            totalRecords: category.assetSearchPage.total,
            allCheckBoxSelected: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  moveAsset() {
    let assetIdList = [];
    this.state.selectedAssetList.forEach((element) => {
      assetIdList.push(element.id);
    });
    this.props.navigate("/moveAsset", {
      state: {
        assetId: this.state.assetId,
        assetIdList: assetIdList,
        categoryId: this.state.categoryId,
      },
    });
  }

  deleteAsset(e, data) {
    const postObject = {
      header: this.header,
      category: {
        id: this.state.categoryId,
        catalogue: {
          id: this.state.catalogueId,
        },
        assetSearchPage: {
          pageNo: this.state.pageNo,
          pageSize: this.state.pageSize,
        },
      },
      asset: {
        id: data.id,
      },
    };

    FetchServerData.callPostService("/categoryms/deleteAsset", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let category = output.data.category;
          PtsAlert.success(output.message);
          this.setState({
            assetList: category.assetList,
            pageNo: category.assetSearchPage.pageNo,
            showPerPage: category.assetSearchPage.pageSize,
            totalPages: category.assetSearchPage.totalPages,
            totalRecords: category.assetSearchPage.total,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  onCheckBoxSelect(e, data, index) {
    let isChecked = e.target.checked;
    if (isChecked) {
      this.state.selectedAssetList.add(data);
    } else {
      this.state.selectedAssetList.delete(data);
    }
    data.value = isChecked;
    this.setState({
      allCheckBoxSelected:
        this.state.selectedAssetList.size === this.state.showPerPage,
      showToolbarBtn: this.state.selectedAssetList.size > 0 ? true : false,
      selectedAssetList: this.state.selectedAssetList,
    });
  }
  onAllCheckBoxSelect(e) {
    let isChecked = e.target.checked;
    let dataRows = this.state.assetList;
    for (let i = 0; i < dataRows.length; i++) {
      const row = dataRows[i];
      this.onCheckBoxSelect(e, row, i);
    }
    this.setState({
      allCheckBoxSelected: isChecked,
    });
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  onPaginationChange(returnProp) {
    let counter = returnProp.counter;
    let pageNo = this.state.pageNo + counter;
    let pageSize = returnProp.showPerPage;
    let buttonIndex = returnProp.buttonIndex;
    let currentPageIndex =
      typeof counter !== "undefined" ? pageNo : buttonIndex;
    let resetPageNo = returnProp.resetPageNo;
    if (resetPageNo === true) {
      currentPageIndex = 0;
    }
    this.setState({
      pageNo: currentPageIndex,
      showPerPage: pageSize,
    });
    this.getCategoryDetails(
      this.state.categoryId,
      this.state.catalogueId,
      this.header,
      currentPageIndex,
      pageSize
    );
    this.handleMaterialTablePageChange(currentPageIndex, pageSize);
  }
  onSearchChange(e) {
    this.setState({ search: e.target.value });
  }
  onSearchClick() {
    this.setState({
      isFirstTimeLoading: false,
    });
    this.getCategoryDetails(
      this.state.categoryId,
      this.state.catalogueId,
      this.header,
      this.state.pageNo,
      this.state.showPerPage,
      this.state.search
    );
  }

  assetTrackingStatus(trackingHistoryStatus) {
    switch (trackingHistoryStatus) {
      case "Active":
        return <span className="dot"></span>;
      case "NotTracked":
        return <span className="dot"></span>;
      case "Tracked":
        return <span className="greendot"></span>;
      case "HoldingPeriodOver":
        return <span className="yellowdot blink_me"></span>;
      case "CycleTimeOver":
        return <span className="orangedot blink_me"></span>;
      case "MissingAsset":
        return <span className="reddot blink_me"></span>;
      default:
    }
  }

  excelDownloadAssets() {
    const postObject = {
      header: this.header,
      category: {
        id: this.state.categoryId,
      },
    };

    FetchServerData.callPostService(
      "/categoryms/exportAssets",
      postObject
    ).then((output) => {
      let AssetsExcelData = output.data;
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        const byteArray = new Buffer(
          AssetsExcelData.data.replace(/^[\w\d;:\/]+base64\,/g, ""),
          "base64"
        );
        const outputFilename = AssetsExcelData.fileName;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
      } else {
      }
    });
  }

  render() {
    return (
      <div style={{ maxWidth: "100%" }}>
        <CardHeader style={{ padding: "1rem" }}>
          <Row>
            <Col md={6} className="cardTitle">
              <h5 style={{ margin: "auto 0px" }}>
                <b> Asset List</b>
              </h5>
            </Col>
            <Col md={6} className="cardAction">
              {this.state.assetList?.length > 0 && (
                <Button
                  id="ptsButton"
                  type="submit"
                  className="me-1"
                  style={{
                    // padding: "5px 10px",
                    width: "200px",
                    background: "#5ac489",
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                  startIcon={
                    <i
                      className="fa fa-download"
                      style={{ fontSize: "16px" }}
                    ></i>
                  }
                  onClick={(e) => this.excelDownloadAssets()}
                >
                  Export Asset QR Code
                </Button>
              )}
              {this.state.showToolbarBtn && (
                <>
                  <PtsButton
                    domainObject="Asset"
                    onClick={(e) => this.deleteMultipleAsset(e)}
                    btnName="Delete"
                    style={{ float: "right" }}
                  />
                  &nbsp;
                  <PtsButton
                    btnName="Move Asset"
                    onClick={(e) => this.moveAsset()}
                    style={{ float: "right" }}
                  />
                  &nbsp;
                </>
              )}
              {!this.state.showToolbarBtn && (
                <PtsButton
                  btnName="Create Asset"
                  onClick={(e) => this.addAsset()}
                />
              )}
            </Col>
          </Row>
        </CardHeader>
        <div className="row">
          <div className="col md-12">
            <div className="card">
              <div className="row justify-content-between p-3">
                <div className="d-flex col-12 col-lg-4 col-xl-4">
                  <div className="col-12 text-start totalResults">
                    <span>Total Results: {this.state.totalRecords}</span>
                  </div>
                </div>
                <div className="d-flex col-12 col-lg-8 col-xl-8">
                  <div className="col-12 text-end">
                    <input
                      type="search"
                      id="gsearch"
                      // style={{ width: "240px", height: "33px", outline: "none" }}
                      placeholder="Search"
                      name="gsearch"
                      value={this.state.search}
                      onChange={this.onSearchChange.bind(this)}
                    />
                    <button
                      className="gsearchButton"
                      onClick={this.onSearchClick.bind(this)}
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table text-start table-hover">
                  <thead className="assetCycleThead">
                    <tr>
                      <th scope="col fw-bold ">
                        <input
                          type="checkbox"
                          checked={this.state.allCheckBoxSelected}
                          onChange={this.onAllCheckBoxSelect.bind(this)}
                        />
                      </th>
                      <th scope="col fw-bold">Name</th>
                      <th scope="col fw-bold">Asset UID</th>
                      <th scope="col fw-bold">Asset Code</th>
                      {/* <th scope="col fw-bold">Asset Type</th> */}
                      <th scope="col fw-bold">Status</th>
                      <th scope="col fw-bold">Tracking Status</th>
                      <th scope="col fw-bold">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.assetList?.map((rowData, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor:
                            rowData.id === this.state.assetId
                              ? "#FEF5E7"
                              : "#fff",
                          cursor: "pointer",
                        }}
                      >
                        {/* <td>{this.state.pageNo * this.state.showPerPage + index + 1}</td> */}
                        <td>
                          <input
                            type="checkbox"
                            checked={
                              rowData.value || this.state.allCheckBoxSelected
                            }
                            onChange={(e) =>
                              this.onCheckBoxSelect(e, rowData, index)
                            }
                          />
                        </td>
                        <td
                          style={{ width: "250px" }}
                          onClick={(e) => this.viewAsset(e, rowData)}
                        >
                          {rowData.name}
                        </td>
                        <td
                          style={{ width: "250px" }}
                          onClick={(e) => this.viewAsset(e, rowData)}
                        >
                          {rowData.uid}
                        </td>
                        <td
                          style={{ width: "250px" }}
                          onClick={(e) => this.viewAsset(e, rowData)}
                        >
                          {rowData.assetcode}
                        </td>
                        {/* <td style={{ width: "300px" }} onClick={(e) => this.viewAsset(e, rowData)}>{rowData.assetType}</td> */}
                        <td
                          style={{ width: "250px" }}
                          onClick={(e) => this.viewAsset(e, rowData)}
                        >
                          {rowData.status}
                        </td>
                        <td
                          style={{ width: "250px" }}
                          onClick={(e) => this.viewAsset(e, rowData)}
                        >
                          {this.assetTrackingStatus(
                            rowData.trackingHistoryStatus
                          )}{" "}
                          {rowData.trackingHistoryStatus}
                        </td>
                        <td style={{ width: "300px" }}>
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "x-larger",
                              display: "flex",
                              float: "left",
                              marginTop: "0.2em",
                            }}
                          >
                            <EditIconButton
                              className="actionBtn"
                              btnName="Edit"
                              onClick={(e) => this.editAsset(e, rowData)}
                            />
                          </span>
                          &nbsp; &nbsp;
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "x-larger",
                              display: "flex",
                              float: "left",
                              marginTop: "0.2em",
                            }}
                          >
                            <DeleteIconButton
                              className="actionBtn"
                              domainObject={rowData.name}
                              btnName="Delete"
                              title="Asset"
                              onClick={(e) => this.deleteAsset(e, rowData)}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {this.state.totalRecords === 0 && (
                <div className="row text-center p-2">
                  <h5>Data not Available</h5>
                </div>
              )}
              {this.state.totalRecords > 10 && (
                <div className="row justify-content-between pagination p-2">
                  <div className="pageSize col-4 text-start">
                    Page {this.state.pageNo + 1} of {this.state.totalPages}
                  </div>
                  <div className="col-4 text-end">
                    <Pagination
                      showPerPage={this.state.showPerPage}
                      onPaginationChange={this.onPaginationChange.bind(this)}
                      total={this.state.totalRecords}
                      pageNo={this.state.pageNo}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AssetList);
