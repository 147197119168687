import React, { Component } from "react";
import infoIcon from "../../../src/assets/images/infoIcon.png";
import GreenCheckIcon from "../../assets/icons/greenCheck.svg";
import RedCrossIcon from "../../assets/icons/redCross.svg";
import Tooltip from "../tooltip/Tooltip";

class PasswordRequirementsTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderTooltipTitle = () => {
    const { error } = this.props;

    return (
      <>
        {/* <p className="lead">Password Requirements:</p> */}
        <div className="d-flex flex-row">
          <span className="mr-04">
            <img
              src={error.one ? RedCrossIcon : GreenCheckIcon}
              alt="validation-check"
              height={15}
            />
          </span>
          Minimum 8 characters and maximum 16 characters long
          <br />
        </div>
        <div className="d-flex flex-row">
          <span className="mr-04">
            <img
              src={error.two ? RedCrossIcon : GreenCheckIcon}
              alt="validation-check"
              height={15}
            />
          </span>
          At least one uppercase character
          <br />
        </div>
        <div className="d-flex flex-row">
          <span className="mr-04">
            <img
              src={error.three ? RedCrossIcon : GreenCheckIcon}
              alt="validation-check"
              height={15}
            />
          </span>
          At least one lowercase character
          <br />
        </div>
        <div className="d-flex flex-row">
          <span className="mr-04">
            <img
              src={error.four ? RedCrossIcon : GreenCheckIcon}
              alt="validation-check"
              height={15}
            />
          </span>
          At least one number
          <br />
        </div>
        <div className="d-flex flex-row">
          <span className="mr-04">
            <img
              src={error.five ? RedCrossIcon : GreenCheckIcon}
              alt="validation-check"
              height={15}
            />
          </span>
          At least one non-alphanumeric character
        </div>
      </>
    );
  };

  render() {
    return (
      <Tooltip
        open={this.props.open}
        placement="top"
        title={this.renderTooltipTitle()}
        id="password-requirements-tooltip"
      >
        <img src={infoIcon} alt="" width="20" height="20" />
      </Tooltip>
    );
  }
}

export default PasswordRequirementsTooltip;
