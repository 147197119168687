import {
  CLEAR_FILTER_DETAILS,
  GET_FILTER_DETAILS,
  SET_FILTER_DETAILS,
} from "./FilterDetailsTypes";

const initialState = {};

const FilterDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILTER_DETAILS:
      return {
        ...state,
      };
    case SET_FILTER_DETAILS:
      return {
        ...state,
        filterData: action.payload,
      };

    case CLEAR_FILTER_DETAILS:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default FilterDetailsReducer;
