import React, { Component } from "react";

import { Col, Row } from "reactstrap";
import { withRouter } from "../../withRouter";
import FetchServerData from "../../provider/FetchServerData";
import { TextField } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import store from "../../redux/store";
import Button from "@material-ui/core/Button";
class AssetPrint extends Component {
  header = store.getState().header.header;
  constructor(props) {
    super(props);
    if (props.state) {
    }
    this.state = {
      selectedPrinter: null,
      selectedPrinterList: [],
      uid: props.state.uid,
    };
    // this.jspmWSStatus();
    this.getPrinterList();
  }
  getPrinterList(e) {
    FetchServerData.callGetService(
      "/assetTrackServices/staticms/getPrinterList"
    )
      .then((output) => {
        if (output.status === "SUCCESS") {
          let printerList = output.data.printerList;
          let id = this.state.uid;

          for (let i = 0; i < printerList.length; i++) {
            let str = printerList[i].commands;
            printerList[i].commands = str.replaceAll("PTSASSETUID", id);
            // let str1 = str.replace(id, str);
            this.setState({ selectedPrinter: printerList[i] });
          }
          this.setState({ selectedPrinterList: printerList });
        } else {
        }
      })
      .catch((error) => { });
  }
  handleSelectPrinterChange(selectedPrinter) {
    this.state.assetUom = selectedPrinter;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.setState({
      selectedPrinter: selectedPrinter,
    });
  }
  render() {
    return (
      <>
        <div style={{ textAlign: "left" }}>
          <h1>Print PRN commands/files from Javascript</h1>
          <hr />
          <Row>
            <Col md={12}>
              <label className="checkbox" style={{ display: "inline" }}>
                <input type="checkbox" id="useDefaultPrinter" />{" "}
                <strong>Print to Default printer</strong>
              </label>
            </Col>
          </Row>
          <Row style={{ marginTop: "2em" }}>
            <Col md={12}>
              <div id="installedPrinters">
                <label htmlFor="installedPrinterName">
                  Select an installed Printer:
                </label>
                &nbsp;
                <select name="installedPrinterName" id="installedPrinterName" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "2em" }}>
            <Col md={12}>
              <Autocomplete
                id="multicol-country"
                style={{ width: "50%" }}
                required
                options={this.state.selectedPrinterList}
                value={this.state.selectedPrinter}
                defaultValue={this.state.selectedPrinter}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  this.handleSelectPrinterChange(value);
                }}
                inputprops={{
                  style: { textTransform: "capitalize" },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="form-control"
                    variant="outlined"
                    placeholder="Unit Of Measurement"
                    required
                  />
                )}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "2em" }}>
            <Col md={12}>
              <Button
                type="submit"
                // onclick="print();"
                style={{
                  background: "#0B6F9D",
                  color: "#fff",
                  marginRight: "1rem",
                }}
                onClick={this.print.bind(this)}
              >
                Print Now
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(AssetPrint);
