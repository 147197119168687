import { GET_ORGANIZATION, SET_ORGANIZATION } from "./OrganizationTypes";

const getOrganization = () => {
  return {
    type: GET_ORGANIZATION,
  };
};

const setOrganization = (data) => {
  return {
    type: SET_ORGANIZATION,
    payload: data,
  };
};
const OrganizationAction = { getOrganization, setOrganization };

export default OrganizationAction;
