import React, { Component } from "react";
import { connect } from "react-redux";
import "./Sidebar.css";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import logo from "../../assets/images/AssetTrackifierLogo.png";
import store from "../../redux/store";
import MenuAction from "../../redux/menu/MenuAction";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.props.handleClick.bind(this);
    let menuList = store.getState().menus.menuList;
    let activeMenu = store.getState().menus?.activeMenu;

    if (undefined === activeMenu || null === activeMenu) {
      activeMenu = (menuList && menuList.length) > 0 ? menuList[0].id : -1;
    }
    console.log(activeMenu);
    this.state = {
      isOpen: true,
      tokenPrice: "",
      menuItems: menuList,
      activeMenu: activeMenu,
    };
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      let activeMenu = store.getState().menus?.activeMenu;
      this.setState({ activeMenu: activeMenu });
    });
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  selectedChildMenu = (menuid) => {
    for (let i = 0; i < this.state.menuItems.length; i++) {
      if (this.state.menuItems[i].id === menuid) {
        store.dispatch(MenuAction.setActiveMenu(menuid));
        this.setState({ activeMenu: menuid });
      }
    }
  };

  toggleSidebar = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.handleClick(!this.state.isOpen);

    if (window.innerWidth > 1199) {
      return;
    }

    let appClasses = document.getElementById("app").classList;
    let overlayClasses = document.getElementById("overlay").classList;

    if (appClasses.contains("sidebar-menu-extended")) {
      appClasses.remove("sidebar-menu-extended");
      overlayClasses.remove("overlay-active");
    } else {
      appClasses.add("sidebar-menu-extended");
      overlayClasses.add("overlay-active");
    }
  };

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  render() {
    return (
      <div>
        <aside
          id="layout-menu"
          className={
            this.state.isOpen
              ? "layout-menu menu-vertical menu bg-menu-theme sidebar"
              : "layout-menu menu-vertical menu bg-menu-theme sidenavClosed"
          }
          style={{ height: "100%" }}
        >
          <div className="app-brand demo">
            <Link to="/dashboard" className="app-brand-link">
              <span className="app-brand-logo demo">
                <div
                  style={{
                    borderRadius: "60%",
                    backgroundColor: "#fff",
                    height: "42px",
                    width: "42px",
                  }}
                >
                  <img
                    src={logo}
                    className="img-fluid"
                    alt=""
                    // width={17}
                    style={{
                      marginLeft: "6px",
                      paddingTop: "5px",
                      width: "30px",
                    }}
                    data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                    data-app-light-img="illustrations/girl-with-laptop-light.png"
                  />
                </div>
              </span>
              <span
                className="app-brand-text demo menu-text fw-bolder ms-2"
                style={{
                  textTransform: "capitalize",
                  fontSize: "23px",
                  fontFamily: "Poppins",
                }}
              >
                AssetTrackifier
              </span>
            </Link>
            <div
              className="layout-menu-toggle menu-link text-large ms-auto"
              style={{ left: this.state.isOpen ? "15rem" : "5.1rem" }}
              onClick={this.toggleSidebar}
            >
              <i
                className="bx bx-chevron-left bx-sm align-middle"
                style={{
                  transform: this.state.isOpen
                    ? "rotatey(0deg)"
                    : "rotatey(180deg)",
                }}
              />
            </div>
          </div>
          <div className="menu-inner-shadow" />
          <ul className="menu-inner py-1">
            {this.state.menuItems
              .sort((a, b) => a.displaysequence - b.displaysequence)
              .map((m, key) => {
                if (!this.isEmpty(m)) {
                  let isactive = false;
                  if (m.id === this.state.activeMenu) {
                    isactive = true;
                  }
                  return m.isactive === true ? (
                    <Link
                      key={key}
                      to={m.pageid}
                      onClick={this.selectedChildMenu.bind(this, m.id)}
                      // className="menu-item"
                      style={{ width: this.state.isOpen ? "250px" : "90px" }}
                      // activeclassname="active open"
                      className={
                        isactive === true ? "menu-item active" : "menu-item"
                      }
                    >
                      <Tooltip title={m.uiname}>
                        <div className="menu-link">
                          <i
                            className={`menu-icon tf-icons ${m.icon}`}
                            style={{ fontSize: "22px" }}
                          />
                          {this.state.isOpen ? (
                            <div className="link_text text-start">
                              {m.uiname}
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      </Tooltip>
                    </Link>
                  ) : (
                    <div></div>
                  );
                }
              })}
          </ul>
        </aside>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { activeMenu: state.menus.activeMenu };
};
export default connect(mapStateToProps)(Sidebar);
