import React, { Component } from 'react'
import { withRouter } from "../../../withRouter";
import { MenuWithBreadScrum } from "../../menu/MenuWithBreadScrum";
import { NavLink } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import ReactApexCharts from 'react-apexcharts';
import store from '../../../redux/store';

class CustomerReportAnalysis extends Component {
	header = store.getState().header.header;
	breadCrumb = [
		{ pageid: "/reports", uiname: "MIS Reports" },
		{ pageid: "", uiname: "Customer Analytics" }
	];

	constructor() {
		super();
		this.state = {
			render: false,
			activeCustomers: 0,
			inActiveCustomers: 0,
			series: null,
			options: null,
			registeredCustomersSeries: null,
			registeredCustomersOptions: null,
			customerOptions: null,
			customerSeries: null,
			customerChartData: [],
		};
		this.getCustomerAnalyticsReport();
	}

	getCustomerAnalyticsReport() {
		const postObject = {
			header: this.header,
		};

		FetchServerData.callPostService('/costomerAnalyticms/get', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					let customerAnalysisDetails = output.data;
					let activeCustomers = customerAnalysisDetails.activeCustomers;
					let inActiveCustomers = customerAnalysisDetails.inActiveCustomers;
					let registeredCustomers = customerAnalysisDetails.registeredCustomers;
					let unregisteredCustomers = customerAnalysisDetails.unregisteredCustomers;
					let customerChartData = customerAnalysisDetails.customerCountList;
					let assetCycleLabels = [];
					let assetCycleValue = [];
					let assetValue = [];
					let missingAssetValue = [];
					let planValue = [];
					customerChartData.sort((a, b) => b.cycleCount - a.cycleCount)
					for (let i = 0; i < customerChartData.length; i++) {
						let date = customerChartData[i].customerName;
						assetCycleLabels.push(date);
						// if (customerChartData[i].assetCount > 0) {
						assetValue.push(customerChartData[i].assetCount);
						// }
						// if (customerChartData[i].missingAssetCount > 0) {
						missingAssetValue.push(customerChartData[i].missingAssetCount);
						// }
						// if (customerChartData[i].cycleCount > 0) {
						assetCycleValue.push(customerChartData[i].cycleCount);
						// }
						// if (customerChartData[i].planCount > 0) {
						planValue.push(customerChartData[i].planCount);
						// }
					}
					this.setState({
						render: true,
						customerChartData: customerChartData,
						chartData: customerAnalysisDetails.assetCycleList,
						series: [activeCustomers, inActiveCustomers],
						options: {
							colors: ['rgb(0, 227, 150)', '#F44336',],
							labels: ["Active Customers", "InActive Customers"],
							plotOptions: {
								pie: {
									donut: {
										labels: {
											show: true,
											total: {
												show: true,
												showAlways: true,
												fontSize: "18px",
												color: "black"
											}
										},
									}
								}
							},
							chart: {
								type: 'donut',
							},

							responsive: [{
								breakpoint: 480,
								options: {
									chart: {
										width: 200
									},
									legend: {
										position: 'bottom'
									}
								}
							}],
						},
						registeredCustomersSeries: [registeredCustomers, unregisteredCustomers],
						registeredCustomersOptions: {
							colors: ['rgb(0, 227, 150)', '#FF9800',],
							labels: ["Registered Customers", "UnRegistered Customers"],
							chart: {
								type: 'pie',
							},

							responsive: [{
								breakpoint: 480,
								options: {
									chart: {
										width: 200
									},
									legend: {
										position: 'bottom'
									}
								}
							}],
						},
						customerSeries: [
							{
								name: 'Asset Cycles',
								type: 'bar',
								data: assetCycleValue,
								marginTop: "10px"
							},
							{
								name: 'Plans',
								type: 'bar',
								data: planValue,
							},
							{
								name: 'Assets',
								type: 'bar',
								data: assetValue,
							},
							{
								name: 'Missing Assets',
								type: 'bar',
								data: missingAssetValue,
							},
						],
						customerOptions: {
							chart: {
								type: 'bar',
								height: 430,
								toolbar: {
									show: true,
									tools: {
										download: false
									}
								}
							},
							legend: {
								position: 'top'
							},
							colors: ['#2E93fA', '#FF9800', "rgb(0, 227, 150)", "#ff3e1d"],
							plotOptions: {
								bar: {
									horizontal: false,
									dataLabels: {
										position: 'top',
									},
								}
							},
							grid: {
								show: true,
								xaxis: {
									lines: {
										show: false
									}
								},
								yaxis: {
									lines: {
										show: false
									}
								},
							},
							dataLabels: {
								hideOverlappingLabels: true,
								enabled: true,
								// offsetX: -6,
								style: {
									fontSize: '12px',
									colors: ['#fff'],
								}
							},
							stroke: {
								show: true,
								width: 1,
								colors: ['#fff']
							},
							tooltip: {
								shared: true,
								intersect: false
							},
							xaxis: {
								categories: assetCycleLabels,
								labels: {
									style: {
										colors: '#247BA0',
									},
								},
								title: {
									text: 'Customers',
									style: {
										color: '#247BA0',
										fontSize: "15px"
									},
								},
								axisTicks: {
									show: true,
								},
								axisBorder: {
									show: true,
									color: '#247BA0',
								},
							},
							yaxis: [
								{
									axisTicks: {
										show: true,
									},
									axisBorder: {
										show: true,
										color: '#247BA0',
									},

									labels: {
										formatter: function (val) {
											return val.toFixed(0);
										},
										style: {
											colors: '#247BA0',
										},
									},

								},
							],
						},
					});
				} else {
				};
			});
	};
	render() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<>
					<MenuWithBreadScrum
						breadCrumb={this.breadCrumb}
						navigate={this.props.navigate}
						domainObject="Customer Analytics"
						name="Customer Analytics"
					/>
					{this.state.customerChartData.length > 0 ? (
						<>
							<div className="row">
								<div className="col-md-6">
									<div className="card">
										<div className="card-body">
											<div className="row mb-12 row d-flex justify-content-around">
												<h5 className='text-start pb-4'>Customer Status</h5>
												<div className="text-start" id="chart" style={{ padding: "1em" }}>
													{this.state.options !== null && this.state.series !== null ?
														<ReactApexCharts options={this.state.options} series={this.state.series} type="donut" height={250} />
														: ""
													}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card">
										<div className="card-body">
											<div className="row mb-12 row d-flex">
												<h5 className='text-start pb-4'>Customer Type</h5>
												<div className="text-start" id="chart" style={{ padding: "1em" }}>
													{this.state.registeredCustomersOptions !== null && this.state.registeredCustomersSeries !== null ?
														<ReactApexCharts options={this.state.registeredCustomersOptions} series={this.state.registeredCustomersSeries} type="pie" height={250} />
														: ""
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-md-12">
									<div className="card">
										<div className="card-body">
											<div className="row mb-12 row justify-content-end">
												<div className="col-sm-3 m-2 text-end">
													<NavLink className="navLink text-decoration-underline" to="/customerAssets">
														Customer Assets
													</NavLink>
													&nbsp; &nbsp;&nbsp;
													<NavLink className="navLink text-decoration-underline" to="/customerReportList">
														Customer List
													</NavLink>
												</div>
											</div >
											<div className="row mb-12 row d-flex align-items-end overflow-auto">
												<h5 className='text-start pb-1'>Customers vs. Assets</h5>
												{/* <div className="text-start overflow-auto " id="chart" style={{ padding: "1em", height: Math.max(300, 50 * this.state.customerChartData.length) + "px" }}> */}
												<div className="text-start overflow-auto " id="chart" style={{ padding: "1em", overflowX: 'auto', }}>
													{/* <div className="text-start overflow-auto " id="chart" style={{ padding: "1em" }}> */}
													{
														this.state.customerOptions !== null && this.state.customerSeries !== null ?
															< ReactApexCharts
																options={this.state.customerOptions}
																series={this.state.customerSeries}
																type="bar"
																height={450}
																width={200 * Math.max(this.state.customerChartData.length)}
															/>
															: ""
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<div className="row" style={{ marginTop: "1em" }}>
							<div className="col-md-12">
								<Card>
									<CardBody>
										<div className="row">
											<div className="col-md-12" style={{ padding: "2em" }}>
												<h5>
													<b>Customer analytics data not found</b>
												</h5>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					)
					}
				</>
			)
		}
	}
}
export default withRouter(CustomerReportAnalysis);