import { GET_USER_DATA, SET_USER_DATA } from "./AppUserDataTypes";

const getUserData = () => {
  return {
    type: GET_USER_DATA,
  };
};

const setUserData = (data) => {
  return {
    type: SET_USER_DATA,
    payload: data,
  };
};
const AppUserDataAction = { getUserData, setUserData };

export default AppUserDataAction;
