import { GET_App_USER_ROLE, SET_App_USER_ROLE, SET_ACTIVE_MENU } from "./AppUserRoleTypes";

const getAppUserRole = () => {
  return {
    type: GET_App_USER_ROLE,
  };
};
const setAppUserRole = (data) => {
  return {
    type: SET_App_USER_ROLE,
    payload: data,
  };
};
export const setAdminActiveMenu = (menuid) => {
  return {
    type: SET_ACTIVE_MENU,
    payload: menuid,
  };
};
const AppUserRoleAction = { getAppUserRole, setAppUserRole, setAdminActiveMenu };
export default AppUserRoleAction;
