import { GET_ASSET_STATIC_DATA, SET_ASSET_STATIC_DATA } from "./AssetStaticDataTypes";

const initialState = {
  assetCodeData: {},
};

const AssetStaticDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSET_STATIC_DATA:
      return {
        ...state,
      };
    case SET_ASSET_STATIC_DATA:
      return {
        ...state,
        assetCodeData: action.payload,
      };

    default:
      return state;
  }
};

export default AssetStaticDataReducer;
