import React, { Component } from 'react'
import { withRouter } from "../../withRouter";
import Utils from "../../provider/Utils";
import TextField from "@material-ui/core/TextField";
import AddressWithTimezone from "../addressWithTimezone/AddressWithTimezone";
import { ValidatorForm } from 'react-material-ui-form-validator';
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
// import { Autocomplete } from '@mui/material';
import { InputAdornment } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ToggleButton from '@mui/material/ToggleButton';
import SaveButton from '../buttons/SaveButton';
import BackButton from '../buttons/BackButton';
import CancelIconButton from '../buttons/CancelIconButton';
import FetchServerData from '../../provider/FetchServerData';
import PtsAlert from '../ptsAlert/PtsAlert';
import store from '../../redux/store';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import "./Supplier.css"
import CreateButton from '../buttons/CreateButton';
class Supplier extends Component {
  header = store.getState().header.header;
  supplierTypeList = store.getState().staticData.supplierTypeList;
  addressRef = React.createRef();
  breadCrumb = [
    { pageid: "/supplierList", uiname: "Supplier List" },
    { pageid: "", uiname: "Supplier Details" }
  ];
  constructor(props) {
    super(props);
    let action = props.action;
    let supplierId = props.supplierId;
    if (!action && props.state) {
      action = props.state.action;
      supplierId = props.state.supplierId;
    };
    if (action && (Utils.equalsIgnoreCase(action, "view" || Utils.equalsIgnoreCase(action, "update")))) {
      this.getSupplierDetails(supplierId);
    };
    this.state = {
      action: action,
      supplierId: supplierId,
      name: "",
      panNo: "",
      gstn: "",
      email: "",
      phone: "",
      description: "",
      address: {},
      supplierType: "",
      supplierTypeId: null,
      isStatus: true,
      render: false,
      getUpdatePermission: false,
      getDeletePermission: false,
    };
  };
  getSupplierDetails(supplierId) {
    const postObject = {
      header: this.header,
      supplier: {
        id: supplierId,
      },
    };

    FetchServerData.callPostService('/supplierms/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let supplierDetails = output.data.supplier;
          let getPermission = Utils.getRolePermission(output.data.privilegeList);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let supplierStatus = '';
          if (supplierStatus === null) {
            supplierStatus = false;
          } else {
            if (supplierDetails.supStatus?.status === 'Active') {
              supplierStatus = true;
            } else {
              supplierStatus = false;
            };
          };
          this.setState({
            render: true,
            action: "view",
            id: supplierDetails.id,
            name: supplierDetails.name,
            panNo: supplierDetails.pan,
            gstn: supplierDetails.gstn,
            email: supplierDetails.email,
            phone: supplierDetails.phone,
            description: supplierDetails.description,
            address: supplierDetails.address,
            supplierType: supplierDetails.suppliertype,
            supplierTypeId: supplierDetails.suppliertype.id,
            isStatus: supplierStatus,
            supplierStatus: supplierDetails.supStatus?.status,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        } else {
        };
      });
  };
  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  };
  viewCancel() {
    this.props.navigate("/supplierList");
  };
  handleSupplierTypeChange(e) {
    this.setState({
      supplierType: e.target.value === 1 ? "Registered" : "UnRegistered",
      supplierTypeId: e.target.value,
    })
  };
  handleNameChange(e) {
    this.setState({ name: e.target.value })
  };
  handleGstnChange(e) {
    if (e.target.value.length === 15) {
      this.setState({ panNo: e.target.value?.toUpperCase().substring(2, 12) })
    }
    this.setState({ gstn: e.target.value?.toUpperCase() })
  };
  
  handlePanNoChange(e) {
    let res = e.target.value;
    if (res.length <= 10) {
      this.setState({ panNo: res });
    } else {
      Utils.maxFieldLength(10);
    };
  };
  handleEmailChange(e) {
    this.setState({ email: e.target.value })
  };
  handlePhoneChange(e) {
    let res = e.target.value;
    if (res.length <= 10) {
      this.setState({ phone: res });
    };
  };
  handleDescriptionChange(e) {
    this.setState({ description: e.target.value })
  };
  handleAddressChange(address) {
    this.setState({ address: address });
  };
  createSupplierToBackend() {
    if (!Utils.checkPanLength(this.state.panNo)) {
      return
    };

    if (this.state.gstn.length > 0) {
      if (!Utils.checkGstnLength(this.state.gstn)) {
        return
      }
    }
    const postObject = {
      header: this.header,
      supplier: {
        name: this.state.name,
        gstn: this.state.gstn,
        email: this.state.email,
        phone: this.state.phone,
        pan: this.state.panNo,
        description: this.state.description,
        address: {
          line: this.addressRef.current.state.line,
          state: {
            name: this.addressRef.current.state.state.name,
            code: this.addressRef.current.state.state.code,
            id: this.addressRef.current.state.state.id,
            country: this.addressRef.current.state.country,
          },
          city: this.addressRef.current.state.city,
          zipcode: this.addressRef.current.state.zipCode,
          latitude: this.addressRef.current.state.latitude,
          longitude: this.addressRef.current.state.longitude,
        },
        suppliertype: {
          id: this.state.supplierTypeId,
          type: this.state.supplierType
        },
      },
    };
    FetchServerData.callPostService('/supplierms/create', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let supplierData = output.data.supplier;
        this.getSupplierDetails(supplierData.id);
        this.setState({
          action: "view",
        })
      } else {
      };
    });
  };
  createCancel() {
    this.props.navigate("/supplierList")
  };

  editSupplierDetails(e) {
    this.setState({ action: "edit", supplierId: this.state.id, })
  };
  updateSupplierToBackend() {
    let supStatus;
    if (this.state.isStatus === true) {
      supStatus = "Active";
    } else {
      supStatus = "InActive";
    };
    if (!Utils.checkPanLength(this.state.panNo)) {
      return;
    };
    if (this.state.gstn.length > 0) {
      if (!Utils.checkGstnLength(this.state.gstn)) {
        return
      }
    }
    const postObject = {
      header: this.header,
      supplier: {
        name: this.state.name,
        email: this.state.email,
        gstn: this.state.gstn,
        phone: this.state.phone,
        pan: this.state.panNo,
        id: this.state.supplierId,
        description: this.state.description,
        address: {
          id: this.addressRef.current.state.id,
          line: this.addressRef.current.state.line,
          state: {
            name: this.addressRef.current.state.state.name,
            code: this.addressRef.current.state.state.code,
            id: this.addressRef.current.state.state.id,
            country: this.addressRef.current.state.country,
          },
          city: this.addressRef.current.state.city,
          zipcode: this.addressRef.current.state.zipCode,
          latitude: this.addressRef.current.state.latitude,
          longitude: this.addressRef.current.state.longitude,
        },
        suppliertype: {
          id: this.state.supplierTypeId,
          type: this.state.supplierType.type,
        },
        supStatus: {
          status: supStatus,
        }
      },
    };
    FetchServerData.callPostService('/supplierms/update', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.getSupplierDetails(this.state.supplierId);
        this.setState({
          action: "view",
        })
      } else {
      };
    });
  };

  editCancel() {
    this.getSupplierDetails(this.state.id)
    // this.setState({
    //   action: "view",
    // })
  };

  deleteSupplierToBackend() {
    const postObject = {
      header: this.header,
      supplier: {
        id: this.state.supplierId,
      },
    };
    FetchServerData.callPostService('/supplierms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.props.navigate('/supplierList');
        } else {
        };
      });
  };

  getGstn() {
    if (!this.state.gstn) {
      PtsAlert.error("Enter GSTN");
      return;
    }
    const postObject = {
      gstin: this.state.gstn,
      header: this.header,
    };
    FetchServerData.callPostService(
      "/staticms/findByGstn",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let gstnDetails = output.data.taxpayer;
        PtsAlert.success(output.message);
        this.setState({
          name: gstnDetails.legalname,
          state: gstnDetails.address?.state,
          address: gstnDetails.address,
        });
      } else {
      }
    });
  }

  render() {
    if (this.state.action === 'create') {
      return this.createSupplier();
    } else if (this.state.action === 'edit') {
      return this.editSupplier();
    } else if (this.state.action === 'view') {
      return this.viewSupplier();
    };
  };
  createSupplier() {

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="Supplier"
          name="Create Supplier"
        />
        <div className="row">
          <ValidatorForm className="text-start" ref="form" onSubmit={this.createSupplierToBackend.bind(this)}>
            <div className="row">
              <div className="col-md-12">
                <div className="card " id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Supplier Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        {/* <label className="form-label " htmlFor="collapsible-supplierType">
                          Type<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="supplierTypeList"
                          options={this.supplierTypeList}
                          value={this.state.supplierType}
                          defaultValue={this.state.supplierType}
                          getOptionLabel={(option) => option.type}
                          onChange={(event, value) => {
                            this.handleSupplierTypeChange(value);
                          }}
                          inputprops={{ style: { textTransform: "capitalize" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Type"
                              variant="outlined"
                              require
                            />
                          )}
                        /> */}
                        <FormControl variant="outlined" fullWidth size="small">
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                          >
                            Type<span className="asterisk"> *</span>
                          </label>
                          <Select
                            id="appointmentType"
                            value={this.state.supplierTypeId}
                            placeholder="Type *"
                            required
                            onChange={(e) => {
                              this.handleSupplierTypeChange(e);
                            }}
                          >
                            {this.supplierTypeList.map((event, keyIndex) => {
                              return (
                                <MenuItem key={keyIndex} value={event.id}>
                                  {event.type}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        {this.state.supplierType != null && this.state.supplierType === "Registered" ?
                          <div className="col-md-12" id="assetFormLabel">
                            <label className="form-label " htmlFor="collapsible-fullname">
                              GSTN<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              type="text"
                              required
                              className="textField"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="GSTN"
                              value={this.state.gstn}
                              onChange={this.handleGstnChange.bind(this)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="verifyGstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          :
                          <div className="col-md-12" id="assetFormLabel">
                            <label className="form-label " htmlFor="collapsible-fullname">
                              GSTN
                            </label>
                            <TextField
                              type="text"
                              className="textField mt-1"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="GSTN"
                              value={this.state.gstn}
                              onChange={this.handleGstnChange.bind(this)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="verifyGstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        }
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          Email<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="email"
                          className="textField"
                          fullWidth
                          id="basic-default-email"
                          variant="outlined"
                          placeholder="Email"
                          aria-label="Email"
                          aria-describedby="basic-icon-default-email2"
                          value={this.state.email}
                          onChange={this.handleEmailChange.bind(this)}
                          validators={["required", "isEmail"]}
                          errormessages={[
                            "This field is required",
                            "Email id is not valid",
                          ]}
                          required
                        />
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          Phone
                        </label>
                        <TextField
                          type="number"
                          className="textField mt-1"
                          fullWidth
                          id="basic-default-phone"
                          variant="outlined"
                          placeholder="Phone"
                          value={this.state.phone}
                          onChange={this.handlePhoneChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          Pan No<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-panNo"
                          variant="outlined"
                          placeholder="PAN NO"
                          value={this.state.panNo}
                          onChange={this.handlePanNoChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-description">
                          Description
                        </label>
                        <textarea
                          name="collapsible-address"
                          className="form-control"
                          style={{ color: 'black', font: "inherit", fontSize: "16px " }}
                          id="collapsible-address"
                          rows="4"
                          placeholder="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Address
                    </h5>
                  </div>
                  <div className="card-body">
                    <AddressWithTimezone
                      ref={this.addressRef}
                      onChange={this.handleAddressChange.bind(this)}
                      data={this.state.address}
                      action="create"
                      pageId="Geolocation"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-sm-12 mt-3 text-center">
                <CreateButton btnName="Create" type="submit" />
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.createCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div >
      </>
    )
  };
  viewSupplier() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject={this.state.name}
            name="Supplier Details"
            title="Supplier"
            editCallback={this.editSupplierDetails.bind(this)}
            deleteCallback={this.deleteSupplierToBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
          />
          <div className="row" style={{ padding: "1em", marginTop: "-12px" }}>
            <div className="card" id="organizationFormCard">
              <div className="row">
                <div
                  className="card-header d-flex align-items-center justify-content-between"
                  id="assetCardHeader"
                >
                  <h5 className="mb-0" id="headerLabel">
                    {this.state.name}
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row pt-1">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-supplierType"
                          id="assetFormLabel"
                        >
                          Type
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.supplierType.type}</span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Email
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              PAN NO
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>{this.state.panNo}</span>
                            </div>
                          </div>
                          <div className="row pt-2">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Phone
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>{this.state.phone}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          GSTN
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.gstn}</span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Status
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.supplierStatus}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row pt-2">
                        <label
                          className="col-sm-12 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Description
                        </label>
                        <br></br>
                        <div className="col-sm-12" id="OrganizationCol">
                          <span>{this.state.description}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div
                  className="card-header d-flex align-items-center justify-content-between"
                  id="assetCardHeader"
                >
                  <h5 className="mb-0" id="headerLabel">
                    Address
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-sm-12" id="OrganizationCol">
                          <span>{Utils.getAddressAsText(this.state.address)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div
                      className="card-header d-flex align-items-center justify-content-between"
                      id="assetCardHeader"
                      style={{ marginLeft: "-12px" }}
                    >
                      <h5 className="mb-0" id="headerLabel">
                        Geolocation
                      </h5>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="row">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Latitude
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>{this.state.address?.latitude}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row ">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Longitude
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>{this.state.address?.longitude}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <BackButton
                type="button"
                style={{ backgroundColor: '#162c50', color: '#fff' }}
                variant="contained"
                onClick={(e) => this.viewCancel()}
              >
                Back
              </BackButton>
            </div>
          </div>
        </>
      )
    }
  };
  editSupplier() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="Supplier"
          name="Edit Supplier"
        />
        <div className="row">
          <ValidatorForm className="text-start" ref="form" onSubmit={this.updateSupplierToBackend.bind(this)}>
            <div className="row">
              <div className="col-md-12">
                <div className="card " id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Supplier Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        {/* <label className="form-label " htmlFor="collapsible-supplierType">
                          Type<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="supplierTypeList"
                          options={this.supplierTypeList}
                          value={this.state.supplierType}
                          defaultValue={this.state.supplierType}
                          getOptionLabel={(option) => option.type}
                          onChange={(event, value) => {
                            this.handleSupplierTypeChange(value);
                          }}
                          inputprops={{ style: { textTransform: "capitalize" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Type "
                              variant="outlined"
                              required
                            />
                          )}
                        /> */}
                        <FormControl variant="outlined" fullWidth size="small">
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                          >
                            Type<span className="asterisk"> *</span>
                          </label>
                          <Select
                            id="appointmentType"
                            value={this.state.supplierTypeId}
                            placeholder="Type *"
                            required
                            onChange={(e) => {
                              this.handleSupplierTypeChange(e);
                            }}
                          >
                            {this.supplierTypeList.map((event, keyIndex) => {
                              return (
                                <MenuItem key={keyIndex} value={event.id}>
                                  {event.type}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        {this.state.supplierType != null && this.state.supplierType === "Registered" ?
                          <div className="col-md-12" id="assetFormLabel">
                            <label className="form-label " htmlFor="collapsible-fullname">
                              GSTN<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              required
                              type="text"
                              className="textField"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="GSTN"
                              value={this.state.gstn}
                              onChange={this.handleGstnChange.bind(this)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="verifyGstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          :
                          <div className="col-md-12" id="assetFormLabel">
                            <label className="form-label " htmlFor="collapsible-fullname">
                              GSTN
                            </label>
                            <TextField
                              type="text"
                              className="textField mt-1"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="GSTN"
                              value={this.state.gstn}
                              onChange={this.handleGstnChange.bind(this)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="verifyGstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        }
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          Email<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="email"
                          className="textField"
                          fullWidth
                          id="basic-default-email"
                          variant="outlined"
                          placeholder="Email"
                          aria-label="Email"
                          aria-describedby="basic-icon-default-email2"
                          value={this.state.email}
                          onChange={this.handleEmailChange.bind(this)}
                          validators={["required", "isEmail"]}
                          errormessages={[
                            "This field is required",
                            "Email id is not valid",
                          ]}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          Phone
                        </label>
                        <TextField
                          type="number"
                          className="textField"
                          fullWidth
                          id="basic-default-phone"
                          variant="outlined"
                          placeholder="Phone"
                          value={this.state.phone}
                          onChange={this.handlePhoneChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-fullname">
                          Pan No<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-panNo"
                          variant="outlined"
                          placeholder="PAN NO"
                          value={this.state.panNo}
                          onChange={this.handlePanNoChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-description">
                          Description
                        </label>
                        <textarea
                          name="collapsible-address"
                          className="form-control"
                          style={{ color: 'black', font: "inherit", fontSize: "16px " }}
                          id="collapsible-address"
                          rows="4"
                          placeholder="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label className="form-label " htmlFor="collapsible-email">
                        Status
                      </label>
                      <span className="ms-4">
                        <ToggleButton
                          value={this.state.isStatus ? "Active" : "InActive"}
                          className="ToggleButtonActive"
                          onChange={this.handleStatusChange.bind(this)}
                          style={this.state.isStatus ?
                            {
                              background: '#0B6F9D',
                              color: '#fff',
                              height: '33px',
                              width: "80px",
                              borderRadius: "5px",
                              textTransform: 'none',
                            } :
                            {
                              background: '#D7DBDD',
                              color: '#000',
                              height: '33px',
                              width: "80px",
                              borderRadius: "5px",
                              textTransform: 'none',
                            }
                          }
                        >
                          {this.state.isStatus ? "Active" : "InActive"}
                        </ToggleButton>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Address
                    </h5>
                  </div>
                  <div className="card-body">
                    <AddressWithTimezone
                      ref={this.addressRef}
                      onChange={this.handleAddressChange.bind(this)}
                      data={this.state.address}
                      action="edit"
                      pageId="Geolocation"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between custBut">
              <div className="col-sm-12 mt-3 text-center">
                <SaveButton id="saveButton" type="submit"> Save </SaveButton>
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.editCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div >
      </>
    )
  };
}
export default withRouter(Supplier);