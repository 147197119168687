import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import TextField from "@material-ui/core/TextField";
import AddressWithTimezone from "../addressWithTimezone/AddressWithTimezone";
import CancelIconButton from "../buttons/CancelIconButton";
import SaveButton from "../buttons/SaveButton";
import { ValidatorForm } from "react-material-ui-form-validator";
import FetchServerData from "../../provider/FetchServerData";
import PtsAlert from "../ptsAlert/PtsAlert";
import store from "../../redux/store";
import Utils from "../../provider/Utils";
import { Autocomplete } from "@mui/material";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import ToggleButton from "@mui/material/ToggleButton";
import Tooltip from "../../ui/tooltip/Tooltip";
import infoIcon from "../../../src/assets/images/infoIcon.png";

import "./CustomerLocation.css";
import BackButton from "../buttons/BackButton";
class CustomerLocation extends Component {
  addressRef = React.createRef();
  header = store.getState().header.header;
  cycleFrequencyList = store.getState().staticData.cycleFrequencyList;
  breadCrumb = [
    { pageid: "/customerList", uiname: "Customer List" },
    { pageid: "/customer", uiname: "Customer Details" },
    { pageid: "", uiname: "Customer Location Details" },
  ];
  constructor(props) {
    super(props);
    let action = "";
    let customerId = "";
    let locationId = "";
    let canRender = false;
    if (!locationId && props.state) {
      action = props.state.action;
      customerId = props.state.customerId;
      locationId = props.state.locationId;
    }
    if (null !== locationId && locationId > 0) {
      this.getCustomerLocation(locationId);
    } else {
      canRender = true;
    }
    this.state = {
      id: locationId,
      name: "",
      cycletime: "",
      locationStatus: "",
      holdingPeriod: "",
      cycletimeFrequency: null,
      transitTime: "",
      transitTimeFrequency: null,
      returnTransitTime: "",
      returnTransitTimeFrequency: null,
      holdingPeriodFrequency: null,
      render: canRender,
      customerId: customerId,
      action: action,
      address: {},
      getUpdatePermission: false,
      getDeletePermission: false,
      getCreatePermission: false,
      error: false,
    };
  }

  getCustomerLocation(locationId) {
    const postObject = {
      header: this.header,
      customerLocation: {
        id: locationId,
      },
    };

    FetchServerData.callPostService("/costomerLocationms/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let LocationDetails = output.data.customerLocation;

          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getCreatePermission = getPermission[1];
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let locationStatus = "";
          if (LocationDetails.status === null) {
            locationStatus = false;
          } else {
            if (LocationDetails.status.status === "Active") {
              locationStatus = true;
            } else {
              locationStatus = false;
            }
          }
          this.setState({
            render: true,
            action: "view",
            id: LocationDetails.id,
            name: LocationDetails.name,
            address: LocationDetails.address,
            cycletime: LocationDetails.cycletime,
            holdingPeriod: LocationDetails.holdingPeriod,
            locationStatus: locationStatus,
            customerLocationStatus: LocationDetails.status,
            cycletimeFrequency: LocationDetails.cycletimeFrequency,
            holdingPeriodFrequency: LocationDetails.holdingPeriodFrequency,
            transitTime: LocationDetails?.transittime,
            transitTimeFrequency: LocationDetails?.transittimeFrequency,
            returnTransitTime: LocationDetails?.returnTransittime,
            returnTransitTimeFrequency:
              LocationDetails?.returnTransittimeFrequency,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
            getCreatePermission: getCreatePermission,
          });
        } else {
        }
      }
    );
  }
  handleStatusChange(e) {
    this.setState({ locationStatus: !this.state.locationStatus });
  }
  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }
  handleAddressChange(address) {
    this.setState({ address: address });
  }

  vadidateCycleTime(
    cycletime,
    holdingPeriod,
    cycletimeFrequency,
    holdingPeriodFrequency
  ) {
    if (cycletimeFrequency !== null || holdingPeriodFrequency !== null) {
      if (
        cycletimeFrequency !== null &&
        cycletimeFrequency.frequency === "Days"
      ) {
        cycletime = cycletime * 24;
      }
      if (
        holdingPeriodFrequency !== null &&
        holdingPeriodFrequency.frequency === "Days"
      ) {
        holdingPeriod = holdingPeriod * 24;
      }
    }
    if (cycletime > holdingPeriod) {
      this.setState({ error: false });
    } else {
      this.setState({ error: true });
    }
  }

  handleTransitTimeChange(e) {
    // this.vadidateCycleTime(parseInt(e.target.value), this.state.holdingPeriod, this.state.cycletimeFrequency, this.state.holdingPeriodFrequency);
    this.setState({ transitTime: e.target.value });
  }

  handleTransitTimeFrequencyChange(selectedFrequency) {
    // this.state.cycletimeFrequency = selectedFrequency;
    // if (undefined !== this.onChange && null !== this.onChange) {
    //   this.onChange(this.state);
    // }
    // this.vadidateCycleTime(this.state.cycletime, this.state.holdingPeriod, selectedFrequency, this.state.holdingPeriodFrequency);
    this.setState({
      transitTimeFrequency: selectedFrequency,
    });
  }

  handleReturnTransitTimeChange(e) {
    this.setState({ returnTransitTime: e.target.value });
  }

  handleReturnTransitTimeFrequencyChange(selectedFrequency) {
    this.setState({
      returnTransitTimeFrequency: selectedFrequency,
    });
  }

  handleHoldingPeriodChange(e) {
    // this.vadidateCycleTime(this.state.cycletime, parseInt(e.target.value), this.state.cycletimeFrequency, this.state.holdingPeriodFrequency);
    this.setState({ holdingPeriod: e.target.value });
  }

  handleHoldingPeriodFrequencyChange(selectedFrequency) {
    // this.vadidateCycleTime(this.state.cycletime, this.state.holdingPeriod, this.state.cycletimeFrequency, selectedFrequency);
    this.setState({
      holdingPeriodFrequency: selectedFrequency,
    });
  }

  createCancel() {
    this.props.navigate("/customer", {
      state: {
        action: "view",
        customerId: this.state.customerId,
      },
    });
  }
  editCancel() {
    this.getCustomerLocation(this.state.id);
  }
  createCustomerLocationToBackend() {
    const postObject = {
      header: this.header,
      customerLocation: {
        name: this.state.name,
        customer: { id: this.state.customerId },
        transittime: this.state.transitTime,
        transittimeFrequency: this.state.transitTimeFrequency,
        returnTransittime: this.state.returnTransitTime,
        returnTransittimeFrequency: this.state.returnTransitTimeFrequency,
        holdingPeriod: this.state.holdingPeriod,
        holdingPeriodFrequency: this.state.holdingPeriodFrequency,
        address: {
          line: this.addressRef.current.state.line,
          state: {
            name: this.addressRef.current.state.state.name,
            code: this.addressRef.current.state.state.code,
            id: this.addressRef.current.state.state.id,
            country: this.addressRef.current.state.country,
          },
          city: this.addressRef.current.state.city,
          zipcode: this.addressRef.current.state.zipCode,
          latitude: this.addressRef.current.state.latitude,
          longitude: this.addressRef.current.state.longitude,
        },
      },
    };
    FetchServerData.callPostService(
      "/costomerLocationms/create",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        // let customerData = output.data.customer;
        this.props.navigate("/customer", {
          state: {
            action: "view",
            customerId: this.state.customerId,
            title: "Customer Details",
          },
        });
        //   this.props.navigate(0);
      } else {
      }
    });
  }

  updateCustomerLocationToBackend() {
    let locationStatusId = "";
    if (this.state.locationStatus === true) {
      locationStatusId = 1;
    } else {
      locationStatusId = 2;
    }
    const postObject = {
      header: this.header,
      customerLocation: {
        name: this.state.name,
        id: this.state.id,
        customer: { id: this.state.customerId },
        transittime: this.state.transitTime,
        transittimeFrequency: this.state.transitTimeFrequency,
        returnTransittime: this.state.returnTransitTime,
        returnTransittimeFrequency: this.state.returnTransitTimeFrequency,
        holdingPeriod: this.state.holdingPeriod,
        holdingPeriodFrequency: this.state.holdingPeriodFrequency,
        status: {
          status: this.state.locationStatus,
          id: locationStatusId,
        },
        address: {
          id: this.addressRef.current.state.id,
          line: this.addressRef.current.state.line,
          state: {
            name: this.addressRef.current.state.state.name,
            code: this.addressRef.current.state.state.code,
            id: this.addressRef.current.state.state.id,
            country: this.addressRef.current.state.country,
          },
          city: this.addressRef.current.state.city,
          zipcode: this.addressRef.current.state.zipCode,
          latitude: this.addressRef.current.state.latitude,
          longitude: this.addressRef.current.state.longitude,
        },
      },
    };
    FetchServerData.callPostService(
      "/costomerLocationms/update",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        let customerLocationData = output.data.customerLocation;
        this.getCustomerLocation(customerLocationData.id);
        this.setState({
          action: "view",
        });
      } else {
      }
    });
  }

  viewCancel() {
    this.props.navigate("/customer", {
      state: {
        action: "view",
        customerId: this.state.customerId,
        title: "Customer Details",
      },
    });
  }

  editCustomerToBackend(e, data) {
    this.setState({
      action: "edit",
      locationId: this.state.id,
      customerId: this.state.customerId,
    });
  }

  deleteCustomerToBackend() {
    const postObject = {
      header: this.header,
      customerLocation: {
        id: this.state.id,
      },
    };
    FetchServerData.callPostService(
      "/costomerLocationms/delete",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.props.navigate("/customer", {
          state: {
            action: "view",
            customerId: this.state.customerId,
            title: "Customer Details",
          },
        });
      } else {
      }
    });
  }
  render() {
    if (this.state.action === "create") {
      return this.createCustomerLocation();
    } else if (this.state.action === "edit") {
      return this.editCustomerLocation();
    } else if (this.state.action === "view") {
      return this.viewCustomerLocation();
    }
  }
  createCustomerLocation() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <ValidatorForm
          className="text-start"
          ref="form"
          onSubmit={this.createCustomerLocationToBackend.bind(this)}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="card " id="organizationFormCard">
                <div
                  className="card-header d-flex align-items-center justify-content-between"
                  id="assetCardHeader"
                >
                  <h5 className="mb-0" id="headerLabel">
                    Customer Location
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Location Name<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Name"
                        value={this.state.name}
                        onChange={this.handleNameChange.bind(this)}
                        required
                      />
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Transit Time<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Transit Time"
                        value={this.state.transitTime}
                        onChange={this.handleTransitTimeChange.bind(this)}
                        required
                      />
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Transit Time Frequency
                        <span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        id="customerType"
                        fullWidth
                        options={this.cycleFrequencyList}
                        value={this.state.transitTimeFrequency}
                        defaultValue={this.state.transitTimeFrequency}
                        getOptionLabel={(option) => option.frequency}
                        onChange={(event, value) => {
                          this.handleTransitTimeFrequencyChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Transit Time Frequency"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Return Transit Time<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Return Transit Time"
                        value={this.state.returnTransitTime}
                        onChange={this.handleReturnTransitTimeChange.bind(this)}
                        required
                      />
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Return Transit Time Frequency
                        <span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        id="customerType"
                        fullWidth
                        options={this.cycleFrequencyList}
                        value={this.state.returnTransitTimeFrequency}
                        defaultValue={this.state.transitTimeFrequency}
                        getOptionLabel={(option) => option.frequency}
                        onChange={(event, value) => {
                          this.handleReturnTransitTimeFrequencyChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Return Transit Time Frequency"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Holding Period<span className="asterisk"> *</span>
                        <Tooltip
                          placement="top"
                          title="Holding Period is the time asset held by Customer"
                          id="password-requirements-tooltip"
                        >
                          <img src={infoIcon} alt="" width="20" height="20" />
                        </Tooltip>
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Holding Period"
                        value={this.state.holdingPeriod}
                        onChange={this.handleHoldingPeriodChange.bind(this)}
                        required
                      />
                      {/* {this.state.error ? <span style={{ color: "red" }}>Holding period should be less than cycle time </span> : ""} */}
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Holding Period Frequency
                        <span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        id="customerType"
                        fullWidth
                        options={this.cycleFrequencyList}
                        value={this.state.holdingPeriodFrequency}
                        defaultValue={this.state.holdingPeriodFrequency}
                        getOptionLabel={(option) => option.frequency}
                        onChange={(event, value) => {
                          this.handleHoldingPeriodFrequencyChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Holding Period Frequency"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <AddressWithTimezone
                        ref={this.addressRef}
                        onChange={this.handleAddressChange.bind(this)}
                        data={this.state.address}
                        action="create"
                        pageId="Geolocation"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-sm-12 mt-3 text-center">
              <SaveButton id="saveButton" type="submit">
                {" "}
                Save{" "}
              </SaveButton>
              &nbsp;&nbsp;
              <CancelIconButton onClick={(e) => this.createCancel()} />
            </div>
          </div>
        </ValidatorForm>
      );
    }
  }
  editCustomerLocation() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <ValidatorForm
          className="text-start"
          ref="form"
          onSubmit={this.updateCustomerLocationToBackend.bind(this)}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="card " id="organizationFormCard">
                <div
                  className="card-header d-flex align-items-center justify-content-between"
                  id="assetCardHeader"
                >
                  <h5 className="mb-0" id="headerLabel">
                    Customer Location
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Location Name<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        type="text"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Name"
                        value={this.state.name}
                        onChange={this.handleNameChange.bind(this)}
                        required
                      />
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Transit Time<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Transit Time"
                        value={this.state.transitTime}
                        onChange={this.handleTransitTimeChange.bind(this)}
                        required
                      />
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Transit Time Frequency
                        <span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        id="customerType"
                        fullWidth
                        options={this.cycleFrequencyList}
                        value={this.state.transitTimeFrequency}
                        defaultValue={this.state.transitTimeFrequency}
                        getOptionLabel={(option) => option.frequency}
                        onChange={(event, value) => {
                          this.handleTransitTimeFrequencyChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Transit Time Frequency"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Return Transit Time<span className="asterisk"> *</span>
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Return Transit Time"
                        value={this.state.returnTransitTime}
                        onChange={this.handleReturnTransitTimeChange.bind(this)}
                        required
                      />
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Return Transit Time Frequency
                        <span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        id="customerType"
                        fullWidth
                        options={this.cycleFrequencyList}
                        value={this.state.returnTransitTimeFrequency}
                        defaultValue={this.state.transitTimeFrequency}
                        getOptionLabel={(option) => option.frequency}
                        onChange={(event, value) => {
                          this.handleReturnTransitTimeFrequencyChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Return Transit Time Frequency"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <div className="row" style={{ marginTop: "2em" }}>
                        <label
                          className="col-sm-3 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Status
                        </label>
                        <ToggleButton
                          value={
                            this.state.locationStatus ? "Active" : "InActive"
                          }
                          // className="ToggleButtonActive"
                          className={
                            this.state.locationStatus
                              ? "activeBtn"
                              : "InactiveBtn"
                          }
                          onChange={this.handleStatusChange.bind(this)}
                        >
                          {this.state.locationStatus ? "Active" : "InActive"}
                        </ToggleButton>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-fullname"
                      >
                        Holding Period<span className="asterisk"> *</span>
                        <Tooltip
                          placement="top"
                          title="Holding Period is the time asset held by Customer"
                          id="password-requirements-tooltip"
                        >
                          <img src={infoIcon} alt="" width="20" height="20" />
                        </Tooltip>
                      </label>
                      <TextField
                        type="number"
                        className="textField"
                        fullWidth
                        id="basic-default-name"
                        variant="outlined"
                        placeholder="Holding Period"
                        value={this.state.holdingPeriod}
                        onChange={this.handleHoldingPeriodChange.bind(this)}
                        required
                      />
                      {this.state.error ? (
                        <span style={{ color: "red" }}>
                          Holding period should be less than cycle time{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label"
                        htmlFor="collapsible-fullname"
                      >
                        Holding Period Frequency
                        <span className="asterisk"> *</span>
                      </label>
                      <Autocomplete
                        id="customerType"
                        fullWidth
                        options={this.cycleFrequencyList}
                        value={this.state.holdingPeriodFrequency}
                        defaultValue={this.state.holdingPeriodFrequency}
                        getOptionLabel={(option) => option.frequency}
                        onChange={(event, value) => {
                          this.handleHoldingPeriodFrequencyChange(value);
                        }}
                        inputprops={{
                          style: { textTransform: "capitalize" },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Holding Period Frequency"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <AddressWithTimezone
                        ref={this.addressRef}
                        onChange={this.handleAddressChange.bind(this)}
                        data={this.state.address}
                        action="edit"
                        pageId="Geolocation"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-sm-12 mt-3 text-center">
              <SaveButton id="saveButton" type="submit">
                {" "}
                Save{" "}
              </SaveButton>
              &nbsp;&nbsp;
              <CancelIconButton onClick={(e) => this.editCancel()} />
            </div>
          </div>
        </ValidatorForm>
      );
    }
  }

  viewCustomerLocation() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject="Customer"
            name="Customer Location Details"
            deleteCallback={this.deleteCustomerToBackend.bind(this)}
            editCallback={this.editCustomerToBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            pageId="AssetView"
            customerId={this.state.customerId}
          />
          <div className="row" style={{ padding: "1em", marginTop: "-12px" }}>
            <div className="card" id="organizationFormCard">
              <div className="row">
                <div
                  className="card-header d-flex align-items-center justify-content-between"
                  id="assetCardHeader"
                >
                  <h5 className="mb-0" id="headerLabel">
                    {this.state.name}
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-supplierType"
                          id="assetFormLabel"
                        >
                          Transit Time
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>
                            {this.state.transitTime !== null &&
                            this.state.transitTimeFrequency !== null
                              ? this.state.transitTime +
                                " " +
                                this.state.transitTimeFrequency.frequency
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <label
                          className="col-sm-6 col-form-label fw-bold"
                          htmlFor="basic-default-supplierType"
                          id="assetFormLabel"
                        >
                          Return Transit Time
                        </label>
                        <div className="col-sm-6" id="OrganizationCol">
                          <span>
                            {this.state.returnTransitTime !== null &&
                            this.state.returnTransitTimeFrequency !== null
                              ? this.state.returnTransitTime +
                                " " +
                                this.state.returnTransitTimeFrequency.frequency
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Holding Period
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>
                                {this.state.holdingPeriod !== null &&
                                this.state.holdingPeriodFrequency !== null
                                  ? this.state.holdingPeriod +
                                    " " +
                                    this.state.holdingPeriodFrequency.frequency
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Status
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>
                            {this.state.customerLocationStatus?.status}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="card-header d-flex align-items-center justify-content-between"
                  id="assetCardHeader"
                >
                  <h5 className="mb-0" id="headerLabel">
                    Address
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-sm-12" id="OrganizationCol">
                          <span>
                            {Utils.getAddressAsText(this.state.address)}
                          </span>
                        </div>
                        {this.state.address?.latitude &&
                        this.state.address?.longitude ? (
                          <div className="col-md-12 ps-3">
                            <div className="row text-start mt-2 d-flex">
                              <div className="col-md-3">
                                <span className="mb-0" id="headerLabel">
                                  Geolocation
                                </span>
                                &nbsp;&nbsp;
                                <span>
                                  {this.state.address?.latitude +
                                    " , " +
                                    this.state.address?.longitude}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <BackButton
                type="button"
                style={{ backgroundColor: "#162c50", color: "#fff" }}
                variant="contained"
                onClick={(e) => this.viewCancel()}
              >
                Back
              </BackButton>
            </div>
          </div>
        </>
      );
    }
  }
}

export default withRouter(CustomerLocation);
