import React, { Component } from "react";
import { withRouter } from "../../../withRouter";
import { Autocomplete } from "@mui/material";
import { MenuWithBreadScrum } from "../../menu/MenuWithBreadScrum";
import TextField from "@material-ui/core/TextField";
import FetchServerData from "../../../provider/FetchServerData";
import MaterialTable from "material-table";
import UiUtils from "../../../provider/UiUtils";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import store from "../../../redux/store";
import Utils from "../../../provider/Utils";
import PtsAlert from "../../ptsAlert/PtsAlert";

class SupplierReportAsset extends Component {
  header = store.getState().header.header;
  breadCrumb = [
    { pageid: "/reports", uiname: "MIS Reports" },
    { pageid: "/supplierAnalytics", uiname: "Supplier Analytics" },
    { pageid: "", uiname: "Supplier Asset" },
  ];

  constructor() {
    super();
    this.state = {
      pageSize: 10,
      supplier: null,
      supplierId: "",
      supplierList: [],
      supplierAssetList: [],
      activeAssetList: [],
      inActiveAssetList: [],
      inCycleAssetList: [],
      damagedAssetList: [],
      render: false,
      isPaging: false,
      supplierName: "",
    };
    this.getSupplierList();
  }

  getSupplierList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/supplierms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let supplierListArray = output.data.supplierList;
          this.setState({
            supplierList: supplierListArray,
          });
        } else {
        }
      }
    );
  }
  handleSupplierTypeChange(selectedSupplier) {
    this.setState({
      supplier: selectedSupplier,
      supplierId: selectedSupplier.id,
    });
  }

  generateSupplierAssets() {
    const postObject = {
      header: this.header,
      supplier: {
        id: this.state.supplierId,
      },
    };

    FetchServerData.callPostService("/misms/supplierAssets", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let supplierDetails = output.data;
          let supplierAssetList = supplierDetails.assetList;
          if (supplierAssetList.length === 0) {
            PtsAlert.error(`No assets for ${supplierDetails.supplier?.name}`);
          }
          let activeAssetList = [];
          let inActiveAssetList = [];
          let inCycleAssetList = [];
          let damagedAssetList = [];
          for (let i = 0; i < supplierAssetList.length; i++) {
            if (supplierAssetList[i].status === "Active") {
              let status = supplierAssetList[i].status;
              activeAssetList.push(status);
            }
            if (supplierAssetList[i].status === "InActive") {
              inActiveAssetList.push(supplierAssetList[i].status);
            }
            if (supplierAssetList[i].status === "InCycle") {
              inCycleAssetList.push(supplierAssetList[i].status);
            }
            if (supplierAssetList[i].status === "Damaged") {
              damagedAssetList.push(supplierAssetList[i].status);
            }
          }
          let isPaging = false;
          if (supplierAssetList.length > this.state.pageSize) {
            isPaging = true;
          }
          this.setState({
            render: true,
            isPaging: isPaging,
            activeAssetList: activeAssetList,
            inActiveAssetList: inActiveAssetList,
            inCycleAssetList: inCycleAssetList,
            damagedAssetList: damagedAssetList,
            supplierAssetList: supplierAssetList,
            supplierName: supplierDetails.supplier?.name,
          });
        } else {
        }
      }
    );
  }

  backButton() {
    this.props.navigate("/supplierAnalytics");
  }

  materialTableHedding() {
    return (
      <div className="row d-flex">
        <span
          className="text-start"
          style={{ fontSize: "17px", fontWeight: "700" }}
        >
          Asset List For
          {this.state.supplierName !== null
            ? ` ${this.state.supplierName} (${this.state.supplierAssetList?.length})`
            : ""}
        </span>
        <br></br>
        <span
          className="d-flex"
          style={{ fontSize: "14px", fontWeight: "500" }}
        >
          <p>{`Active: ${this.state.activeAssetList?.length},`}</p>
          <p className="ms-2">{`InActive: ${this.state.inActiveAssetList?.length},`}</p>
          <p className="ms-2">{`InCycle: ${this.state.inCycleAssetList?.length},`}</p>
          <p className="ms-2">{`Damaged: ${this.state.damagedAssetList?.length},`}</p>
        </span>
      </div>
    );
  }

  render() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          backCallback={this.backButton.bind(this)}
          domainObject="Supplier Asset"
          name="Supplier Assets"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-12 row d-flex">
                  <div className="col-md-4 mt-1" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-supplierType"
                    >
                      Select supplier <span className="asterisk"> *</span>
                    </label>
                    <Autocomplete
                      fullWidth
                      id="supplierTypeList"
                      options={this.state.supplierList}
                      value={this.state.supplier}
                      defaultValue={this.state.supplier}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleSupplierTypeChange(value);
                      }}
                      inputprops={{
                        style: { textTransform: "capitalize" },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Supplier"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-4 mt-2 pt-4">
                    <div className="row text-Start ps-2">
                      <button
                        style={{
                          width: "120px",
                          padding: "8px",
                          fontSize: "17px",
                          fontWeight: "600",
                          backgroundColor: "#0B6F9D",
                          color: "#fff",
                          border: "none",
                          borderRadius: "7px",
                          alignItem: "center",
                        }}
                        onClick={this.generateSupplierAssets.bind(this)}
                      >
                        List Assets
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            {this.state.supplierAssetList.length > 0 && (
              <>
                <div className="row pe-4">
                  <div className="col-md-12 text-end p-2">
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          variant="contained"
                          size="small"
                          id="printBtn"
                          style={{
                            background: "#0577ab",
                            color: "#fff",
                            padding: "7px",
                            width: "100px",
                          }}
                          startIcon={<PrintRoundedIcon />}
                        >
                          print
                        </Button>
                      )}
                      content={() => this.componentRef}
                    />
                  </div>
                </div>
                <div style={{ maxWidth: "100%" }}>
                  <MaterialTable
                    title={this.materialTableHedding()}
                    tableRef={(el) => (this.componentRef = el)}
                    icons={UiUtils.getMaterialTableIcons()}
                    localization={{
                      body: {
                        emptyDataSourceMessage: "Please add supplier",
                      },
                    }}
                    columns={[
                      {
                        title: "#",
                        render: (rowData) => rowData.tableData.id + 1,
                        width: "5%",
                        align: "left",
                        cellStyle: {
                          width: "6%",
                        },
                      },
                      {
                        title: "Asset Name",
                        field: "name",
                        cellStyle: {
                          width: "18%",
                        },
                      },
                      {
                        title: "Asset UID",
                        field: "uid",
                        cellStyle: {
                          width: "15%",
                        },
                      },
                      {
                        title: "Asset Code",
                        field: "assetcode",
                        cellStyle: {
                          width: "15%",
                        },
                      },
                      {
                        title: "Asset Type",
                        field: "assetType",
                        cellStyle: {
                          width: "15%",
                        },
                      },
                      {
                        title: "Description",
                        field: "description",
                        cellStyle: {
                          width: "25%",
                        },
                      },
                      {
                        title: "Status",
                        field: "status",
                      },
                    ]}
                    data={this.state.supplierAssetList}
                    options={{
                      sorting: true,
                      search: false,
                      actionsColumnIndex: -1,
                      initialPage: this.state.initialPage,
                      pageSize: this.state.pageSize,
                      pageSizeOptions: Utils.materialTablePageSize(),
                      paging: this.state.isPaging,
                      headerStyle: {
                        backgroundColor: "#0B6F9D",
                        fontWeight: "bold",
                        color: "#ffff",
                        align: "left",
                      },
                    }}
                    // actions={[
                    //   {
                    //     icon: () =>
                    //     (
                    //       <ReactToPrint
                    //         trigger={() => (
                    //           <Button
                    //             variant="contained"
                    //             size="small"
                    //             id="printBtn"
                    //             style={{ background: '#0577ab', color: "#fff", padding: "7px", width: "100px" }}
                    //             startIcon={<PrintRoundedIcon />}
                    //           >
                    //             print
                    //           </Button>
                    //         )}
                    //         content={() => this.componentRef}
                    //       />
                    //     ),
                    //     tooltip: 'Print',
                    //     isFreeAction: true,
                    //   },
                    // ]}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(SupplierReportAsset);
