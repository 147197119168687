import { Menu } from "@mui/material";
import React, { Component } from "react";
import "./Navbar.css";
import { withRouter } from "../../withRouter";
import FetchServerData from "../../provider/FetchServerData";
import HeaderAction from "../../redux/header/HeaderAction";
import StaticDataAction from "../../redux/staticData/StaticDataAction";
import store from "../../redux/store";
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import CatalogueAction from "../../redux/catalogue/CatalogueAction";
import ApplicationStateAction from "../../redux/applicationState/ApplicationStateAction";
import OrganizationAction from "../../redux/organization/OrganizationAction";
import UserRoleAction from "../../redux/userRole/UserRoleAction";
import CodeUomListAction from "../../redux/codeUomList/CodeUomListAction";
import CustomerAction from "../../redux/customer/CustomerAction";
import AppUserDataAction from "../../redux/appUserData/AppUserDataAction";
import MenuAction from "../../redux/menu/MenuAction";
import AssetAction from "../../redux/asset/AssetAction";
import TrackingHistoryAction from "../../redux/trackingHistory/TrackingHistoryAction";
import CategoryAction from "../../redux/category/CategoryAction";
import MenuIcon from "@mui/icons-material/Menu";
import ReturnablePlanAction from "../../redux/returnablePlan/ReturnablePlanAction";
import FilterDetailsAction from "../../redux/filterDetails/FilterDetailsAction";
import utils from "../../provider/Utils";

class Navbar extends Component {
  header = store.getState().header.header;
  organization = store.getState().organization.organization;
  userName = store.getState().appUserData.appUserData;
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  toggleSidebar = () => {
    let overlay = document.getElementById("overlay");

    // if (window.innerWidth > 1199) {
    //   overlay.removeEventListener("click", this.toggleSidebar);
    //   return;
    // }

    let loaderClasses = document.getElementById("pageLoader").classList;
    let loaderWithTextClasses =
      document.getElementById("loader-with-text").classList;
    if (
      loaderClasses.contains("show-loader") ||
      loaderWithTextClasses.contains("text-loader-show")
    ) {
      return;
    }

    overlay.addEventListener("click", this.toggleSidebar);

    let appClasses = document.getElementById("app").classList;
    let overlayClasses = overlay.classList;

    if (appClasses.contains("sidebar-menu-extended")) {
      appClasses.remove("sidebar-menu-extended");
      overlayClasses.remove("overlay-active");
    } else {
      appClasses.add("sidebar-menu-extended");
      overlayClasses.add("overlay-active");
    }
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };
  profile = () => {
    this.props.navigate("/profile");
  };
  logOut = () => {
    let postObject = {
      header: this.header,
      appuser: {
        email: this.header.email,
      },
    };
    FetchServerData.callPostService("/appuserms/logout", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          store.dispatch(StaticDataAction.setStaticData({}));
          store.dispatch(HeaderAction.setHeader({}));
          store.dispatch(CatalogueAction.setCatalogue({}));
          store.dispatch(ApplicationStateAction.setApplicationState({}));
          store.dispatch(OrganizationAction.setOrganization({}));
          store.dispatch(MenuAction.setMenu({}));
          store.dispatch(MenuAction.setActiveMenu({}));
          store.dispatch(UserRoleAction.setUserRole({}));
          store.dispatch(CodeUomListAction.setCodeUomList({}));
          store.dispatch(AppUserDataAction.setUserData({}));
          store.dispatch(CustomerAction.setCustomer({}));
          store.dispatch(CategoryAction.setCategory({}));
          store.dispatch(AssetAction.setAsset({}));
          store.dispatch(TrackingHistoryAction.setTrackingHistory({}));
          store.dispatch(ReturnablePlanAction.setReturnablePlan({}));
          store.dispatch(FilterDetailsAction.setFilterDetails({}));
          this.props.navigate("/signinEmail");
        } else {
        }
      }
    );
  };

  organizationInfo = () => {
    this.props.navigate("/settings");
  }

  render() {
    return (
      <section id="navbar">
        <nav
          className="layout-navbar navbar navbar-expand-lg fixed-top navbar-detached align-items-center bg-navbar-theme"
          style={{ width: "100%", margin: "0px" }}
          id="layout-navbar"
        >
          <div className="container">
            <div className="row align-items-start">
              <div className="col">
                {this.organization.organizationLogo ? (
                  <span>{utils.base64ToImage()}</span>
                ) : (
                  <div
                    className="logoinfodot blink_me"
                    onClick={this.organizationInfo.bind(this)}
                  >
                    <Tooltip placement="top"
                      title="Logo are uploaded by admin user in Settings"
                      id="password-requirements-tooltip">
                      <InfoIcon className="blink_me" style={{ fontSize: "26px", color: "#0B6F9D", cursor: "pointer" }} />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col">
                <span style={{ fontSize: "18px", color: "#0B6F9D", fontWeight: 650 }}>
                  {this.organization.organizationName}
                </span>
              </div>
            </div>
            <div className="row align-items-end">
              <div className="col">
                <span style={{ fontSize: "16px", color: "black" }}>
                  {this.userName.firstName} {this.userName.lastName}
                </span>{" "}
                &nbsp;
                <MenuIcon
                  // className="userNameInitial"
                  style={{
                    fontSize: "28px",
                    color: "black",
                    cursor: "pointer",
                  }}
                  aria-controls={this.state.open ? "dropdown-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.open ? "true" : undefined}
                  onClick={this.handleAccountDropdownOpen}
                />
              </div>
            </div>
          </div>
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <Menu
              anchorEl={this.state.anchorEl}
              id="dropdown-menu"
              open={this.state.open}
              onClose={this.handleAccountDropdownClose}
              onClick={this.handleAccountDropdownClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 20,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <li>
                <div
                  className="dropdown-item"
                  onClick={this.profile}
                  style={{ cursor: "pointer" }}
                >
                  <i className="bx bx-user-circle me-2"></i>
                  <span className="align-middle">Profile</span>
                </div>
              </li>

              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <div
                  className="dropdown-item"
                  onClick={this.logOut}
                  style={{ cursor: "pointer" }}
                >
                  <i className="bx bx-power-off me-2"></i>
                  <span className="align-middle">Log Out</span>
                </div>
              </li>
            </Menu>
            {/*/ User */}
          </ul>
        </nav>
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return { organization: state.organization.organization };
};
export default withRouter(connect(mapStateToProps)(Navbar));
// export default withRouter(Navbar);
