import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { Col, Row } from "reactstrap";
import store from "../../redux/store";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import PtsAlert from "../ptsAlert/PtsAlert";
import DeleteIcon from "@material-ui/icons/Delete";
class Profile extends Component {
  header = store.getState().header.header;
  userData = store.getState().appUserData.appUserData;
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: {},
      password: "",
      showPassword: false,
      authorizedCaregiver: false,
      confirmWindowOpen: false,
    };
    this.getProfile();
  }
  getProfile() {
    const postObject = {
      header: this.header,
      appuser: {
        id: this.userData.id,
        email: this.userData.email,
      },
    };
    FetchServerData.callPostService("/appuserms/profile", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let userDetails = output.data.appuser;
          let organizationDetails = output.data.organization;
          let userProfile = output.data.userRole;
          this.setState({
            render: true,
            id: userDetails.id,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
            phone: userDetails.phone,
            address: userDetails.address,
            organizationName: organizationDetails.name,
            organizationEmail: organizationDetails.email,
            organizationGSTN: organizationDetails.gstn,
            organizationPhone: organizationDetails.phone,
            organizationPan: organizationDetails.panno,
            organizationWebsite: organizationDetails.website,
            userProfile: userProfile,
          });
        } else {
        }
      }
    );
  }

  deleteProfileInBackend() {
    const postObject = {
      header: this.header,
      appuser: {
        credential: this.state.password,
      },
    };
    FetchServerData.callPostService(
      "/appuserms/deleteProfile",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.props.navigate("/");
      } else {
      }
    });
  }

  handleAuthorizedClose = () => {
    this.setState({ authorizedCaregiver: !this.state.authorizedCaregiver });
  };
  handleOpenAuthorized = () => {
    this.setState({ authorizedCaregiver: true });
  };

  toDeleteAuthorizedConfirmationWindow = () => {
    this.setState({ confirmWindowOpen: true, authorizedCaregiver: false });
  };

  handleConfirmWindowClose = () => {
    this.setState({ confirmWindowOpen: !this.state.confirmWindowOpen });
  };

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  render() {
    return (
      <div className="row" style={{ padding: "1em", marginTop: "-12px" }}>
        <div className="row mb-2">
          <div className="col text-end">
            <Button
              variant="contained"
              size="small"
              // type="submit"
              style={{
                background: "#005476",
                color: "#fff",
                textTransform: "capitalize",
              }}
              onClick={(e) => this.handleOpenAuthorized()}
            >
              <DeleteIcon
                className="delete"
                style={{
                  borderRadius: "25%",
                  color: "#fff",
                  fontSize: "x-large",
                  width: "30px",
                  minHeight: "30px",
                  padding: "2px",
                }}
              />
              Delete Profile
            </Button>
          </div>
        </div>
        <Dialog
          open={this.state.authorizedCaregiver}
          onClose={this.handleAuthorizedClose.bind(this)}
          onHide={this.close}
          style={{ padding: "1em" }}
        >
          <DialogContent>
            <Row style={{ marginRight: "auto", marginLeft: "auto" }}>
              <Col md={12} style={{ textAlign: "center" }}>
                <ErrorOutlineIcon
                  style={{
                    border: "2px solid #fff",
                    borderRadius: "25%",
                    background: "#066f72 !important",
                    color: "red",
                    fontSize: "50px",
                  }}
                />
              </Col>
            </Row>

            <span style={{ textAlign: "center" }}>
              <h2 style={{ fontSize: "17px", lineHeight: "1.3em" }}>
                You will no longer be part of Asset Trackifier anymore. Do you
                want to continue?
              </h2>
            </span>
          </DialogContent>
          <DialogActions
            style={{
              marginTop: "1em",
              padding: "2em",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Button
              variant="contained"
              size="large"
              style={{
                background: "#0B6F9D",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<SaveIcon />}
              onClick={(e) => this.toDeleteAuthorizedConfirmationWindow(e)}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<CloseIcon />}
              onClick={this.handleAuthorizedClose.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.confirmWindowOpen}
          onClose={this.handleConfirmWindowClose.bind(this)}
          onHide={this.close}
          style={{ padding: "1em" }}
        >
          <DialogContent style={{ marginTop: "2em" }}>
            <span style={{ textAlign: "center" }}>
              <h2 style={{ fontSize: "20px" }}>Please enter your Password </h2>
            </span>
            <Row className="mt-4">
              <Col md={12} style={{ marginLeft: "auto", marginRight: "auto" }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  placeholder="Enter Password *"
                  type={this.state.showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="off"
                  onChange={this.handlePasswordChange.bind(this)}
                  value={this.state.password}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword.bind(this)}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions
            style={{ padding: "2em", marginLeft: "auto", marginRight: "auto" }}
          >
            <Button
              variant="contained"
              size="large"
              style={{
                background: "#0B6F9D",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<SaveIcon />}
              onClick={(e) => this.deleteProfileInBackend(e)}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<CloseIcon />}
              onClick={this.handleConfirmWindowClose.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <div className="card" id="organizationFormCard">
          <div className="row">
            <div
              className="card-header d-flex align-items-center justify-content-between"
              id="assetCardHeader"
            >
              <h5 className="mb-0" id="headerLabel">
                User Profile Details
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="row pt-2">
                    <label
                      className="col-sm-4 col-form-label fw-bold"
                      for="basic-default-name"
                      id="assetFormLabel"
                    >
                      Name
                    </label>
                    <div className="col-sm-8" id="OrganizationCol">
                      <span>
                        {this.state.firstName} {this.state.lastName}
                      </span>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <label
                      className="col-sm-4 col-form-label fw-bold"
                      for="basic-default-name"
                      id="assetFormLabel"
                    >
                      Email
                    </label>
                    <div className="col-sm-8" id="OrganizationCol">
                      <span>{this.state.email}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          for="basic-default-name"
                          id="assetFormLabel"
                        >
                          Phone
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.phone}</span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          for="basic-default-name"
                          id="assetFormLabel"
                        >
                          Profile
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.userProfile?.name}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div
              className="card-header d-flex align-items-center justify-content-between"
              id="assetCardHeader"
            >
              <h5 className="mb-0" id="headerLabel">
                Company Details
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="row pt-2">
                    <label
                      className="col-sm-4 col-form-label fw-bold"
                      for="basic-default-name"
                      id="assetFormLabel"
                    >
                      GsTN
                    </label>
                    <div className="col-sm-8" id="OrganizationCol">
                      <span>{this.state.organizationGSTN}</span>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <label
                      className="col-sm-4 col-form-label fw-bold"
                      for="basic-default-name"
                      id="assetFormLabel"
                    >
                      PAN NO
                    </label>
                    <div className="col-sm-8" id="OrganizationCol">
                      <span>{this.state.organizationPan}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          for="basic-default-name"
                          id="assetFormLabel"
                        >
                          Website
                        </label>
                        {this.state.organizationWebsite ? (
                          <div className="col-sm-8" id="OrganizationCol">
                            <a
                              href={
                                this.state.organizationWebsite.includes(
                                  "https://"
                                ) ||
                                this.state.organizationWebsite.includes(
                                  "http://"
                                )
                                  ? this.state.organizationWebsite
                                  : "http://" + this.state.organizationWebsite
                              }
                              target="blank"
                            >
                              {this.state.organizationWebsite}
                            </a>
                          </div>
                        ) : (
                          <span style={{ display: "none" }}></span>
                        )}
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          for="basic-default-name"
                          id="assetFormLabel"
                        >
                          Phone
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.organizationPhone}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div
              className="card-header d-flex align-items-center justify-content-between"
              id="assetCardHeader"
            >
              <h5 className="mb-0" id="headerLabel">
                Address
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-sm-12" id="OrganizationCol">
                      <span>{Utils.getAddressAsText(this.state.address)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Profile);
