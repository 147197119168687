import React, { Component } from 'react'
import { withRouter } from "../../withRouter";
import { NavLink } from "react-router-dom";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import supplierAsset from "../../assets/images/supp1.png"
import AssetCycleAnalytics from "../../assets/images/assetcycleanalysis.png"

class MISReports extends Component {
  breadCrumb = [{ pageid: "", uiname: "MIS Reports Details" }];

  render() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="MIS Reports"
          name="MIS Reports Details"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card" id="organizationFormCard">
              <div className="card-body pb-1">
                <div className="row mb-12 row justify-content-start">
                  <div className='row justify-content-around'>
                    <div className="col-sm-4 mt-2">
                      <NavLink to="/customerAnalytics">
                        <div className="card card-body" style={{ backgroundColor: "#0B6F9D", padding: "14px" }}>
                          <div className="row align-items-center" style={{ margin: "0px" }}>
                            <div className="col-sm-3">
                              <i className="fa fa-users fa-2x" aria-hidden="true" style={{ color: "#fff", fontSize: "24px" }}></i>
                            </div>
                            <div className="col-sm-9 text-start">
                              <p className="dashboardCardText">Customer Analytics</p>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </div>

                    <div className="col-sm-4 mt-2">
                      <NavLink to="/supplierAnalytics">
                        <div className="card card-body" style={{ backgroundColor: "#0B6F9D", padding: "14px" }}>
                          <div className="row align-items-center" style={{ margin: "0px" }}>
                            <div className="col-sm-3">
                              <img alt="" src={supplierAsset} style={{ width: "34px", height: "33px", fontSize: "24px" }} />
                            </div>
                            <div className="col-sm-9 text-start">
                              <p className="dashboardCardText">Supplier Analytics</p>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </div>

                  </div>
                  <div className='row justify-content-around mt-3'>
                    <div className="col-sm-4 mt-2">
                      <NavLink to="/assetAnalytics">
                        <div className="card card-body" style={{ backgroundColor: "#0B6F9D", padding: "14px" }}>
                          <div className="row align-items-center" style={{ margin: "0px" }}>
                            <div className="col-sm-3">
                              <i className="fas fa-box-open" aria-hidden="true" style={{ color: "#fff", fontSize: "24px" }}></i>
                            </div>
                            <div className="col-sm-9 text-start">
                              <p className="dashboardCardText">Asset Analytics</p>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </div>

                    <div className="col-sm-4 mt-2">
                      <NavLink to="/assetCycleAnalytics">
                        <div className="card card-body" style={{ backgroundColor: "#0B6F9D", padding: "11px" }}>
                          <div className="row align-items-center" style={{ margin: "0px" }}>
                            <div className="col-sm-3">
                              <img alt="" src={AssetCycleAnalytics} style={{ width: "30px", height: "30px", fontSize: "24px", color: "#fff" }} />
                            </div>
                            <div className="col-sm-9 text-start p-1">
                              <p className="dashboardCardText">Asset Cycle Analytics</p>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className='row justify-content-around mt-3'>
                    <div className="col-sm-4 mt-2">
                      <NavLink to="/organizationAssets">
                        <div className="card card-body" style={{ backgroundColor: "#0B6F9D", padding: "14px" }}>
                          <div className="row align-items-center" style={{ margin: "0px" }}>
                            <div className="col-sm-3">
                              {/* <i className="fa fa-building-o fa-2x" aria-hidden="true" style={{ color: "#fff" }}></i> */}
                              <i class="fa-solid fa-cubes fa-2x" aria-hidden="true" style={{ color: "#fff" }}></i>
                            </div>
                            <div className="col-sm-9 text-start">
                              <p className="dashboardCardText">Asset Stock Analytics</p>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(MISReports);