import MaterialTable from "material-table";
import moment from "moment";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { withRouter } from "../../withRouter";
import UiUtils from "../../provider/UiUtils";
import Utils from "../../provider/Utils";
import store from "../../redux/store";
import AdminMenuWithBreadScrum from "../adminMenu/AdminMenuWithBreadScrum";
import AdminFetchServerData from '../../provider/AdminFetchServerData';
import ApplicationStateAction from "../../redux/applicationState/ApplicationStateAction";
import PtsButton from "../../component/buttons/PtsButton";

class TermsConditionTemplateList extends Component {
  title = "Terms And Conditions Template List";
  breadCrumb = [{ pageid: "", uiname: "Terms And Conditions Template List" }];
  header = store.getState().adminHeader.adminHeader;
  termsConditionState = store.getState().applicationState.termsConditionState;
  termsConditionTemplateDetails = {};
  constructor(props) {
    super(props);
    if (this.termsConditionState) {
      this.termsConditionTemplateDetails = Object.getOwnPropertyNames(
        this.termsConditionState
      );
    }
    if (this.termsConditionTemplateDetails.length > 0) {
      this.state = {
        termsConditionTemplateList: [],
        isPaging: false,
        initialPage:
          this.termsConditionState.initialPage &&
            this.termsConditionState.initialPage > 0
            ? this.termsConditionState.initialPage
            : 0,
        pageSize:
          this.termsConditionState.pageSize && this.termsConditionState.pageSize
            ? this.termsConditionState.pageSize
            : 0,
        termsConditionTemplateId:
          this.termsConditionState.termsConditionTemplateId,
        render: false,
      };
    } else {
      this.state = {
        termsConditionTemplateList: [],
        isPaging: false,
        initialPage: 0,
        pageSize: 10,
        termsConditionTemplateId: "",
        render: false,
      };
    }
    this.getEmailTemplateList();
  }
  getEmailTemplateList() {
    const postObject = {
      header: this.header,
    };

    AdminFetchServerData.callPostService(
      "/apTermsConditions/getList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let termsConditionTemplateList = output.data.termsConditionsList;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (termsConditionTemplateList.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          termsConditionTemplateList: termsConditionTemplateList,
          render: true,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }
  toTermsConditionTemplateDetails(e, data) {
    let termsConditionState = {
      termsConditionTemplateId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ termsConditionState })
    );
    this.props.navigate("/viewTermsCondition", {
      state: {
        action: "view",
        title: "Terms Conditions Template Details",
        termsConditionTemplateId: data.id,
        pageId: "termsConditionList",
      },
    });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  addTermsCondition(e) {
    this.props.navigate("/addTermsCondition", {
      state: {
        action: "create",
        title: "Add Terms Conditions",
        // termsConditionTemplateId: "",
        pageId: "termsConditionList",
      },
    });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    }
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <Row
          style={{ position: "relative", margin: "1em 0em", maxWidth: "100%" }}
        >
          <Col md={12}>
            <MaterialTable
              onRowClick={(event, rowData) => {
                this.toTermsConditionTemplateDetails(event, rowData);
                event.stopPropagation();
              }}
              icons={UiUtils.getMaterialTableIcons()}
              title=""
              localization={{
                body: {
                  emptyDataSourceMessage: "Please add Terms & Conditon",
                },
              }}
              columns={[
                {
                  title: "#",
                  render: (rowData) => rowData.tableData.id + 1,

                  width: "5%",
                  align: "left",
                },
                {
                  title: "Version",
                  field: "version",
                  width: "5%",
                  filtering: false,
                },
                {
                  title: "created Name",
                  field: "createdbyname",
                  width: "20%",
                  filtering: false,
                },
                {
                  title: "Updated Name",
                  field: "updatedbyname",
                  width: "20%",
                  filtering: false,
                },
                {
                  title: "Created Date",
                  render: (rowData) =>
                    moment(rowData.createdon).format("DD-MMM-YYYY"),
                  // field: 'createdon',
                  width: "25%",
                },
                {
                  title: "Updated Date",
                  render: (rowData) =>
                    rowData.updatedon
                      ? moment(rowData.updatedon).format("DD-MMM-YYYY")
                      : "",
                  // field: 'createdon',
                  width: "25%",
                },
                {
                  title: "Status",
                  render: (rowData) =>
                    rowData.isactive === true ? "Active" : "InActive",
                  width: "30%",
                  // cellStyle: { textTransform: 'none' },
                },
              ]}
              data={this.state.termsConditionTemplateList}
              onPageChange={(page, pageSize) => {
                this.handleMaterialTablePageChange(page, pageSize);
              }}
              options={{
                sorting: true,
                actionsColumnIndex: -1,
                initialPage: this.state.initialPage,
                pageSize: this.state.pageSize,
                headerStyle: {
                  backgroundColor: "#0B6F9D",
                  fontWeight: "bold",
                  color: "#fff",
                  align: "left",
                },
                pageSizeOptions: Utils.materialTablePageSize(),
                filtering: false,
                paging: this.state.isPaging,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.id === this.state.termsConditionTemplateId
                      ? "#FEF5E7"
                      : "#fff",
                  height: "3em",
                  textTransform: "capitalize",
                }),

                tableLayout: "auto",
              }}
              actions={[
                {
                  icon: () => (
                    this.state.getCreatePermission && (
                      <span className="Add T&C Template">
                        {' '}
                        <PtsButton btnName="Add T&C Template" type="submit" />
                      </span>
                    )
                  ),
                  tooltip: "Add Terms Conditions Template",
                  isFreeAction: true,
                  onClick: (event) => this.addTermsCondition(),
                },
              ]}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(TermsConditionTemplateList);
