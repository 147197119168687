import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import DeleteIconButton from "../../buttons/DeleteIconButton";
import EditIconButton from "../../buttons/EditIconButton";
import store from "../../../redux/store";
import SaveButtons from "../../buttons/SaveButtons";
import CancelButtons from "../../buttons/CancelButtons";

class AssetCodeMaster extends Component {
  custAbbreviationList =
    store.getState().assetStaticData.assetCodeData?.customerList;
  materialTypeList =
    store.getState().assetStaticData.assetCodeData?.materialTypeList;
  constructor(props) {
    super(props);
    let action = props.action;
    let data = props.data;

    this.state = {
      action: action,
      id: data.id,
      custAbbreviation: data.custAbbreviation,
      materialtype: data.materialtype,
      pocketcode: data.pocketcode,
      assetcode: props.data.code,
      description: data.description,
      operation: props.data.operation,
      srno: props.srno,
      iteamIndex: props.iteamIndex,
      onAssetCodeAdd: props.onAdd,
      onAssetCodeEdit: props.onEdit,
      onAssetCodeDelete: props.onAssetCodeDelete,
      onAssetCodeUpdateSave: props.onAssetCodeUpdateSave,
      onAssetCodeRemove: props.onAssetCodeRemove,
      onAssetCodeEditCallback: props.onAssetCodeEditClick,
      onSort: props.onSort,
      key: props.key,
      render: false,
    };
  }

  componentDidMount() {
    this.setState({
      id: this.props.data.id,
    });
  }

  componentWillReceiveProps(props) {
    // if (props?.data?.state !== this.state.state) {
    this.setState({
      action: props.action,
      id: props.data.id,
      custAbbreviation: props.data.custAbbreviation,
      materialtype: props.data.materialtype,
      pocketcode: props.data.pocketcode,
      assetcode: props.data.code,
      description: props.data.description,
      operation: props.data.operation,
      srno: props.srno,
      iteamIndex: props.iteamIndex,
      onAssetCodeAdd: props.onAdd,
      onAssetCodeEdit: props.onEdit,
      onAssetCodeDelete: props.onAssetCodeDelete,
      onAssetCodeUpdateSave: props.onAssetCodeUpdateSave,
      onAssetCodeRemove: props.onAssetCodeRemove,
      onAssetCodeEditCallback: props.onAssetCodeEditClick,
      key: props.key,
      render: true,
    });
    // }
  }

  onAssetCodeAdd() {
    if (this.state.onAssetCodeAdd) {
      this.state.onAssetCodeAdd(this.state);
    }
  }

  handlePocketCodeChange(e) {
    let res = e.target.value;
    if (res.length <= 4) {
      this.setState({ pocketcode: res });
    }
  }

  handleDescriptionChange(e) {
    this.setState({
      description: e.target.value,
    });
  }

  updateNotes(e) {
    if (this.state.onNoteUpdate) {
      this.setState({ action: "view" });
      this.state.onNoteUpdate(this.state.key);
    }
  }

  deleteAssetCode(e) {
    if (
      undefined !== this.state.onAssetCodeDelete &&
      null !== this.state.onAssetCodeDelete
    ) {
      this.props.onAssetCodeDelete(this.state);
    }
  }

  onAssetCodeEditClick(id) {
    this.setState({ action: "edit" });
  }

  updateAssetCode(e) {
    if (this.state.onAssetCodeUpdateSave) {
      this.setState({ action: "view" });
      this.state.onAssetCodeUpdateSave(this.state);
    }
  }

  handleCustAbbreviationChange(selectedValue) {
    this.setState({ custAbbreviation: selectedValue });
  }

  handleMaterialTyepeChange(selectedValue) {
    this.setState({ materialtype: selectedValue });
  }

  removeAssetCode() {
    if (this.state.onAssetCodeRemove) {
      this.state.onAssetCodeRemove(this.state);
    }
  }

  editAssetCodeCancel() {
    this.setState({
      action: "view",
    });
  }

  render() {
    if (this.state.action === "create") {
      return this.addAssetCode();
    } else if (this.state.action === "view") {
      return this.viewAssetCode();
    } else if (this.state.action === "edit") {
      return this.editAssetCode();
    }
  }

  addAssetCode() {
    return (
      <>
        <tr>
          <td style={{ width: "5%" }}></td>
          <td>
            <Autocomplete
              id="customer"
              fullWidth
              options={this.custAbbreviationList}
              value={this.state.custAbbreviation}
              defaultValue={this.state.custAbbreviation}
              getOptionLabel={(option) => option.abbreviation}
              onChange={(event, value) => {
                this.handleCustAbbreviationChange(value);
              }}
              inputprops={{
                style: { textTransform: "capitalize" },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Customer Abbreviation"
                  variant="outlined"
                  required
                />
              )}
            />
          </td>
          <td>
            <Autocomplete
              id="customer"
              fullWidth
              options={this.materialTypeList}
              value={this.state.materialtype}
              defaultValue={this.state.materialtype}
              getOptionLabel={(option) => option.type}
              onChange={(event, value) => {
                this.handleMaterialTyepeChange(value);
              }}
              inputprops={{
                style: { textTransform: "capitalize" },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Material Type"
                  variant="outlined"
                  required
                />
              )}
            />
          </td>
          <td>
            <TextField
              type="text"
              fullWidth
              className="textField"
              // style={{ width: "310px" }}
              variant="outlined"
              placeholder="Pocket Code"
              value={this.state.pocketcode}
              onChange={this.handlePocketCodeChange.bind(this)}
              required
            />
          </td>
          <td>
            {/* <TextField
              type="text"
              fullWidth
              className="textField"
              // style={{ width: "310px" }}
              variant="outlined"
              placeholder="Description"
              value={this.state.description}
              onChange={this.handleDescriptionChange.bind(this)}
              required
            /> */}
            <textarea
              required
              type="text"
              value={this.state.description}
              onChange={this.handleDescriptionChange.bind(this)}
              className="form-control"
              placeholder="Description"
              id="exampleFormControlTextarea1"
            />
          </td>
          <td style={{ width: "20%", alignItems: "center", padding: "10px" }}>
            <CancelButtons onClick={this.removeAssetCode.bind(this)} />
            <SaveButtons onClick={this.onAssetCodeAdd.bind(this)} />
          </td>
        </tr>
      </>
    );
  }

  viewAssetCode() {
    return (
      <>
        <tr className="hoverTable" key={this.props.iteamIndex}>
          <td style={{ width: "5%" }}>{this.props.iteamIndex}</td>
          <td style={{ width: "20%" }}>
            {this.state.custAbbreviation
              ? this.state.custAbbreviation?.abbreviation
              : this.state.custabbreviation}
          </td>
          <td style={{ width: "20%" }}>{this.state.materialtype?.type}</td>
          <td style={{ width: "20%" }}>{this.state.pocketcode}</td>
          <td style={{ width: "20%" }}>{this.state.description}</td>
          <td style={{ width: "20%" }}>
            <DeleteIconButton
              onClick={(e) => this.deleteAssetCode(e)}
              title="Asset Code"
              domainObject={this.state.assetcode}
            />
            <EditIconButton onClick={this.onAssetCodeEditClick.bind(this)} />
          </td>
        </tr>
      </>
    );
  }

  editAssetCode() {
    return (
      <>
        <tr>
          <td style={{ width: "5%" }}>{this.state.iteamIndex}</td>
          <td>
            <Autocomplete
              id="customer"
              fullWidth
              options={this.custAbbreviationList}
              value={this.state.custAbbreviation}
              defaultValue={this.state.custAbbreviation}
              getOptionLabel={(option) => option.abbreviation}
              onChange={(event, value) => {
                this.handleCustAbbreviationChange(value);
              }}
              inputprops={{
                style: { textTransform: "capitalize" },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Customer Abbreviation"
                  variant="outlined"
                  required
                />
              )}
            />
          </td>
          <td>
            <Autocomplete
              id="customer"
              fullWidth
              options={this.materialTypeList}
              value={this.state.materialtype}
              defaultValue={this.state.materialtype}
              getOptionLabel={(option) => option.type}
              onChange={(event, value) => {
                this.handleMaterialTyepeChange(value);
              }}
              inputprops={{
                style: { textTransform: "capitalize" },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Material Type"
                  variant="outlined"
                  required
                />
              )}
            />
          </td>
          <td>
            <TextField
              type="text"
              fullWidth
              className="textField"
              // style={{ width: "310px" }}
              variant="outlined"
              placeholder="Pocket Code"
              value={this.state.pocketcode}
              onChange={this.handlePocketCodeChange.bind(this)}
              required
            />
          </td>
          <td>
            <textarea
              required
              type="text"
              value={this.state.description}
              onChange={this.handleDescriptionChange.bind(this)}
              className="form-control"
              placeholder="Description"
              id="exampleFormControlTextarea1"
            />
          </td>
          <td style={{ width: "20%", alignItems: "center", padding: "10px" }}>
            <CancelButtons onClick={this.editAssetCodeCancel.bind(this)} />
            <SaveButtons onClick={this.updateAssetCode.bind(this)} />
          </td>
        </tr>
      </>
    );
  }
}
export default AssetCodeMaster;
