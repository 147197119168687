import { GET_ASSET, SET_ASSET } from "./AssetTypes";

const getAsset = () => {
  return {
    type: GET_ASSET,
  };
};

const setAsset = (data) => {
  return {
    type: SET_ASSET,
    payload: data,
  };
};
const AssetAction = { getAsset, setAsset };

export default AssetAction;
