import React, { Component } from 'react';
import { withRouter } from "../../withRouter";
import ReactApexCharts from 'react-apexcharts';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import AdminFetchServerData from '../../provider/AdminFetchServerData';
import store from '../../redux/store';
export class AdminDashboard extends Component {
	header = store.getState().adminHeader.adminHeader;
	constructor() {
		super();
		this.state = {
			priceOptions: null,
			priceSeries: null,
			totalUsers: 0,
			activeRegistrations: 0,
			initialPage: 0,
			pageSize: 10,
			appUserList: [],
			isPaging: false,
			render: false,
		};
		this.getStatisticData();
	}

	getStatisticData = (e) => {
		const postObject = {
			header: this.header,
		};
		AdminFetchServerData.callPostService('/adminpanelStatsms/getStats', postObject)
			.then((response) => {
				let output = response;
				if (output.status === 'SUCCESS') {
					let statisticData = output.data
					let userChartData = output.data.users;
					let companyChartData = output.data.companies;
					let userLabels = [];
					let userValue = [];
					let companyLabels = [];
					let companyValue = [];
					for (let i = 0; i < userChartData.length; i++) {
						let date = userChartData[i].year + '\n' + userChartData[i].monthName;
						userLabels.push(date);
						userValue.push(userChartData[i].numberOfUsers);
					}
					for (let i = 0; i < companyChartData.length; i++) {
						let date = companyChartData[i].year + '\n' + companyChartData[i].monthName;
						companyLabels.push(date);
						companyValue.push(companyChartData[i].numberOfUsers);
					}

					this.setState({
						render: true,
						totalUsers: statisticData.totalUsers,
						activeRegistrations: statisticData.activeRegistrations,
						chartData: statisticData.users,
						priceSeries: [
							{
								name: 'Users',
								type: 'line',
								data: userValue.reverse(),
							},
							{
								name: 'Companies',
								type: 'line',
								data: companyValue.reverse(),
							},
						],
						priceOptions: {
							chart: {
								height: 350,
								type: 'line',
								zoom: {
									enabled: false,
								},
								toolbar: {
									show: false,
								},
							},
							tooltip: {
								x: {
									show: false,
								},
							},
							grid: {
								yaxis: {
									lines: {
										show: false,
									},
								},
							},
							dataLabels: {
								enabled: false,
							},
							stroke: {
								curve: 'straight',
							},
							title: {
								text: '',
								align: 'center',
							},

							legend: {
								show: true,
								showForSingleSeries: true,
								position: 'top',
								horizontalAlign: 'center',
							},
							labels: userLabels.reverse(),
							xaxis: {
								tooltip: {
									enabled: false,
								},
								max: new Date(new Date().setHours(24, 0, 0, 0)).getTime(), // end date
								type: 'datetime',
								labels: {
									format: 'MMM-yyyy',
								},
								title: {
									text: 'Year',
									style: {
										colors: '#808b96',
									},
									labels: {
										style: {
											colors: '#808b96 ',
										},
									},
									tickPlacement: 'between',
								},

								axisBorder: {
									show: true,
									color: '#34495E',
									height: 1,
									width: '100%',
									offsetX: 0,
									offsetY: 0,
								},
								axisTicks: {
									show: true,
									color: '#34495E',
								},
							},

							yaxis: [
								{
									axisTicks: {
										show: true,
									},
									axisBorder: {
										show: true,
										color: '#247BA0',
									},

									labels: {
										formatter: function (val, index) {
											return val.toFixed();
										},
										style: {
											colors: '#247BA0',
										},
									},
									title: {
										text: 'Users & Companies',
										style: {
											color: '#247BA0',
										},
									},
								},
							],
						},
					});
				} else {
				}
			})
			.catch((error) => { });
	};

	render() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<>
					<Container className="mt--7" fluid>
						<div className="row p-2">
							<div className="col-sm-3 p-2">
								<div className="card card-body" style={{ backgroundColor: "mediumturquoise", padding: "12px", }}>
									<div className="row align-items-center" style={{ margin: "0px" }}>
										<div className="col-sm-2" id="iconStyle" >
											<i className="fa fa-bar-chart fa-2x" aria-hidden="true" style={{ color: "#fff" }}></i>
										</div>
										<div className="col-sm-10 text-start">
											<p className="dashboardCardText">Active Registrations : {this.state.activeRegistrations} </p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3 p-2">
								<div className="card card-body" style={{ backgroundColor: "lightcoral", padding: "12px", }}>
									<div className="row align-items-center" style={{ margin: "0px" }}>
										<div className="col-sm-3" id="iconStyle">
											<i className="fa fa-pie-chart fa-2x" aria-hidden="true" style={{ color: "#fff" }}></i>
										</div>
										<div className="col-sm-9 text-start">
											<p className="dashboardCardText">Total Users : {this.state.totalUsers} </p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3 p-2">
								<div className="card card-body" style={{ backgroundColor: "steelblue", padding: "12px", }}>
									<div className="row align-items-center" style={{ margin: "0px" }}>
										<div className="col-sm-3" id="iconStyle">
											<i className="fa fa-users fa-2x" aria-hidden="true" style={{ color: "#fff" }}></i>
										</div>
										<div className="col-sm-9 text-start">
											{/* <p className="dashboardCardText">Assets : {this.state.activeAssetCount} </p> */}
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3 p-2">
								<div className="card card-body" style={{ backgroundColor: "peru", padding: "12px", }}>
									<div className="row align-items-center" style={{ margin: "0px" }}>
										<div className="col-sm-3" id="iconStyle">
											<i className="fa fa-percent fa-2x" aria-hidden="true" style={{ color: "#fff" }}></i>
										</div>
										<div className="col-sm-9 text-start">
											{/* <p className="dashboardCardText">Tracking History : {this.state.totalAssetTracked} </p> */}
										</div>
									</div>
								</div>
							</div>
						</div>
						<Row className='mt-4'>
							<Col className="mb-5 mb-xl-0" xl="12">
								<Card>
									<CardBody>
										{/* Chart */}
										{this.state.priceOptions !== null && this.state.priceSeries !== null ?
											< ReactApexCharts
												options={this.state.priceOptions}
												series={this.state.priceSeries}
												type="line"
												height={400}
											/> : ""
										}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</>
			);
		}
	}
}
export default withRouter(AdminDashboard);
