import { GET_ASSET_STATIC_DATA, SET_ASSET_STATIC_DATA } from "./AssetStaticDataTypes";

const getAssetStaticData = () => {
  return {
    type: GET_ASSET_STATIC_DATA,
  };
};

const setAssetStaticData = (data) => {
  return {
    type: SET_ASSET_STATIC_DATA,
    payload: data,
  };
};
const AssetStaticDataAction = { getAssetStaticData, setAssetStaticData };

export default AssetStaticDataAction;
