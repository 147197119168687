import { GET_HEADER, SET_HEADER } from "./HeaderTypes";

const initialState = {
  header: {},
};

const HeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HEADER:
      return {
        ...state,
      };
    case SET_HEADER:
      return {
        ...state,
        header: action.payload,
      };
    default:
      return state;
  }
};

export default HeaderReducer;
