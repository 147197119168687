import { Component } from "react";
import { withRouter } from "../../withRouter";
import { NavLink, Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Map from "../map/Map";
import store from "../../redux/store";
import DatePicker from "react-datepicker";
import GooglePlayStore from "../../assets/images/googlePlayStore.png";
import "react-datepicker/dist/react-datepicker.css";
import "react-tabs/style/react-tabs.css";
import "./Dashboard.css";
class Dashboard extends Component {
  header = store.getState().header.header;
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      history: [],
    };
    this.getDashboard();
    this.isMobile();
  }

  isMobile() {
    if (
      / Android | webOS | iPhone | iPad | iPod | BlackBerry | IEMobile | Opera Mini /i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  getDashboard() {
    const postObject = {
      header: this.header,
      date: this.state.date,
    };
    FetchServerData.callPostService("/dashboardms/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let dashboardData = output.data;
          this.setState({
            render: true,
            activeCustomerCount: dashboardData.activeCustomerCount,
            activeSupplierCount: dashboardData.activeSupplierCount,
            activeAssetCount: dashboardData.activeAssetCount,
            totalAssetTracked: dashboardData.totalAssetTracked,
            history: dashboardData.history,
          });
        } else {
        }
      }
    );
  }
  handleDateChange(date) {
    this.setState({ date: date });
  }

  showMobileIcon() {
    return (
      <>
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.pts.assettrak"
          style={{ color: "#fff", cursor: "pointer" }}
        >
          <img
            src={GooglePlayStore}
            style={{ height: "3.5em", width: "9em" }}
          />
        </a>
      </>
    );
  }

  openMenuPage(pageid) {
    this.props.navigate(pageid, {
      state: {
        pageid: pageid,
      },
    });
  }

  render() {
    return (
      <>
        <div className="row p-2">
          {this.isMobile() === true && (
            <Row>
              <Col md={12} style={{ textAlign: "center" }}>
                <p style={{ fontSize: "17px" }}>
                  <b>Use mobile apps for better User experience</b> &nbsp;&nbsp;
                  {this.showMobileIcon()}{" "}
                </p>
                {/* {this.showMobileIcon()} */}
              </Col>
            </Row>
          )}
          <div className="col-sm-3 p-2">
            <a onClick={this.openMenuPage.bind(this, "/customerList")}>
              <div
                className="card card-body"
                style={{
                  backgroundColor: "mediumturquoise",
                  padding: "12px",
                  cursor: "pointer",
                }}
              >
                <div
                  className="row align-items-center"
                  style={{ margin: "0px" }}
                >
                  <div className="col-sm-3">
                    <i
                      className="fa fa-users fa-2x"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                  <div className="col-sm-9 text-start p-0">
                    <p className="dashboardCardText">
                      Active Customers : {this.state.activeCustomerCount}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-sm-3 p-2">
            <a onClick={this.openMenuPage.bind(this, "/supplierList")}>
              <div
                className="card card-body"
                style={{
                  backgroundColor: "lightcoral",
                  padding: "12px",
                  cursor: "pointer",
                }}
              >
                <div
                  className="row align-items-center"
                  style={{ margin: "0px" }}
                >
                  <div className="col-sm-3">
                    <i
                      className="fa-solid fa-dolly fa-2x"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                  <div className="col-sm-9 text-start">
                    <p className="dashboardCardText">
                      Active Suppliers : {this.state.activeSupplierCount}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-sm-3 p-2">
            <a onClick={this.openMenuPage.bind(this, "/catalogue")}>
              <div
                className="card card-body"
                style={{
                  backgroundColor: "steelblue",
                  padding: "12px",
                  cursor: "pointer",
                }}
              >
                <div
                  className="row align-items-center"
                  style={{ margin: "0px" }}
                >
                  <div className="col-sm-3">
                    <i
                      className="fas fa-box-open fa-2x"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                  <div className="col-sm-9 text-start">
                    <p className="dashboardCardText">
                      Total Assets : {this.state.activeAssetCount}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-sm-3 p-2">
            <a onClick={this.openMenuPage.bind(this, "/assetTrackingList")}>
              <div
                className="card card-body"
                style={{
                  backgroundColor: "peru",
                  padding: "12px",
                  cursor: "pointer",
                }}
              >
                <div
                  className="row align-items-center"
                  style={{ margin: "0px" }}
                >
                  <div className="col-sm-3">
                    <i
                      className="fa-solid fa-location-dot fa-2x"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                  <div className="col-sm-9 text-start">
                    <p className="dashboardCardText">
                      Tracking History : {this.state.totalAssetTracked}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div>
          <div className="row justify-content-between pt-3 pb-2">
            <div className="col-sm-8"></div>
            <div className="col-sm-3 d-flex text-end">
              <DatePicker
                selected={this.state.date}
                onChange={this.handleDateChange.bind(this)}
                name="Date"
                dateFormat="dd/MM/yyyy"
                placeholderText="Date*"
                required
                className="dateTimeInput"
              />
              <button
                className="btnDate"
                onClick={this.getDashboard.bind(this)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div
            className="card card-body"
            style={{ height: "600px", marginTop: "10px" }}
          >
            <Map markers={this.state.history} />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Dashboard);
