import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import AdminNavbar from "../adminPanel/adminNavbar/AdminNavbar";
import AdminSidebar from "../adminPanel/adminSidebar/AdminSidebar";
import AdminFooter from "../component/footer/AdminFooter";

class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true
    };
  }
  handleClick = (isOpenState) => {
    this.setState({ isOpen: isOpenState });
  }
  render() {
    return (
      <div id="application-layout">
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <AdminSidebar handleClick={this.handleClick} />
            <div className={this.state.isOpen ? "row fixed-top navOpen" : "row fixed-top navClose"}>
              <AdminNavbar history={this.props.history} />
            </div>
            <div className={this.state.isOpen ? "layout-page layoutOpen" : "layout-page layoutClose"}>
              <div className="content-wrapper" style={{ marginTop: "60px" }}>
                {/* Content */}
                <div className="container-fluid flex-grow-1 container-p-y">
                  <Outlet />
                </div>
                <AdminFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminLayout;
