import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Autocomplete } from "@mui/material";
import { Menu } from "@mui/material";
import FetchServerData from "../../provider/FetchServerData";
// import PtsButton from '../buttons/PtsButton';
import DatePicker from "react-datepicker";
import TextField from "@material-ui/core/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import store from "../../redux/store";
import Utils from "../../provider/Utils";
import CustomerAction from "../../redux/customer/CustomerAction";
import ApplicationStateAction from "../../redux/applicationState/ApplicationStateAction";
import ReturnablePlanAction from "../../redux/returnablePlan/ReturnablePlanAction";
import Pagination from "../assetTracking/Pagination";
import "./AssetCycleList.css";
import MenuAction from "../../redux/menu/MenuAction";

class AssetCycleList extends Component {
  header = store.getState().header.header;
  assetCycleState = store.getState().applicationState.assetCycleState;
  assetCycleStateDetails = {};
  breadCrumb = [{ pageid: "", uiname: "Asset Cycle List" }];
  constructor(props) {
    super(props);
    let assetCycleIds = "";
    if (props.state) {
      assetCycleIds = props.state.assetCycleIds;
    }
    if (this.assetCycleState) {
      this.assetCycleStateDetails = Object.getOwnPropertyNames(
        this.assetCycleState
      );
    }

    let userMenuArray = Utils.getTopMenus();
    if (props.state) {
      for (let i = 0; i < userMenuArray.length; i++) {
        for (
          let j = 0;
          props.state.pageid && j < props.state.pageid?.length;
          j++
        ) {
          if (userMenuArray[i].pageid === props.state.pageid) {
            store.dispatch(MenuAction.setActiveMenu(userMenuArray[i].id));
          }
        }
      }
    }

    if (this.assetCycleStateDetails.length > 0) {
      this.state = {
        pageSize: this.assetCycleState.pageSize,
        selectedTab: this.assetCycleState.selectedTab,
        searchText: "",
        pageNo: this.assetCycleState.pageNo,
        showPerPage: 0,
        totalRecords: 0,
        assetCycleList: [],
        customerList: [],
        startedAssetCycleListArr: [],
        inCycleAssetCycleListArr: [],
        completedAssetCycleListArr: [],
        allAssetCycleListArr: [],
        returnableCyclePlanId: "",
        customerId: "",
        cycleFromDate: null,
        cycleToDate: null,
        customer: null,
        d_customer: null,
        d_cycleFromDate: null,
        d_cycleToDate: null,
        render: false,
        open: false,
        errorMassage: false,
        error: false,
        getCreatePermission: false,
        assetCycleIds: assetCycleIds,
        assetCycleId: this.assetCycleState.assetCycleId,
        isFirstTimeLoading: true,
      };
    } else {
      this.state = {
        pageSize: 10,
        selectedTab: 0,
        render: false,
        searchText: "",
        pageNo: 0,
        showPerPage: 0,
        totalRecords: 0,
        assetCycleList: [],
        customerList: [],
        startedAssetCycleListArr: [],
        inCycleAssetCycleListArr: [],
        completedAssetCycleListArr: [],
        allAssetCycleListArr: [],
        returnableCyclePlanId: "",
        customerId: "",
        cycleFromDate: null,
        cycleToDate: null,
        d_customer: null,
        d_cycleFromDate: null,
        d_cycleToDate: null,
        customer: null,
        open: false,
        error: false,
        getCreatePermission: false,
        assetCycleIds: assetCycleIds,
        isFirstTimeLoading: true,
      };
    }
    this.getPlannedList();
    this.getCustomerList();
  }

  componentDidMount() {
    // this.filterDataInBackend(this.state.selectedTab);
    this.onPaginationChange({
      buttonIndex: this.state.pageNo,
      showPerPage: this.state.pageSize,
    });
  }
  getPlannedList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService(
      "/returnablePlanms/getPlannedList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let ReturnablePlanList = output.data.cyclePlanList;
        store.dispatch(
          ReturnablePlanAction.setReturnablePlan(ReturnablePlanList)
        );
        this.setState({
          ReturnablePlanList: ReturnablePlanList,
        });
      } else {
      }
    });
  }

  getCustomerList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService(
      "/costomerms/getActiveList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let customerListArray = output.data.customerList;
        let allCustomer = {
          name: "All",
        };
        const customerList = [allCustomer, ...customerListArray];
        store.dispatch(CustomerAction.setCustomer(customerList));
        this.setState({
          customerList: customerList,
        });
      } else {
      }
    });
  }

  addAssetCycle() {
    this.props.navigate("/assetCycle", {
      state: {
        action: "create",
      },
    });
  }

  toAssetCycleDetails(data) {
    let assetCycleState = {
      assetCycleId: data.id,
      pageNo: this.state.pageNo,
      pageSize: this.state.pageSize,
      selectedTab: this.state.selectedTab,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ assetCycleState })
    );
    this.props.navigate("/assetCycle", {
      state: {
        action: "view",
        assetCycleId: data.id,
      },
    });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ pageNo: page, pageSize: pageSize });
  }

  setKey(tab) {
    this.filterDataInBackend(tab);
    this.setState({
      selectedTab: tab,
      pageNo: 0,
      pageSize: 10,
    });
  }

  handleCustomerTypeChange(selectedCustomer) {
    this.setState({
      customer: selectedCustomer,
      customerId: selectedCustomer.id,
    });
  }

  handleCycleFromDateChange(date) {
    if (this.state.cycleToDate === null && date === null) {
      this.setState({ cycleFromDate: date, errorMassage: false });
    } else if (date === null) {
      this.setState({ cycleFromDate: date, errorMassage: true });
    } else {
      this.setState({ cycleFromDate: date, errorMassage: false });
    }
  }

  handleCycleToDateChange(date) {
    if (this.state.cycleFromDate === null && date === null) {
      this.setState({ cycleToDate: date, errorMassage: false });
    } else if (this.state.cycleFromDate === null) {
      this.setState({ cycleToDate: date, errorMassage: true });
    } else {
      this.setState({ cycleToDate: date, errorMassage: false });
    }
  }
  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  filterDataInBackend(tab, pageNo, pageSize) {
    this.setState({
      d_customer: this.state.customer,
      d_cycleFromDate: this.state.cycleFromDate,
      d_cycleToDate: this.state.cycleToDate,
    });
    let fromDate = Date.parse(this.state.cycleFromDate);
    let toDate = Date.parse(this.state.cycleToDate);

    if (fromDate !== null || toDate !== null) {
      if (fromDate !== null && toDate !== null && fromDate > toDate) {
        this.setState({ error: true });
      } else {
        let status = ["Started", "InCycle", "Completed", ""];
        const postObject = {
          header: this.header,
          customer: {
            id: this.state.customerId,
          },
          cycleFromDate: this.state.cycleFromDate,
          cycleToDate: this.state.cycleToDate,
          cycleStatus: { status: status[tab] },
          page: {
            searchText: this.state.search,
            pageNo: pageNo,
            pageSize: pageSize,
          },
        };
        FetchServerData.callPostService(
          "/assetCyclems/getFilterList",
          postObject
        ).then((output) => {
          if (output.status === "SUCCESS") {
            let assetCycleData = output.data;
            let assetCycleList = assetCycleData.assetCycleList;
            let page = assetCycleData.page;
            this.state.pageNo = page.pageNo;
            this.state.pageSize = page.pageSize;

            let getPermission = Utils.getRolePermission(
              output.data.privilegeList
            );
            let getCreatePermission = getPermission[1];
            for (let i = 0; i < assetCycleList.length; i++) {
              assetCycleList[i].startDate = Utils.getUtcToLocal(
                assetCycleList[i].startDate
              );
              assetCycleList[i].endDate = Utils.getUtcToLocal(
                assetCycleList[i].endDate
              );
            }
            let startedAssetCycleListArr = [];
            let inCycleAssetCycleListArr = [];
            let completedAssetCycleListArr = [];
            for (let i = 0; i < assetCycleList.length; i++) {
              if (assetCycleList[i].status === "Started") {
                startedAssetCycleListArr.push(assetCycleList[i]);
              } else if (assetCycleList[i].status === "InCycle") {
                inCycleAssetCycleListArr.push(assetCycleList[i]);
              } else if (assetCycleList[i].status === "Completed") {
                completedAssetCycleListArr.push(assetCycleList[i]);
              }
            }

            this.setState({
              render: true,
              open: false,
              searchText: page.searchText,
              pageNo: page.pageNo,
              totalPages: page.totalPages,
              totalRecords: page.total,
              showPerPage: page.pageSize,
              assetCycleList: assetCycleList,
              getCreatePermission: getCreatePermission,
              startedAssetCycleListArr: startedAssetCycleListArr,
              inCycleAssetCycleListArr: inCycleAssetCycleListArr,
              completedAssetCycleListArr: completedAssetCycleListArr,
              allAssetCycleListArr: assetCycleList,
            });
          } else {
          }
        });
      }
    }
  }

  resetFilter() {
    let status = ["Started", "InCycle", "Completed", ""];
    const postObject = {
      header: this.header,
      customer: {
        id: "",
      },
      cycleFromDate: null,
      cycleToDate: null,
      cycleStatus: { status: status[this.state.selectedTab] },
      page: {
        searchText: this.state.search ? this.state.search : "",
        pageNo: this.state.pageNo,
        pageSize: this.state.pageSize,
      },
    };
    FetchServerData.callPostService(
      "/assetCyclems/getFilterList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let assetCycleData = output.data;
        let assetCycleList = assetCycleData.assetCycleList;
        let page = assetCycleData.page;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        for (let i = 0; i < assetCycleList.length; i++) {
          assetCycleList[i].startDate = Utils.getUtcToLocal(
            assetCycleList[i].startDate
          );
          assetCycleList[i].endDate = Utils.getUtcToLocal(
            assetCycleList[i].endDate
          );
        }
        let startedAssetCycleListArr = [];
        let inCycleAssetCycleListArr = [];
        let completedAssetCycleListArr = [];
        for (let i = 0; i < assetCycleList.length; i++) {
          if (assetCycleList[i].status === "Started") {
            startedAssetCycleListArr.push(assetCycleList[i]);
          } else if (assetCycleList[i].status === "InCycle") {
            inCycleAssetCycleListArr.push(assetCycleList[i]);
          } else if (assetCycleList[i].status === "Completed") {
            completedAssetCycleListArr.push(assetCycleList[i]);
          }
        }

        this.setState({
          render: true,
          isFirstTimeLoading: true,
          open: false,
          error: false,
          errorMassage: false,
          cycleFromDate: null,
          cycleToDate: null,
          customer: null,
          customerId: "",
          d_customer: null,
          d_cycleFromDate: null,
          d_cycleToDate: null,
          searchText: page.searchText,
          pageNo: page.pageNo,
          totalPages: page.totalPages,
          totalRecords: page.total,
          showPerPage: page.pageSize,
          assetCycleList: assetCycleList,
          getCreatePermission: getCreatePermission,
          startedAssetCycleListArr: startedAssetCycleListArr,
          inCycleAssetCycleListArr: inCycleAssetCycleListArr,
          completedAssetCycleListArr: completedAssetCycleListArr,
          allAssetCycleListArr: assetCycleList,
        });
      } else {
      }
    });
  }

  onPaginationChange(returnProp) {
    let counter = returnProp.counter;
    let pageNo = this.state.pageNo + counter;
    let pageSize = returnProp.showPerPage;
    let buttonIndex = returnProp.buttonIndex;
    let currentPageIndex =
      typeof counter !== "undefined" ? pageNo : buttonIndex;
    let resetPageNo = returnProp.resetPageNo;
    if (resetPageNo === true) {
      currentPageIndex = 0;
    }
    this.setState({
      pageNo: currentPageIndex,
    });
    // console.log("showPerPage", returnProp.showPerPage, "pageNo", this.state.pageNo + counter)
    this.filterDataInBackend(
      this.state.selectedTab,
      currentPageIndex,
      pageSize
    );
  }

  onSearchChange(e) {
    this.setState({ search: e.target.value });
  }

  assetTrackingStatus(trackingHistoryStatus) {
    switch (trackingHistoryStatus) {
      case "Active":
        return <span className="dot"></span>;
      case "NotTracked":
        return <span className="dot"></span>;
      case "Tracked":
        return <span className="greendot"></span>;
      case "HoldingPeriodOver":
        return <span className="yellowdot blink_me"></span>;
      case "CycleTimeOver":
        return <span className="orangedot blink_me"></span>;
      case "MissingAsset":
        return <span className="reddot blink_me"></span>;
      default:
    }
  }

  toAssetTrakingDetails(assetId) {
    this.props.navigate("/viewAssetTracking", {
      state: {
        action: "view",
        assetId: assetId,
        pageNo: this.state.pageNo,
        pageSize: this.state.showPerPage,
        searchText: this.state.searchText,
      },
    });
  }

  onFilterClick() {
    if (this.state.errorMassage) {
      return false;
    }
    this.setState({
      isFirstTimeLoading: false,
      // open: false,
    });
    this.filterDataInBackend(this.state.selectedTab);
  }
  onSearchClick() {
    this.setState({
      isFirstTimeLoading: false,
    });
    this.filterDataInBackend(
      this.state.selectedTab,
      this.state.pageNo,
      this.state.pageSize
    );
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject="Asset Cycle"
            name="Asset Cycle List"
          />
          <Row>
            <Col md={12}>
              <div className="row align-items-end text-end">
                <div className="col">
                  <span>
                    {this.state.d_customer !== null ||
                    this.state.d_cycleFromDate !== null ||
                    this.state.d_cycleToDate !== null ? (
                      <span>
                        ({" "}
                        {this.state.customer !== null &&
                        this.state.customer.name ? (
                          <span>
                            <b>Customer : </b> {this.state.customer.name + ","}
                          </span>
                        ) : (
                          ""
                        )}
                        &nbsp;
                        {this.state.cycleFromDate &&
                        this.state.cycleFromDate !== null ? (
                          <span>
                            <b>Form : </b>
                            {Utils.getUtcToLocal(this.state.cycleFromDate) +
                              ", "}
                          </span>
                        ) : (
                          ""
                        )}
                        &nbsp;
                        {this.state.cycleToDate &&
                        this.state.cycleToDate !== "" ? (
                          <span>
                            <b>To : </b>
                            {Utils.getUtcToLocal(this.state.cycleToDate) + ", "}
                          </span>
                        ) : (
                          ""
                        )}{" "}
                        )
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                  &nbsp;&nbsp;
                  <span
                    style={{
                      fontSize: "17px",
                      fontWeight: "500",
                      color: "#566a7f",
                      cursor: "pointer",
                    }}
                    onClick={this.handleAccountDropdownOpen}
                  >
                    <FilterAltIcon
                      style={{ fontSize: "28px", color: "rgb(106, 123, 142)" }}
                      aria-controls={
                        this.state.open ? "dropdown-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={this.state.open ? "true" : undefined}
                    />
                    Filter
                  </span>
                  {"  "} &nbsp;&nbsp;
                  {this.state.isFirstTimeLoading ? (
                    ""
                  ) : (
                    <span
                      style={{
                        fontSize: "17px",
                        fontWeight: "500",
                        color: "#566a7f",
                        cursor: "pointer",
                      }}
                      onClick={this.resetFilter.bind(this)}
                    >
                      <i class="fa fa-refresh" aria-hidden="true"></i> Reset
                    </span>
                  )}
                </div>
              </div>
              <Menu
                anchorEl={this.state.anchorEl}
                id="dropdown-menu"
                className="filterDropdownMenu"
                open={this.state.open}
                onClose={this.handleAccountDropdownClose}
                // onClick={this.handleAccountDropdownClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <div
                  className="dropdown-item"
                  style={{ cursor: "pointer", width: "400px", height: "100%" }}
                >
                  <div className="row">
                    <h5>Filter</h5>
                    <div className="col-md-12 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-supplierType"
                      >
                        Customer
                      </label>
                      <Autocomplete
                        fullWidth
                        className="customerDropdownIcon"
                        id="customerTypeList"
                        options={this.state.customerList}
                        value={this.state.customer}
                        defaultValue={this.state.customer}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.handleCustomerTypeChange(value);
                        }}
                        inputprops={{ style: { textTransform: "capitalize" } }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Customer"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-12 mt-1  d-flex justify-content-between">
                      <div className="col-md-6 mt-1">
                        <label
                          className="form-label "
                          htmlFor="collapsible-supplierType"
                        >
                          From Date
                        </label>
                        <br></br>
                        <DatePicker
                          selected={this.state.cycleFromDate}
                          onChange={this.handleCycleFromDateChange.bind(this)}
                          dateFormat="d MMM, yyyy"
                          placeholderText="From Date"
                          required
                          className="startDateTimeInput"
                        />
                      </div>
                      &nbsp;
                      <div className="col-md-6 mt-1">
                        <label
                          className="form-label "
                          htmlFor="collapsible-supplierType"
                        >
                          To Date
                        </label>
                        <br></br>
                        <DatePicker
                          selected={this.state.cycleToDate}
                          onChange={this.handleCycleToDateChange.bind(this)}
                          dateFormat="d MMM, yyyy"
                          placeholderText="To Date"
                          required
                          className="endDateTimeInput"
                        />
                      </div>
                    </div>
                    {this.state.errorMassage ? (
                      <lable className="danger ms-1" style={{ color: "red" }}>
                        From Date is Required
                      </lable>
                    ) : (
                      ""
                    )}
                    {this.state.error ? (
                      <lable className="danger" style={{ color: "red" }}>
                        To Date should be after From Date
                      </lable>
                    ) : (
                      ""
                    )}
                    <div className="col-md-12 mt-4">
                      <button
                        className="fileterButton"
                        onClick={this.onFilterClick.bind(this)}
                      >
                        <i className="fa-solid fa-filter"></i>
                        &nbsp; Filter
                      </button>
                      &nbsp; &nbsp;
                      <button
                        className="resetButton"
                        onClick={this.resetFilter.bind(this)}
                      >
                        <i className="fa fa-refresh"></i>
                        &nbsp; Reset
                      </button>
                    </div>
                  </div>
                </div>
              </Menu>
              <Tabs
                defaultIndex={this.state.selectedTab}
                onSelect={(k) => this.setKey(k)}
                className="returnableTabs"
              >
                <TabList
                  style={{ listStyle: "none" }}
                  className="text-start d-flex justify-content-start ps-0"
                >
                  <Tab className="returnableTab hoverTab"> Started </Tab>
                  <Tab className="returnableTab hoverTab"> InCycle </Tab>
                  <Tab className="returnableTab hoverTab"> Completed </Tab>
                  <Tab className="returnableTab hoverTab"> All </Tab>
                </TabList>
                <TabPanel>
                  {this.state.isFirstTimeLoading &&
                  this.state.assetCycleList.length === 0 ? (
                    <div className="row">
                      <div className="col-md-12">
                        <Card>
                          <CardBody>
                            <div className="row">
                              <div
                                className="col-md-12"
                                style={{ padding: "2em" }}
                              >
                                <h5>
                                  <b>Asset Cycles not Found</b>
                                </h5>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col md-12">
                        <div className="card">
                          <div className="row justify-content-between p-3">
                            <div className="d-flex col-12 col-lg-4 col-xl-4">
                              <div className="col-12 text-start totalResults">
                                <span>
                                  Total Results: {this.state.totalRecords}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex col-12 col-lg-8 col-xl-8">
                              <div className="col-12 text-end">
                                <input
                                  type="search"
                                  id="gsearch"
                                  placeholder="Search"
                                  name="gsearch"
                                  value={this.state.search}
                                  onChange={this.onSearchChange.bind(this)}
                                />
                                <button
                                  className="gsearchButton"
                                  onClick={this.onSearchClick.bind(this)}
                                >
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table text-start table-hover">
                              <thead className="assetCycleThead">
                                <tr>
                                  <th scope="col fw-bold ">#</th>
                                  <th scope="col fw-bold">Customer</th>
                                  <th scope="col fw-bold">Asset Cycle Name</th>
                                  <th scope="col fw-bold">Plan Name</th>
                                  <th scope="col fw-bold">Asset UID</th>
                                  <th scope="col fw-bold">Asset Name</th>
                                  <th scope="col fw-bold">Start Date</th>
                                  <th scope="col fw-bold">Tracking Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.startedAssetCycleListArr?.map(
                                  (rowData, index) => (
                                    <tr
                                      className="hoverTable"
                                      key={index}
                                      onClick={this.toAssetCycleDetails.bind(
                                        this,
                                        rowData
                                      )}
                                      style={{
                                        backgroundColor:
                                          rowData.id ===
                                            this.state.assetCycleId ||
                                          this.state.assetCycleIds.includes(
                                            rowData.assetid
                                          )
                                            ? "#FEF5E7"
                                            : "#fff",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <td style={{ width: "6px" }}>
                                        {this.state.pageNo *
                                          this.state.showPerPage +
                                          index +
                                          1}
                                      </td>
                                      <td>{rowData.customer}</td>
                                      <td>{rowData.name}</td>
                                      <td>{rowData.cycleplanname}</td>
                                      <td>{rowData.assetuid}</td>
                                      <td>{rowData.assetname}</td>
                                      <td>{rowData.startDate}</td>
                                      <td>
                                        {this.assetTrackingStatus(
                                          rowData.trackingHistoryStatus
                                        )}{" "}
                                        {rowData.trackingHistoryStatus}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          {this.state.totalRecords === 0 && (
                            <div className="row text-center p-2">
                              <h5>Data not Available</h5>
                            </div>
                          )}
                          {this.state.totalRecords > 10 && (
                            <div className="row justify-content-between pagination p-2">
                              <div className="d-flex col-12 col-lg-4 col-xl-4">
                                <div className="pageSize col-12 text-start">
                                  Page {this.state.pageNo + 1} of{" "}
                                  {this.state.totalPages}
                                </div>
                              </div>
                              <div className="d-flex col-12 col-lg-8 col-xl-8">
                                <div className="col-12 text-end">
                                  <Pagination
                                    onPaginationChange={this.onPaginationChange.bind(
                                      this
                                    )}
                                    showPerPage={this.state.showPerPage}
                                    total={this.state.totalRecords}
                                    pageNo={this.state.pageNo}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  {this.state.isFirstTimeLoading &&
                  this.state.assetCycleList.length === 0 ? (
                    <div className="row">
                      <div className="col-md-12">
                        <Card>
                          <CardBody>
                            <div className="row">
                              <div
                                className="col-md-12"
                                style={{ padding: "2em" }}
                              >
                                <h5>
                                  <b>Asset Cycles not Found</b>
                                </h5>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col md-12">
                        <div className="card">
                          <div className="row justify-content-between p-3">
                            <div className="d-flex col-12 col-lg-4 col-xl-4">
                              <div className="col-12 text-start totalResults">
                                <span>
                                  Total Results: {this.state.totalRecords}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex col-12 col-lg-8 col-xl-8">
                              <div className="col-12 text-end">
                                <input
                                  type="search"
                                  id="gsearch"
                                  placeholder="Search"
                                  name="gsearch"
                                  value={this.state.search}
                                  onChange={this.onSearchChange.bind(this)}
                                />
                                <button
                                  className="gsearchButton"
                                  onClick={this.onSearchClick.bind(this)}
                                >
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table text-start table-hover">
                              <thead className="assetCycleThead">
                                <tr>
                                  <th scope="col fw-bold ">#</th>
                                  <th scope="col fw-bold">Customer</th>
                                  <th scope="col fw-bold">Asset Cycle Name</th>
                                  <th scope="col fw-bold">Plan Name</th>
                                  <th scope="col fw-bold">Asset UID</th>
                                  <th scope="col fw-bold">Asset Name</th>
                                  <th scope="col fw-bold">Start Date</th>
                                  <th scope="col fw-bold">Tracking Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.inCycleAssetCycleListArr?.map(
                                  (rowData, index) => (
                                    <tr
                                      className="hoverTable"
                                      key={index}
                                      onClick={this.toAssetCycleDetails.bind(
                                        this,
                                        rowData
                                      )}
                                      style={{
                                        backgroundColor:
                                          rowData.id ===
                                            this.state.assetCycleId ||
                                          this.state.assetCycleIds.includes(
                                            rowData.assetid
                                          )
                                            ? "#FEF5E7"
                                            : "#fff",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <td style={{ width: "6px" }}>
                                        {this.state.pageNo *
                                          this.state.showPerPage +
                                          index +
                                          1}
                                      </td>
                                      <td>{rowData.customer}</td>
                                      <td>{rowData.name}</td>
                                      <td>{rowData.cycleplanname}</td>
                                      <td>{rowData.assetuid}</td>
                                      <td>{rowData.assetname}</td>
                                      <td>{rowData.startDate}</td>
                                      <td>
                                        {this.assetTrackingStatus(
                                          rowData.trackingHistoryStatus
                                        )}{" "}
                                        {rowData.trackingHistoryStatus}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          {this.state.totalRecords === 0 && (
                            <div className="row text-center p-2">
                              <h5>Data not Available</h5>
                            </div>
                          )}
                          {this.state.totalRecords > 10 && (
                            <div className="row justify-content-between pagination p-2">
                              <div className="d-flex col-12 col-lg-4 col-xl-4">
                                <div className="pageSize col-12 text-start">
                                  Page {this.state.pageNo + 1} of{" "}
                                  {this.state.totalPages}
                                </div>
                              </div>
                              <div className="d-flex col-12 col-lg-8 col-xl-8">
                                <div className="col-12 text-end">
                                  <Pagination
                                    onPaginationChange={this.onPaginationChange.bind(
                                      this
                                    )}
                                    showPerPage={this.state.showPerPage}
                                    total={this.state.totalRecords}
                                    pageNo={this.state.pageNo}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  {this.state.isFirstTimeLoading &&
                  this.state.assetCycleList.length === 0 ? (
                    <div className="row">
                      <div className="col-md-12">
                        <Card>
                          <CardBody>
                            <div className="row">
                              <div
                                className="col-md-12"
                                style={{ padding: "2em" }}
                              >
                                <h5>
                                  <b>Asset Cycles not Found</b>
                                </h5>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col md-12">
                        <div className="card">
                          <div className="row justify-content-between p-3">
                            <div className="d-flex col-12 col-lg-4 col-xl-4">
                              <div className="col-12 text-start totalResults">
                                <span>
                                  Total Results: {this.state.totalRecords}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex col-12 col-lg-8 col-xl-8">
                              <div className="col-12 text-end">
                                <input
                                  type="search"
                                  id="gsearch"
                                  placeholder="Search"
                                  name="gsearch"
                                  value={this.state.search}
                                  onChange={this.onSearchChange.bind(this)}
                                />
                                <button
                                  className="gsearchButton"
                                  onClick={this.onSearchClick.bind(this)}
                                >
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table text-start table-hover">
                              <thead className="assetCycleThead">
                                <tr>
                                  <th scope="col fw-bold ">#</th>
                                  <th scope="col fw-bold">Customer</th>
                                  <th scope="col fw-bold">Asset Cycle Name</th>
                                  <th scope="col fw-bold">Plan Name</th>
                                  <th scope="col fw-bold">Asset UID</th>
                                  <th scope="col fw-bold">Asset Name</th>
                                  <th scope="col fw-bold">Start Date</th>
                                  <th scope="col fw-bold">End Date</th>
                                  <th scope="col fw-bold">Tracking Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.completedAssetCycleListArr?.map(
                                  (rowData, index) => (
                                    <tr
                                      key={index}
                                      onClick={this.toAssetCycleDetails.bind(
                                        this,
                                        rowData
                                      )}
                                      style={{
                                        backgroundColor:
                                          rowData.id ===
                                            this.state.assetCycleId ||
                                          this.state.assetCycleIds.includes(
                                            rowData.id
                                          )
                                            ? "#FEF5E7"
                                            : "#fff",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <td style={{ width: "6px" }}>
                                        {this.state.pageNo *
                                          this.state.showPerPage +
                                          index +
                                          1}
                                      </td>
                                      <td>{rowData.customer}</td>
                                      <td>{rowData.name}</td>
                                      <td>{rowData.cycleplanname}</td>
                                      <td>{rowData.assetuid}</td>
                                      <td>{rowData.assetname}</td>
                                      <td>{rowData.startDate}</td>
                                      <td>{rowData.endDate}</td>
                                      <td>
                                        {this.assetTrackingStatus(
                                          rowData.trackingHistoryStatus
                                        )}{" "}
                                        {rowData.trackingHistoryStatus}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          {this.state.totalRecords === 0 && (
                            <tfoot>
                              <div className="row p-2">
                                <h5>Data not Available</h5>
                              </div>
                            </tfoot>
                          )}
                          {this.state.totalRecords > 10 && (
                            <div className="row justify-content-between pagination p-2">
                              <div className="d-flex col-12 col-lg-4 col-xl-4">
                                <div className="pageSize col-12 text-start">
                                  Page {this.state.pageNo + 1} of{" "}
                                  {this.state.totalPages}
                                </div>
                              </div>
                              <div className="d-flex col-12 col-lg-8 col-xl-8">
                                <div className="col-12 text-end">
                                  <Pagination
                                    onPaginationChange={this.onPaginationChange.bind(
                                      this
                                    )}
                                    showPerPage={this.state.showPerPage}
                                    total={this.state.totalRecords}
                                    pageNo={this.state.pageNo}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  {this.state.isFirstTimeLoading &&
                  this.state.assetCycleList.length === 0 ? (
                    <div className="row">
                      <div className="col-md-12">
                        <Card>
                          <CardBody>
                            <div className="row">
                              <div
                                className="col-md-12"
                                style={{ padding: "2em" }}
                              >
                                <h5>
                                  <b>Asset Cycles not Found</b>
                                </h5>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col md-12">
                        <div className="card">
                          <div className="row justify-content-between p-3">
                            <div className="d-flex col-12 col-lg-4 col-xl-4">
                              <div className="col-12 text-start totalResults">
                                <span>
                                  Total Results: {this.state.totalRecords}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex col-12 col-lg-8 col-xl-8">
                              <div className="col-12 text-end">
                                <input
                                  type="search"
                                  id="gsearch"
                                  placeholder="Search"
                                  name="gsearch"
                                  value={this.state.search}
                                  onChange={this.onSearchChange.bind(this)}
                                />
                                <button
                                  className="gsearchButton"
                                  onClick={this.onSearchClick.bind(this)}
                                >
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table text-start table-hover">
                              <thead className="assetCycleThead">
                                <tr>
                                  <th scope="col fw-bold ">#</th>
                                  <th scope="col fw-bold">Customer</th>
                                  <th scope="col fw-bold">Asset Cycle Name</th>
                                  <th scope="col fw-bold">Plan Name</th>
                                  <th scope="col fw-bold">Asset UID</th>
                                  <th scope="col fw-bold">Asset Name</th>
                                  <th scope="col fw-bold">Start Date</th>
                                  <th scope="col fw-bold">End Date</th>
                                  <th scope="col fw-bold">Status</th>
                                  <th scope="col fw-bold">Tracking Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.allAssetCycleListArr?.map(
                                  (rowData, index) => (
                                    <tr
                                      key={index}
                                      onClick={this.toAssetCycleDetails.bind(
                                        this,
                                        rowData
                                      )}
                                      style={{
                                        backgroundColor:
                                          rowData.id === this.state.assetCycleId
                                            ? "#FEF5E7"
                                            : "#fff",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <td style={{ width: "6px" }}>
                                        {this.state.pageNo *
                                          this.state.showPerPage +
                                          index +
                                          1}
                                      </td>
                                      <td>{rowData.customer}</td>
                                      <td>{rowData.name}</td>
                                      <td>{rowData.cycleplanname}</td>
                                      <td>{rowData.assetuid}</td>
                                      <td>{rowData.assetname}</td>
                                      <td>{rowData.startDate}</td>
                                      <td>{rowData.endDate}</td>
                                      <td>{rowData.status}</td>
                                      <td>
                                        {this.assetTrackingStatus(
                                          rowData.trackingHistoryStatus
                                        )}{" "}
                                        {rowData.trackingHistoryStatus}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          {this.state.totalRecords === 0 && (
                            <div className="row text-center p-2">
                              <h5>Data not Available</h5>
                            </div>
                          )}
                          {this.state.totalRecords > 10 && (
                            <div className="row justify-content-between pagination p-2">
                              <div className="d-flex col-12 col-lg-4 col-xl-4">
                                <div className="pageSize col-12 text-start">
                                  Page {this.state.pageNo + 1} of{" "}
                                  {this.state.totalPages}
                                </div>
                              </div>
                              <div className="d-flex col-12 col-lg-8 col-xl-8">
                                <div className="col-12 text-end">
                                  <Pagination
                                    onPaginationChange={this.onPaginationChange.bind(
                                      this
                                    )}
                                    showPerPage={this.state.showPerPage}
                                    total={this.state.totalRecords}
                                    pageNo={this.state.pageNo}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
        </>
      );
    }
  }
}
export default withRouter(AssetCycleList);
