import AddIcon from "@mui/icons-material/Add";
import CategoryIcon from "@mui/icons-material/Category";
import MinimizeIcon from "@mui/icons-material/Minimize";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import React, { Component } from "react";
import { Col, Row, Card } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import store from "../../redux/store";
import { withRouter } from "../../withRouter";
import PtsButton from "../buttons/PtsButton";
// import "./CatalogueBreadScrum.css";

class MoveCategory extends Component {
  header = store.getState().header.header;
  constructor(props) {
    super(props);
    // let assetId = "";
    let categoryId = "";
    if (!categoryId && props.state) {
      categoryId = props.state.categoryId;
      //  assetId = props.state.assetId;
    }
    this.getMoveCategoryListPath(categoryId);
    this.state = {
      name: this.props.name,
      categoryList: [],
      status: this.props.status,
      render: true,
      pageId: this.props.pageId,
      catalogueId: "",
      topCategories: [],
      categoryId: categoryId,
      categoryBreadCrumb: [],
      childCategories: [],
      targetCategory: "",
      catalogueName: "",
      treeItemId: [],
      collapseTreeItem: [],
      checked: {},
    };
  }

  getMoveCategoryListPath(categoryId) {
    const postObject = {
      header: this.header,
      category: {
        id: categoryId,
      },
    };
    FetchServerData.callPostService(
      "/categoryms/getFullHierarchy",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let categoryMoveDetails = output.data.catalogue;
        this.setState({
          render: true,
          catalogueData: categoryMoveDetails,
          categoryList: categoryMoveDetails.childCategories,
          catalogueId: categoryMoveDetails.id,
          catalogueName: categoryMoveDetails.name,
        });
      }
    });
  }

  handleSelectToggle = (event, nodeId) => {
    const index = this.state.treeItemId.indexOf(nodeId);
    const copyExpanded = [...this.state.treeItemId];
    if (index === -1) {
      copyExpanded.push(nodeId);
    } else {
      copyExpanded.splice(index, 1);
    }
    // setExpanded(copyExpanded);
    this.setState({
      treeItemId: copyExpanded,
    });
  };

  onSelectedChange = (event, selectedAsset) => {
    const { checked } = this.state;
    const checkedValues = { ...checked };
    checkedValues[event.target.name] = event.target.checked;

    this.setState((prevState, currState) => {
      return {
        ...prevState,
        checked: checkedValues,
        targetCategory: selectedAsset.id,
      };
    });
  };

  handleToggle = (event, nodeIds) => {
    this.setState({
      treeItemId: nodeIds,
    });
  };

  moveCategory() {
    const postObject = {
      header: this.header,
      category: {
        id: this.state.categoryId,
      },
      targetCategory: {
        id: this.state.targetCategory,
      },
    };
    FetchServerData.callPostService("/categoryms/move", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.props.navigate("/category", {
            state: {
              categoryId: this.state.categoryId,
              catalogueId: this.state.catalogueId,
            },
          });
        }
      }
    );
  }
  getTreeItemsFromData = (treeItems) => {
    return treeItems.map((treeItemData, index) => {
      let children = undefined;
      if (
        treeItemData.childCategories &&
        treeItemData.childCategories.length > 0
      ) {
        children = this.getTreeItemsFromData(treeItemData.childCategories);
      }
      if (treeItemData.selected === true) {
        this.state.treeItemId.push(treeItemData.id);
      }

      const { checked } = this.state;
      const checkedValues = { ...checked };
      const checkedCount = Object.values(checkedValues).filter(
        (value) => value
      ).length;
      return (
        <TreeItem
          key={treeItemData.id}
          nodeId={treeItemData.id.toString()}
          // label={treeItemData.name}

          label={
            <ul style={{ listStyle: "none" }} key={index}>
              <li
                style={{
                  padding: "0.5em",
                }}
              >
                {treeItemData.canAddCategory &&
                  treeItemData.canAddCategory === true ? (
                  <Row>
                    <Col>
                      <span
                        style={{
                          color: "black",
                          float: "left",
                          fontWeight:
                            treeItemData.selected === true ? "bold" : "none",
                        }}
                      >
                        {treeItemData.name}
                      </span>
                      &nbsp;&nbsp;&nbsp;
                      {parseInt(treeItemData.id) !== this.state.categoryId ?
                        <input
                          type="checkbox"
                          id="topping"
                          key={index}
                          name={treeItemData.id}
                          onChange={(e) => this.onSelectedChange(e, treeItemData)}
                          value={treeItemData.id}
                          style={{ float: "left", margin: " 0.5em 0.2em" }}
                          checked={this.state.checked[treeItemData.id] || false}
                          disabled={
                            !checkedValues[treeItemData.id] && checkedCount > 0
                          }
                        />
                        : ""
                      }
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <span
                        style={{
                          color: "black",
                          float: "left",
                          fontWeight:
                            treeItemData.selected === true ? "bold" : "none",
                        }}
                      >
                        {treeItemData.name}
                      </span>
                    </Col>
                  </Row>
                )}
              </li>
            </ul>
          }
          children={children}
        />
      );
    });
  };

  moveAsset() {
    const postObject = {
      header: this.header,
      category: {
        id: this.state.categoryId,
      },
      targetCategory: {
        id: this.state.targetCategory,
      },
    };
    FetchServerData.callPostService("/categoryms/move", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          this.props.navigate("/category", {
            state: {
              categoryId: this.state.categoryId,
              catalogueId: this.state.catalogueId,
            },
          });
        }
      }
    );
  }
  cancelMoveCategory() {
    this.props.navigate("/category", {
      state: {
        categoryId: this.state.categoryId,
        catalogueId: this.state.catalogueId,
        view: "categoryView",
      },
    });
  }
  render() {
    if (!this.state.render || this.state.render === false) {
      return;
    }
    return (
      <>
        <Card
          style={{ overflowY: "auto", overflowX: "hidden", padding: " 1em" }}
        >
          <Row>
            <Col
              md={12}
              className="toolBarCol"
              style={{
                textTransform: "capitalize",
                margin: "0.5em auto auto auto",
              }}
            >
              <Row>
                <Col>
                  <CategoryIcon
                    style={{
                      color: "#DC7633",
                      fontSize: "18px",
                      marginBottom: "3px",
                      float: "left",
                    }}
                  />
                  &nbsp; &nbsp;
                  <span
                    style={{
                      color: " #DC7633",
                      fontWeight: "bold",
                      float: "left",
                    }}
                  >
                    &nbsp;{this.state.catalogueName}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TreeView
                    expanded={
                      this.state.treeItemId ? this.state.treeItemId : []
                    }
                    onNodeToggle={this.handleToggle}
                    onNodeSelect={this.handleSelectToggle}
                    collapsed={this.state.collapseTreeItem}
                    defaultCollapseIcon={
                      <MinimizeIcon
                        style={{
                          background: "gray",
                          color: "white",
                          paddingBottom: "0.4em",
                        }}
                      />
                    }
                    defaultExpandIcon={
                      <AddIcon style={{ background: "gray", color: "white" }} />
                    }
                  >
                    {this.getTreeItemsFromData(this.state.categoryList)}
                  </TreeView>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <div
          className="modal-footer"
          style={{ padding: "1.2rem 1.5rem 1.5rem" }}
        >
          <PtsButton
            btnName="Move"
            type="submit"
            onClick={this.moveAsset.bind(this)}
          />
          <button
            type="button"
            className="btn btn-label-secondary"
            data-bs-dismiss="modal"
            style={{ background: "#8592a3", color: "#fff" }}
            onClick={this.cancelMoveCategory.bind(this)}
          >
            Close
          </button>
        </div>
      </>
    );
  }
}

export default withRouter(MoveCategory);
