import { GET_CATALOGUE, SET_CATALOGUE, CLEAR_CATALOGUE } from "./CatalogueType";

const initialState = {};
const CatalogueReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATALOGUE:
      return {
        ...state,
      };
    case SET_CATALOGUE:
      return {
        ...state,
        Catalogue: action.payload,
      };

    case CLEAR_CATALOGUE:
      return {
        ...state,
        Catalogue: null,
      };
    default:
      return state;
  }
};

export default CatalogueReducer;
