import { GET_CUSTOMER, SET_CUSTOMER } from "./CustomerTypes";

const getCustomer = () => {
  return {
    type: GET_CUSTOMER,
  };
};

const setCustomer = (data) => {
  return {
    type: SET_CUSTOMER,
    payload: data,
  };
};
const CustomerAction = { getCustomer, setCustomer };

export default CustomerAction;
