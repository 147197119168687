import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Col, Row } from 'reactstrap';
import './ForgotPassword.css';
import { NavLink } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PtsAlert from '../ptsAlert/PtsAlert';
import FetchServerData from '../../provider/FetchServerData';
import logo from '../../assets/images/AssetTrackifierLogo.png'
import { Autocomplete } from '@mui/material';
import TextField from "@material-ui/core/TextField";
import { withRouter } from "../../withRouter";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    let email = "";
    let tenantList = [];
    let render = false;
    if (props.state) {
      email = props.state.email;
      tenantList = props.state.tenantList;
      render = props.state.render;
    }
    this.state = {
      email: email,
      tenantList: tenantList,
      tenantId: tenantList[0],
      render: render,
    };
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleCompanyChange = (selectedValue) => {
    this.setState({ tenantId: selectedValue })
  }

  forgotPasswordBackend() {
    let header = { email: this.state.email, tid: this.state.tenantId.id };
    const postObject = {
      header: header,
      appuser: {
        email: this.state.email,
      }
    };

    FetchServerData.callPostService('/appuserms/forgetPassword', postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.props.navigate("/resetPassword", {
            state: {
              email: this.state.email,
              tenantId: this.state.tenantId,
              tenantList: this.state.tenantList
            },
          });
        } else {
          if (output.status === 'FAIL') {
            PtsAlert.error('Please enter Registered Email ID');
          }
        }
      })
      .catch((error) => { });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <div className="authentication-wrapper authentication-cover">
            <div className="authentication-inner row m-0">
              <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center leftBackground ">
                <div className="mx-auto">
                  <div className="logoBackground">
                    <img
                      src={logo}
                      className="img-fluid logoImage"
                      alt=""
                      data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                      data-app-light-img="illustrations/girl-with-laptop-light.png"
                    />
                  </div>
                  <div className="align-items-center">
                    <h2 className="logoHeding">AssetTrackifier</h2>
                  </div>
                </div>
              </div>
              <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center authentication-bg p-sm-5 p-4">
                <div className="w-px-400 mx-auto">
                  <Row>
                    <Col md={12} className="forgotPasswordSubTitle">
                      <h4>Forgot Password?</h4>
                      <p>
                        We will send you an email that will allow you to reset
                        your password.
                      </p>
                    </Col>
                  </Row>
                  <ValidatorForm
                    ref="form"
                    id="formAuthentication"
                    className="mb-3"
                    onSubmit={this.forgotPasswordBackend.bind(this)}
                  >
                    <div className="mb-4">
                      <label htmlFor="email" className="form-label" id="signupFormLabel">
                        EMAIL ID <span className="asterisk">*</span>
                      </label>
                      <TextValidator
                        id="email"
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        placeholder="Enter Email"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleEmailChange.bind(this)}
                        autoComplete="off"
                        validators={["required", "isEmail"]}
                        errormessages={[
                          "This field is required",
                          "Email id is not valid",
                        ]}
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label"
                        id="signupFormLabel"
                      >
                        Company name
                        <span className="asterisk">*</span>
                      </label>
                      <Autocomplete
                        id="tenantList"
                        options={this.state.tenantList}
                        value={this.state.tenantId}
                        defaultValue={this.state.tenantId}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.handleCompanyChange(value);
                        }}
                        inputprops={{ style: { textTransform: "capitalize" } }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Company"
                            variant="outlined"
                            required
                            margin="normal"
                          />
                        )}
                      />
                    </div>
                    <button className="goButton d-grid w-100">
                      Send Verification Mail
                    </button>
                  </ValidatorForm>
                  <div style={{ textAlign: 'left', padding: '1em 0em' }}>
                    <NavLink to="/signinEmail">
                      <ArrowBackIosNewIcon />
                      Back Sign In
                    </NavLink>
                    &nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };
  };
};

export default withRouter(ForgotPassword);
