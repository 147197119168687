import React, { Component } from "react";
import MaterialTable from "material-table";
import UiUtils from "../../../provider/UiUtils";
import FetchServerData from "../../../provider/FetchServerData";
import { withRouter } from "../../../withRouter";
import { Card, CardBody } from "reactstrap";
import { MenuWithBreadScrum } from "../../menu/MenuWithBreadScrum";
import { NavLink } from "react-router-dom";
import { Buffer } from "buffer";
import store from "../../../redux/store";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import Utils from "../../../provider/Utils";

class OrganizationAssetList extends Component {
  header = store.getState().header.header;
  breadCrumb = [
    { pageid: "/reports", uiname: "MIS Reports" },
    { pageid: "", uiname: "Asset Stock Analytics" },
  ];

  constructor() {
    super();
    this.state = {
      initialPage: 0,
      pageSize: 10,
      organizationDetails: {},
      organizationAssetList: [],
      isPaging: false,
      render: false,
    };
    this.getOrganizationAssetList();
  }
  getOrganizationAssetList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService(
      "/assetcodeStatsms/getOrgStats",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let organizationAssetList = output.data.orgStatistic;
        let totalStatistics = output.data.totalStatistics;
        let organizationDetails = output.data.organization;
        let isPaging = false;
        if (organizationAssetList.length > this.state.pageSize) {
          isPaging = true;
        }
        this.setState({
          render: true,
          isPaging: isPaging,
          initialPage: this.state.initialPage,
          organizationDetails: organizationDetails,
          organizationAssetList: organizationAssetList,
          activecount: totalStatistics.active,
          discardedcount: totalStatistics.discarded,
          inmaintainancecount: totalStatistics.inmaintainance,
          damagedcount: totalStatistics.damaged,
          total: totalStatistics.total,
        });
      } else {
      }
    });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  activeAsset() {
    this.props.navigate("/activeAssets", {
      state: {
        pageId: "activeAsset",
      },
    });
  }
  damageAsset() {
    this.props.navigate("/damagedAssets", {
      state: {
        pageId: "damagedAssets",
      },
    });
  }
  inMaintenanceAssets() {
    this.props.navigate("/inMaintenanceAssets", {
      state: {
        pageId: "inMaintenanceAssets",
      },
    });
  }
  discardedAsset() {
    this.props.navigate("/discardedAssets", {
      state: {
        pageId: "discardedAssets",
      },
    });
  }

  downloadDayExcelFromBackend() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService(
      "/assetcodeStatsms/getExcel",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        const byteArray = new Buffer(
          output.data.excelData.replace(/^[\w\d;:\/]+base64\,/g, ""),
          "base64"
        );
        const outputFilename = `${Date.now()}.xlsx`;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
      } else {
      }
    });
  }
  backButton() {
    this.props.navigate("/reports");
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            // backCallback={this.backButton.bind(this)}
            domainObject="Active Asset"
            name="Asset Stock Analytics"
          />
          {this.state.organizationAssetList.length > 0 ? (
            <>
              <div
                className="row"
                style={{ padding: "1em", marginTop: "-12px" }}
              >
                <div className="card" id="organizationFormCard">
                  <div className="row">
                    <div
                      className="card-header d-flex align-items-center justify-content-between"
                      id="assetCardHeader"
                    >
                      <h5 className="mb-0" id="headerLabel">
                        {this.state.organizationDetails?.name}
                      </h5>

                      <NavLink
                        className="navLink text-decoration-underline"
                        to="/customerWiseAssetList"
                      >
                        Customer wise Stock
                      </NavLink>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-sm-12" id="OrganizationCol">
                              <span>
                                {this.state.organizationDetails?.addresss}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-12 row justify-content-end">
                <div className="col-sm-8 text-end">
                  <Button
                    variant="contained"
                    size="small"
                    id="excelBtn"
                    // style={{ background: '#3498DB' }}
                    startIcon={<i class="fa fa-download"></i>}
                    onClick={(e) => this.downloadDayExcelFromBackend()}
                  >
                    Download Excel
                  </Button>
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        variant="contained"
                        size="small"
                        id="printBtn"
                        style={{
                          background: "#0577ab",
                          color: "#fff",
                          padding: "7px",
                          width: "100px",
                        }}
                        startIcon={<PrintRoundedIcon />}
                      >
                        print
                      </Button>
                    )}
                    content={() => this.componentRef}
                  />
                  &nbsp; &nbsp;
                </div>
              </div>
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  title="Asset Code wise Distribution Of Assets"
                  // title={`Total Assets:- ${(this.state.total !== null ? this.state.total : "")}`}
                  tableRef={(el) => (this.componentRef = el)}
                  icons={UiUtils.getMaterialTableIcons()}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "Please add customer",
                    },
                  }}
                  columns={[
                    {
                      title: "#",
                      render: (rowData) => rowData.tableData.id + 1,
                      width: "5%",
                      align: "left",
                      cellStyle: {
                        width: "6%",
                      },
                    },
                    {
                      title: "Asset Codes",
                      field: "assetcode",
                      cellStyle: {
                        width: "12%",
                      },
                    },
                    {
                      title: `Total Assets ${"(" + this.state.total + ")"}`,
                      field: "total",
                      align: "center",
                      cellStyle: {
                        width: "15%",
                      },
                    },
                    {
                      title: `Active Assets ${
                        "(" + this.state.activecount + ")"
                      }`,
                      field: "active",
                      align: "center",
                      cellStyle: {
                        width: "15%",
                      },
                    },
                    {
                      title: `Damaged Assets    ${
                        "(" + this.state.damagedcount + ")"
                      }`,
                      field: "damaged",
                      align: "center",
                      cellStyle: {
                        width: "15%",
                      },
                    },
                    {
                      title: `InMaintainance Assets ${
                        "(" + this.state.inmaintainancecount + ")"
                      }`,
                      field: "inmaintainance",
                      align: "center",
                      cellStyle: {
                        width: "18%",
                      },
                    },
                    {
                      title: `Discarded Assets ${
                        "(" + this.state.discardedcount + ")"
                      }`,
                      field: "discarded",
                      align: "center",
                      cellStyle: {
                        width: "16%",
                      },
                    },
                  ]}
                  data={this.state.organizationAssetList}
                  options={{
                    sorting: true,
                    search: false,
                    actionsColumnIndex: -1,
                    initialPage: this.state.initialPage,
                    pageSize: this.state.pageSize,
                    pageSizeOptions: Utils.materialTablePageSize(),
                    paging: this.state.isPaging,
                    headerStyle: {
                      backgroundColor: "#0B6F9D",
                      fontWeight: "bold",
                      color: "#ffff",
                      align: "left",
                    },
                  }}
                  actions={[
                    {
                      icon: () => (
                        <div className="row">
                          <div
                            className="card card-body"
                            style={{
                              backgroundColor: "mediumturquoise",
                              padding: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              className="row align-items-center"
                              style={{ margin: "0px" }}
                              onClick={() => this.activeAsset()}
                            >
                              <p className="dashboardCardText">
                                Active : {this.state.activecount}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      ),
                      tooltip: "Active Asset",
                      isFreeAction: true,
                    },
                    {
                      icon: () => (
                        <div className="row">
                          <div
                            className="card card-body"
                            style={{
                              backgroundColor: "lightcoral",
                              padding: "5px",
                              marginLeft: "30px",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              className="row align-items-center"
                              style={{ margin: "0px" }}
                              onClick={() => this.damageAsset()}
                            >
                              <p className="dashboardCardText">
                                Damaged : {this.state.damagedcount}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      ),
                      tooltip: "Damaged Asset",
                      isFreeAction: true,
                    },
                    {
                      icon: () => (
                        <div className="row">
                          <div
                            className="card card-body"
                            style={{
                              backgroundColor: "steelblue",
                              padding: "5px",
                              marginLeft: "30px",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              className="row align-items-center"
                              style={{ margin: "0px" }}
                              onClick={() => this.inMaintenanceAssets()}
                            >
                              <p className="dashboardCardText">
                                InMaintainance :{" "}
                                {this.state.inmaintainancecount}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      ),
                      tooltip: "Damaged Asset",
                      isFreeAction: true,
                    },
                    {
                      icon: () => (
                        <div className="row">
                          <div
                            className="card card-body"
                            style={{
                              backgroundColor: "peru",
                              padding: "5px",
                              marginLeft: "30px",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              className="row align-items-center"
                              style={{ margin: "0px" }}
                              onClick={() => this.discardedAsset()}
                            >
                              <p className="dashboardCardText">
                                Discarded : {this.state.discardedcount}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      ),
                      tooltip: "Damaged Asset",
                      isFreeAction: true,
                    },
                  ]}
                />
              </div>
            </>
          ) : (
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-12" style={{ padding: "2em" }}>
                        <h5>
                          <b>Organization Assets Not Found</b>
                        </h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          )}
        </>
      );
    }
  }
}
export default withRouter(OrganizationAssetList);
