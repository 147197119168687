import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../component/footer/Footer";
import Navbar from "../component/navbar/Navbar";
import Sidebar from "../component/sidebar/Sidebar";
import "./ApplicationLayout.css"
class ApplicationLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
  }
  handleClick = (isOpenState) => {
    this.setState({ isOpen: isOpenState });
  }
  render() {
    return (
      <div id="application-layout">
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <Sidebar handleClick={this.handleClick} />
            <div className={this.state.isOpen ? "row fixed-top navOpen" : "row fixed-top navClose"}>
              <Navbar history={this.props.history} />
            </div>
            <div className={this.state.isOpen ? "layout-page layoutOpen" : "layout-page layoutClose"}>
              <div className="content-wrapper" style={{ marginTop: "60px" }}>
                {/* Content */}
                <div className="container-fluid flex-grow-1 container-p-y">
                  <Outlet />
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicationLayout;
