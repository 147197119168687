import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import FetchServerData from "../../provider/FetchServerData";
import UiUtils from "../../provider/UiUtils";
import Utils from "../../provider/Utils";
import store from "../../redux/store";
import { withRouter } from "../../withRouter";
import BackButton from "../buttons/BackButton";
import CancelIconButton from "../buttons/CancelIconButton";
import PtsButton from "../buttons/PtsButton";
import SaveButton from "../buttons/SaveButton";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import PtsAlert from "../ptsAlert/PtsAlert";
import Tooltip from "../../ui/tooltip/Tooltip";
import infoIcon from "../../assets/images/infoIcon.png";
import "./Customer.css";
import { InputAdornment } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CreateButton from "../buttons/CreateButton";
import AddressWithTimezone from "../addressWithTimezone/AddressWithTimezone";

class Customer extends Component {
  addressRef = React.createRef();
  header = store.getState().header.header;
  customerTypeList = store.getState().staticData.customerTypeList;
  customerStatusList = store.getState().staticData.customerStatusList;
  cycleFrequencyList = store.getState().staticData.cycleFrequencyList;
  breadCrumb = [
    { pageid: "/customerList", uiname: "Customer List" },
    { pageid: "", uiname: "Customer Details" },
  ];

  constructor(props) {
    super(props);
    let action = "";
    let customerId = "";
    if (!action && props.state) {
      action = props.state.action;
      customerId = props.state.customerId;
    }
    if (
      action &&
      Utils.equalsIgnoreCase(
        action,
        "view" || Utils.equalsIgnoreCase(action, "edit")
      )
    ) {
      this.getCustomerDetails(customerId);
    }
    this.state = {
      action: action,
      customerId: customerId,
      name: "",
      custabbreviation: "",
      panNo: "",
      gstn: "",
      email: "",
      phone: "",
      description: "",
      cycletime: "",
      cycletimeFrequency: null,
      holdingPeriod: "",
      holdingPeriodFrequency: null,
      locName: "",
      transitTime: "",
      transitTimeFrequency: null,
      returnTransitTime: "",
      returnTransitTimeFrequency: null,
      locHoldingPeriod: "",
      locHoldingPeriodFrequency: null,
      customerType: "",
      customerTypeId: "",
      isStatus: true,
      render: false,
      getUpdatePermission: false,
      getDeletePermission: false,
      initialPage: 0,
      pageSize: 10,
      customerList: [],
      isPaging: false,
      getCreatePermission: false,
      showLocationTable: true,
      locations: [],
      address: {},
      error: false,
      locError: false,
    };
  }

  getCustomerDetails(customerId) {
    const postObject = {
      header: this.header,
      customer: {
        id: customerId,
      },
    };

    FetchServerData.callPostService("/costomerms/get", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          window.scrollTo(0, 0);
          let customerDetails = output.data.customer;
          let customerBaseLocation = output.data.customerBaseLocation;
          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getCreatePermission = getPermission[1];
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let customerStatus = "";
          if (customerStatus === null) {
            customerStatus = false;
          } else {
            if (customerDetails.custStatus.status === "Active") {
              customerStatus = true;
            } else {
              customerStatus = false;
            }
          }
          this.setState({
            render: true,
            action: "view",
            customerId: customerDetails.id,
            name: customerDetails.name,
            custabbreviation:
              customerDetails.abbreviation !== null
                ? customerDetails.abbreviation
                : "",
            panNo: customerDetails.pan,
            gstn: customerDetails.gstn,
            email: customerDetails.email,
            phone: customerDetails.phone,
            description: customerDetails.description,
            customerType: customerDetails.customertype,
            customerTypeId: customerDetails.customertype.id,
            isStatus: customerStatus,
            customerStatus: customerDetails.custStatus.status,
            getCreatePermission: getCreatePermission,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
            locations: customerDetails.locations,
            holdingPeriod: customerDetails.holdingPeriod,
            holdingPeriodFrequency:
              customerDetails.holdingPeriodFrequency !== null
                ? customerDetails.holdingPeriodFrequency
                : "",
            holdingPeriodFrequencyId:
              customerDetails.holdingPeriodFrequency !== null
                ? customerDetails.holdingPeriodFrequency.id
                : "",
            cycletime: customerDetails.cycletime,
            cycletimeFrequency: customerDetails.cycletimeFrequency,
            address: customerBaseLocation?.address,
            custLocId: customerBaseLocation?.id,
            locName: customerBaseLocation?.name,
            locHoldingPeriod: customerBaseLocation?.holdingPeriod,
            locHoldingPeriodFrequency:
              customerBaseLocation !== null
                ? customerBaseLocation?.holdingPeriodFrequency
                : null,
            locHoldingPeriodFrequencyId:
              customerBaseLocation?.holdingPeriodFrequency !== null
                ? customerBaseLocation?.holdingPeriodFrequency.id
                : "",
            transitTime: customerBaseLocation?.transittime,
            transitTimeFrequency:
              customerBaseLocation !== null
                ? customerBaseLocation?.transittimeFrequency
                : null,
            returnTransitTime: customerBaseLocation?.returnTransittime,
            returnTransitTimeFrequency:
              customerBaseLocation !== null
                ? customerBaseLocation?.returnTransittimeFrequency
                : null,
          });
        } else {
        }
      }
    );
  }
  viewCancel() {
    this.props.navigate("/customerList");
  }
  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }
  handleCustomerTypeChange(e) {
    this.setState({
      customerType: e.target.value === 1 ? "Registered" : "UnRegistered",
      customerTypeId: e.target.value,
    });
  }
  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }
  handleCustAbbreviationChange(e) {
    this.setState({ custabbreviation: e.target.value });
  }
  handleLocNameChange(e) {
    this.setState({ locName: e.target.value });
  }
  handleGstnChange(e) {
    if (e.target.value.length === 15) {
      this.setState({ panNo: e.target.value.toUpperCase().substring(2, 12) });
    }
    this.setState({ gstn: e.target.value.toUpperCase() });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePhoneChange(e) {
    let res = e.target.value;
    if (res.length <= 10) {
      this.setState({ phone: res });
    }
  }
  handlePanNoChange(e) {
    let res = e.target.value;
    if (res.length <= 10) {
      this.setState({ panNo: res });
    } else {
      Utils.maxFieldLength(10);
    }
  }
  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }
  handleAddressChange(address) {
    this.setState({ address: address });
  }

  vadidateCycleTime(
    cycletime,
    holdingPeriod,
    cycletimeFrequency,
    holdingPeriodFrequency
  ) {
    if (cycletimeFrequency !== null || holdingPeriodFrequency !== null) {
      if (
        cycletimeFrequency !== null &&
        cycletimeFrequency.frequency === "Days"
      ) {
        cycletime = cycletime * 24;
      }
      if (
        holdingPeriodFrequency !== null &&
        holdingPeriodFrequency.frequency === "Days"
      ) {
        holdingPeriod = holdingPeriod * 24;
      }
    }
    if (cycletime > holdingPeriod) {
      this.setState({ error: false });
    } else {
      this.setState({ error: true });
    }
  }

  vadidateLocCycleTime(
    locCycletime,
    locHoldingPeriod,
    locCycletimeFrequency,
    locHoldingPeriodFrequency
  ) {
    if (locCycletimeFrequency !== null || locHoldingPeriodFrequency !== null) {
      if (
        locCycletimeFrequency !== null &&
        locCycletimeFrequency.frequency === "Days"
      ) {
        locCycletime = locCycletime * 24;
      }
      if (
        locHoldingPeriodFrequency !== null &&
        locHoldingPeriodFrequency.frequency === "Days"
      ) {
        locHoldingPeriod = locHoldingPeriod * 24;
      }
    }
    if (locCycletime > locHoldingPeriod) {
      this.setState({ locError: false });
    } else {
      this.setState({ locError: true });
    }
  }

  handleCycleTimeChange(e) {
    this.vadidateCycleTime(
      parseFloat(e.target.value),
      this.state.holdingPeriod,
      this.state.cycletimeFrequency,
      this.state.holdingPeriodFrequency
    );
    this.setState({ cycletime: e.target.value });
  }

  handleCycleTimeFrequencyChange(selectedFrequency) {
    this.vadidateCycleTime(
      this.state.cycletime,
      this.state.holdingPeriod,
      selectedFrequency,
      this.state.holdingPeriodFrequency
    );
    this.setState({
      cycletimeFrequency: selectedFrequency,
    });
  }

  handleTransitTimeChange(e) {
    // this.vadidateLocCycleTime(parseFloat(e.target.value), this.state.locHoldingPeriod, this.state.locCycletimeFrequency, this.state.locHoldingPeriodFrequency);
    this.setState({ transitTime: e.target.value });
  }

  handleTransitTimeFrequencyChange(selectedFrequency) {
    // this.vadidateLocCycleTime(this.state.locCycletime, this.state.locHoldingPeriod, selectedFrequency, this.state.locHoldingPeriodFrequency);
    this.setState({
      transitTimeFrequency: selectedFrequency,
    });
  }
  handleReturnTransitTimeChange(e) {
    this.setState({ returnTransitTime: e.target.value });
  }

  handleReturnTransitTimeFrequencyChange(selectedFrequency) {
    this.setState({
      returnTransitTimeFrequency: selectedFrequency,
    });
  }

  handleHoldingPeriodChange(e) {
    this.vadidateCycleTime(
      this.state.cycletime,
      parseFloat(e.target.value),
      this.state.cycletimeFrequency,
      this.state.holdingPeriodFrequency
    );
    this.setState({ holdingPeriod: e.target.value });
  }
  handleLocHoldingPeriodChange(e) {
    // this.vadidateLocCycleTime(this.state.locCycletime, parseFloat(e.target.value), this.state.locCycletimeFrequency, this.state.locHoldingPeriodFrequency);
    this.setState({ locHoldingPeriod: e.target.value });
  }

  handleHoldingPeriodFrequencyChange(selectedFrequency) {
    this.vadidateCycleTime(
      this.state.cycletime,
      this.state.holdingPeriod,
      this.state.cycletimeFrequency,
      selectedFrequency
    );
    this.setState({
      holdingPeriodFrequency: selectedFrequency,
      holdingPeriodFrequencyId: selectedFrequency.id,
    });
  }
  handleLocHoldingPeriodFrequencyChange(selectedFrequency) {
    // this.vadidateLocCycleTime(this.state.locCycletime, this.state.locHoldingPeriod, this.state.locCycletimeFrequency, selectedFrequency);
    this.setState({
      locHoldingPeriodFrequency: selectedFrequency,
      locHoldingPeriodFrequencyId: selectedFrequency.id,
    });
  }

  createCustomerToBackend() {
    if (this.state.custabbreviation.length !== 2) {
      PtsAlert.error("Customer Abbreviation must be 2 Character log");
      return false;
    }
    if (this.state.gstn.length > 0) {
      if (!Utils.checkGstnLength(this.state.gstn)) {
        return;
      }
    }
    if (!Utils.checkPanLength(this.state.panNo)) {
      return;
    }
    const postObject = {
      header: this.header,
      customer: {
        name: this.state.name,
        abbreviation: this.state.custabbreviation,
        gstn: this.state.gstn ? this.state.gstn : "",
        email: this.state.email,
        phone: this.state.phone,
        pan: this.state.panNo,
        description: this.state.description,
        cycletime: this.state.cycletime,
        cycletimeFrequency: this.state.cycletimeFrequency,
        holdingPeriod: this.state.holdingPeriod,
        holdingPeriodFrequency: this.state.holdingPeriodFrequency,
        customertype: {
          id: this.state.customerTypeId,
          type: this.state.customerType,
        },
      },
      customerLocation: {
        name: this.state.locName,
        transittime: this.state.transitTime,
        transittimeFrequency: this.state.transitTimeFrequency,
        returnTransittime: this.state.returnTransitTime,
        returnTransittimeFrequency: this.state.returnTransitTimeFrequency,
        holdingPeriod: this.state.locHoldingPeriod,
        holdingPeriodFrequency: this.state.locHoldingPeriodFrequency,
        address: {
          line: this.addressRef.current.state.line,
          state: {
            name: this.addressRef.current.state.state.name,
            code: this.addressRef.current.state.state.code,
            id: this.addressRef.current.state.state.id,
            country: this.addressRef.current.state.country,
          },
          city: this.addressRef.current.state.city,
          zipcode: this.addressRef.current.state.zipCode,
          latitude: this.addressRef.current.state.latitude,
          longitude: this.addressRef.current.state.longitude,
        },
      },
    };
    FetchServerData.callPostService("/costomerms/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let customerDetails = output.data.customer;
          this.getCustomerDetails(customerDetails.id);
          this.setState({
            action: "view",
          });
        } else {
        }
      }
    );
  }

  createCancel() {
    this.props.navigate("/customerList");
  }

  editCancel() {
    this.getCustomerDetails(this.state.customerId);
  }
  editCustomerDetails(e) {
    this.setState({
      action: "edit",
      customerId: this.state.customerId,
    });
  }

  updateCustomerToBackend() {
    if (this.state.custabbreviation.length !== 2) {
      PtsAlert.error("Customer Abbreviation must be 2 Character log");
      return false;
    }

    let custStatus;
    if (this.state.isStatus === true) {
      custStatus = "Active";
    } else {
      custStatus = "InActive";
    }

    if (this.state.gstn.length > 0) {
      if (!Utils.checkGstnLength(this.state.gstn)) {
        return;
      }
    }
    if (!Utils.checkPanLength(this.state.panNo)) {
      return;
    }
    const postObject = {
      header: this.header,
      customer: {
        name: this.state.name,
        email: this.state.email,
        gstn: this.state.gstn,
        phone: this.state.phone,
        pan: this.state.panNo,
        id: this.state.customerId,
        abbreviation: this.state.custabbreviation,
        description: this.state.description,
        cycletime: this.state.cycletime,
        cycletimeFrequency: this.state.cycletimeFrequency,
        holdingPeriod: this.state.holdingPeriod,
        holdingPeriodFrequency: this.state.holdingPeriodFrequency,
        customertype: {
          id: this.state.customerTypeId,
          type: this.state.customerType.type,
        },
        custStatus: {
          status: custStatus,
        },
      },
      customerLocation: {
        name: this.state.locName,
        id: this.state.custLocId,
        transittime: this.state.transitTime,
        transittimeFrequency: this.state.transitTimeFrequency,
        returnTransittime: this.state.returnTransitTime,
        returnTransittimeFrequency: this.state.returnTransitTimeFrequency,
        holdingPeriod: this.state.locHoldingPeriod,
        holdingPeriodFrequency: this.state.locHoldingPeriodFrequency,
        address: {
          id: this.addressRef.current.state.id,
          line: this.addressRef.current.state.line,
          state: {
            name: this.addressRef.current.state.state.name,
            code: this.addressRef.current.state.state.code,
            id: this.addressRef.current.state.state.id,
            country: this.addressRef.current.state.country,
          },
          city: this.addressRef.current.state.city,
          zipcode: this.addressRef.current.state.zipCode,
          latitude: this.addressRef.current.state.latitude,
          longitude: this.addressRef.current.state.longitude,
        },
      },
    };
    FetchServerData.callPostService("/costomerms/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let customerData = output.data.customer;
          this.getCustomerDetails(customerData.id);
        } else {
        }
      }
    );
  }
  deleteCustomerToBackend() {
    const postObject = {
      header: this.header,
      customer: {
        id: this.state.customerId,
      },
    };
    FetchServerData.callPostService("/costomerms/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.props.navigate("/customerList");
        } else {
        }
      }
    );
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  addLocation() {
    this.props.navigate("/customerLocation", {
      state: {
        action: "create",
        customerId: this.state.customerId,
      },
    });
  }

  editLocation(e, data) {
    this.props.navigate("/customerLocation", {
      state: {
        action: "edit",
        customerId: this.state.customerId,
        locationId: data.id,
      },
    });
  }
  toCustomerLocationDetails(e, data) {
    this.props.navigate("/customerLocation", {
      state: {
        action: "view",
        customerId: this.state.customerId,
        locationId: data.id,
      },
    });
  }
  deleteCustomerLocationToBackend(e, data) {
    const postObject = {
      header: this.header,
      customerLocation: {
        id: data.id,
      },
    };
    FetchServerData.callPostService(
      "/costomerLocationms/delete",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        PtsAlert.success(output.message);
        this.getCustomerDetails(this.state.customerId);
      } else {
      }
    });
  }

  getGstn() {
    if (!this.state.gstn) {
      PtsAlert.error("Enter GSTN");
      return;
    }
    const postObject = {
      gstin: this.state.gstn,
      header: this.header,
    };
    FetchServerData.callPostService("/staticms/findByGstn", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let gstnDetails = output.data.taxpayer;
          PtsAlert.success(output.message);
          this.setState({
            name: gstnDetails.legalname,
            state: gstnDetails.address?.state,
            address: gstnDetails.address,
          });
        } else {
        }
      }
    );
  }

  render() {
    if (this.state.action === "create") {
      return this.createCustomer();
    } else if (this.state.action === "edit") {
      return this.editCustomer();
    } else if (this.state.action === "view") {
      return this.viewCustomer();
    }
  }
  createCustomer() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="Customer"
          name="Create Customer"
        />
        <div className="row">
          <ValidatorForm
            className="text-start"
            ref="form"
            onSubmit={this.createCustomerToBackend.bind(this)}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="card " id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Customer Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row g-3 mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <FormControl variant="outlined" fullWidth size="small">
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                          >
                            Type<span className="asterisk"> *</span>
                          </label>
                          <Select
                            id="appointmentType"
                            value={this.state.customerTypeId}
                            placeholder="Type *"
                            required
                            onChange={(e) => {
                              this.handleCustomerTypeChange(e);
                            }}
                          >
                            {this.customerTypeList.map((event, keyIndex) => {
                              return (
                                <MenuItem key={keyIndex} value={event.id}>
                                  {event.type}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Customer Abbreviation
                          <span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Customer Abbreviation"
                          value={this.state.custabbreviation}
                          onChange={this.handleCustAbbreviationChange.bind(
                            this
                          )}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4" id="assetFormLabel">
                        {this.state.customerType != null &&
                        this.state.customerType === "Registered" ? (
                          <div className="col-md-12" id="assetFormLabel">
                            <label
                              className="form-label "
                              htmlFor="collapsible-fullname"
                            >
                              GSTN<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              type="text"
                              className="textField"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="GSTN"
                              value={this.state.gstn}
                              onChange={this.handleGstnChange.bind(this)}
                              required
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="verifyGstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        ) : (
                          <div className="col-md-12" id="assetFormLabel">
                            <label
                              className="form-label "
                              htmlFor="collapsible-fullname"
                            >
                              GSTN
                            </label>
                            <TextField
                              type="text"
                              className="textField mt-1"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="GSTN"
                              value={this.state.gstn}
                              onChange={this.handleGstnChange.bind(this)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="verifyGstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Email<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="email"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Email "
                          value={this.state.email}
                          onChange={this.handleEmailChange.bind(this)}
                          validators={["required", "isEmail"]}
                          errormessages={[
                            "This field is required",
                            "Email id is not valid",
                          ]}
                          required
                        />
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Phone
                        </label>
                        <TextField
                          type="number"
                          className="textField mt-1"
                          fullWidth
                          id="basic-default-phone"
                          variant="outlined"
                          placeholder="Phone"
                          aria-label="Phone"
                          value={this.state.phone}
                          onChange={this.handlePhoneChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Pan No<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-panNo"
                          variant="outlined"
                          placeholder="PAN NO"
                          value={this.state.panNo}
                          onChange={this.handlePanNoChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8">
                        <div className="row mb-4">
                          <div className="col-md-6 mt-1" id="assetFormLabel">
                            <label
                              className="form-label "
                              htmlFor="collapsible-fullname"
                            >
                              Cycle Time<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              type="number"
                              className="textField"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="Cycle Time"
                              value={this.state.cycletime}
                              onChange={this.handleCycleTimeChange.bind(this)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mt-1" id="assetFormLabel">
                            <label
                              className="form-label"
                              htmlFor="collapsible-fullname"
                            >
                              Cycle Time Frequency
                              <span className="asterisk"> *</span>
                            </label>
                            <Autocomplete
                              id="customerType"
                              fullWidth
                              options={this.cycleFrequencyList}
                              value={this.state.cycletimeFrequency}
                              defaultValue={this.state.cycletimeFrequency}
                              getOptionLabel={(option) => option.frequency}
                              onChange={(event, value) => {
                                this.handleCycleTimeFrequencyChange(value);
                              }}
                              inputprops={{
                                style: { textTransform: "capitalize" },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Cycle Time Frequency"
                                  variant="outlined"
                                  required
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-6 mt-1" id="assetFormLabel">
                            <label
                              className="form-label "
                              htmlFor="collapsible-fullname"
                            >
                              Holding Period<span className="asterisk"> *</span>
                              <Tooltip
                                placement="top"
                                title="Holding Period is the time asset held by Customer"
                                id="password-requirements-tooltip"
                              >
                                <img
                                  src={infoIcon}
                                  alt=""
                                  width="20"
                                  height="20"
                                />
                              </Tooltip>
                            </label>
                            <TextField
                              type="number"
                              className="textField"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="Holding Period"
                              value={this.state.holdingPeriod}
                              onChange={this.handleHoldingPeriodChange.bind(
                                this
                              )}
                              required
                            />
                            {this.state.error ? (
                              <span style={{ color: "red" }}>
                                Holding period should be less than cycle time{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6 mt-1" id="assetFormLabel">
                            <label
                              className="form-label"
                              htmlFor="collapsible-fullname"
                            >
                              Holding Period Frequency
                              <span className="asterisk"> *</span>
                            </label>
                            <Autocomplete
                              id="customerType"
                              fullWidth
                              options={this.cycleFrequencyList}
                              value={this.state.holdingPeriodFrequency}
                              defaultValue={this.state.holdingPeriodFrequency}
                              getOptionLabel={(option) => option.frequency}
                              onChange={(event, value) => {
                                this.handleHoldingPeriodFrequencyChange(value);
                              }}
                              inputprops={{
                                style: { textTransform: "capitalize" },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Holding Period Frequency"
                                  variant="outlined"
                                  required
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-description"
                        >
                          Description
                        </label>
                        <textarea
                          name="collapsible-address"
                          style={{
                            color: "black",
                            font: "inherit",
                            fontSize: "16px ",
                          }}
                          className="form-control inputTextarea"
                          id="collapsible-address"
                          rows="5"
                          placeholder="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-2" id="headerLabel">
                      Customer Location Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Location Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Name"
                          value={this.state.locName}
                          onChange={this.handleLocNameChange.bind(this)}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Transit Time<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="number"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Transit Time"
                          value={this.state.transitTime}
                          onChange={this.handleTransitTimeChange.bind(this)}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-fullname"
                        >
                          Transit Time Frequency
                          <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="customerType"
                          fullWidth
                          options={this.cycleFrequencyList}
                          value={this.state.transitTimeFrequency}
                          defaultValue={this.state.transitTimeFrequency}
                          getOptionLabel={(option) => option.frequency}
                          onChange={(event, value) => {
                            this.handleTransitTimeFrequencyChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Transit Time Frequency"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Return Transit Time
                          <span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="number"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Return Transit Time"
                          value={this.state.returnTransitTime}
                          onChange={this.handleReturnTransitTimeChange.bind(
                            this
                          )}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-fullname"
                        >
                          Return Transit Time Frequency
                          <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="customerType"
                          fullWidth
                          options={this.cycleFrequencyList}
                          value={this.state.returnTransitTimeFrequency}
                          defaultValue={this.state.transitTimeFrequency}
                          getOptionLabel={(option) => option.frequency}
                          onChange={(event, value) => {
                            this.handleReturnTransitTimeFrequencyChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Return Transit Time Frequency"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Holding Period<span className="asterisk"> *</span>
                          <Tooltip
                            placement="top"
                            title="Holding Period is the time asset held by Customer"
                            id="password-requirements-tooltip"
                          >
                            <img src={infoIcon} alt="" width="20" height="20" />
                          </Tooltip>
                        </label>
                        <TextField
                          type="number"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Holding Period"
                          value={this.state.locHoldingPeriod}
                          onChange={this.handleLocHoldingPeriodChange.bind(
                            this
                          )}
                          required
                        />
                        {this.state.locError ? (
                          <span style={{ color: "red" }}>
                            Holding period should be less than cycle time{" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-fullname"
                        >
                          Holding Period Frequency
                          <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="customerType"
                          fullWidth
                          options={this.cycleFrequencyList}
                          value={this.state.locHoldingPeriodFrequency}
                          defaultValue={this.state.locHoldingPeriodFrequency}
                          getOptionLabel={(option) => option.frequency}
                          onChange={(event, value) => {
                            this.handleLocHoldingPeriodFrequencyChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Holding Period Frequency"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <AddressWithTimezone
                          ref={this.addressRef}
                          onChange={this.handleAddressChange.bind(this)}
                          data={this.state.address}
                          action="create"
                          pageId="Geolocation"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-sm-12 mt-3 text-center">
                <CreateButton btnName="Create" type="submit" />
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.createCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </>
    );
  }
  viewCustomer() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject={this.state.name}
            name="Customer Details"
            title="Customer"
            editCallback={this.editCustomerDetails.bind(this)}
            deleteCallback={this.deleteCustomerToBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
          />
          <div className="row" style={{ padding: "1em", marginTop: "-12px" }}>
            <div className="card" id="organizationFormCard">
              <div className="row">
                <div
                  className="card-header d-flex align-items-center justify-content-between"
                  id="assetCardHeader"
                >
                  <h5 className="mb-0" id="headerLabel">
                    {`${this.state.name} (${this.state.custabbreviation}) `}
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row pt-1">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-supplierType"
                          id="assetFormLabel"
                        >
                          Type
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.customerType.type}</span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Email
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.email}</span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Cycle Time
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>
                            {this.state.cycletime !== null &&
                            this.state.cycletimeFrequency !== null
                              ? this.state.cycletime +
                                " " +
                                this.state.cycletimeFrequency.frequency
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              PAN NO
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>{this.state.panNo}</span>
                            </div>
                          </div>
                          <div className="row pt-2">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Phone
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>{this.state.phone}</span>
                            </div>
                          </div>
                          <div className="row pt-2">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Holding Period
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>
                                {this.state.holdingPeriod !== null &&
                                this.state.holdingPeriodFrequency !== null
                                  ? this.state.holdingPeriod +
                                    " " +
                                    this.state.holdingPeriodFrequency.frequency
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          GSTN
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.gstn}</span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Status
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.customerStatus}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row pt-2">
                        <label
                          className="col-sm-12 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Description
                        </label>
                        <br></br>
                        <div className="col-sm-12" id="OrganizationCol">
                          <span>{this.state.description}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.showLocationTable && (
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <MaterialTable
                  title="Locations"
                  onRowClick={(event, rowData) => {
                    this.toCustomerLocationDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  icons={UiUtils.getMaterialTableIcons()}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "Please add location",
                    },
                  }}
                  columns={[
                    {
                      title: "#",
                      render: (rowData) => rowData.tableData.id + 1,
                      // width: "5%",
                      align: "left",
                      cellStyle: {
                        width: "5%",
                      },
                    },
                    {
                      title: "Name",
                      field: "name",
                      cellStyle: {
                        width: "14%",
                      },
                    },
                    {
                      title: "Address",
                      field: "address",
                      cellStyle: {
                        width: "25%",
                      },
                    },
                    {
                      title: "Transit Time",
                      render: (rowData) => (
                        <>
                          {rowData.transittime +
                            " " +
                            rowData.transittimeFrequency}
                        </>
                      ),
                      cellStyle: {
                        width: "12%",
                      },
                    },
                    {
                      title: "Return Transit Time",
                      render: (rowData) => (
                        <>
                          {rowData.returnTransittime +
                            " " +
                            rowData.returnTransittimeFrequency}
                        </>
                      ),
                      cellStyle: {
                        width: "15%",
                      },
                    },
                    {
                      title: "Holding Period",
                      render: (rowData) => (
                        <>
                          {rowData.holdingPeriod +
                            " " +
                            rowData.holdingPeriodFrequency}
                        </>
                      ),
                      cellStyle: {
                        width: "12%",
                      },
                    },
                    {
                      title: "Status",
                      field: "status",
                      cellStyle: {
                        width: "8%",
                      },
                    },
                  ]}
                  data={this.state.locations}
                  options={{
                    sorting: true,
                    // filtering: true,
                    actionsColumnIndex: -1,
                    initialPage: this.state.initialPage,
                    pageSize: this.state.pageSize,
                    pageSizeOptions: Utils.materialTablePageSize(),
                    paging: this.state.isPaging,
                    headerStyle: {
                      backgroundColor: "#0B6F9D",
                      fontWeight: "bold",
                      color: "#ffff",
                      align: "left",
                    },
                    rowStyle: (rowData) => ({
                      backgroundColor:
                        rowData.id === this.state.customerId
                          ? "#FEF5E7"
                          : "#fff",
                      height: "3em",
                      textTransform: "capitalize",
                    }),
                  }}
                  onChangePage={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                  actions={[
                    {
                      icon: () =>
                        this.state.getCreatePermission && (
                          <>
                            <span className="newDoctor">
                              {" "}
                              <PtsButton btnName="Add Location" type="submit" />
                            </span>
                          </>
                        ),
                      tooltip: "Add Location",
                      isFreeAction: true,
                      onClick: (event) => this.addLocation(),
                    },
                  ]}
                />
              </div>
            </div>
          )}
          <div className="mt-4">
            <BackButton
              type="button"
              style={{ backgroundColor: "#162c50", color: "#fff" }}
              variant="contained"
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }
  editCustomer() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="Customer"
          name="Edit Customer"
        />
        <div className="row">
          <ValidatorForm
            className="text-start"
            ref="form"
            onSubmit={this.updateCustomerToBackend.bind(this)}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="card " id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Customer Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <FormControl variant="outlined" fullWidth size="small">
                          <label
                            className="form-label "
                            htmlFor="collapsible-companyName"
                          >
                            Type<span className="asterisk"> *</span>
                          </label>
                          <Select
                            id="appointmentType"
                            value={this.state.customerTypeId}
                            placeholder="Type *"
                            required
                            onChange={(e) => {
                              this.handleCustomerTypeChange(e);
                            }}
                          >
                            {this.customerTypeList.map((event, keyIndex) => {
                              return (
                                <MenuItem key={keyIndex} value={event.id}>
                                  {event.type}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Customer Abbreviation
                          <span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Customer Abbreviation"
                          value={this.state.custabbreviation}
                          onChange={this.handleCustAbbreviationChange.bind(
                            this
                          )}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4" id="assetFormLabel">
                        {this.state.customerType != null &&
                        this.state.customerType === "Registered" ? (
                          <div className="col-md-12" id="assetFormLabel">
                            <label
                              className="form-label "
                              htmlFor="collapsible-fullname"
                            >
                              GSTN<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              type="text"
                              className="textField"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="GSTN"
                              value={this.state.gstn}
                              onChange={this.handleGstnChange.bind(this)}
                              required
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="verifyGstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        ) : (
                          <div className="col-md-12" id="assetFormLabel">
                            <label
                              className="form-label "
                              htmlFor="collapsible-fullname"
                            >
                              GSTN
                            </label>
                            <TextField
                              type="text"
                              className="textField mt-1"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="GSTN"
                              value={this.state.gstn}
                              onChange={this.handleGstnChange.bind(this)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <RefreshIcon
                                      className="verifyGstnBtn"
                                      onClick={(e) => this.getGstn()}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Email<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="email"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.handleEmailChange.bind(this)}
                          validators={["required", "isEmail"]}
                          errormessages={[
                            "This field is required",
                            "Email id is not valid",
                          ]}
                          required
                        />
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Phone
                        </label>
                        <TextField
                          type="number"
                          className="textField mt-1"
                          fullWidth
                          id="basic-default-phone"
                          variant="outlined"
                          placeholder="Phone"
                          aria-label="Phone"
                          value={this.state.phone}
                          onChange={this.handlePhoneChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Pan No<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-panNo"
                          variant="outlined"
                          placeholder="PAN NO"
                          value={this.state.panNo}
                          onChange={this.handlePanNoChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8">
                        <div className="row mb-4">
                          <div className="col-md-6 mt-1" id="assetFormLabel">
                            <label
                              className="form-label "
                              htmlFor="collapsible-fullname"
                            >
                              Cycle Time<span className="asterisk"> *</span>
                            </label>
                            <TextField
                              type="number"
                              className="textField"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="Cycle Time"
                              value={this.state.cycletime}
                              onChange={this.handleCycleTimeChange.bind(this)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mt-1" id="assetFormLabel">
                            <label
                              className="form-label"
                              htmlFor="collapsible-fullname"
                            >
                              Cycle Time Frequency
                              <span className="asterisk"> *</span>
                            </label>
                            <Autocomplete
                              id="customerType"
                              fullWidth
                              options={this.cycleFrequencyList}
                              value={this.state.cycletimeFrequency}
                              defaultValue={this.state.cycletimeFrequency}
                              getOptionLabel={(option) => option.frequency}
                              onChange={(event, value) => {
                                this.handleCycleTimeFrequencyChange(value);
                              }}
                              inputprops={{
                                style: { textTransform: "capitalize" },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Cycle Time Frequency"
                                  variant="outlined"
                                  required
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-6 mt-1" id="assetFormLabel">
                            <label
                              className="form-label "
                              htmlFor="collapsible-fullname"
                            >
                              Holding Period<span className="asterisk"> *</span>
                              <Tooltip
                                placement="top"
                                title="Holding Period is the time asset held by Customer"
                                id="password-requirements-tooltip"
                              >
                                <img
                                  src={infoIcon}
                                  alt=""
                                  width="20"
                                  height="20"
                                />
                              </Tooltip>
                            </label>
                            <TextField
                              type="number"
                              className="textField"
                              fullWidth
                              id="basic-default-name"
                              variant="outlined"
                              placeholder="Holding Period"
                              value={this.state.holdingPeriod}
                              onChange={this.handleHoldingPeriodChange.bind(
                                this
                              )}
                              required
                            />
                            {this.state.error ? (
                              <span style={{ color: "red" }}>
                                Holding period should be less than cycle time{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6 mt-1" id="assetFormLabel">
                            <label
                              className="form-label"
                              htmlFor="collapsible-fullname"
                            >
                              Holding Period Frequency
                              <span className="asterisk"> *</span>
                            </label>
                            <Autocomplete
                              id="customerType"
                              fullWidth
                              options={this.cycleFrequencyList}
                              value={this.state.holdingPeriodFrequency}
                              defaultValue={this.state.holdingPeriodFrequency}
                              getOptionLabel={(option) => option.frequency}
                              onChange={(event, value) => {
                                this.handleHoldingPeriodFrequencyChange(value);
                              }}
                              inputprops={{
                                style: { textTransform: "capitalize" },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Holding Period Frequency"
                                  variant="outlined"
                                  required
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-description"
                        >
                          Description
                        </label>
                        <textarea
                          name="collapsible-address"
                          style={{
                            color: "black",
                            font: "inherit",
                            fontSize: "16px ",
                          }}
                          className="form-control inputTextarea"
                          id="collapsible-address"
                          rows="5"
                          placeholder="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mt-1" id="assetFormLabel">
                      <label
                        className="form-label "
                        htmlFor="collapsible-email"
                      >
                        Status
                      </label>
                      <span className="ms-4">
                        <ToggleButton
                          value={this.state.isStatus ? "Active" : "InActive"}
                          className="ToggleButtonActive"
                          onChange={this.handleStatusChange.bind(this)}
                          style={
                            this.state.isStatus
                              ? {
                                  background: "#0B6F9D",
                                  color: "#fff",
                                  height: "33px",
                                  width: "80px",
                                  borderRadius: "5px",
                                  textTransform: "none",
                                }
                              : {
                                  background: "#D7DBDD",
                                  color: "#000",
                                  height: "33px",
                                  width: "80px",
                                  borderRadius: "5px",
                                  textTransform: "none",
                                }
                          }
                        >
                          {this.state.isStatus ? "Active" : "InActive"}
                        </ToggleButton>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-2" id="headerLabel">
                      Customer Location Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Location Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Name"
                          value={this.state.locName}
                          onChange={this.handleLocNameChange.bind(this)}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Transit Time<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="number"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Transit Time"
                          value={this.state.transitTime}
                          onChange={this.handleTransitTimeChange.bind(this)}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-fullname"
                        >
                          Transit Time Frequency
                          <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="customerType"
                          fullWidth
                          options={this.cycleFrequencyList}
                          value={this.state.transitTimeFrequency}
                          defaultValue={this.state.transitTimeFrequency}
                          getOptionLabel={(option) => option.frequency}
                          onChange={(event, value) => {
                            this.handleTransitTimeFrequencyChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Transit Time Frequency"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Return Transit Time
                          <span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="number"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Return Transit Time"
                          value={this.state.returnTransitTime}
                          onChange={this.handleReturnTransitTimeChange.bind(
                            this
                          )}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-fullname"
                        >
                          Return Transit Time Frequency
                          <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="customerType"
                          fullWidth
                          options={this.cycleFrequencyList}
                          value={this.state.returnTransitTimeFrequency}
                          defaultValue={this.state.transitTimeFrequency}
                          getOptionLabel={(option) => option.frequency}
                          onChange={(event, value) => {
                            this.handleReturnTransitTimeFrequencyChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Return Transit Time Frequency"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-fullname"
                        >
                          Holding Period<span className="asterisk"> *</span>
                          <Tooltip
                            placement="top"
                            title="Holding Period is the time asset held by Customer"
                            id="password-requirements-tooltip"
                          >
                            <img src={infoIcon} alt="" width="20" height="20" />
                          </Tooltip>
                        </label>
                        <TextField
                          type="number"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Holding Period"
                          value={this.state.locHoldingPeriod}
                          onChange={this.handleLocHoldingPeriodChange.bind(
                            this
                          )}
                          required
                        />
                        {/* {this.state.locError ? <span style={{ color: "red" }}>Holding period should be less than cycle time </span> : ""} */}
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-fullname"
                        >
                          Holding Period Frequency
                          <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          id="customerType"
                          fullWidth
                          options={this.cycleFrequencyList}
                          value={this.state.locHoldingPeriodFrequency}
                          defaultValue={this.state.locHoldingPeriodFrequency}
                          getOptionLabel={(option) => option.frequency}
                          onChange={(event, value) => {
                            this.handleLocHoldingPeriodFrequencyChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Holding Period Frequency"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <AddressWithTimezone
                          ref={this.addressRef}
                          onChange={this.handleAddressChange.bind(this)}
                          data={this.state.address}
                          action="edit"
                          pageId="Geolocation"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between custBut">
              <div className="col-sm-12 mt-3 text-center">
                <SaveButton id="saveButton" type="submit">
                  {" "}
                  Save{" "}
                </SaveButton>
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.editCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </>
    );
  }
}
export default withRouter(Customer);
