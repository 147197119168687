import MaterialTable from "material-table";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { withRouter } from "../../withRouter";
import AdminFetchServerData from '../../provider/AdminFetchServerData';
import UiUtils from "../../provider/UiUtils";
import Utils from "../../provider/Utils";
import moment from "moment";
import store from "../../redux/store";
import AdminMenuWithBreadScrum from "../adminMenu/AdminMenuWithBreadScrum";
import ApplicationStateAction from "../../redux/applicationState/ApplicationStateAction";
import PtsButton from "../../component/buttons/PtsButton";

class PrivacyPolicyTemplateList extends Component {
  title = "Privacy Policy Template List";
  breadCrumb = [{ pageid: "", uiname: "Privacy Policy Template List" }];
  header = store.getState().adminHeader.adminHeader;
  privacyPolicyState = store.getState().applicationState.privacyPolicyState;
  privacyPolicyTemplateDetails = {};
  constructor(props) {
    super(props);
    if (this.privacyPolicyState) {
      this.privacyPolicyTemplateDetails = Object.getOwnPropertyNames(
        this.privacyPolicyState
      );
    }
    if (this.privacyPolicyTemplateDetails.length > 0) {
      this.state = {
        privacyPolicyTemplateList: [],
        isPaging: false,
        initialPage:
          this.privacyPolicyState.initialPage &&
            this.privacyPolicyState.initialPage > 0
            ? this.privacyPolicyState.initialPage
            : 0,
        pageSize:
          this.privacyPolicyState.pageSize && this.privacyPolicyState.pageSize
            ? this.privacyPolicyState.pageSize
            : 0,
        privacyPolicyTemplateId:
          this.privacyPolicyState.privacyPolicyTemplateId,
        render: false,
      };
    } else {
      this.state = {
        privacyPolicyTemplateList: [],
        isPaging: false,
        initialPage: 0,
        pageSize: 10,
        privacyPolicyTemplateId: "",
        render: false,
      };
    }
    this.getPrivacyPolicyTemplateList();
  }
  getPrivacyPolicyTemplateList() {
    const postObject = {
      header: this.header,
    };

    AdminFetchServerData.callPostService(
      "/apPrivacyPolicy/getList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let privacyPolicyTemplateList = output.data.privacyPolicyList;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (privacyPolicyTemplateList.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          privacyPolicyTemplateList: privacyPolicyTemplateList,
          render: true,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }
  toPrivacyPolicyTemplateDetails(e, data) {
    let privacyPolicyState = {
      privacyPolicyTemplateId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ privacyPolicyState })
    );

    this.props.navigate("/viewPrivacyPolicy", {
      state: {
        action: "view",
        title: "Privacy Policy Template Details",
        privacyPolicyTemplateId: data.id,
        pageId: "privacyPolicyList",
      },
    });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  addTermsCondition(e) {
    // let adminFccPageState = {
    //   id: '',
    //   action: 'create',
    //   title: 'Privacy Policy',
    //   pageId: 'privacyPolicyList',
    // };
    // store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.navigate("/addPrivacyPolicy", {
      state: {
        action: "create",
        title: "Add Privacy Policy",
        privacyPolicyTemplateId: "",
        pageId: "privacyPolicyList",
      },
    });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    }
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
        />
        <Row
          style={{ position: "relative", margin: "1em 0em", maxWidth: "100%" }}
        >
          <Col md={12}>
            <MaterialTable
              onRowClick={(event, rowData) => {
                this.toPrivacyPolicyTemplateDetails(event, rowData);
                event.stopPropagation();
              }}
              icons={UiUtils.getMaterialTableIcons()}
              title=""
              localization={{
                body: {
                  emptyDataSourceMessage: "Please add Privacy Policy",
                },
              }}
              columns={[
                {
                  title: "#",
                  render: (rowData) => rowData.tableData.id + 1,

                  width: "5%",
                  align: "left",
                },
                {
                  title: "Version",
                  field: "version",
                  width: "5%",
                  filtering: false,
                },
                {
                  title: "created Name",
                  field: "createdbyname",
                  width: "20%",
                  filtering: false,
                },
                {
                  title: "Updated Name",
                  field: "updatedbyname",
                  width: "20%",
                  filtering: false,
                },
                {
                  title: "Created Date",
                  render: (rowData) =>
                    moment(rowData.createdon).format("DD-MMM-YYYY"),
                  // field: 'createdon',
                  width: "25%",
                },
                {
                  title: "Status",
                  render: (rowData) =>
                    rowData.isactive === true ? "Active" : "InActive",
                  width: "25%",
                  // cellStyle: { textTransform: 'none' },
                },
              ]}
              data={this.state.privacyPolicyTemplateList}
              onPageChange={(page, pageSize) => {
                this.handleMaterialTablePageChange(page, pageSize);
              }}
              options={{
                sorting: true,
                actionsColumnIndex: -1,
                initialPage: this.state.initialPage,
                pageSize: this.state.pageSize,
                headerStyle: {
                  backgroundColor: "#0B6F9D",
                  fontWeight: "bold",
                  color: "#fff",
                  align: "left",
                },
                pageSizeOptions: Utils.materialTablePageSize(),
                filtering: false,
                paging: this.state.isPaging,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.id === this.state.privacyPolicyTemplateId
                      ? "#FEF5E7"
                      : "#fff",
                  height: "3em",
                  textTransform: "capitalize",
                }),

                tableLayout: "auto",
              }}
              actions={[
                {
                  icon: () => (
                    this.state.getCreatePermission && (
                      <span className="Add Privacy Policy Template">
                        {' '}
                        <PtsButton btnName="Add Privacy Policy Template" type="submit" />
                      </span>
                    )
                  ),
                  tooltip: "Add Privacy Policy Template",
                  isFreeAction: true,
                  onClick: (event) => this.addTermsCondition(),
                },
              ]}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(PrivacyPolicyTemplateList);
