import { GET_HEADER, SET_HEADER } from "./HeaderTypes";

const getHeader = () => {
  return {
    type: GET_HEADER,
  };
};

const setHeader = (data) => {
  return {
    type: SET_HEADER,
    payload: data,
  };
};
const HeaderAction = { getHeader, setHeader };

export default HeaderAction;
