import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import store from "../../redux/store";
import { withRouter } from "../../withRouter";
import BackIconButton from "../buttons/BackIconButton";
import CheckIconButton from "../buttons/CheckIconButton";
import CreateSimilarIconButton from "../buttons/CreateSimilarIconButton";
import DeleteIconButton from "../buttons/DeleteIconButton";
import EditIconButton from "../buttons/EditIconButton";
import MarkCancelButton from "../buttons/MarkCancelButton";
import MarkOpenIconButton from "../buttons/MarkOpenIconButton";
import PrintIconButton from "../buttons/PrintIconButton";
import RescheduleButton from "../buttons/RescheduleButton";
import TreeIconButton from "../buttons/TreeIconButton";
import "./MenuWithBreadScrum.scss";
export class MenuWithBreadScrum extends Component {
  catalogue = store.getState().Catalogue.Catalogue;
  constructor(props) {
    super(props);
    let headerClassName = "";
    // if (props.headerWidth === "home") {
    //   headerClassName = "homeHeder";
    // } else {
    //   headerClassName = "ptsHeader";
    // }
    this.state = {
      domainObject: this.props.domainObject,
      name: this.props.name,
      title: this.props.title,
      breadCrumb: this.props.breadCrumb,
      docTitle: this.props.docTitle,
      editCallback: this.props.editCallback,
      deleteCallback: this.props.deleteCallback,
      printCallback: this.props.printCallback,
      HierarchyCallback: this.props.HierarchyCallback,
      backCallback: this.props.backCallback,
      createSimilarCallback: this.props.createSimilarCallback,
      markCompleteCallback: this.props.markCompleteCallback,
      markCancelCallback: this.props.markCancelCallback,
      rescheduleCallback: this.props.rescheduleCallback,
      markOpenCallback: this.props.markOpenCallback,
      breadScrum: this.props.breadScrum,
      addToBreadCrumb: this.props.addToBreadCrumb,
      status: this.props.status,
      appointmentDate: this.props.appointmentDate,
      getDeletePermission: this.props.getDeletePermission,
      getCreatePermission: this.props.getCreatePermission,
      getUpdatePermission: this.props.getUpdatePermission,
      currentDateTime: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
      // width: headerWidth,
      headerClassName: headerClassName,
      render: true,
      deleteClassName: this.props.deleteClassName,
      pageId: this.props.pageId,
    };
  }

  /*Navigate to the click page*/
  goToPage(element) {
    if (this.props.pageId === "AssetView") {
      if (element.pageid) {
        if (this.props.navigate) {
          this.props.navigate(element.pageid, {
            state: {
              action: "view",
              view: "categoryView",
              assetCycleIds: [],
              customerId: this.props.customerId,
              catalogueId: this.catalogue.id,
              categoryId: this.props.categoryId,
              assetCycleId: this.props.assetCycleId,
              returnableCyclePlanId: this.props.returnablePlanId
            },
          });
        }
      }
    } else {
      if (element.pageid) {
        if (this.props.navigate) {
          this.props.navigate(element.pageid);
        }
      }
    }
  }

  getBreadCrumb() {
    if (this.state.breadCrumb) {
      let array = [];
      this.props.breadCrumb.forEach((element, index) => {
        array.push(
          <Link
            key={index}
            style={{ color: "#000", fontSize: "small", cursor: "pointer" }}
            onClick={(e) => this.goToPage(element)}
          >
            <span>
              <i
                className={element.cssclass}
                style={{ color: "#c16767", fontSize: "small" }}
              ></i>
            </span>
            &nbsp;{element.uiname}
          </Link>
        );
      });
      if (this.state.addToBreadCrumb && this.state.addToBreadCrumb.length > 0) {
        array.push(
          <span key="999" style={{ color: "#000", fontSize: "small" }}>
            &nbsp;{this.state.addToBreadCrumb}
          </span>
        );
      }
      let home = { pageid: "/dashboard", uiname: "Home" };
      return (
        <div style={{ float: "right", display: "flex" }}>
          <Breadcrumbs separator="›">
            <Link
              onClick={(e) => this.goToPage(home)}
              style={{ color: "#000", fontSize: "small", cursor: "pointer" }}
            >
              <HomeIcon
                style={{
                  color: "#c16767",
                  fontSize: "18px",
                  marginBottom: "3px",
                }}
              />
              &nbsp;{home.uiname}
            </Link>
            {array}
          </Breadcrumbs>
        </div>
      );
    } else {
      return <div />;
    }
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      // this.state.name = this.props.name;
      let editIcon = null;
      if (this.state.editCallback && null !== this.state.editCallback) {
        this.props.getUpdatePermission === true &&
          (editIcon = (
            <EditIconButton
              domainObject={this.state.domainObject}
              onClick={this.state.editCallback}
            />
          ));
      }
      let deleteIcon = null;

      if (this.state.deleteCallback && null !== this.state.deleteCallback) {
        this.props.getDeletePermission === true &&
          (deleteIcon = (
            <DeleteIconButton
              domainObject={this.state.domainObject}
              onClick={this.state.deleteCallback}
              pageId={this.props.pageId}
              title={this.props.title}
            />
          ));
      }
      let printIcon = null;
      if (this.state.printCallback && null !== this.state.printCallback) {
        printIcon = (
          <PrintIconButton
            domainObject={this.state.domainObject}
            onClick={this.state.printCallback}
          />
        );
      }

      let TreeIcon = null;
      if (
        this.state.HierarchyCallback &&
        null !== this.state.HierarchyCallback
      ) {
        this.props.referenceAppointmentLength > 0 &&
          (TreeIcon = (
            <TreeIconButton
              domainObject={this.state.domainObject}
              onClick={this.state.HierarchyCallback}
            />
          ));
      }
      let backIcon = null;
      if (this.state.backCallback && null !== this.state.backCallback) {
        backIcon = (
          <BackIconButton
            domainObject={this.state.domainObject}
            onClick={this.state.backCallback}
          />
        );
      }

      let createSimilarIcon = null;
      if (
        this.state.createSimilarCallback &&
        null !== this.state.createSimilarCallback
      ) {
        // this.state.getCreatePermission &&
        createSimilarIcon = (
          <CreateSimilarIconButton
            domainObject={this.state.domainObject}
            onClick={this.state.createSimilarCallback}
            name="Create Similar"
          />
        );
      }
      let markCompleteIcon = null;
      if (
        this.state.markCompleteCallback &&
        null !== this.state.markCompleteCallback
      ) {
        this.props.getCreatePermission &&
          (markCompleteIcon = (
            <CheckIconButton
              domainObject={this.state.domainObject}
              onClick={this.state.markCompleteCallback}
            />
          ));
      }

      let markCancelIcon = null;
      if (
        this.state.markCancelCallback &&
        null !== this.state.markCancelCallback
      ) {
        this.props.getCreatePermission &&
          (markCancelIcon = (
            <MarkCancelButton
              domainObject={this.state.domainObject}
              onClick={this.state.markCancelCallback}
            />
          ));
      }

      let rescheduleIcon = null;
      if (
        this.state.rescheduleCallback &&
        null !== this.state.rescheduleCallback
      ) {
        this.props.getCreatePermission &&
          (rescheduleIcon = (
            <RescheduleButton
              domainObject={this.state.domainObject}
              onClick={this.state.rescheduleCallback}
            />
          ));
      }

      let markOpenIcon = null;
      if (this.state.markOpenCallback && null !== this.state.markOpenCallback) {
        this.props.getCreatePermission &&
          (markOpenIcon = (
            <MarkOpenIconButton
              domainObject={this.state.domainObject}
              onClick={this.state.markOpenCallback}
            />
          ));
      }

      let iconToolBar = null;

      if (
        null !== editIcon ||
        null !== deleteIcon ||
        null !== printIcon ||
        null !== backIcon
      ) {
        let docTitle = "";
        if (this.state.docTitle) {
          docTitle = <span>{this.state.docTitle} </span>;
        }

        iconToolBar = (
          <Row
            md={12}
            style={{
              font: "scan",
              margin: "-1em auto auto",
              marginTop: "0.5em",
            }}
          >
            <Col
              md={1}
              style={{ float: "left", fontSize: "large", alignSelf: "center" }}
            >
              {null !== backIcon && backIcon}
            </Col>
            <Col
              md={3}
              style={{ float: "left", fontSize: "large", alignSelf: "center" }}
            >
              {docTitle}
            </Col>
            <Col md={8} style={{ float: "right", margin: "auto" }}>
              {null !== deleteIcon && deleteIcon}
              {null !== editIcon && editIcon}

              {null !== TreeIcon && TreeIcon}
              {null !== createSimilarIcon && createSimilarIcon}
              {null !== printIcon && printIcon}
              {(this.state.appointmentDate < this.state.currentDateTime &&
                this.state.status === "Scheduled") ||
                this.state.status === "Cancelled"
                ? null !== markCompleteIcon && markCompleteIcon
                : ""}
              {this.state.status === "Scheduled"
                ? null !== markCancelIcon && markCancelIcon
                : ""}
              {this.state.status === "Cancelled"
                ? null !== rescheduleIcon && rescheduleIcon
                : ""}
              {this.state.status === "Cancelled"
                ? null !== markOpenIcon && markOpenIcon
                : ""}
            </Col>
          </Row>
        );
      }
      return (
        <>
          <Row>
            <Col md={6}>
              {" "}
              <h5
                style={{
                  textTransform: "capitalize",
                  textAlign: "left",
                  padding: "0em 1em",
                }}
              >
                <b>{this.props.name}</b>
              </h5>
            </Col>
            <Col md={6} style={{ textTransform: "capitalize" }}>
              {this.getBreadCrumb()}
            </Col>
            {/* <Col
              md={6}
              className="toolBarCol"
              style={{ margin: " 2em auto auto auto" }}
            >
              {null !== iconToolBar && iconToolBar}
            </Col> */}
          </Row>
          <Row
            md={12}
            className={this.state.headerClassName}
            style={{ marginTop: "1em" }}
          >
            {/* <Col md={4}>
              <h5
                style={{
                  textTransform: "capitalize",
                  textAlign: "left",
                  padding: "0em 1em",
                }}
              >
                <b>{this.props.name}</b>
              </h5>
            </Col> */}
            <Col md={12}>
              {null !== iconToolBar && iconToolBar}
              {/* {null !== createSimilarIcon && createSimilarIcon} */}
            </Col>
          </Row>
        </>
      );
    }
  }
}

export default withRouter(MenuWithBreadScrum);
