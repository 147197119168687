import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import CategoryIcon from "@mui/icons-material/Category";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import TreeIconButton from "../../buttons/TreeIconButton";
import BackIconButton from "../../buttons/BackIconButton";
// import CheckIconButton from "../../buttons/CheckIconButton";
import CreateSimilarIconButton from "../../buttons/CreateSimilarIconButton";
import DeleteIconButton from "../../buttons/DeleteIconButton";
import EditIconButton from "../../buttons/EditIconButton";
// import MarkCancelButton from "../../buttons/MarkCancelButton";
// import MarkOpenIconButton from "../../buttons/MarkOpenIconButton";
import PrintIconButton from "../../buttons/PrintIconButton";
// import RescheduleButton from "../../buttons/RescheduleButton";
import "./CatalogueBreadScrum.css";
import AddIconButton from "../../buttons/AddIconButton";
import { withRouter } from "../../../withRouter";
import store from "../../../redux/store";
import CategoryAction from "../../../redux/category/CategoryAction";

export class CatalogueBreadScrum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domainObject: this.props.domainObject,
      name: this.props.name,
      breadCrumb: this.props.breadCrumb,
      docTitle: this.props.docTitle,
      editCallback: this.props.editCallback,
      deleteCallback: this.props.deleteCallback,
      printCallback: this.props.printCallback,
      HierarchyCallback: this.props.HierarchyCallback,
      backCallback: this.props.backCallback,
      createSimilarCallback: this.props.createSimilarCallback,
      markCompleteCallback: this.props.markCompleteCallback,
      markCancelCallback: this.props.markCancelCallback,
      rescheduleCallback: this.props.rescheduleCallback,
      markOpenCallback: this.props.markOpenCallback,
      addCallback: this.props.addCallback,
      AddCategoryCallback: this.props.AddCategoryCallback,
      addToBreadCrumb: this.props.addToBreadCrumb,
      status: this.props.status,
      appointmentDate: this.props.appointmentDate,
      getDeletePermission: this.props.getDeletePermission,
      getCreatePermission: this.props.getCreatePermission,
      getUpdatePermission: this.props.getUpdatePermission,
      currentDateTime: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
      render: true,
      deleteClassName: this.props.deleteClassName,
      pageId: this.props.pageId,
    };
  }

  /*Navigate to the click page*/
  goToPage(e, element) {
    store.dispatch(CategoryAction.setCategory(element));
    {
      (element.id || element.catalogueid) &&
        this.props.navigate("/category", {
          state: {
            categoryId: element.id,
            catalogueId: element.catalogueid,
            pageId: element.pageId,
            view: "categoryView"
          },
        });
    }
    this.props.onSuccess(element.id);
  }
  goToHomePage(element) {
    if (element.pageid) {
      if (this.props.navigate) {
        this.props.navigate(element.pageid);
      }
    }
  }
  getBreadCrumb() {
    if (this.state.breadCrumb) {
      let array = [];
      this.props.breadCrumb.forEach((element, index) => {
        array.push(
          <Link
            key={index}
            style={{ color: "#000", fontSize: "small", cursor: "pointer" }}
            onClick={(e) => this.goToPage(e, element)}
          >
            <span>
              <i
                className={element.cssclass}
                style={{ color: "#c16767", fontSize: "small" }}
              ></i>
            </span>
            &nbsp;{element.name}
          </Link>
        );
      });
      if (this.state.addToBreadCrumb && this.state.addToBreadCrumb.length > 0) {
        array.push(
          <span key="999" style={{ color: "#000", fontSize: "small" }}>
            &nbsp;{this.state.addToBreadCrumb}
          </span>
        );
      }
      let home = { pageid: "/catalogue", name: "Catalogue" };
      return (
        <div style={{ float: "right", display: "flex" }}>
          <Breadcrumbs separator="›">
            <Link
              onClick={(e) => this.goToHomePage(home)}
              style={{ color: "#000", fontSize: "small", cursor: "pointer" }}
            >
              <CategoryIcon
                style={{
                  color: "#c16767",
                  fontSize: "18px",
                  marginBottom: "3px",
                }}
              />
              &nbsp;{home.name}
            </Link>
            {array}
          </Breadcrumbs>
        </div>
      );
    } else {
      return <div />;
    }
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let editIcon = null;
      if (this.state.editCallback && null !== this.state.editCallback) {
        editIcon = (
          <EditIconButton
            domainObject={this.state.domainObject}
            onClick={this.state.editCallback}
          />
        );
      }
      let deleteIcon = null;

      if (this.state.deleteCallback && null !== this.state.deleteCallback) {
        deleteIcon = (
          <DeleteIconButton
            domainObject={this.state.domainObject}
            onClick={this.state.deleteCallback}
            pageId={this.props.pageId}
          />
        );
      }
      let printIcon = null;
      if (this.state.printCallback && null !== this.state.printCallback) {
        printIcon = (
          <PrintIconButton
            domainObject={this.state.domainObject}
            onClick={this.state.printCallback}
          />
        );
      }

      let TreeIcon = null;
      if (
        this.state.HierarchyCallback &&
        null !== this.state.HierarchyCallback
      ) {
        this.props.referenceAppointmentLength > 0 &&
          (TreeIcon = (
            <TreeIconButton
              domainObject={this.state.domainObject}
              onClick={this.state.HierarchyCallback}
            />
          ));
      }
      let backIcon = null;
      if (this.state.backCallback && null !== this.state.backCallback) {
        backIcon = (
          <BackIconButton
            domainObject={this.state.domainObject}
            onClick={this.state.backCallback}
          />
        );
      }

      let createSimilarIcon = null;
      if (
        this.state.createSimilarCallback &&
        null !== this.state.createSimilarCallback
      ) {
        this.state.getCreatePermission &&
          (createSimilarIcon = (
            <CreateSimilarIconButton
              domainObject={this.state.domainObject}
              onClick={this.state.createSimilarCallback}
            />
          ));
      }
      // let markCompleteIcon = null;
      // if (
      //   this.state.markCompleteCallback &&
      //   null !== this.state.markCompleteCallback
      // ) {
      //   //this.props.getCreatePermission &&
      //   markCompleteIcon = (
      //     <CheckIconButton
      //       domainObject={this.state.domainObject}
      //       onClick={this.state.markCompleteCallback}
      //     />
      //   );
      // }

      // let markCancelIcon = null;
      // if (
      //   this.state.markCancelCallback &&
      //   null !== this.state.markCancelCallback
      // ) {
      //   //this.props.getCreatePermission &&
      //   markCancelIcon = (
      //     <MarkCancelButton
      //       domainObject={this.state.domainObject}
      //       onClick={this.state.markCancelCallback}
      //     />
      //   );
      // }

      // let rescheduleIcon = null;
      // if (
      //   this.state.rescheduleCallback &&
      //   null !== this.state.rescheduleCallback
      // ) {
      //   //this.props.getCreatePermission &&
      //   rescheduleIcon = (
      //     <RescheduleButton
      //       domainObject={this.state.domainObject}
      //       onClick={this.state.rescheduleCallback}
      //     />
      //   );
      // }

      // let markOpenIcon = null;
      // if (this.state.markOpenCallback && null !== this.state.markOpenCallback) {
      //   //this.props.getCreatePermission &&
      //   markOpenIcon = (
      //     <MarkOpenIconButton
      //       domainObject={this.state.domainObject}
      //       onClick={this.state.markOpenCallback}
      //     />
      //   );
      // }

      let AddIcon = null;
      if (this.state.addCallback && null !== this.state.addCallback) {
        // //this.props.getCreatePermission &&
        AddIcon = (
          <AddIconButton
            domainObject={this.state.domainObject}
            onClick={this.state.addCallback}
          />
        );
      }
      let AddCategoryBtn = null;
      if (
        this.state.AddCategoryCallback &&
        null !== this.state.AddCategoryCallback
      ) {
        if (this.props.categoryDetails) {
          this.props.categoryDetails.length > 0 && (
            <>
              {this.props.getCreatePermission &&
                (AddCategoryBtn = (
                  <button
                    id="ptsButton"
                    type="button"
                    className="btn btn-default "
                    onClick={this.state.AddCategoryCallback}
                  >
                    {this.props.btnName}
                  </button>
                ))}
            </>
          );
        } else {
          this.props.getCreatePermission &&
            (AddCategoryBtn = (
              <button
                id="ptsButton"
                type="button"
                className="btn btn-default "
                onClick={this.state.AddCategoryCallback}
              >
                {this.props.btnName}
              </button>
            ));
        }
      }

      let iconToolBar = null;

      if (
        null !== AddIcon ||
        null !== editIcon ||
        null !== deleteIcon ||
        null !== printIcon ||
        null !== backIcon ||
        null !== AddCategoryBtn
      ) {
        let docTitle = "";
        if (this.state.docTitle) {
          docTitle = <span>{this.state.docTitle} </span>;
        }

        iconToolBar = (
          <Row
            md={12}
            style={{
              font: "scan",

              marginLeft: "auto",
            }}
          >
            <Col
              md={1}
              style={{ float: "left", fontSize: "large", alignSelf: "center" }}
            >
              {null !== backIcon && backIcon}
            </Col>
            <Col
              md={3}
              style={{ float: "left", fontSize: "large", alignSelf: "center" }}
            >
              {docTitle}
            </Col>
            <Col md={12} style={{ float: "right", margin: "auto" }}>
              {null !== AddIcon && AddIcon}
              {null !== AddCategoryBtn && AddCategoryBtn}
              {null !== deleteIcon && deleteIcon}
              {null !== editIcon && editIcon}

              {null !== createSimilarIcon && createSimilarIcon}
              {null !== TreeIcon && TreeIcon}
              {null !== printIcon && printIcon}
            </Col>
          </Row>
        );
      }
      return (
        <>
          <nav className="navbar navbar-expand-lg ">
            <Row md={12} style={{ width: "100%" }}>
              <Col md={5} className="catalogueTitle">
                <h5 className="catalogue ms-3">
                  <b>{this.props.name}</b>
                </h5>
              </Col>
              <Col
                md={7}
                className="toolBarCol"
                style={{
                  textTransform: "capitalize",
                  margin: "0.5em auto auto auto",
                }}
              >
                {this.getBreadCrumb()}
              </Col>
            </Row>
          </nav>
          <Row>
            {/* <Col
              md={7}
              className="toolBarCol"
              style={{
                textTransform: "capitalize",
                margin: "0.5em auto auto auto",
              }}
            >
              {this.getBreadCrumb()}
            </Col> */}
            <Col
              md={12}
              className="toolBarCol"
              style={{ display: "inline-flex" }}
            >
              {null !== iconToolBar && iconToolBar}
            </Col>
          </Row>
        </>
      );
    }
  }
}

export default withRouter(CatalogueBreadScrum);
