import React, { Component } from 'react';
import AdminFetchServerData from '../../provider/AdminFetchServerData';
import PtsAlert from '../../component/ptsAlert/PtsAlert';
import { withRouter } from "../../withRouter";
import TextField from "@material-ui/core/TextField";
import store from '../../redux/store';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import Utils from '../../provider/Utils';
import BackButton from '../../component/buttons/BackButton';
import CreateButton from '../../component/buttons/CreateButton';
import CancelIconButton from '../../component/buttons/CancelIconButton';
class ProfileManagement extends Component {
	header = store.getState().adminHeader.adminHeader;
	title = 'Profile Management';
	breadCrumb = [
		{ pageid: '/apSettings', uiname: 'Profile Management List' },
		{ pageid: '', uiname: 'Profile Management' }
	];
	constructor(props) {
		super(props);
		let action = "";
		let profileId = ""
		if (!action && props.state) {
			action = props.state.action;
			profileId = props.state.profileId;
		};
		this.state = {
			action: action,
			id: profileId,
			name: "",
			menus: [],
			activeMenus: new Map(),
			render: false,
			addProfile: true,
			updateProfile: false,
			getUpdatePermission: false,
			getDeletePermission: false,
		};
	};

	componentDidMount() {
		if (this.state.action !== "create") {
			this.getProfileDetails(this.state.id, this.state.name);
		} else {
		}
	}
	getProfileDetails(id) {
		const postObject = {
			header: this.header,
			profile: {
				id: id,
			},
		};
		AdminFetchServerData.callPostService('/apProfilems/get', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					let roleDetails = output.data.profile;
					let getPermission = Utils.getRolePermission(output.data.privilegeList);
					let getUpdatePermission = getPermission[3];
					let getDeletePermission = getPermission[4];
					this.setState({
						render: true,
						action: "view",
						name: roleDetails.name,
						id: roleDetails.id,
						menus: roleDetails.menus.sort((a, b) => a.displaysequence - b.displaysequence),
						getUpdatePermission: getUpdatePermission,
						getDeletePermission: getDeletePermission,
					});
				} else {
				};
			});
	};

	handleNameChange(e) {
		this.setState({ name: e.target.value });
	}

	createProfileToBackend() {
		const postObject = {
			header: this.header,
			profile: {
				name: this.state.name,
				// id: this.state.id,
				// menus: this.state.menus,
			}
		};
		AdminFetchServerData.callPostService('/apProfilems/create', postObject).then((output) => {
			if (output.status === 'SUCCESS') {
				PtsAlert.success(output.message);
				let profileDetails = output.data.profile;
				this.setState({
					updateProfile: true,
					addProfile: false,
					id: profileDetails.id,
					name: profileDetails.name,
					menus: profileDetails.menus.sort((a, b) => a.displaysequence - b.displaysequence),
				})
			} else {
			}
		});
	};

	cancelProfile() {
		this.props.navigate("/apSettings");
	}

	editCancel() {
		this.props.navigate("/apProfileMgmnt");
		this.setState({
			action: 'view',
			profileId: this.state.id,
		})
	}

	editProfileManagement() {
		this.setState({
			action: 'edit',
			profileId: this.state.id,
			activeMenus: new Map()
		})
	}

	updateProfileToBackend() {
		const postObject = {
			header: this.header,
			profile: {
				name: this.state.name,
				id: this.state.id,
				menus: this.state.menus,
			}
		};
		AdminFetchServerData.callPostService('/apProfilems/update', postObject).then((output) => {
			if (output.status === 'SUCCESS') {
				PtsAlert.success(output.message);
				let profileData = output.data.profile;
				this.getProfileDetails(profileData.id);
			} else {
			}
		});
	};

	deleteProfileToBackend = () => {
		const postObject = {
			header: this.header,
			profile: {
				id: this.state.id,
			},
		};
		AdminFetchServerData.callPostService('/apProfilems/delete', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					PtsAlert.success(output.message);
					this.props.navigate("/apSettings");
				} else {
				}
			});
	};

	showrolesubMenu(menuId) {
		let menus = this.state.activeMenus;
		if (menus.has(menuId)) {
			menus.delete(menuId);
		} else {
			menus.set(menuId, new Set());
		}
		this.setState({ activeMenus: menus });
	};
	showDomainObject(domainObjectId, menuId) {
		let domainObjects = this.state.activeMenus.get(menuId);
		if (domainObjects.has(domainObjectId)) {
			//remove
			domainObjects.delete(domainObjectId);
		} else {
			//Add
			domainObjects.add(domainObjectId);
		}
		this.setState({ activeMenus: this.state.activeMenus.set(menuId, domainObjects) });
	};


	onDomainObjectPrivilegeClicked(id) {
		this.state.menus.map((menu) => {
			menu.domainObjects.map((domainObject) => {

				domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
					if (domainObjectPrivilege.id === id) {

						if (domainObjectPrivilege.isactive === false) {
							this.handleDomainObjectPrivilegeChecked(domainObjectPrivilege, domainObject, menu);
						} else {
							this.handleDomainObjectPrivilegeUnChecked(domainObjectPrivilege, domainObject, menu);
						}
						return;
					}
				})
			});
			menu.childMenus?.map((submenu) => {
				submenu.domainObjects.map((domainObject) => {

					domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
						if (domainObjectPrivilege.id === id) {
							if (domainObjectPrivilege.isactive === false) {
								this.handleDomainObjectPrivilegeChecked(domainObjectPrivilege, domainObject, menu);
							} else {
								this.handleDomainObjectPrivilegeUnChecked(domainObjectPrivilege, domainObject, menu);
							}

							return;
						}
					})

				});
			})

		});
		this.setState({ menus: this.state.menus });
	}

	handleDomainObjectPrivilegeChecked(inputDomainObjectPrivilege, inputdomainObject, menu) {

		let inputPrivilge = inputDomainObjectPrivilege;
		if (inputPrivilge.name === '*') {
			inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
				domainObjectPrivilege.isactive = false;
			});
			inputDomainObjectPrivilege.isactive = true;
		} else if (inputPrivilge.name === 'READ') {
			inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
				let privilege = domainObjectPrivilege;
				if (privilege.name === 'READ') {
					domainObjectPrivilege.isactive = true;
				}
				if (privilege.name === '*') {
					domainObjectPrivilege.isactive = false;
				}
			});
		} else {

			inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
				let privilege = domainObjectPrivilege;
				if (privilege.name === 'READ') {
					domainObjectPrivilege.isactive = true;
				} else if (privilege.name === inputPrivilge.name) {
					domainObjectPrivilege.isactive = true;
				}
			});

			let isCreateCheckd = false;
			let isUpdateCheckd = false;
			let isDeleteheckd = false;
			let isApproveCheckd = false;
			inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
				let privilege = domainObjectPrivilege;
				if (privilege.name === 'CREATE' && domainObjectPrivilege.isactive === true) {
					isCreateCheckd = true;
				} else if (privilege.name === 'UPDATE' && domainObjectPrivilege.isactive === true) {
					isUpdateCheckd = true;
				} else if (privilege.name === 'DELETE' && domainObjectPrivilege.isactive === true) {
					isDeleteheckd = true;
				} else if (privilege.name === 'APPROVE' && domainObjectPrivilege.isactive === true) {
					isApproveCheckd = true;
				}
			});
			// console.log("isCreateCheckd=" + isCreateCheckd, "isUpdateCheckd=" + isUpdateCheckd, "isDeleteheckd=" + isDeleteheckd, "isApproveCheckd=" + isApproveCheckd);
			if (isCreateCheckd && isUpdateCheckd && isDeleteheckd && isApproveCheckd) {
				inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
					let privilege = domainObjectPrivilege;
					if (privilege.name === '*') {
						domainObjectPrivilege.isactive = true;
					} else {
						domainObjectPrivilege.isactive = false;
					}
				});
			} else {
				inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
					let privilege = domainObjectPrivilege;
					if (privilege.name === '*') {
						domainObjectPrivilege.isactive = false;
					}
				});
			}
		}
		inputdomainObject.isactive = true;
		this.handleDomainObjectChecked(inputdomainObject, menu);

	}

	handleDomainObjectPrivilegeUnChecked(inputDomainObjectPrivilege, inputdomainObject, menu) {
		let inputPrivilge = inputDomainObjectPrivilege;
		if (inputPrivilge.name === '*') {
			inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
				domainObjectPrivilege.isactive = false;
			});
		} else if (inputPrivilge.name === 'READ') {
			let flag = false;
			inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
				let privilege = domainObjectPrivilege;
				if (privilege.name !== 'READ' && domainObjectPrivilege.isactive === true) {
					flag = true;
				}
			});
			// console.log('Read deselected flag:', flag);

			inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
				let privilege = domainObjectPrivilege;
				if (privilege.name === 'READ') {
					// console.log("inputDomainObjectPrivilege", inputDomainObjectPrivilege);
					inputDomainObjectPrivilege.isactive = flag;
				}
			});

		} else {
			inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
				let privilege = domainObjectPrivilege;
				if (privilege.name === inputPrivilge.name) {
					domainObjectPrivilege.isactive = false;
				}
			});
		}

		let flag = false;
		inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
			if (domainObjectPrivilege.isactive === true) {
				flag = true;
			}
		});
		if (flag === false) {
			this.handleDomainObjectUnChecked(inputdomainObject, menu);
		}
	}

	renderDomainObjectPrivileges(domainObjectPrivilege, menuId, domainObjectId, action) {
		return (
			<>
				{action === "view" ?
					<div style={{ width: "10em", marginLeft: "3em", paddingBottom: "0px" }}>
						{domainObjectPrivilege.isactive ?
							<>
								<span className='bold' style={{ color: "green", fontWeight: "600" }}>{Utils.toCamelCaseUL(domainObjectPrivilege.name)}&nbsp;&nbsp;</span>
								<i class="fa fa-check" aria-hidden="true"></i>
							</> :
							<span style={{ color: "black" }}> {Utils.toCamelCaseUL(domainObjectPrivilege.name)}&nbsp;</span>
						}
					</div> :
					<li className="list-group-item list-group-item-action prev" style={{ width: "10em", marginLeft: "2em" }}>
						<input
							className="checkbox"
							type="checkbox" checked={domainObjectPrivilege.isactive}
							onChange={this.onDomainObjectPrivilegeClicked.bind(this, domainObjectPrivilege.id)}
						/>
						&nbsp; {Utils.toCamelCaseUL(domainObjectPrivilege.name)}
					</li>
				}
			</>
		);
	};

	onDomainObjectClicked(id) {
		this.state.menus.map((menu) => {
			menu.domainObjects.map((domainObject) => {
				if (domainObject.id === id) {
					if (domainObject.isactive === false) {
						this.handleDomainObjectChecked(domainObject, menu, true);
					} else {
						this.handleDomainObjectUnChecked(domainObject, menu, true);
					}
					return;
				}
			});
			menu.childMenus?.map((submenu) => {
				submenu.domainObjects.map((domainObject) => {
					if (domainObject.id === id) {
						if (domainObject.isactive === false) {
							this.handleDomainObjectChecked(domainObject, submenu, true);
						} else {
							this.handleDomainObjectUnChecked(domainObject, submenu, true);

						}
					}
					return;
				});
			})

		});
		this.setState({ menus: this.state.menus });
	}

	handleDomainObjectChecked(inputdomainObject, inputMenu, checkPrivilges) {
		if (checkPrivilges && checkPrivilges === true) {
			inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
				// if (domainObjectPrivilege.name === '*') {
				domainObjectPrivilege.isactive = true;
				// } else {
				// 	domainObjectPrivilege.isactive = false;
				// }
			});
		}

		inputdomainObject.isactive = true;
		this.state.menus.map((menu) => {
			menu.domainObjects.map((domainObject) => {

				if (inputdomainObject.name === domainObject.name) {
					menu.isactive = true;
					domainObject.isactive = true;
				}
			});

			menu.childMenus?.map((submenu) => {
				submenu.domainObjects.map((domainObject) => {
					if (inputdomainObject.name === domainObject.name) {
						submenu.isactive = true;
						menu.isactive = true;
					}
				});

			});

		});
	}

	handleDomainObjectUnChecked(inputdomainObject, menu, checkPrivilges) {
		if (checkPrivilges && checkPrivilges == true) {
			inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
				domainObjectPrivilege.isactive = false;
			});
		}
		inputdomainObject.isactive = false;
		this.state.menus.map((menu) => {
			let flag = false;
			let found = false;
			menu.domainObjects.map((domainObject) => {
				if (inputdomainObject.name === domainObject.name) {
					domainObject.isactive = false;
					found = true;
				}
				if (domainObject.isactive === true) {
					flag = true;
				}
			});
			if (found) {
				menu.isactive = flag;
			}

			menu.childMenus?.map((submenu) => {
				let subFlag = false;
				let subFound = false;
				submenu.domainObjects.map((subdomainObject) => {
					if (inputdomainObject.name === subdomainObject.name) {
						subdomainObject.isactive = false;
						submenu.isactive = false;
						subFound = true;
					}
					if (subdomainObject.isactive === true) {
						subFlag = true;
					}
				});

				if (subFound === true) {
					menu.childMenus.map((submenu) => {
						if (submenu.isactive === true) {
							subFlag = true;
						}
					});
					submenu.isactive = subFlag;
					menu.isactive = subFlag;

				}
			});


		});
	}

	renderDomainObject(domainObject, menu, action) {
		let activeMenus = this.state.activeMenus;
		let menuId = menu.id;
		return (
			<>
				{action === "view" ?
					<>
						<div style={{ width: "15em", paddingBottom: "0px" }}>
							{domainObject.isactive ?
								<>
									<span className='bold' style={{ fontSize: "16px", color: "#e67300", fontWeight: "650" }}> {domainObject.name}&nbsp;&nbsp;</span>
									<i class="fa fa-check" aria-hidden="true"></i>
								</> :
								<span className='bold' style={{ fontSize: "16px", color: "black", fontWeight: "650" }}> {domainObject.name}&nbsp;</span>
							}
						</div>
						<div className='row'>
							<>
								<div className='row d-flex'>
									<ul className="sub-menu list-group" stype={{ display: "inline" }}>
										{
											domainObject.domainObjectPrivileges.map((domainObjectPrivilege) =>
												this.renderDomainObjectPrivileges(domainObjectPrivilege, menuId, domainObject.id, action)
											)
										}
									</ul>
								</div>
							</>
						</div>
					</>
					:
					<li className="list-group-item list-group-item-action prev" >
						<input
							className="checkbox"
							type="checkbox"
							checked={domainObject.isactive}
							onChange={this.onDomainObjectClicked.bind(this, domainObject.id, menu)}
						/>
						&nbsp; &nbsp; {domainObject.name}&nbsp; &nbsp;
						{domainObject.domainObjectPrivileges.length > 0 ?
							<a className='btn-flat pull-right ps-2' style={{ "display": "contents" }}>
								<i
									className={activeMenus.has(menuId) && activeMenus.get(menuId).has(domainObject.id) ? 'fa fa-lg fa-minus' : 'fa fa-lg fa-plus'}
									onClick={this.showDomainObject.bind(this, domainObject.id, menuId)}>
								</i>
								{activeMenus.has(menuId) && activeMenus.get(menuId).has(domainObject.id) ?
									<ul className="sub-menu list-group" stype={{ "marginLeft": "2em" }}>
										{
											domainObject.domainObjectPrivileges.map((domainObjectPrivilege) =>
												this.renderDomainObjectPrivileges(domainObjectPrivilege, menuId, domainObject.id)
											)
										}
									</ul> : ""
								}
							</a> : ""
						}
					</li>
				}
			</>
		);
	};

	onMenuClicked(id) {
		this.state.menus.map((menu) => {
			if (menu.id === id) {
				if (menu.isactive === false) {
					this.handleMenuChecked(menu);
				} else {
					this.handleMenuUnChecked(menu);
				}
			}
			if (menu.childMenus && menu.childMenus.length > 0) {
				menu.childMenus.map((submenu) => {
					if (submenu.id === id) {
						if (submenu.isactive === false) {
							this.handleMenuChecked(submenu, menu);
						} else {
							this.handleMenuUnChecked(submenu, menu);
						}
					}
				})
			}
		});

		this.setState({ menus: this.state.menus });
	}

	handleMenuChecked(inputmenu) {
		this.state.menus.map((menu) => {
			if (menu.menuid === inputmenu.menuid) {
				menu.isactive = true;
				menu.childMenus?.map((submenu) => {
					submenu.isactive = true;
					submenu.domainObjects.map((domainObject) => {
						domainObject.isactive = true;
						domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
							domainObjectPrivilege.isactive = true;
						});
					});
				});
				menu.domainObjects.map((domainObject) => {
					domainObject.isactive = true;
					domainObject.domainObjectPrivileges?.map((domainObjectPrivilege) => {
						// if (domainObjectPrivilege.name === '*') {
						domainObjectPrivilege.isactive = true;
						// } else {
						// 	domainObjectPrivilege.isactive = false;
						// }
					});
				});
			} else {
				menu.childMenus?.map((submenu) => {
					if (submenu.menuid === inputmenu.menuid) {

						submenu.isactive = true;
						submenu.domainObjects.map((domainObject) => {
							domainObject.isactive = true;
							domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
								// if (domainObjectPrivilege.name === '*') {
								domainObjectPrivilege.isactive = true;
								// } else {
								// 	domainObjectPrivilege.isactive = false;
								// }
							});
						});
						menu.isactive = true;
					}
				});
			}

		});
	}

	handleMenuUnChecked(inputmenu, parentmenu) {
		this.state.menus.map((menu) => {
			if (menu.menuid === inputmenu.menuid) {
				menu.isactive = false;
				menu.childMenus?.map((submenu) => {
					submenu.isactive = false;
					submenu.domainObjects.map((domainObject) => {
						domainObject.isactive = false;
						domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
							domainObjectPrivilege.isactive = false;
						});
					});
				});
				menu.domainObjects.map((domainObject) => {
					domainObject.isactive = false;
					domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
						domainObjectPrivilege.isactive = false;
					});
				});
			} else {
				menu.childMenus?.map((submenu) => {
					if (submenu.menuid === inputmenu.menuid) {

						submenu.isactive = false;
						submenu.domainObjects.map((domainObject) => {
							domainObject.isactive = false;
							domainObject.domainObjectPrivileges?.map((domainObjectPrivilege) => {
								domainObjectPrivilege.isactive = false;
							});
						});

						let flag = false;
						menu.childMenus.map((submenu) => {
							if (submenu.isactive === true) {
								flag = true;
							}
						});
						menu.isactive = flag;
					}
				});

			}
		});

	}

	renderMenus(menu, action) {
		if (menu.childMenus && menu.childMenus.length > 0) {
			return (
				<>
					{action === "view" ?
						<div className="row d-flex ms-1 me-1">
							<li className="list-group-item list-group-item-action prev border-0"
							>
								<div style={{ width: "15em", paddingBottom: "0px" }}>
									{menu.isactive ?
										<>
											<span className='bold' style={{ fontSize: "16px", color: "#0099f1", fontWeight: "650" }}> {menu.uiname}&nbsp;&nbsp;</span>
											<i class="fa fa-check" aria-hidden="true" style={{ color: "#0099f1" }}></i>
										</> :
										<span className='bold' style={{ fontSize: "16px", color: "black", fontWeight: "650" }}> {menu.uiname}&nbsp;</span>
									}
								</div>
								<div className='row d-flex'>
									<ul className="list-group" style={{ display: "content", listStyleType: "none" }}>
										{
											menu.childMenus.map((submenu) =>
												this.renderMenus(submenu, "view"))
										}
									</ul>
								</div>
							</li>
						</div> :
						<li className="list-group-item list-group-item-action prev" style={{ marginLeft: "1em" }}>
							<input
								className="checkbox"
								type="checkbox"
								checked={menu.isactive}

								onChange={this.onMenuClicked.bind(this, menu.id)}
							/>
							&nbsp; &nbsp;{menu.uiname}&nbsp; &nbsp;
							<a style={{ display: "contents" }}>
								<i className={this.state.activeMenus.has(menu.id) ? 'fa fa-lg fa-minus' : 'fa fa-lg fa-plus'}

									onClick={this.showrolesubMenu.bind(this, menu.id)}>
								</i>
								{this.state.activeMenus.has(menu.id) ?
									<ul className="list-group" style={{ display: "content" }}>
										{
											menu.childMenus.map((submenu) =>
												this.renderMenus(submenu))
										}
									</ul> : ""
								}
							</a>
						</li>
					}
				</>
			)
		} else {
			return (
				<>
					{action === "view" ?
						<div className="row d-flex ms-1 me-1">
							<li className="list-group-item list-group-item-action prev border-0"
							>
								<div style={{ width: "16em", paddingBottom: "0px" }}>
									{menu.isactive ?
										<>
											<span className='bold' style={{ fontSize: "16px", color: "#0099f1", fontWeight: "650" }}> {menu.uiname}&nbsp;&nbsp;</span>
											<i class="fa fa-check" aria-hidden="true" style={{ color: "#0099f1" }}></i>
										</> :
										<span className='bold' style={{ fontSize: "16px", color: "black", fontWeight: "650" }}> {menu.uiname}&nbsp;</span>
									}
								</div>
								<ul className="sub-menu list-group" style={{ display: "flex", marginLeft: "2em", width: "fit-content" }}>
									{
										menu.domainObjects.map((domainObject) =>
											this.renderDomainObject(domainObject, menu, action)
										)
									}
								</ul>
							</li>
						</div> :
						<li className="list-group-item list-group-item-action prev"
							style={{ marginLeft: "1em" }} >
							<div >
								<input
									className="checkbox"
									type="checkbox"
									checked={menu.isactive}
									onChange={this.onMenuClicked.bind(this, menu.id)}
								/>
								&nbsp; &nbsp;{menu.uiname}&nbsp; &nbsp;
								{menu.domainObjects.length > 0 ?
									<a style={{ display: "contents" }}>
										<i
											className={this.state.activeMenus.has(menu.id) ? 'fa fa-lg fa-minus' : 'fa fa-lg fa-plus'}
											onClick={this.showrolesubMenu.bind(this, menu.id)}>
										</i>
										{this.state.activeMenus.has(menu.id) ?

											<ul className="sub-menu list-group" style={{ "display": "flex", marginLeft: "2em", width: "fit-content" }}>
												{
													menu.domainObjects.map((domainObject) =>
														this.renderDomainObject(domainObject, menu)
													)
												}
											</ul> : ""
										}
									</a> : ""
								}
							</div>
						</li>
					}
				</>
			);
		}
	};

	render() {
		if (this.state.action === 'create') {
			return this.createProfile();
		} else if (this.state.action === 'edit') {
			return this.editProfile();
		} else if (this.state.action === 'view') {
			return this.viewProfile();
		};
	}
	createProfile() {
		return (
			<>
				<AdminMenuWithBreadScrum
					breadCrumb={this.breadCrumb}
					navigate={this.props.navigate}
					name={this.title}
				/>
				<div className='row'>
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body" style={{ height: "100%" }}>
								<div className='row text-start'>
									<h5>Create Profile</h5>
								</div>
								{this.state.addProfile ?
									<div className='row d-flex justify-content-center'>
										<div className='col-md-4 mt-4'>
											<div className="row">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Profile<span className="asterisk"> *</span>
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<TextField
														type="text"
														className="textField"
														fullWidth
														id="basic-default-name"
														variant="outlined"
														placeholder="Profile"
														value={this.state.name}
														onChange={this.handleNameChange.bind(this)}
														required
													/>
												</div>
											</div>
										</div>
										<div className="col-sm-4 mt-4 text-start">
											<CreateButton btnName="Create" type="submit" onClick={this.createProfileToBackend.bind(this)} />
											&nbsp;&nbsp;
											<CancelIconButton btnName="Cancel" onClick={(e) => this.cancelProfile()} />
										</div>
									</div> :
									<div className='row d-flex justify-content-center'>
										<div className='col-md-4 mt-4'>
											<div className="row">
												<label
													className="col-sm-3 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Profile<span className="asterisk"> *</span>
												</label>
												<div className="col-sm-9" id="OrganizationCol">
													<TextField
														type="text"
														className="textField"
														fullWidth
														id="basic-default-name"
														variant="outlined"
														placeholder="Profile"
														value={this.state.name}
														onChange={this.handleNameChange.bind(this)}
														required
													/>
												</div>
											</div>
										</div>
									</div>
								}
								<div className="mt-4">
									<ul className="list-group text-start">
										{
											this.state.menus && this.state.menus.sort((a, b) => a.menu - b.menu).map((menu) =>
												this.renderMenus(menu)
											)
										}
									</ul>
								</div>
								{this.state.updateProfile &&
									<div className='row justify-content-center pt-4'>
										<button
											className="updateButton"
											type='button'
											onClick={this.updateProfileToBackend.bind(this)}
										>
											UPDATE
										</button>
										&nbsp;
										<button
											className="cancelButton"
											type='button'
											onClick={this.cancelProfile.bind(this)}
										>
											CANCEL
										</button>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	viewProfile() {
		if (this.state.render === false) {
			return <></>;
		} else {
			return (
				<>
					<AdminMenuWithBreadScrum
						breadCrumb={this.breadCrumb}
						navigate={this.props.navigate}
						deleteCallback={this.deleteProfileToBackend.bind(this)}
						editCallback={this.editProfileManagement.bind(this)}
						getDeletePermission={this.state.getDeletePermission}
						getUpdatePermission={this.state.getUpdatePermission}
						domainObject="Profile"
						name="View Profile"
					/>
					<div className='row mt-1'>
						<div className="col-sm-12">
							<div className="card">
								<div className="card-body" style={{ height: "100%" }}>
									<div className="col-auto me-auto text-start">
										<h5 className="mb-1" id="headerLabel">
											<span className='ms-3'>{this.state.name}</span>
										</h5>
									</div>
									<div className="mt-4">
										<ul className="list-group text-start" style={{ display: "inline-flex", flexWrap: "wrap", flexDirection: "row", listStyleType: "none" }}>
											{
												this.state.menus && this.state.menus.sort((a, b) => a.menu - b.menu).map((menu) =>
													this.renderMenus(menu, "view")
												)
											}
										</ul>
									</div>
								</div>
							</div>
							<div className="sponsorDetailFooter">
								<BackButton
									type="button"
									style={{ backgroundColor: '#162c50', color: '#fff' }}
									variant="contained"
									onClick={(e) => this.cancelProfile()}
								>
									Back
								</BackButton>
							</div>
						</div>
					</div>
				</>
			);
		}
	};

	editProfile() {
		if (this.state.render === false) {
			return <></>;
		} else {
			return (
				<>
					<AdminMenuWithBreadScrum
						breadCrumb={this.breadCrumb}
						navigate={this.props.navigate}
						name={this.title}
					/>
					<div className='row'>
						<div className="col-sm-12">
							<div className="card">
								<div className="card-body" style={{ height: "100%" }}>
									<div className='row text-start'>
										<h5>Edit Profile</h5>
									</div>
									<div className='row'>
										<div className='col-md-4 mt-4'>
										</div>
										<div className='col-md-4 mt-4'>
											<div className="row">
												<label
													className="col-sm-4 col-form-label fw-bold"
													htmlFor="basic-default-name"
													id="assetFormLabel"
												>
													Profile Name<span className="asterisk"> *</span>
												</label>
												<div className="col-sm-8" id="OrganizationCol">
													<TextField
														type="text"
														className="textField"
														fullWidth
														id="basic-default-name"
														variant="outlined"
														placeholder="Role"
														value={this.state.name}
														onChange={this.handleNameChange.bind(this)}
														required
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="mt-4">
										<ul className="list-group text-start">
											{
												this.state.menus && this.state.menus.sort((a, b) => a.menu - b.menu).map((menu) =>
													this.renderMenus(menu)
												)
											}
										</ul>
									</div>
									<div className='row justify-content-center pt-4'>
										<button
											className="updateButton"
											type='button'
											onClick={this.updateProfileToBackend.bind(this)}
										>
											UPDATE
										</button>
										&nbsp;
										<button
											className="cancelButton"
											type='button'
											onClick={this.editCancel.bind(this)}
										>
											CANCEL
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			);
		}
	}
};
export default withRouter(ProfileManagement);