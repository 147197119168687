import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { withRouter } from "../../withRouter";
import MaterialTable from "material-table";
import UiUtils from '../../provider/UiUtils';
import AdminFetchServerData from "../../provider/AdminFetchServerData";
import store from '../../redux/store';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import ApplicationStateAction from '../../redux/applicationState/ApplicationStateAction';
import Utils from '../../provider/Utils';

class EmailTemplateList extends Component {
	title = 'Email Template List';
	breadCrumb = [{ pageid: '', uiname: 'Email Template List' }];
	header = store.getState().adminHeader.adminHeader;
	emailTemplateState = store.getState().applicationState.emailTemplateState;
	emailTemplateDetails = {};
	constructor(props) {
		super(props);
		if (this.emailTemplateState) {
			this.emailTemplateDetails = Object.getOwnPropertyNames(this.emailTemplateState);
		}
		if (this.emailTemplateDetails.length > 0) {
			this.state = {
				emailTemplateList: [],
				isPaging: false,
				initialPage: this.emailTemplateState.initialPage,
				pageSize: this.emailTemplateState.pageSize,
				emailTemplateId: this.emailTemplateState.emailTemplateId,
				render: false,
			};
		} else {
			this.state = {
				emailTemplateList: [],
				isPaging: false,
				initialPage: 0,
				pageSize: 10,
				emailTemplateId: '',
				render: false,
			};
		}
		this.getEmailTemplateList();
	}

	getEmailTemplateList() {
		const postObject = {
			header: this.header,
		};
		AdminFetchServerData.callPostService('/emailTemplatems/getList', postObject).then((output) => {
			if (output.status === 'SUCCESS') {
				let emailTemplateList = output.data.templateList;
				let isPaging = false;
				if (emailTemplateList.length > 10) {
					isPaging = true;
				}
				this.setState({
					render: true,
					isPaging: isPaging,
					emailTemplateList: emailTemplateList,
					initialPage: this.state.initialPage,
					pageSize: this.state.pageSize,
				});
			} else {
			}
		});
	}

	toEmailTemplateDetails(e, data) {
		let emailTemplateState = {
			emailTemplateId: data.id,
			initialPage: this.state.initialPage,
			pageSize: this.state.pageSize,
		};
		store.dispatch(ApplicationStateAction.setApplicationState({ emailTemplateState }));
		this.props.navigate('/emailTemplate', {
			state: { action: 'view', title: 'Email Template Details', emailTemplateId: data.id, pageId: 'sponsorList' },
		});
	}

	handleMaterialTablePageChange(page, pageSize) {
		this.setState({ initialPage: page, pageSize: pageSize });
	}

	render() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<>
					<AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} navigate={this.props.navigate} name={this.title} />
					<Row style={{ position: 'relative', margin: '1em 0em', maxWidth: '100%' }}>
						<Col md={12}>
							<MaterialTable
								onRowClick={(event, rowData) => {
									this.toEmailTemplateDetails(event, rowData);
									event.stopPropagation();
								}}
								icons={UiUtils.getMaterialTableIcons()}
								title=""
								columns={[
									{
										title: '#',
										render: (rowData) => rowData.tableData.id + 1,

										width: '6%',
										align: 'left',
									},
									{
										title: 'Name',
										field: 'uid',
										width: '35%',
									},
									{
										title: 'Subject',
										field: 'subject',
										width: '30%',
									},
									{
										title: 'Description',
										field: 'description',
										width: '30%',
										// cellStyle: { textTransform: 'none' },
									},
								]}
								data={this.state.emailTemplateList}
								onPageChange={(page, pageSize) => {
									this.handleMaterialTablePageChange(page, pageSize);
								}}
								options={{
									sorting: true,
									actionsColumnIndex: -1,
									initialPage: this.state.initialPage,
									pageSize: this.state.pageSize,
									headerStyle: {
										backgroundColor: '#0B6F9D',
										fontWeight: 'bold',
										color: '#fff',
										align: 'left',
									},
									pageSizeOptions: Utils.materialTablePageSize(),
									paging: this.state.isPaging,
									rowStyle: (rowData) => ({
										backgroundColor: rowData.id === this.state.emailTemplateId ? '#FEF5E7' : '#fff',
										height: '3em',
										textTransform: 'capitalize',
									}),

									tableLayout: 'auto',
								}}
							/>
						</Col>
					</Row>
				</>
			);
		}
	}
}

export default withRouter(EmailTemplateList);
