// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
// import * as serviceWorker from "./registerServiceWorker";
ReactDOM.render(
  <>
    <App />
    <div id="iAmUniqueModalAT" className="ptsmodal">
      <div id="iAmUniqueLoaderAT" className="ptsloader"></div>
    </div>
    <button
      className="btn btn-primary text-loader"
      id="loader-with-text"
      type="button"
    >
      <span className="spinner-border" role="status" aria-hidden="true"></span>
      <span id="loader-with-text-label">Loading...</span>
    </button>
    <div className="sk-grid sk-primary page-loader" id="pageLoader">
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
    </div>
    <div className="overlay" id="overlay"></div>
  </>,
  document.getElementById("app")
);
// const root = ReactDOM.createRoot(document.getElementById("app"));
// root.render(
//   <>
//     <App />
//     <div id="iAmUniqueLoaderAT" className="ptsloaderHide"></div>
//     <button
//       className="btn btn-primary text-loader"
//       id="loader-with-text"
//       type="button"
//     >
//       <span className="spinner-border" role="status" aria-hidden="true"></span>
//       <span id="loader-with-text-label">Loading...</span>
//     </button>
//     <div className="sk-grid sk-primary page-loader" id="pageLoader">
//       <div className="sk-grid-cube"></div>
//       <div className="sk-grid-cube"></div>
//       <div className="sk-grid-cube"></div>
//       <div className="sk-grid-cube"></div>
//       <div className="sk-grid-cube"></div>
//       <div className="sk-grid-cube"></div>
//       <div className="sk-grid-cube"></div>
//       <div className="sk-grid-cube"></div>
//       <div className="sk-grid-cube"></div>
//     </div>
//     <div className="overlay" id="overlay"></div>
//   </>
// );
