import React, { Component } from "react";
import { withRouter } from "../../../withRouter";
import FetchServerData from "../../../provider/FetchServerData";
import { MenuWithBreadScrum } from "../../menu/MenuWithBreadScrum";
import { NavLink } from "react-router-dom";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import Button from "@material-ui/core/Button";
import store from "../../../redux/store";
import ApplicationStateAction from "../../../redux/applicationState/ApplicationStateAction";
import Utils from "../../../provider/Utils";

class CustomerWiseAssetList extends Component {
  header = store.getState().header.header;
  customerState = store.getState().applicationState.customerState;
  customerStateDetails = {};
  breadCrumb = [
    { pageid: "/reports", uiname: "MIS Reports" },
    { pageid: "/organizationAssets", uiname: "Asset Stock Analytics" },
    { pageid: "", uiname: "Customer wise Stock" },
  ];
  constructor() {
    super();
    this.state = {
      initialPage: 0,
      pageSize: 10,
      customer: {},
      organizationDetails: {},
      allCustomerStatisticList: [],
      locationWiseStatsList: [],
      totalStatistics: [],
      isPaging: false,
      render: false,
      customerWiseStat: false,
      customerLocationWiseStat: false,
      getCreatePermission: false,
      customerId: "",
    };
    this.getAllCustomerStats();
  }

  getAllCustomerStats() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService(
      "/assetcodeStatsms/getAllCustomerStats",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let allCustomerStatisticList = output.data.customerStatisticList;
        let totalStatistics = output.data.totalStatistics;
        let organizationDetails = output.data.organization;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (allCustomerStatisticList.length > this.state.pageSize) {
          isPaging = true;
        }
        this.setState({
          render: true,
          customerWiseStat: true,
          customerLocationWiseStat: false,
          isPaging: isPaging,
          organizationDetails: organizationDetails,
          allCustomerStatisticList: allCustomerStatisticList,
          totalStatistics: totalStatistics,
          initialPage: this.state.initialPage,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }
  addCustomer() {
    this.props.navigate("/customer", {
      state: {
        action: "create",
      },
    });
  }

  toCustomerDetails(e, data) {
    let customerState = {
      customerId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ customerState })
    );
    this.props.navigate("/customer", {
      state: {
        action: "view",
        customerId: data.id,
      },
    });
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  customerLocationWiseStat(data) {
    const postObject = {
      header: this.header,
      customer: {
        id: data.id,
      },
    };

    FetchServerData.callPostService(
      "/assetcodeStatsms/getCustomerStats",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let customerStatisticData = output.data;
        let customerDetails = customerStatisticData.customer;
        let locationWiseStatsList = customerStatisticData.locationWiseStats;
        let totalStatistics = customerStatisticData.customerLocationStatistics;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (locationWiseStatsList.length > this.state.pageSize) {
          isPaging = true;
        }
        this.setState({
          render: true,
          customerWiseStat: false,
          customerLocationWiseStat: true,
          isPaging: isPaging,
          customer: customerDetails,
          locationWiseStatsList: locationWiseStatsList,
          totalStatistics: totalStatistics,
          initialPage: this.state.initialPage,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  printTable() {
    var printWindow = window.open("", "", "height=600px,width=800px");
    printWindow.document.write("<html><head><title>Table Contents</title>");

    //Print the Table CSS.
    var table_style = document.getElementById("table_style").innerHTML;
    printWindow.document.write('<style type = "text/css">');
    printWindow.document.write(table_style);
    printWindow.document.write("</style>");
    printWindow.document.write("</head>");

    //Print the DIV contents i.e. the HTML Table.
    printWindow.document.write("<body>");
    var divContents = document.getElementById("dvContents").innerHTML;
    printWindow.document.write(divContents);
    printWindow.document.write("</body>");

    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }

  backButton() {
    this.props.navigate("/organizationAssets");
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          {this.state.customerLocationWiseStat && (
            <>
              <MenuWithBreadScrum
                breadCrumb={this.breadCrumb}
                navigate={this.props.navigate}
                backCallback={this.backButton.bind(this)}
                domainObject="Location wise Stock"
                name="Location wise Stock"
              />
              <div className="row mb-12 row justify-content-end">
                <div
                  className="row"
                  style={{
                    padding: "1em",
                    paddingRight: "2em",
                    marginTop: "-12px",
                  }}
                >
                  <div className="card" id="organizationFormCard">
                    <div className="row">
                      <div
                        className="card-header d-flex align-items-center justify-content-between"
                        id="assetCardHeader"
                      >
                        <h5 className="mb-0">
                          <NavLink
                            className="navLink text-decoration-underline"
                            onClick={this.getAllCustomerStats.bind(this)}
                          >
                            <u>{this.state.customer?.name}</u>
                          </NavLink>
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-sm-12" id="OrganizationCol">
                                <span>{this.state.customer?.address}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.locationWiseStatsList.length > 0 ? (
                  <>
                    <div className="col-sm-8 m-2 text-end">
                      <Button
                        variant="contained"
                        size="small"
                        id="printBtn"
                        style={{
                          background: "#0577ab",
                          color: "#fff",
                          padding: "7px",
                          width: "100px",
                        }}
                        startIcon={<PrintRoundedIcon />}
                        onClick={this.printTable}
                      >
                        print
                      </Button>
                    </div>
                    <div className="table-responsive" id="dvContents">
                      <table
                        className="table text-start table-hover"
                        id="table_style"
                      >
                        <thead
                          className="assetCycleThead"
                          style={{
                            backgroundColor: "#0B6F9D",
                            color: "#fff",
                            fontWeight: "bold",
                          }}
                        >
                          <tr className="hoverTable">
                            <td style={{ width: "6px" }}>{"#"}</td>
                            <td style={{ width: "180px" }}>Asset Code</td>
                            {this.state.totalStatistics?.map(
                              (rowData, index) => (
                                <td className="text-center" key={index}>
                                  {rowData.name}
                                </td>
                              )
                            )}
                          </tr>
                        </thead>
                        {this.state.locationWiseStatsList?.map(
                          (asset, index) => (
                            <tbody>
                              <tr className="hoverTable" key={index}>
                                <td style={{ width: "6px" }}>{index + 1}</td>
                                <td style={{ width: "180px" }}>
                                  {asset.assetCode}
                                </td>
                                {asset.locationList?.map((rowData, index) => {
                                  return (
                                    <>
                                      <td className="text-center">
                                        {rowData.count}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                            </tbody>
                          )
                        )}
                      </table>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
          {this.state.customerWiseStat && (
            <>
              <MenuWithBreadScrum
                breadCrumb={this.breadCrumb}
                navigate={this.props.navigate}
                backCallback={this.backButton.bind(this)}
                domainObject="Customer wise Stock"
                name="Customer wise Stock"
              />
              <div className="row mb-12 row justify-content-end">
                <div
                  className="row"
                  style={{
                    padding: "1em",
                    paddingRight: "2em",
                    marginTop: "-12px",
                  }}
                >
                  <div className="card" id="organizationFormCard">
                    <div className="row">
                      <div
                        className="card-header d-flex align-items-center justify-content-between"
                        id="assetCardHeader"
                      >
                        <h5 className="mb-0 text-center">
                          <NavLink
                            className="navLink text-decoration-underline"
                            to="/organizationAssets"
                          >
                            <u>{this.state.organizationDetails?.name}</u>
                          </NavLink>
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-sm-12" id="OrganizationCol">
                                <span>
                                  {this.state.organizationDetails?.addresss}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 m-2 text-end">
                  <Button
                    variant="contained"
                    size="small"
                    id="printBtn"
                    style={{
                      background: "#0577ab",
                      color: "#fff",
                      padding: "6.5px",
                      width: "100px",
                    }}
                    startIcon={<PrintRoundedIcon />}
                    onClick={this.printTable}
                  >
                    print
                  </Button>
                </div>
                <div
                  className="row-md-12"
                  style={{
                    padding: "1em",
                    marginTop: "-12px",
                    height: "600px",
                  }}
                >
                  <div className="card" id="organizationFormCard">
                    <div className="table-responsive" id="dvContents">
                      <table
                        className="table text-start table-hover"
                        id="table_style"
                      >
                        <thead
                          className="assetCycleThead"
                          style={{
                            fontWeight: "650",
                            backgroundColor: "aliceblue",
                          }}
                        >
                          <tr className="hoverTable">
                            <td style={{ width: "6px" }}>{"#"}</td>
                            <td style={{ width: "180px" }}>Asset Code</td>
                            {this.state.totalStatistics?.map(
                              (rowData, index) => (
                                <td key={index} className="text-center">
                                  <NavLink
                                    className="navLink text-decoration-underline text-center"
                                    onClick={this.customerLocationWiseStat.bind(
                                      this,
                                      rowData
                                    )}
                                  >
                                    <u>{rowData.name}</u>
                                  </NavLink>
                                </td>
                              )
                            )}
                          </tr>
                        </thead>
                        {this.state.allCustomerStatisticList?.map(
                          (asset, index) => {
                            return (
                              <tbody>
                                <tr className="hoverTable" key={index}>
                                  <td style={{ width: "6px" }}>{index + 1}</td>
                                  <td style={{ width: "180px" }}>
                                    {asset.name}
                                  </td>
                                  {asset.statList?.map((rowData, index) => {
                                    return (
                                      <>
                                        <td className="text-center">
                                          {rowData.count}
                                        </td>
                                      </>
                                    );
                                  })}
                                </tr>
                              </tbody>
                            );
                          }
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }
}
export default withRouter(CustomerWiseAssetList);
