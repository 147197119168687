import Tooltip from '@material-ui/core/Tooltip';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import React, { Component } from 'react';

export class PrintIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domainObject: props.onClick,
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        onClick={this.onClick.bind(this)}
        style={{ cursor: 'pointer', fontSize: 'x-larger' }}
      >
        <Tooltip title="Print">
          <PrintRoundedIcon
            className="print"
            style={{
              borderRadius: '25%',
              background: '#867e7e',
              color: 'white',
              padding: '3px',
              fontSize: 'x-large',
              width: '30px',
              minHeight: '30px',
            }}
          />
        </Tooltip>
      </span>
    );
  }
}
export default PrintIconButton;
