import { GET_USER_ROLE, SET_USER_ROLE } from "./UserRoleTypes";

const getUserRole = () => {
  return {
    type: GET_USER_ROLE,
  };
};
const setUserRole = (data) => {
  return {
    type: SET_USER_ROLE,
    payload: data,
  };
};
const UserRoleAction = { getUserRole, setUserRole };
export default UserRoleAction;
