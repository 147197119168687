import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import { Card, CardBody, Col, Row } from "reactstrap";
import "./EmailTemplate.css";
import Quill from "./quill/Quill";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ValidatorForm } from "react-material-ui-form-validator";
import AdminFetchServerData from "../../provider/AdminFetchServerData";
import Utils from "../../provider/Utils";
import store from "../../redux/store";
import BackButton from "../../component/buttons/BackButton";
import CancelIconButton from "../../component/buttons/CancelIconButton";
import SaveButton from "../../component/buttons/SaveButton";
import PtsAlert from "../../component/ptsAlert/PtsAlert";
// import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import Tooltip from "./tooltip/Tooltip";
import AdminMenuWithBreadScrum from "../adminMenu/AdminMenuWithBreadScrum";
class EmailTemplate extends Component {
  title = "Email Template Details";
  breadCrumb = [
    { pageid: "/emailTemplateList", uiname: "Email Template List" },
    { pageid: "", uiname: "Email Template" },
  ];
  header = store.getState().adminHeader.adminHeader;
  constructor(props) {
    super(props);
    let emailTemplateId = props.emailTemplateId;
    let action = props.action;
    if (!action && props.state) {
      action = props.state.action;
      emailTemplateId = props.state.emailTemplateId;
    }
    if (
      action &&
      Utils.equalsIgnoreCase(
        action,
        "view" || Utils.equalsIgnoreCase(action, "update")
      )
    ) {
      this.getTemplateDetails(emailTemplateId);
    }
    this.state = {
      action: action,
      message: "",
      fromName: "",
      replyToEmail: "",
      subject: "",
      variables: "",
      anchorEl: null,
      emailTypeList: [],
      emailType: null,
      selectedEmailFlag: 0,
      id: emailTemplateId,
      variableList: [],
      description: "",
      viewEmailUid: "",
      copied: false,
      render: false,
    };
  }

  handleEmailTypeChange(selectedEmailType) {
    this.state.emailType = selectedEmailType;
    this.setState({ emailType: selectedEmailType });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.getTemplateDetails(selectedEmailType.id);
  }

  getTemplateDetails(emailTemplateId) {
    let postObject = {
      header: this.header,
      template: { id: emailTemplateId },
    };
    AdminFetchServerData.callPostService("/emailTemplatems/get", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          let templateDetails = output.data.template;
          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getUpdatePermission = getPermission[3];
          this.getEmailTemplateList(templateDetails.uid);
          this.setState({
            render: true,
            viewEmailUid: templateDetails.uid,
            message: templateDetails.content,
            fromName: templateDetails.from_email,
            variableList: templateDetails.variableList,
            subject: templateDetails.subject,
            description: templateDetails.description,
            variables: this.state.variables,
            id: templateDetails.id,
            getUpdatePermission: getUpdatePermission,
          });
        }
      })
      .catch((error) => {});
  }

  getEmailTemplateList(templateUid) {
    let postObject = {
      header: this.header,
    };
    AdminFetchServerData.callPostService("/emailTemplatems/getList", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          let emailTypeListData = output.data;
          for (let i = 0; emailTypeListData.length; i++) {
            if (emailTypeListData[i].uid === templateUid) {
              this.setState({
                emailType: emailTypeListData[i],
                emailTypeList: emailTypeListData,
              });
            }
          }
        }
      })
      .catch((error) => {});
  }

  addNotificationTemplateInBackend() {
    let postObject = {
      header: this.header,
      template: {
        uid: this.state.viewEmailUid,
        from_email: this.state.fromName,
        subject: this.state.subject,
        content: this.state.message,
        variableList: this.state.variableList,
        description: this.state.description,
        variables: this.state.variables,
        id: this.state.id,
      },
    };
    AdminFetchServerData.callPostService("/emailTemplatems/update", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let templateDetails = output.data.template;
          this.getTemplateDetails(templateDetails.id);
          window.scroll(0, 0);
          this.setState({
            action: "view",
          });
        }
      })
      .catch((error) => {});
  }

  handleSubjectChange(e) {
    this.setState({
      subject: e.target.value,
    });
  }
  clearTemplate(e) {
    this.setState({
      message: "",
      fromName: "",
      replyToEmail: "",
      subject: "",
      emailType: null,
    });
  }

  handleReplyToEmailChange(e) {
    this.setState({
      replyToEmail: e.target.value,
    });
  }

  handleMessageChange(value) {
    this.setState({ message: value });
  }

  handleClose(event) {
    this.setState({ anchorEl: null });
  }
  editCancel() {
    this.setState({
      action: "view",
    });
  }

  copySelected(string, e) {
    if (string === "") {
      PtsAlert.error("Please select an account to copy.");
      return;
    }
    // navigator.clipboard.writeText(string);
    PtsAlert.success(string + " Copied");
  }
  onCopy = (e) => {
    // navigator.clipboard.writeText(this.state.websiteuser)
    this.setState({ copied: true });
  };
  showVariableList() {
    return this.state.variableList.map((data, index) => {
      if (this.state.render === false) {
        return <div />;
      } else {
        let splitList = data.split("-");
        let selectVariable = splitList[0];
        return (
          <>
            <li>
              {/* <Tooltip title={selectVariable}> */}
              <Tooltip title={selectVariable} aria-label="add">
                <IconButton style={{ marginLeft: "30px" }}>
                  {/* <i class="fa fa-clone" aria-hidden="true" onClick={this.copySelected.bind(this, selectVariable)} /> */}
                  <CopyToClipboard text={selectVariable} onClick={this.onCopy}>
                    <FileCopyIcon style={{ color: "gray", fontSize: "20px" }} />
                  </CopyToClipboard>
                </IconButton>
              </Tooltip>
              &nbsp;
              {/* </Tooltip> */}
              {data}
            </li>
          </>
        );
      }
    });
  }
  handleDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ description: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }
  editEmail() {
    this.setState({
      action: "edit",
      emailTemplateId: this.state.emailTemplateId,
    });
  }
  viewCancel() {
    this.props.navigate("/emailTemplateList");
  }
  render() {
    if (this.state.action === "view") {
      return this.viewEmailTemplate();
    } else if (this.state.action === "edit") {
      return this.editEmailTemplate();
    }
  }
  viewEmailTemplate() {
    // const open = Boolean(this.state.anchorEl);
    // const id = open ? 'simple-popover' : undefined;
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            // backCallback={this.viewCancel.bind(this)}
            name={this.title}
            editCallback={this.editEmail.bind(this)}
            getUpdatePermission={this.state.getUpdatePermission}
          />
          <Row style={{ position: "relative", margin: "0.5em" }}>
            <Col
              md={12}
              className="sponsorDetailColumn"
              style={{ position: "relative", padding: "5px" }}
            >
              <Card style={{ height: "100%" }}>
                {/* <CardHeader className="cardHeader"> */}
                {/* </CardHeader> */}
                <CardBody className="text-start">
                  {/* <Row>
										<Col md={10} className="text-start ps-4">
											<Label className="cardHeaderTitle">
												Email Template Details
											</Label>
										</Col>
									</Row> */}
                  <Row className="mt-3">
                    <Col md={2} className="labelColumn">
                      <label>Template Id:</label>
                    </Col>
                    <Col md={10}>{this.state.viewEmailUid}</Col>
                  </Row>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label>Subject:</label>
                    </Col>
                    <Col md={10}>{this.state.subject}</Col>
                  </Row>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label>Description:</label>
                    </Col>
                    <Col md={10}>
                      {" "}
                      {/* <pre className="view_AppointmentNote" style={{ marginLeft: '-1.9px' }}> */}{" "}
                      {this.state.description}
                      {/* </pre> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label> Email Body:</label>
                    </Col>
                    <Col md={10}>
                      {" "}
                      {this.state.variableList.length > 0 && (
                        <p style={{ marginLeft: "0.2em" }}>
                          The following variables has a significance role in
                          composing the email. Please do not change it.
                        </p>
                      )}
                      <Row style={{ lineHeight: "1.8em", marginLeft: "0.2em" }}>
                        <Col md={12} style={{ display: "flex" }}>
                          <ul style={{ listStyle: "none" }}>
                            {this.showVariableList()}
                          </ul>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.message,
                            }}
                            className="emailBody"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="sponsorDetailFooter">
            <BackButton
              type="button"
              style={{ backgroundColor: "#162c50", color: "#fff" }}
              variant="contained"
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }
  editEmailTemplate() {
    // const open = Boolean(this.state.anchorEl);
    // const id = open ? 'simple-popover' : undefined;
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          name={this.title}
        />
        <Row style={{ position: "relative", margin: "1em" }}>
          <Col md={12} style={{ position: "relative", padding: "5px" }}>
            <ValidatorForm
              ref="form"
              style={{ width: "100%" }}
              onSubmit={this.addNotificationTemplateInBackend.bind(this)}
            >
              <Card>
                <CardBody className="text-start">
                  <Row className="mb-4">
                    <Col md={2} className="labelColumn">
                      <label>Template Id:</label>
                    </Col>
                    <Col md={10}>{this.state.viewEmailUid}</Col>
                  </Row>

                  <Row>
                    <Col md={2} className="labelColumn">
                      <label id="notificationFormLabel">
                        Subject <span className="asterisk">*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="text"
                        required
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter Subject"
                        autoComplete="off"
                        margin="normal"
                        value={this.state.subject}
                        onChange={this.handleSubjectChange.bind(this)}
                        // inputProps={{
                        //   style: { textTransform: 'capitalize' },
                        // }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label id="notificationFormLabel">
                        Description <span className="asterisk">*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        style={{
                          width: "100%",
                          padding: "10px",
                          marginTop: "2em",
                        }}
                        rowsMin={3}
                        id={"note" + this.state.index}
                        name="note"
                        value={this.state.description}
                        inputProps={{ maxLength: 256 }}
                        onChange={this.handleDescriptionChange.bind(this)}
                        placeholder="Description"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Row>
                      <Col md={2} className="labelColumn">
                        <label id="notificationFormLabel">Body</label>
                      </Col>
                      <Col md={10} style={{ marginTop: "2em" }}>
                        {this.state.variableList.length > 0 && (
                          <p style={{ marginLeft: "0.2em" }}>
                            The following variables has a significance role in
                            composing the email. Please do not change it.
                          </p>
                        )}
                        <Row
                          style={{ lineHeight: "1.8em", marginLeft: "0.2em" }}
                        >
                          <Col md={12} style={{ display: "flex" }}>
                            <ul style={{ listStyle: "none" }}>
                              {this.showVariableList()}
                            </ul>
                          </Col>
                        </Row>
                        <Quill
                          text={this.state.message}
                          onChange={this.handleMessageChange.bind(this)}
                          className="templateMessageBox"
                        />
                      </Col>
                    </Row>
                  </Row>
                </CardBody>

                <div style={{ textAlign: "center", padding: "0.5em" }}>
                  <SaveButton type="submit" />
                  &nbsp;
                  <CancelIconButton onClick={(e) => this.editCancel()} />
                </div>
              </Card>
            </ValidatorForm>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(EmailTemplate);
