import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import Utils from "../../provider/Utils";
import AdminFetchServerData from "../../provider/AdminFetchServerData";
import store from "../../redux/store";
import AdminMenuWithBreadScrum from "../adminMenu/AdminMenuWithBreadScrum";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import ToggleButton from "@mui/material/ToggleButton";

class Registration extends Component {
  header = store.getState().adminHeader.adminHeader;
  breadCrumb = [
    { pageid: "/registrationList", uiname: "Registration List" },
    { pageid: "", uiname: "Registration" },
  ];
  constructor(props) {
    super(props);
    let action = props.action;
    let registrationId = props.registrationId;
    if (!action && props.state) {
      action = props.state.action;
      registrationId = props.state.registrationId;
    }
    if (
      action &&
      Utils.equalsIgnoreCase(
        action,
        "view" || Utils.equalsIgnoreCase(action, "update")
      )
    ) {
      this.getRegistrationDetails(registrationId);
    }
    this.state = {
      action: action,
      companyName: "",
      email: "",
      gstn: "",
      disabledReason: "",
      isdisabled: false,
      render: false,
    };
  }

  getRegistrationDetails(registrationId) {
    const postObject = {
      header: this.header,
      registration: {
        id: registrationId,
      },
    };
    AdminFetchServerData.callPostService(
      "/adminpanelStatsms/getRegistration",
      postObject
    )
      .then((output) => {
        if (output.status === "SUCCESS") {
          let registrationDetails = output.data.registration;
          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          this.setState({
            render: true,
            id: registrationDetails.id,
            companyName: registrationDetails.name,
            email: registrationDetails.email,
            gstn: registrationDetails.gstn,
            // isStatus: userStatus,
            isdisabled: registrationDetails.isdisabled,
            createdon: registrationDetails.createdon,
            disabledReason: registrationDetails.disabledReason,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  handleDisableReasonChange(e) {
    this.setState({
      disabledReason: e.target.value,
    });
  }

  handleEnableStatusChange() {
    const postObject = {
      header: this.header,
      registration: {
        id: this.state.id,
        // enabledReason: this.state.reason,
      },
    };
    AdminFetchServerData.callPostService(
      "/adminpanelStatsms/enableRegistration",
      postObject
    )
      .then((output) => {
        if (output.status === "SUCCESS") {
          let registrationEnableDetails = output.data.registration;
          this.setState({
            render: true,
            id: registrationEnableDetails.id,
            companyName: registrationEnableDetails.name,
            email: registrationEnableDetails.email,
            gstn: registrationEnableDetails.gstn,
            // isStatus: userStatus,
            isdisabled: registrationEnableDetails.isdisabled,
            disabledReason: registrationEnableDetails.disabledReason,
            createdon: registrationEnableDetails.createdon,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  handleDisabledStatusChange() {
    const postObject = {
      header: this.header,
      registration: {
        id: this.state.id,
        disabledReason: this.state.disabledReason,
      },
    };
    AdminFetchServerData.callPostService(
      "/adminpanelStatsms/disableRegistration",
      postObject
    )
      .then((output) => {
        if (output.status === "SUCCESS") {
          let registrationDisableDetails = output.data.registration;
          this.setState({
            render: true,
            id: registrationDisableDetails.id,
            companyName: registrationDisableDetails.name,
            email: registrationDisableDetails.email,
            gstn: registrationDisableDetails.gstn,
            // isStatus: userStatus,
            isdisabled: registrationDisableDetails.isdisabled,
            disabledReason: registrationDisableDetails.disabledReason,
            createdon: registrationDisableDetails.createdon,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  viewCancel() {
    this.props.navigate("/registrationList");
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          name={this.title}
          backCallback={this.viewCancel.bind(this)}
          // editCallback={this.editAppUserDetails.bind(this)}
          // deleteCallback={this.deleteAppUserDetails.bind(this)}
          // getDeletePermission={this.state.getDeletePermission}
          // getUpdatePermission={this.state.getUpdatePermission}
        />
        <div className="row" style={{ padding: "1em", marginTop: "-12px" }}>
          <div className="card" id="organizationFormCard">
            <div className="row">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="row pt-1">
                      <label
                        className="col-sm-4 col-form-label fw-bold"
                        htmlFor="basic-default-name"
                        id="assetFormLabel"
                      >
                        Company Name
                      </label>
                      <div className="col-sm-8" id="OrganizationCol">
                        <span>{this.state.companyName}</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="row pt-1">
                      <label
                        className="col-sm-4 col-form-label fw-bold"
                        htmlFor="basic-default-name"
                        id="assetFormLabel"
                      >
                        Email
                      </label>
                      <div className="col-sm-8" id="OrganizationCol">
                        <span>{this.state.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row pt-1">
                      <label
                        className="col-sm-4 col-form-label fw-bold"
                        htmlFor="basic-default-name"
                        id="assetFormLabel"
                      >
                        GSTN No
                      </label>
                      <div className="col-sm-8" id="OrganizationCol">
                        <span>{this.state.gstn}</span>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="row pt-2" style={{ alignItems: "center" }}>
                      <label
                        className="col-sm-4 form-label fw-bold"
                        htmlFor="basic-default-name"
                        id="assetFormLabel"
                      >
                        Is Disabled
                      </label>
                      <div className="col-sm-8" id="OrganizationCol">
                        <Tooltip title="Please click 'Click’ to change Status">
                          <span>
                            {this.state.isdisabled ? (
                              <ToggleButton
                                // value={this.state.isStatus}
                                // className="ToggleButtonInActive"
                                onChange={this.handleEnableStatusChange.bind(
                                  this
                                )}
                                style={{
                                  backgroundColor: "#c7c7c7",
                                  color: "#000",
                                  textTransform: "none",
                                  height: "2rem",
                                  width: "4.5rem",
                                  margin: "0.5rem 0rem 0.5rem 0rem",
                                  borderRadius: "5px",
                                }}
                              >
                                Disabled
                              </ToggleButton>
                            ) : (
                              <ToggleButton
                                // value={this.state.isStatus}
                                // className="ToggleButtonActive"
                                onChange={this.handleDisabledStatusChange.bind(
                                  this
                                )}
                                style={{
                                  backgroundColor: "#639a63",
                                  color: "#fff",
                                  textTransform: "none",
                                  height: "2rem",
                                  width: "4.5rem",
                                  margin: "0.5rem 0rem 0.5rem 0rem",
                                  borderRadius: "5px",
                                }}
                              >
                                Enabled
                              </ToggleButton>
                            )}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row pt-1">
                      <label
                        className="col-sm-4 col-form-label fw-bold"
                        htmlFor="basic-default-name"
                        id="assetFormLabel"
                      >
                        Created
                      </label>
                      <div className="col-sm-8" id="OrganizationCol">
                        <span>{Utils.getUtcToLocal(this.state.createdon)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    {!this.state.isdisabled ? (
                      <div className="row-md-12 pt-2 text-start">
                        <label
                          className="col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Is Disabled Reason
                        </label>
                        <div className="col-md-12" id="OrganizationCol">
                          <TextareaAutosize
                            variant="outlined"
                            fullWidth
                            id="Reason"
                            style={{
                              width: "100%",
                              padding: "10px",
                              border: "0.5px solid #D5D8DC",
                            }}
                            minRows={4}
                            // label="Description"
                            value={this.state.disabledReason}
                            onChange={this.handleDisableReasonChange.bind(this)}
                            autoComplete="description"
                            placeholder="Disabled Reason"
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="row pt-1">
                          <label
                            className="col-sm-2 col-form-label fw-bold"
                            htmlFor="basic-default-name"
                            id="assetFormLabel"
                          >
                            Is Disabled Reason
                          </label>
                          <div className="col-sm-10" id="OrganizationCol">
                            <span>{this.state.disabledReason}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                background: "#717373",
                color: "#fff",
                marginRight: "1rem",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={(e) => this.viewCancel()}
            >
              Back
            </Button>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Registration);
