import { GET_CATEGORY, SET_CATEGORY, CLEAR_CATEGORY } from "./CategoryType";

const initialState = { category: {} };
const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY:
      return {
        ...state,
      };
    case SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };

    case CLEAR_CATEGORY:
      return {
        ...state,
        category: null,
      };
    default:
      return state;
  }
};

export default CategoryReducer;
