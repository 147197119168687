import { Row, Col } from "reactstrap";
import React, { Component } from "react";
import UiUtils from "../../provider/UiUtils";
import Quill from "../quill/Quill";
import FetchServerData from '../../provider/FetchServerData';
export class TermsConditionContent extends Component {
	constructor(props) {
		super(props);
		this.tableIcons = UiUtils.getMaterialTableIcons();

		this.state = { templateDetails: "", render: false };
		this.getTemplateDetails();
	}
	getTemplateDetails() {
		let postObject = {};
		FetchServerData.callPostService("/apTermsConditions/geActive", postObject)
			.then((response) => {
				let output = response;
				if (output.status === "SUCCESS") {
					let templateDetails = output.data.termsConditions.data;
					this.setState({ templateDetails: templateDetails, render: true });
				}
			})
			.catch((error) => { });
	}
	render() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<div
					id="top"
					data-wow-duration="1s"
					data-wow-delay="0.5s"
				>
					<div
						className="container"
						style={{ border: "1px solid lightgray", padding: "2em" }}
					>
						<Row>
							<Col md={12}>
								<Quill
									text={this.state.templateDetails}
									className="templateMessageBox"
									theme="bubble"
									readOnly={true}
								/>
							</Col>
						</Row>
					</div>
				</div>
			);
		}
	}
}
export default TermsConditionContent;
