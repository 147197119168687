import Tooltip from "@material-ui/core/Tooltip";
import RepeatIcon from "@material-ui/icons/Repeat";
import React, { Component } from "react";

export class RescheduleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <Tooltip title="Reschedule">
        <RepeatIcon
          onClick={this.onClick.bind(this)}
          style={{
            borderRadius: "25%",
            color: "#6A7B8E",
            padding: "3px",
            fontSize: "x-large",
            width: "30px",
            minHeight: "30px",
          }}
        />
      </Tooltip>
    );
  }
}
export default RescheduleButton;
