import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import React, { Component } from "react";

export class EditIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
      hideToolTip: props.hideToolTip,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        className="editDetails"
        onClick={this.onClick.bind(this)}
        style={{
          cursor: "pointer",
          fontSize: "x-larger",
          float: "right",
          marginRight: "3px",
          paddingTop: "5px",
          display: "flex",
        }}
      >
        {this.state.hideToolTip ?
          <EditIcon
            style={{
              borderRadius: "25%",
              color: "#6A7B8E",
              fontSize: "x-large",
              width: "30px",
              minHeight: "30px",
              padding: "3px",
            }}
          />
          :
          <Tooltip title="Edit">
            <EditIcon
              style={{
                borderRadius: "25%",
                color: "#6A7B8E",
                fontSize: "x-large",
                width: "30px",
                minHeight: "30px",
                padding: "3px",
              }}
            />
          </Tooltip>
        }
        <h6 style={{ margin: "auto 0px" }}> Edit</h6>
      </span>
    );
  }
}
export default EditIconButton;
