import { GET_MENU, SET_MENU,SET_ACTIVE_MENU, } from "./MenuTypes";

const initialState = {
  menuList: {},
  activeMenu: {},
};

const MenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MENU:
      return {
        ...state,
      };
    case SET_MENU:
      return {
        ...state,
        menuList: action.payload,
      };
    case SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.payload,
      };

    default:
      return state;
  }
};

export default MenuReducer;
