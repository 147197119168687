import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "../../withRouter";
import AddressWithTimezone from "../addressWithTimezone/AddressWithTimezone";
import Utils from "../../provider/Utils";
import FetchServerData from "../../provider/FetchServerData";
import PtsAlert from "../ptsAlert/PtsAlert";
import store from "../../redux/store";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleButton from "@mui/material/ToggleButton";
import BackButton from "../buttons/BackButton";
import SaveButton from "../buttons/SaveButton";
import CancelIconButton from "../buttons/CancelIconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ValidatorForm } from "react-material-ui-form-validator";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import { IconButton, InputAdornment } from "@mui/material";
import { Autocomplete } from "@mui/material";
import infoIcon from "../../../src/assets/images/infoIcon.png";
import "./UserManagement.css";
import CreateButton from "../buttons/CreateButton";

class UserManagement extends Component {
  addressRef = React.createRef();
  title = "User Details";
  header = store.getState().header.header;
  userProfileList = store.getState().userRole.userRoleList;
  breadCrumb = [
    { pageid: "/userList", uiname: "User List" },
    { pageid: "", uiname: "User Details" },
  ];
  constructor(props) {
    super(props);
    let action = props.action;
    let userId = props.userId;
    if (!action && props.state) {
      action = props.state.action;
      userId = props.state.userId;
    }
    if (
      action &&
      Utils.equalsIgnoreCase(
        action,
        "view" || Utils.equalsIgnoreCase(action, "update")
      )
    ) {
      this.getUserDetails(userId);
    }
    this.state = {
      action: action,
      userId: userId,
      address: {},
      firstName: "",
      lastName: "",
      phone: "",
      panno: "",
      email: "",
      designation: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      userProfile: null,
      enablemobileaccess: false,
      isStatus: true,
      render: false,
      getUpdatePermission: false,
      getDeletePermission: false,
    };
  }
  getUserDetails(userId) {
    const postObject = {
      header: this.header,
      appuser: {
        id: userId,
      },
    };
    FetchServerData.callPostService("/appuserms/get", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let userDetails = output.data.appuser;
          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let userStatus = "";
          if (userStatus === null) {
            userStatus = false;
          } else {
            if (userDetails.userStatus.status === "Active") {
              userStatus = true;
            } else {
              userStatus = false;
            }
          }
          this.setState({
            render: true,
            action: "view",
            id: userDetails.id,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
            phone: userDetails.phone,
            panno: userDetails.panno,
            adhar: userDetails.adhar,
            designation: userDetails.designation,
            enablemobileaccess: userDetails.enablemobileaccess,
            address: userDetails.address,
            userProfile: userDetails.profile,
            isStatus: userStatus,
            userStatus: userDetails.userStatus.status,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  handlePasswordConfirmChange(e) {
    this.setState({ confirmPassword: e.target.value });
  }
  handleFirstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }
  handleLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }
  handlePhoneChange(e) {
    let res = e.target.value;
    if (res.length <= 10) {
      this.setState({ phone: res });
    }
  }
  handlePanNoChange(e) {
    this.setState({ panno: e.target.value });
  }
  handleAdharNoChange(e) {
    let res = e.target.value;
    if (res.length <= 12) {
      this.setState({ adhar: res });
    } else {
      Utils.maxFieldLength(12);
    }
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handleDesignationChange(e) {
    this.setState({ designation: e.target.value });
  }
  handleUsernameChange(e) {
    this.setState({ username: e.target.value });
  }
  handleUserProfileChange(selectedValue) {
    this.setState({ userProfile: selectedValue });
  }
  handleEnableMobileAccessChange(e) {
    this.setState({ enablemobileaccess: !this.state.enablemobileaccess });
  }
  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleClickShowConfirmPassword() {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }

  createUserToBackend() {
    if (this.state.panno.length > 0) {
      if (!Utils.checkPanLength(this.state.panno)) {
        return;
      }
    }
    if (this.state.password !== this.state.confirmPassword) {
      return;
    }
    const postObject = {
      header: this.header,
      appuser: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        panno: this.state.panno,
        adhar: this.state.adhar,
        designation: this.state.designation,
        credential: this.state.password,
        tenant: this.header.tid,
        enablemobileaccess: this.state.enablemobileaccess,
        address: {
          line: this.addressRef.current.state.line,
          state: {
            name: this.addressRef.current.state.state.name,
            code: this.addressRef.current.state.state.code,
            id: this.addressRef.current.state.state.id,
            country: this.addressRef.current.state.country,
          },
          city: this.addressRef.current.state.city,
          zipcode: this.addressRef.current.state.zipCode,
        },
        profile: {
          name: this.state.userProfile.name,
          id: this.state.userProfile.id,
        },
      },
    };
    FetchServerData.callPostService("/appuserms/create", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let userData = output.data.appuser;
          this.getUserDetails(userData.id);
          this.setState({
            action: "view",
          });
        } else {
        }
      }
    );
  }
  createCancel() {
    this.props.navigate("/userList");
  }
  viewCancel() {
    this.props.navigate("/userList");
  }
  editCancel() {
    this.getUserDetails(this.state.id);
    // this.setState({
    //   action: "view",
    // })
  }
  handleAddressChange(address) {
    this.setState({ address: address });
  }

  editUserDetails(e) {
    this.setState({ action: "edit", userId: this.state.id });
  }

  updateUserToBackend() {
    let userStatus;
    if (this.state.isStatus === true) {
      userStatus = "Active";
    } else {
      userStatus = "InActive";
    }
    if (this.state.panno.length > 0) {
      if (!Utils.checkPanLength(this.state.panno)) {
        return;
      }
    }
    const postObject = {
      header: this.header,
      appuser: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        panno: this.state.panno,
        adhar: this.state.adhar,
        designation: this.state.designation,
        tenant: this.header.tid,
        enablemobileaccess: this.state.enablemobileaccess,
        id: this.state.userId,
        address: {
          id: this.addressRef.current.state.id,
          line: this.addressRef.current.state.line,
          state: {
            name: this.addressRef.current.state.state.name,
            code: this.addressRef.current.state.state.code,
            id: this.addressRef.current.state.state.id,
            country: this.addressRef.current.state.country,
          },
          city: this.addressRef.current.state.city,
          zipcode: this.addressRef.current.state.zipCode,
        },
        userStatus: {
          status: userStatus,
        },
        profile: {
          name: this.state.userProfile.name,
          id: this.state.userProfile.id,
        },
      },
    };
    FetchServerData.callPostService("/appuserms/update", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let userData = output.data.appuser;
          this.getUserDetails(userData.id);
          this.setState({
            action: "view",
          });
        } else {
        }
      }
    );
  }

  deleteUserToBackend() {
    const postObject = {
      header: this.header,
      appuser: {
        id: this.state.userId,
      },
    };

    FetchServerData.callPostService("/appuserms/delete", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.props.navigate("/userList");
        } else {
        }
      })
      .catch((error) => {});
  }

  render() {
    if (this.state.action === "create") {
      return this.createUser();
    } else if (this.state.action === "edit") {
      return this.editUser();
    } else if (this.state.action === "view") {
      return this.viewUser();
    }
  }

  createUser() {
    const error = this.state.password !== this.state.confirmPassword;
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="User"
          name="Create User"
        />
        <div className="row">
          <ValidatorForm
            className="text-start"
            ref="form"
            onSubmit={this.createUserToBackend.bind(this)}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="card " id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      User Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-firstname"
                        >
                          First Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-firstName"
                          variant="outlined"
                          placeholder="First Name"
                          value={this.state.firstName}
                          onChange={this.handleFirstNameChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-lastname"
                        >
                          Last Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-lastName"
                          variant="outlined"
                          placeholder="Last Name"
                          value={this.state.lastName}
                          onChange={this.handleLastNameChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-email"
                        >
                          Email<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="email"
                          className="textField"
                          fullWidth
                          id="basic-default-email"
                          variant="outlined"
                          placeholder="Email"
                          aria-label="Email"
                          aria-describedby="basic-icon-default-email2"
                          value={this.state.email}
                          onChange={this.handleEmailChange.bind(this)}
                          validators={["required", "isEmail"]}
                          errormessages={[
                            "This field is required",
                            "Email id is not valid",
                          ]}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-panno"
                        >
                          Pan No<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-panno"
                          variant="outlined"
                          placeholder="PAN NO"
                          value={this.state.panno}
                          onChange={this.handlePanNoChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-adharno"
                        >
                          Adhar No
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-panno"
                          variant="outlined"
                          placeholder="Adhar No"
                          value={this.state.adhar}
                          onChange={this.handleAdharNoChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-phone"
                        >
                          Phone
                        </label>
                        <TextField
                          type="number"
                          className="textField"
                          fullWidth
                          id="basic-default-phone"
                          variant="outlined"
                          placeholder="Phone"
                          aria-label="Phone"
                          value={this.state.phone}
                          onChange={this.handlePhoneChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-panno"
                        >
                          Designation
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-designation"
                          variant="outlined"
                          placeholder="Designation"
                          value={this.state.designation}
                          onChange={this.handleDesignationChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Address
                    </h5>
                  </div>
                  <div className="card-body">
                    <AddressWithTimezone
                      ref={this.addressRef}
                      onChange={this.handleAddressChange.bind(this)}
                      data={this.state.address}
                      action="create"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Account Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-firstname"
                        >
                          User ID<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="email"
                          className="textField"
                          fullWidth
                          id="basic-default-email"
                          variant="outlined"
                          placeholder="User ID"
                          value={this.state.email}
                          onChange={this.handleUsernameChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-userProfile"
                        >
                          Profile<span className="asterisk"> *</span>
                          <Tooltip
                            placement="top"
                            title="Profile are created by Admin Users in Settings"
                            id="password-requirements-tooltip"
                          >
                            <img src={infoIcon} alt="" width="20" height="20" />
                          </Tooltip>
                        </label>
                        <Autocomplete
                          size="small"
                          fullWidth
                          id="userRoleList"
                          options={this.userProfileList}
                          value={this.state.userProfile}
                          defaultValue={this.state.userProfile}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleUserProfileChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Profile"
                              variant="outlined"
                              validators={["required"]}
                              errormessages={["This field is required"]}
                              required
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-email"
                        >
                          Enable Mobile Access
                        </label>
                        <br></br>
                        <Tooltip title="Please click 'Click’ to change Enable Mobile Access">
                          <span>
                            {this.state.enablemobileaccess ? (
                              <ToggleButton
                                value="Yes"
                                className="ToggleButtonActive"
                                onChange={this.handleEnableMobileAccessChange.bind(
                                  this
                                )}
                                style={{
                                  background: "#0B6F9D",
                                  color: "#ffff",
                                  height: "33px",
                                  width: "80px",
                                  borderRadius: "5px",
                                  textTransform: "none",
                                }}
                              >
                                YES
                              </ToggleButton>
                            ) : (
                              <ToggleButton
                                value="No"
                                className="ToggleButtonActive"
                                onChange={this.handleEnableMobileAccessChange.bind(
                                  this
                                )}
                                style={{
                                  background: "#D7DBDD",
                                  color: "#000",
                                  height: "33px",
                                  width: "80px",
                                  borderRadius: "5px",
                                  textTransform: "none",
                                }}
                              >
                                NO
                              </ToggleButton>
                            )}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-firstname"
                        >
                          Password<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type={this.state.showPassword ? "text" : "password"}
                          className="textField"
                          fullWidth
                          required
                          id="basic-default-password"
                          variant="outlined"
                          placeholder="Password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handlePasswordChange.bind(this)}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword.bind(
                                    this
                                  )}
                                >
                                  {this.state.showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-lastname"
                        >
                          Confirm Password<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          className="textField"
                          fullWidth
                          required
                          id="confirmPassword"
                          variant="outlined"
                          placeholder="Confirm Password;"
                          name="confirmPassword"
                          type={
                            this.state.showConfirmPassword ? "text" : "password"
                          }
                          value={this.state.confirmPassword}
                          onChange={this.handlePasswordConfirmChange.bind(this)}
                          helperText={
                            error
                              ? "Password & confirm password must match"
                              : ""
                          }
                          error={error}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowConfirmPassword.bind(
                                    this
                                  )}
                                >
                                  {this.state.showConfirmPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-sm-12 mt-3 text-center">
                <CreateButton btnName="Create" type="submit" />
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.createCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </>
    );
  }

  viewUser() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject={this.state.firstName + " " + this.state.lastName}
            name="User Details"
            title="User"
            editCallback={this.editUserDetails.bind(this)}
            deleteCallback={this.deleteUserToBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
          />
          <div className="row" style={{ padding: "1em", marginTop: "-12px" }}>
            <div className="card" id="organizationFormCard">
              <div className="row">
                <div
                  className="card-header d-flex align-items-center justify-content-between"
                  id="assetCardHeader"
                >
                  <h5 className="mb-0" id="headerLabel">
                    {this.state.firstName + " " + this.state.lastName}
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row pt-1">
                        <label
                          className="col-sm-3 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Name
                        </label>
                        <div className="col-sm-9" id="OrganizationCol">
                          <span>
                            {this.state.firstName + " " + this.state.lastName}
                          </span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-3 col-form-label fw-bold"
                          htmlFor="basic-default-email"
                          id="assetFormLabel"
                        >
                          Email
                        </label>
                        <div className="col-sm-9" id="OrganizationCol">
                          <span>{this.state.email}</span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-3 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Phone
                        </label>
                        <div className="col-sm-9" id="OrganizationCol">
                          <span>{this.state.phone}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Adhar No
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>{this.state.adhar}</span>
                            </div>
                          </div>
                          <div className="row pt-2">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Profile
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              {this.state.userProfile && (
                                <span>{this.state.userProfile.name}</span>
                              )}
                            </div>
                          </div>
                          <div className="row pt-2">
                            <label
                              className="col-sm-4 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Designation
                            </label>
                            <div className="col-sm-8" id="OrganizationCol">
                              <span>{this.state.designation}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Pan No
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.panno}</span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Status
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>{this.state.userStatus}</span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <label
                          className="col-sm-4 col-form-label fw-bold"
                          htmlFor="basic-default-name"
                          id="assetFormLabel"
                        >
                          Enable Mobile Access
                        </label>
                        <div className="col-sm-8" id="OrganizationCol">
                          <span>
                            {this.state.enablemobileaccess === true
                              ? "YES"
                              : "NO"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div
                  className="card-header d-flex align-items-center justify-content-between"
                  id="assetCardHeader"
                >
                  <h5 className="mb-0" id="headerLabel">
                    Address
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-sm-12" id="OrganizationCol">
                          <span>
                            {Utils.getAddressAsText(this.state.address)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <BackButton
                type="button"
                style={{ backgroundColor: "#162c50", color: "#fff" }}
                variant="contained"
                onClick={(e) => this.viewCancel()}
              >
                Back
              </BackButton>
            </div>
          </div>
        </>
      );
    }
  }
  editUser() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="User"
          name="Edit User"
        />
        <div className="row">
          <ValidatorForm
            className="text-start"
            ref="form"
            onSubmit={this.updateUserToBackend.bind(this)}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="card " id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      User Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-firstname"
                        >
                          First Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-firstName"
                          variant="outlined"
                          placeholder="First Name"
                          value={this.state.firstName}
                          onChange={this.handleFirstNameChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-lastname"
                        >
                          Last Name<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-lastName"
                          variant="outlined"
                          placeholder="Last Name"
                          value={this.state.lastName}
                          onChange={this.handleLastNameChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-email"
                        >
                          Email<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="email"
                          className="textField"
                          fullWidth
                          id="basic-default-email"
                          variant="outlined"
                          placeholder="Email"
                          aria-label="Email"
                          aria-describedby="basic-icon-default-email2"
                          value={this.state.email}
                          onChange={this.handleEmailChange.bind(this)}
                          validators={["required", "isEmail"]}
                          errormessages={[
                            "This field is required",
                            "Email id is not valid",
                          ]}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-panno"
                        >
                          Pan No<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-panno"
                          variant="outlined"
                          placeholder="PAN NO"
                          value={this.state.panno}
                          onChange={this.handlePanNoChange.bind(this)}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-adharno"
                        >
                          Adhar No
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-panno"
                          variant="outlined"
                          placeholder="Adhar No"
                          value={this.state.adhar}
                          onChange={this.handleAdharNoChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-phone"
                        >
                          Phone
                        </label>
                        <TextField
                          type="number"
                          className="textField"
                          fullWidth
                          id="basic-default-phone"
                          variant="outlined"
                          placeholder="Phone"
                          aria-label="Phone"
                          value={this.state.phone}
                          onChange={this.handlePhoneChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-panno"
                        >
                          Designation
                        </label>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-designation"
                          variant="outlined"
                          placeholder="Designation"
                          value={this.state.designation}
                          onChange={this.handleDesignationChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Address
                    </h5>
                  </div>
                  <div className="card-body">
                    <AddressWithTimezone
                      ref={this.addressRef}
                      onChange={this.handleAddressChange.bind(this)}
                      data={this.state.address}
                      action="edit"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Account Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-firstname"
                        >
                          User ID<span className="asterisk"> *</span>
                        </label>
                        <TextField
                          type="email"
                          className="textField"
                          fullWidth
                          id="basic-default-panno"
                          variant="outlined"
                          placeholder="User ID"
                          value={this.state.email}
                          onChange={this.handleUsernameChange.bind(this)}
                          validators={["required"]}
                          errormessages={["This field is required"]}
                          required
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-userRole"
                        >
                          Profile<span className="asterisk"> *</span>
                          <Tooltip
                            placement="top"
                            title="Profile are created by Admin Users in Settings"
                            id="password-requirements-tooltip"
                          >
                            <img src={infoIcon} alt="" width="20" height="20" />
                          </Tooltip>
                        </label>
                        <Autocomplete
                          size="small"
                          fullWidth
                          id="userRoleList"
                          options={this.userProfileList}
                          value={this.state.userProfile}
                          defaultValue={this.state.userProfile}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleUserProfileChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Profile"
                              variant="outlined"
                              validators={["required"]}
                              errormessages={["This field is required"]}
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-email"
                        >
                          Enable Mobile Access
                        </label>
                        <Tooltip title="Please click 'Click’ to change Enable Mobile Access">
                          <span className="ms-4">
                            {this.state.enablemobileaccess ? (
                              <ToggleButton
                                value="Yes"
                                className="ToggleButtonActive"
                                onChange={this.handleEnableMobileAccessChange.bind(
                                  this
                                )}
                                style={{
                                  background: "#0B6F9D",
                                  color: "#ffff",
                                  height: "33px",
                                  width: "80px",
                                  borderRadius: "5px",
                                  textTransform: "none",
                                }}
                              >
                                YES
                              </ToggleButton>
                            ) : (
                              <ToggleButton
                                value="No"
                                className="ToggleButtonActive"
                                onChange={this.handleEnableMobileAccessChange.bind(
                                  this
                                )}
                                style={{
                                  background: "#D7DBDD",
                                  color: "#000",
                                  height: "33px",
                                  width: "80px",
                                  borderRadius: "5px",
                                  textTransform: "none",
                                }}
                              >
                                NO
                              </ToggleButton>
                            )}
                          </span>
                        </Tooltip>
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-email"
                        >
                          Status
                        </label>
                        <Tooltip title="Please click 'Click’ to change Status">
                          <span className="ms-4">
                            <ToggleButton
                              value={
                                this.state.isStatus ? "Active" : "InActive"
                              }
                              className="ToggleButtonActive"
                              onChange={this.handleStatusChange.bind(this)}
                              style={
                                this.state.isStatus
                                  ? {
                                      background: "#0B6F9D",
                                      color: "#fff",
                                      height: "33px",
                                      width: "80px",
                                      borderRadius: "5px",
                                      textTransform: "none",
                                    }
                                  : {
                                      background: "#D7DBDD",
                                      color: "#000",
                                      height: "33px",
                                      width: "80px",
                                      borderRadius: "5px",
                                      textTransform: "none",
                                    }
                              }
                            >
                              {this.state.isStatus ? "Active" : "InActive"}
                            </ToggleButton>
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-sm-12 mt-3 text-center">
                <SaveButton id="saveButton" type="submit">
                  {" "}
                  Save{" "}
                </SaveButton>
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.editCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </>
    );
  }
}
export default withRouter(UserManagement);
