import { SET_FCMTOKEN, USER_LOGOUT } from './AppUserTypes';

const setFCMToken = (data) => {
  return {
    type: SET_FCMTOKEN,
    payload: data,
  };
};
const logout = (data) => {
  return {
    type: USER_LOGOUT,
    payload: data,
  };
};
const AppUserAction = { setFCMToken, logout };
export default AppUserAction;
