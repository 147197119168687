import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import { Card, CardBody } from "reactstrap";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import { Menu } from "@mui/material";
import FetchServerData from "../../provider/FetchServerData";
import store from "../../redux/store";
import "./AssetTrackingHistoryList.css";
import Pagination from "./Pagination";
import Utils from "../../provider/Utils";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DatePicker from "react-datepicker";
import MenuAction from "../../redux/menu/MenuAction";

class AssetTrackingHistoryList extends Component {
  header = store.getState().header.header;
  breadCrumb = [{ pageid: "", uiname: "Asset Tracking History List" }];
  constructor(props) {
    super(props);
    let assetId = "";
    let userMenuArray = Utils.getTopMenus();
    if (props.state) {
      assetId = props.state.assetId;

      for (let i = 0; i < userMenuArray.length; i++) {
        for (
          let j = 0;
          props.state.pageid && j < props.state.pageid?.length;
          j++
        ) {
          if (userMenuArray[i].pageid === props.state.pageid) {
            store.dispatch(MenuAction.setActiveMenu(userMenuArray[i].id));
          }
        }
      }
    }
    this.state = {
      assetId: assetId,
      render: false,
      search: "",
      startDate: "",
      endDate: "",
      pageNo: 0,
      showPerPage: 0,
      pageSize: 10,
      trackingHistoryList: [],
      selectedTrackingId: false,
      open: false,
      isFirstTimeLoading: true,
    };
    this.getTrakingHistoryList();
  }
  /*Real time messaging thread functionality */
  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      let receiveTrackingHistoryList = this.state.trackingHistoryList;
      let newTrackingData = store.getState().trackingHistory.history;
      for (let i = 0; i < newTrackingData.length; i++) {
        newTrackingData[i].selectedTrack = true;
        receiveTrackingHistoryList.unshift(newTrackingData[i]);
      }

      this.setState({
        selectedTrackingId: true,
        trackingHistoryList: receiveTrackingHistoryList,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onFilterClick() {
    if (
      this.state.startDate !== null &&
      this.state.endDate !== null &&
      this.state.startDate > this.state.endDate
    ) {
      this.setState({ error: true });
      return false;
    }
    this.setState({
      isFirstTimeLoading: false,
      open: false,
    });
    this.getTrakingHistoryList(this.state.pageNo, this.state.pageSize);
  }

  resetFilter() {
    this.getTrakingHistoryList(this.state.pageNo, this.state.pageSize, true);
  }

  onSearchClick() {
    this.setState({
      isFirstTimeLoading: false,
    });
    this.getTrakingHistoryList(this.state.pageNo, this.state.pageSize);
  }

  handleCycleFromDateChange(date) {
    this.setState({ startDate: date });
  }

  handleCycleToDateChange(date) {
    this.setState({ endDate: date });
  }

  handleAccountDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  handleAccountDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  getTrakingHistoryList(pageNo, pageSize, reset) {
    const postObject = {
      header: this.header,
      startDate: reset ? "" : this.state.startDate,
      endDate: reset ? "" : this.state.endDate,
      page: {
        searchText: this.state.search,
        pageNo: pageNo,
        pageSize: pageSize,
      },
    };
    FetchServerData.callPostService(
      "/trackingHistory/getLatestHistory",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let trackingHistoryData = output.data;
        for (let i = 0; i < trackingHistoryData.history.length; i++) {
          trackingHistoryData.history[i].selectedTrack = false;
        }
        this.setState({
          render: true,
          open: false,
          error: false,
          startDate: reset ? "" : this.state.startDate,
          endDate: reset ? "" : this.state.endDate,
          isFirstTimeLoading:
            reset || this.state.isFirstTimeLoading ? true : false,
          selectedTrackingId: false,
          trackingHistoryList: trackingHistoryData.history,
          pageNo: trackingHistoryData.page.pageNo,
          showPerPage: trackingHistoryData.page.pageSize,
          totalPages: trackingHistoryData.page.totalPages,
          totalRecords: trackingHistoryData.page.total,
        });
      } else {
      }
    });
  }
  onPaginationChange(returnProp) {
    let counter = returnProp.counter;
    let pageNo = this.state.pageNo + counter;
    let pageSize = returnProp.showPerPage;
    let buttonIndex = returnProp.buttonIndex;
    let currentPageIndex =
      typeof counter !== "undefined" ? pageNo : buttonIndex;
    let resetPageNo = returnProp.resetPageNo;
    if (resetPageNo === true) {
      currentPageIndex = 0;
    }
    this.setState({
      pageNo: currentPageIndex,
    });
    this.getTrakingHistoryList(currentPageIndex, pageSize);
  }
  onSearchChange(e) {
    this.setState({ search: e.target.value });
  }
  toAssetTrakingDetails(assetId) {
    this.props.navigate("/asset", {
      state: {
        // categoryId: this.state.categoryId,
        action: "view",
        assetId: assetId,
        replace: true,
        pageId: "assetTrackHistoryList",
      },
    });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject="Asset"
            name="Asset Tracking History"
          />{" "}
          {this.state.isFirstTimeLoading &&
          this.state.trackingHistoryList.length === 0 ? (
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-12" style={{ padding: "2em" }}>
                        <h5>
                          <b>Traking history not created yet</b>
                        </h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col">
                <div className="row align-items-end text-end">
                  <div className="col">
                    <span>
                      {this.state.startDate !== "" ||
                      this.state.endDate !== "" ? (
                        <span>
                          ({" "}
                          {this.state.startDate &&
                          this.state.startDate !== "" ? (
                            <span>
                              <b>Form : </b>
                              {Utils.getUtcToLocal(this.state.startDate) + ", "}
                            </span>
                          ) : (
                            ""
                          )}
                          &nbsp;
                          {this.state.endDate && this.state.endDate !== "" ? (
                            <span>
                              <b>To : </b>
                              {Utils.getUtcToLocal(this.state.endDate) + ", "}
                            </span>
                          ) : (
                            ""
                          )}{" "}
                          )
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    &nbsp;&nbsp;
                    <span
                      className="text-end"
                      style={{
                        fontSize: "17px",
                        fontWeight: "500",
                        color: "#566a7f",
                        cursor: "pointer",
                      }}
                      onClick={this.handleAccountDropdownOpen}
                    >
                      <FilterAltIcon
                        style={{
                          fontSize: "28px",
                          color: "rgb(106, 123, 142)",
                        }}
                        aria-controls={
                          this.state.open ? "dropdown-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={this.state.open ? "true" : undefined}
                      />
                      Filter
                    </span>
                    {"  "} &nbsp;&nbsp;
                    {this.state.isFirstTimeLoading ? (
                      ""
                    ) : (
                      <span
                        style={{
                          fontSize: "17px",
                          fontWeight: "500",
                          color: "#566a7f",
                          cursor: "pointer",
                        }}
                        onClick={this.resetFilter.bind(this)}
                      >
                        <i class="fa fa-refresh" aria-hidden="true"></i> Reset
                      </span>
                    )}
                  </div>
                </div>
                <Menu
                  anchorEl={this.state.anchorEl}
                  id="dropdown-menu"
                  className="filterDropdownMenu"
                  open={this.state.open}
                  onClose={this.handleAccountDropdownClose}
                  // onClick={this.handleAccountDropdownClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 20,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <div
                    className="dropdown-item"
                    style={{
                      cursor: "pointer",
                      width: "400px",
                      height: "100%",
                    }}
                  >
                    <div className="row">
                      <h5>Filter</h5>
                      <div className="col-md-12 mt-1  d-flex justify-content-between">
                        <div className="col-md-6 mt-1">
                          <label
                            className="form-label "
                            htmlFor="collapsible-supplierType"
                          >
                            From Date
                          </label>
                          <br></br>
                          <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleCycleFromDateChange.bind(this)}
                            dateFormat="d MMM, yyyy"
                            placeholderText="From Date"
                            required
                            className="startDateTimeInput"
                          />
                        </div>
                        &nbsp;
                        <div className="col-md-6 mt-1">
                          <label
                            className="form-label "
                            htmlFor="collapsible-supplierType"
                          >
                            To Date
                          </label>
                          <br></br>
                          <DatePicker
                            selected={this.state.endDate}
                            onChange={this.handleCycleToDateChange.bind(this)}
                            dateFormat="d MMM, yyyy"
                            placeholderText="To Date"
                            required
                            className="endDateTimeInput"
                          />
                        </div>
                      </div>
                      {this.state.error ? (
                        <lable className="danger" style={{ color: "red" }}>
                          To Date should be after From Date
                        </lable>
                      ) : (
                        ""
                      )}
                      <div className="col-md-12 mt-4">
                        <button
                          className="fileterButton"
                          onClick={this.onFilterClick.bind(this)}
                        >
                          <i className="fa-solid fa-filter"></i>
                          &nbsp; Filter
                        </button>
                        &nbsp; &nbsp;
                        <button
                          className="resetButton"
                          onClick={this.resetFilter.bind(this)}
                        >
                          <i className="fa fa-refresh"></i>
                          &nbsp; Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </Menu>
                <div className="col md-12 mt-2">
                  <div className="card">
                    <div className="row justify-content-between p-4">
                      <div className="d-flex col-12 col-lg-4 col-xl-4">
                        <div className="col-12 text-start totalResults">
                          <span>Total Results: {this.state.totalRecords}</span>
                        </div>
                      </div>
                      <div className="d-flex col-12 col-lg-8 col-xl-8">
                        <div className="col-12 text-end">
                          <input
                            type="search"
                            id="gsearch"
                            placeholder="Search"
                            name="gsearch"
                            value={this.state.search}
                            onChange={this.onSearchChange.bind(this)}
                          />
                          <button
                            className="gsearchButton"
                            onClick={this.onSearchClick.bind(this)}
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table text-start table-hover">
                        <thead className="assetTrakingThead">
                          <tr>
                            <th scope="col fw-bold">#</th>
                            <th scope="col fw-bold">Plan Name</th>
                            <th scope="col fw-bold">Asset Name</th>
                            <th scope="col fw-bold">Asset UID</th>
                            <th scope="col fw-bold">Asset Code</th>
                            <th scope="col fw-bold">Action</th>
                            <th scope="col fw-bold">First Track Date</th>
                            <th scope="col fw-bold">Latest Track Date</th>
                            <th scope="col fw-bold">Location </th>
                            <th scope="col fw-bold">Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.trackingHistoryList?.map(
                            (rowData, index) => (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor:
                                    rowData.assetid === this.state.assetId
                                      ? "#FEF5E7"
                                      : "#fff",
                                }}
                              >
                                <td>
                                  {this.state.pageNo * this.state.showPerPage +
                                    index +
                                    1}
                                </td>
                                <td style={{ width: "260px" }}>
                                  {rowData.planName}
                                </td>
                                <td style={{ width: "260px" }}>
                                  {rowData.assetname}
                                </td>
                                <td
                                  className="assetUID"
                                  style={{ width: "250px" }}
                                >
                                  <a
                                    // href="#"
                                    onClick={this.toAssetTrakingDetails.bind(
                                      this,
                                      rowData.assetid
                                    )}
                                    style={{ color: "blue", cursor: "pointer" }}
                                  >
                                    {rowData.assetuid}
                                  </a>
                                </td>
                                <td style={{ width: "200px" }}>
                                  {rowData.assetcode}
                                </td>
                                <td style={{ width: "30px" }}>
                                  {rowData.action}
                                </td>
                                <td style={{ width: "230px" }}>
                                  {Utils.getUtcToLocalDateTime(rowData.tdate)}
                                </td>
                                <td style={{ width: "230px" }}>
                                  {Utils.getUtcToLocalDateTime(
                                    rowData.tenddate
                                  )}
                                </td>
                                <td style={{ width: "480px" }}>
                                  {rowData.location ? rowData.location : ""}
                                </td>
                                <td style={{ width: "20px" }}>
                                  {rowData.count}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    {this.state.totalRecords === 0 && (
                      <div className="row text-center p-2">
                        <h5>Data not Available</h5>
                      </div>
                    )}
                    {this.state.totalRecords > this.state.pageSize && (
                      <div className="row justify-content-between pagination p-1">
                        <div className="d-flex col-12 col-lg-4 col-xl-4">
                          <div className="pageSize col-12 text-start">
                            Page {this.state.pageNo + 1} of{" "}
                            {this.state.totalPages}
                          </div>
                        </div>
                        <div className="d-flex col-12 col-lg-8 col-xl-8">
                          <div className="col-12 text-end">
                            <Pagination
                              showPerPage={this.state.showPerPage}
                              onPaginationChange={this.onPaginationChange.bind(
                                this
                              )}
                              total={this.state.totalRecords}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  }
}
export default withRouter(AssetTrackingHistoryList);
