import firebase from "firebase/app";
import "firebase/messaging";
import AppUserAction from "../redux/appUser/AppUserAction";
import TrackingHistoryAction from "../redux/trackingHistory/TrackingHistoryAction";
import store from "../redux/store";
const firebaseConfig = {
  apiKey: "AIzaSyD6hwuNMT-svXHxmsIAhH9zySQt1QKef8U",
  authDomain: "assettrack-e2f8e.firebaseapp.com",
  projectId: "assettrack-e2f8e",
  storageBucket: "assettrack-e2f8e.appspot.com",
  messagingSenderId: "589585575847",
  appId: "1:589585575847:web:5dab9c34100fd104099082",
  measurementId: "G-WVJZL93BSQ",
};
// const apiKey = 'BMD5vtY9_xCOEeOkgJ1goRnoOtL3ymk3Sp6plkqWUex0onIkyv-kKY0kNm4rMNIeYwd-UNAdUopFxWbn2ItV8lg';
firebase.initializeApp(firebaseConfig);
export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    if (firebase) {
      const messaging = firebase.messaging();
      /* Get registration token. Initially this makes a network call, once retrieved
       subsequent calls to getToken will return from cache. */
      messaging
        .getToken({
          vapidKey:
            "BCwzTgbYjTYzHGMeNXvrx4OC-eu16oQ2fX7jMjQBN4aDCubVNlBHWtqfbwS7qEQv_5-UeKAq77vNPCxBRE9YbDs",
        })
        .then((currentToken) => {
          if (currentToken) {
            /*Save Token to Store*/
            store.dispatch(AppUserAction.setFCMToken(currentToken));

            // ...
            if (!Notification) {
              alert(
                "FCC Uses Desktop Notification, It is not enabled in your browser! Please enable it"
              );
              return;
            }
            if (Notification.permission !== "granted") {
              Notification.requestPermission();
            }
            resolve(currentToken);
          } else {
            // Show permission request UI
            reject(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          reject(err);
          // ...
        });
    }
  });

export const onMessageListener = () => {
  if (firebase && firebase.messaging) {
    firebase.messaging().onMessage((payload) => {
      let notificationTitle = payload.notification.title;
      let notificationOptions = {
        body: payload.notification.body,
        icon: "/asset/img/favicon/favicon.ico",
        // image: '/firebase-logo.png',
        click_action: "http://45.79.147.166:9000/home",
      };
      var notification = new Notification(
        notificationTitle,
        notificationOptions
      );

      // console.log("Payload:" + JSON.stringify(payload));
      // console.log("payload.data:" + JSON.parse(JSON.stringify(payload.data.HISTORY)));

      let historyData = payload.data.HISTORY;
      // let newData = historyData.replace('"', "");
      // console.log(newData, "newData");
      // var obj = JSON.parse(historyData);
      // payload.data.HISTORY = newData;
      // console.log("historyData:" + JSON.parse(payload.data.HISTORY));
      //
      // console.log(historyData.replace(/"([^"]+(?="))"/g, "$1"));
      let data = historyData.replace(/"([^"]+(?="))"/g, "$1");
      // console.log("obj:" + obj.lati);
      const replaced = data.replaceAll(`'`, `"`);
      // console.log(replaced);
      // let latitude = historyData.lati;
      // let longitude = historyData.longi;
      // let uid = historyData.assetuid;
      let messageType = payload.data.MessageType;
      // let location = historyData.location;
      // let date = historyData.tdate;
      let messageData = [];
      // let historyDetails = {
      //   latitude: latitude,
      //   longitude: longitude,
      //   uid: uid,
      //   location: location,
      //   date: date,
      // };
      let historyDetails = replaced;
      // console.log("historyDetails:" + historyDetails);

      let obj = JSON.parse(historyDetails);
      messageData.push(obj);

      // console.log("messageData:" + messageData);
      store.dispatch(TrackingHistoryAction.setTrackingHistory(messageData));
      notification.addEventListener("click", function () {
        if (messageType === "Tracking History") {
          window.open("http://localhost:9000/assetTracking", "_self");
        } else {
          window.open(
            "http://http://localhost:9000/dashboard:9000/home",
            "_self"
          );
        }
      });

      return payload;
    });
  }
};

export const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("firebase-messaging-sw.js")
      .then(function (registration) {
        return registration.scope;
      })
      .catch(function (err) {
        return err;
      });
  }
};

const firebaseInitObject = {
  requestFirebaseNotificationPermission,
  onMessageListener,
  registerServiceWorker,
};
export default firebaseInitObject;
