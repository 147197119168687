import React, { Component } from 'react'
import { Row, Col } from "reactstrap";
// import { Card, CardContent } from "@material-ui/core";
import Quill from "../quill/Quill";
import FetchServerData from '../../provider/FetchServerData';
import { withRouter } from "../../withRouter";
class PrivacyPolicyContent extends Component {
	constructor(props) {
		super(props);
		this.state = { templateDetails: "", render: false };
		this.getTemplateDetails();
	}
	navigateToLink(linkToNavigate) {
		this.props.navigate(linkToNavigate);
	}
	contactUs(linkToNavigate) {
		this.props.navigate(linkToNavigate, {
			state: {
				scrollTo: "contact"
			}
		});
	}

	getTemplateDetails() {
		let postObject = {};
		FetchServerData.callPostService("/apPrivacyPolicy/geActive", postObject)
			.then((response) => {
				let output = response;
				if (output.status === "SUCCESS") {
					let templateDetails = output.data.privacyPolicy.data;
					this.setState({ templateDetails: templateDetails, render: true });
				}
			})
			.catch((error) => { });
	}

	render() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<div
					id="top"
					data-wow-duration="1s"
					data-wow-delay="0.5s"
				>
					<div
						className="container"
						style={{ border: "1px solid lightgray", padding: "2em" }}
					>
						<Row>
							<Col md={12}>
								<Quill
									text={this.state.templateDetails}
									className="templateMessageBox"
									theme="bubble"
									readOnly={true}
								/>
							</Col>
						</Row>
					</div>
				</div>
			)
		}
	}
}
export default withRouter(PrivacyPolicyContent)