import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Box } from '@mui/material';
import FetchServerData from "../../provider/FetchServerData";
import CatalogueAction from "../../redux/catalogue/CatalogueAction";
import store from "../../redux/store";
import Modal from "../../ui/modal/Modal";
import { withRouter } from "../../withRouter";
import PtsButton from "../buttons/PtsButton";
import PtsAlert from "../ptsAlert/PtsAlert";
import CatalogueBreadScrum from "./catalogueBreadScrum/CatalogueBreadScrum";
import "./TopCategory.css";
import Menu from "@mui/material/Menu";
import MoveCategory from "../moveCategory/MoveCategory";
import CodeUomListAction from "../../redux/codeUomList/CodeUomListAction";
import MenuIcon from "@mui/icons-material/Menu";
import CategoryAction from "../../redux/category/CategoryAction";
import Utils from "../../provider/Utils";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import CustomerAction from "../../redux/customer/CustomerAction";
import Pagination from "../assetTracking/Pagination";
import BackButton from "../buttons/BackButton";
import Link from "@material-ui/core/Link";
import FilterDetailsAction from "../../redux/filterDetails/FilterDetailsAction";
import CreateButton from "../buttons/CreateButton";
import CancelIconButton from "../buttons/CancelIconButton";
import SaveButton from "../buttons/SaveButton";
import MenuAction from "../../redux/menu/MenuAction";
import UpdateButton from "../buttons/UpdateButton";
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";

class TopCategory extends Component {
  header = store.getState().header.header;
  assetCodeList = store.getState().codeUomList.assetCodeList;
  filterDetails = store.getState().filterDetails.filterData;
  title = "Catalogue";
  breadCrumb = [];
  constructor(props) {
    super(props);
    let view = "categoryView";
    let pageId = "";

    let userMenuArray = Utils.getTopMenus();
    // if (props.state) {
    //   for (let i = 0; i < userMenuArray.length; i++) {
    //     for (let j = 0; props.state.pageid && j < props.state.pageid?.length; j++) {
    //       if (userMenuArray[i].pageid === props.state.pageid) {
    //         store.dispatch(MenuAction.setActiveMenu(userMenuArray[i].id));
    //       }
    //     }
    //   }
    // }

    if (view && props.state) {
      view = props.state.view;
      pageId = props.state.pageId;
      for (let i = 0; i < userMenuArray.length; i++) {
        for (let j = 0; props.state.pageid && j < props.state.pageid?.length; j++) {
          if (userMenuArray[i].pageid === props.state.pageid) {
            store.dispatch(MenuAction.setActiveMenu(userMenuArray[i].id));
          }
        }
      }
    }
    // store.dispatch(FilterDetailsAction.setFilterDetails({}));
    let filterData = {};
    if (this.filterDetails) {
      filterData = Object.getOwnPropertyNames(this.filterDetails);
      // view = "filterView";
    }
    if (filterData.length > 0) {
      this.state = {
        action: "",
        openModal: false,
        catalogueId: "",
        categoryId: "",
        categoryName: "",
        topCategories: [],
        openEditModal: false,
        render: false,
        open: false,
        anchorEl: null,
        openMoveCategoryModal: false,
        getAllPermission: false,
        getCreatePermission: false,
        openMenu: false,
        anchorElMenu: null,
        assetCode: this.filterDetails.assetcode,
        assetName: this.filterDetails.name,
        assetUid: this.filterDetails.uid,
        customer: this.filterDetails.customer,
        customerList: [],
        supplierType: this.filterDetails.supplier,
        supplierList: [],
        categoryType: this.filterDetails.category,
        categoryList: [],
        statusList: [
          { id: 1, name: "Active" },
          { id: 2, name: "Inactive" },
        ],
        filterStatus: this.filterDetails.status,
        assetSearchList: [],
        searchText: "",
        pageNo: 0,
        showPerPage: 0,
        pageSize: 10,
        displaySequence: "",
        draggableId: "",
        view: view,
        search: "",
        filterDetails: this.filterDetails,
        pageId: pageId,
        SequenceCategoryList: [],
        sequenseButton: false,
        iszIndex: false,
      };
      if (pageId !== "AssetView") {
        this.onPaginationChange({
          "counter": 0,
          "pageNo": 0,
          "showPerPage": 10,
          "buttonIndex": 0,
        })
      }
    } else {
      this.state = {
        action: "",
        openModal: false,
        catalogueId: "",
        categoryId: "",
        categoryName: "",
        assetName: "",
        assetUid: "",
        topCategories: [],
        openEditModal: false,
        render: false,
        open: false,
        anchorEl: null,
        openMoveCategoryModal: false,
        getAllPermission: false,
        getCreatePermission: false,
        openMenu: false,
        anchorElMenu: null,
        assetCode: null,
        customer: null,
        customerList: [],
        supplierType: null,
        supplierList: [],
        categoryType: null,
        categoryList: [],
        statusList: [
          { id: 1, name: "Active" },
          { id: 2, name: "Inactive" },
        ],
        filterStatus: null,
        assetSearchList: [],
        searchText: "",
        pageNo: 0,
        showPerPage: 0,
        pageSize: 10,
        displaySequence: "",
        draggableId: "",
        view: view,
        search: "",
        filterDetails: {},
        SequenceCategoryList: [],
        sequenseButton: false,
        iszIndex: false,
      };
    }
    // this.filterDataInBackend(this.state.pageNo, this.state.pageSize);
    this.getCatalogueList();
    this.getCodeUomList();
  }

  getCatalogueList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/cataloguems/getDefault", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let Catalogue = output.data;
          let getPermission = Utils.getRolePermission(
            output.data.privilegeList
          );
          let getAllPermission = getPermission[0];
          let getCreatePermission = getPermission[1];
          let CatalogueDetails = Catalogue.catalogue;
          store.dispatch(CatalogueAction.setCatalogue(CatalogueDetails));
          store.dispatch(FilterDetailsAction.setFilterDetails({}));
          this.setState({
            render: true,
            catalogueId: Catalogue.catalogue.id,
            topCategories: Catalogue.topCategories?.sort((a, b) => a.displaysequence - b.displaysequence),
            getAllPermission: getAllPermission,
            getCreatePermission: getCreatePermission,
            // view: "categoryView",
          });
        } else {
        }
      }
    );
  }
  getCodeUomList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/assetms/getCodeUomList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let assetCodeUomData = output.data;
          store.dispatch(CodeUomListAction.setCodeUomList(assetCodeUomData));
        } else {
        }
      }
    );
  }
  onMoveAssetClose() {
    this.setState({ openMoveCategoryModal: !this.state.openMoveCategoryModal });
  }
  addNewCategory() {
    this.setState({
      openModal: true,
      action: "create",
      categoryId: null,
      categoryName: "",
    });
  }

  onModalClose() {
    this.setState({ openModal: !this.state.openModal });
  }

  handleCategoryNameChange(e) {
    this.setState({ categoryName: e.target.value });
  }
  createCategoryInBackend() {
    const postObject = {
      header: this.header,
      category: {
        name: this.state.categoryName,
        catalogue: {
          id: this.state.catalogueId,
        },
      },
    };

    FetchServerData.callPostService("/categoryms/create", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.getCatalogueList();
          this.setState({
            openModal: false,
          });
        } else {
        }
      })
      .catch((error) => { });
  }

  deleteCategory(e, data) {
    const postObject = {
      header: this.header,
      category: {
        id: data.id,
        catalogue: {
          id: this.state.catalogueId,
        },
      },
    };
    FetchServerData.callPostService("/categoryms/delete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          for (let i = 0; i < this.state.topCategories.length; i++) {
            if (this.state.topCategories[i].id === data.id) {
              this.state.topCategories.splice(i, 1);
            }
          }
          this.setState({
            topCategories: this.state.topCategories?.sort((a, b) => a.displaysequence - b.displaysequence),
          });
        } else {
        }
      }
    );
  }
  handleAccountDropdownOpen = (e) => {
    this.setState({
      openMenu: true,
      anchorElMenu: e.currentTarget,
      // categoryType: null,
      // supplierType: null,
      // customer: null,
      // categoryType: null,
      // filterStatus: null,
      // assetCode: null,
    });
    store.dispatch(FilterDetailsAction.setFilterDetails({}));
    this.getCustomerList();
    this.getSupplierTypeList();
    this.getCategoryTypeList();
  };

  handleAccountDropdownClose = () => {
    this.setState({ openMenu: false, anchorElMenu: null });
  };
  viewCategory = (e, data) => {
    store.dispatch(CategoryAction.setCategory(data));
    this.props.navigate("/category", {
      state: {
        categoryId: data.id,
        catalogueId: this.state.catalogueId,
        view: "categoryView",
      },
    });
  };

  handleMoveCategoryDropdownOpen = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget });
  };

  handleMoveCategoryDropdownClose = () => {
    this.setState({ open: false, anchorEl: null });
  };
  handleMoveCategory = (e, data) => {
    this.props.navigate("/moveCategory", {
      state: {
        categoryId: data.id,
      },
    });
  };

  handleAssetNameChange(e) {
    this.setState({ assetName: e.target.value });
  }
  handleAssetCodeChange(selectedAssetCode) {
    this.state.assetCode = selectedAssetCode;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.setState({
      assetCode: selectedAssetCode,
    });
  }
  handleCustomerTypeChange(selectedAssetCode) {
    this.state.customer = selectedAssetCode;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.setState({
      customer: selectedAssetCode,
    });
  }
  handleAssetUidChange(e) {
    this.setState({ assetUid: e.target.value });
  }

  getCustomerList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/costomerms/getActiveList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let customerListArray = output.data.customerList;
          let allCustomer = {
            name: "All"
          }
          const customerList = [allCustomer, ...customerListArray]
          store.dispatch(CustomerAction.setCustomer(customerList));
          this.setState({
            customerList: customerList,
          });
        } else {
        }
      }
    );
  }

  getSupplierTypeList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/supplierms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let supplierListArray = output.data.supplierList;
          let allCustomer = {
            name: "All"
          }
          const supplierList = [allCustomer, ...supplierListArray]
          this.setState({
            supplierList: supplierList,
          });
        } else {
        }
      }
    );
  }
  getCategoryTypeList() {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService("/categoryms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let categoryList = output.data.categoryList;
          this.setState({
            categoryList: categoryList,
          });
        } else {
        }
      }
    );
  }
  handleSupplierTypeChange(selectedSupplier) {
    this.state.supplierType = selectedSupplier;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.setState({
      supplierType: selectedSupplier,
    });
  }
  handleCategoryChange(selectedCategory) {
    this.state.categoryType = selectedCategory;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.setState({
      categoryType: selectedCategory,
    });
  }

  handleStatusChange(selectedStatus) {
    this.state.filterStatus = selectedStatus;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.setState({
      filterStatus: selectedStatus,
    });
  }
  resetFilter() {
    this.setState({
      openMenu: false,
      // anchorElMenu: e.currentTarget,
      categoryType: null,
      supplierType: null,
      customer: null,
      filterStatus: null,
      assetCode: null,
      assetName: "",
      assetUid: "",
      view: "categoryView",
    });
    store.dispatch(FilterDetailsAction.setFilterDetails({}));
  }
  filterDataInBackend(pageNo, pageSize) {
    let assetCode = "";
    let status = "";
    let customerId = "";
    let supplierId = "";
    let categoryId = "";
    if (this.state.assetCode === null) {
      assetCode = "";
    } else {
      assetCode = this.state.assetCode.code;
    }
    if (this.state.filterStatus === null) {
      status = "";
    } else {
      status = this.state.filterStatus.name;
    }
    if (this.state.customer === null) {
      customerId = null;
    } else {
      customerId = this.state.customer.id;
    }
    if (this.state.supplierType === null) {
      supplierId = null;
    } else {
      supplierId = this.state.supplierType.id;
    }
    if (this.state.categoryType === null) {
      categoryId = null;
    } else {
      categoryId = this.state.categoryType.id;
    }
    const postObject = {
      header: this.header,
      assetcode: assetCode,
      assetName: this.state.assetName,
      uid: this.state.assetUid,
      status: status,
      customerId: customerId,
      supplierId: supplierId,
      categoryId: categoryId,
      page: {
        searchText: this.state.search,
        pageNo: pageNo,
        pageSize: pageSize,
      },
    };
    FetchServerData.callPostService("/assetms/getFilterList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let filterDetails = {
            assetcode: this.state.assetCode,
            uid: this.state.assetUid,
            name: this.state.assetName,
            status: this.state.filterStatus,
            customer: this.state.customer,
            supplier: this.state.supplierType,
            category: this.state.categoryType,
          };
          store.dispatch(FilterDetailsAction.setFilterDetails(filterDetails));
          let assetDetails = output.data;
          let assetList = output.data.assetList;
          for (let i = 0; i < assetList.length; i++) {
            assetList[i].sortDate = assetList[i].tdate;
            assetList[i].tdate = Utils.getUtcToLocal(assetList[i].tdate);
          }

          this.setState({
            render: true,
            view: "filterView",
            openMenu: false,
            assetSearchList: assetList,
            pageNo: assetDetails.page.pageNo,
            showPerPage: assetDetails.page.pageSize,
            totalPages: assetDetails.page.totalPages,
            totalRecords: assetDetails.page.total,
          });
        } else {
        }
      }
    );
  }
  onPaginationChange(returnProp) {
    let counter = returnProp.counter;
    let pageNo = this.state.pageNo + counter;
    let pageSize = returnProp.showPerPage;
    let buttonIndex = returnProp.buttonIndex;
    let currentPageIndex =
      typeof counter !== "undefined" ? pageNo : buttonIndex;
    let resetPageNo = returnProp.resetPageNo;
    if (resetPageNo === true) {
      currentPageIndex = 0;
    }
    this.setState({
      pageNo: currentPageIndex,
    });
    this.filterDataInBackend(currentPageIndex, pageSize);
  }
  onSearchChange(e) {
    this.setState({ search: e.target.value });
  }

  viewCancel() {
    this.setState({
      view: "categoryView",
      openMenu: false,
      anchorElMenu: null,
    });
  }
  toAssetDetails = (asetId, e) => {
    this.props.navigate("/asset", {
      state: {
        categoryId: this.state.categoryId,
        action: "view",
        view: "categoryView",
        assetId: asetId,
        replace: true,
        pageId: "filterData",
      },
    });
  };
  goToPage(e, element) {
    store.dispatch(CategoryAction.setCategory(element));

    this.props.navigate("/category", {
      state: {
        categoryId: element.categoryid,
        // catalogueId: this.state.catalogueId,
        view: "categoryView",
        pageId: "AssetView"
      },
    });
    // this.props.onSuccess(element.id);
  }
  showCategoryPath(data) {
    return (
      <Link
        // key={index}
        style={{ color: "blue", fontSize: "small", cursor: "pointer" }}
        onClick={(e) => this.goToPage(e, data)}
      >
        <span>
          <i
            // className={element.cssclass}
            style={{ color: "#c16767", fontSize: "small" }}
          ></i>
        </span>
        &nbsp;{data.cagegoryPath}
      </Link>
    );
  }

  editCategory = (e, data) => {
    this.setState({
      openEditModal: true,
      categoryId: data.id,
      categoryName: data.name,
    });
  };

  onEditModalClose() {
    this.setState({
      openEditModal: !this.state.openEditModal,
      categoryId: "",
      categoryName: "",
    });
  }

  updateCategoryInBackend() {
    const postObject = {
      header: this.header,
      category: {
        id: this.state.categoryId,
        name: this.state.categoryName,
        catalogue: {
          id: this.state.catalogueId,
        },
      },
    };

    FetchServerData.callPostService("/categoryms/update", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          // let categoryDetails = output.data.category;
          PtsAlert.success(output.message);
          // this.getCategoryDetails();
          this.getCatalogueList();
          this.setState({
            // name: categoryDetails.name,
            openEditModal: false,
          });
        } else {
        }
      })
      .catch((error) => { });
  }

  updateCategorySequence() {
    const postObject = {
      header: this.header,
      categoryList: this.state.SequenceCategoryLists,
    }
    FetchServerData.callPostService("/categoryms/updateSequence", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          // let Catalogue = output.data;
          PtsAlert.success(output.message);
          this.setState({
            sequenseButton: false,
          })
        } else {
        }
      }
    );
  }

  onDragEnd(sourceId, sourceIndex, targetIndex) {
    const nextState = swap(this.state.topCategories, sourceIndex, targetIndex);
    let SequenceCategoryLists = [];
    for (let i = 0; i < nextState.length; i++) {
      const element = nextState[i];
      SequenceCategoryLists.push({ "id": element.id, "displaySequence": i + 1 });
    }
    this.setState({
      topCategories: nextState,
      SequenceCategoryLists: SequenceCategoryLists,
      sequenseButton: true,
    })

  }

  handleDropdownMenu(id) {
    this.setState({
      dropdownMenuId: id,
    })
  }

  render() {
    if (this.state.view === "filterView") {
      return this.filterView();
    } else {
      store.dispatch(FilterDetailsAction.setFilterDetails({}));
      return this.categoryView();
    }
  }

  showTopCategories() {
    if (this.state.render === false) {
      return <div />;
    } else {
      const colors = [
        "#9bdef9",
        "#d4dfd1",
        "#e4b982",
        "#67e0e9",
        "#e8b1c4",
        "#67e0e9",
        "#e8b1c4",
        "#7ab9de",
        "#84bfcf",
        "#f4eade",
        "#ffb6b9"
        // "#f8d3ff",
      ];
      return (
        <Box>
          <GridContextProvider className="droppable" onChange={this.onDragEnd.bind(this)} >
            <GridDropZone
              id="items"
              boxesPerRow={4}
              rowHeight={100}
              style={{ height: 100 * Math.ceil(this.state.topCategories.length / 4) }}
            >
              {
                this.state.topCategories.map((data, index) => {
                  return (
                    <GridItem key={data.id} style={{ zIndex: data.id === this.state.dropdownMenuId ? "1" : "0" }}>
                      <Card
                        style={{
                          background: colors[index % colors.length],
                          cursor: "pointer",
                          // margin: "10px"
                          width: "90%"
                        }}
                        key={index}
                      >
                        <Row >
                          <div className="d-flex col-12 col-lg-9 col-xl-9">
                            <Col
                              md={10}
                              onClick={(e) => this.viewCategory(e, data)}
                              style={{ margin: "auto", minHeight: "100%", width: "100%" }}
                            >
                              {/* <div className="row"> */}
                              <h5 id="cardTitle">{data.name}</h5>
                              <h6 className="pb-2">Assets : {data.assetCount}</h6>
                              {/* </div> */}
                            </Col>
                          </div>
                          <div className="d-flex col-12 col-lg-3 col-xl-3 justify-content-center">
                            <Col md={2}>
                              <UncontrolledDropdown nav inNavbar onClick={this.handleDropdownMenu.bind(this, data.id)}>
                                <DropdownToggle nav>
                                  <MenuIcon id="MoveIconBtn" />
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <DropdownItem
                                    onClick={(e) => this.handleMoveCategory(e, data)}
                                  >
                                    <Row>
                                      <Col md={12}>
                                        <span id="menuNameStyle">Move Category</span>
                                      </Col>
                                    </Row>
                                  </DropdownItem>
                                  <hr style={{ margin: "0em" }} />
                                  <DropdownItem onClick={(e) => this.editCategory(e, data)}>
                                    <Row>
                                      <Col md={12}>
                                        <span id="menuNameStyle">Edit</span>
                                      </Col>
                                    </Row>
                                  </DropdownItem>
                                  <hr style={{ margin: "0em" }} />
                                  <DropdownItem
                                    onClick={(e) => this.deleteCategory(e, data)}
                                  >
                                    <Row>
                                      <Col md={12}>
                                        <span id="menuNameStyle">Delete</span>
                                      </Col>
                                    </Row>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </Col>
                          </div>
                        </Row>
                      </Card >
                    </GridItem>
                  )
                })
              }
            </GridDropZone>
          </GridContextProvider>
        </Box>
      )
    }
  }
  filterView() {
    if (this.state.render === false) {
      return <div />;
    } else {
      // let searchFilterDetails = { };
      // if (this.filterDetails) {
      //   searchFilterDetails = Object.getOwnPropertyNames(this.filterDetails);
      // }
      return (
        <>
          <CatalogueBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            pageId="home"
            // btnName="Add Category"
            categoryDetails={this.state.topCategories}
            getAllPermission={this.state.getAllPermission}
            getCreatePermission={this.state.getCreatePermission}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
          />
          <Row>
            <Col md={11} style={{ margin: "auto" }}>
              <span style={{ float: "right", marginTop: "1em" }}>
                {/* {Object.keys(this.state.filterDetails).length === 0 ? (
                  ""
                ) : ( */}
                <>
                  (
                  {this.state.assetName ? (
                    <>
                      <span>
                        <label htmlFor="basic-default-name" id="assetFormLabel">
                          <b> Asset Name: </b>
                        </label>
                        <span>
                          {this.state.assetName ? " " + this.state.assetName : ""}
                        </span>
                      </span>
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}{" "}
                  {this.state.assetCode ? (
                    <>
                      <span>
                        <label htmlFor="basic-default-name" id="assetFormLabel">
                          <b>asset Code: </b>
                        </label>

                        <span>
                          {this.state.assetCode
                            ? " " + this.state.assetCode.code
                            : ""}
                        </span>
                      </span>
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.assetUid ? (
                    <>
                      <span>
                        <label htmlFor="basic-default-name" id="assetFormLabel">
                          <b> Asset Uid : </b>
                        </label>
                        <span>
                          {this.state.assetUid ? " " + this.state.assetUid : ""}
                        </span>
                      </span>
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}{" "}
                  {this.state.customer ? (
                    <>
                      <span>
                        <label htmlFor="basic-default-name" id="assetFormLabel">
                          <b>customer : </b>
                        </label>

                        <span>
                          {this.state.customer
                            ? " " + this.state.customer.name
                            : ""}
                        </span>
                      </span>{" "}
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.supplierType ? (
                    <>
                      <span>
                        <label htmlFor="basic-default-name" id="assetFormLabel">
                          <b> supplier : </b>
                        </label>

                        <span>
                          {this.state.supplierType
                            ? " " + this.state.supplierType.name
                            : ""}
                        </span>
                      </span>{" "}
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}{" "}
                  {this.state.categoryType ? (
                    <>
                      <span>
                        <label htmlFor="basic-default-name" id="assetFormLabel">
                          <b> Category : </b>
                        </label>

                        <span>
                          {this.state.categoryType
                            ? " " + this.state.categoryType.name
                            : ""}
                        </span>
                      </span>{" "}
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}{" "}
                  &nbsp;
                  {this.state.filterStatus ? (
                    <>
                      <span>
                        <label htmlFor="basic-default-name" id="assetFormLabel">
                          <b> Status : </b>
                        </label>

                        <span>
                          {this.state.filterStatus
                            ? " " + this.state.filterStatus.name
                            : ""}
                        </span>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                  )
                </>
                {/* )} */}
              </span>
            </Col>
            <Col md={1}>
              {" "}
              <span style={{ float: "right", marginTop: "1em", cursor: "pointer" }} onClick={this.handleAccountDropdownOpen}>
                <FilterAltIcon
                  style={{
                    fontSize: "28px",
                    color: "rgb(106, 123, 142)",

                  }}
                  aria-controls={
                    this.state.openMenu ? "dropdown-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={this.state.openMenu ? "true" : undefined}
                />
                &nbsp;
                <span
                  style={{
                    fontSize: "17px",
                    color: "#566a7f",
                    fontWeight: "500",
                  }}
                >
                  Filter
                </span>{" "}
              </span>
            </Col>{" "}
            <Menu
              anchorEl={this.state.anchorElMenu}
              id="dropdown-menu"
              open={this.state.openMenu}
              onClose={this.handleAccountDropdownClose}
              // onClick={this.handleAccountDropdownClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 20,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div
                className="dropdown-item"
                style={{ cursor: "pointer", width: "400px", height: "100%" }}
              >
                <h5>Filter</h5>
                <div className="row mt-2">
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      className="form-label"
                      htmlFor="collapsible-fullname"
                    >
                      Asset Name
                    </label>
                    <br></br>
                    <TextField
                      type="text"
                      className="textField"
                      fullWidth
                      id="basic-default-name"
                      variant="outlined"
                      placeholder="Asset Name"
                      value={this.state.assetName}
                      onChange={this.handleAssetNameChange.bind(this)}
                    />
                  </div>
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-supplierType"
                    >
                      Asset Uid
                    </label>
                    <br></br>
                    <TextField
                      type="text"
                      className="textField"
                      fullWidth
                      variant="outlined"
                      placeholder="Asset Uid"
                      value={this.state.assetUid}
                      onChange={this.handleAssetUidChange.bind(this)}
                      id="basic-default-name"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6" id="assetFormLabel">
                    <label
                      className="form-label"
                      htmlFor="collapsible-fullname"
                    >
                      Asset Code
                    </label>
                    <Autocomplete
                      required
                      options={this.assetCodeList}
                      value={this.state.assetCode}
                      defaultValue={this.state.assetCode}
                      getOptionLabel={(option) => option.code}
                      onChange={(event, value) => {
                        this.handleAssetCodeChange(value);
                      }}
                      inputprops={{
                        style: { textTransform: "capitalize" },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="form-control"
                          variant="outlined"
                          placeholder="Asset Code*"
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6 mt-1" id="assetFormLabel">
                    <label
                      className="form-label"
                      htmlFor="collapsible-fullname"
                    >
                      Category
                    </label>
                    <Autocomplete
                      id="multicol-country"
                      options={this.state.categoryList}
                      value={this.state.categoryType}
                      defaultValue={this.state.categoryType}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleCategoryChange(value);
                      }}
                      inputprops={{
                        style: { textTransform: "capitalize" },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="form-control"
                          variant="outlined"
                          placeholder="Category"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-1" id="assetFormLabel">
                    <label
                      className="form-label "
                      htmlFor="collapsible-supplierType"
                    >
                      Customer
                    </label>
                    <Autocomplete
                      fullWidth
                      id="customerTypeList"
                      className="customer"
                      options={this.state.customerList}
                      value={this.state.customer}
                      defaultValue={this.state.customer}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleCustomerTypeChange(value);
                      }}
                      inputprops={{ style: { textTransform: "capitalize" } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Customer"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6 mt-1" id="assetFormLabel">
                    {" "}
                    <label
                      className="form-label"
                      htmlFor="collapsible-fullname"
                    >
                      Supplier
                    </label>
                    <Autocomplete
                      id="multicol-country"
                      options={this.state.supplierList}
                      value={this.state.supplierType}
                      defaultValue={this.state.supplierType}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleSupplierTypeChange(value);
                      }}
                      inputprops={{
                        style: { textTransform: "capitalize" },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="form-control"
                          variant="outlined"
                          placeholder="Supplier"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-1" id="assetFormLabel">
                    <label
                      className="form-label"
                      htmlFor="collapsible-fullname"
                    >
                      Status
                    </label>
                    <Autocomplete
                      id="multicol-country"
                      options={this.state.statusList}
                      value={this.state.filterStatus}
                      defaultValue={this.state.filterStatus}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleStatusChange(value);
                      }}
                      inputprops={{
                        style: { textTransform: "capitalize" },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="form-control"
                          variant="outlined"
                          placeholder="Status"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <button
                    className="fileterButton"
                    onClick={this.onPaginationChange.bind(this, {
                      "counter": 0,
                      "pageNo": 0,
                      "showPerPage": 10,
                      "buttonIndex": 0,

                    })}
                  >
                    <i className="fa-solid fa-filter"></i>
                    &nbsp; Filter
                  </button>
                  &nbsp; &nbsp;
                  <button
                    className="resetButton"
                    onClick={this.resetFilter.bind(this)}
                  >
                    <i className="fa fa-refresh"></i>
                    &nbsp; Reset
                  </button>
                </div>
              </div>
            </Menu>
          </Row>
          {
            this.state.assetSearchList.length > 0 ? (
              <>
                <div className="row mt-2">
                  <div className="col md-12">
                    <div className="card">
                      <div className="row justify-content-between p-4">
                        <div className="col-4 text-start totalResults">
                          <span>Total Results: {this.state.totalRecords}</span>
                        </div>
                        <div className="col-4 text-end">
                          <input
                            type="search"
                            id="gsearch"
                            style={{
                              width: "240px",
                              height: "33px",
                              outline: "none",
                            }}
                            placeholder="Search"
                            name="gsearch"
                            value={this.state.search}
                            onChange={this.onSearchChange.bind(this)}
                          />
                          <button
                            className="searchButton"
                            onClick={this.filterDataInBackend.bind(
                              this,
                              this.state.pageNo,
                              this.state.pageSize
                            )}
                            style={{ background: "#0B6F9D" }}
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      <table className="table table-striped text-start">
                        <thead
                          className="trackingThead"
                          style={{ background: "#0B6F9D", color: "#fff" }}
                        >
                          <tr>
                            <th>#</th>
                            <th>Asset Name</th>
                            <th>Asset UID</th>
                            <th>Asset Code</th>
                            <th>Asset Type</th>
                            <th>category Path</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.assetSearchList?.map((rowData, index) => (
                            <tr
                              key={index}
                            >
                              {" "}
                              <td>
                                {this.state.pageNo * this.state.showPerPage +
                                  index +
                                  1}
                              </td>
                              <td>{rowData.name}</td>
                              <td
                                className="assetUID"
                                style={{ color: "blue" }}
                                onClick={(e) => this.toAssetDetails(rowData.id)}
                              > {rowData.uid}
                              </td>
                              <td>{rowData.assetcode}</td>
                              <td>{rowData.assetType}</td>
                              <td className="assetUID">
                                {this.showCategoryPath(rowData)}
                              </td>
                              <td>{rowData.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="row justify-content-between pagination p-4">
                        <div className="pageSize col-4 text-start">
                          Page {this.state.pageNo + 1} of {this.state.totalPages}
                        </div>
                        <div className="col-4 text-end">
                          <Pagination
                            showPerPage={this.state.showPerPage}
                            onPaginationChange={this.onPaginationChange.bind(
                              this
                            )}
                            total={this.state.totalRecords}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="row" style={{ marginTop: "1em" }}>
                <div className="col-md-12">
                  <Card>
                    <CardBody>
                      <div className="row">
                        <div className="col-md-12" style={{ padding: "2em" }}>
                          <h5>
                            <b>Details not Found</b>
                          </h5>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            )
          }
          <div
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "2em",
              padding: "0em 0em 1em",
            }}
          >
            <BackButton
              type="button"
              style={{ backgroundColor: "#162c50", color: "#fff" }}
              variant="contained"
              // startIcon={<CloseIcon />}
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>{" "}
          </div>
        </>
      );
    }
  }
  categoryView() {
    if (this.state.render === false) {
      return <div />;
    } else {
      // let searchFilterDetails = { };
      // if (this.filterDetails) {
      //   searchFilterDetails = Object.getOwnPropertyNames(this.filterDetails);
      // }
      // console.log(Object.keys(this.filterDetails).length, "filterDetails");
      return (
        <>
          <CatalogueBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            pageId="home"
            // deleteCallback={this.deleteCategory.bind(this)}
            // editCallback={this.editCategory.bind(this)}
            // AddCategoryCallback={this.addNewCategory.bind(this)}
            btnName="Add Category"
            categoryDetails={this.state.topCategories}
            getAllPermission={this.state.getAllPermission}
            getCreatePermission={this.state.getCreatePermission}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
          />

          {this.state.topCategories.length > 0 ? (
            <>
              <Row style={{ marginTop: "1em" }}>
                <Col md={9} style={{ margin: "auto" }}></Col>
                <Col md={3} style={{ display: "inline-flex" }}>
                  <span style={{ float: "right", margin: "0.5em 1em" }} onClick={this.handleAccountDropdownOpen}>
                    <FilterAltIcon
                      style={{
                        fontSize: "28px",
                        color: "rgb(106, 123, 142)",
                        cursor: "pointer",
                      }}
                      aria-controls={
                        this.state.openMenu ? "dropdown-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={this.state.openMenu ? "true" : undefined}
                    />
                    &nbsp;
                    <span
                      style={{
                        fontSize: "17px",
                        color: "#566a7f",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      Filter
                    </span>{" "}
                  </span>{" "}
                  &nbsp;
                  <PtsButton
                    btnName="Add Category"
                    onClick={this.addNewCategory.bind(this)}
                    style={{ float: "right" }}
                  />
                </Col>
                <Menu
                  anchorEl={this.state.anchorElMenu}
                  id="dropdown-menu"
                  open={this.state.openMenu}
                  onClose={this.handleAccountDropdownClose}
                  // onClick={this.handleAccountDropdownClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 20,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <div
                    className="dropdown-item"
                    style={{
                      cursor: "pointer",
                      width: "400px",
                      height: "100%",
                    }}
                  >
                    <h5>Filter</h5>
                    <div className="row mt-2">
                      <div className="col-md-6" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-fullname"
                        >
                          Asset Name
                        </label>
                        <br></br>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          id="basic-default-name"
                          variant="outlined"
                          placeholder="Asset Name"
                          value={this.state.assetName}
                          onChange={this.handleAssetNameChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-6" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-supplierType"
                        >
                          Asset Uid
                        </label>
                        <br></br>
                        <TextField
                          type="text"
                          className="textField"
                          fullWidth
                          variant="outlined"
                          placeholder="Asset Uid"
                          value={this.state.assetUid}
                          onChange={this.handleAssetUidChange.bind(this)}
                          id="basic-default-name"
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-fullname"
                        >
                          Asset Code
                        </label>
                        <Autocomplete
                          required
                          options={this.assetCodeList}
                          value={this.state.assetCode}
                          defaultValue={this.state.assetCode}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, value) => {
                            this.handleAssetCodeChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="form-control"
                              variant="outlined"
                              placeholder="Asset Code*"
                              required
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-6 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-fullname"
                        >
                          Category
                        </label>
                        <Autocomplete
                          id="multicol-country"
                          options={this.state.categoryList}
                          value={this.state.categoryType}
                          defaultValue={this.state.categoryType}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCategoryChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="form-control"
                              variant="outlined"
                              placeholder="Category"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-1" id="assetFormLabel">
                        <label
                          className="form-label "
                          htmlFor="collapsible-supplierType"
                        >
                          Customer
                        </label>
                        <Autocomplete
                          fullWidth
                          id="customerTypeList"
                          className="customer"
                          options={this.state.customerList}
                          value={this.state.customer}
                          defaultValue={this.state.customer}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCustomerTypeChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Customer"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-6 mt-1" id="assetFormLabel">
                        {" "}
                        <label
                          className="form-label"
                          htmlFor="collapsible-fullname"
                        >
                          Supplier
                        </label>
                        <Autocomplete
                          id="multicol-country"
                          options={this.state.supplierList}
                          value={this.state.supplierType}
                          defaultValue={this.state.supplierType}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleSupplierTypeChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="form-control"
                              variant="outlined"
                              placeholder="Supplier"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-1" id="assetFormLabel">
                        <label
                          className="form-label"
                          htmlFor="collapsible-fullname"
                        >
                          Status
                        </label>
                        <Autocomplete
                          id="multicol-country"
                          options={this.state.statusList}
                          value={this.state.filterStatus}
                          defaultValue={this.state.filterStatus}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleStatusChange(value);
                          }}
                          inputprops={{
                            style: { textTransform: "capitalize" },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="form-control"
                              variant="outlined"
                              placeholder="Status"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <button
                        className="fileterButton"
                        onClick={this.onPaginationChange.bind(this, {
                          "counter": 0,
                          "pageNo": 0,
                          "showPerPage": 10,
                          "buttonIndex": 0,
                        })}
                      >
                        <i className="fa-solid fa-filter"></i>
                        &nbsp; Filter
                      </button>
                      &nbsp; &nbsp;
                      <button
                        className="resetButton"
                        onClick={this.resetFilter.bind(this)}
                      >
                        <i className="fa fa-refresh"></i>
                        &nbsp; Reset
                      </button>
                    </div>
                  </div>
                </Menu>
              </Row>
              <Card xs={12} style={{ marginTop: "1em" }}>

                <CardBody>
                  {this.state.sequenseButton &&
                    <div className="row text-end">
                      <UpdateButton type="submit" onClick={this.updateCategorySequence.bind(this)} />
                    </div>
                  }
                  <div className="row p-3">
                    {this.showTopCategories()}
                  </div>{" "}

                </CardBody>
              </Card>
            </>
          ) : (
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-12" style={{ padding: "2em" }}>
                        <h5>
                          <b>Category not created yet</b>
                        </h5>
                        <br />
                        <PtsButton
                          btnName="Add New Category"
                          onClick={this.addNewCategory.bind(this)}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          )
          }

          <Modal
            open={this.state.openModal}
            title="Create New Category"
            onClose={this.onModalClose.bind(this)}
          >
            <div className="row mb-3">
              <label
                className="col-sm-3 col-form-label"
                htmlFor="basic-icon-default-fullname"
              >
                Category Name
              </label>
              <div className="col-sm-9">
                <div className="input-group input-group-merge">
                  <span
                    id="basic-icon-default-fullname2"
                    className="input-group-text"
                  >
                    <i className="bx bx-category"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-icon-default-fullname"
                    placeholder="Category Name"
                    aria-label="Category Name"
                    aria-describedby="basic-icon-default-fullname2"
                    value={this.state.categoryName}
                    onChange={this.handleCategoryNameChange.bind(this)}
                  />
                </div>
              </div>
            </div>

            <div
              className="modal-footer"
              style={{ padding: "1.2rem 1.5rem 1.5rem" }}
            >
              {" "}
              {/* <PtsButton
                btnName="Create"
                onClick={(e) => this.createCategoryInBackend()}
              />
              <button
                type="button"
                className="btn btn-label-secondary"
                data-bs-dismiss="modal"
                onClick={this.onModalClose.bind(this)}
              >
                Close
              </button> */}
              <CreateButton
                btnName="Create"
                type="submit"
                onClick={(e) => this.createCategoryInBackend()}
              />
              <CancelIconButton
                btnName="Cancel"
                onClick={this.onModalClose.bind(this)}
              />
            </div>
          </Modal>
          <Modal
            open={this.state.openEditModal}
            title="Update Category"
            onClose={this.onEditModalClose.bind(this)}
          >
            <div className="row mb-3">
              <label
                className="col-sm-3 col-form-label"
                htmlFor="basic-icon-default-fullname"
              >
                Category Name
              </label>
              <div className="col-sm-9">
                <div className="input-group input-group-merge">
                  <span
                    id="basic-icon-default-fullname2"
                    className="input-group-text"
                  >
                    <i className="bx bx-category"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-icon-default-fullname"
                    placeholder="Category Name"
                    aria-label="Category Name"
                    aria-describedby="basic-icon-default-fullname2"
                    value={this.state.categoryName}
                    onChange={this.handleCategoryNameChange.bind(this)}
                  />
                </div>
              </div>
            </div>
            <div
              className="modal-footer"
              style={{ padding: "1.2rem 1.5rem 1.5rem" }}
            >
              {/* <PtsButton
                btnName=" Save"
                onClick={(e) => this.updateCategoryInBackend()}
              />
              <button
                type="button"
                className="btn btn-label-secondary"
                data-bs-dismiss="modal"
                onClick={this.onEditModalClose.bind(this)}
              >
                Close
              </button> */}
              <SaveButton
                btnName="Save"
                type="submit"
                onClick={(e) => this.updateCategoryInBackend()}
              />
              <CancelIconButton
                btnName="Cancel"
                onClick={this.onEditModalClose.bind(this)}
              />
            </div>
          </Modal>
          <Modal
            open={this.state.openMoveCategoryModal}
            // title="Create Similar Asset"
            onClose={this.onMoveAssetClose.bind(this)}
          >
            <MoveCategory
              categoryId={this.state.categoryId}
              // assetId={this.state.assetId}
              onSuccess={this.onMoveAssetClose.bind(this)}
            />
          </Modal>
        </>
      );
    }
  }
}

export default withRouter(TopCategory);
