import React, { Component } from 'react';
import MaterialTable from 'material-table';
import Utils from '../../provider/Utils';
import UiUtils from '../../provider/UiUtils';
import { withRouter } from "../../withRouter";
import FetchServerData from '../../provider/FetchServerData';
import { Card, CardBody } from "reactstrap";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import store from '../../redux/store';
import PtsButton from '../buttons/PtsButton';
import ApplicationStateAction from '../../redux/applicationState/ApplicationStateAction';
import MenuAction from '../../redux/menu/MenuAction';
class SupplierList extends Component {
  header = store.getState().header.header;
  supplierState = store.getState().applicationState.supplierState;
  supplierStateDetails = {};
  breadCrumb = [{ pageid: "", uiname: "Supplier List" }];

  constructor(props) {
    super(props);
    if (this.supplierState) {
      this.supplierStateDetails = Object.getOwnPropertyNames(this.supplierState);
    };

    let userMenuArray = Utils.getTopMenus();
    if (props.state) {
      for (let i = 0; i < userMenuArray.length; i++) {
        for (let j = 0; props.state.pageid && j < props.state.pageid?.length; j++) {
          if (userMenuArray[i].pageid === props.state.pageid) {
            store.dispatch(MenuAction.setActiveMenu(userMenuArray[i].id));
          }
        }
      }
    }

    if (this.supplierStateDetails.length > 0) {
      this.state = {
        initialPage: this.supplierState.initialPage,
        pageSize: this.supplierState.pageSize,
        supplierList: [],
        isPaging: false,
        render: false,
        getCreatePermission: false,
        supplierId: this.supplierState.supplierId,
      };
    } else {
      this.state = {
        initialPage: 0,
        pageSize: 10,
        supplierList: [],
        isPaging: false,
        render: false,
        getCreatePermission: false,
        supplierId: "",
      };
    };
    this.getSupplierList();
  };
  getSupplierList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/supplierms/getList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let supplierList = output.data.supplierList;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (supplierList.length > this.state.pageSize) {
          isPaging = true;
        }
        this.setState({
          render: true,
          isPaging: isPaging,
          supplierList: supplierList,
          initialPage: this.state.initialPage,
          getCreatePermission: getCreatePermission,
        });
      } else {
      };
    });
  };
  addSupplier() {
    this.props.navigate("/supplier", {
      state: {
        action: "create"
      }
    });
  };
  toSupplierDetails(e, data) {
    let supplierState = {
      supplierId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(ApplicationStateAction.setApplicationState({ supplierState }));
    this.props.navigate("/supplier", {
      state: {
        action: "view",
        supplierId: data.id,
      }
    });
  };
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  };
  render() {
    if (this.state.render === false) {
      return <div />;
    };
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="Supplier"
          name="Supplier List"
        />
        {this.state.supplierList.length > 0 ? (
          <>
            <div style={{ maxWidth: '100%' }}>
              <MaterialTable
                title=""
                onRowClick={(event, rowData) => {
                  this.toSupplierDetails(event, rowData);
                  event.stopPropagation();
                }}
                icons={UiUtils.getMaterialTableIcons()}
                columns={[
                  {
                    title: '#',
                    render: (rowData) => rowData.tableData.id + 1,
                    width: '5%',
                    align: 'left',
                    cellStyle: {
                      width: '6%',
                    },
                  },
                  {
                    title: 'Name',
                    field: 'name',
                    cellStyle: {
                      width: '20%',
                    },
                  },
                  {
                    title: 'Type',
                    field: 'type',
                    cellStyle: {
                      width: '15%',
                    },
                  },
                  {
                    title: 'GSTN',
                    field: 'gstn',
                    cellStyle: {
                      width: '20%',
                    },
                  },
                  {
                    title: 'Address',
                    field: 'address',
                    cellStyle: {
                      width: '30%',
                    },
                  },
                  {
                    title: 'Status',
                    field: 'status',
                  },
                ]}
                data={this.state.supplierList}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  initialPage: this.state.initialPage,
                  pageSize: this.state.pageSize,
                  pageSizeOptions: Utils.materialTablePageSize(),
                  paging: this.state.isPaging,
                  headerStyle: {
                    backgroundColor: '#0B6F9D',
                    fontWeight: 'bold',
                    color: "#ffff",
                    align: 'left',
                  },
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.id === this.state.supplierId ? '#FEF5E7' : '',
                    height: '3em',
                    textTransform: 'capitalize',
                  }),
                }}
                onChangePage={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
                actions={[
                  {
                    icon: () => (
                      <span className="newDoctor">
                        {' '}
                        <PtsButton btnName="Add Supplier" type="submit" />
                      </span>
                    ),
                    tooltip: 'Add Supplier',
                    isFreeAction: true,
                    onClick: (event) => this.addSupplier(),
                  },
                ]}
              />
            </div>
          </>
        ) : (
          <div className="row" style={{ marginTop: "1em" }}>
            <div className="col-md-12">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-12" style={{ padding: "2em" }}>
                      <h5>
                        <b>Supplier not created yet</b>
                      </h5>
                      <br />
                      <PtsButton
                        btnName="Add New Supplier"
                        onClick={this.addSupplier.bind(this)}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </>
    );
  };
};
export default withRouter(SupplierList);