import AddIcon from "@material-ui/icons/Add";
import React, { Component } from "react";
export class AddIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <>
        <AddIcon
          className="add"
          onClick={this.onClick.bind(this)}
          style={{
            borderRadius: "25%",
            background: "#867e7e",
            color: "white",
            fontSize: "x-large",
            width: "30px",
            minHeight: "30px",
            padding: "2px",
            cursor: "pointer",
            float: "right",
            marginRight: "3px",
          }}
        />
      </>
    );
  }
}
export default AddIconButton;
