import { CLEAR_APPLICATION_STATE, GET_APPLICATION_STATE, SET_APPLICATION_STATE } from './ApplicationStateTypes';

const initialState = {
  supplierState: {},
  customerState: {},
  userState: {},
  returnablePlanState: {},
  returnableAssetState: {},
  returnableAssetCycleState: {},
  assetCycleState: {},
  roleState: {},
  assetState: {},
  appUserState: {},
  emailTemplateState: {},
  profileState: {},
};

const ApplicationStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLICATION_STATE:
      return {
        ...state,
      };
    case SET_APPLICATION_STATE:
      return {
        ...state,
        supplierState: action.payload.supplierState,
        customerState: action.payload.customerState,
        userState: action.payload.userState,
        returnablePlanState: action.payload.returnablePlanState,
        returnableAssetState: action.payload.returnableAssetState,
        returnableAssetCycleState: action.payload.returnableAssetCycleState,
        assetCycleState: action.payload.assetCycleState,
        roleState: action.payload.roleState,
        assetState: action.payload.assetState,
        appUserState: action.payload.appUserState,
        emailTemplateState: action.payload.emailTemplateState,
        profileState: action.payload.profileState,
      };

    case CLEAR_APPLICATION_STATE:
      return {
        ...state,
        supplierState: null,
        customerState: null,
        userState: null,
        returnablePlanState: null,
        returnableAssetState: null,
        returnableAssetCycleState: null,
        assetCycleState: null,
        roleState: null,
        assetState: null,
        appUserState: null,
        emailTemplateState: null,
        profileState: null,
      };
    default:
      return state;
  }
};

export default ApplicationStateReducer;
