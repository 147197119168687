import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import store from "../../redux/store";
import { withRouter } from "../../withRouter";
import Pagination from "../assetTracking/Pagination";
import Map from "../map/Map";
import "./TrackingHistory.css";
class TrackingHistory extends Component {
  header = store.getState().header.header;
  constructor(props) {
    super(props);
    this.state = {
      assetTrackingHistoryList: [],
      render: false,
      searchText: "",
      pageNo: 0,
      showPerPage: 0,
      pageSize: 10,
      trackingMapList: [],
      search: "",
    };
    this.getAssetTrackingHistoryList();
  }
  getAssetTrackingHistoryList(pageNo, pageSize) {
    const postObject = {
      header: this.header,
      assetId: this.props.assetId,
      page: {
        searchText: this.state.search,
        pageNo: pageNo,
        pageSize: pageSize,
      },
    };

    FetchServerData.callPostService(
      "/trackingHistory/getHistoryForAsset",
      postObject
    )
      .then((output) => {
        if (output.status === "SUCCESS") {
          let trackingDetails = output.data;
          let assetHistoryList = output.data.history;
          for (let i = 0; i < assetHistoryList.length; i++) {
            assetHistoryList[i].sortDate = assetHistoryList[i].tdate;
            assetHistoryList[i].tdate = Utils.getUtcToLocalDateTime(assetHistoryList[i].tdate);
            assetHistoryList[i].tenddate = Utils.getUtcToLocalDateTime(assetHistoryList[i].tenddate);
          }
          // let trackingMapList = [];
          // trackingMapList = assetHistoryList.slice(0, 5);

          this.setState({
            render: true,
            assetTrackingHistoryList: assetHistoryList,
            pageNo: trackingDetails.page.pageNo,
            showPerPage: trackingDetails.page.pageSize,
            totalPages: trackingDetails.page.totalPages,
            totalRecords: trackingDetails.page.total,
            trackingMapList: assetHistoryList,
            assetName: trackingDetails.asset.name,
            assetUid: trackingDetails.asset.uid,
            assetCode: trackingDetails.asset.assetcode,
          });
        } else {
        }
      })
      .catch((error) => { });
  }
  // handleMaterialTablePageChange(page, pageSize) {
  //   console.log("Page:" + page);
  //   console.log("PageSize:" + pageSize);
  //   this.setState({ initialPage: page, pageSize: pageSize });
  // }
  onPaginationChange(returnProp) {
    let counter = returnProp.counter;
    let pageNo = this.state.pageNo + counter;
    let pageSize = returnProp.showPerPage;
    let buttonIndex = returnProp.buttonIndex;
    let currentPageIndex =
      typeof counter !== "undefined" ? pageNo : buttonIndex;
    let resetPageNo = returnProp.resetPageNo;
    if (resetPageNo === true) {
      currentPageIndex = 0;
    }
    this.setState({
      pageNo: currentPageIndex,
    });
    this.getAssetTrackingHistoryList(currentPageIndex, pageSize);
  }
  onSearchChange(e) {
    this.setState({ search: e.target.value });
  }
  getTableDetails() {
    let rows = [];
    if (this.state.assetTrackingHistoryList) {
      for (let index = 0; index < this.state.assetTrackingHistoryList.length; index++) {
        let element = this.state.assetTrackingHistoryList[index];
        let row = (
          <tr key={element.index}>
            <td style={{ width: "6px" }}>{this.state.pageNo * this.state.showPerPage + index + 1}</td>
            <td>{element.assetname}</td>
            <td className="assetUID">
              {/* <a
                href="#"
                onClick={this.toAssetTrakingDetails.bind(this, element.assetid)}
              > */}
              {element.assetuid}
              {/* </a> */}
            </td>
            <td>{element.assetcode}</td>
            <td>{Utils.getUtcToLocalDateTime(element.tdate)}</td>
            <td>{element.lati}</td>
            <td>{element.longi}</td>
            <td>{element.source}</td>
          </tr>
        );
        return (rows = [...rows, row]);
      }
    }
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          {" "}
          {this.state.assetTrackingHistoryList.length > 0 ? (
            <>
              <div className="row">
                <div className="col md-12">
                  <Card style={{ height: "100%", borderRadius: "20px" }}>
                    <CardBody>
                      {" "}
                      <Map markers={this.state.trackingMapList} />
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="row">
                <div className="col md-12">
                  <div className="card">
                    <div className='row justify-content-between p-3 pb-1'>
                      <div className="row col-md-12 text-start">
                        <div className="col-sm-3">
                          <div className="row pt-2">
                            <label
                              className="col-sm-5 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Asset Name
                            </label>
                            <div className="col-sm-7" id="OrganizationCol">
                              <span>{this.state.assetName}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="row pt-2">
                            <label
                              className="col-sm-5 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Asset UID
                            </label>
                            <div className="col-sm-7" id="OrganizationCol">
                              <span>{this.state.assetUid}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="row pt-2">
                            <label
                              className="col-sm-5 col-form-label fw-bold"
                              htmlFor="basic-default-name"
                              id="assetFormLabel"
                            >
                              Asset Code
                            </label>
                            <div className="col-sm-7" id="OrganizationCol">
                              <span>{this.state.assetCode}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex col-12 col-lg-4 col-xl-4">
                        <div className="col-md-12 text-start">
                          <div className="row">
                            <span className="col-form-label fw-bold" id="assetFormLabel" style={{ fontSize: "13px" }}>
                              Total Results: <span className="ps-1">{this.state.totalRecords}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex col-12 col-lg-8 col-xl-8">
                        <div className='col-12 text-end'>
                          <input
                            type="search"
                            id="gsearch"
                            placeholder="Search"
                            name="gsearch"
                            value={this.state.search}
                            onChange={this.onSearchChange.bind(this)}
                          />
                          <button
                            className='gsearchButton'
                            onClick={this.getAssetTrackingHistoryList.bind(this, this.state.pageNo, this.state.pageSize)}
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table text-start table-hover">
                        <thead
                          className="trackingThead"
                          style={{ background: "#0B6F9D", color: "#fff" }}
                        >
                          <tr>
                            <th>#</th>
                            <th>Plan Name</th>
                            <th>First Track Date</th>
                            <th>Latest Track Date</th>
                            <th>Action</th>
                            <th>Location</th>
                            <th>Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.assetTrackingHistoryList?.map(
                            (rowData, index) => (
                              <tr key={index}>
                                <td>{this.state.pageNo * this.state.showPerPage + index + 1}</td>
                                <td style={{ width: "280px" }}>{rowData.planName}</td>
                                <td style={{ width: "280px" }}>{rowData.tdate}</td>
                                <td style={{ width: "280px" }}>{rowData.tenddate}</td>
                                <td style={{ width: "150px" }}>{rowData.action}</td>
                                <td style={{ width: "520px" }}>{rowData.location ? rowData.location : ""}</td>
                                <td style={{ width: "120px" }}>{rowData.count}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    {this.state.totalRecords > this.state.pageSize &&
                      <div className="row justify-content-between pagination p-1">
                        <div className="pageSize col-4 text-start">
                          Page {this.state.pageNo + 1} of {this.state.totalPages}
                        </div>
                        <div className="col-4 text-end">
                          <Pagination
                            showPerPage={this.state.showPerPage}
                            onPaginationChange={this.onPaginationChange.bind(
                              this
                            )}
                            total={this.state.totalRecords}
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-12" style={{ padding: "2em" }}>
                        <h5>
                          <b>Tracking history not created yet</b>
                        </h5>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          )}
        </>
      );
    }
  }
}

export default withRouter(TrackingHistory);
