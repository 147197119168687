import { GET_TRACKINGHISTORY, SET_TRACKINGHISTORY } from "./TrackingHistoryTypes";

const getTrackingHistory = () => {
  return {
    type: GET_TRACKINGHISTORY,
  };
};

const setTrackingHistory = (data) => {
  return {
    type: SET_TRACKINGHISTORY,
    payload: data,
  };
};
const TrackingHistoryAction = { getTrackingHistory, setTrackingHistory };

export default TrackingHistoryAction;
