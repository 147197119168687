import { GET_ORGANIZATION, SET_ORGANIZATION } from "./OrganizationTypes";

const initialState = {
  organization: {},
};

const OrganizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION:
      return {
        ...state,
      };
    case SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    default:
      return state;
  }
};

export default OrganizationReducer;
