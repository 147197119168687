import React, { Component } from 'react';
class Pagination extends Component {
	constructor(props) {
		super(props);
		let onPaginationChange = this.props.onPaginationChange;
		this.state = {
			render: false,
			counter: typeof props.pageNo !== "undefined" ? props.pageNo + 1 : 1,
			numberOfButtons: Math.ceil(this.props.total / this.props.showPerPage),
			showPerPage: this.props.showPerPage,
			pageSize: [5, 10, 20, 50, 100],
			onPaginationChange: onPaginationChange,
		};
	};

	onButtonClick(type) {
		if (type === "prev") {
			if (this.state.counter === 1) {
				this.setState({ counter: 1 });
			} else {
				this.setState({ counter: this.state.counter - 1 });
				this.state.onPaginationChange({ "counter": -1, "showPerPage": parseInt(this.state.showPerPage) })
			};
		} else if (type === "next") {
			if (Math.ceil(this.props.total / this.state.showPerPage) === this.state.counter) {
				this.setState({ counter: this.state.counter });
			} else {
				this.setState({ counter: this.state.counter + 1 });
				this.state.onPaginationChange({ "counter": 1, "showPerPage": parseInt(this.state.showPerPage) })
			};
		};
	};
	handleShowPerPageChange(e) {
		this.setState({
			showPerPage: e.target.value,
			numberOfButtons: Math.ceil(this.props.total / e.target.value),
			counter: 1,
		})
		this.state.onPaginationChange({ "counter": 0, "showPerPage": parseInt(e.target.value), "resetPageNo": true })
	};
	onNumberButtonClick(buttonIndex) {
		this.setState({ counter: buttonIndex })
		this.state.onPaginationChange({ "buttonIndex": buttonIndex - 1, "showPerPage": parseInt(this.state.showPerPage) })
	};
	render() {
		if (this.props.total > 0 && this.props.showPerPage > 0) {
			this.state.numberOfButtons = Math.ceil(this.props.total / this.props.showPerPage);
		}
		return (
			<div className="d-flex justify-content-end p-2 col-12 rowPagination">
				<div className="pe-2">
					<select id="pageSize"
						className="select2 form-select"
						style={{ width: "80px", height: "34px" }}
						value={this.state.showPerPage}
						onChange={this.handleShowPerPageChange.bind(this)}
					>
						{this.state.pageSize.map(option => (
							<option key={option} value={option}>
								{option}
							</option>
						))};
					</select>
				</div>
				<nav aria-label="Page navigation example">
					<ul className="pagination">
						<li className="page-item">
							<a className="page-link pagenavigation" onClick={() => this.onButtonClick("prev")}>
								<span> Previous </span>
							</a>
						</li>
						{new Array(this.state.numberOfButtons).fill("").map((value, index) => {
							if (index < 4) {
								return (
									<li className={`page-item ${index + 1 === this.state.counter ? "active" : null}`} key={index}>
										<a className="page-link" onClick={this.onNumberButtonClick.bind(this, index + 1)}>
											{index + 1}
										</a>
									</li>
								);
							} else if (index > this.state.counter - 4 && index < (this.state.counter + 2)) {
								return (
									<li className={`page-item ${index + 1 === this.state.counter ? "active" : null}`} key={index}>
										<a className="page-link" onClick={this.onNumberButtonClick.bind(this, index + 1)}>
											{index + 1}
										</a>
									</li>
								);
							} else if (index > (this.state.numberOfButtons - 4)) {
								return (
									<li className={`page-item ${index + 1 === this.state.counter ? "active" : null}`} key={index}>
										<a className="page-link" onClick={this.onNumberButtonClick.bind(this, index + 1)}>
											{index + 1}
										</a>
									</li>
								);
							}
						})}
						<li className="page-item">
							<a className="page-link pagenavigation" onClick={() => this.onButtonClick("next")}>
								Next
							</a>
						</li>
					</ul>
				</nav>
			</div >
		);
	};
};
export default Pagination;