import { combineReducers } from "redux";
import AdminAppUserDataReducer from "./adminPanel/appUserData/AdminAppUserDataReducer";
import AppUserRoleReducer from "./adminPanel/appUserRole/AppUserRoleReducer";
import AdminHeaderReducer from "./adminPanel/header/AdminHeaderReducer";
import ApplicationStateReducer from "./applicationState/ApplicationStateReducer";
import AppUserReducer from "./appUser/AppUserReducer";
import AppUserDataReducer from "./appUserData/AppUserDataReducer";
import AssetReducer from "./asset/AssetReducer";
import CatalogueReducer from "./catalogue/CatalogueReducer";
import CategoryReducer from "./category/CategoryReducer";
import CodeUomListReducer from "./codeUomList/CodeUomListReducer";
import CustomerReducer from "./customer/CustomerReducer";
import FilterDetailsReducer from "./filterDetails/FilterDetailsReducer";
import HeaderReducer from "./header/HeaderReducer";
import MenuReducer from "./menu/MenuReducer";
import OrganizationReducer from "./organization/OrganizationReducer";
import ReturnablePlanReducer from "./returnablePlan/ReturnablePlanReducer";
import StaticDataReducer from "./staticData/StaticDataReducer";
import TackingHistoryReducer from "./trackingHistory/TrackingHistoryReducer";
import UserRoleReducer from "./userRole/UserRoleReducer";
import AssetStaticDataReducer from "./assetStaticData/AssetStaticDataReducer";
const appReducer = combineReducers({
  header: HeaderReducer,
  organization: OrganizationReducer,
  staticData: StaticDataReducer,
  Catalogue: CatalogueReducer,
  category: CategoryReducer,
  applicationState: ApplicationStateReducer,
  userRole: UserRoleReducer,
  appUser: AppUserReducer,
  trackingHistory: TackingHistoryReducer,
  codeUomList: CodeUomListReducer,
  menus: MenuReducer,
  customer: CustomerReducer,
  asset: AssetReducer,
  appUserData: AppUserDataReducer,
  returnablePlan: ReturnablePlanReducer,
  filterDetails: FilterDetailsReducer,
  adminUserDetails: AdminAppUserDataReducer,
  adminHeader: AdminHeaderReducer,
  appUserRole: AppUserRoleReducer,
  assetStaticData: AssetStaticDataReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_ACTION" || action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
