import React, { Component } from 'react';
// import MaterialTable from '@material-table/core';
import MaterialTable from 'material-table'
import UiUtils from '../../provider/UiUtils';
import { withRouter } from "../../withRouter";
import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import FetchServerData from '../../provider/FetchServerData';
import store from '../../redux/store';
import PtsButton from '../buttons/PtsButton';
import ApplicationStateAction from '../../redux/applicationState/ApplicationStateAction';
import UserRoleAction from '../../redux/userRole/UserRoleAction';
import Utils from '../../provider/Utils';

class UserManagementList extends Component {
  header = store.getState().header.header;
  userState = store.getState().applicationState.userState;
  userStateDetails = {};
  breadCrumb = [{ pageid: "", uiname: "User List" }];
  constructor() {
    super();
    if (this.userState) {
      this.userStateDetails = Object.getOwnPropertyNames(this.userState);
    }
    if (this.userStateDetails.length > 0) {
      this.state = {
        initialPage: this.userState.initialPage,
        pageSize: this.userState.pageSize,
        userList: [],
        roleList: [],
        isPaging: false,
        render: false,
        getCreatePermission: false,
        userId: this.userState.userId
      };
    } else {
      this.state = {
        initialPage: 0,
        pageSize: 10,
        userList: [],
        roleList: [],
        isPaging: false,
        render: false,
        userId: "",
      };
    }
    this.getUserList();
    this.getUserProfileList();
  }

  getUserList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/appuserms/getList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let userList = output.data.appusers;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (userList.length > this.state.pageSize) {
          isPaging = true;
        }
        this.setState({
          render: true,
          isPaging: isPaging,
          userList: userList,
          initialPage: this.state.initialPage,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }
  async getUserProfileList() {
    const postObject = {
      header: this.header,
    };
    await FetchServerData.callPostService('/profilems/getList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let profileList = output.data.profileList;
        store.dispatch(UserRoleAction.setUserRole(profileList));
        this.setState({
          render: true,
          profileList: profileList,
        });
      } else {
      }
    });
  }

  async addUser() {
    await this.getUserProfileList();
    this.props.navigate("/user", {
      state: {
        action: "create",
        profileList: this.state.profileList,
      },
    });
  };

  toUserDetails(e, data) {
    let userState = {
      userId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(ApplicationStateAction.setApplicationState({ userState }));
    this.props.navigate("/user", {
      state: {
        action: "view",
        userId: data.id,
      },
    });
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    }
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="User"
          name="User List"
        />
        <div style={{ maxWidth: '100%' }}>
          <MaterialTable
            title=""
            onRowClick={(event, rowData) => {
              this.toUserDetails(event, rowData);
              event.stopPropagation();
            }}
            icons={UiUtils.getMaterialTableIcons()}
            localization={{
              body: {
                emptyDataSourceMessage: "Please add user",
              },
            }}
            columns={[
              {
                title: '#',
                render: (rowData) => rowData.tableData.id + 1,
                width: '5%',
                align: 'left',
                cellStyle: {
                  width: '6%',
                },
              },
              {
                title: 'Name',
                field: 'name',
                cellStyle: {
                  width: '20%',
                },
              },
              {
                title: 'Email',
                field: 'email',
                cellStyle: {
                  width: '20%',
                },
              },
              {
                title: 'Phone',
                field: 'phone',
                cellStyle: {
                  width: '20%',
                },
              },
              {
                title: 'Profile',
                field: 'profile',
                cellStyle: {
                  width: '20%',
                },
              },
              {
                title: 'Status',
                field: 'status',
                filtering: false,

              },
            ]}
            data={this.state.userList}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              initialPage: this.state.initialPage,
              pageSize: this.state.pageSize,
              pageSizeOptions: Utils.materialTablePageSize(),
              paging: this.state.isPaging,
              headerStyle: {
                backgroundColor: '#0B6F9D',
                fontWeight: 'bold',
                color: "#ffff",
                align: 'left',
              },
              rowStyle: (rowData) => ({
                backgroundColor: rowData.id === this.state.userId ? '#FEF5E7' : '',
                height: '3em',
                textTransform: 'capitalize',
              }),
            }}
            onChangePage={(page, pageSize) => {
              this.handleMaterialTablePageChange(page, pageSize);
            }}
            actions={[
              {
                icon: () => (
                  this.state.getCreatePermission &&
                  <span className="newDoctor">
                    {' '}
                    <PtsButton btnName="Add User" type="submit" />
                  </span>
                ),
                tooltip: 'Add User',
                isFreeAction: true,
                onClick: (event) => this.addUser(),
              },
            ]}
          />
        </div>
      </>
    )
  }
}
export default withRouter(UserManagementList);