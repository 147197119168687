import {
  GET_CODE_UOM_List,
  SET_CODE_UOM_List,
  CLEAR_CODE_UOM_List,
} from "./CodeUomListType";

const getCodeUomList = () => {
  return {
    type: GET_CODE_UOM_List,
  };
};
const setCodeUomList = (data) => {
  return {
    type: SET_CODE_UOM_List,
    payload: data,
  };
};
const clearCodeUomList = (data) => {
  return {
    type: CLEAR_CODE_UOM_List,
    payload: data,
  };
};
const CodeUomListAction = {
  getCodeUomList,
  setCodeUomList,
  clearCodeUomList,
};
export default CodeUomListAction;
