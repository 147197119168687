import { TextField } from "@material-ui/core";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "../../withRouter";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Col, Row } from "reactstrap";
import logo from "../../assets/images/AssetTrackifierLogo.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FetchServerData from "../../provider/FetchServerData";
import GooglePlayStore from "../../assets/images/googlePlayStore.png";
import "./SignInEmail.css";
import StaticDataAction from "../../redux/staticData/StaticDataAction";
import HeaderAction from "../../redux/header/HeaderAction";
import CatalogueAction from "../../redux/catalogue/CatalogueAction";
import ApplicationStateAction from "../../redux/applicationState/ApplicationStateAction";
import OrganizationAction from "../../redux/organization/OrganizationAction";
import MenuAction from "../../redux/menu/MenuAction";
import UserRoleAction from "../../redux/userRole/UserRoleAction";
import CodeUomListAction from "../../redux/codeUomList/CodeUomListAction";
import AppUserDataAction from "../../redux/appUserData/AppUserDataAction";
import CustomerAction from "../../redux/customer/CustomerAction";
import CategoryAction from "../../redux/category/CategoryAction";
import AssetAction from "../../redux/asset/AssetAction";
import TrackingHistoryAction from "../../redux/trackingHistory/TrackingHistoryAction";
import ReturnablePlanAction from "../../redux/returnablePlan/ReturnablePlanAction";
import FilterDetailsAction from "../../redux/filterDetails/FilterDetailsAction";
import store from "../../redux/store";

export class SignInEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      render: false,
    };
    this.isMobile();
  }

  isMobile() {
    // credit to Timothy Huang for this regex test:
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if (
      / Android | webOS | iPhone | iPad | iPod | BlackBerry | IEMobile | Opera Mini /i.test(
        navigator.userAgent
      )
    ) {
      // is mobile..

      return true;
    } else {
      return false;
    }
  }

  getTenantList() {
    const postObject = {
      header: {
        email: this.state.email,
      },
    };
    FetchServerData.callPostService("/tntms/getList", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let tenantList = output.data;
          store.dispatch(StaticDataAction.setStaticData({}));
          store.dispatch(HeaderAction.setHeader({}));
          store.dispatch(CatalogueAction.setCatalogue({}));
          store.dispatch(ApplicationStateAction.setApplicationState({}));
          store.dispatch(OrganizationAction.setOrganization({}));
          store.dispatch(MenuAction.setMenu({}));
          store.dispatch(UserRoleAction.setUserRole({}));
          store.dispatch(CodeUomListAction.setCodeUomList({}));
          store.dispatch(AppUserDataAction.setUserData({}));
          store.dispatch(CustomerAction.setCustomer({}));
          store.dispatch(CategoryAction.setCategory({}));
          store.dispatch(AssetAction.setAsset({}));
          store.dispatch(TrackingHistoryAction.setTrackingHistory({}));
          store.dispatch(ReturnablePlanAction.setReturnablePlan({}));
          store.dispatch(FilterDetailsAction.setFilterDetails({}));
          this.props.navigate("/signin", {
            state: {
              render: true,
              email: this.state.email,
              tenantList: tenantList,
            },
          });
        } else {
        }
      }
    );
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  showMobileIcon() {
    return (
      <>
        <a
          target="_blank"
          rel="noopener"
          href="https://play.google.com/store/apps/details?id=com.pts.assettrak"
          style={{ color: "#fff", cursor: "pointer" }}
        >
          <img
            src={GooglePlayStore}
            alt=""
            style={{ height: "3.5em", width: "9em" }}
          />
        </a>
      </>
    );
  }

  render() {
    return (
      <>
        <div className="authentication-wrapper authentication-cover">
          <div className="authentication-inner row m-0">
            <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center leftBackground ">
              <div className="mx-auto">
                <div className="logoBackground">
                  <img
                    src={logo}
                    className="img-fluid logoImage"
                    alt=""
                    data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                    data-app-light-img="illustrations/girl-with-laptop-light.png"
                  />
                </div>
                <h2 className="logoHeding">AssetTrackifier</h2>
              </div>
            </div>
            <div className="d-flex col-12 col-lg-6 col-xl-6 align-items-center authentication-bg p-sm-5 p-4">
              <div className="w-px-400 mx-auto">
                {this.isMobile() === true && (
                  <Row>
                    <Col md={12} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "17px" }}>
                        <b>Use mobile apps for better User experience</b>{" "}
                        &nbsp;&nbsp;
                        {this.showMobileIcon()}{" "}
                      </p>
                      {/* {this.showMobileIcon()} */}
                    </Col>
                  </Row>
                )}
                <ValidatorForm
                  className="text-start"
                  ref="form"
                  onSubmit={this.getTenantList.bind(this)}
                >
                  <div className="signinHeding">Sign In</div>
                  <div className="mb-3 mt-4">
                    <label
                      htmlFor="email"
                      className="form-label"
                      id="signupFormLabel"
                    >
                      EMAIL ID <span className="asterisk">*</span>
                    </label>
                    <TextField
                      id="email"
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      placeholder="Enter Email *"
                      type="email"
                      value={this.state.email}
                      onChange={this.handleEmailChange.bind(this)}
                      validators={["required", "isEmail"]}
                      errormessages={[
                        "This field is required",
                        "Email id is not valid",
                      ]}
                    />
                  </div>
                  <NavLink
                    to="/forgotPasswordEmail"
                    style={{ float: "right", marginBottom: "10px" }}
                  >
                    <span>Forgot Password?</span>
                  </NavLink>
                  <button className="goButton" type="submit">
                    {" "}
                    Go{" "}
                  </button>
                  <div className="d-flex flex-row pt-3">
                    <NavLink className="pt-1" to="/">
                      <ArrowBackIosNewIcon />
                      Back Home
                    </NavLink>
                    <p className="ms-5 ps-4 text-end">
                      <span>Do not have an account?</span>&nbsp;
                      <NavLink to="/signup">
                        <span>Sign Up</span>
                      </NavLink>
                    </p>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SignInEmail);
