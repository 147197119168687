import React, { Component } from 'react'
import { withRouter } from "../../withRouter";
import FetchServerData from '../../provider/FetchServerData';
import store from '../../redux/store';
import Pagination from '../assetTracking/Pagination';
import Utils from '../../provider/Utils';

class AssetCycleAuditLog extends Component {
	header = store.getState().header.header;
	constructor(props) {
		super(props);
		let assetCycleId = "";
		if (props.state) {
			assetCycleId = props.state.assetCycleId;
		};
		this.state = {
			render: false,
			searchText: "",
			pageNo: 0,
			showPerPage: 0,
			pageSize: 10,
			auditLogDetailsList: [],
			assetCycleId: assetCycleId,
		};
		this.getTrakingHistoryList();
	};
	getTrakingHistoryList(pageNo, pageSize) {
		const postObject = {
			header: this.header,
			assetCycle: {
				id: this.state.assetCycleId
			},
			page: {
				searchText: this.state.search,
				pageNo: pageNo,
				pageSize: pageSize,
			}
		};
		FetchServerData.callPostService('/assetCycleAuditlog/getForCycle', postObject).then((output) => {
			if (output.status === 'SUCCESS') {
				let auditLogDetails = output.data;
				this.setState({
					render: true,
					auditLogDetailsList: auditLogDetails.logs,
					pageNo: auditLogDetails.page.pageNo,
					showPerPage: auditLogDetails.page.pageSize,
					totalPages: auditLogDetails.page.totalPages,
					totalRecords: auditLogDetails.page.total,
				});
			} else {
			};
		});
	};
	onPaginationChange(returnProp) {
		let counter = returnProp.counter;
		let pageNo = this.state.pageNo + counter;
		let pageSize = returnProp.showPerPage;
		let buttonIndex = returnProp.buttonIndex;
		let currentPageIndex = typeof counter !== 'undefined' ? pageNo : buttonIndex;
		let resetPageNo = returnProp.resetPageNo;
		if (resetPageNo === true) {
			currentPageIndex = 0
		};
		this.setState({
			pageNo: currentPageIndex,
		});
		// console.log("showPerPage", returnProp.showPerPage, "pageNo", this.state.pageNo + counter)
		this.getTrakingHistoryList(currentPageIndex, pageSize);
	};
	onSearchChange(e) {
		this.setState({ search: e.target.value });
	};
	toAssetTrakingDetails(assetId) {
		this.props.navigate("/viewAssetTracking", {
			state: {
				action: "view",
				assetId: assetId,
				pageNo: this.state.pageNo,
				pageSize: this.state.showPerPage,
				searchText: this.state.searchText,
			}
		});
	};
	render() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<>
					<div className="row">
						<div className="col md-12">
							<div className="card">
								<div className='row justify-content-between p-3'>
									<div className="d-flex col-12 col-lg-4 col-xl-4">
										<div className="col-12 text-start totalResults">
											<span>Total Results: {this.state.totalRecords}</span>
										</div>
									</div>
									<div className="d-flex col-12 col-lg-8 col-xl-8">
										<div className='col-12 text-end'>
											<input
												type="search"
												id="gsearch"
												placeholder="Search"
												name="gsearch"
												value={this.state.search}
												onChange={this.onSearchChange.bind(this)}
											/>
											<button
												className='gsearchButton'
												onClick={this.getTrakingHistoryList.bind(this, this.state.pageNo, this.state.pageSize)}
											>
												<i className="fa fa-search" aria-hidden="true"></i>
											</button>
										</div>
									</div>
								</div>
								<div className="table-responsive">
									<table className="table text-start table-hover">
										<thead className="assetCycleThead">
											<tr>
												<th scope="col fw-bold ">#</th>
												<th scope="col fw-bold">User Name</th>
												<th scope="col fw-bold">Date</th>
												<th scope="col fw-bold">Action</th>
											</tr>
										</thead>
										<tbody>
											{this.state.auditLogDetailsList?.map((rowData, index) => (
												<tr key={index}>
													<td style={{ width: "6px" }}>{this.state.pageNo * this.state.showPerPage + index + 1}</td>
													<td>{rowData.username}</td>
													<td>{Utils.getUtcToLocalDateTime(rowData.date)}</td>
													<td>{rowData.action}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								{this.state.totalRecords >= this.state.pageSize &&
									<div className="row justify-content-between pagination p-1">
										<div className="pageSize col-4 text-start">
											Page {this.state.pageNo + 1} of {this.state.totalPages}
										</div>
										<div className='col-4 text-end'>
											<Pagination
												showPerPage={this.state.showPerPage}
												onPaginationChange={this.onPaginationChange.bind(this)}
												total={this.state.totalRecords}
											/>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				</>
			);
		};
	};
};
export default withRouter(AssetCycleAuditLog);