import React, { useState } from 'react';
import locationpin from "../../assets/images/locationpin.png"
const Marker = (props) => {
  let marker = props.marker;
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
    <>
      <img src={locationpin} alt="" style={{ height: "30px", width: "30px", color: "red", cursor: "point" }} onClick={toggleClass} />
      <div className="body" style={{ borderRadius: '15px', minWidth: '17em' }}>
        <div className="message">
          {isActive ?
            <div className="card">
              <div className="card-body text-start p-2">
                {marker.assetuid && (
                  <>
                    <span style={{ fontWeight: 'bold' }}>{marker.assetuid}</span>
                    <br></br>
                  </>
                )}
                {marker.assetname && (
                  <>
                    <span style={{ fontWeight: 'bold' }}>{marker.assetname}</span>
                    <br></br>
                  </>
                )}
                {marker.assetcode && (
                  <>
                    <span style={{ fontWeight: 'bold' }}>{marker.assetcode}</span>
                    <br></br>
                  </>
                )}
                {marker.assetstatus && (
                  <>
                    <span style={{ fontWeight: 'bold' }}>{marker.location}</span>
                  </>
                )}
              </div>
            </div> : ""
          }
        </div>
      </div>
    </>
  );
};

export default Marker;
