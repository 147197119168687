import Tooltip from "@material-ui/core/Tooltip";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import React, { Component } from "react";

export class TreeIconButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      domainObject: props.onClick,
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        className="trails"
        onClick={this.onClick.bind(this)}
        style={{
          cursor: "pointer",
          fontSize: "x-larger",
          marginRight: "0.5em",
          float: "right",
        }}
      >
        <Tooltip title="Appointment Trails">
          <AccountTreeIcon
            style={{
              borderRadius: "25%",
              background: "#867e7e",
              color: "white",
              padding: "3px",
              fontSize: "x-large",
              width: "30px",
              minHeight: "30px",
            }}
          />
        </Tooltip>
      </span>
    );
  }
}
export default TreeIconButton;
