import React, { Component } from 'react'
import { withRouter } from "../../withRouter";
import AddressWithTimezone from '../addressWithTimezone/AddressWithTimezone';
import { ValidatorForm } from 'react-material-ui-form-validator';
// import { MenuWithBreadScrum } from "../menu/MenuWithBreadScrum";
import { Autocomplete } from '@mui/material';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Col, Modal, ModalFooter, Row } from 'reactstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import FetchServerData from '../../provider/FetchServerData';
import Utils from '../../provider/Utils';
import Button from '@material-ui/core/Button';
import BackButton from '../buttons/BackButton';
import DeleteIconButton from '../buttons/DeleteIconButton';
import EditIconButton from '../buttons/EditIconButton';
import SaveButton from '../buttons/SaveButton';
import CancelIconButton from '../buttons/CancelIconButton';
import TextField from "@material-ui/core/TextField";
import PtsAlert from '../ptsAlert/PtsAlert';
import UiUtils from '../../provider/UiUtils';
import MaterialTable from "material-table";
import store from '../../redux/store';
import "./AssetCycle.css"
import AssetCycleAuditLog from './AssetCycleAuditLog';
import AssetCycleTrakingHistory from './AssetCycleTrakingHistory';
import ApplicationStateAction from '../../redux/applicationState/ApplicationStateAction';
import StartCycleButton from '../buttons/StartCycleButton';
import MarkCompletedButton from '../buttons/MarkCompletedButton';
import MenuWithBreadScrum from "../menu/MenuWithBreadScrum";

class AssetCycle extends Component {
  header = store.getState().header.header;
  customerList = store.getState().customer.customerList;
  returnablePlanList = store.getState().returnablePlan.returnablePlanList;
  sourceAddress = store.getState().organization.organization.organizationAddress;
  sourceAddressRef = React.createRef();
  destinationAddressRef = React.createRef();

  constructor(props) {
    super(props);
    let action = "";
    let assetCycleId = "";
    let pageId = "";

    let assetCycleBreadCrumb = [
      { pageid: "/assetCycleList", uiname: "Asset Cycle List" },
      { pageid: "", uiname: "Asset Cycle Details" }
    ];
    let returnablePlanBreadCrumb = [
      { pageid: "/returnablePlanList", uiname: "Returnable Plan List" },
      { pageid: "/returnablePlan", uiname: "Returnable Plan Details" },
      { pageid: "", uiname: "Asset View" },
    ];

    if (!action && props.state) {
      action = props.state.action;
      assetCycleId = props.state.assetCycleId;
      pageId = props.state.pageId;
    }
    if (pageId === "returnablePlanDetails") {
      this.breadCrumb = returnablePlanBreadCrumb;
    } else {
      this.breadCrumb = assetCycleBreadCrumb;
    }
    if (action && (Utils.equalsIgnoreCase(action, "view" || Utils.equalsIgnoreCase(action, "update")))) {
      this.getAssetCycleDetails(assetCycleId);
    };
    this.state = {
      action: action,
      assetCycleId: assetCycleId,
      pageId: pageId,
      name: "",
      customerId: "",
      startDate: new Date(),
      endDate: new Date(),
      description: "",
      returnablePlanId: "",
      assetId: "",
      customerLocationId: "",
      cycletime: "",
      cycletimeFrequency: "",
      customerCycletime: "",
      customerCycletimeFrequency: "",
      sourceAddress: this.sourceAddress,
      destinationAddress: {},
      customer: null,
      asset: null,
      returnablePlan: null,
      customerLocation: null,
      selectedTab: 0,
      initialPage: 0,
      pageSize: 10,
      render: false,
      error: false,
      assetList: [],
      customerLocationList: [],
    }
  }

  getAssetCycleDetails(assetCycleId) {
    const postObject = {
      header: this.header,
      assetCycle: {
        id: assetCycleId,
      },
    };

    FetchServerData.callPostService('/assetCyclems/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let getPermission = Utils.getRolePermission(output.data.privilegeList);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let assetCycleData = output.data.assetCycle;
          this.setState({
            render: true,
            action: "view",
            id: assetCycleData.id,
            name: assetCycleData.name,
            startDate: new Date(assetCycleData.startDate),
            endDate: assetCycleData.endDate,
            cycleStatus: assetCycleData.cycleStatus.status,
            customer: assetCycleData.customer,
            customerLocationList: assetCycleData.customer.locations,
            customerId: assetCycleData.customer.id,
            sourceAddress: assetCycleData.sourceAddress,
            customerLocation: assetCycleData.customerLocation,
            returnablePlanId: assetCycleData.cycleplanid,
            assetId: assetCycleData.asset.id,
            returnablePlan: assetCycleData.plan,
            description: assetCycleData.description,
            asset: assetCycleData.asset,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
            canMarkStarted: assetCycleData.canMarkStarted,
            canMarkCompleted: assetCycleData.canMarkCompleted,
            cycletime: assetCycleData.cycletime,
            cycletimeFrequency: assetCycleData.cycletimeFrequency,
            holdingPeriod: assetCycleData.holdingPeriod,
            holdingPeriodFrequency: assetCycleData.holdingPeriodFrequency,
            trackingHistoryStatus: assetCycleData.trackingHistoryStatus,
            cyclePlanName: assetCycleData.plan.name,
          });
        } else {
        };
      });
  };

  setKey = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleStartDateChange(date) {
    this.setState({ startDate: date });
  }

  handleEndDateChange(date) {
    if (this.state.startDate > date) {
      this.setState({ endDate: date, error: true });
    } else {
      this.setState({ endDate: date, error: false });
    }
  }

  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handleSourceAddressChange(address) {
    this.setState({ sourceAddress: address })
  }

  handleDestinationAddressChange(address) {
    this.setState({ destinationAddress: address })
  }

  handleAssetChange(selectedAsset) {
    this.setState({ asset: selectedAsset, assetId: selectedAsset.id });
  }
  handleCustomerLocationChange(selectedCustomerLocation) {
    this.setState({
      customerLocation: selectedCustomerLocation,
      customerLocationId: selectedCustomerLocation.id
    });
  }
  handleCustomerTypeChange(selectedCustomer) {
    this.getLoactionListForCustomer(selectedCustomer.id);
    this.setState({ customer: selectedCustomer, customerId: selectedCustomer.id });
  }

  getLoactionListForCustomer(customerId) {
    const postObject = {
      header: this.header,
      customerLocation: {
        customer: {
          id: customerId,
        }
      }
    };
    FetchServerData.callPostService("/costomerLocationms/getForCustomer", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let customerLocationList = output.data.customerLocationList;
          this.setState({
            customerLocationList: customerLocationList,
          });
        } else {
        }
      }
    );
  }

  handleReturnablePlanTypeChange(selectedReturnablePlan) {
    this.getListForPlan(selectedReturnablePlan.id);
    this.setState({ returnablePlan: selectedReturnablePlan, returnablePlanId: selectedReturnablePlan.id });
  }


  getListForPlan(returnablePlanId) {
    const postObject = {
      header: this.header,
      assetCycle: {
        cycleplanid: returnablePlanId,
      }
    };
    FetchServerData.callPostService("/assetCyclems/getListForPlan", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          let assetList = output.data.assetList;
          this.setState({
            assetList: assetList,
          });
        } else {
        }
      }
    );
  }

  createAssetCycleToBackend() {
    const postObject = {
      header: this.header,
      assetCycle: {
        name: this.state.name,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        description: this.state.description,
        cycleplanid: this.state.returnablePlanId,
        assetid: this.state.assetId,
        customer: {
          id: this.state.customerId,
        },
        sourceAddress: {
          line: this.sourceAddressRef.current.state.line,
          state: {
            name: this.sourceAddressRef.current.state.state.name,
            code: this.sourceAddressRef.current.state.state.code,
            id: this.sourceAddressRef.current.state.state.id,
            country: this.sourceAddressRef.current.state.country,
          },
          city: this.sourceAddressRef.current.state.city,
          zipcode: this.sourceAddressRef.current.state.zipCode,
        },
        customerLocation: {
          id: this.state.customerLocationId,
        }
      }
    }
    FetchServerData.callPostService('/assetCyclems/create', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let assetCycleData = output.data.assetCycle;
        this.props.navigate("/viewassetCycle", {
          state: {
            action: "view",
            assetCycleId: assetCycleData.id,
            title: 'Asset Cycle Details',
          },
        });
        this.props.navigate(0);
      } else {
      }
    });
  }

  createCancel() {
    this.props.navigate("/assetCycleList");
  }

  viewCancel() {
    if (this.state.pageId === "returnablePlanDetails") {
      this.props.navigate("/returnablePlan", {
        state: {
          action: "view",
          returnableCyclePlanId: this.state.returnablePlanId,
          assetCycleId: this.state.assetCycleId
        },
      });
    } else if (this.state.pageId === "assetCycleDetails") {
      this.props.navigate("/asset", {
        state: {
          assetId: this.state.assetId,
          action: "view",
        },
      })
    } else {
      this.props.navigate("/assetCycleList");
    };
  }

  editCancel() {
    this.getAssetCycleDetails(this.state.id);
    // this.setState({
    //   action: "view",
    // })
  }

  editAssetCycleDetails(e) {
    this.getLoactionListForCustomer(this.state.customerId);
    this.setState({ action: "edit", assetCycleId: this.state.id });
  }

  updateAssetCycleToBackend(e) {
    const postObject = {
      header: this.header,
      assetCycle: {
        name: this.state.name,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        description: this.state.description,
        cycleplanid: this.state.returnablePlanId,
        assetid: this.state.assetId,
        id: this.state.id,
        customer: {
          id: this.state.customerId,
        },
        sourceAddress: {
          id: this.sourceAddressRef.current.state.id,
          line: this.sourceAddressRef.current.state.line,
          state: {
            name: this.sourceAddressRef.current.state.state.name,
            code: this.sourceAddressRef.current.state.state.code,
            id: this.sourceAddressRef.current.state.state.id,
            country: this.sourceAddressRef.current.state.country,
          },
          city: this.sourceAddressRef.current.state.city,
          zipcode: this.sourceAddressRef.current.state.zipCode,
          latitude: this.sourceAddressRef.current.state.latitude,
          longitude: this.sourceAddressRef.current.state.longitude,
        },
        customerLocation: {
          id: this.state.customerLocationId,
        }
      }
    }
    FetchServerData.callPostService('/assetCyclems/update', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let assetCycleData = output.data.assetCycle;
        this.setState({
          action: "view",
          render: true,
          id: assetCycleData.id,
          name: assetCycleData.name,
          startDate: new Date(assetCycleData.startDate),
          endDate: new Date(assetCycleData.endDate),
          description: assetCycleData.description,
          cycleStatus: assetCycleData.cycleStatus.status,
          customer: assetCycleData.customer,
          customerId: assetCycleData.customer.id,
          cycleAssets: assetCycleData.cycleAssets,
          sourceAddress: assetCycleData.sourceAddress,
          customerLocation: assetCycleData.customerLocation,
          cycletime: assetCycleData.cycletime,
          cycletimeFrequency: assetCycleData.cycletimeFrequency,
          holdingPeriod: assetCycleData.holdingPeriod,
          holdingPeriodFrequency: assetCycleData.holdingPeriodFrequency,
        });
      } else {
      }
    });
  }

  deleteAssetCycleToBackend() {
    const postObject = {
      header: this.header,
      assetCycle: {
        id: this.state.assetCycleId,
      },
    };
    FetchServerData.callPostService('/assetCyclems/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.props.navigate('/assetCycleList');
        } else {
        }
      });
  }

  getStartCycle() {
    if (this.state.customerLocation === null) {
      PtsAlert.error("Please set the destination address")
      return;
    }
    const postObject = {
      header: this.header,
      assetCycle: {
        id: this.state.assetCycleId,
      }
    };
    FetchServerData.callPostService("/assetCyclems/startCycle", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let assetCycle = output.data.assetCycle;
          let assetIds = []
          assetIds.push(assetCycle.id);
          let assetCycleState = {
            initialPage: this.state.initialPage,
            pageSize: this.state.pageSize,
            selectedTab: 1,
            assetCycleId: assetIds,
          }
          store.dispatch(ApplicationStateAction.setApplicationState({ assetCycleState }));

          this.props.navigate("/assetCycleList", {
            state: {
              assetCycleIds: assetIds,
            },
          });
        } else {
        }
      }
    );
  }

  getCompletedCycle() {
    const postObject = {
      header: this.header,
      assetCycle: {
        id: this.state.assetCycleId,
      }
    };
    FetchServerData.callPostService("/assetCyclems/markComplete", postObject).then(
      (output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          let assetCycle = output.data.assetCycle;
          let assetIds = []
          assetIds.push(assetCycle.id);
          let assetCycleState = {
            initialPage: this.state.initialPage,
            pageSize: this.state.pageSize,
            selectedTab: 2,
            assetCycleId: assetIds,
          }
          store.dispatch(ApplicationStateAction.setApplicationState({ assetCycleState }));

          this.props.navigate("/assetCycleList", {
            state: {
              assetCycleIds: assetIds,
            },
          });
        } else {
        }
      }
    );
  }

  toAssetDetails() {
    this.props.navigate("/asset", {
      state: {
        action: "view",
        assetId: this.state.asset.id,
        assetCycleId: this.state.assetCycleId,
        pageId: "assetCycleDetails",
      }
    });
  }
  toPlanDetails() {
    this.props.navigate("/returnablePlan", {
      state: {
        action: "view",
        returnableCyclePlanId: this.state.returnablePlanId,
        assetCycleId: this.state.assetCycleId,
        pageId: "assetCycleDetails",
      }
    });
  }
  render() {
    if (this.state.action === "create") {
      return this.createAssetCycle();
    } else if (this.state.action === "view") {
      return this.viewAssetCycle();
    } else if (this.state.action === "edit") {
      return this.editAssetCycle();
    }
  }
  createAssetCycle() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="Asset Cycle"
          name="Create Asset Cycle"
        />
        <div className="row">
          <ValidatorForm className="text-start" ref="form" onSubmit={this.createAssetCycleToBackend.bind(this)}>
            <div className="row">
              <div className="col-md-12">
                <div className="card " id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Asset Cycle Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label" htmlFor="collapsible-supplierType">
                          Customer <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="customerTypeList"
                          options={this.customerList}
                          value={this.state.customer}
                          defaultValue={this.state.customer}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCustomerTypeChange(value);
                          }}
                          inputprops={{ style: { textTransform: "capitalize" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Customer"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label" htmlFor="collapsible-supplierType">
                          Returnable Plan<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="customerTypeList"
                          options={this.returnablePlanList}
                          value={this.state.returnablePlan}
                          defaultValue={this.state.returnablePlan}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleReturnablePlanTypeChange(value);
                          }}
                          inputprops={{ style: { textTransform: "capitalize" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Returnable Plan"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label" htmlFor="collapsible-supplierType">
                          Asset <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="customerTypeList"
                          options={this.state.assetList}
                          value={this.state.asset}
                          defaultValue={this.state.asset}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleAssetChange(value);
                          }}
                          inputprops={{ style: { textTransform: "capitalize" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Asset"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label" htmlFor="collapsible-fullname">
                          Start Date
                        </label>
                        <DatePicker
                          style={{ overflowX: "auto", overflowY: "auto" }}
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange.bind(this)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Start Date*"
                          required
                          className="startDateTimeInput"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12" id="assetFormLabel">
                        <label className="form-label" htmlFor="collapsible-description">
                          Description
                        </label>
                        <textarea
                          name="collapsible-address"
                          className="form-control"
                          style={{ color: 'black', font: "inherit", fontSize: "16px " }}
                          id="collapsible-address"
                          rows="4"
                          placeholder="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Source Address
                    </h5>
                  </div>
                  <div className="card-body">
                    <AddressWithTimezone
                      ref={this.sourceAddressRef}
                      onChange={this.handleSourceAddressChange.bind(this)}
                      data={this.state.sourceAddress}
                      action="edit"
                    />
                  </div>
                  <hr></hr>
                  <div className="col-md-12">
                    <div
                      className="card-header d-flex align-items-center justify-content-between"
                      id="assetCardHeader"
                    >
                      <h5 className="mb-0" id="headerLabel">
                        Destination Address
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label" htmlFor="collapsible-supplierType">
                          Loacation <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="customerTypeList"
                          options={this.state.customerLocationList}
                          value={this.state.customerLocation}
                          defaultValue={this.state.customerLocation}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCustomerLocationChange(value);
                          }}
                          inputprops={{ style: { textTransform: "capitalize" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Location"
                              variant="outlined"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between custBut">
              <div className="col-sm-12 mt-3 text-center">
                <SaveButton id="saveButton" type="submit"> Save </SaveButton>
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.createCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </>
    )
  }

  viewAssetCycle() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let cycletime = this.state.cycletime !== null ? this.state.cycletime : "";
      let cycletimeFrequency = this.state.cycletimeFrequency !== null ? this.state.cycletimeFrequency.frequency : "";

      // let endDate = new Date(this.state.startDate);
      // if (cycletimeFrequency === "Days") {
      //   endDate.setDate(endDate.getDate() + parseFloat(cycletime))
      // } else if (cycletimeFrequency === "Hours") {
      //   endDate.setMinutes(endDate.getMinutes() + parseFloat(cycletime) * 60)
      // }
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            navigate={this.props.navigate}
            domainObject={this.state.asset.name}
            name="Asset Cycle Details"
            pageId="AssetView"
            assetCycleId={this.state.assetCycleId}
            returnablePlanId={this.state.returnablePlanId}
          />
          <Row>
            <Col
              md={12}
            >
              <Tabs
                defaultIndex={this.state.selectedTab}
                onSelect={(k) => this.setKey(k)}
                className="assetCycleTabs"
              >
                <TabList style={{ listStyle: "none" }} className="text-start d-flex justify-content-start ps-0">
                  <Tab className="assetCycleTab hoverTab" > Asset Cycle Details</Tab>
                  <Tab className="assetCycleTab hoverTab" > Asset Cycle Tracking History</Tab>
                  <Tab className="assetCycleTab hoverTab" > Asset Cycle Audit Log</Tab>
                </TabList>
                <TabPanel>
                  <>
                    <div className="row" style={{ padding: "1em", marginTop: "-15px" }}>
                      <div className="card" id="organizationFormCard">
                        <div className="row">
                          <div className="row pb-1" style={{ alignItems: "center", float: "right" }}>
                            <div className="col-auto me-auto">
                              <h5 className="ps-4 mb-0" id="headerLabel">
                                {/* {this.state.cyclePlanName} */}
                              </h5>
                            </div>
                            <div className="col-auto me-3">
                              {this.state.getDeletePermission &&
                                <DeleteIconButton
                                  domainObject={this.state.asset.name}
                                  title="Asset Cycle"
                                  onClick={(e) => this.deleteAssetCycleToBackend(e)}
                                />}
                              {this.state.getUpdatePermission &&
                                <EditIconButton
                                  onClick={(e) => this.editAssetCycleDetails(e)}
                                />}
                              {this.state.canMarkStarted &&
                                <StartCycleButton
                                  domainObject={this.state.domainObject}
                                  onClick={this.getStartCycle.bind(this)}
                                  name="Start Cycle"
                                />
                              } {" "}
                              {this.state.canMarkCompleted &&
                                <MarkCompletedButton
                                  domainObject={this.state.domainObject}
                                  onClick={this.getCompletedCycle.bind(this)}
                                  name="Mark Completed"
                                />
                              } {" "}
                            </div>
                          </div>
                          <hr className='m-0'></hr>
                          <div className="card-body">
                            <div className="col-auto me-auto text-start">
                              <h5 className="mb-1" id="headerLabel">
                                {this.state.name}
                              </h5>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="row pt-2">
                                  <label
                                    className="col-sm-4 col-form-label fw-bold"
                                    htmlFor="basic-default-name"
                                    id="assetFormLabel"
                                  >
                                    Customer
                                  </label>
                                  <div className="col-sm-8" id="OrganizationCol">
                                    <span>{this.state.customer !== null ? this.state.customer.name : ""}</span>
                                  </div>
                                </div>
                                <div className="row pt-2">
                                  <label
                                    className="col-sm-4 col-form-label fw-bold"
                                    htmlFor="basic-default-name"
                                    id="assetFormLabel"
                                  >
                                    Start Date
                                  </label>
                                  <div className="col-sm-8" id="OrganizationCol">
                                    <span>{Utils.getUtcToLocalDateTime(this.state.startDate)}</span>
                                  </div>
                                </div>
                                <div className="row pt-2">
                                  <label
                                    className="col-sm-4 col-form-label fw-bold"
                                    htmlFor="basic-default-name"
                                    id="assetFormLabel"
                                  >
                                    Cycle Time
                                  </label>
                                  <div className="col-sm-8" id="OrganizationCol">
                                    <span>{cycletime + " " + cycletimeFrequency}</span>
                                  </div>
                                </div>
                                <div className="row">
                                  <label
                                    className="col-sm-4 col-form-label fw-bold"
                                    htmlFor="basic-default-name"
                                    id="assetFormLabel"
                                  >
                                    Plan Name
                                  </label>
                                  <div className="col-sm-8" id="AssetCol">
                                    <span>
                                      <a
                                        onClick={this.toPlanDetails.bind(this)}
                                        style={{ color: "blue", cursor: "pointer" }}
                                      >
                                        {this.state.returnablePlan?.name}
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="row pt-2">
                                      <label
                                        className="col-sm-4 col-form-label fw-bold"
                                        htmlFor="basic-default-name"
                                        id="assetFormLabel"
                                      >
                                        Asset
                                      </label>
                                      <div className="col-sm-8" id="OrganizationCol">
                                        <span>{this.state.asset !== null ? this.state.asset.name : ""}</span>
                                      </div>
                                    </div>
                                    <div className="row pt-2">
                                      <label
                                        className="col-sm-4 col-form-label fw-bold"
                                        htmlFor="basic-default-name"
                                        id="assetFormLabel"
                                      >
                                        End Date
                                      </label>
                                      <div className="col-sm-8" id="OrganizationCol">
                                        <span>{Utils.getUtcToLocalDateTime(this.state.endDate)}</span>
                                      </div>
                                    </div>
                                    <div className="row pt-2">
                                      <label
                                        className="col-sm-4 col-form-label fw-bold"
                                        htmlFor="basic-default-name"
                                        id="assetFormLabel"
                                      >
                                        Holding Period
                                      </label>
                                      <div className="col-sm-8" id="OrganizationCol">
                                        <span>{(this.state.holdingPeriod !== null && this.state.holdingPeriodFrequency !== null) ? this.state.holdingPeriod + " " + this.state.holdingPeriodFrequency.frequency : ""}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="row pt-2">
                                      <label
                                        className="col-sm-4 col-form-label fw-bold"
                                        htmlFor="basic-default-name"
                                        id="assetFormLabel"
                                      >
                                        Asset UID
                                      </label>
                                      <div className="col-sm-8" id="OrganizationCol">
                                        <span>
                                          <a
                                            // href="#"
                                            onClick={this.toAssetDetails.bind(this)}
                                            style={{ color: "blue", cursor: "pointer" }}
                                          >
                                            {this.state.asset.uid}
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row pt-2">
                                      <label
                                        className="col-sm-4 col-form-label fw-bold"
                                        htmlFor="basic-default-name"
                                        id="assetFormLabel"
                                      >
                                        Status
                                      </label>
                                      <div className="col-sm-8" id="OrganizationCol">
                                        <span>{this.state.cycleStatus}</span>
                                      </div>
                                    </div>
                                    {this.state.trackingHistoryStatus !== null ? (
                                      <div className="row pt-2">
                                        <label
                                          className="col-sm-4 col-form-label fw-bold"
                                          htmlFor="basic-default-name"
                                          id="assetFormLabel"
                                        >
                                          Tracking Status
                                        </label>
                                        {
                                          this.state.trackingHistoryStatus.status === "Active" &&
                                          <div className="col-sm-8" id="AssetCol">
                                            <span >{this.state.trackingHistoryStatus.status}</span>
                                          </div>
                                        }
                                        {
                                          this.state.trackingHistoryStatus.status === "NotTracked" &&
                                          <div className="col-sm-8" id="AssetCol">
                                            <span >{this.state.trackingHistoryStatus.status}</span>
                                          </div>
                                        }
                                        {
                                          this.state.trackingHistoryStatus.status === "Tracked" &&
                                          <div className="col-sm-8" id="AssetCol">
                                            <span className="greendot"></span>
                                            <span className='ps-2'>{this.state.trackingHistoryStatus.status}</span>
                                          </div>
                                        }
                                        {
                                          this.state.trackingHistoryStatus.status === "HoldingPeriodOver" &&
                                          <div className="col-sm-8" id="AssetCol">
                                            <span className="yellowdot blink_me"></span>
                                            <span className='ps-2'>{this.state.trackingHistoryStatus.status}</span>
                                          </div>
                                        }
                                        {
                                          this.state.trackingHistoryStatus.status === "CycleTimeOver" &&
                                          <div className="col-sm-8" id="AssetCol">
                                            <span className="orangedot blink_me"></span>
                                            <span className='ps-2'>{this.state.trackingHistoryStatus.status}</span>
                                          </div>
                                        }
                                        {
                                          this.state.trackingHistoryStatus.status === "MissingAsset" &&
                                          <div className="col-sm-8" id="AssetCol">
                                            <span className="reddot blink_me"></span>
                                            <span className='ps-2'>{this.state.trackingHistoryStatus.status}</span>
                                          </div>
                                        }
                                      </div>
                                    )
                                      : ""
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="row pt-2">
                                  <label
                                    className="col-sm-12 col-form-label fw-bold"
                                    htmlFor="basic-default-name"
                                    id="assetFormLabel"
                                  >
                                    Description
                                  </label>
                                  <br></br>
                                  <div className="col-sm-12" id="OrganizationCol">
                                    <span>{this.state.description}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="card-header d-flex align-items-center justify-content-between p-2"
                              id="assetCardHeader"
                            >
                              <h5 className="mb-0" id="headerLabel">
                                Source Address
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12 p-0">
                                  <div className="row pt-2 text-start">
                                    <span>{Utils.getAddressAsText(this.state.sourceAddress)}</span>
                                  </div>
                                </div>
                                {this.state.sourceAddress !== null ?
                                  <div className="col-md-12 p-0">
                                    <div className='row text-start mt-2 d-flex'>
                                      <div className='col-md-3'>
                                        <span className="mb-0" id="headerLabel">
                                          Geolocation
                                        </span>
                                      </div>
                                      <div className='col-md-9 text-start' style={{ marginLeft: "-25px" }}>
                                        <span>{this.state.sourceAddress?.latitude + " , " + this.state.sourceAddress?.longitude}</span>
                                      </div>
                                    </div>
                                  </div> : ""
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="card-header d-flex align-items-center justify-content-between p-2"
                              id="assetCardHeader"
                            >
                              <h5 className="mb-0" id="headerLabel">
                                Destination Address
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12 p-0">
                                  <div className="row pt-2 text-start">
                                    <span>{this.state.customerLocation !== null ? this.state.customerLocation.address : ""}</span>
                                  </div>
                                </div>
                                {this.state.customerLocation !== null ?
                                  <div className="col-md-12 p-0">
                                    <div className='row text-start mt-2 d-flex'>
                                      <div className='col-md-3'>
                                        <span className="mb-0" id="headerLabel">
                                          Geolocation
                                        </span>
                                      </div>
                                      <div className='col-md-9 text-start' style={{ marginLeft: "-25px" }}>
                                        <span>{this.state.customerLocation?.latitude + " , " + this.state.customerLocation?.longitude}</span>
                                      </div>
                                    </div>
                                  </div>
                                  : ""
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <BackButton
                        type="button"
                        style={{ backgroundColor: '#162c50', color: '#fff' }}
                        variant="contained"
                        onClick={(e) => this.viewCancel()}
                      >
                        Back
                      </BackButton>
                    </div>
                    <Modal isOpen={this.state.openListModal} toggle={this.toggle} size="lg">
                      <Row>
                        <Col md={12}>
                          <MaterialTable
                            title="Asset List"
                            icons={UiUtils.getMaterialTableIcons()}
                            columns={[
                              {
                                title: "Name",
                                field: "name",
                                cellStyle: {
                                  width: "25%"
                                }
                              },
                              {
                                title: "UID",
                                field: "uid",
                                cellStyle: {
                                  width: "20%"
                                }
                              },
                              {
                                title: "Asset code",
                                field: "assetcode",
                                cellStyle: {
                                  width: "20%"
                                }
                              },
                              {
                                title: "Asset Type",
                                field: "assetType",
                                cellStyle: {
                                  width: "20%"
                                }
                              },
                              {
                                title: "Status",
                                field: "assetStatus",
                              },
                            ]}
                            data={this.assetList}
                            options={{
                              actionsColumnIndex: -1,
                              selection: true,
                              paging: false,
                              showTextRowsSelected: false,
                              maxBodyHeight: 450,
                              headerStyle: {
                                fontWeight: 'bold',
                                position: 'sticky',
                                top: 0,
                                align: 'left',
                              },
                            }}
                            onSelectionChange={(rows) => this.onAssetCheckBoxSelect(rows)}
                          />
                        </Col>
                      </Row>
                      <ModalFooter>
                        <Col style={{ textAlign: 'center', paddingTop: "10px" }}>
                          <Button
                            variant="contained"
                            size="small"
                            style={{ background: '#ef6603', color: '#fff' }}
                            onClick={(e) => this.addAssetToList()}
                          >
                            Add Asset
                          </Button>
                          &nbsp; &nbsp;
                          <CancelIconButton onClick={(e) => this.toggle()} />
                        </Col>
                      </ModalFooter>
                    </Modal>
                  </>
                </TabPanel>
                <TabPanel>
                  <AssetCycleTrakingHistory assetCycleId={this.state.assetCycleId} />
                </TabPanel>
                <TabPanel>
                  <AssetCycleAuditLog assetCycleId={this.state.assetCycleId} />
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
        </>
      )
    }
  }

  editAssetCycle() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          domainObject="Asset Cycle"
          name="Edit Asset Cycle"
        />
        <div className="row">
          <ValidatorForm className="text-start" ref="form" onSubmit={this.updateAssetCycleToBackend.bind(this)}>
            <div className="row">
              <div className="col-md-12">
                <div className="card " id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Asset Cycle Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label" htmlFor="collapsible-supplierType">
                          Customer <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="customerTypeList"
                          options={this.customerList}
                          value={this.state.customer}
                          defaultValue={this.state.customer}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCustomerTypeChange(value);
                          }}
                          inputprops={{ style: { textTransform: "capitalize" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Customer"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label" htmlFor="collapsible-supplierType">
                          Returnable Plan<span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="customerTypeList"
                          options={this.returnablePlanList}
                          value={this.state.returnablePlan}
                          defaultValue={this.state.returnablePlan}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleReturnablePlanTypeChange(value);
                          }}
                          inputprops={{ style: { textTransform: "capitalize" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Returnable Plan"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label" htmlFor="collapsible-supplierType">
                          Asset <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="customerTypeList"
                          options={this.state.assetList}
                          value={this.state.asset}
                          defaultValue={this.state.asset}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleAssetChange(value);
                          }}
                          inputprops={{ style: { textTransform: "capitalize" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Asset"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label" htmlFor="collapsible-fullname">
                          Start Date
                        </label>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange.bind(this)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Start Date*"
                          required
                          className="startDateTimeInput"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12" id="assetFormLabel">
                        <label className="form-label" htmlFor="collapsible-description">
                          Description
                        </label>
                        <textarea
                          name="collapsible-address"
                          className="form-control"
                          style={{ color: 'black', font: "inherit", fontSize: "16px " }}
                          id="collapsible-address"
                          rows="4"
                          placeholder="Description"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1em" }}>
              <div className="col-md-12">
                <div className="card" id="organizationFormCard">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="assetCardHeader"
                  >
                    <h5 className="mb-0" id="headerLabel">
                      Source Address
                    </h5>
                  </div>
                  <div className="card-body">
                    <AddressWithTimezone
                      ref={this.sourceAddressRef}
                      onChange={this.handleSourceAddressChange.bind(this)}
                      data={this.state.sourceAddress}
                      action="edit"
                    />
                  </div>
                  <hr></hr>
                  <div className="col-md-12">
                    <div
                      className="card-header d-flex align-items-center justify-content-between"
                      id="assetCardHeader"
                    >
                      <h5 className="mb-0" id="headerLabel">
                        Destination Address
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="col-md-4 mt-1" id="assetFormLabel">
                        <label className="form-label " htmlFor="collapsible-supplierType">
                          Loacation <span className="asterisk"> *</span>
                        </label>
                        <Autocomplete
                          fullWidth
                          id="customerTypeList"
                          options={this.state.customerLocationList}
                          value={this.state.customerLocation}
                          defaultValue={this.state.customerLocation}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleCustomerLocationChange(value);
                          }}
                          inputprops={{ style: { textTransform: "capitalize" } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Asset"
                              variant="outlined"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between custBut">
              <div className="col-sm-12 mt-3 text-center">
                <SaveButton id="saveButton" type="submit"> Save </SaveButton>
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.editCancel()} />
              </div>
            </div>
          </ValidatorForm>
        </div>
      </>
    )
  }
}
export default withRouter(AssetCycle);