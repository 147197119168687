import React, { Component } from "react";

export class PtsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
      type: props.type,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <button
        id="ptsButton"
        type="submit"
        className="btn btn-default "
        onClick={this.onClick.bind(this)}
      >
        {this.props.btnName}
      </button>
    );
  }
}
export default PtsButton;
