import { GET_RETURNABLE_PLAN, SET_RETURNABLE_PLAN } from "./ReturnablePlanTypes";

const initialState = {
    returnablePlanList: {},
};

const ReturnablePlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RETURNABLE_PLAN:
            return {
                ...state,
            };
        case SET_RETURNABLE_PLAN:
            return {
                ...state,
                returnablePlanList: action.payload,
            };

        default:
            return state;
    }
};

export default ReturnablePlanReducer;
