import Tooltip from "@material-ui/core/Tooltip";
import React, { Component } from "react";
export class CreateSimilarIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domainObject: props.onClick,
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        className="createSimilar"
        onClick={this.onClick.bind(this)}
        style={{
          cursor: "pointer",
          fontSize: "x-larger",
          marginRight: "0.5em",
          paddingTop: "5px",
          float: "right",
        }}
      >
        <Tooltip title="Create Similar">
          <button
            id="ptsButton"
            type="submit"
            className="btn btn-default "
            onClick={this.onClick.bind(this)}
          >
            {this.props.name}
          </button>
        </Tooltip>
      </span>
    );
  }
}
export default CreateSimilarIconButton;
