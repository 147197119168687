import React from "react";

import "./Modal.css";

import { IconButton } from "@mui/material";

import CloseIcon from "@material-ui/icons/Close";

import { Modal, Fade, Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 2,
  width: "80vw",
  maxWidth: "40rem",
};

class CustomModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onClose: props.onClose,
    };
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.state.onClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
        id="modal"
      >
        <Fade in={this.props.open}>
          <Box sx={style} className={this.props.className}>
            <IconButton className="close-button" onClick={this.state.onClose}>
              <CloseIcon />
            </IconButton>
            <div
              className="modal-header"
              style={{ padding: "0.5rem 0rem 2.25rem" }}
            >
              <h5 className="modal-title" id="exampleModalLabel2">
                {this.props.title}
              </h5>
            </div>
            {this.props.children}
          </Box>
        </Fade>
      </Modal>
    );
  }
}

export default CustomModal;
