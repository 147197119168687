import { GET_ASSET, SET_ASSET } from "./AssetTypes";

const initialState = {
    assetList: {},
};

const AssetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSET:
      return {
        ...state,
      };
    case SET_ASSET:
      return {
        ...state,
        assetList: action.payload,
      };

    default:
      return state;
  }
};

export default AssetReducer;
