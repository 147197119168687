import { GET_CATEGORY, SET_CATEGORY, CLEAR_CATEGORY } from "./CategoryType";

const getCategory = () => {
  return {
    type: GET_CATEGORY,
  };
};

const setCategory = (data) => {
  return {
    type: SET_CATEGORY,
    payload: data,
  };
};

const clearCategory = (data) => {
  return {
    type: CLEAR_CATEGORY,
    payload: data,
  };
};

const CategoryAction = {
  getCategory,
  setCategory,
  clearCategory,
};
export default CategoryAction;
