import {
  CLEAR_FILTER_DETAILS,
  GET_FILTER_DETAILS,
  SET_FILTER_DETAILS,
} from "./FilterDetailsTypes";

const getFilterDetails = () => {
  return {
    type: GET_FILTER_DETAILS,
  };
};

const setFilterDetails = (data) => {
  return {
    type: SET_FILTER_DETAILS,
    payload: data,
  };
};

const clearFilterDetails = (data) => {
  return {
    type: CLEAR_FILTER_DETAILS,
    payload: data,
  };
};
const FilterDetailsAction = {
  getFilterDetails,
  setFilterDetails,
  clearFilterDetails,
};
export default FilterDetailsAction;
