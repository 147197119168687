import { GET_USER_DATA, SET_USER_DATA } from "./AppUserDataTypes";

const initialState = {
    appUserData: {},
};

const AppUserDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DATA:
      return {
        ...state,
      };
    case SET_USER_DATA:
      return {
        ...state,
        appUserData: action.payload,
      };

    default:
      return state;
  }
};

export default AppUserDataReducer;
