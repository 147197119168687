import React, { Component } from 'react'
import { withRouter } from "../../../withRouter";
import { MenuWithBreadScrum } from "../../menu/MenuWithBreadScrum";
import { NavLink } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import FetchServerData from "../../../provider/FetchServerData";
import ReactApexCharts from 'react-apexcharts';
import store from '../../../redux/store';

class AssetAnalysis extends Component {
	header = store.getState().header.header;
	breadCrumb = [
		{ pageid: "/reports", uiname: "MIS Reports" },
		{ pageid: "", uiname: "Asset Analytics" }
	];

	constructor() {
		super();
		this.state = {
			render: false,
			activeCustomers: 0,
			inActiveCustomers: 0,
			assetSeries: null,
			assetOptions: null,
			assetTrackingSeries: null,
			assetTrackingOptions: null,
			customerAssetOptions: null,
			customerAssetSeries: null,
			supplierAssetSeries: null,
			supplierAssetOptions: null,
			assetCycleSeries: null,
			assetCycleOptions: null,
			customerChartData: [],
			supplierChartData: [],
			assetCycleChartData: [],
		};
		this.getCustomerAnalysisReport();
	}

	getCustomerAnalysisReport() {
		const postObject = {
			header: this.header,
		};

		FetchServerData.callPostService('/assetAnalyticms/get', postObject)
			.then((output) => {
				if (output.status === 'SUCCESS') {
					let assetAnalysisDetails = output.data;
					let activeCount = assetAnalysisDetails.activeCount;
					let incycleCount = assetAnalysisDetails.incycleCount;
					let discardedCount = assetAnalysisDetails.discardedCount;
					let damagedCount = assetAnalysisDetails.damagedCount;
					let assignedToPlanCount = assetAnalysisDetails.assignedToPlanCount;
					let inMaintainanceCount = assetAnalysisDetails.inMaintainanceCount;
					let activeAssets = assetAnalysisDetails.activeAssets;
					let missingAssets = assetAnalysisDetails.missingAssets;
					let trackedAssets = assetAnalysisDetails.trackedAssets;
					let customerChartData = assetAnalysisDetails.customerAssetList;
					let supplierChartData = assetAnalysisDetails.supplierAssetList;
					let assetCycleChartData = assetAnalysisDetails.assetCycleList;

					let customerLabels = [];
					let customerAssetValue = [];
					let customerAssetCycleValue = [];
					let customerMissingAssetValue = [];
					let customerPlanValue = [];
					let supplierAssetLabels = [];
					let supplierAssetValue = [];
					let assetCycleLabels = [];
					let assetCycleValue = [];

					customerChartData.sort((a, b) => b.cycleCount - a.cycleCount)
					for (let i = 0; i < customerChartData.length; i++) {
						let date = customerChartData[i].customerName;
						customerLabels.push(date);
						customerAssetValue.push(customerChartData[i].assetCount);
						customerMissingAssetValue.push(customerChartData[i].missingAssetCount);
						customerAssetCycleValue.push(customerChartData[i].cycleCount);
						customerPlanValue.push(customerChartData[i].planCount);
					}

					supplierChartData.sort((a, b) => b.count - a.count)
					for (let i = 0; i < supplierChartData.length; i++) {
						let date = supplierChartData[i].supplierName;
						supplierAssetLabels.push(date);
						supplierAssetValue.push(supplierChartData[i].count);
					}

					for (let i = 0; i < assetCycleChartData.length; i++) {
						let date = assetCycleChartData[i].assetName;
						assetCycleLabels.push(date);
						assetCycleValue.push(assetCycleChartData[i].count);
					}

					this.setState({
						render: true,
						customerChartData: customerChartData,
						supplierChartData: supplierChartData,
						assetCycleChartData: assetCycleChartData,
						assetSeries: [activeCount, assignedToPlanCount, incycleCount, damagedCount, inMaintainanceCount, discardedCount,],
						assetOptions: {
							colors: ['rgb(0, 227, 150)', '#fd7e14', "#288C28", "#ffc107", "#696cff", "#ff3e1d"],
							// colors: ['#99E2FF', "#5CABED", '#1ab7ea', "#0077B5", "#676BB0", "#005E9A"],
							labels: ["Active", "Assigned To Plan", "InCycle", "Damaged", "InMaintainance", "Discarded"],
							plotOptions: {
								pie: {
									donut: {
										labels: {
											show: true,
											total: {
												show: true,
												showAlways: true,
												fontSize: "18px",
												color: "black"
											}
										},
									}
								}
							},
							chart: {
								type: 'donut',
							},

							responsive: [{
								breakpoint: 480,
								options: {
									chart: {
										width: 200
									},
									legend: {
										position: 'bottom'
									}
								}
							}],
						},
						assetTrackingSeries: [activeAssets, missingAssets, trackedAssets],
						assetTrackingOptions: {
							colors: ['rgb(0, 227, 150)', '#F44336', "#288C28"],
							// colors: ['#99E2FF', '#005E9A', "#1ab7ea"],
							labels: ["Active Assets", "Missing Assets", "Tracked Assets"],
							chart: {
								type: 'pie',
							},

							responsive: [{
								breakpoint: 480,
								options: {
									chart: {
										width: 200
									},
									legend: {
										position: 'bottom'
									}
								}
							}],
						},
						customerAssetSeries: [
							{
								name: 'Asset Cycles',
								type: 'bar',
								data: customerAssetCycleValue
							},
							{
								name: 'Plans',
								type: 'bar',
								data: customerPlanValue,
							},
							{
								name: 'Assets',
								type: 'bar',
								data: customerAssetValue,
							},
							{
								name: 'Missing Assets',
								type: 'bar',
								data: customerMissingAssetValue,
							},
						],
						customerAssetOptions: {
							chart: {
								type: 'bar',
								height: 430,
								toolbar: {
									show: true,
									tools: {
										download: false
									}
								}
							},
							legend: {
								position: 'top'
							},
							colors: ['#2E93fA', '#FF9800', "rgb(0, 227, 150)", "#ff3e1d"],
							plotOptions: {
								bar: {
									horizontal: false,
									// columnWidth: '25%',
									dataLabels: {
										position: 'top',
									},
								}
							},
							grid: {
								show: true,
								xaxis: {
									lines: {
										show: false
									}
								},
								yaxis: {
									lines: {
										show: false
									}
								},
							},
							dataLabels: {
								enabled: true,
								// offsetX: -6,
								style: {
									fontSize: '12px',
									colors: ['#fff']
								}
							},
							stroke: {
								show: true,
								width: 1,
								colors: ['#fff']
							},
							tooltip: {
								shared: true,
								intersect: false
							},
							xaxis: {
								categories: customerLabels,
								labels: {
									style: {
										colors: '#247BA0',
									},
								},
								title: {
									text: 'Customers',
									style: {
										color: '#247BA0',
										fontSize: "15px"
									},
								},
								axisTicks: {
									show: true,
								},
								axisBorder: {
									show: true,
									color: '#247BA0',
								},
							},
							yaxis: [
								{
									axisTicks: {
										show: true,
									},
									axisBorder: {
										show: true,
										color: '#247BA0',
									},

									labels: {
										formatter: function (val) {
											return val.toFixed(0);
										},
										style: {
											colors: '#247BA0',
										},
									},
								},
							],
						},
						supplierAssetSeries: [
							{
								name: 'Assets',
								type: 'bar',
								data: supplierAssetValue
							},
						],
						supplierAssetOptions: {
							chart: {
								type: 'bar',
								height: 430,
								toolbar: {
									show: true,
									tools: {
										download: false
									}
								}
							},
							// title: {
							// 	text: 'Assets vs. Suppliers',
							// 	align: 'left',
							// 	fontSize: "18px"
							// },
							colors: ['#2E93fA', '#0091B1',],
							plotOptions: {
								bar: {
									horizontal: false,
									columnWidth: '25%',
									dataLabels: {
										position: 'top',
									},
								}
							},
							grid: {
								show: true,
								xaxis: {
									lines: {
										show: false
									}
								},
								yaxis: {
									lines: {
										show: false
									}
								},
							},
							dataLabels: {
								enabled: true,
								// offsetX: -6,
								style: {
									fontSize: '12px',
									colors: ['#fff']
								}
							},
							stroke: {
								show: true,
								width: 1,
								colors: ['#fff']
							},
							tooltip: {
								shared: true,
								intersect: false
							},
							xaxis: {
								categories: supplierAssetLabels,
								labels: {
									style: {
										colors: '#247BA0',
									},
								},
								title: {
									text: 'Suppliers',
									style: {
										color: '#247BA0',
										fontSize: "12px"
									},
								},
								axisTicks: {
									show: true,
								},
								axisBorder: {
									show: true,
									color: '#247BA0',
								},
							},
							yaxis: [
								{
									axisTicks: {
										show: true,
									},
									axisBorder: {
										show: true,
										color: '#247BA0',
									},
									labels: {
										formatter: function (val) {
											return val.toFixed(0);
										},
										style: {
											colors: '#247BA0',
										},
									},
									title: {
										text: 'Assets',
										style: {
											color: '#247BA0',
											fontSize: "12px"
										},
									},
								},
							],
						},
						assetCycleSeries: [
							{
								name: 'Asset Cycles',
								type: 'bar',
								data: assetCycleValue
							},
						],
						assetCycleOptions: {
							chart: {
								type: 'bar',
								height: 430,
								toolbar: {
									show: true,
									tools: {
										download: false
									}
								}
							},
							// title: {
							// 	text: 'Assets vs. Asset Codes',
							// 	align: 'left',
							// 	fontSize: "18px"
							// },
							colors: ['#0091B1', '#69d2e7'],
							text: "0091B1",
							plotOptions: {
								bar: {
									horizontal: false,
									columnWidth: '25%',
									width: "20px",
									dataLabels: {
										position: 'top',
									},
								}
							},
							grid: {
								show: true,
								xaxis: {
									lines: {
										show: false
									}
								},
								yaxis: {
									lines: {
										show: false
									}
								},
							},
							dataLabels: {
								enabled: true,
								// offsetX: -6,
								style: {
									fontSize: '12px',
									colors: ['#fff']
								}
							},
							stroke: {
								show: true,
								width: 1,
								colors: ['#fff']
							},
							tooltip: {
								shared: true,
								intersect: false
							},
							xaxis: {
								categories: assetCycleLabels,
								labels: {
									style: {
										colors: '#247BA0',
									},
								},
								title: {
									text: 'Assets',
									style: {
										color: '#247BA0',
										fontSize: "12px"
									},
								},
								axisTicks: {
									show: true,
								},
								axisBorder: {
									show: true,
									color: '#247BA0',
								},
							},
							yaxis: [
								{
									// allowDecimals: false,
									tickInterval: 1,
									title: {
										text: 'Asset Cycles',
										style: {
											color: '#247BA0',
											fontSize: "12px"
										},
									},
									axisTicks: {
										show: true,
									},
									axisBorder: {
										show: true,
										color: '#247BA0',
									},
									labels: {
										formatter: function (val) {
											return val.toFixed(0);
										},
										style: {
											colors: '#247BA0',
										},
									},
								},
							],
						},
					});
				} else {
				};
			});
	};
	render() {
		if (this.state.render === false) {
			return <div />;
		} else {
			return (
				<>
					<MenuWithBreadScrum
						breadCrumb={this.breadCrumb}
						navigate={this.props.navigate}
						domainObject="Asset Analytics"
						name="Asset Analytics"
					/>
					{this.state.customerChartData.length > 0 ? (
						<>
							<div className="row">
								<div className="col-md-6">
									<div className="card">
										<div className="card-body">
											<div className="row mb-12 row d-flex justify-content-around">
												<h5 className='text-start pb-4'>Asset Status</h5>
												<div className="text-start" id="chart" style={{ padding: "1em" }}>
													{this.state.assetOptions !== null && this.state.assetSeries !== null ?
														<ReactApexCharts options={this.state.assetOptions} series={this.state.assetSeries} type="donut" height={250} />
														: ""
													}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card">
										<div className="card-body">
											<div className="row mb-12 row d-flex">
												<h5 className='text-start pb-4'>Tracking Status</h5>
												<div className="text-start" id="chart" style={{ padding: "1em" }}>
													{this.state.assetTrackingOptions !== null && this.state.assetTrackingSeries !== null ?
														<ReactApexCharts options={this.state.assetTrackingOptions} series={this.state.assetTrackingSeries} type="pie" height={250} />
														: ""
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-md-12">
									<div className="card">
										<div className="card-body">
											<div className="row mb-12 row justify-content-end">
												<div className="col-sm-8 m-2 text-end">
													<NavLink className="navLink text-decoration-underline" to="/activeAssets">
														Active
													</NavLink>
													&nbsp; &nbsp;&nbsp;
													<NavLink className="navLink text-decoration-underline" to="/inMaintenanceAssets">
														InMaintenance
													</NavLink>
													&nbsp; &nbsp;&nbsp;
													<NavLink className="navLink text-decoration-underline" to="/missingAssets">
														Missing
													</NavLink>
													&nbsp; &nbsp;&nbsp;
													<NavLink className="navLink text-decoration-underline" to="/damagedAssets">
														Damaged
													</NavLink>
													&nbsp; &nbsp;&nbsp;
													<NavLink className="navLink text-decoration-underline" to="/discardedAssets">
														Discarded
													</NavLink>
												</div>
											</div >
											<div className="row mb-12 row d-flex">
												<h5 className='text-start pb-1'>Assets vs. Customers</h5>
												<div className="text-start overflow-auto " id="chart" style={{ padding: "1em", overflowX: 'auto' }}>
													{this.state.customerAssetOptions !== null && this.state.customerAssetSeries !== null ?
														< ReactApexCharts
															options={this.state.customerAssetOptions}
															series={this.state.customerAssetSeries}
															type="bar"
															height={450}
															width={200 * Math.max(this.state.customerChartData.length)}
														/>
														: ""
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-md-12">
									<div className="card">
										<div className="card-body">
											<div className="row mb-12 row d-flex">
												<h5 className='text-start pb-1'>Assets vs. Asset Cycles</h5>
												<div className="text-start" id="chart" style={{ padding: "1em", overflowX: 'auto' }}>
													{this.state.assetCycleOptions !== null && this.state.assetCycleSeries !== null ?
														< ReactApexCharts
															options={this.state.assetCycleOptions}
															series={this.state.assetCycleSeries}
															type="bar"
															height={450}
															width={150 * Math.max(this.state.assetCycleChartData.length)}
														/>
														: ""
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-md-12">
									<div className="card">
										<div className="card-body">
											<div className="row mb-12 row d-flex">
												<h5 className='text-start pb-1'>Assets vs. Suppliers</h5>
												<div className="text-start" id="chart" style={{ padding: "1em", overflowX: 'auto' }}>
													{this.state.supplierAssetOptions !== null && this.state.supplierAssetSeries !== null ?
														< ReactApexCharts
															options={this.state.supplierAssetOptions}
															series={this.state.supplierAssetSeries}
															type="bar"
															height={450}
															width={150 * Math.max(this.state.supplierChartData.length)}
														/>
														: ""
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<div className="row" style={{ marginTop: "1em" }}>
							<div className="col-md-12">
								<Card>
									<CardBody>
										<div className="row">
											<div className="col-md-12" style={{ padding: "2em" }}>
												<h5>
													<b>Asset analytics data not found</b>
												</h5>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					)}
				</>
			)
		}
	}
}
export default withRouter(AssetAnalysis);