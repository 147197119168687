import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class AdminFooter extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div>
				<footer className="content-footer footer" style={{ backgroundColor: "black" }}>
					<div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
						<div className="col-6 text-start">
							<span>
								<ul style={{ listStyle: 'none', display: 'inline-flex', margin: 'auto' }}>
									<li style={{ textAlign: 'left' }}>
										<NavLink
											to='/apTermsCondition'
											style={{ color: '#fff', cursor: 'pointer' }}
										>
											{' '}
											Terms &amp; Conditions
										</NavLink>
									</li>
									&nbsp;
									<li style={{ textAlign: 'left' }}>
										<NavLink
											to="/apPrivacyPolicy"
											style={{ color: '#fff', cursor: 'pointer' }}
										>
											{' '}
											| &nbsp; Privacy Policy
										</NavLink>
									</li>
									&nbsp;
									<li style={{ textAlign: 'left' }}>
										<NavLink
											to="/apAboutUs"
											style={{ color: '#fff', cursor: 'pointer' }}
										>
											{' '}
											| &nbsp; About Us
										</NavLink>
									</li>
								</ul>
							</span>
						</div>
						<div className="col-6 mb-2 mb-md-0 footer-link text-end">
							<span style={{ color: '#fff', cursor: 'pointer' }}>Copyright © 2023. All rights reserved.</span>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}

export default AdminFooter;
