import { SET_FCMTOKEN, USER_LOGOUT } from './AppUserTypes';

const initialState = {
  fcmToken: '',
};

const AppUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FCMTOKEN:
      return {
        ...state,
        fcmToken: action.payload,
      };

    case USER_LOGOUT:
      state = undefined;
      return {
        state,
      };
    default:
      return state;
  }
};

export default AppUserReducer;
