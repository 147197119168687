import { GET_CATALOGUE, SET_CATALOGUE, CLEAR_CATALOGUE } from "./CatalogueType";

const getCatalogue = () => {
  return {
    type: GET_CATALOGUE,
  };
};

const setCatalogue = (data) => {
  return {
    type: SET_CATALOGUE,
    payload: data,
  };
};

const clearCatalogue = (data) => {
  return {
    type: CLEAR_CATALOGUE,
    payload: data,
  };
};

const CatalogueAction = {
  getCatalogue,
  setCatalogue,
  clearCatalogue,
};
export default CatalogueAction;
