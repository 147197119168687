import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import MaterialTable from "material-table";
import AdminFetchServerData from "../../provider/AdminFetchServerData";
import store from "../../redux/store";
import uiUtils from "../../provider/UiUtils";
import Utils from "../../provider/Utils";
import AdminMenuWithBreadScrum from "../adminMenu/AdminMenuWithBreadScrum";
import ApplicationStateAction from "../../redux/applicationState/ApplicationStateAction";
class RegistrationList extends Component {
  header = store.getState().adminHeader.adminHeader;
  title = "Registration List";
  breadCrumb = [{ pageid: "", uiname: "Registration List" }];
  constructor() {
    super();
    this.state = {
      activeRegistrations: [],
      initialPage: 0,
      pageSize: 10,
      isPaging: false,
      render: false,
    };
    this.getRegistrationsList();
  }

  getRegistrationsList() {
    const postObject = {
      header: this.header,
    };

    AdminFetchServerData.callPostService(
      "/adminpanelStatsms/getRegistrationList",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let activeRegistrationList = output.data.activeRegistrationList;
        let isPaging = false;
        if (activeRegistrationList.length > this.state.pageSize) {
          isPaging = true;
        }
        for (let i = 0; i < activeRegistrationList.length; i++) {
          activeRegistrationList[i].createdon = Utils.getUtcToLocal(
            activeRegistrationList[i].createdon
          );
        }
        this.setState({
          render: true,
          isPaging: isPaging,
          activeRegistrationList: activeRegistrationList,
          initialPage: this.state.initialPage,
        });
      } else {
      }
    });
  }

  toRegistrationDetails(e, data) {
    let adminRegistrationState = {
      registrationId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      ApplicationStateAction.setApplicationState({ adminRegistrationState })
    );
    this.props.navigate("/viewRegistration", {
      state: {
        action: "view",
        registrationId: data.id,
      },
    });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          navigate={this.props.navigate}
          name={this.title}
        />
        <div className="mt-4" style={{ maxWidth: "100%" }}>
          <MaterialTable
            title=""
            icons={uiUtils.getMaterialTableIcons()}
            onRowClick={(event, rowData) => {
              this.toRegistrationDetails(event, rowData);
              event.stopPropagation();
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Data not found",
              },
            }}
            columns={[
              {
                title: "#",
                render: (rowData) => rowData.tableData.id + 1,
                width: "5%",
                align: "left",
                cellStyle: {
                  width: "6%",
                },
              },
              {
                title: "Company Name",
                field: "name",
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: "Created On",
                field: "createdon",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: "Is Disabled",
                field: "isdisabled",
                render: (rowData) =>
                  rowData.isdisabled ? "Disabled" : "Enabled",
                cellStyle: {
                  width: "10%",
                },
              },
              {
                title: "Disabled Reason",
                field: "disabledReason",
                cellStyle: {
                  width: "30%",
                },
              },
              {
                title: "Status",
                field: "status",
                cellStyle: {
                  width: "10%",
                },
                filtering: false,
              },
            ]}
            data={this.state.activeRegistrationList}
            options={{
              sorting: true,
              actionsColumnIndex: -1,
              initialPage: this.state.initialPage,
              pageSize: this.state.pageSize,
              pageSizeOptions: Utils.materialTablePageSize(),
              paging: this.state.isPaging,
              headerStyle: {
                backgroundColor: "#0B6F9D",
                fontWeight: "bold",
                color: "#ffff",
                align: "left",
              },
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.id === this.state.userId ? "#FEF5E7" : "",
                height: "3em",
                textTransform: "capitalize",
              }),
            }}
            // onChangePage={(page, pageSize) => {
            // 	this.handleMaterialTablePageChange(page, pageSize);
            // }}
          />
        </div>
      </>
    );
  }
}
export default withRouter(RegistrationList);
